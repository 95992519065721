import * as React from 'react';

//other deps
import { Localized } from '@fluent/react';
import Scrollbars from 'react-custom-scrollbars';
import { RouteComponentProps, withRouter } from 'react-router';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//tabs
import { Personal } from './personal';
import { Contacts } from './contacts';
import { Documents } from './documents';
import { Transports } from './transports';

//types
import { RootState } from 'app/reducers';
import { CommonActions } from 'app/actions';
import { SidebarType, RoleEnum } from 'app/models';

//----------------------------------------------------------
// Profile
//----------------------------------------------------------
class Profile extends React.Component<Profile.Props, Profile.State> {
	public state: Profile.State = {
		tab: Profile.Tab.Personal,
	};

	constructor(props: Profile.Props) {
		super(props);

		this.createContactToggle = this.createContactToggle.bind(this);
		this.getListHeaderList = this.getListHeaderList.bind(this);
		this.getTab = this.getTab.bind(this);
	}

	private getListHeaderList() {
		const currentTab = this.state.tab;
		const { userRole } = this.props;

		const items = [
			{ text: 'profile-tab-personal', tab: Profile.Tab.Personal },

			...(userRole && userRole === RoleEnum.Customer
				? [{ text: 'profile-tab-contacts', tab: Profile.Tab.Contacts }]
				: [{ text: 'profile-tab-transports', tab: Profile.Tab.Transports }]),

			{ text: 'profile-tab-documents', tab: Profile.Tab.Documents },
		];

		return items.map(({ text, tab }, index) => {
			const onClick = () => {
				this.setState({ tab });
			};

			const className = `item ${currentTab === tab ? 'active' : ''}`;
			const props = { className, onClick, key: 'tab-' + (text + index) };

			return (
				<div {...props} key={`profile-headers-list-${index}`}>
					<Localized id={text} />
				</div>
			);
		});
	}

	private getTab() {
		const { tab } = this.state;

		switch (+tab) {
			case Profile.Tab.Personal:
				return <Personal />;
			case Profile.Tab.Contacts:
				return <Contacts />;
			case Profile.Tab.Transports:
				return <Transports />;
			case Profile.Tab.Documents:
				return <Documents />;
			default:
				return <div />;
		}
	}

	private async createContactToggle() {
		const { toggleSidebar } = this.props;

		if (toggleSidebar) {
			await toggleSidebar({ type: SidebarType.ContactCreate, value: true });
		}
	}

	public render() {
		const { userRole } = this.props;
		const { tab } = this.state;

		const contactsOpened = tab === Profile.Tab.Contacts;

		const scrollbarProps: any = {
			renderTrackHorizontal: () => <div />,
			// className: "form",
		};

		return (
			<div className='profile page'>
				<div className='header widget'>
					<div className='container'>
						<div className='title'>
							<Localized id='profile-page-header' />
						</div>

						<div className='actions'>
							<div className='menu'>{this.getListHeaderList()}</div>

							{!contactsOpened || !userRole || userRole !== RoleEnum.Customer ? null : (
								<button className='action' onClick={this.createContactToggle}>
									<Localized id='profile-action-create-contact' />
								</button>
							)}
						</div>
					</div>
				</div>

				<Scrollbars {...scrollbarProps}>
					<div className='tab-container'>{this.getTab()}</div>
				</Scrollbars>
			</div>
		);
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	userRole: user.profile ? user.profile.role : undefined,
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
		},
		dispatch,
	);

export default withRouter(
	connect<Profile.StateProps, Profile.DispatchProps, Profile.ExternalProps>(
		mapStateToProps,
		mapDispatchToProps,
	)(Profile),
);

namespace Profile {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	export enum Tab {
		Personal,
		Password,
		Contacts,
		Documents,
		Transports,
	}

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
		userRole?: RoleEnum;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		tab: Tab;
	}
}
