import { RouteComponentProps, withRouter } from 'react-router';
// import transportService from 'app/services/transport';
import { Dispatch, bindActionCreators } from 'redux';
import PageTitle from 'app/components/PageTitle';
import { BidApiModel } from 'app/api/types';
import Spinner from 'react-loader-spinner';
import { Localized } from '@fluent/react';
import { RootState } from 'app/reducers';
import { connect } from 'react-redux';
import Masker from 'vanilla-masker';
import Posed from 'react-pose';
import * as React from 'react';

// import { Bid, RoleEnum, UserProfile } from 'app/models';
import { RoleEnum, UserProfile } from 'app/models';
import { isMobile, maskPhoneValue } from 'app/utils';
import objectSwitch from 'app/utils/objectSwitch';
// import { store } from '../../../main';
import { delay } from 'bluebird';

import { CommonActions, UserActions, BidActions, ExecutorActions } from 'app/actions';

import { userSignIn, userSelectRole, userVerifyCode } from 'app/api';
import queryParser from 'app/utils/query-parser';

const googlePlay = require('../../../assets/images/googlePlay.png');

const ErrorMessage = Posed.div({
	closed: {
		backgroundColor: 'rgb(239,154,154)',
		height: 0,

		transition: {
			ease: 'easeOut',
			duration: 200,
		},
	},

	opened: {
		backgroundColor: 'rgb(239,83,80)',
		height: 34,

		transition: {
			ease: 'easeIn',
			duration: 200,
		},
	},
});

const errorState = (message?: string) => ({
	errorPose: message ? Login.ErrorPose.Opened : Login.ErrorPose.Closed,
	errorMessage: message,
});

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
	profile: user.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getVacantExecutors: ExecutorActions.getVacantExecutors,
			getExecutors: ExecutorActions.getExecutors,
			getCommonMeta: CommonActions.getCommonMeta,
			setAuthToken: UserActions.setAuthToken,
			getContacts: UserActions.getContacts,
			getProfile: UserActions.getProfile,
			getBids: BidActions.getBids,
		},
		dispatch,
	);

class Login extends React.Component<Login.Props, Login.State> {
	public state: Login.State = {
		errorPose: Login.ErrorPose.Closed,
		step: Login.Step.Phone,
		loading: false,
		phone: '',
		code: '',
	};

	constructor(props: Login.Props) {
		super(props);

		this.onChangePhone = this.onChangePhone.bind(this);
		this.onSubmitPhone = this.onSubmitPhone.bind(this);

		this.onSubmitCode = this.onSubmitCode.bind(this);
		this.onChangeCode = this.onChangeCode.bind(this);

		this.getRoleSelector = this.getRoleSelector.bind(this);
		this.getPhoneForm = this.getPhoneForm.bind(this);
		this.getCodeForm = this.getCodeForm.bind(this);

		this.elapseResendCode = this.elapseResendCode.bind(this);
		this.resendCode = this.resendCode.bind(this);

		this.getContent = this.getContent.bind(this);
	}

	public componentDidMount() {
		const query = queryParser();
		const isPhoneNumberEntered = !!query.phone;
		if (isPhoneNumberEntered) {
			query.phone = decodeURIComponent(query.phone);
			query.phone = query.phone.replace(/^8/, '+7');
			this.setState({ phone: maskPhoneValue(query.phone) });
		}
	}

	private onChangePhone(e: React.ChangeEvent<HTMLInputElement>) {
		const value = maskPhoneValue(e.target.value);
		this.setState({ phone: value, ...errorState() });
	}

	private onChangeCode(e: React.ChangeEvent<HTMLInputElement>) {
		const value = Masker.toPattern(e.target.value, '9999');
		this.setState({ code: value, ...errorState() });
	}

	private elapseResendCode() {
		setTimeout(() => {
			const { leftResendCode } = this.state;

			if (leftResendCode && leftResendCode > 0) {
				this.setState({ leftResendCode: leftResendCode - 1 });
				this.elapseResendCode();
			} else {
				this.setState({ leftResendCode: undefined });
			}
		}, 1000);
	}

	private async resendCode() {
		const { leftResendCode, phone } = this.state;

		if (!leftResendCode) {
			await this.setState({ code: '' });

			const { smsToken } = await userSignIn({
				phone: `+${Masker.toNumber(phone).toString()}`,
			});

			await this.setState({
				leftResendCode: 30,
				smsToken,
			});

			this.elapseResendCode();
		}
	}

	private async onSubmitPhone(e: React.FormEvent) {
		e.preventDefault();
		const { phone } = this.state;
		const cleanPhone = phone.replace(/[^0-9]/g, '');

		if (cleanPhone.length === 11 || cleanPhone.length === 12) {
			this.setState({ loading: true });

			try {
				const { smsToken } = await userSignIn({
					phone: `+${Masker.toNumber(phone).toString()}`,
				});

				await this.setState({
					step: Login.Step.Code,
					leftResendCode: 30,
					loading: false,
					smsToken,
				});

				this.elapseResendCode();
			} catch (error) {
				this.setState({
					...errorState('login-phone-invalid'),
					loading: false,
				});
			}
		} else {
			this.setState(errorState('login-phone-short'));
		}
	}

	private async onSubmitCode(e: React.FormEvent) {
		const { code, smsToken } = this.state;

		const {
			getVacantExecutors,
			getCommonMeta,
			getExecutors,
			setAuthToken,
			getContacts,
			getProfile,
			// getBids,
		} = this.props;

		e.preventDefault();

		if (code.length === 4 && smsToken) {
			this.setState({ loading: true });

			try {
				const { meta, data } = await userVerifyCode({ code, smsToken });
				const { sessionToken } = meta;

				// Set token
				await setAuthToken(sessionToken);

				// await Promise.all([
				//     getBids({ type: Bid.BidType.Archive }),
				//     getBids({ type: Bid.BidType.All }),
				//     getBids({ type: Bid.BidType.My }),
				// ]);

				if (data.isCompleted) {
					// If user already sign up and logged in - init transport
					// transportService.init(sessionToken, store);

					await getContacts(sessionToken);
					await getProfile(sessionToken);
					this.setState({ loading: false });

					// Get default meta info
					await delay(150);
					const { profile } = this.props;

					if (profile) {
						if (profile.role === RoleEnum.Customer) {
							getVacantExecutors({});
							getExecutors({});
						}

						await getCommonMeta();

						// Live-time set metrica params
						(window as any).ym(48431690, 'userParams', {
							UserID: profile.uuid,
							Role: profile.role,
						});
					}
				} else {
					this.setState({
						step: Login.Step.SelectRole,
						loading: false,
					});
				}
			} catch (error) {
				this.setState({
					...errorState('login-verify-error'),
					loading: false,
				});
			}
		} else {
			this.setState(errorState('login-verify-short'));
		}
	}

	private async onSubmitRole(name: string) {
		const role = name === 'role-customer' ? RoleEnum.Customer : RoleEnum.Driver;

		const { getVacantExecutors, getCommonMeta, getExecutors, sessionToken, getContacts, getProfile } = this.props;

		if (sessionToken) {
			this.setState({ loading: true });

			try {
				// transportService.init(sessionToken, store);

				await userSelectRole({ role });
				await getProfile(sessionToken);
				getContacts(sessionToken);

				// Transports queue
				if (role === RoleEnum.Customer) {
					getVacantExecutors({});
					getExecutors({});
				}

				this.setState({ loading: false });

				// Get default meta info
				await delay(150);
				const { profile } = this.props;

				if (profile) {
					await getCommonMeta();

					// Live-time set metrica params
					(window as any).ym(48431690, 'userParams', {
						UserID: profile.uuid,
						Role: profile.role,
					});
				}
			} catch (error) {
				// @todo: handle role error
				this.setState({ loading: false });
			}
		}
	}

	private getRoleSelector() {
		const roles = [
			{ text: 'role-customer', icon: require('../../../assets/icons/profile.png') },
			{ text: 'role-driver', icon: require('../../../assets/icons/transport.png') },
		];

		return (
			<div key='step-role' className='form role'>
				<div className='header'>
					<h1>
						<Localized id='login-role-header' />
					</h1>
				</div>

				<div className='options'>
					{roles.map((role, index) => {
						const onClick = this.onSubmitRole.bind(this, role.text);

						return (
							<div key={role.text + index} className='option' onClick={onClick}>
								<img src={role.icon} alt='' />
								<div className='title'>
									<Localized id={role.text} />
								</div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}

	private getPhoneForm() {
		const { phone, errorMessage, errorPose, loading } = this.state;

		const formProps = {
			className: 'form ' + (loading ? 'loading' : ''),
			onSubmit: this.onSubmitPhone,
			key: 'step-phone',
		};

		return (
			<form {...formProps}>
				{!loading ? null : (
					<div className='loading'>
						<Spinner type='RevolvingDot' color='#1FB0E2' height={50} width={50} />
					</div>
				)}

				<div className='header'>
					<h1>
						<Localized id='login-phone-header' />
					</h1>
				</div>
				<div className='content'>
					<div className='input rounded'>
						<input name='phone' type='tel' placeholder=' ' value={phone} required onChange={this.onChangePhone} />
						<label>
							<Localized id='login-phone-input-label' />
						</label>
					</div>
					<button className='submit' type='submit'>
						<Localized id='login-phone-action-label' />
					</button>
				</div>
				<ErrorMessage withParent={false} className='error' initialPose='closed' pose={errorPose}>
					<div className='message'>{!errorMessage ? null : <Localized id={errorMessage} />}</div>
				</ErrorMessage>
				<div className='bottom'>
					<div className='title'>
						<Localized id='login-phone-bottom-title' />
					</div>
				</div>
			</form>
		);
	}

	private getCodeForm() {
		const { phone, code, leftResendCode, errorMessage, errorPose, loading } = this.state;

		const formProps = {
			className: 'form ' + (loading ? 'loading' : ''),
			onSubmit: this.onSubmitCode,
			key: 'step-code',
		};

		return (
			<form {...formProps}>
				{!loading ? null : (
					<div className='loading'>
						<Spinner type='RevolvingDot' color='#1FB0E2' height={50} width={50} />
					</div>
				)}

				<div className='header'>
					<h1>
						<Localized id='login-verify-header' />
					</h1>
				</div>
				<div className='content'>
					<div className='input rounded'>
						<input type='text' placeholder=' ' required value={code} onChange={this.onChangeCode} />
						<label>
							<Localized id='login-verify-input-label' />
						</label>
					</div>
					<button className='submit' type='submit'>
						<Localized id='login-verify-action-label' />
					</button>
				</div>
				<ErrorMessage withParent={false} className='error' initialPose='closed' pose={errorPose}>
					<div className='message'>{!errorMessage ? null : <Localized id={errorMessage} />}</div>
				</ErrorMessage>
				<div className='bottom'>
					<div className='title'>
						<Localized id='login-verify-bottom-title' />
						<strong>{phone}</strong>
					</div>
					<div className='actions'>
						<button type='button' className='action' onClick={() => this.setStep(Login.Step.Phone)}>
							<Localized id='login-verify-change-phone' />
						</button>

						<button type='button' className='action' onClick={this.resendCode}>
							<Localized id='login-verify-resend-code' />{' '}
							{!leftResendCode ? null : <Localized id='login-verify-code-elapsed' vars={{ elapsed: leftResendCode }} />}
						</button>
					</div>
				</div>
			</form>
		);
	}

	private setStep(step: any) {
		this.setState(() => ({
			step,
			errorPose: Login.ErrorPose.Closed,
		}));
	}

	private getContent() {
		const { step } = this.state;

		const content = objectSwitch(step, {
			[Login.Step.SelectRole]: this.getRoleSelector,
			[Login.Step.Phone]: this.getPhoneForm,
			[Login.Step.Code]: this.getCodeForm,
		});

		return content();
	}

	public render() {
		const mobile = isMobile();

		return mobile ? (
			<div className='login page stores'>
				<PageTitle titleKey='title-login-page' />

				<div className='info'>
					<div className='title'>
						<Localized id='login-info-title' />
					</div>
					<div className='description'>
						<Localized id='login-info-description' />
					</div>

					<div className='markets'>
						<a className='link' href='https://play.google.com/store/apps/details?id=ru.vezzerno'>
							<img src={googlePlay} />
							<div className='text'>Google Play</div>
						</a>
					</div>
				</div>
			</div>
		) : (
			<div className='login page'>
				<PageTitle titleKey='title-login-page' />
				{this.getContent()}
			</div>
		);
	}
}

export default withRouter(
	connect<Login.StateProps, Login.DispatchProps, Login.ExternalProps>(mapStateToProps, mapDispatchToProps)(Login),
);

namespace Login {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		profile?: UserProfile;
		sessionToken?: string;
		dispatch?: Dispatch;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		getBids: (params: BidApiModel.BidsRequest, cursor?: string) => void;
		getCommonMeta: () => void;
		getVacantExecutors: (params: any) => void;
		getExecutors: (params: any) => void;
		setAuthToken: (sessionToken: string) => void;
		getContacts: (sessionToken: string) => void;
		getProfile: (sessionToken: string) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Current error status (for animated view)
	export enum ErrorPose {
		Closed = 'closed',
		Opened = 'opened',
	}

	// Current step
	export enum Step {
		SelectRole = 'select_role',
		Phone = 'phone',
		Code = 'code',
	}

	// Main component state
	export interface State {
		errorMessage?: string;
		errorPose: ErrorPose;
		loading: boolean;

		leftResendCode?: number;
		smsToken?: string;
		phone: string;
		code: string;
		step: Step;
	}
}
