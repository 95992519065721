import React from 'react';
import { Typography } from 'app/sharedComponents/Typography';
import { CellColContainer } from './styles';

export const RegionCell = ({ value }: any) => {
	return (
		<CellColContainer>
			<Typography variant='body3'>{value}</Typography>
			<Typography variant='body4' color='lightgray'>
				Черноморский порт
			</Typography>
		</CellColContainer>
	);
};
