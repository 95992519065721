import React from 'react';
import styled from 'styled-components';
import Primary from './Primary';
import Secondary from './Secondary';
import White from './White';
import Default from './Default';
import Dark from './Dark';

export const ButtonIcon = styled.button`
	display: block;
	text-align: left;

	height: 64px;
	padding: 12px;
	border-radius: 4px;

	font-weight: 500;
	width: 100%;

	background: #e6e8ed;
	color: #6c6f88;

	&:before {
		content: attr(data-symbol);
		display: inline-block;
		flex-direction: row;
		vertical-align: middle;

		width: 40px;
		height: 40px;
		margin-right: 16px;
		border-radius: 50%;

		background-color: #fff;
		text-align: center;
		font-size: 33px;
		color: #cecfd6;
	}
`;

export enum ButtonStyleTypes {
	PRIMARY = 'Primary',
	SECONDARY = 'Secondary',
	WHITE = 'White',
	DEFAULT = 'Default',
	DARK = 'Dark',
}
export interface ButtonProps {
	styleType: ButtonStyleTypes;
}

export const ButtonTypes: any = {
	Primary,
	Secondary,
	White,
	Default,
	Dark,
};

const Button: React.FC<any> = (props) => {
	const ButtonComponent: any = ButtonTypes[props.styleType];
	return <ButtonComponent {...props}>{props.children}</ButtonComponent>;
};

export default Button;
