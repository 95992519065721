import React, { useState } from 'react';
import styled from 'styled-components';
import RadioButtonGroup, { RadioButton } from '../RadioButtonGroup';
import { StyledContainer } from '../sharedStyles/styledContainer';
import { Icon } from 'app/components/Icons';

const StyledContainerLeft = styled(StyledContainer)`
	width: 330px;
`;

export type GroupFilterItem = {
	title: string;
	id: string;
	icon?: any;
};

export type GroupFilterProps = {
	active: string;
	title?: string;
	onChange: (value: string) => void;
	items: GroupFilterItem[];
	width?: string;
};

export const GroupFilter = ({ onChange, items, title = '', width }: GroupFilterProps) => {
	const [checkedIndex, setCheckedIndex] = useState(0);
	const handleOnChange = (value: number) => {
		onChange(items[value].id);
		setCheckedIndex(value);
	};
	return (
		<StyledContainerLeft style={{ width }}>
			<RadioButtonGroup canBeUnchecked checkedIndex={checkedIndex} onChange={handleOnChange} title={title}>
				{items.map(({ id, icon, title }) => {
					const isIconBase64 = icon?.includes('data:image/png;base64');
					return (
						<RadioButton key={id}>
							{icon && isIconBase64 && <img src={icon} />}
							{icon && !isIconBase64 && <Icon variant={icon} />}
							<span>{title}</span>
						</RadioButton>
					);
				})}
			</RadioButtonGroup>
		</StyledContainerLeft>
	);
};
