import * as React from 'react';

//other deps
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Localized } from '@fluent/react';
import Spinner from 'react-loader-spinner';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RootState } from 'app/reducers';
import { UserActions } from 'app/actions';
import { store } from 'src/main';

//api
import * as api from 'app/api';

//components
import Builder from 'app/containers/Builder';
import Checkbox from 'app/sharedComponents/Checkbox';
import FileUpload from 'app/sharedComponents/FileUpload';
import ValidationReporter from 'app/components/ValidationReporter';

//types
import { UserApiModel } from 'app/api/types';
import { SchemeProps } from 'app/containers/Builder/props';
import { LegalTypeEnum, UserVerifyStatus, UserProfile } from 'app/models';

type ErrorReport = {
	message: string;
	fieldName: string;
};

//constants
import { legalTypes, base as SCHEME } from '../schemes';

//helpers
import { schemeMapper } from './utils';
import innIsValid from 'app/utils/inn-validator';

//images
const profileImage = require('assets/icons/profile.png');

//----------------------------------------------------------
// Personal edit form
//----------------------------------------------------------
class Personal extends React.Component<Personal.Props, Personal.State> {
	private builder: Builder | null = null;

	public state: Personal.State = {
		agreeTerms: false,
		loading: false,
		validationReport: [],
		form: {},
		legalType: LegalTypeEnum.Np,
	};

	constructor(props: Personal.Props) {
		super(props);

		this.uploadDropperAvatar = this.uploadDropperAvatar.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.componentDidMount = this.componentDidMount.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.onSave = this.onSave.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	public applyDefaults() {
		const profile = this.props.profile;

		const savedAgreeTerms = localStorage.getItem('agreeTerms');

		if (savedAgreeTerms) {
			const agreeTerms = JSON.parse(savedAgreeTerms);

			this.setState({
				agreeTerms: agreeTerms,
			});
		}

		if (profile) {
			const photos = {
				avatar: profile.avatar,
				photo: profile.photo,
			};

			if (profile.legalType === LegalTypeEnum.Np) {
				profile.legalType = LegalTypeEnum.Company;
			}

			const defaultValues: { [name: string]: string | LegalTypeEnum | undefined } = {
				legalType: _.find(legalTypes, { value: profile.legalType })?.value || LegalTypeEnum.Company,

				companyName: profile.companyName,

				middleName: profile.middleName,
				firstName: profile.firstName,
				lastName: profile.lastName,

				phone: profile.phone,
				email: profile.email,
				inn: profile.inn,
			};

			const scheme = SCHEME.map((field) => {
				const input = { ...field } as any;

				if (input.name) {
					const defaultValue = defaultValues[input.name];
					const fieldProps = { ...field, defaultValue } as any;

					if (input.name === 'email') {
						// @TODO Enable that after create email verify on backend

						fieldProps.prepareProps = (form: any, props: any) => {
							// const { verifyStatus } = this.props

							// if (verifyStatus && verifyStatus.email) {
							//     props.extendComponentProps.verified = true
							//     props.disabled = true
							// } else {
							//     props.extendComponentProps.verified = false
							//     props.disabled = false
							// }

							return {
								...props,
							};
						};
					}

					if (input.name === 'inn') {
						const isInnCorrect = (inn: string) => {
							const { legalType } = this.state;
							const isStrictValidation = !(profile && profile.needActions.includes('fill_profile'));
							if (!inn && isStrictValidation) return 'builder-field-required';

							const symbolCount = legalType === LegalTypeEnum.Np ? [12] : [10, 12];

							if (inn && inn.length && !symbolCount.includes(inn.length))
								return `profile-field-inn-need-length-${symbolCount.join(',')}`;
							if (inn && !innIsValid(inn)) return 'profile-field-inn-invalid';

							return true;
						};

						fieldProps.prepareProps = (form: any, props: any) => {
							props.rules = [isInnCorrect];

							return {
								...props,
							};
						};
					}

					return fieldProps;
				} else {
					return {
						...field,
					};
				}
			});

			this.setState({
				...photos,
				scheme: schemeMapper(scheme, profile?.legalType, profile?.verification?.status === 'verified'),
				legalType: defaultValues.legalType as LegalTypeEnum,
			});
		}
	}

	public async componentDidMount() {
		setTimeout(() => {
			this.applyDefaults();
		}, 100);
	}

	private async onSave() {
		const {
			state: { photo, avatar, loading, agreeTerms, legalType },
			builder,
		} = this;

		const { sessionToken, setProfile } = this.props;

		const errorBucket: boolean | ErrorReport[] = this.validateForm();

		if (errorBucket !== true && typeof errorBucket !== 'boolean') {
			errorBucket.forEach((report: ErrorReport) => {
				const warnMessage = this.getLocalizedMessage(report.fieldName) + ': ' + report.message;

				toast.warn(warnMessage, {
					position: toast.POSITION.BOTTOM_CENTER,
					autoClose: 5000,
				});
			});

			return;
		}

		if (setProfile && sessionToken && builder && !loading && agreeTerms && errorBucket === true) {
			let params: any = _.clone(builder.state.form);
			params.companyName = ''; // auto loading from INN

			params = { ...params, photo, avatar, legalType };

			try {
				if (params.inn) {
					const suggests = await api.suggestsCompany(params.inn);
					const [company] = suggests;

					if (company.inn === params.inn) {
						params.companyName = company.name;
					}
				}
			} catch (error) {
				params.companyName = '--------';
			}

			// Updating locally with setter action
			this.setState({ loading: true });

			try {
				const userProfile = this.props.profile;

				const paramsToUpdate = Object.keys(params).reduce((acc: any, key: string) => {
					//@ts-ignore
					if (userProfile && params[key] !== userProfile[key]) {
						acc[key] = params[key];
					}
					return acc;
				}, {});

				const profile = await api.userPatchProfile(paramsToUpdate);

				setProfile(profile);

				toast.success(this.getLocalizedMessage('profile-alert-updated'), {
					position: toast.POSITION.BOTTOM_CENTER,
				});

				localStorage.setItem('agreeTerms', JSON.stringify(agreeTerms));

				this.props.toggleEditable(false);
			} catch (error) {
				console.log(error);
			}

			if (sessionToken) {
				await store.dispatch(UserActions.getProfile(sessionToken, true) as any);
			}

			this.setState({ loading: false });
		}
	}

	private async uploadDropperAvatar(accepted: File) {
		const { sessionToken, updateAvatar } = this.props;

		if (sessionToken && updateAvatar) {
			try {
				const { avatar, photo } = await api.userUploadAvatar(accepted);

				if (avatar && photo) {
					const updated = { photo, avatar };

					this.setState(updated);
					updateAvatar(updated);

					toast.success(this.getLocalizedMessage('profile-alert-photo-updated'), {
						position: toast.POSITION.BOTTOM_CENTER,
					});
				} else {
					toast.error(this.getLocalizedMessage('profile-alert-photo-updated-failed'), {
						position: toast.POSITION.BOTTOM_CENTER,
						autoClose: 5000,
					});
				}
			} catch (error) {
				console.log(error);

				toast.error(this.getLocalizedMessage('profile-alert-photo-updated-failed'), {
					position: toast.POSITION.BOTTOM_CENTER,
					autoClose: 5000,
				});
			}
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			const message = locale.getMessage(id)?.value?.toString();

			return message || '';
		} else {
			return '';
		}
	}

	private validateForm() {
		const {
			builder,
			state: { agreeTerms },
		} = this;

		if (!builder) return false;

		const builderReport = builder.isValid();

		if (builderReport !== true) {
			return builderReport;
		}

		if (!agreeTerms) {
			return [{ message: 'profile-validation-agree-terms', fieldName: '' }];
		}

		return true;
	}

	private onUpdate(name: string, value: any) {
		const { form, scheme = [] } = this.state;

		let mappedSchema = [...scheme];
		if (name === 'legalType') {
			if (value === LegalTypeEnum.Np) {
				form.legalType = LegalTypeEnum.Company;
			}
			mappedSchema = schemeMapper(scheme, value);
		}

		this.setState({
			scheme: mappedSchema,
			form: {
				...form,
				[name]: value,
			},
		});
	}

	public render() {
		const { avatar, scheme, loading, agreeTerms, validationReport } = this.state;
		const loadingClass = loading ? 'loading' : '';
		const builderProps: any = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		const onChangeAgree = ({ target: { checked } }: any) => {
			this.setState({
				agreeTerms: checked,
			});
		};

		return (
			<div className='profile form base'>
				<div className='photos'>
					<div className='avatar'>
						<FileUpload
							allowTitleClick
							className='drop-zone-avatar'
							dropZoneClassName='drop-zone-container'
							thumbsClassName='drop-zone-thumbs'
							thumbClassName='drop-zone-thumb'
							titleClassName='message drop-zone-title'
							title={<Localized id='profile-action-change-photo' />}
							uploaded={[{ preview: avatar ? avatar : profileImage }]}
							onUpload={this.uploadDropperAvatar}
						/>
					</div>
				</div>

				<div className='common'>
					<Builder {...builderProps} />

					<Checkbox onChange={onChangeAgree} checked={agreeTerms}>
						<Localized id='profile-agree-terms' />{' '}
						<a target='_blank' href='/privacy-policy'>
							<Localized id='profile-privacy-policy' />
						</a>
					</Checkbox>

					<ValidationReporter reportList={validationReport} />

					<div className='actions'>
						<button
							disabled={!this.state.agreeTerms || !!validationReport.length}
							className={`action ${loadingClass}`}
							onClick={this.onSave}
						>
							{loading ? (
								<>
									<Spinner type='RevolvingDot' color='#1fb0e2' height={22} width={22} />

									<Localized id='profile-action-save-loading' />
								</>
							) : (
								<Localized id='profile-action-save' />
							)}
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ user, common }: RootState) => ({
	locales: common.bundlesLocales,
	profile: user.profile,
	sessionToken: user.sessionToken,
	verifyStatus: user.verifyStatus,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			getProfile: UserActions.getProfile,
			setProfile: UserActions.setProfile,
			updateAvatar: UserActions.updateAvatar,
			dispatch,
		},
		dispatch,
	);

export default connect<Personal.StateProps, Personal.DispatchProps, Personal.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(Personal);

namespace Personal {
	export type Props = StateProps & DispatchProps & ExternalProps;

	export interface LegalTypeChoice {
		value: LegalTypeEnum;
		label: string;
	}

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
		profile?: UserProfile;
		sessionToken?: string;
		verifyStatus?: UserVerifyStatus;
		dispatch?: Dispatch;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		getProfile?: (sessionToken: string) => void;
		setProfile?: (profile: UserProfile | UserApiModel.UserProfile) => void;
		updateAvatar?: (params: UserActions.Payload.Avatar) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		toggleEditable: (editable: boolean) => void;
	}

	// Main component state
	export interface State {
		scheme?: SchemeProps.Form;
		legalType: LegalTypeEnum;
		avatar?: string;
		photo?: string;
		inn?: string;
		form: { [name: string]: any };
		agreeTerms: boolean;
		loading: boolean;
		validationReport: string[];
	}
}
