import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Localized } from '@fluent/react';

import { getCandidateDetail } from 'app/actions/candidates';
import { CandidateData, CandidateStatus } from './types/CandidateData';
import Actions from './Actions';
import MultiselectOperations from './MultiselectOperations';
import Switch from 'app/sharedComponents/Switch';
import { Options } from './Common';
import { OfferCard } from './OfferCard';
import { makePlace } from 'app/utils/transport';
import { BidInfoContainer } from './StyledCandidatesComponents';
import { Stats, StatsValue } from 'app/commonPatterns/Stats';
import Scrollbars from 'react-custom-scrollbars';
import Modal from 'app/sharedComponents/Modal';
import { ButtonTypes as Button } from 'app/sharedComponents/Button';
import { BigCar as IconDriver } from 'app/components/Icons';
import { bidDetails as bidDetailsSelector } from 'app/selectors/candidates';
import { useMyTransportContext } from '../Candidates';
import {
	Container,
	PaddingContainer,
	Header,
	Marker,
	ActionBlock,
	Date,
	WhiteButtonFullwidth,
	Divider,
	CloseIconContainer,
} from './BidInfoStyledComponents';

import { Close as CloseIcon } from '../../../components/Icons';

const ICON_LOADING = require('assets/icons/i-shipment-small.png');
const ICON_TO = require('assets/icons/i-landing-small.png');
const ICON_REPLIES = require('assets/icons/i-reply.png');
const ICON_APPROVES = require('assets/icons/i-approve.png');
const ICON_VIEWS = require('assets/icons/i-view.png');
const ICON_DRIVER_ROUTE = require('assets/icons/svg/ic_driver.svg');

/**
 * BidInfo
 * В проп data необходимо передать данные заявки с массивом кандидатов, свойства которого описаны в CandidateData
 * onDriverMessageClick - событие клика на иконку СООБЩЕНИЯ в карточке кандидата в который передаются данные кандидата и событие клика
 * onDriverRouteClick - событие клика на иконку МАРШРУТА в карточке кандидата в который передаются данные кандидата и событие клика
 * onDriverTrackClick - событие клика на иконку ТРЕКА в карточке кандидата в который передаются данные кандидата и событие клика
 */
export type BidInfoProps = {
	status?: CandidateStatus | undefined;
	onConfirm?: (bid: string, offers: string | string[]) => void;
	onDecline?: (bid: string, offers: string | string[]) => void;
	onDriverMessageClick?: (data: CandidateData, event: MouseEvent) => any;
	onDriverRouteClick?: (data: CandidateData, event: MouseEvent) => any;
	onDriverTrackClick?: (data: CandidateData, event: MouseEvent) => any;
	onAllApproveClick?: (event: MouseEvent) => any;
	onAllWriteClick?: (event: MouseEvent) => any;
	onShowRoute?: (event: MouseEvent) => void;
	bidId?: string | null;
	selectedTransport?: any;
	onOfferExpand?: () => void;
	choosenTransport?: any;
	selectedOffers?: any;
	setSelectedOffers?: Function;
	onOfferHover?: Function;
	onClose?: Function;
};

export const BidInfo: React.FC<BidInfoProps> = ({
	status,
	onConfirm,
	onDecline,
	onClose,
	// onDriverMessageClick,
	// onDriverRouteClick,
	// onDriverTrackClick,
	// onAllApproveClick,
	// onAllWriteClick,
	// onShowRoute = () => {},
	bidId,
	selectedTransport,
	onOfferExpand,
	choosenTransport = {},
	selectedOffers: selection = [],
	setSelectedOffers: setSelection = () => {},
	onOfferHover = () => {},
}) => {
	//@ts-ignore
	const { showBidDetails, onShowRoute } = useMyTransportContext();
	const dispatch = useDispatch();

	const bidoffer: any = useSelector(bidDetailsSelector);

	const [showDriverFirst, setShowDriversFirst] = useState(false);

	const modalConfirmAllState = useState(false);
	const modalDriverAcceptState = useState<any>(false);
	const modalDriverDeclineState = useState<any>(false);
	const modalConfirmSelectedState = useState(false);
	const modalSize = {
		width: 500,
		height: 300,
	};

	useEffect(() => {
		bidId && dispatch(getCandidateDetail(bidId));
	}, [bidId]);

	const handleOfferHover = (transportId: string) => () => onOfferHover(transportId);

	const filterByStatus = (offers: any[]) => offers.filter((offer) => (status ? status === offer.status : true));
	const clearSelection = () => setSelection('clear');
	const handleSelect = (offerId: string) => {
		setSelection(offerId);
	};
	const handleAcceptSelected = () => {
		const offers = bidoffer.offers.filter((offer: any) => selection.includes(offer.id) && offer.status === 'open');
		const offerIds = offers.map((offer: any) => offer.id);
		onConfirm?.(bidoffer.bid.id, offerIds);
		modalConfirmSelectedState[1](false);
	};
	const handleAcceptAll = () => {
		const offers = bidoffer.offers.filter((offer: any) => offer.status === 'open');
		const offerIds = offers.map((offer: any) => offer.id);
		onConfirm?.(bidoffer.bid.id, offerIds);
	};
	const handleShowDetails = () => {
		showBidDetails(bidoffer.bid.id);
	};
	const hasSelectedOpenedOffers = bidoffer?.offers.some(
		(offer: any) => selection.includes(offer.id) && offer.status === 'open',
	);
	const multiselectActions = [
		hasSelectedOpenedOffers && {
			icon: require('assets/icons/i-approve-multiselect.png'),
			title: 'Подтвердить всех',
			handler: () => {
				modalConfirmSelectedState[1](true);
			},
		},
		{
			icon: require('assets/icons/i-route-multiselect.png'),
			title: 'Показать маршрут',
			handler: () => true,
		},
	].filter(Boolean);

	const handleShowRouteClick = () => {
		if (bidoffer) {
			const {
				bid: { id, direction },
			} = bidoffer;
			onShowRoute({ ...direction, id });
		}
	};

	const confirmSelectedOffer = () => {
		const state = modalDriverAcceptState[0];
		if (!state) return;
		const [bidId, offerId] = state;
		onConfirm?.(bidId, offerId);
		modalDriverAcceptState[1](false);
	};

	const declineSelectedOffer = () => {
		const state = modalDriverDeclineState[0];
		if (!state) return;
		const [bidId, offerId] = state;
		onDecline?.(bidId, offerId);
		modalDriverDeclineState[1](false);
	};

	const handleBidClose = () => {
		onClose && onClose();
	};

	if (!bidoffer) return null;
	return (
		<BidInfoContainer>
			<Modal
				{...modalSize}
				value={modalConfirmSelectedState}
				actions={[
					<Button.Default onClick={handleAcceptSelected} key='confirm-modal-yes'>
						Да
					</Button.Default>,
					<Button.Primary onClick={() => modalConfirmSelectedState[1](false)} key='confirm-modal-no'>
						НЕТ
					</Button.Primary>,
				]}
			>
				<img src={ICON_DRIVER_ROUTE} /> <br />
				<div>Вы уверены что хотите подтвердить выбранных водителей ?</div>
			</Modal>

			<Modal
				{...modalSize}
				value={modalConfirmAllState}
				actions={[
					<Button.Default onClick={handleAcceptAll} key='confirm-modal-yes'>
						Да
					</Button.Default>,
					<Button.Primary onClick={() => modalConfirmAllState[1](false)} key='confirm-modal-no'>
						НЕТ
					</Button.Primary>,
				]}
			>
				<img src={ICON_DRIVER_ROUTE} /> <br />
				<div>Вы уверены что хотите подтвердить всех водителей ?</div>
			</Modal>

			<Modal
				{...modalSize}
				value={modalDriverAcceptState}
				actions={[
					<Button.Default onClick={confirmSelectedOffer} key='button-modal-confirm-yes'>
						<Localized id='yes' />
					</Button.Default>,
					<Button.Primary onClick={() => modalDriverAcceptState[1](false)} key='button-modal-confirm-no'>
						<Localized id='not' />
					</Button.Primary>,
				]}
			>
				<div>
					<IconDriver />
				</div>
				<div>Подтвердить этот оффер?</div>
			</Modal>
			<Modal
				{...modalSize}
				value={modalDriverDeclineState}
				actions={[
					<Button.Default onClick={declineSelectedOffer} key='button-modal-confirm-yes'>
						<Localized id='yes' />
					</Button.Default>,
					<Button.Primary onClick={() => modalDriverDeclineState[1](false)} key='button-modal-confirm-no'>
						<Localized id='not' />
					</Button.Primary>,
				]}
			>
				<div>
					<IconDriver />
				</div>
				<div>Отклонить этот оффер?</div>
			</Modal>
			<Container>
				<Scrollbars>
					<PaddingContainer>
						<CloseIconContainer>
							<CloseIcon color='#B0B1BF' onClick={handleBidClose} />
						</CloseIconContainer>
						<Header>{`${bidoffer.bid.cargo?.type?.name}, ${bidoffer.bid.cargo.weight}/${bidoffer.bid.weightFree}т`}</Header>
						<Marker icon={ICON_LOADING}>{makePlace(bidoffer.bid.direction?.from)}</Marker>
						<Marker icon={ICON_TO}>{makePlace(bidoffer.bid.direction?.to)}</Marker>
						<Date>{`${bidoffer.bid.deliveryTime?.from} → ${bidoffer.bid.deliveryTime?.to}`}</Date>
						<Stats>
							<StatsValue title='Просмотры' icon={ICON_VIEWS}>
								{`${bidoffer.bid.status?.views}/${bidoffer.bid.status?.previews}`}
							</StatsValue>
							<StatsValue title='Ответы' icon={ICON_REPLIES}>
								{bidoffer.bid.status?.requests}
							</StatsValue>
							<StatsValue title='В работе' icon={ICON_APPROVES}>
								{bidoffer.bid.status.confirmed}
							</StatsValue>
						</Stats>
						<WhiteButtonFullwidth onClick={handleShowDetails}>Посмотреть заявку полностью</WhiteButtonFullwidth>
						<Divider />
						<Options>
							<tbody>
								<tr>
									<td>Показывать водителей</td>
									<td>
										<Switch checked={showDriverFirst} onChange={setShowDriversFirst} />
									</td>
								</tr>
							</tbody>
						</Options>
						{filterByStatus(bidoffer.offers).map((offer: any) => (
							<OfferCard
								key={offer.id}
								data={offer}
								choosen={choosenTransport[offer?.transports?.[0]?.transport.uuid]}
								onExpand={onOfferExpand}
								selected={selectedTransport[offer?.transports?.[0]?.transport.uuid]}
								checked={selection?.includes(offer.id)}
								showDriverFirst={showDriverFirst}
								onMouseLeave={handleOfferHover(offer?.transports?.[0]?.transport.uuid)}
								onMouseEnter={handleOfferHover(offer?.transports?.[0]?.transport.uuid)}
								onDeclineClick={() => modalDriverDeclineState[1]([bidoffer.bid.id, offer.id])}
								onAcceptClick={() => modalDriverAcceptState[1]([bidoffer.bid.id, offer.id])}
								onSelectClick={() => handleSelect?.(offer.id)}
								onActionRouteClick={handleShowRouteClick}
								noSelect={offer.status === 'confirmed'}
							/>
						))}
					</PaddingContainer>
				</Scrollbars>
				<ActionBlock>
					{!selection.length ? (
						<Actions
							actions={[
								{
									icon: require('assets/icons/i-approve-big.png'),
									title: 'Подтвердить всех',
									handler: () => {
										modalConfirmAllState[1](true);
									},
								},
								// {
								//     icon: require('assets/icons/i-message.png'),
								//     title: 'Написать всем',
								//     handler: onAllWriteClick,
								// },
								{
									icon: require('assets/icons/i-route.png'),
									title: 'Показать маршрут',
									handler: handleShowRouteClick,
								},
							]}
						/>
					) : (
						<MultiselectOperations
							actions={multiselectActions}
							selectedCount={selection.length}
							onCloseClick={clearSelection}
						/>
					)}
				</ActionBlock>
			</Container>
		</BidInfoContainer>
	);
};
