import { ADD_SUBSCRIPTION, REMOVE_SUBSCRIPTION } from 'app/actions/subscriptions';
import { Action, handleActions } from 'redux-actions';

type State = [];

export const subscriptionsReducer = handleActions<State, any>(
	{
		// @ts-ignore
		[ADD_SUBSCRIPTION]: (state, action: Action<any>) => (action.payload ? [...state, action.payload] : state),
		// @ts-ignore
		[REMOVE_SUBSCRIPTION]: (state, action: Action<any>) =>
			action.payload ? state.filter((item: any) => item !== action.payload) : state,
	},
	[],
);
