import React from 'react';

export const Document: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='18' height='24' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M5.00006 6H7.00006M5.00006 10L13.0001 10M5.00006 14L11.0001 14M15.0001 23L3.00006 23C1.89549 23 1.00006 22.1046 1.00006 21L1.00006 3C1.00006 1.89543 1.89549 1 3.00006 1L15.0001 1C16.1046 1 17.0001 1.89543 17.0001 3L17.0001 21C17.0001 22.1046 16.1046 23 15.0001 23Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);

export default Document;
