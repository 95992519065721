import * as React from 'react';

//other deps
import { Localized } from '@fluent/react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//components
import LoadingIndicator from 'app/sharedComponents/LoadingIndicator';
import InsuranceItem from './insuranceItem';

//types
import { DriverActions } from 'app/actions';
import { DriverCar, Insurance } from 'app/models';

//----------------------------------------------------------
// Insurances
//----------------------------------------------------------
class Insurances extends React.Component<Insurances.Props, Insurances.State> {
	public state: Insurances.State = {};

	constructor(props: Insurances.Props) {
		super(props);

		this.addDraft = this.addDraft.bind(this);
	}

	private async addDraft() {
		const {
			car: { insurances, uuid },
			addInsurance,
		} = this.props;

		if (!insurances.draft && addInsurance) {
			addInsurance({
				transportId: uuid,
				data: {
					uuid: 'draft',
				},
			});
		}
	}

	public render() {
		const {
			car: { insurances, uuid },
		} = this.props;

		const insurancesList = Object.keys(insurances).map((key) => (
			<InsuranceItem insurance={insurances[key]} key={insurances[key].uuid} transportId={uuid} />
		));

		return (
			<div className='insurances forms list'>
				<div className='header'>
					<Localized id='transport-insurances-title' />
				</div>

				{insurances.loading && <LoadingIndicator align='left' />}

				{!insurances.loading && <div className='items'>{insurancesList}</div>}

				{insurances.draft ? null : (
					<div className='action new' onClick={this.addDraft}>
						<Localized id='transport-insurances-action-add' />
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			addInsurance: DriverActions.addInsurance,
		},
		dispatch,
	);

export default connect<Insurances.StateProps, Insurances.DispatchProps, Insurances.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(Insurances);

namespace Insurances {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {}

	// Dispatch properties function from redux
	export interface DispatchProps {
		addInsurance?: (props: DriverActions.Payload.Docs<Insurance>) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		car: DriverCar;
	}

	// Main component state
	export interface State {}
}
