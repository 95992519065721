import * as React from 'react';

//other deps
import _ from 'lodash';
import { toast } from 'react-toastify';
import { Localized } from '@fluent/react';
import Spinner from 'react-loader-spinner';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//api
import * as api from 'app/api';

//components
import Builder from 'app/containers/Builder';
import * as Icons from 'app/components/Icons';
import FileUpload from 'app/sharedComponents/FileUpload';

//schemes
import * as schemes from '../../schemes';

//types
import { RootState } from 'app/reducers';
import { DriverActions } from 'app/actions';
import { BidApiModel } from 'app/api/types';
import { DriverCar, UserProfile, Transport, Certificate } from 'app/models';
import { SchemeProps } from 'app/containers/Builder/props';

export interface FileWithPreview extends File {}

//helpers
import diffArrays from 'app/utils/array-diff';
import validateObject from 'app/utils/form-validator';
import { getTransportBrands, getTransportTypes } from '../../../common/common.helpers';

//images
const carImage = require('../../../../../../../../../assets/icons/car_avatar.png');
const profileImage = require('../../../../../../../../../assets/icons/profile.png');

//constants
const LABELS_TO_FILTER = ['transports-car-height', 'transports-car-length', 'transports-car-gross', 'Габариты'];

//----------------------------------------------------------
// BaseCarForm
//----------------------------------------------------------
class BaseCarForm extends React.Component<BaseCarForm.Props, BaseCarForm.State> {
	public builder: Builder | null = null;

	public state: BaseCarForm.State = {
		loading: false,
		form: {},
		validationReport: [],
	};

	constructor(props: BaseCarForm.Props) {
		super(props);

		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.onDropPhoto = this.onDropPhoto.bind(this);
		this.getPhotos = this.getPhotos.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.isValid = this.isValid.bind(this);
	}

	public async componentDidMount() {
		const { transportTypes, brands = [], car } = this.props;
		const { builder, state } = this;

		if (builder && transportTypes && !state.scheme) {
			const scheme = _.cloneDeep(schemes.base);

			const transportTypesInput = scheme[0] as any;
			const transportBrandsInput = scheme[1] as any;

			transportTypesInput.choices = getTransportTypes(transportTypes);

			if (brands) {
				transportBrandsInput.choices = getTransportBrands(brands);
			}

			const filteredScheme =
				car?.transport?.uuid === 'NEW_TRANSPORT_ID'
					? scheme.filter((item: any) => !LABELS_TO_FILTER.includes(item.label))
					: [...scheme];

			this.setState({ scheme: filteredScheme });

			await Promise.delay(50);
			this.applyDefaults();
		}

		this.validateForm(this.state.form);
	}

	public componentDidUpdate(prevProps: BaseCarForm.Props) {
		const { car } = prevProps;
		if (!_.isEqual(car, this.props.car)) {
			this.applyDefaults();
		}
	}

	private applyDefaults() {
		const { car = {} } = this.props;
		const { builder } = this;

		if (builder) {
			const { formRefs } = builder;
			//@ts-ignore
			const { transport = {} } = car;

			const allowed = ['transportType', 'brand', 'trailerIdent', 'height', 'length', 'gross', 'ident'];

			for (const key of allowed) {
				const value = (transport as any)[key];

				if (value) {
					const ref = formRefs.get(key);

					if (ref) {
						if (key === 'transportType' || key === 'brand') {
							const { name: label, uuid } = value;
							ref.updateValue({ label, value: uuid });
						} else {
							ref.updateValue(value);
						}
					}
				}
			}
		}
	}

	private validateForm(form: any) {
		const { getLocalizedMessage } = this;
		const validationReport: string[] = validateObject(form, {
			gross(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-gross-empty');
				v = parseInt(v);
				if (v < 0) return getLocalizedMessage('transports-validation-gross-less-than');
				if (v > 150) return getLocalizedMessage('transports-validation-gross-more-than');
				return true;
			},

			height(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-height-empty');
				v = parseInt(v);
				if (v < 0) return getLocalizedMessage('transports-validation-height-less-than');
				if (v > 5) return getLocalizedMessage('transports-validation-height-more-than');

				return true;
			},

			length(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-length-empty');
				v = parseInt(v);
				if (v < 0) return getLocalizedMessage('transports-validation-length-less-than');
				return true;
			},

			ident(v: any) {
				v = v.toUpperCase();
				const regexp: RegExp = /^[ABEKMHOPCTYX]\d\d\d[ABEKMHOPCTYX]{2}(RUS)?[\d]{2,3}$/g;
				if (v === '') return getLocalizedMessage('transports-validation-ident-empty');
				if (!regexp.test(v)) return getLocalizedMessage('transports-validation-ident-invalid');
				return true;
			},

			trailerIdent(v: any) {
				v = v.toUpperCase();
				const regexp: RegExp = /^[ABEKMHOPCTYX]{2}\d\d\d\d(RUS)?[\d]{2,3}$/g;
				if (v === '') return getLocalizedMessage('transports-validation-trailer-ident-empty');
				if (!regexp.test(v)) return getLocalizedMessage('transports-validation-trailer-ident-invalid');

				return true;
			},
			transportType(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-transport-type-empty');
				return true;
			},
		});

		if (diffArrays(validationReport, this.state.validationReport)) {
			this.setState({ validationReport });
		}

		return validationReport.length === 0;
	}

	private onUpdate(name: string, value: any) {
		const { form } = this.state;
		this.setState(
			{
				form: {
					...form,
					[name]: value,
				},
			},
			() => {
				this.validateForm(this.state.form);
			},
		);
	}

	private isValid() {
		const { form } = this.state;

		return form?.trailerIdent?.length && form?.ident?.length && form?.height && form?.length && form?.gross;
	}
	isValidForSave = () => {
		const { form } = this.state;
		return form?.transportType?.length && form?.trailerIdent?.length && form?.ident?.length;
	};

	private onSave = () => {
		const { form, loading } = this.state;
		const { builder } = this;
		const {
			updateDriverTransport,
			setCertificates,

			car,
			openSendedModal,
			toggleEditable,
		} = this.props;

		if (updateDriverTransport && setCertificates && !loading) {
			const validReports = builder ? builder.isValid() : true;
			const isFormValid = validReports === true;

			if (!isFormValid) {
				if (Array.isArray(validReports)) {
					validReports.forEach((report: any) => {
						toast.warn(this.getLocalizedMessage(report.fieldName) + ': ' + report.message, {
							position: toast.POSITION.BOTTOM_CENTER,
							autoClose: 5000,
						});
					});
				}
				return;
			}

			this.setState({ loading: true });

			const _transportType = form?.transportType
				? { uuid: form?.transportType }
				: typeof car?.transport?.transportType === 'string'
				? { uuid: car?.transport?.transportType }
				: car?.transport?.transportType;

			const updated: any = {
				...form,
				brand: form?.brand ? { uuid: form.brand } : undefined,
				transportType: _transportType,
			};

			updateDriverTransport({ data: updated, transportId: car?.uuid });

			this.setState({ loading: false }, () => {
				toggleEditable(false);
				openSendedModal();
			});
		}
	};

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private async onDropPhoto(photo: File) {
		const { form } = this.state;

		const {
			updateTransportPhoto,
			sessionToken,
			car: { uuid },
		} = this.props;

		if (photo) {
			this.setState({
				form: {
					...form,
					photo,
				},
			});

			if (updateTransportPhoto && sessionToken) {
				try {
					const photos = await api.driverUploadTransportAvatar(uuid, photo);
					updateTransportPhoto({ uuid, ...photos });
				} catch (error) {
					console.error(error);
				}
			}
		}
	}

	private getPhotos() {
		const { form } = this.state;
		const { car } = this.props;

		const avatar = car?.contact?.avatar ? car.contact.avatar : profileImage;
		const transportAvatar = form.photo
			? form?.photo?.preview
			: car?.transport?.avatar
			? car?.transport?.avatar
			: carImage;

		return (
			<div className='photos'>
				<div className='car-photo'>
					<FileUpload
						allowTitleClick
						className='drop-zone-avatar'
						dropZoneClassName='drop-zone-container'
						thumbsClassName='drop-zone-thumbs'
						thumbClassName='drop-zone-thumb'
						titleClassName='message drop-zone-title'
						title={<Localized id='transports-action-change' />}
						uploaded={[{ preview: transportAvatar }]}
						onUpload={this.onDropPhoto}
					/>
				</div>
				<div className='arrow'>
					<Icons.DottedArrowUp color='#6C6F88' />
				</div>
				<div className='driver'>
					<div className={'avatar ' + (!car?.contact?.avatar ? 'empty' : '')}>
						<img src={avatar} />
					</div>
					<div className='fullname'>
						{car?.contact?.lastName} {car?.contact?.firstName[0]}.{car?.contact?.middleName[0]}.
					</div>
				</div>
			</div>
		);
	}

	public render() {
		const { scheme, loading } = this.state;
		const loadingClass = loading ? 'loading' : '';
		const { cancelAddNewTransport, car } = this.props;
		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		const isFormValid = this.isValidForSave() && !loading;
		const isNewCar = car.uuid === DriverActions.NEW_TRANSPORT_ID;
		return (
			<div className='car base form'>
				{!isNewCar && this.getPhotos()}
				<div className='common'>
					<div className='header'>
						<div className='title'>
							<Localized id='transports-base-title' />
						</div>
						<div className='subtitle'>
							<Localized id='transports-base-description' />
						</div>
					</div>

					<Builder {...builderProps} />

					<div className='actions'>
						<button disabled={!isFormValid} className={`action ${loadingClass}`} onClick={this.onSave}>
							{loading ? (
								<>
									<Spinner type='RevolvingDot' color='#1FB0E2' height={22} width={22} />

									<Localized id='profile-action-save-loading' />
								</>
							) : (
								<Localized id='transports-action-save' />
							)}
						</button>
						<button className='action cancel-button' onClick={cancelAddNewTransport}>
							<Localized id='transports-action-save-cancel' />
						</button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ user, common }: RootState) => ({
	brands: common.brands,
	locales: common.bundlesLocales,
	profile: user.profile,
	sessionToken: user.sessionToken,
	transportTypes: common.transports,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			cancelAddNewTransport: DriverActions.cancelAddNewTransport,
			setCertificates: DriverActions.setCertificates,
			updateDriverTransport: DriverActions.updateDriverTransport,
			updateTransportPhoto: DriverActions.updateTransportPhoto,
		},
		dispatch,
	);

export default connect<BaseCarForm.StateProps, BaseCarForm.DispatchProps, BaseCarForm.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(BaseCarForm);

namespace BaseCarForm {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		brands?: BidApiModel.CommonMeta[];
		locales?: FluentBundle[];
		profile?: UserProfile;
		sessionToken?: string;
		transportTypes?: { [name: string]: BidApiModel.CommonMeta };
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		cancelAddNewTransport?: () => void;
		setCertificates?: (params: DriverActions.Payload.Docs<Certificate[]>) => void;
		updateDriverTransport?: (params: DriverActions.Payload.Docs<Transport>) => void;
		updateTransportPhoto?: (params: DriverActions.Payload.UpdatePhoto) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		openSendedModal: () => void;
		toggleEditable: (editable: boolean) => void;
		car: DriverCar;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		loading: boolean;
		validationReport: string[];
	}
}
