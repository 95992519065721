import styled from 'styled-components';

export const CargoBlock = styled.div`
	display: flex;
	margin-top: 16px;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px 8px 16px;
	background-color: ${(props) => props.theme.colors.bgGrey};
	border-radius: 24px;
`;

export const OfferGridRow = styled.div`
	display: grid;
	grid-template-columns: 1fr repeat(2, 2fr) 1fr 1fr;
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	/* grid-row-gap: 5px; */
	margin-bottom: 5px;
`;

export const OfferGridCompanyRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 5fr;
	grid-template-rows: 1fr;
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	/* margin-bottom: 10px; */
`;

export const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const PaddingContainer = styled.div`
	padding-left: ${(props) => props.theme.padding.regular};
	padding-right: ${(props) => props.theme.padding.regular};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ButtonConteiner = styled.div`
	position: absolute;
	bottom: 0px;
	width: 300px;
`;

export const OffersListContainer = styled.div`
	margin-bottom: 12px;
	background-color: #fafafa;
	padding: 10px;
	border-radius: 8 px;
	padding: 10 px;
	margin-top: 10 px;
	box-shadow: 0px 0px 16px rgb(114 114 114 / 14%);
	border-radius: 16 px;
`;
