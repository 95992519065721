import * as React from 'react';

//other deps
import _ from 'lodash';
import moment from 'moment';
import Posed from 'react-pose';
import { toast } from 'react-toastify';
import { Localized } from '@fluent/react';
import Spinner from 'react-loader-spinner';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { store } from 'src/main';

//api
import { driverTransportInsurance, driverDeleteInsurance } from 'app/api';

//schemes
import * as schemes from '../../../schemes';

//components
import Builder from 'app/containers/Builder';
import * as Icons from 'app/components/Icons';
import ValidationReporter from 'app/components/ValidationReporter';

ValidationReporter;

const Container = Posed.div({
	opened: {
		height: 'auto',
		transition: {
			ease: 'easeIn',
			duration: 300,
		},
	},
	closed: {
		height: 64,
		transition: {
			ease: 'easeIn',
			duration: 300,
		},
	},
});

//types
import { Insurance } from 'app/models';
import { RootState } from 'app/reducers';
import { DriverActions, UserActions } from 'app/actions';
import { SchemeProps } from 'app/containers/Builder/props';

//constants
import { VERIFIED } from '../docs.page';

//----------------------------------------------------------
// InsuranceItem
//----------------------------------------------------------
class InsuranceItem extends React.Component<InsuranceItem.Props, InsuranceItem.State> {
	private builder: Builder | null = null;

	public state: InsuranceItem.State = {
		loading: false,
		editable: true,
		opened: true,
		form: {},
		validationReport: [],
	};

	constructor(props: InsuranceItem.Props) {
		super(props);

		this.initializeBuilderWithProps = this.initializeBuilderWithProps.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.getPreview = this.getPreview.bind(this);
		this.getHeader = this.getHeader.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.isValid = this.isValid.bind(this);
		this.getForm = this.getForm.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	public async componentDidMount() {
		if (this.isValid()) {
			this.setState({
				editable: false,
			});
		} else {
			this.initializeBuilderWithProps();
		}
	}

	private async initializeBuilderWithProps() {
		if (!this.state.scheme) {
			const scheme = _.cloneDeep(schemes.insurance);
			this.setState({ scheme });
			await Promise.delay(50);
		}

		this.applyDefaults();
	}

	private applyDefaults() {
		const { insurance } = this.props;
		const { builder } = this;

		if (builder) {
			const { formRefs } = builder;

			Object.keys(insurance).forEach((key) => {
				const ref = formRefs.get(key);
				const value = (insurance as any)[key];

				if (ref && value) {
					ref.updateValue(value);
				}
			});
		}
	}

	private isValid() {
		const { insurance } = this.props;

		return (
			insurance.expiredAt &&
			insurance.expiredAt.length > 0 &&
			insurance.issuedAt &&
			insurance.issuedAt.length > 0 &&
			insurance.number &&
			insurance.number.length > 0 &&
			insurance.mainPage
		);
	}

	private async onUpdate(name: string, value: any) {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				[name]: value,
			},
		});
	}

	private async onSave() {
		const { form, loading } = this.state;

		const {
			updateTransportInsurance,
			deleteTransportDocument,
			addInsurance,

			sessionToken,
			transportId,
			insurance,
		} = this.props;

		const isDraft = insurance.uuid === 'draft';

		if (updateTransportInsurance && deleteTransportDocument && sessionToken && addInsurance && !loading) {
			this.setState({ loading: true });

			try {
				const { builder } = this;

				const params: any = {
					...form,
					...(isDraft ? {} : { uuid: insurance.uuid }),
				};

				if (builder) {
					builder.isValid();
				}

				const updated = await driverTransportInsurance(
					transportId,
					params,
					isDraft ? 'post' : 'patch', // send post if draft
				);

				if (isDraft) {
					addInsurance({
						transportId,
						data: updated,
					});

					deleteTransportDocument({
						type: 'insurances',
						transportId,
						id: 'draft',
					});
				} else {
					updateTransportInsurance({
						transportId,
						data: updated,
					});

					await Promise.delay(70);

					if (this.isValid()) {
						this.setState({
							editable: false,
						});
					}
				}

				// updating user state
				if (sessionToken) await store.dispatch(UserActions.getProfile(sessionToken, true) as any);

				toast.success('Сохранено', {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			} catch (error) {
				console.error(error);
			}

			this.setState({ loading: false });
		}
	}

	private outputModeratorMessages(builder?: Builder | null) {
		const { insurance } = this.props;
		if (builder && insurance.verification) {
			builder.outputOperatorCommentary(insurance.verification);
		}
	}

	private getForm() {
		const { scheme, loading } = this.state;
		const loadingClass = loading ? 'loading' : '';

		const builderProps = {
			getRef: (node: Builder | null) => {
				this.builder = node;
				this.outputModeratorMessages(node);
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		return (
			<div className='form'>
				<Builder {...builderProps} />

				<div className={`action ${loadingClass}`} onClick={this.onSave}>
					{loading ? (
						<>
							<Spinner type='RevolvingDot' color='#FFFFFF' height={22} width={22} />
							<Localized id='profile-action-save-loading' />
						</>
					) : (
						<Localized id='transports-action-save' />
					)}
				</div>
			</div>
		);
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private getPreview() {
		const { insurance } = this.props;

		return (
			<div className='preview'>
				<div className='field'>
					<div className='title'>
						<Localized id='transports-insurance-number' />
					</div>
					<div className='text'>{insurance.number}</div>
				</div>
				<div className='field'>
					<div className='title'>
						<Localized id='transports-insurance-expired-at' />
					</div>
					<div className='text'>{moment(insurance.expiredAt).format('DD MMMM YYYY')}</div>
				</div>
				<div className='field photo'>
					<div className='title'>
						<Localized id='transports-insurance-main-page' />
					</div>
					<div className='image'>
						<img src={insurance.mainPage} alt='' />
					</div>
				</div>
			</div>
		);
	}

	private getHeader() {
		const { editable } = this.state;
		const { deleteTransportDocument, sessionToken, transportId, insurance } = this.props;

		const isVerified = insurance && insurance.verification && insurance.verification.status === VERIFIED;

		const { number, uuid } = insurance;

		const header = number ? number : null;
		const isDraft = uuid === 'draft';

		const toggleOpened = () => {};

		const toggleEditable = async () => {
			this.setState({ editable: true });

			await Promise.delay(250);
			this.initializeBuilderWithProps();
		};

		const deleteInsurance = async () => {
			if (sessionToken && deleteTransportDocument) {
				try {
					if (uuid !== 'draft') {
						await driverDeleteInsurance(transportId, insurance);
					}

					deleteTransportDocument({
						type: 'insurances',
						transportId,
						id: uuid,
					});
				} catch (error) {
					console.error(error);
				}
			}
		};

		return (
			<div className='titlebar' onClick={toggleOpened}>
				<div className='titles'>
					<div className='header'>
						<Localized id='transports-insurance-osago' />:{header}
					</div>
				</div>
				<div className='actions'>
					{!isDraft ? null : (
						<div className='draft'>
							<Localized id='transports-insurance-draft' />
						</div>
					)}

					{!editable && !isVerified && (
						<div className='edit' onClick={toggleEditable}>
							<Icons.Edit color='rgba(0,0,0, 0.7)' />
						</div>
					)}

					<div className='edit' onClick={deleteInsurance}>
						<Icons.Delete color='rgba(0,0,0, 0.7)' />
					</div>
				</div>
			</div>
		);
	}

	public render() {
		const { editable, opened } = this.state;
		const { insurance } = this.props;

		const pose = opened ? 'opened' : 'closed';

		const header = this.getHeader();
		const isVerified = insurance && insurance.verification && insurance.verification.status === VERIFIED;
		const content = editable && !isVerified ? this.getForm() : this.getPreview();

		const itemProps = {
			className: 'insurance item ' + pose,
			withParent: false,
			pose,
		};

		return (
			<Container {...itemProps}>
				{header}
				{content}
			</Container>
		);
	}
}

const mapStateToProps = ({ user, common }: RootState) => ({
	locales: common.bundlesLocales,
	sessionToken: user.sessionToken,
	trailerTypes: common.trailers,
	transportTypes: common.transports,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateTransportInsurance: DriverActions.updateTransportInsurance,
			deleteTransportDocument: DriverActions.deleteTransportDocument,
			addInsurance: DriverActions.addInsurance,
		},
		dispatch,
	);

export default connect<InsuranceItem.StateProps, InsuranceItem.DispatchProps, InsuranceItem.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(InsuranceItem);

export namespace InsuranceItem {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updateTransportInsurance?: (insurance: DriverActions.Payload.Docs<Insurance>) => void;
		deleteTransportDocument?: (params: DriverActions.Payload.DeleteDoc) => void;
		addInsurance?: (params: DriverActions.Payload.Docs<Insurance>) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		insurance: Insurance;
		transportId: string;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		editable: boolean;
		loading: boolean;
		opened: boolean;
		validationReport: string[];
	}
}
