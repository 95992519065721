import styled from 'styled-components';
import DefaultButtonStyle from './Default';

export const Dark = styled(DefaultButtonStyle)`
	color: #fff;
	background: #2e3043;

	&:disabled {
		background-color: #f8f8f8;
		color: #d0d0d4;
	}
`;

export default Dark;
