import { Range as RangeSlider, createSliderWithTooltip } from 'rc-slider';
import { SchemeProps, Field } from '../props';
import * as React from 'react';

const RangeSliderTip = createSliderWithTooltip(RangeSlider);

export class Range extends React.Component<Range.Props, Range.State> implements Field.Hooks<SchemeProps.RangeValue> {
	private sliderRef: any = null;

	public state: Range.State = {
		min: 0,
		max: 0,
	};

	constructor(props: Range.Props) {
		super(props);

		this.onAfterChange = this.onAfterChange.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.reset = this.reset.bind(this);

		const { field, onUpdate } = props;

		// Default range is min/max params
		let ranges = { min: field.min, max: field.max };

		// or preset default values
		if (typeof field.defaultValue === 'object') {
			ranges = { ...field.defaultValue };
		}

		this.state = ranges;

		if (field.presetDefaultValue) {
			onUpdate(field.name, ranges);
		}
	}

	public async reset() {
		const { defaultValue, min, max } = this.props.field;
		const ranges = defaultValue ? { ...defaultValue } : { min, max };

		this.updateValue(ranges);
	}

	public async updateValue(value: SchemeProps.RangeValue) {
		await this.setState(value);
	}

	private async onAfterChange() {
		const {
			onUpdate,
			field: { name },
		} = this.props;
		const { min, max } = this.state;
		onUpdate(name, { min, max });
	}

	private async onChange(value: number[]) {
		const [min, max] = value;

		await this.setState({ min, max });
	}

	public render() {
		const { hidden, step, min, max } = this.props.field;

		return hidden ? null : (
			<div className={`form-item range`}>
				<RangeSliderTip
					onAfterChange={this.onAfterChange}
					defaultValue={[min, max]}
					onChange={this.onChange}
					step={step}
					value={[this.state.min, this.state.max]}
					max={max}
					min={min}
				/>
				<div className='values'>
					<div className='min'>{min}</div>
					<div className='max'>{max}</div>
				</div>
			</div>
		);
	}
}

export namespace Range {
	export interface State {
		min: number;
		max: number;
	}

	export interface Props extends Field.ComponentProps<any, SchemeProps.Range> {
		// @default from base
	}
}
