import { push } from 'connected-react-router';
import { UserApiModel } from 'app/api/types';
import { createAction } from 'redux-actions';
import { CommonActions } from './common';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { jivoSetUser } from '../utils';

import {
	UserSettings,
	UserProfile,
	UserContact,
	SidebarType,
	Passport,
	DriverLicence,
	RoleEnum,
	BankAccount,
	UserCertificate,
} from 'app/models';

import {
	userGetContacts,
	userGetSession,
	userDeleteContact,
	userUpdateContact,
	userCreateContact,
	userGetPassport,
	userGetDriverLicence,
	userGetBankAccount,
	userGetCertificates,
	userDeleteCertificate,
} from 'app/api';

export namespace UserActions {
	export namespace Payload {
		export type Token = string;

		export interface Avatar {
			avatar: string;
			photo: string;
		}

		export interface Verify {
			type: 'email' | 'company';
			value: boolean;
		}

		export interface Docs {
			driverLicence?: DriverLicence;
			bankAccount?: BankAccount;
			passport?: Passport;
		}
	}

	export enum Action {
		SET_USER_AVATAR_URL = 'SET_USER_AVATAR_URL',
		SET_SESSION_TOKEN = 'SET_SESSION_TOKEN',
		SET_USER_CONTACTS = 'SET_USER_CONTACTS',
		SET_VERIFY_STATUS = 'SET_VERIFY_STATUS',
		SET_USER_SETTINGS = 'SET_USER_SETTINGS',
		SET_USER_PROFILE = 'SET_USER_PROFILE',
		USER_LOGOUT = 'USER_LOGOUT',

		DELETE_USER_CERTIFICATE = 'DELETE_USER_CERTIFICATE',
		ADD_USER_CERTIFICATE = 'ADD_USER_CERTIFICATE',
		USER_UPDATE_DOCS = 'USER_UPDATE_DOCS',

		SET_TMP_CONTACT = 'SET_TMP_CONTACT',
		CREATE_CONTACT = 'CREATE_CONTACT',
		UPDATE_CONTACT = 'UPDATE_CONTACT',
		DELETE_CONTACT = 'DELETE_CONTACT',
	}

	export const setProfile = createAction<UserProfile | UserApiModel.UserProfile>(Action.SET_USER_PROFILE);
	export const setVerifyStatus = createAction<Payload.Verify>(Action.SET_VERIFY_STATUS);
	export const updateAvatar = createAction<Payload.Avatar>(Action.SET_USER_AVATAR_URL);
	export const setAuthToken = createAction<Payload.Token>(Action.SET_SESSION_TOKEN);
	export const setContacts = createAction<UserContact[]>(Action.SET_USER_CONTACTS);
	export const setSettings = createAction<UserSettings>(Action.SET_USER_SETTINGS);
	export const updateDocs = createAction<Payload.Docs>(Action.USER_UPDATE_DOCS);

	export const _setTmpContact = createAction<UserContact | undefined>(Action.SET_TMP_CONTACT);
	export const _createContact = createAction<UserContact>(Action.CREATE_CONTACT);
	export const _updateContact = createAction<UserContact>(Action.UPDATE_CONTACT);
	export const _deleteContact = createAction<string>(Action.DELETE_CONTACT);

	export const addUserCertificate = createAction<UserCertificate>(Action.ADD_USER_CERTIFICATE);
	export const _deleteUserCertificate = createAction<string>(Action.DELETE_USER_CERTIFICATE);

	export const logout =
		() =>
		async (dispatch: Dispatch): Promise<void> => {
			await dispatch({ type: Action.USER_LOGOUT });
			await dispatch(push('/login'));
		};

	export const getProfile =
		(sessionToken: string, baseInit?: boolean) =>
		async (dispatch: Dispatch): Promise<void> => {
			try {
				const { data } = await userGetSession();

				const {
					phone,
					uuid,
					profile,
					role,
					settings,
					verification,
					currency,
					needActions,
					defaultCompany,
					defaultDispatcher,
				} = data;

				jivoSetUser(phone, profile.email, profile.firstName, profile.lastName);

				const verifyStatus: Payload.Verify = {
					value: verification.status !== 'not verified',
					type: 'email',
				};

				const userProfile: UserProfile = {
					...profile,
					currency,
					phone,
					uuid,
					role,
					needActions,
					verification,
					defaultCompany,
					defaultDispatcher,
				};

				await CommonActions.prepareShippingFare()(dispatch);

				if (role === RoleEnum.Driver) {
					const driverLicence = await userGetDriverLicence();
					const passport = await userGetPassport();

					const certificates = await (async () => {
						const certificates: { [uuid: string]: UserCertificate } = {};

						try {
							if (userProfile.inn) {
								const _certificates = await userGetCertificates();
								_certificates.forEach((certificate) => {
									certificates[certificate.uuid] = certificate;
								});
							}
						} catch (error) {
							console.error(error);
						}

						return certificates;
					})();

					userProfile.certificates = certificates;

					userProfile.driverDocs = {
						driverLicence,
						passport,
					};

					if (userProfile.inn) {
						const bankAccount = await userGetBankAccount();
						userProfile.driverDocs.bankAccount = bankAccount;
					}
				}

				await dispatch(setVerifyStatus(verifyStatus));
				await dispatch(setProfile(userProfile));
				await dispatch(setSettings(settings));

				if (!baseInit) {
					await dispatch(push('/panel/orders'));
				}
			} catch (error) {
				// @todo: handle this error
				console.error(error);

				await dispatch({ type: Action.USER_LOGOUT });
				await dispatch(push('/login'));
			}
		};

	export const getContacts =
		() =>
		async (dispatch: Dispatch): Promise<void> => {
			try {
				const contacts = await userGetContacts();
				await dispatch(setContacts(contacts));
			} catch (error) {
				// @todo: handle this error
				console.error(error);
			}
		};

	export const deleteContact = (id: string) => async (dispatch: Dispatch) => {
		try {
			await userDeleteContact(id);
			dispatch(_deleteContact(id));

			toast.success('Контакт успешно удален', { position: toast.POSITION.BOTTOM_CENTER });
		} catch (error) {
			console.log(error);
		}
	};

	export const openContactEditor = (contact: UserContact) => async (dispatch: Dispatch) => {
		await dispatch(CommonActions.toggleSidebar({ type: SidebarType.ContactEdit, value: true }) as any);
		await dispatch(_setTmpContact(contact));
	};

	export const updateContact =
		(
			id: string,
			params: {
				middleName: string;
				firstName: string;
				lastName: string;
				phone: string;
			},
			messages: {
				success: string;
				failed: string;
			},
		) =>
		async (dispatch: Dispatch) => {
			try {
				const contact = await userUpdateContact(id, params);
				dispatch(_updateContact(contact));

				toast.success(messages.success, { position: toast.POSITION.BOTTOM_CENTER });
			} catch (error) {
				console.error(error);
			}
		};

	export const createContact =
		(
			params: {
				middleName: string;
				firstName: string;
				lastName: string;
				phone: string;
			},
			messages: {
				success: string;
				failed: string;
			},
		) =>
		async (dispatch: Dispatch) => {
			try {
				const contact = await userCreateContact(params);
				dispatch(_createContact(contact));

				toast.success(messages.success, { position: toast.POSITION.BOTTOM_CENTER });
			} catch (error) {
				console.error(error);
			}
		};

	export const deleteUserCertificate = (id: string) => async (dispatch: Dispatch) => {
		try {
			await userDeleteCertificate(id);
			dispatch(_deleteUserCertificate(id));
		} catch (error) {
			console.error(error);
		}
	};
}
