import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { RootState } from 'app/reducers';
import { connect } from 'react-redux';
import * as React from 'react';

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

class NotFound extends React.Component<NotFound.Props, NotFound.State> {
	public state: NotFound.State = {};

	constructor(props: NotFound.Props) {
		super(props);
	}

	public render() {
		return (
			<div className='notfound page'>
				<h1>Страница не найдена</h1>
			</div>
		);
	}
}

export default connect<NotFound.StateProps, NotFound.DispatchProps, NotFound.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(NotFound);

namespace NotFound {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
