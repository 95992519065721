import React from 'react';
import styled from 'styled-components';
import { Localized } from '@fluent/react';
import moment from 'moment';

import { BidDetails, UserProfile, RoleEnum } from 'app/models';

import { Button } from 'app/components/Button';
import { BidStatus, BidMap, FromTo, Distance } from 'app/components/BidPreview';

const Actions = styled.div`
	margin: 16px 16px 0;
`;

const SecondaryActions = styled.div`
	margin: 16px;
`;

export const DetailsContent = ({
	bid,
	profile,
	suspendOrder,
	unsuspendOrder,
	push,
}: {
	bid: BidDetails;
	profile: UserProfile;
	suspendOrder: any;
	unsuspendOrder: any;
	push: any;
}) => {
	const { isSuspended } = bid;
	const handleOrdersSuspend = () => {
		if (isSuspended) {
			unsuspendOrder && unsuspendOrder(bid.id);
		} else {
			suspendOrder && suspendOrder(bid.id);
		}
	};

	const isProfileCustomer = profile?.role === RoleEnum.Customer;
	const canCloseAcceptance = bid?.capabilities?.includes('suspend') || bid?.capabilities?.includes('unsuspend');

	return (
		<div className='info'>
			<BidMap bid={bid} />
			<BidStatus bid={bid} />
			{canCloseAcceptance && isProfileCustomer && (
				<>
					<Actions>
						<Button onClick={() => push?.({ pathname: `/panel/transport/my/?bidId=${bid.id}` })}>
							Посмотреть водителей
						</Button>
					</Actions>
					<SecondaryActions>
						<Button onClick={handleOrdersSuspend} variant='secondary'>
							{isSuspended ? 'ПРОДОЛЖИТЬ ПРИЁМ ЗАЯВОК' : 'закрыть приём заявок'}
						</Button>
					</SecondaryActions>
				</>
			)}
			<FromTo bid={bid} />
			<Distance bid={bid} />

			{profile.isDispatcher && bid.tariff ? (
				<div className='field'>
					<div className='title'>
						<Localized id='details-label-dispatcher-tariff' />
					</div>
					<div className='text'>{bid.tariff.name}</div>
				</div>
			) : null}

			<div className='cargo field'>
				<div className='title'>
					<Localized id='details-label-cargo' />
				</div>
				<div className='text'>{bid.cargo.type.name}</div>
				<div className='addittional'>
					<Localized id='details-specific-weight' vars={{ specificWeight: bid.cargo.type.specificWeight }} />
				</div>
			</div>
			<div className='weight field'>
				<div className='title'>
					<Localized id='details-label-weight' />
				</div>

				<div className='text'>
					<Localized id='details-weight' vars={{ weight: bid.cargo.weight }} />
				</div>
			</div>
			<div className='price field'>
				<div className='title'>
					<Localized id='details-label-price' />
				</div>
				<div className='text'>{bid.cargo.price}₽/мт</div>
			</div>
			<div className='field'>
				<div className='title'>
					<Localized id='details-label-weight-free' />
				</div>

				<div className='text'>
					<Localized id='details-weight-free' vars={{ weightFree: bid.weightFree }} />
				</div>
			</div>
			<div className='cars field'>
				<div className='title'>
					<Localized id='details-label-trips-count' />
				</div>

				<div className='text'>{bid.cargo.numberOfCars}</div>
			</div>
			<div className='delivery field'>
				<div className='title'>Период доставки</div>
				<div className='text'>
					{moment(new Date(bid.deliveryTime.from)).format('DD.MM.YYYY')}
					<div className='divider'> -</div>
					{moment(new Date(bid.deliveryTime.to)).format('DD.MM.YYYY')}
				</div>
			</div>
			<div className='rate field'>
				<div className='title'>
					<Localized id='details-label-rate' />
				</div>
				<div className='text'>
					{bid.payment.type.name === '₽/т/км' ? bid.payment.pricePerKm : bid.payment.price}

					{bid.payment.type.name}
				</div>
			</div>
			<div className='iscash field'>
				<div className='title'>
					<Localized id='details-label-payment-type' />
				</div>

				<div className='text'>
					<Localized id='details-payment-type' vars={{ cash: bid.payment.isCash ? 'yes' : 'not' }} />
				</div>
			</div>
			<div className='overload field'>
				<div className='title'>
					<Localized id='details-label-overload' />
				</div>
				<div className='text'>
					<Localized id={bid.payment.isOverload ? 'yes' : 'not'} />
				</div>
			</div>
			<div className='idle field'>
				<div className='title'>
					<Localized id='details-label-keep' />
				</div>
				<div className='text'>
					{!bid.idle.price ? (
						<Localized id='details-no-payment' />
					) : (
						<Localized id='details-payment-from' vars={{ price: bid.idle.price, idleFrom: bid.idle.from }} />
					)}
				</div>
			</div>
			<div className='loss field'>
				<div className='title'>
					<Localized id='details-label-allowed-loss' />
				</div>

				<div className='text'>
					{bid.idle.loss} {!bid.idle.lossInPercent ? <Localized id='details-label-kg' /> : '%'}
				</div>
			</div>
			{profile && profile.role === RoleEnum.Driver && bid.contact ? (
				<div className='contact field'>
					<div className='title'>
						<Localized id='details-label-contact' />
					</div>

					<div className='text'>
						<div className='name'>
							{bid.contact.firstName} {bid.contact.lastName} {bid.contact.middleName}
						</div>
						<div className='phone'>{bid.contact.phone}</div>
					</div>
				</div>
			) : null}
			<div className='comment field'>
				<div className='title'>
					<Localized id='details-label-comment' />
				</div>
				<div className='text'>{!bid.comment ? <Localized id='details-no-comment' /> : bid.comment}</div>
			</div>
		</div>
	);
};
