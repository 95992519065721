import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { DocumentType } from './types';

const InfoContainer = styled.div``;

const Title = styled.p`
	font-family: UniNeue;
	font-style: normal;
	font-weight: 500;
	font-size: 16px;
	line-height: 22px;
	/* color: #000000; */
	margin-bottom: 3px;
`;
const Info = styled.p`
	font-family: UniNeue;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 19px;
	/* color: #6c6f88; */
	margin-bottom: 0px;
`;

export const PaddingContainer = styled.div`
	padding: 20px;
	box-sizing: border-box;
`;

const getType = (type: any) => {
	switch (type) {
		case 'tr_contract':
			return 'Рамочный договор';
		case 'tr_contract_add':
			// return 'Дополнительное соглашение к рамочному договору';
			return 'Доп.соглашение';
		case 'tr_report':
			return 'Акт';
		case 'tr_customer_contract_add':
			return 'Доп.соглашение';
		// return 'Дополнительное соглашение к рамочному договору между диспетчером и заказчиком';
		default:
			return 'Неизвестный документ';
	}
};

const parseDate = (date: any) => {
	return format(new Date(date), 'dd-MM-yyyy');
};

export const DocTitle = ({
	item,
	onClick = () => {},
}: {
	item: DocumentType;
	onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void | undefined;
}) => {
	const { companyName, createdAt, number, type } = item;
	return (
		<InfoContainer onClick={onClick}>
			{type && createdAt && <Title>{`${getType(type)} ${parseDate(createdAt)}`}</Title>}
			{number && companyName && <Info>{`${number}:${companyName}`}</Info>}
		</InfoContainer>
	);
};
