import React from 'react';
import styled from 'styled-components';
import { Select } from 'app/sharedComponents/Select';
import { docTypesOptions, orderingOptions } from './constants';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-right: 19px;
	align-items: center;
`;
const LeftSide = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const FilterContainer = styled.div`
	width: 200px;
	padding-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
`;

export const DocumentsFilters = ({ onChange, companies }: any) => {
	const handleTypeChange = onChange('type');
	const handleCompanyChange = onChange('company');
	const handleOrderingChange = onChange('ordering');

	return (
		<Container>
			<LeftSide>
				<FilterContainer>
					<Select
						isClearable
						options={orderingOptions}
						label='Сортировка'
						placeholder='Любой'
						onChange={(option: any) => handleOrderingChange(option?.value || '')}
					/>
				</FilterContainer>
				<FilterContainer>
					<Select
						isClearable
						options={docTypesOptions}
						label='Тип документа'
						placeholder='Любой'
						onChange={(option: any) => handleTypeChange(option?.value || '')}
					/>
				</FilterContainer>
				<FilterContainer>
					<Select
						isClearable
						options={companies}
						label='Заказчик'
						placeholder='Любой'
						onChange={(option: any) => handleCompanyChange(option?.value || '')}
					/>
				</FilterContainer>
			</LeftSide>
		</Container>
	);
};
