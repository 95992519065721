import {
	SET_TRANSPORT_ON_MAP,
	SET_TRANSPORT_ON_MAP_FILTER,
	SET_TRANSPORT_ON_MAP_BOUNDS,
} from 'app/actions/transportOnMap';
import { Action, handleActions } from 'redux-actions';

type State = {
	filter: boolean;
	data: string[];
	bounds:
		| {
				Va: {
					i: number;
					j: number;
				};
				ab: {
					i: number;
					j: number;
				};
		  }
		| {};
};

const getDefaultFilterValue = () => {
	const mapSyncFlag: string | null = localStorage.getItem('gmap-move-sync');
	return mapSyncFlag ? JSON.parse(mapSyncFlag) : false;
};

export const transportOnMapReducer = handleActions<State, any>(
	{
		[SET_TRANSPORT_ON_MAP]: (state, action: Action<string[]>) => {
			return action.payload
				? {
						...state,
						data: action.payload,
				  }
				: state;
		},
		[SET_TRANSPORT_ON_MAP_FILTER]: (state, { payload }: Action<any>) => {
			return {
				...state,
				filter: payload,
			};
		},
		[SET_TRANSPORT_ON_MAP_BOUNDS]: (state, { payload }: Action<any>) => {
			return {
				...state,
				bounds: payload,
			};
		},
	},
	{
		filter: getDefaultFilterValue(),
		data: [],
		bounds: {},
	},
);
