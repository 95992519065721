import { FluentBundle, FluentResource } from '@fluent/bundle';
import { negotiateLanguages } from '@fluent/langneg';

import AppLocalization from './AppLocalization';
import en from './en';
import ru from './ru';
import { store } from 'src/main';

export { AppLocalization };

export const MESSAGES_ALL = {
	ru,
	en,
};

export function generateLocales(userLocales: string[]) {
	const currentLocales = negotiateLanguages(userLocales, ['en', 'ru'], { defaultLocale: 'en' });

	const generateBundles = function () {
		const bundles = [];

		for (const locale of currentLocales) {
			const bundle = new FluentBundle(locale);

			for (const messagesBlock of (MESSAGES_ALL as any)[locale]) {
				const resource = new FluentResource(messagesBlock);
				bundle.addResource(resource);
			}

			bundles.push(bundle);
		}

		return bundles;
	};

	return {
		bundlesLocales: generateBundles(),
		currentLocales,
		userLocales,
	};
}

export function getLocalizedMessage(id: string): string {
	const bundlesLocales = store.getState().common.bundlesLocales;

	if (bundlesLocales) {
		const [locale] = bundlesLocales;
		const message = locale.getMessage(id)?.value;

		return message ? message.toString() : id;
	} else {
		return '';
	}
}
