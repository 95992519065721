import styled from 'styled-components';
import { ActionsContainer } from './Actions';
import WhiteButton from 'app/sharedComponents/Button/White';

export const Container = styled.section`
	position: absolute;
	width: 350px;
	height: 100%;
	background-color: #f1f2f5;
	font-family: 'UniNeue', Helvetica;
	font-weight: normal;
	box-shadow: inset 10px 0 10px rgba(103, 116, 150, 0.1);
`;

export const PaddingContainer = styled.div`
	padding: 22px 16px;
	box-sizing: border-box;
	padding-bottom: 80px;
`;

export const Header = styled.h1`
	font-size: 22px;
	line-height: 30px;
	font-weight: 300;
	color: #000000;
	letter-spacing: 0;
	margin-bottom: 16px;
`;

interface MarkerProps {
	icon?: string;
}
export const Marker = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 12px;
    line-height: 17px;
    color: #6c6f88;
    opacity: 0.7;

    &:before {
        content: '';
        display: inline-block;
        width: 12px;
        height: 12px;
        margin-right: 5px;
        vertical-align: middle;

        ${(props: MarkerProps) => {
					if (props.icon) {
						return `
                        background: url(${props.icon});
                        background-size: cover;
                    `;
					}

					return '';
				}}
    }
}`;

export const ActionBlock = styled.div`
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 9;

	${ActionsContainer} {
		border-top: 1px solid #d6d8e5;
		background: #f1f2f5;
		padding: 0 24px;
		box-sizing: border-box;
	}
`;
export const Date = styled.div`
	font-size: 12px;
	line-height: 17px;
	color: #6c6f88;
	opacity: 0.7;
	margin: 6px 0;
`;

export const WhiteButtonFullwidth = styled<any>(WhiteButton)`
	width: 100%;
`;

export const Divider = styled.hr`
	border: none;
	height: 1px;
	background: #d6d8e5;

	margin: 0 -16px;
	margin-top: 13px;
	margin-bottom: 20px;
`;

export const CloseIconContainer = styled.div`
	position: absolute;
	right: 10px;
	cursor: pointer;
`;
