import { v1, prepareParams } from 'app/api';
import { Executor, VacantExecutor } from 'app/models';
import { ExecutorApiModel } from './types';

type ListRequest = ExecutorApiModel.ListRequest;
type ExecutorResponse = ExecutorApiModel.ExecutorResponse;
type VacantListRequest = ExecutorApiModel.VacantListRequest;
type VacantExecutorResponse = ExecutorApiModel.VacantExecutorResponse;

export async function executorGetList(filter: ListRequest): Promise<Executor[]> {
	const params = prepareParams(filter);
	let cursor: string | null | undefined;
	let result: Executor[] = [];
	while (cursor !== null) {
		if (cursor) params.cursor = cursor;
		const response = await v1.get<ExecutorResponse>('/customer/transport', { params });
		cursor = response.data.meta?.links?.next;
		result = result.concat(response.data.data as Executor[]);
	}
	return result;
}

export async function vacantExecutorGetList(filter: VacantListRequest): Promise<VacantExecutor[]> {
	const params = prepareParams(filter);
	let cursor: string | null | undefined;
	let result: VacantExecutor[] = [];
	let counter = 0;
	while (cursor !== null || counter <= 3) {
		if (cursor) params.cursor = cursor;
		const response = await v1.get<VacantExecutorResponse>('/customer/transport/vacant', { params });
		counter++;
		cursor = response.data.meta?.links?.next;
		result = result.concat(response.data.data as VacantExecutor[]);
	}
	return result;
}
