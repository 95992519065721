import * as React from 'react';

//Styles Injection
import 'normalize.css';

//Tooltips
import 'react-tippy/dist/tippy.css';

//Toasts
import 'react-toastify/dist/ReactToastify.css';

//Sliders
import 'rc-slider/assets/index.css';

//Common styles
import './assets/styles/main.sass';

// Set Bluebird as default Promise handler
import Promise from 'bluebird';
global.Promise = Promise;

//Other
import { initMetrics, jivoStyleFixesInit } from 'app/utils';
import { UserActions } from 'app/actions';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { AppLocalization } from 'app/locales';
import { configureStore } from 'app/store';
import { Provider } from 'react-redux';
import * as ReactDOM from 'react-dom';
import Modal from 'react-modal';
import moment from 'moment';
import { App } from 'app';
import { Theme } from 'app/theme';

//Init Jivo Style Fixes
jivoStyleFixesInit();

//Init metrics
initMetrics();

//Globally locale russian for moment
moment.locale('ru');

//Modal window overlay portal
Modal.setAppElement('#modalWindow');

//prepare store
export const history = createBrowserHistory();
export const store = configureStore(history);
(window as any).store = store;

//Get profile if have session token
{
	const token = localStorage.getItem('sessionToken');

	if (token) {
		store.dispatch(UserActions.getProfile(token, true) as any);
		store.dispatch(UserActions.getContacts() as any);
	}
}

const root: any = (
	<Provider store={store}>
		<AppLocalization>
			<ConnectedRouter history={history}>
				<Theme>
					<App />
				</Theme>
			</ConnectedRouter>
		</AppLocalization>
	</Provider>
);

ReactDOM.render(root, document.getElementById('root'));
