import React from 'react';
import { Localized } from '@fluent/react';
import { connect } from 'react-redux';
import { delay } from 'bluebird';
import { push as pushAction } from 'connected-react-router';

import { Dispatch, bindActionCreators } from 'redux';
import Scrollbars from 'react-custom-scrollbars';
import { LocationDescriptorObject } from 'history';

import { BidDetails, SidebarType, UserProfile, RoleEnum } from 'app/models';

import * as Icons from 'app/components/Icons';
import { CommonActions, BidActions } from 'app/actions';
import { RootState } from 'app/reducers';

import { DetailsContent } from './DetailsContent';

const closeIcon = require('../../../../assets/icons/close.svg');

const mapStateToProps = ({ user, common }: RootState) => ({
	sessionToken: user.sessionToken,
	bid: common.detailsBid,
	profile: user.profile,
	common,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			setDetailsBid: CommonActions.setDetailsBid,
			suspendOrder: BidActions.suspendOrder,
			unsuspendOrder: BidActions.unsuspendOrder,
			push: pushAction,
		},
		dispatch,
	);

class Details extends React.Component<Details.Props, Details.State> {
	public state: Details.State = {};

	constructor(props: Details.Props) {
		super(props);

		this.getSidebarHeader = this.getSidebarHeader.bind(this);
		this.getNotFound = this.getNotFound.bind(this);
	}

	private getSidebarHeader() {
		const { toggleSidebar, push, setDetailsBid, bid, profile } = this.props;
		const userIsBidOwner = bid && typeof bid !== 'string' && bid.publisher;
		const userIsCustomer = (profile || ({} as UserProfile)).role === RoleEnum.Customer;

		const close = async () => {
			if (toggleSidebar && push && setDetailsBid) {
				await toggleSidebar({ type: SidebarType.OrderDetails, value: false });
				// clean query from url
				await push({ search: '' });
				await delay(200);
				// clean tmp bid
				await setDetailsBid();
			}
		};

		const openEdit = async () => {
			if (toggleSidebar) {
				await toggleSidebar({ type: SidebarType.OrderEdit, value: true });
			}
		};

		return (
			<div key='details-header' className='header'>
				<div className='title'>
					<Localized id='details-header-title' />
				</div>

				{!userIsBidOwner || !userIsCustomer ? null : (
					<button className='edit' onClick={openEdit}>
						<Icons.Edit color='rgb(0,0,0)' />
					</button>
				)}

				<div className='icon' onClick={close}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
		);
	}

	public getNotFound() {
		return (
			<div className='info'>
				<div className='not-found'>
					<Icons.CarInfo />

					<div className='title'>
						<Localized id='details-order-not-found' />
					</div>
				</div>
			</div>
		);
	}

	public render() {
		const { bid, profile, suspendOrder, unsuspendOrder, push } = this.props;

		const scrollbarProps: any = {
			renderTrackHorizontal: () => <div />,
			key: 'details-scrollbar',
			className: 'form',
		};

		return [
			this.getSidebarHeader(),

			<Scrollbars {...scrollbarProps} key='details-scrollbars'>
				{profile && bid && typeof bid !== 'string' ? (
					<DetailsContent
						bid={bid}
						profile={profile}
						suspendOrder={suspendOrder}
						unsuspendOrder={unsuspendOrder}
						push={push}
					/>
				) : (
					this.getNotFound()
				)}
			</Scrollbars>,
		];
	}
}

export default connect<Details.StateProps, Details.DispatchProps, Details.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(Details);

namespace Details {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		bid?: BidDetails | string;
		common?: RootState.Common;
		sessionToken?: string;
		profile?: UserProfile;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		push?: (location: LocationDescriptorObject) => void;
		setDetailsBid?: (bid?: BidDetails | string) => void;
		suspendOrder?: (id: string) => void;
		unsuspendOrder?: (id: string) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
