//saga
import { put, takeEvery, call } from 'redux-saga/effects';

//api
import { createNewCar, prepareParams } from 'app/api';

//actions
import { store } from 'src/main';
import { DriverActions, EmptyCarActions } from 'app/actions';

//types
import { EmptyCar } from 'app/models';
import { CarApiModel } from 'app/api/types';

type Action<T> = {
	type: string;
	payload: T;
};

//helpers
import { getBasicContact } from 'app/selectors';

//sagas
function* createCar(action: Action<EmptyCar>): Generator<any, void, any> {
	yield put(EmptyCarActions.setLoadingState(true));

	const payload = action.payload;
	const form = new FormData();

	if (payload?.documents?.transport?.mainPage) {
		form.append('transport_main_page', payload.documents.transport.mainPage);
	}

	if (payload?.documents?.transport?.ownerPage) {
		form.append('transport_owner_page', payload.documents.transport.ownerPage);
	}

	if (payload?.documents?.trailer?.mainPage) {
		form.append('trailer_main_page', payload.documents.trailer.mainPage);
	}

	if (payload?.documents?.trailer?.ownerPage) {
		form.append('trailer_owner_page', payload.documents.trailer.ownerPage);
	}

	if (payload?.documents?.policy?.mainPage) {
		form.append('policy_main_page', payload.documents.policy.mainPage);
	}

	//transport part
	const transport: CarApiModel.CarRequest['transport'] = {
		ident: payload?.base?.ident || '',
		kind: { uuid: payload?.documents?.transport?.kind ?? '' },

		brand: payload?.documents?.transport?.brand ? { uuid: payload.documents.transport.brand } : undefined,
		number: payload?.documents?.transport?.number || undefined,
		model: payload?.documents?.transport?.model || undefined,
		color: payload?.documents?.transport?.color || undefined,
		manufactureYear: payload?.documents?.transport?.manufactureYear || undefined,
		vin: payload?.documents?.transport?.vin || undefined,
		issuedAt: payload?.documents?.transport?.issuedAt || undefined,

		gross: payload?.dimensions?.gross || undefined,
		height: payload?.dimensions?.height || undefined,
		length: payload?.dimensions?.length || undefined,
	};

	//trailer part
	const trailer: CarApiModel.CarRequest['trailer'] = {
		ident: payload?.base?.trailerIdent || '',
		kind: { uuid: payload?.documents?.trailer?.kind || '' },

		brand: payload?.documents?.trailer?.brand ? { uuid: payload.documents.trailer.brand } : undefined,
		number: payload?.documents?.trailer?.number || undefined,
		model: payload?.documents?.trailer?.model || undefined,
		color: payload?.documents?.trailer?.color || undefined,
		manufactureYear: payload?.documents?.trailer?.manufactureYear || undefined,
		vin: payload?.documents?.trailer?.vin || undefined,
		issuedAt: payload?.documents?.trailer?.issuedAt || undefined,
	};

	//policy part
	const policy: CarApiModel.CarRequest['policy'] = {
		number: payload?.documents?.policy?.number || undefined,
		issuedAt: payload?.documents?.policy?.issuedAt || undefined,
		expiredAt: payload?.documents?.policy?.expiredAt || undefined,
	};

	const data = {
		transport: prepareParams(transport),
		policy: prepareParams(policy),
		...(trailer?.ident && { trailer: prepareParams(trailer) }),
	};

	form.append('data', JSON.stringify(data));

	try {
		yield call(createNewCar, form);

		yield put(EmptyCarActions.setLoadingState(false));
		yield put(EmptyCarActions.deleteCarData());

		const { user } = store.getState();

		if (user?.profile) {
			const basicContact = getBasicContact(user.profile);
			yield put(DriverActions.getDriverTransportsList(basicContact));
		}
	} catch (error) {
		yield put(EmptyCarActions.setLoadingState(false));
	}
}

export function* watchCarCreation() {
	yield takeEvery(EmptyCarActions.Action.CREATE_CAR, createCar);
}
