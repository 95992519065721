/* eslint-disable */
import { globalInitialState } from 'app/utils/initial-state';
import React, { Component } from 'react';

//TODO: Update privacy policy text
export default class PrivacyPolicy extends Component {
	render() {
		return (
			<section className={'privacy-policy-page'}>
				<div className='privacy-policy-page__document'>
					<h1>Политика конфиденциальности</h1>
					<p className='c10'>
						<span className='c1'>Редакция от 01.04.2018 г.</span>
					</p>
					<ol className='c5 lst-kix_list_5-0 start' start={1}>
						<li className='c3'>
							<span className='c6'>Термины и определения</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								В настоящей политике конфиденциальности, если из текста прямо не вытекает иное, следующие термины будут
								иметь указанные ниже значения:
							</span>
						</li>
					</ol>
					<ul className='c5 lst-kix_list_6-0 start'>
						<li className='c4'>
							<span className='c1'>
								«{globalInitialState.sitename}» - Общество с ограниченной ответственностью «Технологии грузоперевозок»,
								ИНН 7751153878, ОГРН 1197746005445, Адрес: 108841, г. Москва, г. Троицк, микрорайон В д. 55, эт. 3, пом.
								1, офис 127,{' '}
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Договор» - любой договор и/или соглашение, заключенный с Пользователем, информация о котором доступна
								на Сайте и/или в Приложении{' '}
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Персональные данные» - любая информация, относящаяся прямо или косвенно определенному или определяемому
								физическому лицу (субъекту персональных данных) к Пользователю и/или третьим лицам, информация о которых
								предоставляется Пользователем с использованием Мобильного приложения, Сайта и/или уполномоченного адреса
								электронной почты
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Пользователь» – Физическое, юридическое лицо или индивидуальный предприниматель, имеющее доступ к сайту
								или мобильному приложению посредством сети Интернет и использующее данный сайт / мобильное приложение
								для своих целей.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Мобильное Приложение» Программа для мобильных устройств, доступ к которой предоставляется Пользователю
								после регистрации на Сайте и/или в программе
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Сайт» &nbsp;Совокупность информации, текстов, графических элементов, дизайна, изображений, фото и
								&nbsp;видеоматериалов и &nbsp;иных результатов интеллектуальной деятельности, а &nbsp;также программ для
								ЭВМ, содержащихся в &nbsp;информационной системе, обеспечивающей доступность такой информации в
								&nbsp;сети Интернет по сетевому адресу https://{globalInitialState.hostname}
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Обработка персональных данных» - любое действие (операция) или совокупность действий (операций),
								совершаемых с использованием средств автоматизации или без использования таких средств с персональными
								данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение),
								извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
								блокирование, удаление, уничтожение персональных данных.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«Cookies» — небольшой фрагмент данных, отправленный веб-сервером и хранимый на компьютере пользователя,
								который веб-клиент или веб-браузер каждый раз пересылает веб-серверу в HTTP-запросе при попытке открыть
								страницу соответствующего сайта. Файлы cookies - это текстовые файлы, доступные{' '}
								{globalInitialState.sitename}, для обработки информации об активности Пользователя, включая информацию о
								том, какие страницы посещал Пользователь и о времени, которое Перевозчик провел на странице.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								«IP-адрес» — уникальный сетевой адрес узла в компьютерной сети, построенной по протоколу IP.
							</span>
						</li>
					</ul>
					<p className='c12'>
						<span className='c1'>
							Все остальные термины и определения, встречающиеся в тексте Политики конфиденциальности, толкуются в
							соответствии с Соглашением, Договором и действующим законодательством Российской Федерации.{' '}
						</span>
					</p>
					<p className='c12 c13'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={2}>
						<li className='c3'>
							<span className='c6'>Общие положения</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								Настоящая политика конфиденциальности разработана и должна толковаться в соответствии с
								&nbsp;Федеральным законом от 27.07.2006 &nbsp;№ &nbsp;152-ФЗ «О &nbsp;персональных данных» и правом
								Российской Федерации и устанавливает правила использования Обществом с ограниченной ответственностью
								«Технологии грузоперевозок», зарегистрированным и действующим в соответствии с законодательством
								Российской Федерации, персональной информации, получаемой от &nbsp;лиц, зарегистрированных на сайте{' '}
							</span>
							<a href={`https://${globalInitialState.hostname}/`}>https://{globalInitialState.hostname}/</a>
						</li>
						<li className='c3'>
							<span className='c1'>
								Политика конфиденциальности персональной информации (далее — Политика) действует в отношении всей
								информации, которую ООО «ТЕХНОЛОГИИ ГРУЗОПЕРЕВОЗОК» и/или его аффилированные лица (далее —{' '}
								{globalInitialState.sitename}), могут получить о Пользователе во время использования им сайта, сервиса,
								служб, программ, продуктов или услуг {globalInitialState.sitename} (далее — Сервисы, Сервисы
								{globalInitialState.sitename}) и в ходе исполнения {globalInitialState.sitename} любых соглашений и
								договоров с Пользователем. Пользователь дает свое согласие на обработку персональных данных путем
								принятия Политики конфиденциальности и использования Сервизов {globalInitialState.sitename}.
								Использование Сервисов {globalInitialState.sitename} означает безоговорочное согласие Пользователя с
								настоящей Политикой и указанными в ней условиями обработки его персональной информации; в случае
								несогласия с этими условиями Пользователь должен прекратить или воздержаться от использования Сервисов.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Настоящая Политика конфиденциальности применяется только к информации, обрабатываемой в ходе
								использования Сервиса {globalInitialState.sitename}. {globalInitialState.sitename} не контролирует и не
								несет ответственность за сайты третьих лиц, на которые Пользователь может перейти по ссылкам, доступным
								на сайте {globalInitialState.sitename}.{' '}
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c6' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={3}>
						<li className='c3'>
							<span className='c6'>
								Персональные данные Пользователей, обрабатываемая {globalInitialState.sitename}{' '}
							</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} собирает, получает доступ и использует в определенных Политикой
								конфиденциальности целях Персональные данные, техническую и иную информацию, связанную с Пользователем
								и/или третьими лицами, использующими Мобильное Приложение и Сайт, либо информация о которых
								предоставляется Пользователем.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								В рамках настоящей Политики конфиденциальности, «персональные данные Пользователя», разрешенные к
								обработке {globalInitialState.sitename}, предоставляются пользователем о себе самостоятельно при
								регистрации (создании учетной записи) или в процессе использования Сервиса, путем заполнения специальных
								форм на сайте или мобильном приложении или в рамках исполнения Соглашения и/или Договора. &nbsp;Под
								Персональными данными, в частности, может пониматься следующая информация:
							</span>
						</li>
					</ol>
					<ul className='c5 lst-kix_list_8-1 start'>
						<li className='c4'>
							<span className='c1'>фамилия, имя, отчество;</span>
						</li>
						<li className='c4'>
							<span className='c1'>паспортные данные;</span>
						</li>
						<li className='c4'>
							<span className='c1'>адрес электронной почты;</span>
						</li>
						<li className='c4'>
							<span className='c1'>контактный телефон;</span>
						</li>
						<li className='c4'>
							<span className='c1'>дата рождения;</span>
						</li>
						<li className='c4'>
							<span className='c1'>адреса регистрации по месту жительства и фактического проживания.</span>
						</li>
						<li className='c4'>
							<span className='c1'>номера телефонов: мобильного.</span>
						</li>
						<li className='c4'>
							<span className='c1'>СНИЛС.</span>
						</li>
						<li className='c4'>
							<span className='c1'>ИНН.</span>
						</li>
						<li className='c4'>
							<span className='c1'>геолокационные данные</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								иная информация, указываемая Пользователем в Мобильном Приложении, на Сайте и/или предоставляемая{' '}
								{globalInitialState.sitename} с использованием уполномоченного адреса электронной почты Пользователя.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Данные, которые автоматически передаются Сервисам {globalInitialState.sitename} в процессе его
								использования с помощью установленного на устройстве Пользователя программного обеспечения, в том числе
								IP-адрес, данные файлов cookie, информация о браузере Пользователя (или иной программе, с помощью
								которой осуществляется доступ к Сервисам), технические характеристики оборудования и программного
								обеспечения, используемых Пользователем, дата и время доступа к Сервисам, адреса запрашиваемых страниц и
								иная подобная информация.{' '}
							</span>
						</li>
					</ul>
					<ol className='c5 lst-kix_list_5-1' start={3}>
						<li className='c3'>
							<span className='c1'>
								Пользователь дает свое согласие {globalInitialState.sitename} на Обработку Персональных данных, в том
								числе на передачу таких Персональных данных третьим лицам во исполнение Соглашения и/или Договора, даже
								когда такая передача осуществляется на территорию иных государств (трансграничная передача).
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} обрабатывает Персональные данные, Техническую информацию и иную информацию
								Пользователя без ограничения срока с момента предоставления такой информации, любым законным способом, в
								том числе в информационных системах персональных данных с использованием средств автоматизации или без
								использования таких средств.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} исходит из того, что пользователь предоставляет достоверную и достаточную
								персональную информацию и поддерживает эту информацию в актуальном состоянии. Последствия предоставления
								недостоверной или недостаточной информации определены в Пользовательском соглашении сервиса{' '}
								{globalInitialState.sitename}{' '}
							</span>
						</li>
						<li className='c2'>
							<span className='c1'>
								Пользователь до передачи персональных данных {globalInitialState.sitename} обязуется выполнить все
								действия, предусмотренные Законом, для обеспечения соблюдения прав лиц, к персональным данным которых
								&nbsp;{globalInitialState.sitename}
								получает доступ, в том числе получить от указанных лиц разрешение на передачу и обработку персональных
								данных {globalInitialState.sitename} и привлекаемыми им для оказания услуг лицами. В случае неполучения
								Пользователем такого разрешения, в связи с чем {globalInitialState.sitename} были причинены убытки,
								Пользователь обязан в срок не позднее 10 (десяти) дней с даты получения соответствующего письменного
								требования от {globalInitialState.sitename}, возместить такие убытки при наличии документов,
								подтверждающих основания для возмещения таких убытков.
							</span>
						</li>
					</ol>
					<p className='c12 c13'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={4}>
						<li className='c3' id='h.gjdgxs'>
							<span className='c6'>Цели использования и обработки персональных данных Пользователей </span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} собирает и хранит ту персональную информацию, которая необходима для
								предоставления Сервиса или исполнения соглашений и договоров с Пользователем, за исключением случаев,
								когда законодательством предусмотрено обязательное хранение персональной информации в течение
								определенного законом срока. Персональную информацию, предоставленную Пользователем{' '}
								{globalInitialState.sitename} обрабатывает и использует в следующих целях:
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ul className='c5 lst-kix_list_9-0 start'>
						<li className='c4'>
							<span className='c1'>
								Регистрации и Идентификации Пользователя в Мобильном Приложении и на сайте, и в рамках исполнения
								соглашений и договоров с {globalInitialState.sitename};
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Подтверждения достоверности и полноты персональных данных, предоставленных Пользователем.
							</span>
						</li>
						<li className='c7 c11'>
							<span className='c1'>
								Предоставление Пользователю персонализированных Сервисов и исполнение соглашений и договоров; Исполнения{' '}
								{globalInitialState.sitename} обязательств перед Пользователем и третьими лицами по Соглашению и/или
								Договору;
							</span>
						</li>
						<li className='c7 c11'>
							<span className='c1'>
								Установление и поддержания связи с Пользователем, в том числе направление уведомлений, запросов и
								информации, касающихся использования Сервиса, исполнения соглашений и договоров, а также обработка
								запросов и заявок от Пользователя; а также направление на адрес электронной почты и мобильный телефон
								Пользователя сообщений информационного и иного характера;{' '}
							</span>
						</li>
						<li className='c7 c11'>
							<span className='c1'>
								Уведомления Пользователя сайта о состоянии Заказа, Коммерческого предложения, разного рода Заявок,
								Договоров и Контрактов.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Таргетирование рекламных материалов; Пользователь дает согласие на получение информационных рассылок и
								рекламных материалов от {globalInitialState.sitename}, либо от иных лиц по поручению{' '}
								{globalInitialState.sitename}, на адрес электронной почты и контактный телефон, указанные Перевозчиком
								при регистрации на Сайте и/или в Приложении.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Улучшение качества Сервиса и обслуживания, удобства их использования, разработка новых Сервисов,
								модернизации Сайта и/или Мобильного приложения;
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>Проведение статистических и иных исследований на основе обезличенных данных.</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Определения места нахождения Пользователя для обеспечения безопасности, предотвращения мошенничества.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Осуществления правосудия, в случае поступления в адрес {globalInitialState.sitename}
								соответствующего запроса уполномоченных органов;
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>Исполнения требований действующего российского законодательства.</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Обработки и получения платежей, подтверждения налога или налоговых льгот, оспаривания платежа,
								определения права на получение кредитной линии Пользователем.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Предоставления доступа Пользователю на сторонние сайты или сервисы партнеров данного сайта с целью
								получения их предложений, обновлений или услуг.
							</span>
						</li>
					</ul>
					<p className='c0 c19'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={5}>
						<li className='c3'>
							<span className='c6'>Условия обработки персональной информации Пользователей</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								При обработке персональных данных Пользователей {globalInitialState.sitename}
								руководствуется Федеральным законом РФ «О персональных данных» и хранит персональную информацию
								Пользователей в соответствии с внутренними регламентами.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								В отношении персональной информации Пользователя сохраняется ее конфиденциальность, кроме случаев
								добровольного предоставления Пользователем информации о себе для общего доступа неограниченному кругу
								лиц. При использовании сервиса, Пользователь соглашается с тем, что определённая часть его персональной
								информации становится общедоступной.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} вправе передать персональную информацию Пользователя третьим лицам в
								следующих случаях:
							</span>
						</li>
					</ol>
					<ul className='c5 lst-kix_list_9-0'>
						<li className='c4'>
							<span className='c1'>Пользователь не выразил несогласие на такие действия;</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Передача необходима для использования Пользователем Сервиса либо для исполнения{' '}
								{globalInitialState.sitename} обязательств перед Пользователем по заключенным с Пользователем договорам
								и соглашениям{' '}
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Передача предусмотрена российским или иным применимым законодательством в рамках установленной
								законодательством процедуры;
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Предоставление информацию о Пользователе правоохранительным органам либо иным государственным органам в
								рамках судебного процесса или в рамках проведения расследования на основании судебного решения,
								принудительно исполнимого запроса или в порядке сотрудничества, а также в иных случаях, предусмотренных
								действующим российским законодательством.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или в части), при этом к
								приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к
								полученной им персональной информации;{' '}
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								В целях обеспечения возможности защиты прав и законных интересов
								{globalInitialState.sitename} или третьих лиц в случаях, когда Пользователь нарушает Пользовательское
								соглашение сервисов {globalInitialState.sitename}, настоящую Политику, либо документы, содержащие
								условия использования конкретных Сервисов.{' '}
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные
								статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ
								или оказания услуг по поручению {globalInitialState.sitename}.
							</span>
						</li>
						<li className='c4'>
							<span className='c1'>
								Предоставления информации о Пользователе третьим лицам для выявления и пресечения мошеннических
								действий, для устранения технических неполадок или проблем с безопасностью.
							</span>
						</li>
					</ul>
					<p className='c12'>
						<span>
							Предоставления персональных данных третьим лицам, в частности, курьерским службам, организациями почтовой
							связи, операторам электросвязи, в целях выполнения заявок Пользователя, оформленных на сайте, в рамках
							Договора публичной оферты.
						</span>
					</p>
					<p className='c0 c23'>
						<span className='c6' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={6}>
						<li className='c3'>
							<span className='c6'>Изменение удаление и хранения персональной информации. </span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} предпринимает технические и организационные меры по предоставлению
								Пользователю возможности получать доступ к предоставленной им информации и редактировать такую
								информацию. Пользователь может в любой момент изменить (обновить, дополнить) предоставленную им
								персональную информацию или её часть, воспользовавшись функцией редактирования персональных данных в
								разделе «профиль» либо в персональном разделе соответствующего Сервиса путем совершения необходимых для
								этого действий на Сайте, а при отсутствии такой возможности - обратившись к{' '}
								{globalInitialState.sitename} по адресу электронной почты - info@{globalInitialState.hostname}. При этом
								Пользователь понимает, что {globalInitialState.sitename} вправе продолжить использование такой
								информации в допустимых действующим российским законодательством случаях.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Права, предусмотренные п.6.1 настоящей Политики могут быть ограничены в соответствии с требованиями
								законодательства. В частности, такие ограничения могут предусматривать обязанность{' '}
								{globalInitialState.sitename} сохранить измененную или удаленную Пользователем информацию на срок,
								установленный законодательством, и передать такую информацию в соответствии с законодательно
								установленной процедурой государственному органу.{' '}
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={7}>
						<li className='c3'>
							<span className='c6'>Обработка персональной информации при помощи файлов Cookie</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								Файлы cookie, передаваемые {globalInitialState.sitename} оборудованию Пользователя и оборудованием
								Пользователя {globalInitialState.sitename}, могут использоваться {globalInitialState.sitename} для
								предоставления Пользователю персонализированных Сервисов, для таргетирования рекламы, которая
								показывается Пользователю, в статистических и исследовательских целях, а также для улучшения Сервисов
								{globalInitialState.sitename}.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Пользователь осознает, что оборудование и программное обеспечение, используемые им для посещения сайтов
								в сети интернет могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для
								определенных сайтов), а также удаления ранее полученных файлов cookie.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} вправе установить, что предоставление Сервиса возможно лишь при условии,
								что прием и получение файлов cookie разрешены Пользователем.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Структура файла cookie, его содержание и технические параметры определяются{' '}
								{globalInitialState.sitename} и могут изменяться без предварительного уведомления Пользователя.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Счетчики, размещенные {globalInitialState.sitename} в Сервисе, могут использоваться для анализа файлов
								cookie Пользователя, для сбора и обработки статистической информации об использовании Сервиса, а также
								для обеспечения работоспособности Сервиса в целом или его отдельных функций в частности. Технические
								параметры работы счетчиков определяются {globalInitialState.sitename} и могут изменяться без
								предварительного уведомления Пользователя.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Техническая информация не является Персональными данными, однако
								{globalInitialState.sitename} использует файлы cookies, которые позволяют идентифицировать Пользователя.
								Пользователь может отключить возможность использования файлов cookies в настройках браузера.
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={8}>
						<li className='c3'>
							<span className='c6'>Меры защиты персональной информации Пользователей</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} принимает необходимые и достаточные организационные и технические меры для
								защиты персональной информации Пользователя от неправомерного или случайного доступа, уничтожения,
								изменения, блокирования, копирования, распространения, а также от иных неправомерных действий с ней
								третьих лиц.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} гарантирует, что предоставленная Перевозчиком информация не объединяется
								со статистическими данными, не предоставляется третьим лицам и не разглашается, за исключением случаев,
								предусмотренных в Политике конфиденциальности.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} не продаёт и не передаёт информацию о Перевозчике отдельно. Такая
								информация может передаваться только при частичной или полной реорганизации{' '}
								{globalInitialState.sitename}.
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={9}>
						<li className='c3'>
							<span className='c6'>Изменение Политики конфиденциальности</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								{globalInitialState.sitename} имеет право вносить изменения в настоящую Политику конфиденциальности без
								согласия Пользователя.{' '}
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								При внесении изменений в актуальной редакции указывается дата последнего обновления. Новая редакция
								Политики вступает в силу с момента ее размещения, если иное не предусмотрено новой редакцией Политики.
								Действующая редакция постоянно доступна на странице по адресу{' '}
							</span>
							<a href={`https://${globalInitialState.hostname}/legal/confidential`}>
								https://{globalInitialState.hostname}/legal/confidential/
							</a>
							<span className='c1'>. </span>
						</li>
						<li className='c3'>
							<span className='c1'>
								На Пользователе лежит обязанность при каждом обращении к Сайту и/или Мобильному Приложению знакомиться с
								текстом Политики конфиденциальности. В случае несогласия с условиями Политики конфиденциальности
								Пользователь не должен пользоваться Сайтом и/или Мобильным Приложением.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Новая редакция Политики конфиденциальности вступает в силу с момента ее размещения на Сайте и/или в
								Мобильном Приложении. Продолжение пользования Сайта и/или Мобильного Приложения после публикации новой
								редакции Политики конфиденциальности на Сайте и/или в Мобильном Приложении означает принятие Политики
								конфиденциальности и ее условий Пользователем.
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={10}>
						<li className='c3'>
							<span className='c6'>Применимое законодательство</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								К настоящей Политике и отношениям между Пользователем и {globalInitialState.sitename}, возникающим в
								связи с применением Политики конфиденциальности, подлежит применению право Российской Федерации.
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={11}>
						<li className='c3'>
							<span className='c6'>Исключение противоречий</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								В случае, когда соглашения между {globalInitialState.sitename} и Пользователем содержат положения об
								использовании персональной информации и/или Персональных данных, применяются положения Политики
								конфиденциальности и таких соглашений в части, не противоречащей Политике конфиденциальности
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={12}>
						<li className='c3'>
							<span className='c6'>Разрешение споров</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c3'>
							<span className='c1'>
								До обращения в суд с иском по спорам, возникающим из отношений между Пользователем и{' '}
								{globalInitialState.sitename}, обязательным является предъявление претензии (письменного предложения о
								добровольном урегулировании спора).
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								Получатель претензии в течение 30 календарных дней со дня получения претензии, письменно уведомляет
								заявителя претензии о результатах рассмотрения претензии.
							</span>
						</li>
						<li className='c3'>
							<span className='c1'>
								При не достижении соглашения спор будет передан на рассмотрение в судебный орган в соответствии с
								действующим законодательством.
							</span>
						</li>
					</ol>
					<p className='c0'>
						<span className='c1' />
					</p>
					<ol className='c5 lst-kix_list_5-0' start={13}>
						<li className='c3'>
							<span className='c6'>Обратная связь. Вопросы и предложения</span>
						</li>
					</ol>
					<ol className='c5 lst-kix_list_5-1 start' start={1}>
						<li className='c2'>
							<span className='c1'>
								Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять в Службу
								поддержки пользователей {globalInitialState.sitename} (
							</span>
							<span className='c1'>
								<a className='c17' href={`http://feedback.${globalInitialState.hostname}/`}>
									http://feedback.{globalInitialState.hostname}/
								</a>
							</span>
							<span className='c1'>
								), либо по адресу: 108841, г. Москва, г. Троицк, микрорайон В д. 55, эт. 127, ООО «Технологии
								грузоперевозок».{' '}
							</span>
						</li>
					</ol>
					<p className='c7'>
						<span className='c20'>Дата публикации: 01.04.2018 г.</span>
						<span className='c1'>&nbsp;</span>
					</p>
				</div>
			</section>
		);
	}
}
