import React, { useState } from 'react';

import styled from 'styled-components';
import { Localized } from '@fluent/react';

const Wrapper = styled.div`
	position: absolute;
	z-index: 99999;
	right: 60px;
	top: 30px;
	display: flex;
`;

const Button = styled.button`
	background-color: #ffffff;
	padding: 11px;
	border-radius: 4px;
`;

const states = [
	{ type: 'roadmap', localeId: 'gmap-map-type-roadmap' },
	{
		type: 'hybrid',
		localeId: 'gmap-map-type-hybrid',
	},
];

const MapType = ({ onClick }: any) => {
	const [type, setType] = useState(0);

	const handleClick = () => {
		const nextState = type === states.length - 1 ? 0 : type - -1;

		setType(nextState);
		onClick(states[nextState].type);
	};

	return (
		<Wrapper>
			<Button onClick={handleClick}>
				<Localized id={states[type - -1]?.localeId || states[0]?.localeId} />
			</Button>
		</Wrapper>
	);
};

export default MapType;
