import React from 'react';

//other deps
import { toast } from 'react-toastify';
import { Localized } from '@fluent/react';
import Spinner from 'react-loader-spinner';
import Scrollbars from 'react-custom-scrollbars';

//redux
import { useDispatch } from 'react-redux';

//screen parts
import { BaseCar } from './parts/base';
import { DocsCar } from './parts/docs';
import { DimensionsCar } from './parts/dimensions';

//components
import LoadingIndicator from 'app/sharedComponents/LoadingIndicator';

//types
import { RootState } from 'app/reducers';
import { EmptyCarActions } from 'app/actions';

interface CarCreationProps {
	car?: RootState['driver']['emptyCar'];
	handleModal: () => void;
}

//----------------------------------------------------------
// CarCreation
//----------------------------------------------------------
export const CarCreation = ({ car }: CarCreationProps) => {
	//data
	const isLoading = car?.isLoading || false;
	const loadingClass = isLoading ? 'loading' : '';

	//callbacks
	const dispatch = useDispatch();

	const createNewCar = () => {
		const errors: string[] = [];

		if (car?.data?.base?.ident) {
			if (!car?.data?.documents?.transport?.kind) {
				errors.push('Тип транспортного средства');
			}
		} else {
			errors.push('Гос номер транспортного средства');
		}

		if (car?.data?.base?.trailerIdent) {
			if (!car?.data?.documents?.trailer?.kind) {
				errors.push('[прицеп] Тип транспортного средства');
			}
		}

		if (errors.length) {
			errors.forEach((message) => {
				toast.warn(`Обязательное поле: ${message}`, {
					position: toast.POSITION.BOTTOM_CENTER,
					autoClose: 5000,
				});
			});

			errors.length = 0;
			return;
		}

		car?.data && dispatch(EmptyCarActions.createNewCar(car.data));
	};

	const deleteCarData = () => {
		dispatch(EmptyCarActions.deleteCarData());
	};

	//render
	return (
		<Scrollbars className='car-creation'>
			{/* loader */}
			{isLoading && <LoadingIndicator align='center' />}

			{/* form parts */}
			<BaseCar carBase={car?.data?.base} />
			<DocsCar carData={car?.data} />
			<DimensionsCar dimensions={car?.data?.dimensions} />

			{/* buttons */}
			<div className='actions'>
				<button disabled={false} className={`action ${loadingClass}`} onClick={createNewCar}>
					{isLoading ? (
						<>
							<Spinner type='RevolvingDot' color='#1FB0E2' height={22} width={22} />

							<div style={{ width: '10px' }} />

							<Localized id='profile-action-save-loading' />
						</>
					) : (
						<Localized id='transports-action-save' />
					)}
				</button>

				<button className='action cancel-button' onClick={deleteCarData}>
					<Localized id='transports-action-save-cancel' />
				</button>
			</div>
		</Scrollbars>
	);
};
