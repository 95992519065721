import React from 'react';

//other deps
import { Localized } from '@fluent/react';

//components
import CarItem from './CarItem';

//helpers
import { isProfileFilled } from '../cars.helpers';

//----------------------------------------------------------
// CreateButton
//----------------------------------------------------------
const CreateButton = ({ createTransport = () => {} }) => {
	return (
		<div key='team-cars-add' className='action add team' onClick={createTransport}>
			<div className='plus'>
				<span>+</span>
			</div>

			<Localized id='transports-action-add-car' />
		</div>
	);
};

//----------------------------------------------------------
// NeedFill
//----------------------------------------------------------
const NeedFill = () => (
	<div key='team-cars-add' className='action add disabled team'>
		<Localized id='transports-info-need-fill' />
	</div>
);

//----------------------------------------------------------
// CarsList
//----------------------------------------------------------
const CarsList = (props: any) => {
	const { addNewTransport, deleteDriverTransport, setOpened, profile, opened, cars = {} } = props;

	const handleCarClick = (id: string) => () => setOpened(id);

	return (
		<>
			<div key='other-cars-header' className='header'>
				<Localized id='transports-page-your-cars' />
			</div>

			{isProfileFilled(profile) ? <CreateButton createTransport={addNewTransport} /> : <NeedFill />}

			<div key='cars-list' className='cars'>
				{typeof cars === 'object' &&
					Object.keys(cars).map((key: string) => {
						const car = cars[key];

						return (
							<CarItem
								car={car}
								key={car.uuid}
								profileAvatar={profile.avatar}
								deleteDriverTransport={deleteDriverTransport}
								isOpened={opened === car.uuid}
								onClick={handleCarClick(car.uuid)}
							/>
						);
					})}
			</div>
		</>
	);
};

export default CarsList;
