import React from 'react';
import styled from 'styled-components';

import { IconMappings } from './svgIcons/mappings';

export interface GlobalIconProps {
	/** String name of the icon to render in kebab-case. */
	variant: keyof typeof IconMappings;
	/** Boolean flag to show icon state: hover, active, etc */
	//   withState?: boolean;
	/** A function to be called on icon click */
	onClick?: Function;
	/** Icon test id */
	testid?: string;
	/** color */
	color?: string;
	/** size */
	size?: number;
}

const IconContainer = styled.div`
	padding: 2px;
`;
export const Icon: React.FC<GlobalIconProps> = ({ variant, onClick = () => {}, ...all }) => {
	//@ts-ignore
	const SelectedIcon = IconMappings[variant];

	const isValidIcon = (iconVariant: any) => {
		const validIcon = Object.keys(IconMappings).includes(iconVariant);

		if (!validIcon) {
			console.error('Icon variant is invalid.', iconVariant);
		}

		return validIcon;
	};

	const handleClick = () => {
		onClick && onClick();
	};

	const validIcon = isValidIcon(variant);
	//@ts-ignore
	return <IconContainer>{validIcon && <SelectedIcon {...all} onClick={handleClick} />}</IconContainer>;
};

export default Icon;
