import * as React from 'react';

//router
import { RouteComponentProps, withRouter } from 'react-router';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//components
import PageTitle from 'app/components/PageTitle';

//types
import { RootState } from 'app/reducers';
import { UserProfile, RoleEnum } from 'app/models';

//parts
import Base from './base';
import Docs from './docs';

//----------------------------------------------------------
// Personal
//----------------------------------------------------------
class Personal extends React.Component<PersonalSettings.Props, PersonalSettings.State> {
	public state: PersonalSettings.State = {};

	constructor(props: PersonalSettings.Props) {
		super(props);
	}

	public render() {
		const { profile } = this.props;
		const driverDocs = profile && profile.role === RoleEnum.Driver && <Docs />;

		return (
			<div className='personal-data' style={{ display: 'grid' }}>
				<PageTitle titleKey='title-profile' />
				<Base />
				{driverDocs}
			</div>
		);
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	profile: user.profile,
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
	connect<PersonalSettings.StateProps, PersonalSettings.DispatchProps, PersonalSettings.ExternalProps>(
		mapStateToProps,
		mapDispatchToProps,
	)(Personal),
);

namespace PersonalSettings {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		profile?: UserProfile;
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
