import React from 'react';
import { ListRowProps } from 'react-virtualized';
import { Localized } from '@fluent/react';
import { Bid } from 'app/models';
import {
	View as ViewsIcon,
	Reply as AnswersIcon,
	Approve as InworkIcon,
	Check as VerifiedIcon,
} from 'app/components/Icons';
import { Orders } from './types';

export type TableRowProps = ListRowProps & {
	onClick?: () => void;
	bid?: Bid;
	tab?: Orders.Tab;
};

export const TableRow = (props: any) => {
	const { style, onClick, bid, tab } = props;
	if (!bid) {
		return (
			<div className='bid item' style={style}>
				<div className='start'>...</div>
				<div className='from'>...</div>
				<div className='to'>...</div>
				<div className='distance'>...</div>
				<div className='cargo'>...</div>
				<div className='weight'>...</div>
				<div className='rate'>...</div>
				<div className='ratePerKm'>...</div>
				{tab === Orders.Tab.My && (
					<>
						<div className='view'>...</div>
						<div className='view'>...</div>
						<div className='view'>...</div>
					</>
				)}
			</div>
		);
	}
	return (
		<div className='bid item' onClick={onClick} style={style}>
			<div className='start'>{bid.deliveryTime.from}</div>
			<div className='from'>
				{bid.isPlatformBid && (
					<div className='verified-icon'>
						<VerifiedIcon color='white' size={13} />
					</div>
				)}
				<div className='name'>{bid?.direction?.from?.name}</div>
				<div className='address'>{bid?.direction?.from?.address?.replace(bid?.direction?.from?.name + ',', '')}</div>
			</div>
			<div className='to'>
				<div className='name'>{bid?.direction?.to?.name}</div>
				<div className='address'>{bid?.direction?.to?.address?.replace(bid?.direction?.from?.name + ',', '')}</div>
			</div>
			<div className='distance'>
				<div className='text'>{bid.direction.distance} Км</div>
			</div>
			<div className='cargo'>
				<div className='text'>{bid.cargo.type.name}</div>
			</div>
			<div className='weight'>
				<div className='text'>{bid.cargo.weight} Т</div>
			</div>
			{(bid.kind === 'bid' && (
				<>
					<div className='rate'>
						{bid.payment.price}
						&nbsp;
						<Localized id='bid-price-unit' vars={{ currency: bid?.payment?.currency?.symbol }} />
					</div>
					<div className='rate'>
						{bid?.payment?.pricePerKm}
						&nbsp;
						<Localized id='bid-price-per-km-unit' vars={{ currency: bid?.payment?.currency?.symbol }} />
					</div>
				</>
			)) || (
				<>
					<div className='rate'>&nbsp;</div>
					<div className='rate'>&nbsp;</div>
				</>
			)}
			{tab === Orders.Tab.My && (
				<>
					<div className='views'>
						<div className='content'>
							<ViewsIcon />
							{bid?.status?.previews}/{bid?.status?.views}
						</div>
					</div>
					<div className='views'>
						<div className='content'>
							<AnswersIcon />
							{bid?.status?.requests}
						</div>
					</div>
					<div className='views'>
						<div className='content'>
							<InworkIcon />
							{bid?.status?.confirmed}
						</div>
					</div>
				</>
			)}
		</div>
	);
};
