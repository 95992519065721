import { BidApiModel } from 'app/api/types';

export const LIMITS_DEFAULT = {
	dimensions: null,
	loadWeights: null,
	loadingType: null,
	trailerType: null,
	transportType: [],
	workingTime: {
		loading: [
			[480, 1080],
			[480, 1080],
			[480, 1080],
			[480, 1080],
			[480, 1080],
			[-1, -1],
			[-1, -1],
		],
		unloading: [
			[480, 1080],
			[480, 1080],
			[480, 1080],
			[480, 1080],
			[480, 1080],
			[-1, -1],
			[-1, -1],
		],
	},
};

export const normalizeParams = (form: any, prices: BidApiModel.CommonMeta[]): BidApiModel.BidCreateRequest => {
	const {
		isPrepaid,
		isCash,
		isOverload,
		isBargain,
		hasVat,
		tariff,
		isPricePerKm,
		orderType,
		tenderOrderType,
		minWeight,
		dailyWeight,
		tenderFrom,
		tenderTo,
		finalizeType,
	} = form;

	const type = prices.find((el: any) => el.name === `${form.currencySymbol}/${orderType}`) || { id: '' };
	const typeId: string = type?.id;
	// console.log('dfdf', { prices, orderType, searchStr: `${form.currencySymbol}/${orderType}`, type });
	const params: BidApiModel.BidCreateRequest = {
		comment: form.comment,
		limits: form.limits,

		direction: {
			from: { id: form.from.id },
			to: { id: form.to.id },

			autoDistance: form.autoDistance,
			distance: form.distance,
		},

		cargo: {
			weight: form.weight.toString(),
			numberOfCars: form.tripsCount,
			type: { uuid: form.cargo },
			price: form.cargoPrice,
		},

		deliveryTime: {
			from: form.shipmentStart,
			to: form.shipmentEnd,
		},

		payment: {
			type: { uuid: typeId },
			price: parseFloat(parseFloat(form.rate).toFixed(2)),
			isOverload,
			isBargain,
			isPrepaid,
			isCash,
			hasVat,
			isPricePerKm,
		},

		contact:
			(form.contact && {
				uuid: form.contact || '',
			}) ||
			null,

		idle: {
			lossInPercent: form.allowedLossUnit === 'pc',
			loss: form.allowedLoss || 0,

			from: form.keepStart || 0,
			price: form.keepRate || 0,
		},
		customer: {
			uuid: form.company || '',
		},
		tender:
			tenderOrderType === 'tender'
				? {
						minWeight,
						dailyWeight,
						isActive: true,
						from: tenderFrom,
						to: tenderTo,
						finalizeType,
						customer: form.company || '',
						status: 'new',
						suggestedPrice: Math.round(form.rate),
				  }
				: undefined,
		// tariff: tariff
		//     ? {
		//           uuid: tariff,
		//       }
		//     : undefined,
		tariff: tariff || undefined,
		kind: tenderOrderType === 'tender' ? 'tender' : 'bid',
	};

	return params;
};
