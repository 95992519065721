import styled from 'styled-components';

export const DocsZone = styled.div`
	flex: auto;
	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		display: flex;
		overflow: hidden;
	}
`;

export const LeftPanel = styled.div`
	background-color: #f1f2f5;
	position: relative;
	overflow: auto;
	/* border: 2px solid red; */
	width: 418px;
	height: calc(100% - 81px);
	top: 81px;
	box-sizing: border-box;

	&.nopadding {
		padding: 0;
	}

	& > div {
		padding: 20px;
		box-sizing: border-box;
	}
`;

export const FiltersLeftPart = styled.div`
	/* width: 584px; */
	background-color: white;
	display: flex;
	flex-direction: column;
	border-right: 1px solid lightgray;
`;

export const StyledFilterButtons = styled.div`
	display: flex;
`;

export const FiltersRightPart = styled.div`
	display: flex;
	width: 100%;
	background-color: white;
`;

export const PdfContainer = styled.div`
	width: calc(100% - 5px);
	height: calc(100% - 180px);
	margin-top: 81px;
	background-color: white;
`;

export const SignALLButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding-top: 5px !important;
`;

export const ModalTitle = styled.div`
	width: 100%;
	display: flex;
	justify-content: flex-start;
	padding: 10px;
	border-bottom: 1px solid lightgray;
`;

export const DocumentsListContainer = styled.div`
	height: inherit;
	padding-bottom: 5px !important;
`;
