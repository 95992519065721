import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Localized } from '@fluent/react';
import Loader from 'react-loader-spinner';

import { RootState } from 'app/reducers';
import { pricesGetPrices, pricesGetByCropId, pricesGetGrainCrops } from 'app/api';

import PageTitle from 'app/components/PageTitle';
import TableCustom from 'app/components/TableCustom';
import { TabLayout, TabLayoutContent, TabLayoutCenterContent } from 'app/commonPatterns/TabLayout';
import { Notebook } from 'app/components/Icons';
import { setPricesLoading, setPrices } from 'app/actions/prices';

import { SideBarFilter } from './SideBarFilter';
import { getTableProps, types } from './tableProps';
import { TopFilter } from './TopFilter';
import { convertToReactTableProps } from './utils';
import { RTable } from 'app/sharedComponents/Table';

const LoaderContainer = styled.div`
	position: absolute;
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: center;
	align-items: center;
	background-color: rgba(1, 1, 1, 0.2);
	z-index: 99999;
`;

function randomInteger(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const generatePrices = (number: number = 10) => {
	return new Array(number)
		.fill({ timepoint: '2021-07-04T00:00:00+00:00', count: 0 })
		.map((el: any) => ({ ...el, value: randomInteger(0, 100) }));
};

type TopFilterType = {
	buyer: string;
	basis: string;
	region: string;
	currency: string;
};

type Crop = {
	id: string;
	uuid: string;
	name: string;
};

type Crops = {
	[Key: string]: Crop;
};

export const NewPrices = () => {
	const dispatch = useDispatch();

	const [searchType, setSearchType] = useState(types.PRICES);
	const [selectedFrom, setSelectedFrom] = useState({});
	const [crops, setCrops] = useState<Crops>({});
	const [topFilters, setTopFilters] = useState<TopFilterType>({ buyer: '', basis: '', region: '', currency: 'rub' });

	const role = useSelector((state: RootState) => state?.user?.profile?.role);

	const { data, loading } = useSelector((state: RootState) => state?.prices);

	const handleSearch = async (payload: any = {}) => {
		const { from = {}, selectedProduct, cargo } = payload;
		const currentSearchType = from.address && selectedProduct ? types.ROUTE : types.PRICES;

		setSearchType(currentSearchType);
		dispatch(setPricesLoading(true));

		const apiToCall = from.address && selectedProduct ? pricesGetPrices : pricesGetByCropId;
		const { basis, buyer, currency } = topFilters;
		const crop = crops?.[cargo]?.id || '';
		const payloadForApi =
			from.address && selectedProduct
				? { address: from?.address, product: selectedProduct, currency }
				: { crop: crop, basis, buyer, currency };

		//@ts-ignore
		const response = await apiToCall(payloadForApi);

		dispatch(setPrices(response.map((el: any) => ({ ...el, charts: generatePrices() }))));
	};

	const handleFiltersChange = (type: string) => (val: string) => {
		setTopFilters({ ...topFilters, [type]: val });
	};

	useEffect(() => {
		async function fetchCrops() {
			const crops = await pricesGetGrainCrops();
			const reducedCrops = crops.reduce((acc: any, item: any) => ({ ...acc, [item.name]: item }), {});
			setCrops(reducedCrops);
		}
		fetchCrops();
		!data?.length && handleSearch();
	}, []);

	const tableProps = useMemo(
		() =>
			getTableProps({
				// prices: data.sort((a: any, b: any) => b?.[sortFieldMap[searchType]] - a?.[sortFieldMap[searchType]]),
				prices: data,
				typeToShow: searchType,
				from: selectedFrom,
				role,
			}),
		[data],
	);

	const showNewTable = true;
	return (
		<div className='prices page'>
			<PageTitle titleKey='title-prices' />
			<div className='header widget'>
				<div className='container'>
					<div className='title'>
						<Localized id='prices-page-header' />
					</div>
				</div>
			</div>

			<TabLayout>
				<SideBarFilter onSearch={handleSearch} onFromChange={setSelectedFrom} />
				<TabLayoutContent>
					<div className='table-container'>
						<TopFilter filters={topFilters} onChange={handleFiltersChange} />
						{loading && (
							<LoaderContainer>
								<Loader color='#2196F3' height={34} width={34} type='Oval' />
							</LoaderContainer>
						)}

						{!data?.length && (
							<TabLayoutCenterContent>
								<Notebook />
								<div className='title'>
									<Localized id='prices-summary-empty' />
								</div>
							</TabLayoutCenterContent>
						)}
						{data?.length > 0 && (
							<TabLayoutContent>
								{!showNewTable && <TableCustom {...tableProps} />}
								{showNewTable && (
									<RTable
										sticky
										loading={loading}
										data={tableProps.data}
										columns={convertToReactTableProps(tableProps.head)}
									/>
								)}
							</TabLayoutContent>
						)}
					</div>
				</TabLayoutContent>
			</TabLayout>
		</div>
	);
};
