import React, { useState } from 'react';
import styled from 'styled-components';
import MapMark from 'app/sharedComponents/MapMark/MapMark';

export const StyledMarkerContainer = styled.div<{
	hovered?: boolean;
	selected?: boolean;
	routeVisible?: boolean;
	status: string;
}>`
	width: 15px;
	height: 15px;
	border-radius: 25px;
	background-color: ${(props) => (props.hovered ? '#2FCAEF' : colorSelectorByStatus(props.status))};
	box-shadow: -2px 3px 5px 0px rgba(0, 0, 0, 0.75);
	border: 3px solid white;
	${(props) =>
		props.selected &&
		`:after {
        content: '✔';
        position: absolute;
        color: white;
        left: 1px;
        top: 4px;
        width: 20px;
        height: 20px;
        text-align: center;
    }`}
`;

export const BallonContainer = styled.div`
	z-index: 999999999;
	position: absolute;
	transform: translate3d(-50%, -100%, 0);
	margin-top: -48px;
	left: 50%;
	width: fit-content;
`;

export const colorSelectorByStatus = (status: string) => {
	switch (status) {
		case 'confirmed':
			return '#79D9A5';
		case 'open':
			return '#F4AE46';
		case 'free':
		default:
			return '#A5A7BB';
	}
};

export const Marker = ({
	// text,
	hintComponent: Ballon,
	data,
	selected,
	hovered,
	onClick,
	onShowRoute,
	// routeVisible,
	onMouseEnter,
	onMouseLeave,
	status,
	onDoubleClick,
}: any) => {
	const [show, setShow] = useState(false);

	const handleMouseEnter = () => {
		setShow(true);
		onMouseEnter();
	};

	const handleMouseLeave = () => {
		setShow(false);
		onMouseLeave();
	};

	if (!data?.showOnMap) {
		return null;
	}

	return (
		<MapMark
			onDoubleClick={onDoubleClick}
			onClick={onClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			selected={selected}
			hovered={hovered}
			color={colorSelectorByStatus(status)}
		>
			{show && (
				<BallonContainer onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
					<Ballon data={data} onActionRouteClick={onShowRoute} />
				</BallonContainer>
			)}
		</MapMark>
	);
};
