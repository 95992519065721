import { v1 } from 'app/api';
import { Transport } from 'app/models';

import { CarApiModel } from './types';

export async function createNewCar(data: FormData): Promise<Transport> {
	const response = await v1.post<CarApiModel.CarResponse>('/driver/transport/single', data);

	return response.data.data as Transport;
}
