import React from 'react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//components
import LoadingIndicator from 'app/sharedComponents/LoadingIndicator';
import CertificateItem from './certificateItem';

//types
import { DriverCar } from 'app/models';

//----------------------------------------------------------
// Certificates
//----------------------------------------------------------
class Certificates extends React.Component<Certificates.Props, Certificates.State> {
	public state: Certificates.State = {};

	constructor(props: Certificates.Props) {
		super(props);
	}

	public render() {
		const {
			car: { certificates, uuid },
			allVerified,
		} = this.props;

		return (
			<div className='certificates forms list'>
				{certificates.loading && <LoadingIndicator align='left' />}

				{!certificates.loading && (
					<div className='items'>
						{Object.keys(certificates).map((key) => (
							<CertificateItem
								allVerified={allVerified}
								certificate={certificates[key]}
								key={certificates[key].uuid}
								transportId={uuid}
							/>
						))}
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<Certificates.StateProps, Certificates.DispatchProps, Certificates.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(Certificates);

namespace Certificates {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		car: DriverCar;
		allVerified?: boolean;
	}

	// Main component state
	export interface State {}
}
