//redux
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

//types
import { EmptyCar, EmptyCarBase, EmptyCarDimensions, EmptyCarDocuments } from 'app/models';

//actions
export namespace EmptyCarActions {
	export const NEW_TRANSPORT_ID = 'NEW_TRANSPORT_ID';

	export enum Action {
		ADD_CAR_DATA = 'ADD_EMPTY_CAR',
		CREATE_CAR = 'CREATE_NEW_CAR',
		DELETE_CAR_DATA = 'DELETE_EMPTY_CAR',
		SET_LOADING_STATE = 'SET_CAR_CREATION_LOADING_STATE',
		SET_OPENED_STATE = 'SET_CAR_OPENED_STATE',

		UPDATE_BASE_DATA = 'UPDATE_BASE_DATA',
		UPDATE_TRANSPORT_DATA = 'UPDATE_TRANSPORT_DATA',
		UPDATE_TRAILER_DATA = 'UPDATE_TRAILER_DATA',
		UPDATE_POLICY_DATA = 'UPDATE_POLICY_DATA',
		UPDATE_DIMENSIONS_DATA = 'UPDATE_DIMENSIONS_DATA',
	}

	export namespace Payload {
		export interface SagaAction {
			action: string;
			payload: {
				uuid: string;
			};
		}
	}

	export const updateBaseData = createAction<Partial<EmptyCarBase>>(Action.UPDATE_BASE_DATA);
	export const updateTransportData = createAction<Partial<EmptyCarDocuments['transport']>>(
		Action.UPDATE_TRANSPORT_DATA,
	);
	export const updateTrailerData = createAction<Partial<EmptyCarDocuments['trailer']>>(Action.UPDATE_TRAILER_DATA);
	export const updatePolicyData = createAction<Partial<EmptyCarDocuments['policy']>>(Action.UPDATE_POLICY_DATA);
	export const updateDimensionsData = createAction<EmptyCarDimensions>(Action.UPDATE_DIMENSIONS_DATA);

	/*
	/ [inner] actions with _ is inner only actions
	/ [outer] actions without _ can be used from other reducers action
	*/

	export const _addCarData = createAction<EmptyCar>(Action.ADD_CAR_DATA);
	export const _setOpenedState = createAction<boolean>(Action.SET_OPENED_STATE);

	export const setLoadingState = createAction<boolean>(Action.SET_LOADING_STATE);
	export const deleteCarData = createAction(Action.DELETE_CAR_DATA);
	export const createNewCar = createAction<EmptyCar>(Action.CREATE_CAR); //saga action

	export const openEmptyCar = () => (dispatch: Dispatch) => {
		dispatch(_setOpenedState(true));
	};

	export const closeEmptyCar = () => (dispatch: Dispatch) => {
		dispatch(_setOpenedState(false));
	};

	export const addEmptyCar = () => (dispatch: Dispatch) => {
		const newEmptyCarData: EmptyCar = {
			base: { ident: '' },
			documents: {},
			dimensions: {},
		};

		dispatch(_addCarData(newEmptyCarData));
		dispatch(_setOpenedState(true));
	};
}
