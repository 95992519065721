import _ from 'lodash';

import { v1, prepareParams } from 'app/api';
import { Transport, Certificate, Insurance } from 'app/models';

import { DriverApiModel } from './types';

export async function driverGetTransportList(): Promise<Transport[]> {
	const params: { cursor?: string } = {};
	let cursor: string | null | undefined;
	let result: Transport[] = [];

	while (cursor !== null) {
		if (cursor) params.cursor = cursor;
		const response = await v1.get<DriverApiModel.TransportsResponse>('/driver/transport', { params });
		cursor = response.data.meta?.links?.next;
		result = result.concat(response.data.data as Transport[]);
	}

	return result;
}

export async function driverUploadTransportAvatar(
	transportId: string,
	picture: File,
): Promise<{
	avatar?: string;
	photo?: string;
}> {
	const form = new FormData();
	form.append('image', picture);
	const response = await v1.post<DriverApiModel.TransportResponse>(`/driver/transport/${transportId}/upload`, form);
	const { avatar, photo } = response.data.data as Transport;

	return { avatar, photo };
}

export async function driverCreateTransport(data: DriverApiModel.TransportRequest): Promise<Transport> {
	const response = await v1.post<DriverApiModel.TransportResponse>('/driver/transport', prepareParams(data));

	return response.data.data as Transport;
}

export async function driverUpdateTransport(id: string, data: DriverApiModel.TransportRequest): Promise<Transport> {
	const response = await v1.put<DriverApiModel.TransportResponse>(`/driver/transport/${id}`, prepareParams(data));

	return response.data.data as Transport;
}

export async function driverDeleteTransport(id: string): Promise<any> {
	const response = await v1.delete(`/driver/transport/${id}`);

	return response.data.data;
}

export async function driverGetCertificates(id: string): Promise<Certificate[]> {
	const response = await v1.get<DriverApiModel.CertificatesResponse>(
		`/driver/transport/${id}/registration_certificate`,
	);

	return response.data.data as Certificate[];
}

export async function driverTransportCertificate(
	id: string,
	data: DriverApiModel.CertificateRequest,
	action: 'post' | 'patch',
): Promise<Certificate> {
	const files = new FormData();
	const params: any = {};

	Object.keys(data).forEach((key) => {
		params[_.snakeCase(key)] = (data as any)[key];
	});

	Object.keys(params).forEach((key) => {
		if (['main_page', 'owner_page'].includes(key)) {
			const value = params[key];
			files.append(key, value);
			delete params[key];
		}
	});

	const baseResponse = await v1.request<DriverApiModel.CertificateResponse>({
		url: `/driver/transport/${id}/registration_certificate`,
		method: action.toUpperCase(),
		data: params,
	});

	// @ts-ignore
	if ((files.get('main_page') || files.get('owner_page')) && baseResponse.status < 300) {
		files.append('data', JSON.stringify({ uuid: params.uuid }));
		try {
			const response = await v1.patch<DriverApiModel.CertificateResponse>(
				`/driver/transport/${id}/registration_certificate`,
				files,
			);
			return response.data.data as Certificate;
		} catch (error) {
			return baseResponse.data.data as Certificate;
		}
	}

	return baseResponse.data.data as Certificate;
}

export async function driverDeleteCertificate(id: string, certificate: Certificate): Promise<boolean> {
	const response = await v1.request<DriverApiModel.CertificatesResponse>({
		url: `/driver/transport/${id}/registration_certificate`,
		method: 'DELETE',
		data: prepareParams(certificate),
	});

	return response.data.data as any;
}

export async function driverGetInsurances(id: string): Promise<Insurance[]> {
	const response = await v1.get<DriverApiModel.InsurancesResponse>(`/driver/transport/${id}/insurance_policy`);

	return response.data.data as Insurance[];
}

export async function driverTransportInsurance(
	id: string,
	data: DriverApiModel.InsuranceRequest,
	action: 'post' | 'patch',
): Promise<Insurance> {
	const files = new FormData();
	const params: any = {};

	Object.keys(data).forEach((key) => {
		params[_.snakeCase(key)] = (data as any)[key];
	});

	Object.keys(params).forEach((key) => {
		if (['main_page'].includes(key)) {
			const value = params[key];
			files.append(key, value);
			delete params[key];
		}
	});

	const baseResponse = await v1.request<DriverApiModel.InsurancesResponse>({
		url: `/driver/transport/${id}/insurance_policy`,
		method: action.toUpperCase(),
		data: params,
	});

	// @ts-ignore
	if (files.get('main_page') && baseResponse.status < 300) {
		const baseData = (baseResponse as any).data.data;
		files.append('data', JSON.stringify({ uuid: baseData.uuid, id: baseData.uuid }));
		try {
			const response = await v1.patch<DriverApiModel.InsuranceResponse>(
				`/driver/transport/${id}/insurance_policy`,
				files,
			);
			return response.data.data as Insurance;
		} catch (error) {
			return baseResponse.data.data as unknown as Insurance;
		}
	}

	return baseResponse.data.data as unknown as Insurance;
}

export async function driverDeleteInsurance(parentTransportId: string, insurance: Insurance): Promise<boolean> {
	const response = await v1.request<DriverApiModel.InsurancesResponse>({
		url: `/driver/transport/${parentTransportId}/insurance_policy`,
		method: 'DELETE',
		data: prepareParams(insurance),
	});

	return response.data.data as any;
}
