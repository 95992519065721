import React from 'react';
import { Localized } from '@fluent/react';
import { UserProfile, RoleEnum } from 'app/models';
import { Orders } from './types';

const OrdersTabs = (props: OrdersTabsProps) => {
	const { tab, profile, setTab } = props;
	const currentTab = tab;
	const isCustomer = (profile || ({} as UserProfile)).role === RoleEnum.Customer;
	const tabsForCustomer = [
		{ text: 'orders-tab-my', tab: Orders.Tab.My },
		{ text: 'orders-tab-archive', tab: Orders.Tab.Archive },
	];
	const items = [{ text: 'orders-tab-all', tab: Orders.Tab.All }, ...(isCustomer ? tabsForCustomer : [])];
	return (
		<div className='menu'>
			{items.map(({ text, tab }, index) => {
				const className = `item ${currentTab === tab ? 'active' : ''}`;
				const onClick = () => setTab(tab);
				const props = { className, onClick, key: `tab ${tab} ${index}` };

				return (
					<div {...props} key={`orders-tab-${index}`}>
						<Localized id={text} />
					</div>
				);
			})}
		</div>
	);
};

interface OrdersTabsProps {
	tab: Orders.Tab;
	profile: any;
	setTab: Function;
}

export default OrdersTabs;
