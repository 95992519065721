import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { InfoColumns, IconMarker } from '../components/Common';
import { makePlace } from 'app/utils/transport';

import { CAPABILITIES } from 'app/models/bid';

const ICON_LOADING = require('assets/icons/svg/ic_shipment_small.svg');
const ICON_TO = require('assets/icons/svg/ic_landing_small.svg');
const ICON_REPLIES = require('assets/icons/svg/ic_reply_micro.svg');
const ICON_APPROVES = require('assets/icons/svg/ic_approve_micro.svg');
// const ICON_VIEWS = require('assets/icons/svg/ic_view_micro.svg');
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
import { confirmCustomerOffer, declineCustomerOffer } from 'app/actions/customer';
import { Localized } from '@fluent/react';
import { useModalContext } from 'app/containers/Modal';
import { BigCar as IconDriver } from 'app/components/Icons';
import WhiteButton from 'app/sharedComponents/Button/White';
import { useMyTransportContext } from '../Candidates';

const WhiteButtonFullwidth = styled<any>(WhiteButton)`
	width: 100%;
	/* background-color: #eee; */
	box-shadow: rgba(103, 116, 150, 0.247) 0px 0px 4px 0px;
	margin-top: 10px;
	margin-bottom: 10px;
	background: ${(props) => (props.selected ? '#d6f3fd' : 'inherit')};
`;

const OfferCardContainer = styled.div<any>`
	min-height: 112px;
	background: ${(props) => (props.selected ? '#d6f3fd' : '#fff')};
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: left;
	/* width: 100%; */
	padding: 10px 13px;
	margin-bottom: 10px;
	margin-top: 10px;
	border-radius: 5px;
	box-shadow: rgba(103, 116, 150, 0.247) 0px 0px 4px 0px;
`;

const Separator = styled.div<any>`
	width: 100%;
	border: 1px solid lightgray;
	margin-top: 5px;
	margin-bottom: 5px;
	${({ fat }) =>
		fat &&
		`
		border: 2px solid lightgray;
  `}
`;

const Stats = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	margin-top: 10px;
`;

const StatsValue = styled.div`
	margin: 0 16px;
	text-align: center;
	font-size: 14px;
	color: #6c6f88;
	display: inline-flex;
	flex-direction: row;
	align-items: center;

	& > img {
		width: 19px;
		height: 19px;
		margin-right: 7px;
	}

	& > span,
	& > img {
		display: inline-block;
		vertical-align: top;
	}
`;

const TermsLine = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 14px;
	color: #6c6f88;
	font-weight: 400;
`;

const TermsContainer = styled.div`
	margin-top: 15px;
	font-size: 14px;
	font-weight: 700;
`;

const ButtonsContainer = styled.div`
	display: flex;
	justify-content: space-between;
	padding: 10px;
`;

const ModalContent = ({ text }: any) => {
	return (
		<div>
			<div>
				<IconDriver />
			</div>
			<div>{text}</div>
		</div>
	);
};

const ModalActions = ({ onConfirm, onCancel }: any) => (
	<>
		<Button onClick={onConfirm} key='button-modal-confirm-yes' styleType={ButtonStyleTypes.DEFAULT}>
			<Localized id='yes' />
		</Button>
		,
		<Button styleType={ButtonStyleTypes.PRIMARY} onClick={onCancel} key='button-modal-confirm-no'>
			<Localized id='not' />
		</Button>
	</>
);

const getModalContent = (text: any) => <ModalContent text={text} />;
const getModalActions = (onConfirm: any, onCancel: any) => <ModalActions onConfirm={onConfirm} onCancel={onCancel} />;

export const OfferCard = ({ offer = {} }: any) => {
	const { bid, price, startAt, id, status } = offer;
	//@ts-ignore
	const { onShowBid, selectedBid, showBidDetails } = useMyTransportContext<any>();
	const dispatch = useDispatch();
	const { handleModal } = useModalContext();
	const handleShowBid = () => onShowBid(bid.id);
	const handleShowBidDetails = () => showBidDetails(bid.id);
	const confirmOffer = () => {
		handleModal({
			content: getModalContent('Подтвердить этот оффер?'),
			actions: getModalActions(
				() => {
					dispatch(confirmCustomerOffer({ id: bid.id, offer: id }));
					handleModal();
				},
				() => handleModal(),
			),
		});
	};

	const declineOffer = () => {
		handleModal({
			content: getModalContent('Отклонить этот оффер?'),
			actions: getModalActions(
				() => {
					dispatch(declineCustomerOffer({ id: bid.id, offer: id }));
					handleModal();
				},
				() => handleModal(),
			),
		});
	};

	return (
		<OfferCardContainer>
			<InfoColumns>
				<div>{bid?.cargo?.type?.name}</div>
				<div>{bid?.direction?.distance} км</div>
				<div>{`${bid?.payment?.price} ${bid?.payment?.type?.name}`}</div>
			</InfoColumns>
			<div>
				<IconMarker>
					<img src={ICON_LOADING} alt='Погрузка' />
					{makePlace(bid?.direction?.from) || ''}
				</IconMarker>
				<IconMarker>
					<img src={ICON_TO} alt='Место назначения' />
					{makePlace(bid?.direction?.to) || ''}
				</IconMarker>
				<IconMarker>
					<span>
						{bid?.deliveryTime?.from} - {bid?.deliveryTime?.to}
					</span>
				</IconMarker>
			</div>
			<Stats>
				<StatsValue title='Ответы'>
					<img src={ICON_REPLIES} alt='Ответы' />
					<IconMarker>{bid?.status?.requests} ответов</IconMarker>
				</StatsValue>
				<StatsValue title='В работе'>
					<img src={ICON_APPROVES} alt='В работе' />
					<IconMarker>{bid?.status?.confirmed} водителей в работе</IconMarker>
				</StatsValue>
			</Stats>
			<WhiteButtonFullwidth onClick={handleShowBidDetails}>Посмотреть заявку полностью</WhiteButtonFullwidth>
			<WhiteButtonFullwidth selected={selectedBid === bid?.id} onClick={handleShowBid}>
				Посмотреть всех водителей
			</WhiteButtonFullwidth>
			<Separator />
			<TermsContainer>
				<p>Условия от перевозчика</p>
				<TermsLine>
					<span>Дата начала</span>
					<span>{startAt}</span>
				</TermsLine>
				<TermsLine>
					<span>Ставка</span>
					<span>{price}р/т</span>
				</TermsLine>
			</TermsContainer>
			{status === 'open' && (
				<ButtonsContainer>
					{offer?.capabilites?.includes(CAPABILITIES.CONFIRM) && (
						<Button styleType={ButtonStyleTypes.PRIMARY} onClick={confirmOffer}>
							Подтвердить
						</Button>
					)}
					{offer?.capabilites?.includes(CAPABILITIES.DECLINE) && (
						<Button styleType={ButtonStyleTypes.DEFAULT} onClick={declineOffer}>
							Отклонить
						</Button>
					)}
				</ButtonsContainer>
			)}
		</OfferCardContainer>
	);
};
