import * as React from 'react';

//other deps
import { Localized } from '@fluent/react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//schemes
import { TRAILER_IDENT_REGEX } from '../../schemes';

//screen parts
import CertificateItem from './features/certificateItem';
import InsuranceItem from './features/insuranceItem';

//components
import * as Icons from 'app/components/Icons';

//types
import { EmptyCar } from 'app/models';

//constants
export const VERIFIED = 'verified';

//----------------------------------------------------------
// DocsCar
//----------------------------------------------------------
class DocsCar extends React.Component<DocsCar.Props, DocsCar.State> {
	public state: DocsCar.State = {};

	constructor(props: DocsCar.Props) {
		super(props);
	}

	private isTrailerIdentValid(): boolean {
		const { carData } = this.props;

		if (carData?.base?.trailerIdent && TRAILER_IDENT_REGEX.test(carData?.base?.trailerIdent)) return true;

		return false;
	}

	public render() {
		const { carData } = this.props;

		return (
			<div className='car docs form'>
				<div className='photos'>
					<Icons.VerifyUser />
				</div>

				<div className='common'>
					<div className='header'>
						<div className='title'>
							<Localized id='transports-docs-title' />
						</div>

						<div className='subtitle'>
							<Localized id='transports-docs-description' />
						</div>

						<div className='certificates forms list'>
							<div className='items'>
								<CertificateItem car={carData} certificateType='transport' />

								{this.isTrailerIdentValid() && <CertificateItem car={carData} certificateType='trailer' />}
							</div>
						</div>

						<div className='insurances forms list'>
							<div className='items'>
								<InsuranceItem insurance={carData?.documents?.policy ?? null} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<DocsCar.StateProps, DocsCar.DispatchProps, DocsCar.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(DocsCar);

namespace DocsCar {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		carData?: EmptyCar | null;
	}

	// Main component state
	export interface State {}
}
