import React from 'react';
import styled from 'styled-components';

//components
const Container = styled.div`
	width: calc(100% - 5px);
	height: calc(100% - 84px);
	margin-top: 81px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: UniNeue;
`;

//types
type EmptyStateProps = {
	text?: string;
};

//constants
const DEFAULT_TEXT = 'Выберите документ, чтобы увидеть подробную информацию';

//----------------------------------------------------------
// EmptyState
//----------------------------------------------------------
export const EmptyState = ({ text }: EmptyStateProps): JSX.Element => {
	return <Container>{text || DEFAULT_TEXT}</Container>;
};
