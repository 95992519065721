import React from 'react';
import styled from 'styled-components';

import { Localized } from '@fluent/react';

export type TabItem = {
	text: string;
	tabId: string;
};

export type TabsProps = {
	activeTab: string;
	tabs: TabItem[];
	onTabClick: (tabId: any) => void;
};

const TabsContainer = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const TabItem = styled.div<any>`
	display: inline-block;
	color: #fff;
	transition: all 0.25s ease;
	text-decoration: none;
	margin-right: 17px;
	padding-left: 8px;
	padding-right: 8px;
	padding-bottom: 16px;
	letter-spacing: 0;
	font-weight: 500;
	cursor: pointer;
	box-sizing: border-box;
	border-bottom: ${(props) => (props.active ? '4px solid #FFF' : '4px solid transparent')};
	&:hover {
		border-bottom: 4px solid #fff;
	}
`;

export const Tabs = (props: TabsProps) => {
	const { activeTab, tabs, onTabClick } = props;

	return (
		<TabsContainer>
			{tabs.map(({ text, tabId }) => {
				return (
					<TabItem onClick={() => onTabClick(tabId)} active={activeTab === tabId} key={`tab ${tabId}`}>
						<Localized id={text} />
					</TabItem>
				);
			})}
		</TabsContainer>
	);
};
