import React from 'react';
import { Localized } from '@fluent/react';
import { BigCar } from 'app/components/Icons';

const NoSelectedCar = () => (
	<div className='info-message'>
		<div className='content'>
			<div className='icon'>
				<BigCar />
			</div>

			<div className='title'>
				<Localized id='transports-page-select-car' />
			</div>
		</div>
	</div>
);

export default NoSelectedCar;
