import * as Components from './components';
import { Rate as RateApi } from 'app/api';
import * as Model from 'app/models';
import { Place } from 'app/models';

export enum BasicTypes {
	Checkbox = 'Checkbox',
	Select = 'Select',
	MultiSelect = 'MultiSelect',
	Toggle = 'Toggle',
	Slider = 'Slider',
	Header = 'Header',
	Range = 'Range',
	Input = 'Input',
	Date = 'Date',
	DateTime = 'DateTime',

	// Complex fields
	ImagePicker = 'ImagePicker',
	Contacts = 'Contacts',
	Company = 'Company',
	Address = 'Address',
	Group = 'Group',
	Bank = 'Bank',
	Rate = 'Rate',
	Map = 'Map',
	CompanyPreview = 'CompanyPreview',
}

export namespace BasicTypes {
	export const COMPONENTS = {
		ImagePicker: Components.ImagePicker,
		Checkbox: Components.Checkbox,
		Contacts: Components.Contacts,
		Bank: Components.BankSuggest,
		Address: Components.Address,
		Date: Components.SelectDate,
		DateTime: Components.SelectDateTime,
		Company: Components.Company,
		CompanyPreview: Components.CompanyPreview,
		Select: Components.Select,
		MultiSelect: Components.MultiSelect,
		Toggle: Components.Toggle,
		Slider: Components.Slider,
		Header: Components.Header,
		Input: Components.Input,
		Group: Components.Group,
		Range: Components.Range,
		Rate: Components.Rate,
		Map: Components.Map,
	};
}

export enum Validate {
	Number = 'number',
	Price = 'price',
	None = 'none',
}

export namespace Field {
	export type Component<T = {}> = React.Component & Field.Hooks<T>;

	// Basic repr of extenral props state in field component
	export interface ComponentProps<U, F> {
		onUpdate: (name: string, value: U) => void;
		field: F;

		// Reflection: current form values
		form: {
			[name: string]: any;
		};
	}

	export interface Props {
		localized?: boolean;
		type: BasicTypes;
		label: string;
		name: string;
		hide?: boolean;
		showTime?: boolean;
		noBorder?: boolean;
		showIcon?: boolean;
	}

	export interface Callbacks<T> {
		onUpdate?: (name: string, value: T) => Promise<void>;
	}

	export interface Hooks<T> {
		updateValue(value: T): Promise<void>;
		reset(): void;
	}

	export interface CustomComponent<T> {
		component?: Component<T>;
	}

	export interface ExtendComponent {
		// Custom component prepend of input with field`s as props
		extendComponentProps?: { [key: string]: any };
		extendComponent?: React.FC<any>;
	}

	export interface Prepare<T> {
		// Receive current props before re-render, and extend props with object from this
		prepareProps?: (form: any, field: T) => T;
	}

	export interface Values<T> {
		defaultValue?: T;
	}

	export interface Hidden {
		hidden: boolean;
	}

	export interface Validation {
		validation: Validate;
	}

	export interface Choice<T> {
		category?: string;
		label: string;
		value: T;
	}

	export interface BaseInput extends Props, Hidden {
		required: boolean;
		disabled: boolean;
	}
}

export namespace SchemeProps {
	export type Union =
		| ImagePicker
		| Select<any>
		| MultiSelect<any>
		| Field.Props
		| Company
		| Slider
		| Toggle
		| Input
		| Group
		| Range
		| Bank
		| Rate
		| Map;

	export type Form = Union[];

	export interface Group extends Field.Prepare<any> {
		direction: 'column' | 'row';
		type: BasicTypes.Group;
		className: string;
		hidden?: boolean;
		contains: Form;
	}

	export type UnionInput<T, C, P = any> = Field.Callbacks<C> & Field.Prepare<P> & Field.BaseInput & Field.Values<T>;

	export interface RangeValue {
		min: number;
		max: number;
	}

	export interface Range extends UnionInput<RangeValue, RangeValue>, RangeValue {
		presetDefaultValue?: boolean;
		type: BasicTypes.Range;
		step: number;
	}

	export interface Slider extends UnionInput<number, number> {
		type: BasicTypes.Slider;
		step: number;
		min: number;
		max: number;
	}

	export interface Toggle extends UnionInput<any, any> {
		type: BasicTypes.Toggle;
	}

	export interface Checkbox extends UnionInput<any, any> {
		type: BasicTypes.Checkbox;
	}

	export interface SelectDate extends UnionInput<Date, string> {
		type: BasicTypes.Date;
		rounded?: boolean;

		rules?: Function[];
	}

	export interface SelectDateTime extends UnionInput<Date, string> {
		type: BasicTypes.Date;
		rounded?: boolean;

		rules?: Function[];
	}
	export interface ImagePicker extends UnionInput<any, any> {
		type: BasicTypes.ImagePicker;
	}

	export interface RateDefaultValues {
		currencySymbol: string;
		isOverload: boolean;
		isPrepaid: boolean;
		isBargain: boolean;
		hasVat: boolean;
		isCash: boolean;
		isPricePerKm: boolean;
		hideSetupBlock?: boolean;
		type: RateApi.ByType;
	}

	export interface Rate extends Field.Props {
		defaultValues: RateDefaultValues;
		type: BasicTypes.Rate;
	}

	export interface Company extends UnionInput<Model.Company, Model.Company>, Field.ExtendComponent {
		type: BasicTypes.Company;
		hidden: boolean;
		returnType?: string;
		inputProps?: {
			onBlur?: () => void;
		};
	}

	export interface Address extends UnionInput<Place, Place> {
		type: BasicTypes.Address;
		inputProps?: {
			onBlur?: () => void;
		};
	}

	export interface Bank extends UnionInput<any, any> {
		type: BasicTypes.Bank;
		inputProps?: {
			onBlur?: () => void;
		};
		rules?: Function[];
		mask?: string;
	}

	export interface Contacts extends UnionInput<string, string> {
		type: BasicTypes.Contacts;
	}

	export interface Map extends Field.Props {
		placesBy: [string, string];
		type: BasicTypes.Map;
		onFocus?: () => void;
	}

	export interface Select<T> extends UnionInput<Field.Choice<T>, any> {
		choices?: Array<Field.Choice<T>>;
		disableLocalizeForOption?: boolean;
		type: BasicTypes.Select;
		rounded?: boolean;
		isMulti?: boolean;
		name: string;
		async?: boolean;
		inputProps?: {
			onSelect?: () => void;
			onInput?: (value: any) => void;
			loadingOptions?: (inputValue: any, callback: any) => void;
		};
	}

	export interface MultiSelect<T> extends UnionInput<Field.Choice<T>, any> {
		choices?: Array<Field.Choice<T>>;
		disableLocalizeForOption?: boolean;
		type: BasicTypes.MultiSelect;
		rounded?: boolean;
		isMulti?: boolean;
		name: string;
		inputProps?: {
			onSelect?: () => void;
		};
	}

	export interface Input extends UnionInput<string, string>, Field.Validation, Field.ExtendComponent {
		preventSpace?: boolean;
		multiline?: boolean;
		rounded?: boolean;
		uppercase?: boolean;

		// For raw-text
		mask?: string | ((value: string) => string);

		// For numeric
		step?: string;
		min?: string;
		max?: string;
		form?: any;

		rules?: Function[];
	}
}
