import { createAction } from 'redux-actions';

export namespace FilterActions {
	export enum Action {
		SET_FILTER = 'SET_FILTER',
	}
	export namespace Payload {
		export interface Filter {
			[key: string]: boolean;
		}
	}
}

export const setFilter = createAction<FilterActions.Payload.Filter>(FilterActions.Action.SET_FILTER);
