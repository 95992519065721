import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
// import Sidebar from "app/components/Sidebar"
import { SidebarType } from 'app/models';
import { RootState } from 'app/reducers';
import { connect } from 'react-redux';

import CreateContact from './CreateContact';
import UpdateContact from './UpdateContact';
import CreateOrder from './CreateOrder';
import UpdateOrder from './UpdateOrder';
import Details from './Details';
import { TenderView } from '../TenderView';

const mapStateToProps = ({ common, user }: RootState) => ({
	openedSidebar: common.openedSidebar,
	sessionToken: user.sessionToken,
	profile: user.profile,
	cargos: common.cargos,
	prices: common.prices,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

class ActionSidebar extends React.Component<ActionSidebar.Props, ActionSidebar.State> {
	public state: ActionSidebar.State = {};

	constructor(props: ActionSidebar.Props) {
		super(props);

		this.getSidebarContent = this.getSidebarContent.bind(this);
		this.getSidebarPose = this.getSidebarPose.bind(this);
	}

	private getSidebarContent() {
		const { openedSidebar } = this.props;
		if (!openedSidebar) return null;

		switch (openedSidebar[0]) {
			case SidebarType.ContactCreate:
				return <CreateContact />;
			case SidebarType.ContactEdit:
				return <UpdateContact />;
			case SidebarType.OrderEdit:
				return <UpdateOrder />;
			case SidebarType.OrderCreate:
				return <CreateOrder />;
			case SidebarType.OrderDetails:
				return <Details />;
			case SidebarType.TenderView:
				return <TenderView />;
			default:
				return null;
		}
	}

	private getSidebarPose() {
		const { openedSidebar } = this.props;

		return openedSidebar && openedSidebar[1] ? 'opened' : 'closed';
	}

	public render() {
		const { openedSidebar } = this.props;

		const pose = this.getSidebarPose();
		let className = `sidebar right ${pose} `;

		if (openedSidebar && openedSidebar[0] === SidebarType.OrderDetails) {
			className += 'details';
		} else {
			className += 'create';
		}

		const props = {
			className,
			pose,
		};
		// console.log('---sidebar props', props);
		return <div {...props}>{this.getSidebarContent()}</div>;
	}
}

export default connect<ActionSidebar.StateProps, ActionSidebar.DispatchProps, ActionSidebar.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(ActionSidebar);

namespace ActionSidebar {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		openedSidebar?: [SidebarType, boolean, any];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
