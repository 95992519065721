import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Localized } from '@fluent/react';
import clsx from 'clsx';
import { push as pushAction } from 'connected-react-router';
import { Dispatch, bindActionCreators } from 'redux';
import queryString from 'query-string';

import { RootState } from 'app/reducers';
import { SidebarType, RoleEnum } from 'app/models';

import { CommonActions, BidActions } from 'app/actions';
import { OrdersFilter } from 'app/containers/OrdersFilter';
import PageTitle from 'app/components/PageTitle';
import { Filter as FilterIcon } from 'app/components/Icons';

import OrdersList from './OrdersList';
import OrdersTabs from './OrdersTabs';

import { Orders as OrdersNameSpace } from './types';

const mapStateToProps = ({ user, common, bid }: RootState) => ({
	filtersOpened: bid.filtersOpened,
	sessionToken: user.sessionToken,
	profile: user.profile,
	common,
	filters: bid.filters,
	list: {
		[OrdersNameSpace.Tab.Archive]: bid.archive.list,
		[OrdersNameSpace.Tab.All]: bid.all.list,
		[OrdersNameSpace.Tab.My]: bid.my.list,
	},
	cursor: {
		[OrdersNameSpace.Tab.Archive]: bid.archive.cursor,
		[OrdersNameSpace.Tab.All]: bid.all.cursor,
		[OrdersNameSpace.Tab.My]: bid.my.cursor,
	},
	loader: {
		[OrdersNameSpace.Tab.Archive]: bid.archive.loading,
		[OrdersNameSpace.Tab.All]: bid.all.loading,
		[OrdersNameSpace.Tab.My]: bid.my.loading,
	},
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleFiltersToolbar: BidActions.toggleFiltersToolbar,
			toggleSidebar: CommonActions.toggleSidebar,
			getDetailsBid: BidActions.getDetailsBid,
			getBids: BidActions.getBids,
			push: pushAction,
		},
		dispatch,
	);

const OrdersView = (props: OrdersNameSpace.Props & OrdersNameSpace.State) => {
	const role = props.profile && props.profile.role;
	const defaultTab = role === RoleEnum.Customer ? OrdersNameSpace.Tab.My : OrdersNameSpace.Tab.All;
	const [tab, setTab] = useState(defaultTab);
	const [compact, setCompact] = useState(false);
	const [currentSort, setCurrentSort] = useState('');

	useEffect(() => {
		//@ts-ignore
		const { sessionToken, getBids, filters } = props;
		if (sessionToken) {
			const type = OrdersNameSpace.tabMapper[tab].bidType;
			const ordering = [currentSort];
			getBids({ type, ordering, ...filters });
		}
	}, [tab, currentSort]);

	const hanleOrderCreate = async () => {
		const { toggleSidebar, common } = props;
		if (toggleSidebar && common) {
			if (common.openedSidebar[0] !== SidebarType.OrderCreate || !common.openedSidebar[1]) {
				await toggleSidebar({
					type: SidebarType.OrderCreate,
					value: true,
				});
			}
		}
	};
	const handleSetTab = (tab: OrdersNameSpace.Tab) => {
		setTab(tab);
		setCurrentSort('');
	};
	const onRowClick = async (bidId: string) => {
		const { getDetailsBid, sessionToken, push, toggleSidebar } = props;

		if (getDetailsBid && toggleSidebar && bidId && sessionToken && push) {
			await getDetailsBid(bidId, sessionToken);
			await toggleSidebar({ type: SidebarType.OrderDetails, value: true });

			await push({
				search: queryString.stringify({
					bid: bidId,
				}),
			});
		}
	};

	const onLoadMore = async () => {
		//@ts-ignore
		const { getBids, cursor, sessionToken, filters } = props;

		const next = cursor[tab].next || undefined;
		if (sessionToken && next) {
			getBids(
				{
					type: OrdersNameSpace.tabMapper[tab].bidType,
					ordering: [currentSort],
					...filters,
				},
				next,
				true,
			);
		}
	};

	const onSort = (sort: string) => {
		const sortColumn = sort.replace('-', '');
		const isDownSorting = sort[0] === '-';
		if (sortColumn === currentSort && !isDownSorting) {
			setCurrentSort('-' + sortColumn);
		} else {
			setCurrentSort(sortColumn);
		}
	};
	const toggleFilters = () => props.toggleFiltersToolbar?.();

	const { profile, list, cursor, loader } = props;
	const isProfileFilled =
		profile && !profile.needActions.includes('fill_profile') && !profile.needActions.includes('company');

	const hideAction = !profile || profile.role !== RoleEnum.Customer;
	const actionTextId = isProfileFilled ? 'orders-action-create' : 'orders-attention-fill-profile';

	return (
		<div className='orders page'>
			<PageTitle titleKey='title-orders' />
			<div className={clsx('header widget', { compact })}>
				<div className='container'>
					<div className='title'>
						<Localized id='orders-page-title' />
					</div>
					<div className='actions'>
						<OrdersTabs tab={tab} setTab={handleSetTab} profile={profile} />
						<div className='filter-icon-container'>
							<div className='filter-button' onClick={toggleFilters}>
								<FilterIcon />
								<Localized id='orders-filters-button' />
							</div>
							{!hideAction && (
								<button disabled={!isProfileFilled} className='action' onClick={hanleOrderCreate}>
									<Localized id={actionTextId} />
								</button>
							)}
						</div>
					</div>
				</div>
			</div>

			<OrdersFilter />
			<OrdersList
				onSort={onSort}
				cursor={cursor && cursor[tab]}
				onLoadMore={onLoadMore}
				onRowClick={onRowClick}
				key={OrdersNameSpace.tabMapper[tab].key}
				list={list && list[tab]}
				tab={tab}
				compact={compact}
				setCompact={setCompact}
				currentSort={currentSort}
				loader={loader && loader[tab]}
			/>
		</div>
	);
};

export default connect<OrdersNameSpace.StateProps, OrdersNameSpace.DispatchProps, OrdersNameSpace.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(OrdersView);
