//constants
export const EVENTS_THROTTLING_TIMEOUT = 1000;

const events: {
	[eventKey: string]: Array<(message: any) => void>;
} = {};

const hOP = events.hasOwnProperty;

//utils
function throttleWithArgumentsStack<TItem>(originListener: (data: TItem[]) => void, delay: number) {
	let waiting = false;
	let previousArguments: TItem[] = [];

	return function (message: TItem) {
		previousArguments.push(message);

		if (!waiting) {
			waiting = true;
			setTimeout(function () {
				originListener(previousArguments);

				previousArguments = [];
				waiting = false;
			}, delay);
		}
	};
}

export default {
	subscribe<TMessage>(event: string, listener: (data: TMessage[]) => void) {
		const isEventHasSavedEventKey = hOP.call(events, event);

		if (!isEventHasSavedEventKey) events[event] = [] as Array<(data: TMessage) => void>;

		const throttledListener = throttleWithArgumentsStack<TMessage>(listener, EVENTS_THROTTLING_TIMEOUT);

		events[event][0] = throttledListener;

		return {
			remove() {
				delete events[event];
			},
		};
	},
	publish<TMessage>(event: string, message: TMessage) {
		const isEventHasSavedEventKey = hOP.call(events, event);

		if (!isEventHasSavedEventKey) return;

		events[event].forEach((fn: (arg: TMessage) => void) => {
			fn(message);
		});
	},
};
