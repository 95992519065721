import { UserApiModel } from 'app/api/types';

export interface DriverDocs {
	driverLicence: DriverLicence;
	passport: Passport;

	bankAccount?: BankAccount;
}

export interface UserProfile extends UserApiModel.UserProfile {
	isDispatcher?: boolean;
	interestedInDigitalSignature: boolean;

	currency: UserCurrency;
	role: RoleEnum;
	phone: string;
	uuid: string;
	needActions: string[];
	verification: UserApiModel.VerificationResponseData;

	driverDocs?: DriverDocs;

	certificates?: {
		[uuid: string]: UserCertificate;
	};
	defaultCompany: { name: string; uuid: string };
	defaultDispatcher: any;
}

export interface UserVerifyStatus {
	company?: boolean;
	email?: boolean;
}

export interface UserSettings {
	onTransportStatusChanged: boolean;
	onBidStatusChanged: boolean;
	onNewMessage: boolean;
	onNewOffers: boolean;
	onNewChat: boolean;
	sendEmail: boolean;
	sendPush: boolean;
	tendersAllowed: boolean;
}

export interface UserCurrency {
	symbol: string;
	name: string;
	code: string;
}

export interface UserContact {
	isAutoCreated: boolean;
	isVerified?: boolean;
	middleName: string;
	firstName: string;
	lastName: string;
	avatar?: string;
	photo?: string;
	phone: string;
	uuid: string;
}

export interface UserCertificate {
	issuedAt: string;
	issuedBy: string;
	document: string;
	number: string;
	kind: string;
	uuid: string;
	id: string;
}

export enum RoleEnum {
	Customer = 'customer',
	Driver = 'driver',
}

export enum NeedActionsEnum {
	FillProfile = 'fill_profile',
	SelectRole = 'select_role',
	Company = 'company',
}

export enum VerifyStatus {
	OnVerification = 'on_verification',
	DetailsNeeded = 'details_needed',
	Verified = 'verified',
}

export interface Verification {
	requiredFields: string[];
	status: VerifyStatus;
	comment: string;
}

export interface Passport {
	verification: Verification;
	issuedAt: string;
	number: string;
	issuer: string;

	issuerCode: string;
	registrationAddress: string;
	birthday: string;
	birthplace: string;
	snils: string;
	snilsPage: string;

	registrationPage: string;
	mainPage: string;
}

export interface DriverLicence {
	verification: Verification;
	number: string;

	expiredAt: string;
	issuedAt: string;
	mainPage: string;
	backPage: string;
}

export interface BankAccount {
	account: string;
	bic?: string;
}

export enum oldLegalTypeEnum {
	Np = 'np',
	Self = 'self',
	Ojsc = 'ojsc',
	Cjsc = 'cjsc',
	Farm = 'farm',
	Ltd = 'ltd',
}

export enum LegalTypeEnum {
	Np = 'np',
	Company = 'self',
}
