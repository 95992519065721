import russianLocale from 'date-fns/locale/ru';
import { formatDistance } from 'date-fns/esm';
import { format } from 'date-fns';
// import { TENDER_CAPABILITIES, Bid, TenderCapabilitiesType } from 'app/models/bid';
import { TENDER_CAPABILITIES, Bid, TenderCapabilitiesType } from 'app/models/bid';

export const TENDER_STATUS = {
	ACTIVE: 'ACTIVE',
	FINISHED: 'FINISHED',
	NOT_STARTED: 'NOT_STARTED',
} as const;

export const statusMapper = {
	[TENDER_STATUS.ACTIVE]: 'Тендер идет',
	[TENDER_STATUS.FINISHED]: 'Тендер завершен',
	[TENDER_STATUS.NOT_STARTED]: 'Начало тендера',
};

export const TENDER_PROGRESS = {
	CONFIRMED: 'confirmed',
	DOING: 'doing',
	FINISHED: 'finished',
	DONE: 'done',
	CANCELED: 'canceled',
	NEW: 'new',
} as const;

type TenderProgressType = (typeof TENDER_PROGRESS)[keyof typeof TENDER_PROGRESS];

type TENDER_STATUS = (typeof TENDER_STATUS)[keyof typeof TENDER_STATUS];

export const TENDER_VIEW_ACTIONS = {
	DENY_TENDER: 'deny',
	APPROVE_TENDER: 'approve',
	UPDATE_OFFER: 'updateOffer',
	ADD_OFFER: 'addOffer',
	SHOW_OFFER: 'showOffer',
} as const;

export type TenderViewActionsType = (typeof TENDER_VIEW_ACTIONS)[keyof typeof TENDER_VIEW_ACTIONS];

const formatDate = (date: any) => {
	try {
		return format(new Date(date), 'dd LLLL yyyy HH:ss', { locale: russianLocale });
	} catch (e) {
		return { tenderDate: date, distance: 0 };
	}
};

export type TenderStatus = {
	from: any;
	to: any;
	status: TENDER_STATUS;
	isActive: boolean;
	ended: boolean;
	notStarted: boolean;
	distanceTo: string;
	distanceFrom: string;
	finalizeType: 'manual_on_expire' | 'auto_on_expire';
	tenderProgress?: TenderProgressType;
};

export const getTenderStatus = ({ from, to, status, finalizeType }: Bid.Tender): TenderStatus => {
	const currentDate = new Date();

	try {
		return {
			from: formatDate(from),
			to: formatDate(to),
			isActive: currentDate > new Date(from) && currentDate < new Date(to),
			ended: currentDate > new Date(to),
			notStarted: currentDate < new Date(from),
			status:
				currentDate < new Date(from)
					? TENDER_STATUS.NOT_STARTED
					: new Date() > new Date(to)
					? TENDER_STATUS.FINISHED
					: TENDER_STATUS.ACTIVE,
			distanceTo: formatDistance(currentDate, new Date(to), { locale: russianLocale }),
			distanceFrom: formatDistance(new Date(), new Date(from), { locale: russianLocale }),
			tenderProgress: status,
			finalizeType,
		};
	} catch (e) {
		return {
			from: '',
			to: '',
			status: TENDER_STATUS.FINISHED,
			isActive: false,
			ended: false,
			notStarted: false,
			distanceTo: '',
			distanceFrom: '',
			tenderProgress: undefined,
			finalizeType,
		};
	}
};

export const tenderCapabilitiesMapper = {
	[TENDER_CAPABILITIES.SEND_QUOTE]: {
		text: 'сделать оферту',
		variant: 'primary',
		action: TENDER_VIEW_ACTIONS.ADD_OFFER,
	},
	[TENDER_CAPABILITIES.EDIT_QUOTE]: {
		text: 'Обновить оферту',
		variant: 'primary',
		action: TENDER_VIEW_ACTIONS.UPDATE_OFFER,
	},
	[TENDER_CAPABILITIES.DELETE]: { text: 'Отклонить', variant: 'secondary', action: TENDER_VIEW_ACTIONS.DENY_TENDER },
	[TENDER_CAPABILITIES.FINISH]: {
		text: 'Подтвердить',
		variant: 'primary',
		action: TENDER_VIEW_ACTIONS.APPROVE_TENDER,
	},
};

export const bidCapabilitesMapper = {};

export type GetCapabilitiesType = { text: string; variant: 'primary' | 'secondary'; action: TenderViewActionsType };

const capabilitesMapper: (capabilitie: TenderCapabilitiesType) => GetCapabilitiesType | null = (capability) =>
	//@ts-ignore
	tenderCapabilitiesMapper[capability] || null;

//@ts-ignore
export const getCapabilites: (bid: Bid, offerView: boolean) => GetCapabilitiesType[] = (bid, offerView) => {
	if (!bid?.tender) {
		return [];
	}
	if (!offerView) {
		return [{ text: 'Просмотр', variant: 'primary', action: 'showOffer' }];
	}
	if (!bid?.tender?.capabilities) {
		if (bid?.capabilities?.length) {
			return (
				bid.capabilities
					//@ts-ignore
					.map(capabilitesMapper)
					.filter((e: GetCapabilitiesType | null) => e)
			);
		} else return [];
	}

	console.log('---- tender capabilities', bid.tender.capabilities);
	// return [];
	return (
		bid.tender.capabilities
			//@ts-ignore
			.map(capabilitesMapper)
			.filter((e: GetCapabilitiesType | null) => e)
	);
};
