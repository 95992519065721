import * as React from 'react';

//other deps
import _ from 'lodash';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//schemes
import * as schemes from '../../../schemes';

//components
import Builder from 'app/containers/Builder';

//types
import { RootState } from 'app/reducers';
import { EmptyCar, EmptyCarDocuments } from 'app/models';
import { BidApiModel } from 'app/api/types';
import { EmptyCarActions } from 'app/actions';
import { SchemeProps } from 'app/containers/Builder/props';

//helpers
import { getTrailerTypes, getTransportTypes, getTransportBrands } from '../../../../common/common.helpers';

//images
const transportImage = require('src/assets/icons/car_avatar.png');
const trailerImage = require('src/assets/icons/trailer_avatar.png');

//----------------------------------------------------------
// CertificateItem
//----------------------------------------------------------
class CertificateItem extends React.Component<CertificateItem.Props, CertificateItem.State> {
	private builder: Builder | null = null;

	public state: CertificateItem.State = {
		form: {},
		validationReport: [],
	};

	constructor(props: CertificateItem.Props) {
		super(props);

		this.initializeBuilderWithProps = this.initializeBuilderWithProps.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	public componentDidMount() {
		this.initializeBuilderWithProps();
	}

	private async initializeBuilderWithProps() {
		const { state } = this;
		const { transportTypes, trailerTypes, brands, certificateType } = this.props;

		if (!state.scheme) {
			const scheme = _.cloneDeep(schemes.certificate);

			const transportTypesInput = scheme[0] as SchemeProps.Select<any>;
			const transportBrandsInput = scheme[1] as SchemeProps.Select<any>;

			if (certificateType === 'trailer' && trailerTypes) {
				transportTypesInput.choices = getTrailerTypes(trailerTypes);
				transportTypesInput.label = 'transports-car-trailer-type';
			}

			if (certificateType === 'transport' && transportTypes) {
				transportTypesInput.choices = getTransportTypes(transportTypes);
			}

			if (brands) {
				transportBrandsInput.choices = getTransportBrands(brands);
			}

			this.setState({ scheme });
			await Promise.delay(70);
		}

		this.applyDefaults();
	}

	private applyDefaults() {
		const { builder } = this;
		const { scheme } = this.state;
		const { certificateType } = this.props;

		if (!builder) return;

		const { formRefs } = builder;
		const allowedStringFields = ['model', 'vin', 'number', 'color'];
		const allowedSelectFields = ['kind', 'brand', 'manufactureYear'];

		for (const key of allowedStringFields) {
			const ref = formRefs?.get(key);
			const value = (this.props?.car?.documents as any)?.[certificateType]?.[key] || null;

			if (ref && value) ref.updateValue(value);
		}

		for (const key of allowedSelectFields) {
			const ref = formRefs?.get(key);
			const value = (this.props?.car?.documents as any)?.[certificateType]?.[key] || null;

			if (!ref || !value) return;

			if (key === 'manufactureYear') {
				ref.updateValue({
					label: value,
					value,
				});
			} else if (key === 'kind' && scheme) {
				const { choices: kindChoices = [] } = scheme[0] as SchemeProps.Select<any>;
				const kind = kindChoices.find((kind) => kind.value === value);

				if (!kind?.label) return;

				ref.updateValue({
					label: kind.label,
					value,
				});
			} else if (key === 'brand' && scheme) {
				const { choices: brandChoices = [] } = scheme[1] as SchemeProps.Select<any>;
				const brand = brandChoices.find((brand) => brand.value === value);

				if (!brand?.label) return;

				ref.updateValue({
					label: brand.label,
					value,
				});
			}
		}
	}

	private async onUpdate(name: string, value: any) {
		const { form } = this.state;
		const { certificateType, updateTransportData, updateTrailerData } = this.props;

		this.setState({
			form: {
				...form,
				[name]: value,
			},
		});

		if (certificateType === 'transport' && updateTransportData) {
			updateTransportData({
				[name]: value,
			});
		} else if (certificateType === 'trailer' && updateTrailerData) {
			updateTrailerData({
				[name]: value,
			});
		}
	}

	public render() {
		const { scheme } = this.state;
		const { certificateType } = this.props;

		const builderProps = {
			getRef: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		const image = certificateType === 'transport' ? transportImage : trailerImage;

		return (
			<div className='certificate item opened'>
				<div className='icons'>
					<div className='icon'>
						<img src={image} />
					</div>
				</div>

				<div className='titlebar'>
					<div className='titles'>
						<div className='header'>
							<Localized id={`transports-car-type-${certificateType}`} />
						</div>
					</div>
				</div>

				<div className='form'>
					<Builder {...builderProps} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ common }: RootState) => ({
	brands: common.brands,
	locales: common.bundlesLocales,
	trailerTypes: common.trailers,
	transportTypes: common.transports,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateTransportData: EmptyCarActions.updateTransportData,
			updateTrailerData: EmptyCarActions.updateTrailerData,
		},
		dispatch,
	);

export default connect<CertificateItem.StateProps, CertificateItem.DispatchProps, CertificateItem.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(CertificateItem);

export namespace CertificateItem {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		brands?: BidApiModel.CommonMeta[];
		locales?: FluentBundle[];
		trailerTypes?: { [name: string]: BidApiModel.CommonMeta };
		transportTypes?: { [name: string]: BidApiModel.CommonMeta };
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updateTransportData?: (params: Partial<EmptyCarDocuments['transport']>) => void;
		updateTrailerData?: (params: Partial<EmptyCarDocuments['trailer']>) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		certificateType: 'trailer' | 'transport';
		car?: EmptyCar | null;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		validationReport: string[];
	}
}
