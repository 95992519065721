import * as React from 'react';

//other deps
import lodash from 'lodash';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//forms
import Form from './form';
import Preview from './preview';

//types
import { DriverCar, Transport } from 'app/models';

//----------------------------------------------------------
// BaseCar
//----------------------------------------------------------
class BaseCar extends React.Component<BaseCar.Props, BaseCar.State> {
	public state: BaseCar.State = {
		editable: true,
		loading: true,
	};

	constructor(props: BaseCar.Props) {
		super(props);

		this.toggleEditable = this.toggleEditable.bind(this);
	}

	public componentDidUpdate({ car: { transport: prevTransport } }: BaseCar.Props) {
		const {
			car: { transport },
		} = this.props;

		if (!lodash.isEqual(prevTransport, transport)) {
			this.setCurrentState(transport);
		}
	}

	public componentDidMount() {
		const {
			car: { transport },
		} = this.props;

		this.setCurrentState(transport);
	}

	private setCurrentState(transport: Transport) {
		const baseValid =
			transport &&
			!!(
				transport.ident &&
				transport.ident.length > 0 &&
				transport.trailerIdent &&
				transport.trailerIdent.length > 0 &&
				transport.height &&
				transport.height > 0 &&
				transport.length &&
				transport.length > 0
			);

		this.setState({
			editable: !baseValid,
			loading: false,
		});
	}

	public toggleEditable(editable: boolean) {
		this.setState({
			editable,
		});
	}

	public render() {
		const { car, openSendedModal } = this.props;

		const { editable, loading } = this.state;
		const { toggleEditable } = this;

		if (loading || lodash.isEmpty(car)) {
			return null;
		} else {
			const props = {
				openSendedModal,
				toggleEditable,
				car,
			};
			return !editable ? <Preview {...props} /> : <Form {...props} />;
		}
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<BaseCar.StateProps, BaseCar.DispatchProps, BaseCar.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(BaseCar);

namespace BaseCar {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		openSendedModal: () => void;
		car: DriverCar;
	}

	// Main component state
	export interface State {
		editable: boolean;
		loading: boolean;
	}
}
