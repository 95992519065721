import { put, takeEvery, call, select } from 'redux-saga/effects';

import * as api from '../api';
// import { ExecutorApiModel } from '../api/types';

import {
	setVacantExecutorsLoading,
	GET_VACANT_EXECUTORS_LIST_NEXT,
	GET_VACANT_EXECUTORS_LIST_PREV,
	UPDATE_BOUNDS_AND_EXECUTORS,
	addVacantExecutosToList,
	setVacantExecutorsList,
	// setTransportOnMapBounds,
} from 'app/actions/';

// type VacantExecutorResponse = ExecutorApiModel.VacantExecutorResponse;

export function* getExecutorsListNext(): Generator<any, void, any> {
	const next = yield select((state) => state.vacantExecutors.next);
	const { filter, bounds } = yield select((state) => state.transportOnMap);
	const bbox = filter && bounds ? `?in_bbox=${bounds}&size=200` : '';

	yield put(setVacantExecutorsLoading(true));
	try {
		const response = yield api.v1.get(`/customer/transport/vacant${bbox}`, {
			params: { cursor: next },
		});
		const {
			data,
			meta: { links },
		} = response?.data || {}; //TODO: check eslint/no-unsafe-optional-chaining;

		yield put(addVacantExecutosToList({ data, next: links.next, prev: links.previos }));
		yield put(setVacantExecutorsLoading(false));
	} catch (error) {
		yield call<any>(api.apiSentryException, error); // TODO: check type checking
	}
}

export function* updateBoundsAndExecutors(): Generator<any, void, any> {
	const { filter, bounds } = yield select((state) => state.transportOnMap);
	const next = yield select((state) => state.vacantExecutors.next);

	const bbox = filter && bounds ? `?in_bbox=${bounds}&size=200` : '';

	try {
		const response = yield api.v1.get(`/customer/transport/vacant${bbox}`, {
			params: { cursor: next },
		});
		const {
			data,
			meta: { links },
		} = response?.data || {}; //TODO: check eslint/no-unsafe-optional-chaining;

		yield put(setVacantExecutorsList({ data, next: links.next, prev: links.previos }));
		yield put(setVacantExecutorsLoading(false));
		// yield put(setTransportOnMapBounds(action.payload));
	} catch (error) {
		yield call<any>(api.apiSentryException, error); // TODO: check type checking
	}
}

export function* watchExecutors() {
	yield takeEvery(GET_VACANT_EXECUTORS_LIST_NEXT, getExecutorsListNext);
	yield takeEvery(GET_VACANT_EXECUTORS_LIST_PREV, getExecutorsListNext);
	yield takeEvery(UPDATE_BOUNDS_AND_EXECUTORS, updateBoundsAndExecutors);
}
