import { Dispatch, bindActionCreators } from 'redux';
import { RootState } from 'app/reducers';
import { FluentBundle } from '@fluent/bundle';
import { connect } from 'react-redux';
import * as React from 'react';

const mapStateToProps = ({ common }: RootState) => ({
	locales: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

class PageTitle extends React.Component<PageTitle.Props, PageTitle.State> {
	public state: PageTitle.State = {};

	constructor(props: PageTitle.Props) {
		super(props);
	}

	public async componentDidMount() {
		const { titleKey, locales } = this.props;

		if (locales) {
			const [locale] = locales;
			const title = locale.getMessage(titleKey)?.value?.toString() || '';
			// console.log(titleKey, title)

			document.title = title;
		}
	}

	public render() {
		// return <div
		//     style={{ position: "absolute", display: "none" }}
		//     className="meta-title"
		// />
		return null;
	}
}

export default connect<PageTitle.StateProps, PageTitle.DispatchProps, PageTitle.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(PageTitle);

namespace PageTitle {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		titleKey: string;
	}

	// Main component state
	export interface State {}
}
