import Modal from 'react-modal';
import * as React from 'react';
import clsx from 'clsx';

export default class ImageModal extends React.Component<ImageModal.Props, ImageModal.State> {
	state: ImageModal.State = {
		opened: false,
	};

	public render() {
		const { src, className = '' } = this.props;
		const { opened } = this.state;

		const close = () => this.setState({ opened: false });
		const open = () => this.setState({ opened: true });

		const modalProps = {
			className: 'image-preview',
			key: 'modal-image-' + src,
			onRequestClose: close,
			isOpen: opened,
		};

		const imgProps = {
			key: 'image-' + src,
			onClick: open,
			className: clsx(className, 'image-modal'),
			src,
		};

		return [
			<img {...imgProps} key='modal-image' />,

			<Modal {...modalProps} key='image-modal'>
				<div className='content-image' onClick={close}>
					<img {...imgProps} />
				</div>
			</Modal>,
		];
	}
}

export namespace ImageModal {
	export interface State {
		opened: boolean;
	}

	export interface Props {
		className?: string;
		src: string;
	}
}
