import React, { useMemo, useRef, useLayoutEffect } from 'react';
import { useTable, useSortBy, Column } from 'react-table';
import Scrollbars from 'react-custom-scrollbars';
import Spinner from 'react-loader-spinner';
import { Styles, ThContent, SpinnerContainer, Tr } from './styles';
import { OrderingIcon } from './OrderIcon';
/* eslint-disable react/jsx-key */

type SortByItem = {
	id: string;
	desc: boolean;
};

type RTableProps = {
	columns: Column[];
	data: any;
	sortBy?: SortByItem[];
	sticky?: boolean;
	loading?: boolean;
	onLoadMore?: Function;
	hasMore?: boolean;
	onRowClick?: Function;
	disableSort?: boolean;
};

export function RTable({
	columns,
	data,
	sortBy,
	sticky = false,
	loading,
	onLoadMore,
	hasMore,
	disableSort,
	onRowClick,
}: RTableProps) {
	const scrollbarsRef = useRef<Scrollbars>(null);
	const tableWrapperRef = useRef<HTMLDivElement>(null);
	const memoColumns = useMemo(() => columns, [columns]);
	const memoData = useMemo(() => data, [data]);

	const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
		{
			columns: memoColumns,
			data: memoData,
			//@ts-ignore
			disableSortRemove: true,
			disableMultiSort: true,
			disableSortBy: disableSort,
			autoResetSortBy: false,
			sortBy,
		},
		useSortBy,
	);
	const handleScroll = (event: any) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		const pixelsToBottom = scrollHeight - clientHeight - scrollTop;
		if (pixelsToBottom < 10 && !loading && hasMore) {
			onLoadMore?.();
		}
	};

	const handleRowClick = (row: any) => () => {
		if (onRowClick) {
			onRowClick(row.original);
		}
	};

	useLayoutEffect(() => {
		if (!tableWrapperRef?.current?.getBoundingClientRect) {
			return;
		}

		if (!scrollbarsRef.current) {
			return;
		}

		const wrapper = tableWrapperRef.current.getBoundingClientRect();
		const scrollHeight = scrollbarsRef.current.getScrollHeight();

		if (wrapper.bottom - wrapper.height > 10 && scrollHeight < wrapper.bottom && !loading && hasMore && onLoadMore) {
			onLoadMore();
		}
	});

	return (
		<Styles
			rowClickable={!!onRowClick}
			ref={tableWrapperRef}
			className='table-wrapper'
			sticky={sticky}
			style={{ height: '95%' }}
		>
			<Scrollbars ref={scrollbarsRef} onScroll={handleScroll} style={{ width: '98%' }}>
				<table {...getTableProps()}>
					<thead>
						{headerGroups.map((headerGroup) => (
							<Tr {...headerGroup.getHeaderGroupProps()}>
								{headerGroup.headers.map((column: any) => {
									return (
										<th {...column.getHeaderProps(column.getSortByToggleProps())}>
											<ThContent>
												{column.render('Header')}
												{column.canSort && (
													<OrderingIcon
														isSorted={column.isSorted && column.isSortedDesc !== undefined}
														ordering={column.isSortedDesc ? 'desc' : 'asc'}
													/>
												)}
											</ThContent>
										</th>
									);
								})}
							</Tr>
						))}
					</thead>
					<tbody {...getTableBodyProps()}>
						{rows.map((row) => {
							prepareRow(row);
							return (
								<Tr rowClickable={!!onRowClick} {...row.getRowProps()} onClick={handleRowClick(row)}>
									{row.cells.map((cell) => {
										return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
									})}
								</Tr>
							);
						})}
						{loading && (
							<tr>
								<td colSpan={Object.keys(columns)?.length || 0}>
									<SpinnerContainer>
										<Spinner type='RevolvingDot' color='#1FB0E2' height={50} width={50} />
									</SpinnerContainer>
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</Scrollbars>
		</Styles>
	);
}
/* eslint-enable react/jsx-key */
