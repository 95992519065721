import React, { useContext, createContext, useState, useEffect } from 'react';

export type SideBarPositions = 'left' | 'right';
export type SidebarContentType = null | React.ReactNode | React.ReactNodeArray;
export type ContentType = any | null | React.ReactNode | React.ReactNodeArray;
export type SidebarContextType = {
	open: boolean;
	title: null | string | React.ReactNode;
	position: SideBarPositions;
	content: ContentType;
	setTitle: (c: null | string | React.ReactNode) => void;
	setContent: (c: ContentType) => void;
	setOpen: (s: boolean) => void;
	setPosition: (p: SideBarPositions) => void;
};

export const SidebarContext = createContext<SidebarContextType>({
	open: false,
	position: 'right',
	content: null,
	title: '',
	setTitle: () => {},
	setContent: () => {},
	setOpen: () => {},
	setPosition: () => {},
});

export const useSidebar = ({
	content,
	title,
}: { content?: SidebarContentType; title?: string | React.ReactNode } = {}) => {
	const sidebarContext = useContext(SidebarContext);

	useEffect(() => {
		content && sidebarContext.setContent(content);
		title && sidebarContext.setTitle(title);
	}, []);

	return sidebarContext;
};

export const SidebarContextProvider = ({ children }: any) => {
	const [open, setOpen] = useState(false);
	const [position, setPosition] = useState<SideBarPositions>('right');
	const [content, setContent] = useState<any>(null);
	const [title, setTitle] = useState<string | React.ReactNode>('');
	const handleSetContent = (mutatedContent: any) => setContent(() => mutatedContent);
	return (
		<SidebarContext.Provider
			value={{
				open: open,
				setOpen,
				position,
				setPosition,
				content,
				setContent: handleSetContent,
				title,
				setTitle,
			}}
		>
			{children}
		</SidebarContext.Provider>
	);
};
