import { Action, handleActions } from 'redux-actions';
import { RootState } from 'app/reducers/state';
import { BidActions } from 'app/actions';
import { Bid, BidsCollection, BidsFilter } from 'app/models';

export const initialState: RootState.Bid = {
	archive: { cursor: { previous: null, next: null }, list: [], loading: true },
	all: { cursor: { previous: null, next: null }, list: [], loading: true },
	my: { cursor: { previous: null, next: null }, list: [], loading: true },

	filtersOpened: false,
	filters: {},
};

export const bidReducer = handleActions<RootState.Bid, any>(
	{
		[BidActions.Action.SET_ARCHIVE_BID]: (state, { payload }: Action<BidActions.Payload.Bids>) => {
			if (payload) {
				const { cursor, list, loadMore } = payload;
				const archive: BidsCollection = {
					loading: state.archive.loading,
					cursor,
					list: loadMore ? [...state.archive.list, ...list] : list,
				};

				return {
					...state,
					archive,
				};
			}

			return {
				...state,
			};
		},

		[BidActions.Action.SET_ALL_BID]: (state, { payload }: Action<BidActions.Payload.Bids>) => {
			if (payload) {
				const { cursor, list, loadMore } = payload;
				const all: BidsCollection = {
					loading: state.all.loading,
					cursor,
					list: loadMore ? [...state.all.list, ...list] : list,
				};

				return {
					...state,
					all,
				};
			}

			return {
				...state,
			};
		},

		[BidActions.Action.BID_TOGGLE_TOOLBAR]: (state: RootState.Bid) => ({
			...state,
			filtersOpened: !state.filtersOpened,
		}),

		[BidActions.Action.SET_BIDS_LOADING]: (state, { payload }: Action<BidActions.Payload.Loading>) => ({
			...state,

			...(!payload
				? {}
				: {
						[payload.type]: {
							...(state as any)[payload.type],
							loading: payload.value,
						},
				  }),
		}),

		[BidActions.Action.SET_MY_BID]: (state, { payload }: Action<BidActions.Payload.Bids>) => {
			if (payload) {
				const { cursor, list, loadMore } = payload;
				const my: BidsCollection = {
					loading: false,
					cursor,
					list: loadMore ? [...state.my.list, ...list] : list,
				};

				return {
					...state,
					my,
				};
			}

			return {
				...state,
			};
		},

		[BidActions.Action.SET_BID_FILTERS]: (state, { payload }: Action<BidsFilter>) => ({
			...state,
			...(payload ? { filters: payload } : {}),
		}),

		[BidActions.Action.BID_LIST_CLEAN]: (state, { payload }: Action<Bid.BidType>) => ({
			...state,

			...(payload
				? {
						[payload]: { cursor: { previous: null, next: null }, list: [] },
				  }
				: {}),
		}),
	},
	initialState,
);
