import * as React from 'react';
import SelectComponent from 'react-select';
import { SchemeProps, Field } from '../../props';
import { getLocalizedMessage } from 'app/locales';
import { store } from 'src/main';
import { FluentBundle } from '@fluent/bundle';
import { ArrowDown } from 'app/components/Icons';
import { LocalizedOption, ValueContainer } from './StyledMultiSelectComponents';

export class MultiSelect<T = any>
	extends React.Component<MultiSelect.Props<T>, MultiSelect.State<T>>
	implements Field.Hooks<T>
{
	private selectRef: null | SelectComponent<any> = null;
	private bundlesLocales: FluentBundle[] | undefined;
	public state: MultiSelect.State<T> = {
		value: [],
	};

	constructor(props: MultiSelect.Props<T>) {
		super(props);

		this.updateValue = this.updateValue.bind(this);
		this.focus = this.focus.bind(this);
		this.reset = this.reset.bind(this);

		this.bundlesLocales = store.getState().common.bundlesLocales;
	}

	public async updateValue(value: any) {
		// { value, label: value }
		const {
			onUpdate,
			field: { name },
		} = this.props;
		this.setState({ value });
		onUpdate(name, value.value);
	}

	public focus() {
		if (this.selectRef) {
			this.selectRef.focus();
		}
	}

	public reset() {
		this.setState({
			value: [],
		});
	}

	public validate() {
		const { required } = this.props.field;
		const { value } = this.state;
		let validationMessage = '';
		if (this.state.validationMessage !== '') this.setState({ validationMessage: '' });

		if (required && !value) {
			validationMessage = getLocalizedMessage('builder-field-required') || 'Invalid report';
		}

		if (validationMessage) {
			this.setState({ validationMessage });
			return validationMessage;
		}

		return true;
	}

	public render() {
		const { name, label, choices, disabled, localized, disableLocalizeForOption } = this.props.field;
		// const selectPrefix = rounded ? 'rounded-select' : 'select';
		const {
			onUpdate,
			field: { inputProps },
		} = this.props;
		const { value, validationMessage } = this.state;

		const onChangeValue = (selected: []) => {
			const selectedForFilter = selected && selected.map((item: any) => item.value);
			onUpdate(name, selectedForFilter);
			this.setState({ value: selected as any }, () => this.validate());

			if (inputProps && inputProps.onSelect) {
				inputProps.onSelect();
			}
		};

		const selectProps: any = {
			ref: (node: SelectComponent<any> | null) => (this.selectRef = node),
			onChange: onChangeValue,
			hideSelectedOptions: false,

			blurInputOnSelect: true,
			isDisabled: disabled,
			options: choices,
			placeholder: label,
			value,
			name,
			isMulti: true,

			components: {
				Option: (props: any) => (
					<LocalizedOption
						localized={!!localized}
						disableLocalizeForOption={!!disableLocalizeForOption}
						selected={value}
						{...props}
					/>
				),
				DropdownIndicator: () => <ArrowDown color='#64C8EB' />,
				IndicatorSeparator: () => <div />,
				ValueContainer: (props: any) => <ValueContainer {...props} selectedItem={value} />,
			},
		};

		const classList = ['form-item', 'multi-select', name];
		if (validationMessage) {
			classList.push('select--invalid');
		}

		const customStyles = {
			control: (provided: any) => {
				return {
					...provided,
					height: 50,
					borderColor: 'hsla(0, 0%, 0%, 0.15)',
					color: '#333333',
					paddingRight: 10,
					fontFamily: 'UniNeue',
					borderRadius: 6,
				};
			},
		};

		return (
			<div className={classList.join(' ')}>
				<div className='input__message'>{validationMessage}</div>
				{value.length > 0 && <div className='multi-label'>{label}</div>}
				<SelectComponent styles={customStyles} {...selectProps} />
			</div>
		);
	}
}

export namespace MultiSelect {
	export interface Props<T> extends Field.ComponentProps<any, SchemeProps.MultiSelect<T>> {
		// @default from base
	}

	export interface State<T> {
		value: Field.Choice<T>[];
		validationMessage?: string;
	}
}

export type Props<T> = Field.ComponentProps<any, SchemeProps.MultiSelect<T>> & {
	// @default from base
};
