import React from 'react';
import { getTenderStatus, TENDER_STATUS, statusMapper } from '../helper';
import { CargoBlock } from './styles';
import { Typography } from 'app/sharedComponents/Typography';
import { Bid } from 'app/models';

export const TenderInfo = ({ bid }: { bid: Bid }) => {
	const { cargo, payment, tender } = bid;
	const dates = getTenderStatus(tender);
	return (
		<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '16px' }}>
			<CargoBlock>
				<Typography variant='body3'>{`${cargo.type.name} ${payment.price} ${payment.type.name}`}</Typography>
			</CargoBlock>
			<div style={{ display: 'flex', flexDirection: 'column', marginTop: '16px', paddingRight: '16px' }}>
				<Typography variant='body4'>
					{dates.ended ? dates.to : dates.status === TENDER_STATUS.ACTIVE ? `осталось ${dates.distanceTo}` : dates.from}
				</Typography>
				<Typography variant='body4' color='#A0A0A0'>
					{statusMapper[dates.status]}
				</Typography>
			</div>
		</div>
	);
};
