import { routerMiddleware, connectRouter } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore, combineReducers, Store } from 'redux';
import { reducers, RootState } from 'app/reducers';
import createSagaMiddleware from 'redux-saga';

import { logger } from 'app/middleware';
import { History } from 'history';
import thunk from 'redux-thunk';
import { rootSaga } from 'app/sagas';

export function configureStore(history: History, initialState?: RootState): Store<RootState> {
	let middleware;

	const sagaMiddleware = createSagaMiddleware();

	if (process.env.NODE_ENV !== 'production') {
		middleware = applyMiddleware(logger, thunk, routerMiddleware(history), sagaMiddleware);
		middleware = composeWithDevTools(middleware);
	} else {
		middleware = applyMiddleware(thunk, routerMiddleware(history), sagaMiddleware);
	}

	const reducer = combineReducers({ ...reducers, router: connectRouter(history) });
	const store = createStore(reducer, initialState as any, middleware) as Store<RootState>;

	sagaMiddleware.run(rootSaga);

	if (module.hot) {
		module.hot.accept('app/reducers', () => {
			const nextReducer = require('app/reducers');
			store.replaceReducer(nextReducer);
		});
	}

	// Init Hardcode store for development
	// if (process.env.NODE_ENV !== 'production') {
	//     initHardcodeState(store);
	// }

	return store;
}
