import React, { Component } from 'react';
import './index.sass';
export default class TableCustom extends Component<TableCustom.Props, TableCustom.State> {
	private headerCells!: any;
	private headerCellsTarget!: any;
	private defaultData: any[];
	public state: TableCustom.State = {
		currentSortKey: '',
		sortDownwards: {},
	};
	constructor(props: TableCustom.Props) {
		super(props);

		this.registerHeaderTarget = this.registerHeaderTarget.bind(this);
		this.registerHeader = this.registerHeader.bind(this);
		this.resizeHandler = this.resizeHandler.bind(this);
		this.sortColumn = this.sortColumn.bind(this);

		window.addEventListener('resize', this.resizeHandler);
		this.defaultData = [...this.props.data];
	}

	public componentDidMount() {
		setTimeout(() => {
			this.resizeHandler();
		}, 1);
	}

	public componentDidUpdate() {
		this.resizeHandler();
	}

	public componentWillUnmount() {
		window.removeEventListener('resize', this.resizeHandler);
	}

	private resizeHandler() {
		const { headerCells, headerCellsTarget } = this;
		if (headerCells && headerCellsTarget) {
			for (let index = 0; index < headerCells.length; index++) {
				const th = headerCells[index];
				const targetTh = headerCellsTarget[index];
				th.style.width = targetTh?.offsetWidth + 'px';
			}
		}
	}

	private sortColumn(param: TableCustom.HeaderProps) {
		if (param.key === this.state.currentSortKey) {
			this.setState(() => {
				return {
					sortDownwards: {
						[param.key]: !this.state.sortDownwards[param.key],
					},
				};
			});
		} else {
			this.setState(() => {
				return {
					sortDownwards: {
						[this.state.currentSortKey]: false,
					},
				};
			});
		}
		this.setState({ currentSortKey: param.key });
	}

	private registerHeader(table: any) {
		if (!table) return;
		this.headerCells = table.querySelectorAll('div');
	}
	private registerHeaderTarget(table: any) {
		if (!table) return;
		this.headerCellsTarget = table.querySelectorAll('th');
	}

	private renderHeader() {
		const { head } = this.props;

		return (
			<thead>
				<tr>
					{head.map((params: TableCustom.HeaderProps, i: number) => {
						const props = {
							style: {
								width: (params.width || '') + 'px',
								maxWidth: (params.width || '') + 'px',
							},
							key: 'table-row-' + i,
						};
						const condition = params.condition === undefined ? true : params.condition;
						return condition ? <th {...props}> {params.title || ''} </th> : null;
					})}
				</tr>
			</thead>
		);
	}

	private renderPseudoHeader() {
		const { head } = this.props;

		return head.map((param: TableCustom.HeaderProps, i: number) => {
			const props = { key: 'table-roww-' + i };
			const condition = param.condition === undefined ? true : param.condition;
			const isRowSortingActive = param.key === this.state.currentSortKey;
			const buttonProps = {
				onClick: () => {
					this.sortColumn(param);
				},
				style: {
					opacity: isRowSortingActive ? 1 : 0.5,
				},
			};
			const style = param.breakWords ? { whiteSpace: 'normal' } : { whiteSpace: 'nowrap' };
			return condition ? (
				//@ts-ignore
				<div {...props} style={style}>
					{param.title || ''}
					{param.headerRender && param.headerRender()}
					{param.sortable ? <button {...buttonProps}>{this.state.sortDownwards[param.key] ? '▲' : '▼'}</button> : null}
				</div>
			) : null;
		});
	}

	private renderRow() {
		this.defaultData = [...this.props.data];
		this.defaultData.sort((a, b) => {
			const sortKey = this.state.currentSortKey;
			const isReverseSorting = this.state.sortDownwards[sortKey];
			return isReverseSorting ? b[sortKey] - a[sortKey] : a[sortKey] - b[sortKey];
		});
		const { defaultData } = this;
		const { head } = this.props;

		return (
			<tbody>
				{defaultData.map((ref: any, i: number) => {
					return (
						<tr key={'table-brow-' + i}>
							{head.map((params: TableCustom.HeaderProps, j: number) => {
								const props = { key: 'table-bcell-' + i + j };
								const condition = params.condition === undefined ? true : params.condition;
								return condition ? (
									<td {...props}>{params.render ? params.render(ref[params.key], ref, i) : ref[params.key]}</td>
								) : null;
							})}
						</tr>
					);
				})}
			</tbody>
		);
	}

	public render() {
		return (
			<div className='table-overlay'>
				<div className='table table--head' ref={this.registerHeader}>
					{this.renderPseudoHeader()}
				</div>
				<div className='table-scroll'>
					<table width='100%' className='table' cellSpacing='0' cellPadding='0' ref={this.registerHeaderTarget}>
						{this.renderHeader()}
						{this.renderRow()}
					</table>
				</div>
			</div>
		);
	}
}

namespace TableCustom {
	export interface Props {
		head: HeaderProps[];
		data: any[];
	}
	export interface State {
		currentSortKey: string;
		sortDownwards: { [key: string]: boolean };
	}
	export interface HeaderProps {
		condition?: boolean;
		sortable?: boolean;
		breakWords?: boolean;
		headerRender?: () => JSX.Element | JSX.Element[];
		width?: number;
		title?: string;
		render?: (ref: any, props: any, rowIndex: number) => JSX.Element | JSX.Element[];
		key: string;
	}
}
