import styled from 'styled-components';

export const DatePickerContainer = styled.div<{ noBorder?: boolean }>`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	border: ${(p) => (p.noBorder ? 'none' : '1px solid #cccccc')};
	border-radius: 5px;
	padding: 4px 10px;
	position: relative;
	height: 30px;
	align-items: center;
`;

export const Label = styled.p`
	font-size: 10px;
	color: #6c6f88;
	margin-bottom: 6px;
	min-height: 11px;
`;

export const InputContainer = styled.div`
	padding-left: 0px;
	display: flex;
	justify-content: space-between;
	width: 100%;
	align-items: center;
	& svg {
		margin-left: -7px;
	}
`;

export const Value = styled.div`
	font-size: 16px;
	font-weight: 500;
	// width: 175px;
`;

export const Placeholder = styled.div`
	color: rgb(128, 128, 128);
	width: 160px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
`;
