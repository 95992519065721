import { BidDetails, SidebarType } from 'app/models';
import { bidGetCommonMeta, getRegionsCommon, pricesGetProducts, pricesPrepareShippingFare } from 'app/api';
import { generateLocales } from 'app/locales';
import { createAction } from 'redux-actions';
import { FluentBundle } from '@fluent/bundle';
import { UserActions } from './user';

import { Dispatch } from 'redux';
// import _ from 'lodash';

export namespace CommonActions {
	export enum Action {
		SET_COMMON_SIDEBAR_OPENED = 'SET_COMMON_SIDEBAR_OPENED',
		SET_COMMON_DETAILS_ITEM = 'SET_COMMON_DETAILS_ITEM',
		CHANGE_LOCALES = 'COMMON_CHANGE_LOCALES',
		EXTEND_COMMON = 'EXTEND_COMMON',
		GET_TARIFF = 'COMMON_GET_TARIFF',
	}

	export namespace Payload {
		export interface Extend {
			[name: string]: any;
		}

		export interface ToggleSidebar {
			type: SidebarType;
			value?: boolean;
			params?: any;
		}

		export interface Locales {
			bundlesLocales: FluentBundle[];
			currentLocales: string[];
			userLocales: string[];
		}
	}

	export const setDetailsBid = createAction<BidDetails | string | undefined>(Action.SET_COMMON_DETAILS_ITEM);
	export const extend = createAction<Payload.Extend>(Action.EXTEND_COMMON);

	export const getTariff = createAction(Action.GET_TARIFF);

	export const changeLocales = (userLocales: string[]) => {
		const action = createAction<Payload.Locales>(Action.CHANGE_LOCALES);
		const params = generateLocales(userLocales);

		return action(params);
	};

	export const getRegions = () => async (dispatch: Dispatch) => {
		try {
			const { regions, pricePerKm, distance, weight, price } = await getRegionsCommon();
			await dispatch(extend({ regions, filters: { pricePerKm, distance, weight, price } }));
		} catch (error) {
			// @ignore
		}
	};

	export const toggleSidebar =
		({ type, value, params }: Payload.ToggleSidebar) =>
		async (dispatch: Dispatch) => {
			// Promise.delay(350).then(() => {
			//     if (utils.currentMapGlobal) {
			//         utils.currentMapGlobal.resize()
			//     }
			// })

			// Clean active tmp contacts for leave from `contactEdit` sidebar
			dispatch(UserActions._setTmpContact(undefined));

			dispatch({
				type: Action.SET_COMMON_SIDEBAR_OPENED,
				payload: { type, value, params },
			});
		};

	const META_TO_OBJ = ['transport', 'trailer'];
	const META_TO_ARRAY = ['loading', 'goods', 'price', 'brand'];

	const META_NAME_MAPPER = {
		transport: 'transports',
		trailer: 'trailers',
		loading: 'loadings',
		goods: 'cargos',
		price: 'prices',
		brand: 'brands',
	};

	export const getCommonMeta = () => async (dispatch: Dispatch) => {
		try {
			const products = await pricesGetProducts();
			const metaFields = await bidGetCommonMeta();
			const reducedMeta = metaFields.reduce(
				(acc: any, item: any) => {
					if (META_TO_OBJ.includes(item.type)) {
						// @ts-ignore
						acc[META_NAME_MAPPER[item.type]] = {
							//@ts-ignore
							...acc[META_NAME_MAPPER[item.type]],
							[item.name]: item,
						};
					}

					if (META_TO_ARRAY.includes(item.type)) {
						//@ts-ignore
						acc[META_NAME_MAPPER[item.type]] = [...acc[META_NAME_MAPPER[item.type]], item];
					}
					return acc;
				},
				{
					transports: {},
					products,
					trailers: {},
					loadings: [],
					cargos: [],
					prices: [],
					brands: [],
					tariff: [],
				},
			);
			dispatch(extend(reducedMeta));
			dispatch(getTariff());
		} catch (error) {
			console.error('Get cargos failed');
			console.error(error);
		}
	};

	export const prepareShippingFare = () => async (dispatch: Dispatch) => {
		const shippingFare = await pricesPrepareShippingFare();
		dispatch(extend({ shippingFare }));
	};
}
