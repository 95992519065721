import * as React from 'react';

//other deps
import _ from 'lodash';
import moment from 'moment';
import Posed from 'react-pose';
import { toast } from 'react-toastify';
import { Localized } from '@fluent/react';
import Spinner from 'react-loader-spinner';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { store } from 'src/main';

//api
import { driverTransportCertificate } from 'app/api';

//schemes
import * as schemes from '../../../schemes';

//components
import ImageModal from 'app/components/ImageModal';
import * as Icons from 'app/components/Icons';
import Builder from 'app/containers/Builder';

const Container = Posed.div({
	opened: {
		height: 'auto',
		transition: {
			ease: 'easeIn',
			duration: 300,
		},
	},
	closed: {
		height: 64,
		transition: {
			ease: 'easeIn',
			duration: 300,
		},
	},
});

//types
import { RootState } from 'app/reducers';
import { Certificate } from 'app/models';
import { BidApiModel } from 'app/api/types';
import { DriverActions, UserActions } from 'app/actions';
import { SchemeProps } from 'app/containers/Builder/props';

//helpers
import { makeMasker, maskGetValue } from 'app/utils';
import { findTransportTypeByName, getTransportBrands, getTransportTypes } from '../../../../common/common.helpers';

//constants
import { VERIFIED } from '../docs.page';
import { trailerIdentRules } from '../../../schemes';

//----------------------------------------------------------
// CertificateItem
//----------------------------------------------------------
class CertificateItem extends React.Component<CertificateItem.Props, CertificateItem.State> {
	private builder: Builder | null = null;

	public state: CertificateItem.State = {
		certType: 'transport',
		loading: false,
		editable: true,
		opened: true,
		form: {},
		validationReport: [],
	};

	constructor(props: CertificateItem.Props) {
		super(props);

		this.initializeBuilderWithProps = this.initializeBuilderWithProps.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.getPreview = this.getPreview.bind(this);
		this.getHeader = this.getHeader.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.isValid = this.isValid.bind(this);
		this.getForm = this.getForm.bind(this);
		this.onSave = this.onSave.bind(this);
	}

	public componentDidMount() {
		if (this.isValid()) {
			this.setState({
				editable: false,
			});
		} else {
			this.initializeBuilderWithProps();
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private async initializeBuilderWithProps() {
		const { transportTypes, trailerTypes, brands, certificate } = this.props;
		const { state } = this;

		if (transportTypes && trailerTypes) {
			const kindType = findTransportTypeByName({ ...transportTypes, ...trailerTypes }, certificate.kind);

			if (!state.scheme) {
				const scheme = _.cloneDeep(schemes.certificate);

				const transportTypesInput = scheme[0] as SchemeProps.Select<any>;
				const transportBrandsInput = scheme[1] as SchemeProps.Select<any>;
				const transportIdentInput = scheme[6] as SchemeProps.Input;

				transportIdentInput.disabled = true;

				if (brands) {
					transportBrandsInput.choices = getTransportBrands(brands);
				}

				if (kindType) {
					this.setState({ certType: kindType.type as any });

					if (kindType.type === 'trailer') {
						transportTypesInput.choices = getTransportTypes(trailerTypes);
						transportTypesInput.label = 'transports-car-trailer-type';

						transportIdentInput.mask = 'ss0000000';
						transportIdentInput.rules = trailerIdentRules;
						transportIdentInput.label = 'transports-car-trailer-ident';
					} else {
						transportTypesInput.choices = getTransportTypes(transportTypes);
					}
				}

				this.setState({ scheme });
				await Promise.delay(70);
			}

			this.applyDefaults(kindType);
		}
	}

	private applyDefaults(kindType?: BidApiModel.CommonMeta) {
		const { builder } = this;
		const { certificate, brands } = this.props;

		if (!builder) return;

		const { formRefs } = builder;

		const allowedStringFields = ['ident', 'model', 'vin', 'number', 'color', 'issuedAt', 'mainPage', 'ownerPage'];
		const allowedSelectFields = ['kind', 'brand', 'manufactureYear'];

		Object.keys(certificate).forEach((key) => {
			const ref = formRefs.get(key);
			const value = (certificate as any)?.[key] || null;

			if (!ref || !value) return;

			if (allowedStringFields.includes(key)) {
				ref.updateValue(value);
			} else if (allowedSelectFields.includes(key)) {
				if (key === 'kind' && kindType) {
					ref.updateValue({
						label: kindType.name,
						value: kindType.id,
					});
				} else if (key === 'brand') {
					const brandChoices = getTransportBrands(brands);
					const selectedBrand = brandChoices.find(({ label }) => label === value);

					if (selectedBrand) {
						ref.updateValue(selectedBrand);
					}
				} else if (key === 'manufactureYear') {
					ref.updateValue({
						label: value,
						value,
					});
				}
			}
		});
	}

	private isValid() {
		const { certificate } = this.props;

		return (
			certificate.ident &&
			certificate.ident.length > 0 &&
			certificate.issuedAt &&
			certificate.issuedAt.length > 0 &&
			certificate.number &&
			certificate.number.length > 0 &&
			certificate.model &&
			certificate.model.length > 0 &&
			certificate.vin &&
			certificate.vin.length > 0 &&
			certificate.manufactureYear &&
			certificate.ownerPage &&
			certificate.mainPage
		);
	}

	private async onUpdate(name: string, value: any) {
		const { form } = this.state;

		this.setState({
			form: {
				...form,
				[name]: value,
			},
		});
	}

	private async onSave() {
		const { form, loading } = this.state;

		const { updateTransportCertificate, sessionToken, certificate, transportId } = this.props;

		if (sessionToken && updateTransportCertificate && !loading) {
			this.setState({ loading: true });

			try {
				const { builder } = this;

				const params: any = {
					...form,
					uuid: certificate.uuid,
				};

				if (builder) {
					builder.isValid();
				}

				if (form.number) {
					params.number = form.number.split(' ').join('').replace('№', '');
				}

				const updated = await driverTransportCertificate(transportId, params, 'patch');

				updateTransportCertificate({
					transportId,
					data: updated,
				});

				toast.success('Сохранено', {
					position: toast.POSITION.BOTTOM_CENTER,
				});

				this.setState({ loading: false });

				await Promise.delay(70);

				if (this.isValid()) {
					this.setState({
						editable: false,
					});
				}

				// updating user state
				if (sessionToken) await store.dispatch(UserActions.getProfile(sessionToken, true) as any);
			} catch (error) {
				console.error(error);
			}

			this.setState({ loading: false });
		}
	}

	private outputModeratorMessages(builder?: Builder | null) {
		const {
			certificate: { verification },
		} = this.props;

		if (builder) {
			builder.outputOperatorCommentary(verification);
		}
	}

	private getForm() {
		const { scheme, loading } = this.state;
		const loadingClass = loading ? 'loading' : '';

		const builderProps = {
			getRef: (node: Builder | null) => {
				this.builder = node;
				this.outputModeratorMessages(node);
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		return (
			<div className='form'>
				<Builder {...builderProps} />

				<button disabled={loading} className={`action ${loadingClass}`} onClick={this.onSave}>
					{loading ? (
						<>
							<Spinner type='RevolvingDot' color='#FFFFFF' height={22} width={22} />
							<Localized id='profile-action-save-loading' />
						</>
					) : (
						<Localized id='transports-action-save' />
					)}
				</button>
			</div>
		);
	}

	private getPreview() {
		const { certificate } = this.props;

		const number = () => {
			const masker = makeMasker('00 00 № 000000', '');

			return !certificate.number ? '' : maskGetValue(certificate.number, undefined, masker);
		};

		return (
			<div className='preview'>
				<div className='row'>
					<div className='column'>
						<div className='field'>
							<div className='title'>
								<Localized id='transports-certificate-manufacture-year' />
							</div>

							<div className='text'>{certificate.manufactureYear}</div>
						</div>

						<div className='field'>
							<div className='title'>VIN</div>

							<div className='text'>{certificate.vin}</div>
						</div>
					</div>

					<div className='column'>
						<div className='field'>
							<div className='title'>
								<Localized id='transports-certificate-number' />
							</div>

							<div className='text'>{number()}</div>
						</div>

						<div className='field'>
							<div className='title'>
								<Localized id='transports-certificate-issued-at' />
							</div>

							<div className='text'>{certificate.issuedAt && moment(certificate.issuedAt).format('DD MMMM YYYY')}</div>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='column'>
						<div className='field photo'>
							<div className='title'>
								<Localized id='transports-certificate-preview-scan-title' />

								<div className='sub'>
									<Localized id='transports-certificate-main-page-sub' />
								</div>
							</div>

							<div className='image'>
								<ImageModal src={certificate.mainPage as string} />
							</div>
						</div>
					</div>

					<div className='column'>
						<div className='field photo'>
							<div className='title'>
								<Localized id='transports-certificate-preview-scan-title' />

								<div className='sub'>
									<Localized id='transports-certificate-owner-page-sub' />
								</div>
							</div>

							<div className='image'>
								<ImageModal src={certificate.ownerPage as string} />
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	private getHeader() {
		const {
			certificate: { ident, model },
			allVerified,
		} = this.props;

		const { editable, certType } = this.state;

		const header = ident ? ident : <Localized id='transports-number-unknown' />;
		const subheader = model ? model : <Localized id='transports-model-unknown' />;

		const baseType = certType === 'transport' ? 'transports-car-type-transport' : 'transports-car-type-trailer';

		const toggleOpened = () => {};

		const toggleEditable = async () => {
			this.setState({ editable: true });

			await Promise.delay(250);
			this.initializeBuilderWithProps();
		};

		return (
			<div className='titlebar' onClick={toggleOpened}>
				<div className='titles'>
					<div className='header'>
						<Localized id={baseType} />:{header}
					</div>
					<div className='subheader'>{subheader}</div>
				</div>

				<div className='actions'>
					{editable || allVerified ? null : (
						<div className='edit' onClick={toggleEditable}>
							<Icons.Edit color='rgba(0,0,0, 0.7)' />
							<Localized id='profile-action-edit' />
						</div>
					)}
				</div>
			</div>
		);
	}

	public render() {
		const { certificate } = this.props;
		const { editable, opened } = this.state;
		const pose = opened ? 'opened' : 'closed';

		const header = this.getHeader();
		const isVerified = certificate && certificate.verification && certificate.verification.status === VERIFIED;
		const content = editable && !isVerified ? this.getForm() : this.getPreview();

		const itemProps = {
			className: 'certificate item ' + pose,
			withParent: false,
			pose,
		};

		return (
			<Container {...itemProps}>
				{header}
				{content}
			</Container>
		);
	}
}

const mapStateToProps = ({ user, common }: RootState) => ({
	locales: common.bundlesLocales,
	sessionToken: user.sessionToken,
	brands: common.brands,
	trailerTypes: common.trailers,
	transportTypes: common.transports,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateTransportCertificate: DriverActions.updateTransportCertificate,
		},
		dispatch,
	);

export default connect<CertificateItem.StateProps, CertificateItem.DispatchProps, CertificateItem.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(CertificateItem);

export namespace CertificateItem {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
		sessionToken?: string;
		brands?: BidApiModel.CommonMeta[];
		trailerTypes?: { [name: string]: BidApiModel.CommonMeta };
		transportTypes?: { [name: string]: BidApiModel.CommonMeta };
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updateTransportCertificate?: (certificate: DriverActions.Payload.Docs<Certificate>) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		certificate: Certificate;
		transportId: string;
		allVerified?: boolean;
	}

	// Main component state
	export interface State {
		certType: 'trailer' | 'transport';
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		editable: boolean;
		loading: boolean;
		opened: boolean;
		validationReport: string[];
	}
}
