import { SchemeProps, BasicTypes } from 'app/containers/Builder/props';
import { getPhoneMask } from 'app/utils';

const scheme: SchemeProps.Form = [
	{
		label: 'form-contact-label-first-name',
		type: BasicTypes.Input,
		name: 'firstName',
		localized: true,
		required: true,
		rounded: true,
	},

	{
		label: 'form-contact-label-last-name',
		type: BasicTypes.Input,
		name: 'lastName',
		localized: true,
		required: true,
		rounded: true,
	},

	{
		label: 'form-contact-label-middle-name',
		type: BasicTypes.Input,
		name: 'middleName',
		localized: true,
		required: true,
		rounded: true,
	},

	{
		label: 'form-contact-label-phone',
		type: BasicTypes.Input,
		localized: true,
		required: true,
		rounded: true,
		name: 'phone',

		mask: (value: string) => getPhoneMask(value),
	},
];

export default scheme;
