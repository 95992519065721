import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Localized } from '@fluent/react';
import { useDispatch, useSelector } from 'react-redux';
import { CommonActions, getBidPoints, BidsType } from 'app/actions';
import { SidebarType, RoleEnum } from 'app/models';
import PageTitle from 'app/components/PageTitle';
import { OrdersOnMap } from './OrdersOnMap';
import { Tabs } from 'app/sharedComponents/Tabs';

const tabs = [
	{ text: 'orders-tab-all', tabId: BidsType.ALL },
	{ text: 'orders-tab-my', tabId: BidsType.MY },
	{ text: 'orders-tab-archive', tabId: BidsType.ARCHIVE },
];

const NewOrders = () => {
	const [tab, setTab] = useState<string>(tabs[0].tabId);
	const history = useHistory();
	const profile = useSelector((state: any) => state.user.profile);
	const dispatch = useDispatch();

	const handleSetTab = (tabId: BidsType) => {
		setTab(tabId);

		history.push(`/panel/orders/${tabId}`);
		dispatch(getBidPoints(tabId));
	};

	const hanleOrderCreate = async () => {
		dispatch(
			CommonActions.toggleSidebar({
				type: SidebarType.OrderCreate,
				value: true,
			}),
		);
	};

	useEffect(() => {
		history.push(`/panel/orders/all`);
		dispatch(getBidPoints(BidsType.ALL));
	}, []);

	const hideAction = !profile || profile.role !== RoleEnum.Customer;
	const isProfileFilled =
		profile && !profile.needActions.includes('fill_profile') && !profile.needActions.includes('company');
	const actionTextId = isProfileFilled ? 'orders-action-create' : 'orders-attention-fill-profile';
	return (
		<div className='orders page' style={{ width: '100%' }}>
			<PageTitle titleKey='title-orders' />
			<div className='header widget'>
				<div className='container'>
					<div className='title'>
						<Localized id='orders-page-title' />
					</div>
					<div className='actions'>
						<Tabs activeTab={tab} tabs={tabs} onTabClick={handleSetTab} />
						{!hideAction && (
							<button disabled={!isProfileFilled} className='action' onClick={hanleOrderCreate}>
								<Localized id={actionTextId} />
							</button>
						)}
					</div>
				</div>
			</div>
			<OrdersOnMap selectedTab={tab} />
		</div>
	);
};

export default NewOrders;
