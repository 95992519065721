import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { BidActions, CommonActions } from 'app/actions';
// import { BidActions, CommonActions, getBidPoints } from 'app/actions';
import { SidebarType, Bid } from 'app/models';
import { GMap, Route } from 'app/components/GMap';
import { parseLocation } from 'app/utils/transport';

import MapBubble from './MapBubble';
import { Filters as OrderFilters } from './Filters';
import {
	Content,
	Filters,
	MapZone,
	LeftPanel,
	MapContainer,
	FiltersLeftPart,
	FiltersRightPart,
} from '../Executors/components/StyledCandidatesComponents';
import { GroupFilter } from 'app/sharedComponents/GroupFilter';

import { BidList } from '../Executors/components/BidList';
import { OrdersListNew } from './ordersList/OrdersList';

const groups = {
	LIST: 'list',
	MAP: 'map',
};

const grouping = [
	{ id: groups.LIST, title: 'список' },
	{ id: groups.MAP, title: 'карта' },
];

const getBid = (bidId: string, list: Bid[]): Bid | undefined => list.find((bid: Bid) => bid.id === bidId);

export const OrdersOnMap = ({ selectedTab }: any) => {
	const dispatch = useDispatch();
	const [currentGroup, setCurrentGroup] = useState('list');
	const [compact, setCompact] = useState(false);
	const [currentSort, setCurrentSort] = useState('');

	const [selectedBids, setSelectedBids] = useState<string[]>([]);
	const [routeToShow, setRouteToShow] = useState<Route | undefined>(undefined);

	const { list, cursor, loading } = useSelector((state: any) => state.bid[selectedTab]);
	const { filter: filterOnMap, bounds } = useSelector((state: any) => state?.transportOnMap);

	const filters = useSelector((state: any) => state.bid.filters);

	const bidsOnMap = useSelector((state: any) => state.bidPoints[selectedTab]);
	const handleGroupChange = (val: any) => {
		setCurrentGroup(val);
	};

	const markers = bidsOnMap.map(({ uuid, location }: any) => ({
		id: uuid,
		location: {
			lat: location.latitude,
			lng: location.longitude,
		},
		data: {
			showOnMap: true,
			bidId: uuid,
			bid: getBid(uuid, list),
		},
	}));

	const onLoadMore = async () => {
		if (cursor?.next && !loading) {
			dispatch(
				BidActions.getBidsNew({
					params: {
						type: selectedTab,
						ordering: [''],
						...filters,
					},
					skipBox: currentGroup === groups.LIST,
					cursor: cursor.next,
					loadMore: true,
				}),
			);
		}
	};

	const onSort = (sort: string) => {
		const sortColumn = sort.replace('-', '');
		const isDownSorting = sort[0] === '-';
		if (sortColumn === currentSort && !isDownSorting) {
			setCurrentSort('-' + sortColumn);
		} else {
			setCurrentSort(sortColumn);
		}
	};

	const handleBidHover = (val: any) => {
		setSelectedBids(selectedBids[0] === val ? [] : [val]);
	};

	const handleClusterSelect = (items: any[]) => {
		const newSelectedBids = items.map((item: any) => item?.properties?.id);
		setSelectedBids(newSelectedBids);
	};

	const handleShowRoute = (bidId: string) => {
		if (routeToShow?.id === bidId) {
			setRouteToShow(undefined);
			return;
		}
		const bid = getBid(bidId, list);

		if (bid && bid.direction) {
			const { from, to } = bid.direction;
			setRouteToShow({
				origin: parseLocation(from.location),
				destination: parseLocation(to.location),
				id: bid.id,
			});
		}
	};

	const onRowClick = async (bidId: string) => {
		handleShowRoute(bidId);
		loadBidInfo(bidId);
	};

	const loadBidInfo = (bidId: string) => {
		dispatch(BidActions.getDetailsBid(bidId));
		const currBid = getBid(bidId, list);
		if (currBid) {
			dispatch(
				CommonActions.toggleSidebar({
					type: currBid.kind === 'tender' ? SidebarType.TenderView : SidebarType.OrderDetails,
					value: true,
				}),
			);
		}
	};

	const onMarkerClick = (bidId: string) => loadBidInfo(bidId);

	const highlightedMarkers = useMemo(
		() =>
			selectedBids.reduce(
				(acc: any, id: any) => ({
					...acc,
					[id]: true,
				}),
				{},
			),
		[selectedBids],
	);

	const getFilteredBid = (updatedFilters: any, ordering: any) => {
		dispatch(
			BidActions.getBidsNew({
				params: { type: selectedTab, ordering, ...updatedFilters },
				skipBox: currentGroup === groups.LIST,
			}),
		);
	};

	useEffect(() => {
		const ordering = [currentSort];
		getFilteredBid(filters, ordering);
	}, [selectedTab, currentSort, filters, currentGroup, filterOnMap]);

	useEffect(() => {
		if (filterOnMap) {
			const ordering = [currentSort];
			getFilteredBid(filters, ordering);
		}
	}, [bounds]);

	useEffect(() => {
		if (list.length < 20 && cursor?.next) onLoadMore();
	}, [list, loading]);

	return (
		<Content>
			<Filters>
				<FiltersLeftPart>
					<GroupFilter active='' title='вид' items={grouping} onChange={handleGroupChange} />
				</FiltersLeftPart>
				<FiltersRightPart>
					<OrderFilters />
				</FiltersRightPart>
			</Filters>
			<MapZone>
				{currentGroup === groups.MAP && (
					<div className='maps-div'>
						<LeftPanel className='left-panel'>
							<BidList
								bidoffers={list.map((bid: any) => ({
									bid,
								}))}
								loading={loading}
								selectedBids={selectedBids}
								selectedBid={''}
								onSelectBid={onRowClick}
								onLoadMore={onLoadMore}
								handleBidHover={handleBidHover}
							/>
						</LeftPanel>
						<MapContainer className='map-container'>
							<GMap
								singleRoute
								showMapMoveSync
								showZoomButtons
								routeToShow={routeToShow}
								markers={markers}
								onMarkerClick={onMarkerClick}
								hintComponent={MapBubble}
								onClusterSelect={handleClusterSelect}
								onMarkerSelect={handleBidHover}
								highlightedMarkers={highlightedMarkers}
							/>
						</MapContainer>
					</div>
				)}
				{currentGroup === groups.LIST && (
					<OrdersListNew
						isTender={filters.kind === 'tender'}
						onSort={onSort}
						cursor={cursor}
						onLoadMore={onLoadMore}
						onRowClick={onRowClick}
						list={list}
						tab={selectedTab}
						compact={compact}
						setCompact={setCompact}
						currentSort={currentSort}
						loader={loading}
					/>
				)}
			</MapZone>
		</Content>
	);
};
