import { createAction } from 'redux-actions';

export const SET_TRANSPORT_INFO = 'transport/SET_INFO';
export const CLEAR_TRANSPORT = 'transport/CLEAR';
export const ADD_TRANSPORT_INFO_ARRAY = 'transport/ADD_TRANSPORT_INFO_ARRAY';

type SetTransportInfoPayload = any;

export const setTransportInfo = createAction<SetTransportInfoPayload>(SET_TRANSPORT_INFO);
export const clearTransport = createAction(CLEAR_TRANSPORT);
export const addTransportInfoArray = createAction<SetTransportInfoPayload[]>(ADD_TRANSPORT_INFO_ARRAY);
