import { all, fork } from 'redux-saga/effects';
import { watchGetCarCertificates } from './driver';
import { watchCarCreation } from './emptyCar';
import { watchCandidates } from './candidates';
import { watchExecutors } from './executors';
import { watchCustomer } from './customer';
import { watchBid } from './bids';
import { watchCommon } from './common';
import { watchBidPoints } from './bidPoints';
import { watchDocuments } from './documents';

export function* rootSaga() {
	yield all([
		fork(watchGetCarCertificates),
		fork(watchCarCreation),
		fork(watchCandidates),
		fork(watchExecutors),
		fork(watchCustomer),
		fork(watchBid),
		fork(watchCommon),
		fork(watchBidPoints),
		fork(watchDocuments),
	]);
}
