import React from 'react';
import styled from 'styled-components';

const variants = [
	'body1',
	'body2',
	'body3',
	'body4',
	'body5',
	'body6',
	'h1',
	'h2',
	'h3',
	'h4',
	'h5',
	'link1',
	'link2',
] as const;

type Variant = (typeof variants)[number];

export const variantMapping = {
	body1: '19',
	body2: '16',
	body3: '14',
	body4: '12',
	body5: '10',
	body6: '8',
	h1: '48',
	h2: '44',
	h3: '36',
	h4: '32',
	h5: '30',
	h6: '22',
	link1: '14',
	link2: '16',
};

const getSize = (val: Variant) => `${variantMapping[val] || '14'}px`;

const Text = styled.span<{ bold?: boolean; variant: Variant; color: string; textTransform: TextTransform }>`
	font-family: UniNeue;
	font-weight: ${(props) => (props.bold ? '700' : '500')};
	color: ${(props) => (props.variant.includes('link') ? '#5FC8ED' : props.color)};
	font-size: ${(props) => getSize(props.variant)};
	cursor: ${(props) => (props.variant.includes('link') ? 'pointer' : 'inherit')};
	text-align: left;
	text-transform: ${(props) => props.textTransform};
`;

type TextTransform = 'none' | 'capitalize' | 'uppercase' | 'lowercase' | 'full-width' | 'full-size-kana';

export type TypographyProps = {
	children: any;
	bold?: boolean;
	variant: Variant;
	color?: string;
	textTransform?: TextTransform;
	onClick?: (event: React.MouseEvent<HTMLSpanElement>) => void;
};

export const Typography = ({
	children,
	bold,
	variant,
	color = '#1B1B1B',
	onClick = () => {},
	textTransform = 'none',
}: TypographyProps) => {
	return (
		<Text onClick={onClick} bold={bold} variant={variant} color={color} textTransform={textTransform}>
			{children}
		</Text>
	);
};
