import React from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
// import * as Icons from 'app/components/Icons';
// import { Localized } from '@fluent/react';
import { SidebarType } from 'app/models';
import { CommonActions } from 'app/actions';
import { Typography } from 'app/sharedComponents/Typography';
// import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
const Container = styled.div`
	display: flex;
`;

const Button = styled.div`
	background: #ffffff;
	box-shadow: 0px 2px 6px rgba(187, 192, 209, 0.35);
	border-radius: 10px;
	cursor: pointer;
	padding: 5px 9px;
`;

/* eslint-disable react/display-name */
export const OrderCell = ({ prices, role, from, index }: any) => {
	const buyers = prices ? prices[index] : undefined;

	// const { form } = this.state;
	const dispatch = useDispatch();
	const toggleSidebar = () => {};

	const onOrderClick = async () => {
		if (buyers && role !== 'driver') {
			const params = {
				rate: buyers.transportTotal,
				product: buyers.productName,
				distance: buyers.distance,
				productId: buyers.cropId,
				to: buyers.basisAddress,
				from,
			};

			dispatch(
				CommonActions.toggleSidebar({
					type: SidebarType.OrderCreate,
					value: true,
					params,
				}),
			);
		}
	};

	return (
		<Container>
			<Button onClick={onOrderClick}>
				<Typography variant='body3' color='#64C8EB'>
					ЗАЯВКА
				</Typography>
			</Button>
		</Container>
	);
};
/* eslint-enable react/display-name */
