import React from 'react';
import styled, { keyframes } from 'styled-components';

const blinker = keyframes`
50% {
    opacity: 0;
  }
`;

const WarnContainer = styled.div`
	position: absolute;
	top: 10px;
	left: 50%;
	border: 1px solid red;
	border-radius: 5px;
	padding: 10px;
	z-index: 999999;
	animation: ${blinker} 2s linear infinite;
	background-color: white;
`;

export const ProdWarning = () => <WarnContainer>Connected to prod DB</WarnContainer>;
