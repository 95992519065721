import { createAction } from 'redux-actions';
import { Candidate } from 'app/models';

export type GetCandidateListPayload = {
	cursor?: string;
	uuids?: string[];
	size?: number;
	type?: 'open' | 'confirmed';
	more?: boolean;
};

export type SetCandidateListPayload = {
	cursor?: string;
	data: Candidate[];
};

export type ConfirmCandidateOffersPayload = {
	id: string;
	offers: string[];
};

export type DeclineCandidateOffersPayload = {
	id: string;
	offers: string[];
};

export const GET_CANDIDATE_DETAIL = 'GET_CANDIDATE_DETAIL';
export const SET_CANDIDATE_DETAIL = 'SET_CANDIDATE_DETAIL';
export const SET_CANDIDATE_LOADING = 'SET_CANDIDATE_LOADING';
export const SET_CANDIDATE_UPDATING = 'SET_CANDIDATE_UPDATING';
export const GET_CANDIDATE_LIST = 'GET_CANDIDATE_LIST';
export const GET_CANDIDATE_LIST_MORE = 'GET_CANDIDATE_LIST_MORE';
export const SET_CANDIDATE_LIST = 'SET_CANDIDATE_LIST';
export const CONFIRM_CANDIDATE_OFFERS = 'CONFIRM_CANDIDATE_OFFERS';
export const DECLINE_CANDIDATE_OFFERS = 'DECLINE_CANDIDATE_OFFERS';

export const getCandidateDetail = createAction<string>(GET_CANDIDATE_DETAIL);
export const setCandidateDetail = createAction<Candidate>(SET_CANDIDATE_DETAIL);
export const setCandidateLoading = createAction<boolean>(SET_CANDIDATE_LOADING);
export const setCandidateUpdating = createAction<boolean>(SET_CANDIDATE_UPDATING);
export const getCandidateList = createAction<GetCandidateListPayload>(GET_CANDIDATE_LIST);
export const getCandidateListMore = createAction<void>(GET_CANDIDATE_LIST_MORE);
export const setCandidateList = createAction<SetCandidateListPayload>(SET_CANDIDATE_LIST);
export const confirmCandidateOffers = createAction<ConfirmCandidateOffersPayload>(CONFIRM_CANDIDATE_OFFERS);
export const declineCandidateOffers = createAction<DeclineCandidateOffersPayload>(DECLINE_CANDIDATE_OFFERS);
