import * as React from 'react';
import { Localized } from '@fluent/react';

import { UserProfile, RoleEnum } from 'app/models';

const profileIcon = require('../../../assets/icons/profile.png');

const UserProfile: React.FC<{ profile: UserProfile }> = ({ profile: { avatar, role, firstName, lastName } }) => (
	<div className='profile widget'>
		<div className={'avatar ' + (!avatar ? 'empty' : '')}>
			<img src={avatar ? avatar : profileIcon} alt='' />
		</div>

		<div className='user'>
			<div className='name'>
				{lastName} {firstName}
			</div>

			<div className='role'>
				<Localized id={role === RoleEnum.Customer ? 'role-customer' : 'role-driver'} />
			</div>
		</div>
	</div>
);

export default UserProfile;
