import { put, takeEvery, call, select } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import {
	GetCandidateListPayload,
	ConfirmCandidateOffersPayload,
	DeclineCandidateOffersPayload,
	setCandidateLoading,
	setCandidateUpdating,
	setCandidateList,
	setCandidateDetail,
	GET_CANDIDATE_DETAIL,
	GET_CANDIDATE_LIST,
	GET_CANDIDATE_LIST_MORE,
	CONFIRM_CANDIDATE_OFFERS,
	DECLINE_CANDIDATE_OFFERS,
} from '../actions';
import * as api from '../api';

export function* getCandidateDetail(action: Action<string>): Generator<any, void, any> {
	try {
		const response = yield api.v1.get(`/customer/bidoffers/${action.payload}`);
		yield put(setCandidateDetail(response.data.data));
	} catch (error) {
		yield call<any>(api.apiSentryException, error); // TODO: check type checking
	}
}

export function* getCandidateList(action: Action<GetCandidateListPayload>): Generator<any, void, any> {
	yield put(setCandidateLoading(true));
	const { filter, bounds } = yield select((state) => state.transportOnMap);

	const bbox = filter && bounds ? `?in_bbox=${bounds}&size=200` : '';
	try {
		const response = yield api.v1.get(`/customer/bidoffers${bbox}`, {
			params: action.payload,
		});
		yield put(setCandidateList({ data: response.data.data, cursor: response.data.meta?.links?.next }));
	} catch (error) {
		yield call<any>(api.apiSentryException, error); // TODO: check type checking
	}
	yield put(setCandidateLoading(false));
}

export function* getCandidateListMore(): Generator<any, void, any> {
	const cursor = yield select((state) => state.candidates.cursor);
	const { filter, bounds } = yield select((state) => state.transportOnMap);
	const bbox = filter && bounds ? `?in_bbox=${bounds}&size=200` : '';

	if (cursor) {
		yield put(setCandidateLoading(true));
		try {
			const response = yield api.v1.get(`/customer/bidoffers${bbox}`, { params: { cursor } });
			const prev = yield select((state) => state.candidates.data);
			const next = prev.concat(response.data.data);
			yield put(setCandidateList({ data: next, cursor: response.data.meta?.links?.next }));
		} catch (error) {
			yield call<any>(api.apiSentryException, error); // TODO: check type checking
		}
		yield put(setCandidateLoading(false));
	}
}

export function* confirmCandidateOffers(action: Action<ConfirmCandidateOffersPayload>): Generator<any, void, any> {
	yield put(setCandidateUpdating(true));
	try {
		const { id, offers } = action.payload!;
		const body = { confirm: offers.map((id) => ({ id })), decline: [] };
		const response = yield api.v1.put(`/customer/bidoffers/${id}`, body);
		const candidates = (yield select((state) => state.candidates.data)).map((candidate: any) => {
			return candidate.bid.id === response.data.data.bid.id ? response.data.data : candidate;
		});
		yield put(setCandidateList({ data: candidates }));
		yield put(setCandidateDetail(response.data.data));
	} catch (error) {
		yield call<any>(api.apiSentryException, error); // TODO: check type checking
	}
	yield put(setCandidateUpdating(false));
}

export function* declineCandidateOffers(action: Action<DeclineCandidateOffersPayload>): Generator<any, void, any> {
	yield put(setCandidateUpdating(true));
	try {
		const { id, offers } = action.payload!;
		const body = { confirm: [], decline: offers.map((id) => ({ id })) };
		const response = yield api.v1.put(`/customer/bidoffers/${id}`, body);
		const candidates = (yield select((state) => state.candidates.data)).map((candidate: any) => {
			return candidate.bid.id === response.data.data.bid.id ? response.data.data : candidate;
		});
		yield put(setCandidateList({ data: candidates }));
	} catch (error) {
		yield call<any>(api.apiSentryException, error); // TODO: check type checking
	}
	yield put(setCandidateUpdating(false));
}

export function* watchCandidates() {
	yield takeEvery(GET_CANDIDATE_DETAIL, getCandidateDetail);
	yield takeEvery(GET_CANDIDATE_LIST, getCandidateList);
	yield takeEvery(GET_CANDIDATE_LIST_MORE, getCandidateListMore);
	yield takeEvery(CONFIRM_CANDIDATE_OFFERS, confirmCandidateOffers);
	yield takeEvery(DECLINE_CANDIDATE_OFFERS, declineCandidateOffers);
}
