import { Place } from 'app/models';
import Posed from 'react-pose';

import StaticMap from 'app/components/StaticMap';
import { Field, SchemeProps } from '../props';
import { Localized } from '@fluent/react';
import * as React from 'react';

const minusIcon = require('../../../../assets/icons/minus.svg');
const plusIcon = require('../../../../assets/icons/plus.svg');

export const Container = Posed.div({
	closed: {
		height: 0,

		transition: {
			ease: 'easeOut',
			duration: 100,
		},
	},

	opened: {
		height: '100%',

		transition: {
			ease: 'easeIn',
			duration: 100,
		},
	},
});

export class Map extends React.Component<Map.Props, Map.State> implements Field.Hooks<Map.Hook> {
	public staticMap: StaticMap | null = null;

	public state: Map.State = {
		distance: 0,
	};

	constructor(props: Map.Props) {
		super(props);

		this.updateDistance = this.updateDistance.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);
	}

	public async updateDistance(distance: number) {
		// console.log("updateDistance: ", distance)
		const { onUpdate } = this.props;
		this.setState({ distance });

		onUpdate('autoDistance', distance);
		onUpdate('distance', distance);
	}

	public async updateValue() {
		// @unimplemented
	}

	public async reset() {
		await this.setState({ distance: 0 });
		this.forceUpdate();
	}

	public render() {
		const {
			form,
			field: { placesBy },
			onUpdate,
		} = this.props;
		const { distance } = this.state;

		const from = form[placesBy[0]];
		const to = form[placesBy[1]];

		// const fromId = from && from.id;
		// const toId = to && to.id;
		// const isSamePlaces = fromId === toId;

		const directionProps = {
			ref: (node: StaticMap | null) => (this.staticMap = node),
			width: '18rem',
			from,
			to,
			// Here get distance in meters from maps api
			onUpdate: (result: any) => {
				const distanceKm = Math.floor(result.distance / 1000);

				this.setState({ distance: distanceKm });
				// console.log("initial instance distance", distance)

				onUpdate('autoDistance', distanceKm);
				onUpdate('distance', distanceKm);
			},
		};

		const onIncrement = () => {
			const newValue = distance + 1;
			this.setState({ distance: newValue });
			onUpdate('distance', newValue);
		};

		const onDecrement = () => {
			const newValue = distance - 1;
			this.setState({ distance: newValue });
			onUpdate('distance', newValue);
		};

		return !!from && !!to ? (
			<div className='map'>
				<div className='distance'>
					<div className='title'>
						<div className='label'>
							<Localized id='builder-map-distance' />
						</div>
						<div className='text'>
							{distance ? distance : 0} <Localized id='builder-map-distance-km' />
						</div>
					</div>
					<div className='actions'>
						<div className='action' onClick={onDecrement}>
							<img src={minusIcon} alt='' />
						</div>
						<div className='action' onClick={onIncrement}>
							<img src={plusIcon} alt='' />
						</div>
					</div>
				</div>

				<StaticMap {...directionProps} />
			</div>
		) : null;
	}
}

export namespace Map {
	export interface Hook {
		from: Place;
		to: Place;
	}

	export interface Props extends Field.ComponentProps<number, SchemeProps.Map> {
		// @default from base
	}

	export interface State {
		distance: number;
	}
}
