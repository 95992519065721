import { takeEvery, call, put, select } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import { toast } from 'react-toastify';

import * as api from '../api';

import {
	GET_CUSTOMER_COMPANIES,
	GET_CUSTOMER_OFFERS,
	GET_CUSTOMER_OFFER_DETAILS,
	CONFIRM_CUSTOMER_OFFER,
	DECLINE_CUSTOMER_OFFER,
	СonfirmDeclineCustomerOfferPayload,
	setCustomerCompanies,
	setCustomerOffers,
	addCustomerOffers,
	setCustomerOffersLoading,
	setCustomerOffersDetails,
	setCustomerOffersDetailsLoading,
	getCustomerOffersDetails as getCustomerOffersDetailsAction,
	updateCustomerOffer,
	removeCustomerOffer,
} from 'app/actions/customer';
import { Company } from 'app/models';

const parseFilters = (filters: any) => {
	const str = Object.keys(filters).reduce((acc, key) => {
		return filters[key] ? `${acc}${acc && ','}${key}` : acc;
	}, '');

	return str;
};

function* getCustomerCompanies(): Generator<any, void, any> {
	try {
		const response = yield api.v1.get('/customer/company');
		const data: Company[] = response.data.data;
		yield put(setCustomerCompanies(data));
	} catch (error) {
		console.log(error);
	}
}

function* getCustomerOffers(action: any): Generator<any, void, any> {
	try {
		const cursorParams = action.payload ? `&cursor=${action.payload}` : '';
		const filters = yield select((state) => state.filters);
		const { filter: bboxFilter, bounds } = yield select((state) => state.transportOnMap);
		const filtersStr = parseFilters(filters);
		const directionParams = filtersStr ? `&direction=${filtersStr}` : '';
		const bboxParams = bboxFilter && bounds && Object.keys(bounds).length ? `&in_bbox=${bounds}` : '';
		yield put(setCustomerOffersLoading(true));
		const offers = yield api.v1.get(`/customer/transportoffers?size=10${cursorParams}${directionParams}${bboxParams}`);
		const {
			data,
			meta: { links },
		} = offers.data;
		const actionName = cursorParams ? addCustomerOffers : setCustomerOffers;
		yield put(
			actionName({
				data,
				next: links.next,
				previous: links.previous,
			}),
		);
	} catch (error) {
		yield call(toast.error, 'Ошибка при загрузке откликов', { position: toast.POSITION.TOP_CENTER });
	}
}

function* getCustomerOffersDetails(action: any): Generator<any, void, any> {
	try {
		yield put(setCustomerOffersDetailsLoading(true));
		const result = yield api.v1.get(`/customer/transportoffers/${action.payload?.id}`);
		if (result && result.data && result.data.data) {
			yield put(setCustomerOffersDetails(result.data.data));
			if (action.payload.updateOffer) {
				yield put(updateCustomerOffer(result.data.data));
			}
		}
	} catch (error) {
		yield call(toast.error, 'Ошибка при загрузке информации о транспорте', { position: toast.POSITION.TOP_CENTER });
	}
}

function* confirmCustomerOffer(action: Action<СonfirmDeclineCustomerOfferPayload>): Generator<any, void, any> {
	try {
		const { id, offer } = action.payload!;
		const { uuid: transportId } = yield select((state) => state.customerTransport.details.data.transport);
		const body = { confirm: [{ id: offer }], decline: [] };
		yield put(setCustomerOffersDetailsLoading(true));

		yield api.v1.put(`/customer/bidoffers/${id}`, body);
		yield put(getCustomerOffersDetailsAction({ id: transportId, updateOffer: true }));
		yield put(setCustomerOffersDetailsLoading(false));
	} catch (error) {
		yield call(toast.error, 'Ошибка при подтверждении офера', { position: toast.POSITION.TOP_CENTER });
		yield put(setCustomerOffersDetailsLoading(false));
	}
}

function* declineCustomerOffer(action: Action<СonfirmDeclineCustomerOfferPayload>) {
	try {
		const { id, offer } = action.payload!;
		const { transport, driver, offers, company } = yield select((state) => state.customerTransport.details.data);

		const body = { confirm: [], decline: [{ id: offer }] };
		yield put(setCustomerOffersDetailsLoading(true));
		yield api.v1.put(`/customer/bidoffers/${id}`, body);
		if (offers.length > 1) {
			yield put(getCustomerOffersDetailsAction({ id: transport.uuid, updateOffer: true }));
		} else {
			yield put(setCustomerOffersDetails({ transport, driver, company, offers: [] }));
			yield put(removeCustomerOffer(transport.uuid));
		}
		yield put(setCustomerOffersDetailsLoading(false));
	} catch (error) {
		yield call(toast.error, 'Ошибка при отклонении офера', { position: toast.POSITION.TOP_CENTER });
		yield put(setCustomerOffersDetailsLoading(false));
	}
}

export function* watchCustomer() {
	yield takeEvery(GET_CUSTOMER_COMPANIES, getCustomerCompanies);
	yield takeEvery(GET_CUSTOMER_OFFERS, getCustomerOffers);
	yield takeEvery(GET_CUSTOMER_OFFER_DETAILS, getCustomerOffersDetails);
	yield takeEvery(CONFIRM_CUSTOMER_OFFER, confirmCustomerOffer);
	yield takeEvery(DECLINE_CUSTOMER_OFFER, declineCustomerOffer);
}
