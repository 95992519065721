import styled from 'styled-components';
import React, { useState, useEffect, useRef } from 'react';
import Actions from './Actions';
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
import { CandidateData, CandidateStatus } from './types/CandidateData';
import { driverName, driverInfo } from 'app/utils/transport';

import { CAPABILITIES } from 'app/models/bid';

import {
	ExpansionCard,
	CardRow,
	CardHeader,
	CardPhoto,
	CardPhotoSmall,
	CardPhotoVerified,
	CardStatus,
	Conditions,
	PhotoCheckBox,
	Options,
} from './Common';

const ICON_NO_AVATAR = require('src/assets/icons/avatar_driver.jpg');
const ICON_NO_DRIVER_PHOTO = require('src/assets/icons/avatar_autotype.png');
const ICON_DROPDOWN = require('src/assets/icons/i-arrow-drop.png');
const ICON_REPLIES = require('assets/icons/i-reply.png');
const ICON_APPROVES = require('assets/icons/i-approve.png');
const ICON_VERIFIED = require('assets/icons/svg/ic_verification_small.svg');

const STATUS_ICONS: { [key: string]: string } = {
	open: ICON_REPLIES,
	confirmed: ICON_APPROVES,
};

type OfferCardContainerProps = {
	selected?: boolean;
};

const OfferCardContainer = styled(ExpansionCard)<OfferCardContainerProps>`
	--expanded-height: 400px;
	background-color: ${(props) => (props.selected ? '#D6F3FD' : 'none')};
`;

export type OfferCardProps = {
	data: CandidateData;
	showDriverFirst?: Boolean;
	checked?: Boolean;
	noSelect?: Boolean;
	selected?: boolean;
	onDeclineClick?: (event: MouseEvent) => any;
	onAcceptClick?: (event: MouseEvent) => any;
	onActionMessageClick?: (event: MouseEvent) => any;
	onActionRouteClick?: (event: MouseEvent) => any;
	onActionTrackClick?: (event: MouseEvent) => any;
	onSelectClick?: (data: CandidateData) => any;
	onMouseEnter?: (event: MouseEvent) => any;
	onMouseLeave?: (event: MouseEvent) => any;
	choosen?: boolean;
	onExpand?: (id: string, expanded: boolean) => void;
};

export const OfferCard: React.FC<OfferCardProps> = (props) => {
	const [isOpened, setOpenState] = useState(false);
	const {
		data = {} as CandidateData,
		showDriverFirst,
		checked,
		selected,
		noSelect,
		choosen = false,
		onMouseEnter,
		onMouseLeave,
		onExpand,
	} = props;
	const { transports = [], user: { isVerified = false } = {} } = data;
	const currentDriver: any = transports[0] || {};
	const onSelectClick = (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		props.onSelectClick?.(props?.data);
		e?.preventDefault?.();
	};
	let node: HTMLElement | null;
	const setExpansionHeight = () => {
		if (!node) return;
		const styles = node.getAttribute('style') || '';
		if (styles.includes('--expanded-height')) return;
		const updatedStyles = styles.split(';');
		updatedStyles.push('--expanded-height: ' + node.scrollHeight + 'px');
		node.setAttribute('style', updatedStyles.join(';'));
	};

	const handleMessage = () => {
		window.open(`https://wa.me/${currentDriver?.contact?.phone.replace('+', '')}`);
	};

	const handleExpand = () => {
		setOpenState(!isOpened);
		if (onExpand) {
			onExpand(data?.transports?.[0]?.transport?.uuid, !isOpened);
		}
	};
	useEffect(() => {
		setOpenState(choosen);
		if (choosen) {
			offerCardRef.current?.scrollIntoView({
				behavior: 'smooth',
			});
		}
	}, [choosen]);

	const offerCardRef = useRef<HTMLInputElement>(null);
	const primaryPhoto = showDriverFirst
		? data.transports?.[0]?.contact?.avatar || ICON_NO_AVATAR
		: data.transports?.[0]?.transport?.avatar || ICON_NO_DRIVER_PHOTO;
	const secondaryPhoto = !showDriverFirst
		? data.transports?.[0]?.contact?.avatar || ICON_NO_AVATAR
		: data.transports?.[0]?.transport?.avatar || ICON_NO_DRIVER_PHOTO;
	return (
		//@ts-ignore
		<div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} ref={offerCardRef}>
			<OfferCardContainer
				selected={selected}
				ref={(n) => (node = n)}
				onMouseDown={setExpansionHeight}
				data-expanded={isOpened || undefined}
			>
				<CardHeader>
					<div className='photos'>
						{!noSelect ? <PhotoCheckBox onClick={onSelectClick} data-checked={checked || undefined} /> : null}
						<CardPhoto src={primaryPhoto} />
						{isVerified ? <CardPhotoVerified src={ICON_VERIFIED} /> : null}
						<CardPhotoSmall src={secondaryPhoto} />
					</div>

					<button onClick={handleExpand} className='actions__dropdown-button'>
						<div className='info'>
							<div className='info__name'>
								<div>{showDriverFirst ? driverName(currentDriver?.contact) : currentDriver?.transport?.ident}</div>
							</div>

							<div className='info__data'>
								<div>{showDriverFirst ? driverInfo(data) : currentDriver?.transport?.transportType?.name}</div>
							</div>
						</div>

						<div className='actions'>
							<img className='dropdown-indicator' src={ICON_DROPDOWN} width='24' />
						</div>
					</button>
				</CardHeader>
				<CardStatus value={STATUS_ICONS[data?.status]} />
				<CardHeader>
					<div className='photos'>
						<CardPhoto src={secondaryPhoto} />
					</div>

					<div className='info'>
						<div className='info__name'>
							<div>{showDriverFirst ? currentDriver?.transport?.ident : driverName(currentDriver?.contact)}</div>
						</div>

						<div className='info__data'>
							<div>{showDriverFirst ? currentDriver?.transport?.transportType?.name : driverInfo(data)}</div>
						</div>
					</div>
				</CardHeader>
				<CardRow className='borders'>
					<Actions
						actions={[
							{
								icon: require('assets/icons/i-message.png'),
								title: 'Написать',
								handler: handleMessage,
								disabled: !currentDriver?.contact?.phone,
							},
							{
								icon: require('assets/icons/i-route.png'),
								title: 'Показать маршрут',
								disabled: !props.onActionRouteClick,
								handler: props.onActionRouteClick,
							},
							// {
							//     icon: require('assets/icons/i-tracking.png'),
							//     title: 'Трек скрыт',
							//     handler: props.onActionTrackClick,
							// },
						]}
					/>
				</CardRow>

				{props.data.status === CandidateStatus.OPEN ? (
					<CardRow>
						<Conditions>
							<h1> Условие от водителя </h1>
							<Options>
								<tbody>
									<tr>
										<td>Дата погрузки</td>
										<td>не указано</td>
									</tr>
									<tr>
										<td>Новая ставка</td>
										<td>{data?.price}</td>
									</tr>
									{data?.capabilities?.includes(CAPABILITIES.VERIFY) ? (
										<tr>
											<td colSpan={2}>
												{data?.capabilities?.includes(CAPABILITIES.VERIFY) && (
													<Button styleType={ButtonStyleTypes.DEFAULT}>Необходима верификация</Button>
												)}
											</td>
										</tr>
									) : (
										<tr>
											<td>
												{data?.capabilities?.includes(CAPABILITIES.CONFIRM) && (
													<Button styleType={ButtonStyleTypes.PRIMARY} onClick={props.onAcceptClick}>
														Подтвердить
													</Button>
												)}
											</td>
											<td>
												{data?.capabilities?.includes(CAPABILITIES.DECLINE) && (
													<Button styleType={ButtonStyleTypes.DEFAULT} onClick={props.onDeclineClick}>
														Отклонить
													</Button>
												)}
											</td>
										</tr>
									)}
								</tbody>
							</Options>
						</Conditions>
					</CardRow>
				) : null}
			</OfferCardContainer>
		</div>
	);
};
