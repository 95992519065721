export const minLength = (number: number, message?: string) => {
	return (value: string) => {
		if (value.length < number) return message || false;

		return true;
	};
};

export const maxLength = (number: number, message?: string) => {
	return (value: string) => {
		if (value.length > number) return message || false;

		return true;
	};
};

export const regex = (regex: RegExp, message?: string) => {
	return (value: string) => {
		if (!regex) return 'RegExp incorrect';
		if (!regex.test(value)) return message || false;

		return true;
	};
};

export const lessThan = (number: number, message?: string) => {
	return (value: any) => {
		if (value !== '' && value > number) return message || false;
		return true;
	};
};

export const moreThan = (number: number, message?: string) => {
	return (value: any) => {
		if (value !== '' && value <= number) return message || false;

		return true;
	};
};

export const equals = (string: string, message?: string) => {
	return (value: string) => {
		if (value !== string) return message || false;

		return true;
	};
};

export const isDateEarlierThanToday = (message?: string) => {
	return (value: string) => {
		const valueDate = new Date(value);
		const today = new Date();
		if (valueDate > today) return message || false;

		return true;
	};
};

export const isDateLaterThanToday = (message?: string) => {
	return (value: string | Date) => {
		const valueDate = value instanceof Date ? value : new Date(value);
		const today = new Date();

		if (valueDate < today) return message || false;

		return true;
	};
};
