import React, { useEffect, useState } from 'react';

//other deps
import camelCaseKeys from 'camelcase-keys';

//redux
import { useSelector } from 'react-redux';

//types
type Topic = 'my' | 'free';

type TopicWebSocketProps = {
	topic: Topic;
	onGetMessages: (messages: any[]) => void;
};

//helpers
import { getSessionToken } from 'app/config';
import { getApiHost } from './TransportsWebSocket.helpers';

//utils
import utils from './TransportsWebSocket.utils';

//----------------------------------------------------------
// TransportsWebSocket
//----------------------------------------------------------
const TransportsWebSocket: React.FC<TopicWebSocketProps> = ({ topic, onGetMessages }) => {
	const [socket, setSocket] = useState<WebSocket | null>(null);

	const sessionToken = useSelector((state: any) => state?.user?.sessionToken);

	useEffect(() => {
		const webSocketUrl = getApiHost();
		const newSocket = new WebSocket(webSocketUrl);

		setSocket(newSocket);

		return () => {
			newSocket.close();
			setSocket(null);
		};
	}, []);

	useEffect(() => {
		if (socket) {
			socket.onopen = () => {
				const authAction = {
					token: getSessionToken(sessionToken),
					action: 'auth',
				};

				socket.send(JSON.stringify(authAction));

				const subscribeAction = {
					topic: topic,
					action: 'subscribe',
				};

				socket.send(JSON.stringify(subscribeAction));
			};

			socket.onclose = () => {
				utils.subscribe<any>(topic, onGetMessages).remove();
			};

			socket.onmessage = (e) => {
				const message = JSON.parse(e.data);
				const data = camelCaseKeys(message, { deep: true });

				utils.publish<any[]>(topic, { ...data, topics: [topic] });
			};
		}
	}, [socket, topic]);

	useEffect(() => {
		utils.subscribe<any>(topic, onGetMessages);
	}, [topic]);

	return null; // Этот компонент не должен ничего отображать на экране
};

export default TransportsWebSocket;
