import {
	SetCandidateListPayload,
	SET_CANDIDATE_LOADING,
	SET_CANDIDATE_UPDATING,
	SET_CANDIDATE_LIST,
	SET_CANDIDATE_DETAIL,
} from 'app/actions';
import { Action, handleActions } from 'redux-actions';
import { Candidate } from 'app/models';

type State = {
	detail: Candidate | null;
	data: Candidate[];
	cursor: string | null;
	loading: boolean;
	updating: boolean;
};

export const candidatesReducer = handleActions<State, any>(
	{
		[SET_CANDIDATE_DETAIL]: (state, action: Action<Candidate>) => ({
			...state,
			detail: action.payload !== undefined ? action.payload : state.detail,
		}),
		[SET_CANDIDATE_LOADING]: (state, action: Action<boolean>) => ({
			...state,
			loading: action.payload ?? state.loading,
		}),
		[SET_CANDIDATE_UPDATING]: (state, action: Action<boolean>) => ({
			...state,
			updating: action.payload ?? state.updating,
		}),
		[SET_CANDIDATE_LIST]: (state, action: Action<SetCandidateListPayload>) => ({
			...state,
			cursor: action.payload?.cursor !== undefined ? action.payload.cursor : state.cursor,
			data: action.payload?.data !== undefined ? action.payload.data : state.data,
		}),
	},
	{
		detail: null,
		data: [],
		cursor: null,
		loading: false,
		updating: false,
	},
);
