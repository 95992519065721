// import React, { useState } from 'react';
import styled from 'styled-components';

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	flex: auto;
`;
export const Filters = styled.div`
	border-bottom: 1px solid rgba(0, 0, 0, 0.05);
	border-radius: 0;
	display: flex;
	border: none;
	height: 80px;
	padding: 0px;
	display: flex;
	box-shadow: 0px 5px 10px rgba(154, 158, 169, 0.2);
	position: relative;
	z-index: 6;
`;

export const FiltersLeftPart = styled.div`
	width: 395px;
	display: flex;
	flex-direction: column;
	border-right: 1px solid lightgray;
`;

export const StyledFilterButtons = styled.div`
	display: flex;
`;

export const FiltersRightPart = styled.div`
	display: flex;
	width: 100%;
`;

export const MapZone = styled.div`
	flex: auto;
	position: relative;

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		display: flex;
		overflow: hidden;
	}
`;

export const LeftPanel = styled.div`
	background-color: #f1f2f5;
	position: relative;
	overflow: auto;
	width: 350px;
	height: 100%;
	box-sizing: border-box;

	&.nopadding {
		padding: 0;
	}

	& > div {
		padding: 20px;
		box-sizing: border-box;
	}
`;

export const MapContainer = styled.div`
	position: relative;
	background-color: rgba(0, 0, 0, 0.2);
	flex: auto;
`;

export const BidInfoContainer = styled.div`
	width: 350px;
	position: absolute;
	z-index: 5;
	height: 100%;
	left: 0;
	overflow: hidden;
	transition: width 0.25s ease-in-out;
	box-shadow: 2px 10px 10px rgba(103, 116, 150, 0.2);
	&.fade-enter {
		width: 0px;
	}

	&.fade-enter-active {
		opacity: 1;
		width: 350px;
	}

	&.fade-exit {
		width: 350px;
	}

	&.fade-exit-active {
		width: 0px;
	}
`;

export const ScrollContainer = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: auto;
	padding: 20px;
	box-sizing: border-box;
`;

export const PaddingContainer = styled.div`
	padding: 20px;
	box-sizing: border-box;
`;
