import { UserProfile, Bid, Cursor } from 'app/models';
import { BidApiModel } from 'app/api/types';
import { CommonActions } from 'app/actions';
import { RootState } from 'app/reducers';
import { LocationDescriptorObject } from 'history';
import { RouteComponentProps } from 'react-router';

export namespace Orders {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	export enum Tab {
		Archive = 'archive',
		All = 'all',
		My = 'my',
	}

	// Props from redux mapState
	export interface StateProps {
		common?: RootState.Common;
		filtersOpened?: boolean;
		sessionToken?: string;
		profile?: UserProfile;
		list: ListByTab;
		cursor: CursorByTab;
		loader: LoaderByTab;
	}

	export interface ListByTab {
		[Tab.Archive]: Bid[];
		[Tab.All]: Bid[];
		[Tab.My]: Bid[];
	}

	export interface CursorByTab {
		[Tab.Archive]: Cursor;
		[Tab.All]: Cursor;
		[Tab.My]: Cursor;
	}
	export interface LoaderByTab {
		[Tab.Archive]: boolean;
		[Tab.All]: boolean;
		[Tab.My]: boolean;
	}
	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		toggleFiltersToolbar?: () => void;
		getDetailsBid?: (id: string, token: string) => void;
		getBids: (params: BidApiModel.BidsRequest, cursor?: string, loadMore?: boolean) => void;
		push?: (location: LocationDescriptorObject) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		tab: Tab;
	}

	export const tabMapper = {
		[Tab.Archive]: {
			key: 'tab-archive',
			bidType: Bid.BidType.Archive,
		},
		[Tab.All]: {
			key: 'tab-all',
			bidType: Bid.BidType.All,
		},
		[Tab.My]: {
			key: 'tab-my',
			bidType: Bid.BidType.My,
		},
	};
}
