import * as React from 'react';

//other deps
import { RouteComponentProps, withRouter } from 'react-router';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//screens
import { CarsPage } from './cars';

//types
import { RootState } from 'app/reducers';

//----------------------------------------------------------
// Profile - Transports
//----------------------------------------------------------
class Transports extends React.Component<Transports.Props, Transports.State> {
	public state: Transports.State = {};

	constructor(props: Transports.Props) {
		super(props);
	}

	public render() {
		return (
			<div className='drivers page'>
				<CarsPage />
			</div>
		);
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default withRouter(
	connect<Transports.StateProps, Transports.DispatchProps, Transports.ExternalProps>(
		mapStateToProps,
		mapDispatchToProps,
	)(Transports),
);

namespace Transports {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
