import { Validate } from 'app/containers/Builder/props';
import IMask from 'imask';

export const phoneCodes: {
	[index: string]: string;
} = {
	994: 'AZ',
	374: 'AM',
	375: 'BY',
	7: 'RU',
	380: 'UA',
};

export function getPhoneMask(phone: string) {
	const isOther = !!phoneCodes[phone.substr(1, 3)];
	const isRu = !!phoneCodes[phone.substr(1, 1)];

	let mask = '+000000000000';

	if (isRu) {
		mask = '+0 (000) 000-00-00';
	} else if (isOther) {
		mask = '+000 (00) 000-00-00';
	}

	return mask;
}

export function maskPhoneValue(phone: string) {
	const mask = getPhoneMask(phone);

	const masker = new IMask.MaskedPattern({ mask });
	masker.unmaskedValue = phone;
	return masker.value;
}

// Mask - is mask instance
export function makeMasker(mask: ((value?: string) => string) | string, defaultValue?: string) {
	return new IMask.MaskedPattern({
		mask: typeof mask !== 'string' ? mask(defaultValue) : mask,

		definitions: {
			s: /[АВЕКМНОРСТУХABEKMHOPCTYXавекмнорстухabekmhopctyx]/,
			v: /[0-9ABCDEFGHJKLMNPRSTUVWXYZ]/,
			w: /[a-zA-Z0-9_]/,
			l: /[0-9а-яА-Я]/,
		},
	});
}

export function maskGetValue(input?: string, validation?: Validate, masker?: any) {
	if (input) {
		if (validation && validation === Validate.Number) {
			return parseFloat(input);
		} else {
			if (masker) {
				masker.unmaskedValue = input;
				const value = masker.value;
				return value;
			} else {
				return input;
			}
		}
	} else {
		return '';
	}
}
