import { useState } from 'react';

export const useFilters = (defaultFilters: any) => {
	const [filters, setFilters] = useState(defaultFilters);

	const handleFilterChange = (filterName: string) => (val: any) => {
		setFilters((curFilters: any) => ({
			...curFilters,
			[filterName]: val,
		}));
	};
	const handleFiltersClear = () => {
		setFilters(defaultFilters);
	};

	function applyFilters(list: any[]) {
		const notEmptyFilters = Object.keys(filters).filter((el: any) => filters[el]);

		return list.filter((item: DocumentType) => {
			//@ts-ignore
			const isItemFalse = notEmptyFilters.some((key: any) => item[key] !== filters[key]);
			return !isItemFalse;
		});
	}

	return {
		filters,
		handleFilterChange,
		handleFiltersClear,
		applyFilters,
	};
};
