import React from 'react';

export const Info: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M12 17.5V17M12 6.5V13M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);

export default Info;
