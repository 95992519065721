import React, { forwardRef, useCallback, useState, useImperativeHandle } from 'react';

//other deps
import _ from 'lodash';
import clsx from 'clsx';
import { Localized } from '@fluent/react';
import Autocomplete from 'react-autocomplete';

//api
import { getPlaceDetails, getPlaceSuggests } from 'app/api';

//components
import styled from 'styled-components';

const AddressOption = styled.div`
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
	cursor: pointer;

	.address-title,
	.address-subtitle {
		white-space: wrap;
	}

	&:hover,
	&.selected {
		color: #64c8eb !important;
		background-color: rgba(0, 0, 0, 0.03) !important;
	}
`;

//types
import { SchemeProps, Field } from '../props';
import { Place, PlaceBase } from 'app/models';

export type AddressProps = Field.ComponentProps<Place, SchemeProps.Address>;

//----------------------------------------------------------
// Address
//----------------------------------------------------------
export const Address = forwardRef((props: AddressProps, ref) => {
	//states
	const [open, setOpen] = useState<boolean>(false);
	const [selecting, setSelecting] = useState<boolean>(false);
	const [value, setValue] = useState<string>('');
	const [place, setPlace] = useState<Place | null>(null);
	const [suggests, setSuggests] = useState<PlaceBase[]>([]);

	//callbacks
	useImperativeHandle(ref, () => ({ reset, updateValue }));

	const reset = useCallback(() => {
		setOpen(false);
		setValue('');
		setPlace(null);
		setSuggests([]);
	}, [setOpen, setValue, setPlace, setSuggests]);

	const updateValue = async (value: Place | string) => {
		if (typeof value === 'string') {
			const [placeBase] = await getPlaceSuggests(value);
			const place = await getPlaceDetails(placeBase.id);

			setPlace(place);
			setValue(value);
			props.onUpdate(props.field.name, place);
		} else {
			setPlace(value);
			setValue(value.address);
			props.onUpdate(props.field.name, value);
		}
	};

	const updateSuggests = useCallback(
		_.debounce(async (value: string) => {
			if (value.length > 2) {
				const suggests = await getPlaceSuggests(value);
				setSuggests(suggests);
			}
		}, 300),
		[],
	);

	const onSelect = async (value: string, item: PlaceBase) => {
		setSelecting(true);

		const place = await getPlaceDetails(item.id);
		setPlace(place);
		setValue(value);
		props.onUpdate(props.field.name, place);

		setSelecting(false);
	};

	const onChange = async (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
		props.onUpdate(props.field.name, undefined as any);
		setValue(value);
		updateSuggests(value);
	};

	const onBlur = async () => {
		if (!place && value.length > 2) {
			const suggests = await getPlaceSuggests(value);
			const [autoSelect] = suggests;
			const place = await getPlaceDetails(autoSelect.id);
			setPlace(place);
			setValue(autoSelect.name);
			props.onUpdate(props.field.name, place);
		}

		props.field.inputProps?.onBlur?.();
	};

	//renders
	const renderItem = (item: PlaceBase, selected: boolean) => {
		const classList = clsx({
			selected,
		});

		return (
			<AddressOption key={item.id} className={classList}>
				<div className='address-title input-text'>{item.name}</div>
				<div className='address-subtitle input-text color-tx-gray-4'>{item.address}</div>
			</AddressOption>
		);
	};

	const renderMenu = (items: React.ReactNode[], value: string) => {
		return value === '' ? <div style={{ display: 'none' }} /> : <div className='menu'>{items}</div>;
	};

	const renderInput = (inputProps: React.HTMLProps<HTMLInputElement>) => {
		if (selecting) {
			return (
				<div className={clsx('input rounded ', { opened: open })}>
					<input
						name={props.field.name}
						type='text'
						required
						{...inputProps}
						value='загрузка...'
						disabled
						style={{ color: '#999' }}
					/>
					<label>{props.field.localized ? <Localized id={props.field.label} /> : props.field.label}</label>
				</div>
			);
		} else {
			return (
				<div className={clsx('input rounded ', { opened: open })}>
					<input placeholder=' ' name={props.field.name} type='text' required {...inputProps} />
					<label>{props.field.localized ? <Localized id={props.field.label} /> : props.field.label}</label>
				</div>
			);
		}
	};

	return (
		<div className='form-item input address'>
			<Autocomplete
				onMenuVisibilityChange={(visibility) => setOpen(visibility)}
				getItemValue={(item: PlaceBase) => `${item.name}, ${item.address}`}
				renderInput={renderInput}
				renderItem={renderItem}
				renderMenu={renderMenu}
				onSelect={onSelect}
				onChange={onChange}
				inputProps={{ onBlur }}
				items={suggests}
				value={value}
				wrapperStyle={{
					position: 'relative',
					width: '100%',
				}}
			/>
		</div>
	);
});

Address.displayName = 'Address';
