import { Action, handleActions } from 'redux-actions';
import { Company } from 'app/models';
import { SET_CUSTOMER_COMPANIES } from 'app/actions/customer';
type State = {
	companies: Company[];
};

export const customerReducer = handleActions<State, any>(
	{
		[SET_CUSTOMER_COMPANIES]: (state, { payload }: Action<Company[]>) => {
			return payload
				? {
						...state,
						companies: payload,
				  }
				: state;
		},
	},
	{ companies: [] },
);
