import { takeEvery, select, put } from 'redux-saga/effects';
// import { Action } from 'redux-actions';
// import { toast } from 'react-toastify';

import * as api from '../api';
import { CommonActions } from 'app/actions/common';

function* getTariff(): Generator<any, void, any> {
	try {
		const isDispatcher = yield select((state) => state?.user?.profile?.isDispatcher);
		if (isDispatcher) {
			const response = yield api.v1.get('/tariff');
			yield put(CommonActions.extend({ tariff: response.data.data }));
		}
		// const response = yield api.v1.get('/customer/company');
		// const data: Company[] = response.data.data;
		// yield put(setCustomerCompanies(data));
	} catch (error) {
		console.log(error);
	}
}

export function* watchCommon() {
	yield takeEvery(CommonActions.Action.GET_TARIFF, getTariff);
}
