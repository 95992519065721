import { Localized } from '@fluent/react';
import { Field } from '../props';
import * as React from 'react';

export const Header: React.FC<Header.Props> = (props) => {
	return (
		<div className='header'>{props.field.localized ? <Localized id={props.field.label} /> : props.field.label}</div>
	);
};

export namespace Header {
	export interface Props {
		field: Field.Props;
	}
}
