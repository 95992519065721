import React, { useState } from 'react';
import styled from 'styled-components';

import { Typography } from '../Typography';

const Container = styled.div<{ focus: boolean }>`
	border: 1px solid ${(props) => (props.focus ? props.theme.colors.fontBlue : props.theme.colors.borderGrey)};
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	flex-direction: column;
	padding: 8px 6px;
	margin-bottom: 8px;
`;

const SInput = styled.input`
	font-size: 16px;
	border: none;
	&:focus {
		outline: none;
	}
	::placeholder {
		color: ${(props) => props.theme.colors.lightGrey};
	}
`;

export const Input = ({ name, label, placeholder, value, onChange, type }: any) => {
	const [focus, setFocus] = useState(false);

	return (
		<Container focus={focus}>
			<Typography variant='body5' color='#6C6F88'>
				{label}
			</Typography>
			<SInput
				type={type}
				onFocus={() => setFocus(true)}
				onBlur={() => setFocus(false)}
				placeholder={placeholder}
				name={name}
				onChange={onChange}
				value={value}
			/>
		</Container>
	);
};
