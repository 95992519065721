import { prepareParams, v1, v2 } from 'app/api';

import { SuggestsApiModel } from './types/suggests';
import { Place, PlaceBase, Company, Bank } from 'app/models';

export async function suggestsCompany(query: string): Promise<Company[]> {
	const params = { query };
	const response = await v2.get<SuggestsApiModel.CompanyResponse>('/suggestions/company', { params });
	return response.data.suggestions;
}

export async function suggestsBank(query: string): Promise<Bank[]> {
	const params = { query };
	const response = await v2.get<SuggestsApiModel.BankResponse>('/suggestions/bank', { params });
	return response.data.suggestions;
}

export async function getPlaceSuggests(address: string): Promise<PlaceBase[]> {
	const params = { address };
	const response = await v1.get<{ data: PlaceBase[] }>('/location/autocomplete', { params });
	return response.data.data;
}

export async function getPlaceDetails(id: string): Promise<Place> {
	const params = { id };
	const response = await v1.get<{ data: Place }>('/location/place', { params });
	return response.data.data;
}

export async function suggestsCompanyByPattern(pattern?: string): Promise<Company[]> {
	const handledInput = pattern ? `?q=${pattern}` : '';
	const response = await v1.get(`/customer/company${handledInput}`);
	return response.data.data;
}

export async function getLocationFares(
	params: SuggestsApiModel.LocationFaresParams,
): Promise<SuggestsApiModel.LocationFaresResponse | null> {
	const preparedParams = prepareParams(params);

	const response = await v1.get<{ data: SuggestsApiModel.LocationFaresResponse | null }>('/location/fares', {
		params: preparedParams,
	});

	return response.data.data;
}
