import { Action, handleActions } from 'redux-actions';
import {
	SET_CUSTOMER_OFFERS,
	SET_CUSTOMER_OFFERS_LOADING,
	ADD_CUSTOMER_OFFERS,
	UPDATE_CUSTOMER_OFFER,
	REMOVE_CUSTOMER_OFFER,
	TransportOffer,
	TransportOfferPayload,
} from 'app/actions';

type State = {
	data: TransportOffer[];
	next: string | null;
	previous: string | null;
	loading: boolean;
};

export const offersReducer = handleActions<State, any>(
	{
		[SET_CUSTOMER_OFFERS]: (state: State, { payload }: Action<TransportOfferPayload>) =>
			payload
				? {
						data: payload.data,
						next: payload.next,
						previous: payload.previous,
						loading: false,
				  }
				: state,
		[ADD_CUSTOMER_OFFERS]: (state: State, { payload }: Action<TransportOfferPayload>) =>
			payload
				? {
						data: [...state.data, ...payload.data],
						next: payload.next,
						previous: payload.previous,
						loading: false,
				  }
				: state,
		[SET_CUSTOMER_OFFERS_LOADING]: (state: State, { payload }: Action<boolean>) =>
			payload
				? {
						...state,
						loading: payload,
				  }
				: state,
		[UPDATE_CUSTOMER_OFFER]: (state: State, { payload }: Action<any>) =>
			payload
				? {
						...state,
						data: state.data.map((item: any) =>
							item.transport.uuid === payload.transport.uuid ? { ...item, ...payload } : item,
						),
				  }
				: state,
		[REMOVE_CUSTOMER_OFFER]: (state: State, { payload }: Action<any>) =>
			payload ? { ...state, data: state.data.filter((item: any) => item.transport.uuid !== payload) } : state,
	},
	{
		data: [],
		next: null,
		previous: null,
		loading: false,
	},
);
