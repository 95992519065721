import React from 'react';
import { ThemeProvider } from 'styled-components';

const theme = {
	colors: {
		lightBlue: '#5FC8ED',
		fontBlue: '#64c8eb',
		bgGrey: '#F8F8F8',
		borderGrey: '#D6D8E5',
		borderDarkGrey: '#59595a',
		lightGrey: '#E6E6E6',
	},
	padding: {
		regular: '20px',
	},
};

export const Theme = ({ children }: { children: any }) => <ThemeProvider theme={theme}>{children}</ThemeProvider>;
