import { isFiltersActive } from './filters';

export const transport = (topic: string, selectedIds?: any) => (state: any) => {
	const transportToFilter = (state?.transport && Object.values(state?.transport)) || [];
	const activeFilters = isFiltersActive(state);
	const filters = state?.filters || {};

	const transport = transportToFilter.filter(
		(item: any) => item.topics.includes(topic) && (activeFilters ? filters[item.offerStatus] : true),
	);

	const filteredById = transport.map((t: any) => ({
		...t,
		showOnMap: selectedIds ? selectedIds.includes(t?.transportId) : true,
	}));

	return filteredById;
};

export const rawTransport = (state: any) => state?.transport;

export const transportTypeByKind = (id: any) => (state: any) => {
	if (state?.common?.transports) {
		const transport: any = Object.values(state?.common?.transports).find((item: any) => item.id === id);
		return transport?.name || '';
	}
	return '';
};

export const vacantTransport = (state: any) => state?.vacantExecutors;

export const transportOnMap = (state: any) => state?.transportOnMap.data;
export const transportOnMapBounds = (state: any) => state?.transportOnMap;
