import React, { forwardRef, useCallback, useState, useImperativeHandle } from 'react';
import Autocomplete from 'react-autocomplete';
import { Localized } from '@fluent/react';
import clsx from 'clsx';
import _ from 'lodash';
import { Bank } from 'app/models';
import { getLocalizedMessage } from 'app/locales';
import { suggestsBank } from 'app/api';
import { SchemeProps, Field } from '../props';

export type BankSuggestProps = Field.ComponentProps<string, SchemeProps.Bank>;

export const BankSuggest = forwardRef((props: BankSuggestProps, ref) => {
	const [open, setOpen] = useState<boolean>(false);
	const [value, setValue] = useState<string>('');
	const [suggests, setSuggests] = useState<Bank[]>([]);
	const [validationMessage, setValidationMessage] = useState<string>('');

	useImperativeHandle(ref, () => ({ reset, updateValue }));

	const validate = (value: string) => {
		let validationMessage = '';
		setValidationMessage('');

		if (props.field.rules) {
			props.field.rules.forEach((check: Function) => {
				const report = check(value, props.form);
				if (report !== true) {
					validationMessage = getLocalizedMessage(report) || 'Invalid report';
				}
			});
		}
		if (props.field.required && !value) {
			validationMessage = getLocalizedMessage('builder-field-required') || 'Invalid report';
		}
		if (validationMessage) {
			setValidationMessage(validationMessage);
			return validationMessage;
		}

		return true;
	};

	const reset = useCallback(() => {
		setOpen(false);
		setValue('');
		setSuggests([]);
	}, [setOpen, setValue, setSuggests]);

	const updateValue = async (value: Bank | string) => {
		const bic = typeof value === 'string' ? value : value.bic;
		setValue(bic);
		props.onUpdate(props.field.name, bic);

		if (bic.length > 2) {
			const suggests = await suggestsBank(bic);
			setSuggests(suggests);
		}
	};

	const updateSuggests = useCallback(
		_.debounce(async (value: string) => {
			if (value.length > 2) {
				const suggests = await suggestsBank(value);
				setSuggests(suggests);
			}
		}, 300),
		[],
	);

	const onSelect = async (value: string) => {
		setValue(value);
		props.onUpdate(props.field.name, value);
		updateSuggests(value);
		validate(value);
	};

	const onChange = async (e: React.ChangeEvent<HTMLInputElement>, value: string) => {
		props.onUpdate(props.field.name, undefined as any);
		setValue(value);
		updateSuggests(value);
		validate(value);
	};

	const onBlur = async () => {
		props.field.inputProps?.onBlur?.();
	};

	const renderItem = (item: Bank, selected: boolean) => (
		<div key={item.bic} className={clsx('item', { selected })}>
			<div className='name'>{item.name}</div>
			<div className='reg-info'>
				<div className='coracc'>
					<Localized id='builder-bank-coracc' />: {item.coracc}
				</div>
				<div className='bic'>
					<Localized id='builder-bank-bic' />: {item.bic}
				</div>
			</div>
		</div>
	);

	const renderMenu = (items: React.ReactNode[], value: string) =>
		value === '' ? <div style={{ display: 'none' }} /> : <div className='menu'>{items}</div>;

	const renderInput = (inputProps: React.HTMLProps<HTMLInputElement>) => {
		const mask =
			typeof props.field.mask === 'string'
				? props.field.mask.replace(/s/g, 'A').replace(/[wl]/g, 'X').replace(/`/g, '')
				: false;
		return (
			<div className={clsx('input rounded ', { opened: open })}>
				<input placeholder=' ' name={props.field.name} type='text' required {...inputProps} />
				{mask && (
					<span className='input__mask'>
						<span className='input__mask-hidden'>{value}</span>
						<div className='input__mask-show'>{mask.substr(value.length, mask.length)}</div>
					</span>
				)}
				<label>{props.field.localized ? <Localized id={props.field.label} /> : props.field.label}</label>
				<div className='input__message'>{validationMessage}</div>
			</div>
		);
	};

	return !props.field.hidden ? (
		<div className={clsx('form-item input bank', { 'input--invalid': !!validationMessage })}>
			<Autocomplete
				onMenuVisibilityChange={(visibility) => setOpen(visibility)}
				getItemValue={(item: Bank) => item.bic}
				renderInput={renderInput}
				renderItem={renderItem}
				renderMenu={renderMenu}
				onSelect={onSelect}
				onChange={onChange}
				inputProps={{ onBlur }}
				items={suggests}
				value={value}
				wrapperStyle={{
					position: 'relative',
					width: '100%',
				}}
			/>
		</div>
	) : null;
});

BankSuggest.displayName = 'Address';
