const validateObject = function (object: any, rules: any): string[] {
	const reports: string[] = [];
	for (const field in rules) {
		const check = rules[field];
		let report;
		if (object[field] !== undefined) {
			report = check(object[field]);
		} else {
			report = check('');
		}
		if (report) {
			if (typeof report === 'string') reports.push(report);
		}
	}

	return reports;
};

export default validateObject;
