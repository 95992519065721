// import { getEnvUrl } from 'app/config';

export const getApiHost = () => {
	if (process.env.NODE_ENV === 'development') {
		return 'wss://dev.vezzer.no/geo';
	}
	if (window.location.origin === 'https://app.dev.vezzer.no') {
		return 'wss://dev.vezzer.no/geo';
	} else {
		return 'wss://vezzer.no/geo';
	}
};
