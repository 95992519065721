import { v2, v1 } from 'app/api';
import { PriceProduct, Buyer } from 'app/models';
import { PricesApiModel } from './types';

export interface _Product {
	protein: number;
	class: string;
	name: string;
	uuid: string;
	id: string;
}

export async function pricesGetProducts(): Promise<Record<string, PriceProduct>> {
	const response = await v2.get<{ data: _Product[] }>('/prices/products');
	const products: Record<string, PriceProduct> = {};
	response.data.data.forEach(({ protein, class: classType, name, uuid }) => {
		if (!products[name]) {
			products[name] = { classes: {}, name };
		}
		if (!classType && !protein) {
			products[name].id = uuid;
			return;
		}
		if (classType) {
			if (!products[name].classes[classType]) {
				(products[name].classes as any)[classType] = {};
			}

			if (protein) {
				(products[name].classes[classType] as any)[protein] = {
					protein,
					id: uuid,
				};
			} else {
				products[name].classes[classType].id = uuid;
			}
		}
	});
	return products;
}

export async function pricesGetPrices(params: PricesApiModel.PricesRequest): Promise<Buyer[]> {
	const response = await v2.get<{ data: Buyer[] }>('/prices/table/simple', { params: { ...params, version: 2 } });
	return response.data.data;
}

export async function pricesGetSummaryTable(params: PricesApiModel.SummaryTableRequest): Promise<Buyer[]> {
	const response = await v2.post<{ data: Buyer[] }>('/prices/table/pivot', params);
	return response.data.data;
}

export async function pricesGetGrainCrops(): Promise<any> {
	const response = await v2.get('/prices/graincrops');
	return response.data.data ?? [];
}

export async function pricesGetByCropId({
	crop,
	address,
	basis,
	buyer,
	currency,
}: {
	crop?: any;
	address?: string;
	basis?: string;
	buyer?: string;
	currency?: string;
}): Promise<any> {
	const params = { crop, address, basis, buyer, version: 2, currency };

	const response = await v2.get('/prices/table/graincrop_products', { params });

	return response.data.data ?? [];
}

export async function pricesPrepareShippingFare(): Promise<any> {
	const shippingFareExpiresIn = parseInt(localStorage.getItem('v-shipping-fare-expires-in') || '0');
	const isCacheExpired = !shippingFareExpiresIn || Date.now() > shippingFareExpiresIn;
	const expireIn = Date.now() + 604800000; // Week
	if (isCacheExpired) {
		const response = await v2.get('/prices/shippingfare');
		localStorage.setItem('v-shipping-fare', JSON.stringify(response.data.data));
		localStorage.setItem('v-shipping-fare-expires-in', expireIn.toString());
	}
	const shippingFare = JSON.parse(localStorage.getItem('v-shipping-fare') || '');
	return shippingFare;
}

export async function getTariff(): Promise<any> {
	const response = await v1.get('/tariff');
	return response.data ?? [];
}
