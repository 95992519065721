import React from 'react';
import styled from 'styled-components';

import { RadioButtonContainerStyle } from '../sharedStyles/RadioButtonContainerStyle';

const ButtonStyle: string = `
    border-radius: 4px;
    border: 1px solid #d6d8e5;
    padding: 0 10px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    font-family: 'UniNeue';
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    height: 40px;
    box-sizing: border-box;
    user-select: none;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.25s;

    color: #6c6f88;
`;

const WideButtonStyle: string = `
    ${ButtonStyle}
    img {
        object-fit: cover;
        margin-right: 10px;
    }
`;

const SquareButtonStyle: string = `
    ${ButtonStyle}
    img {
        object-fit: cover;
        margin-right: 10px;
    }
`;
const WideDivButton = styled.div`
	${WideButtonStyle}
`;

const SquareDivButton = styled.div`
	${SquareButtonStyle}
`;

const MultiButtonStyleBase: string = `
    input {
        display: none;
    }
    &:not(:last-child) {
        div {
            border-right: none;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not(:first-child) {
            div {
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
            }
        }
    }
    &:last-child {
        div {
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
    }
`;

const WideMultiButttonStyle: string = `
    ${MultiButtonStyleBase}
    input:checked + ${WideDivButton} {
        background: #2e3043;
        border-color: #2e3043;
        color: #ffffff;
    }
`;
const SquareMultiButttonStyle: string = `
    ${MultiButtonStyleBase}
    input:checked + ${SquareDivButton} {
        background: #2e3043;
        border-color: #2e3043;
        color: #ffffff;
    }
`;

const WideMultiButton = styled.label<any>`
	${WideMultiButttonStyle}
`;

const SquareMultiButton = styled.label<any>`
	${SquareMultiButttonStyle}
`;

export const MultiButton: React.FC<any | HTMLInputElement> = (props) => {
	const inputProps = {
		children: undefined,
		value: undefined,
	};
	const { type, handleClick, value, checked = false } = props;
	const isSquare = type === 'square';
	const click = (event: any) => {
		handleClick(value, event.target.checked);
	};
	const Input = () => <input type='checkbox' {...inputProps} onChange={click} checked={checked} />;
	return (
		(isSquare && (
			<SquareMultiButton>
				<Input />
				<SquareDivButton>{props.children}</SquareDivButton>
			</SquareMultiButton>
		)) || (
			<WideMultiButton>
				<Input />
				<WideDivButton>{props.children}</WideDivButton>
			</WideMultiButton>
		)
	);
};

export interface MultiSelectButtonGroupProps {
	onChange?: (value: any, isChecked: boolean) => any;
	valuesMap: any;
	children: any[];
	title?: string;
}

const MultiSelectButtonGroup: React.FC<MultiSelectButtonGroupProps> = ({
	children = [],
	title,
	valuesMap = {},
	onChange,
}) => {
	const toRender = React.Children.map(children, (child: any) => {
		const value = child.props.value;
		return React.cloneElement(child, {
			checked: valuesMap[value],
			handleClick: onChange,
		});
	});
	return <RadioButtonContainerStyle data-title={title || 'Untitled group'}>{toRender}</RadioButtonContainerStyle>;
};

export default MultiSelectButtonGroup;
