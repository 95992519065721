import React, { useMemo } from 'react';
import styled from 'styled-components';
import { Bid } from 'app/models';
import { OfferGridCompanyRow, OfferGridRow } from './styles';
import { Typography } from 'app/sharedComponents/Typography';
// import { Icon } from 'app/components/Icons';
const MaskingEl = styled.div<{ color: string }>`
	height: 10px;
	width: 10px;
	background-color: ${(p) => p.color};
`;

const DeleteCotainer = styled.div`
	cursor: pointer;
	background-color: #f42f37;

	border-radius: 80px;
	width: 20px;
	height: 20px;
	align-content: center;
	align-items: center;
	display: flex;
	justify-content: center;
	font-size: 11px;
	color: white;
	&:hover {
		font-size: 12px;
	}
`;

const colors = ['#DDDDDD', '#E4E4E4', '#E6E6E6', '#D8D8D8', '#D9D9D9', '#EBEBEB', '#EDEDED', '#DCDCDC'];

const MaskingRow = () => {
	const arr = useMemo(() => Array.from({ length: 20 }, () => colors[Math.floor(Math.random() * (8 - 0 + 1) + 0)]), []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div className='masking-row' style={{ display: 'flex' }}>
				{arr.map((color: string, index: number) => (
					<MaskingEl key={index} color={color} />
				))}
			</div>
		</div>
	);
};

const MaskingRows = () => (
	<div style={{ display: 'flex', flexDirection: 'column', marginBottom: '5px' }}>
		<MaskingRow />
		<MaskingRow />
	</div>
);

export const OfferRow = ({
	offer,
	index,
	priceType,
	onDelete,
}: {
	offer: Bid.TenderOffer;
	index: number;
	priceType: string;
	onDelete: any;
}) => {
	const maskedOfferName = offer?.company?.name.includes('*');

	return (
		<div style={{ marginBottom: '10px' }}>
			<OfferGridCompanyRow>
				<Typography variant='body5' bold>
					{index}
				</Typography>
				{maskedOfferName ? (
					<MaskingRows />
				) : (
					<Typography bold variant='body5'>
						{offer.company.name}
					</Typography>
				)}
			</OfferGridCompanyRow>
			<OfferGridRow>
				<Typography variant='body5'>{``}</Typography>
				<Typography variant='body5'>{offer.offeredWeight}</Typography>
				<Typography variant='body5' color={offer.preApproved ? '#55BC32' : '#FF2525'}>
					{offer.preAcceptedWeight}
				</Typography>
				<Typography
					variant='body5'
					color={offer.preApproved ? '#55BC32' : '#FF2525'}
				>{`${offer.offeredPrice} ${priceType}`}</Typography>
				{onDelete && (
					<DeleteCotainer onClick={() => onDelete(offer.id)}>
						{/* <Icon variant="delete" color="red" size={12} /> */}x
					</DeleteCotainer>
				)}
			</OfferGridRow>
		</div>
	);
};
