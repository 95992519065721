import React from 'react';
import styled from 'styled-components';
import { Typography } from 'app/sharedComponents/Typography';
import './sidebar.css';
import { useSidebar } from './SidebarContext';
const closeIcon = require('../../../assets/icons/close.svg');

const SidebarContainer = styled.div`
	height: 100%;
	overflow: hidden;
`;
const Title = styled.div`
	padding-left: 20px;
	padding-top: 20px;
	width: 271px;
	height: 51px;
	display: flex;
	justify-content: space-between;
`;

const Content = styled.div`
	height: 90%;
	overflow: hidden;
`;

export const Sidebar = () => {
	const { open, setOpen, content: ContentComponent, title } = useSidebar();
	return (
		<div className={`sidebar right ${open ? 'opened' : 'closed'}`}>
			<SidebarContainer>
				<Title>
					{typeof title === 'string' ? (
						<Typography bold variant='h5'>
							{title}
						</Typography>
					) : (
						title
					)}
					<div style={{ cursor: 'pointer' }} onClick={() => setOpen(false)}>
						<img src={closeIcon} alt='' />
					</div>
				</Title>
				{open && ContentComponent && (
					<Content>
						<ContentComponent />
					</Content>
				)}
			</SidebarContainer>
		</div>
	);
};
