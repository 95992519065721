import * as React from 'react';
export * from './Icon';
export const Request: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M27 23L27 11C27 9.89543 26.1046 9 25 9L7 9C5.89543 9 5 9.89543 5 11L5 23C5 24.1046 5.89543 25 7 25L25 25C26.1046 25 27 24.1046 27 23Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path d='M9 13L11 13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M9 17L23 17' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M9 21L18.5 21' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M15 13L17 13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M21 13L23 13' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const Car: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M9 13.5H23V24H9V13.5Z' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M12 21H13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M19 21L20 21' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path
			d='M23 24V9C23 8.44772 22.5523 8 22 8H10C9.44772 8 9 8.44772 9 9V24'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path d='M9 26V28V27V25' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M23 26V28V27V25' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const Wayback: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<line x1='8' y1='16' x2='9' y2='16' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<line x1='13' y1='16' x2='14' y2='16' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<line x1='18' y1='16' x2='19' y2='16' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M21 12L25 16L21 20' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const CargoLoading: React.FC<{ color: string }> = ({ color }) => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path d='M10 1.81812L10 13.2727' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M13.2144 11.2856L10.0001 14.4999L6.78584 11.2856' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const CargoUnloading: React.FC<{ color: string }> = ({ color }) => (
	<svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10 19C14.9706 19 19 14.9706 19 10C19 5.02944 14.9706 1 10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M6.63645 6H5.00009V8V11V13V17.4545H6.63645V13H7.00009H12.0001H14.0001V11V8V6H12.0001H7.00009H6.63645ZM7.00009 8H12.0001V11H7.00009V8Z'
			fill={color}
		/>
	</svg>
);

export const RoadToTarget: React.FC<{ color: string }> = ({ color }) => (
	<svg width='22' height='12' viewBox='0 0 22 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<line x1='5.99997' y1='6' x2='18' y2='6' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M16 2L20 6L16 10' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<circle cx='2.99997' cy='6' r='2' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const ExecutorCar: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M9 13.5H23V24H9V13.5Z' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M12 21H13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M19 21L20 21' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path
			d='M23 24V9C23 8.44772 22.5523 8 22 8H10C9.44772 8 9 8.44772 9 9V24'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path d='M9 26V28V27V25' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M23 26V28V27V25' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const ExecutorMessage: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M9.6 22L10.595 21.9005L10.505 21H9.6V22ZM9.89501 24.9501L10.89 24.8506H10.89L9.89501 24.9501ZM10.7461 25.2539L10.039 24.5468L10.039 24.5468L10.7461 25.2539ZM14 22V21H13.5858L13.2929 21.2929L14 22ZM8 10C8 9.44772 8.44772 9 9 9V7C7.34315 7 6 8.34315 6 10H8ZM8 20V10H6V20H8ZM9 21C8.44772 21 8 20.5523 8 20H6C6 21.6569 7.34315 23 9 23V21ZM9.6 21H9V23H9.6V21ZM8.60496 22.0995L8.89997 25.0496L10.89 24.8506L10.595 21.9005L8.60496 22.0995ZM8.89997 25.0496C9.02589 26.3088 10.5584 26.8558 11.4532 25.961L10.039 24.5468C10.3372 24.2485 10.8481 24.4309 10.89 24.8506L8.89997 25.0496ZM11.4532 25.961L14.7071 22.7071L13.2929 21.2929L10.039 24.5468L11.4532 25.961ZM23 21H14V23H23V21ZM24 20C24 20.5523 23.5523 21 23 21V23C24.6569 23 26 21.6569 26 20H24ZM24 10V20H26V10H24ZM23 9C23.5523 9 24 9.44772 24 10H26C26 8.34315 24.6569 7 23 7V9ZM9 9H23V7H9V9Z'
			fill={color}
		/>
		<path
			d='M12 15.5791C12.2761 15.5791 12.5 15.3552 12.5 15.0791C12.5 14.803 12.2761 14.5791 12 14.5791C11.7239 14.5791 11.5 14.803 11.5 15.0791C11.5 15.3552 11.7239 15.5791 12 15.5791Z'
			fill={color}
			stroke={color}
			strokeLinecap='square'
		/>
		<path
			d='M16 15.5791C16.2761 15.5791 16.5 15.3552 16.5 15.0791C16.5 14.803 16.2761 14.5791 16 14.5791C15.7239 14.5791 15.5 14.803 15.5 15.0791C15.5 15.3552 15.7239 15.5791 16 15.5791Z'
			fill={color}
			stroke={color}
			strokeLinecap='square'
		/>
		<path
			d='M20 15.5791C20.2761 15.5791 20.5 15.3552 20.5 15.0791C20.5 14.803 20.2761 14.5791 20 14.5791C19.7239 14.5791 19.5 14.803 19.5 15.0791C19.5 15.3552 19.7239 15.5791 20 15.5791Z'
			fill={color}
			stroke={color}
			strokeLinecap='square'
		/>
	</svg>
);

export const ExecutorRoad: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='2' y='2' width='28' height='28' rx='4' fill={color} />
		<circle cx='9' cy='22' r='2' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<circle cx='23' cy='10' r='2' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path
			d='M21 10H11C9.34315 10 8 11.3431 8 13V13C8 14.6569 9.34315 16 11 16H21C22.6569 16 24 17.3431 24 19V19C24 20.6569 22.6569 22 21 22H11'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);

export const ExecutorTrack: React.FC<{ color: string }> = ({ color }) => (
	<svg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='2' cy='14' r='1' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<circle cx='16' cy='2' r='1' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path
			d='M14 2H4C2.34315 2 1 3.34315 1 5V5C1 6.65685 2.34315 8 4 8H14C15.6569 8 17 9.34315 17 11V11C17 12.6569 15.6569 14 14 14H4'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
			strokeLinejoin='round'
			strokeDasharray='1 4'
		/>
	</svg>
);

export const ArrowDown: React.FC<{ color: string }> = ({ color }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M19.0711 9L12 16.0711L4.92894 9' stroke={color} strokeWidth='2' strokeLinecap='round' />
	</svg>
);

export const Upload: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M21.3792 24.5H25.4137C26.8992 24.5 28.1034 23.2958 28.1034 21.8103V11.0517C28.1034 9.56627 26.8992 8.36207 25.4137 8.36207H21.3792H13.9827L11.0145 5.39389C10.7623 5.14169 10.4202 5 10.0636 5H5.24131C4.49858 5 3.89648 5.6021 3.89648 6.34483V21.8103C3.89648 23.2958 5.10068 24.5 6.58614 24.5H10.6206'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path d='M16.0002 28.6816L16.0002 17.2271' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M12.7859 19.2148L16.0002 16.0006L19.2145 19.2148' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const CircleArrowDown: React.FC<{ color: string }> = ({ color }) => (
	<svg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.25 4.68913L4.25 0.0556641C1.84437 0.417527 0 2.49327 0 4.99979C0 7.76121 2.23858 9.99979 5 9.99979C7.76142 9.99979 10 7.76121 10 4.99979C10 2.49327 8.15563 0.417527 5.75 0.0556641L5.75 4.68913L6.96967 3.46946L8.03033 4.53012L5.53033 7.03012L5 7.56045L4.46967 7.03012L1.96967 4.53012L3.03033 3.46946L4.25 4.68913Z'
			fill={color}
		/>
	</svg>
);

export const Flag: React.FC<{ color: string }> = ({ color }) => (
	<svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M2 0.999756H10V6.99976H2V0.999756Z' fill={color} />
		<line x1='2.8' y1='0.999756' x2='2.8' y2='10.9998' stroke={color} strokeWidth='1.6' />
	</svg>
);

export const Check: React.FC<{ color: string; size?: number }> = ({ color, size }) => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		height={size ? size : 32}
		width={size ? size : 32}
		viewBox={`0 0 32 32`}
		fill='none'
	>
		<path d='M8 16.8667L13.0909 22L24 11' stroke={color} strokeWidth='4' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export const Route: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='9' cy='22' r='2' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<circle cx='23' cy='10' r='2' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path
			d='M21 10H11C9.34315 10 8 11.3431 8 13V13C8 14.6569 9.34315 16 11 16H21C22.6569 16 24 17.3431 24 19V19C24 20.6569 22.6569 22 21 22H11'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);

export const Close: React.FC<{ color: string; onClick?: Function }> = ({ color, onClick = () => {} }) => {
	const handleClick = (e: any) => {
		e.stopPropagation();
		e.preventDefault();
		onClick(e);
	};
	return (
		<svg
			onClick={handleClick}
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.93934 8L0.71967 1.78033C0.426777 1.48744 0.426777 1.01256 0.71967 0.71967C1.01256 0.426777 1.48744 0.426777 1.78033 0.71967L8 6.93934L14.2197 0.71967C14.5126 0.426777 14.9874 0.426777 15.2803 0.71967C15.5732 1.01256 15.5732 1.48744 15.2803 1.78033L9.06066 8L15.0386 13.9779C15.3315 14.2708 15.3315 14.7457 15.0386 15.0386C14.7457 15.3315 14.2708 15.3315 13.9779 15.0386L8 9.06066L2.02208 15.0386C1.72919 15.3315 1.25431 15.3315 0.961417 15.0386C0.668524 14.7457 0.668524 14.2708 0.961417 13.9779L6.93934 8Z'
				fill={color}
			/>
		</svg>
	);
};

export const Sort: React.FC<{ color?: string; onClick?: Function; className?: string }> = ({
	color = '#64C8EB',
	onClick = () => {},
	className,
}) => {
	const handleClick = () => onClick();
	return (
		<svg
			className={className}
			onClick={handleClick}
			width='12'
			height='12'
			viewBox='0 0 16 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'
		>
			<path d='M10 6L5.99925 10.0008L1.99849 6' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
		</svg>
	);
};

export const DottedArrowUp: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<line x1='16' y1='24' x2='16' y2='23' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<line x1='16' y1='19' x2='16' y2='18' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<line x1='16' y1='14' x2='16' y2='13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M12 11L16 7L20 11' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const DottedArrowLeft: React.FC<{ color: string }> = ({ color }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<line x1='24' y1='16' x2='23' y2='16' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<line x1='19' y1='16' x2='18' y2='16' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<line x1='14' y1='16' x2='13' y2='16' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M11 20L7 16L11 12' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const VerifyUser: React.FC<{}> = () => (
	<svg width='93' height='86' viewBox='0 0 93 86' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M37.8261 0C16.9353 0 0 16.9353 0 37.8261C0 58.7169 16.9353 75.6522 37.8261 75.6522C58.7169 75.6522 75.6522 58.7169 75.6522 37.8261C75.6522 16.9353 58.7169 0 37.8261 0Z'
			fill='#2E3043'
		/>
		<path
			d='M54.8479 58.6298V54.8471C54.8479 49.6245 50.6141 45.3906 45.3914 45.3906H30.261C25.0383 45.3906 20.8044 49.6245 20.8044 54.8471V58.6298'
			stroke='white'
			strokeWidth='3.78261'
			strokeLinecap='square'
		/>
		<circle cx='37.8263' cy='28.6388' r='9.72671' stroke='white' strokeWidth='3.78261' strokeLinecap='square' />
		<circle cx='66.196' cy='58.6325' r='20.8043' fill='#64C8EB' />
		<circle cx='66.196' cy='58.6325' r='23.6413' stroke='#64C8EB' strokeOpacity='0.36' strokeWidth='5.67391' />
		<path
			d='M57.8743 59.5323L63.1699 64.8721L74.5177 53.4297'
			stroke='white'
			strokeWidth='4.16087'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export const BigCar: React.FC<{}> = () => (
	<svg width='181' height='118' viewBox='0 0 181 118' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path opacity='0.2' d='M150.391 19.541V7.49942' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M166.391 35.541H178.432' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M161.652 24.5447L165.582 20.6149' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M134.887 20.3017L138.817 24.2315' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M161.887 47.3017L165.817 51.2315' stroke='black' strokeWidth='2' strokeLinecap='square' />

		<g opacity='0.2'>
			<path d='M42.6221 32.7109L38.4722 36.8608' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
			<path d='M38.387 32.7109L42.5368 36.8608' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
		</g>

		<g opacity='0.2'>
			<path d='M143.854 91.375L139.704 95.5248' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
			<path d='M139.619 91.375L143.769 95.5248' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
		</g>

		<path opacity='0.2' d='M25.3801 58.3281H42.2507' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M21.7446 64.5703H42.2507' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M40.1833 71.0527H42.2507' stroke='black' strokeWidth='2' strokeLinecap='square' />

		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M40.4988 94.2578C43.8125 94.2578 46.4988 91.5715 46.4988 88.2578C46.4988 84.9441 43.8125 82.2578 40.4988 82.2578C37.1851 82.2578 34.4988 84.9441 34.4988 88.2578C34.4988 91.5715 37.1851 94.2578 40.4988 94.2578Z'
			fill='white'
			stroke='#2E3044'
			strokeWidth='4.8'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M140.624 66.1992C143.938 66.1992 146.624 63.5129 146.624 60.1992C146.624 56.8855 143.938 54.1992 140.624 54.1992C137.31 54.1992 134.624 56.8855 134.624 60.1992C134.624 63.5129 137.31 66.1992 140.624 66.1992Z'
			fill='white'
			stroke='#2E3044'
			strokeWidth='4.8'
		/>
		<path
			opacity='0.2'
			fillRule='evenodd'
			clipRule='evenodd'
			d='M141.059 48.0384V32.0586H155.881V39.6989H143.515V48.0384H141.059Z'
			fill='black'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M84.624 71.1992C87.9377 71.1992 90.624 68.5129 90.624 65.1992C90.624 61.8855 87.9377 59.1992 84.624 59.1992C81.3103 59.1992 78.624 61.8855 78.624 65.1992C78.624 68.5129 81.3103 71.1992 84.624 71.1992Z'
			fill='white'
			stroke='#2E3044'
			strokeWidth='4.8'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M108.624 88.1992C111.938 88.1992 114.624 85.5129 114.624 82.1992C114.624 78.8855 111.938 76.1992 108.624 76.1992C105.31 76.1992 102.624 78.8855 102.624 82.1992C102.624 85.5129 105.31 88.1992 108.624 88.1992Z'
			fill='white'
			stroke='#2E3044'
			strokeWidth='4.8'
		/>
		<path
			d='M94.124 71.6992L99.124 75.6992'
			stroke='black'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeDasharray='1 4'
		/>
		<path
			d='M52.0001 84.9993L130 62.9994'
			stroke='black'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
			strokeDasharray='1 4'
		/>

		<mask id='path-20-inside-1' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M64.7883 97.0293H75.5117V107.205C75.5117 108.531 74.4371 109.605 73.1117 109.605H67.1883C65.8628 109.605 64.7883 108.531 64.7883 107.205V97.0293Z'
			/>
		</mask>

		<path
			d='M64.7883 97.0293V82.6293H50.3883V97.0293H64.7883ZM75.5117 97.0293H89.9117V82.6293H75.5117V97.0293ZM64.7883 111.429H75.5117V82.6293H64.7883V111.429ZM61.1117 97.0293V107.205H89.9117V97.0293H61.1117ZM73.1117 95.2052H67.1883V124.005H73.1117V95.2052ZM79.1883 107.205V97.0293H50.3883V107.205H79.1883ZM67.1883 95.2052C73.8158 95.2052 79.1883 100.578 79.1883 107.205H50.3883C50.3883 116.484 57.9099 124.005 67.1883 124.005V95.2052ZM61.1117 107.205C61.1117 100.578 66.4842 95.2052 73.1117 95.2052V124.005C82.39 124.005 89.9117 116.484 89.9117 107.205H61.1117Z'
			fill='#2E3044'
			mask='url(#path-20-inside-1)'
		/>

		<mask id='path-22-inside-2' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M106.439 97.0293H117.163V107.205C117.163 108.531 116.088 109.605 114.763 109.605H108.839C107.514 109.605 106.439 108.531 106.439 107.205V97.0293Z'
			/>
		</mask>

		<path
			d='M106.439 97.0293V82.6293H92.0392V97.0293H106.439ZM117.163 97.0293H131.563V82.6293H117.163V97.0293ZM106.439 111.429H117.163V82.6293H106.439V111.429ZM102.763 97.0293V107.205H131.563V97.0293H102.763ZM114.763 95.2052H108.839V124.005H114.763V95.2052ZM120.839 107.205V97.0293H92.0392V107.205H120.839ZM108.839 95.2052C115.467 95.2052 120.839 100.578 120.839 107.205H92.0392C92.0392 116.484 99.5608 124.005 108.839 124.005V95.2052ZM102.763 107.205C102.763 100.578 108.135 95.2052 114.763 95.2052V124.005C124.041 124.005 131.563 116.484 131.563 107.205H102.763Z'
			fill='#2E3044'
			mask='url(#path-22-inside-2)'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M64.72 39.5855C64.72 38.2601 65.7945 37.1855 67.12 37.1855H114.72C116.045 37.1855 117.12 38.2601 117.12 39.5855V99.0474H64.72V39.5855Z'
			fill='#2E3044'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M70.0867 59.867C70.4217 56.1565 73.532 53.3145 77.2575 53.3145H104.582C108.308 53.3145 111.418 56.1565 111.753 59.8669L113.691 81.335H68.1482L70.0867 59.867Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M64.6511 87.7664C64.6511 83.79 67.8747 80.5664 71.8511 80.5664H109.988C113.965 80.5664 117.188 83.79 117.188 87.7664V99.0325H64.6511V87.7664Z'
			fill='#5FC8ED'
		/>

		<mask id='path-27-inside-3' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M110.906 88.6152C112.232 88.6152 113.306 89.6898 113.306 91.0152V92.5032C113.306 93.8287 112.232 94.9032 110.906 94.9032H103.132V88.6152H110.906Z'
			/>
		</mask>

		<path
			d='M103.132 94.9032H88.7323V109.303H103.132V94.9032ZM103.132 88.6152V74.2152H88.7323V88.6152H103.132ZM98.9064 91.0152V92.5032H127.706V91.0152H98.9064ZM110.906 80.5032H103.132V109.303H110.906V80.5032ZM117.532 94.9032V88.6152H88.7323V94.9032H117.532ZM103.132 103.015H110.906V74.2152H103.132V103.015ZM98.9064 92.5032C98.9064 85.8758 104.279 80.5032 110.906 80.5032V109.303C120.185 109.303 127.706 101.782 127.706 92.5032H98.9064ZM127.706 91.0152C127.706 81.7369 120.185 74.2152 110.906 74.2152V103.015C104.279 103.015 98.9064 97.6426 98.9064 91.0152H127.706Z'
			fill='white'
			mask='url(#path-27-inside-3)'
		/>

		<mask id='path-29-inside-4' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M71.4413 94.9629C70.1158 94.9629 69.0413 93.8884 69.0413 92.5629V91.0749C69.0413 89.7494 70.1158 88.6749 71.4413 88.6749H79.2153V94.9629H71.4413Z'
			/>
		</mask>

		<path
			d='M79.2153 88.6749H93.6153V74.2749H79.2153V88.6749ZM79.2153 94.9629V109.363H93.6153V94.9629H79.2153ZM83.4413 92.5629V91.0749H54.6413V92.5629H83.4413ZM71.4413 103.075H79.2153V74.2749H71.4413V103.075ZM64.8153 88.6749V94.9629H93.6153V88.6749H64.8153ZM79.2153 80.5629H71.4413V109.363H79.2153V80.5629ZM83.4413 91.0749C83.4413 97.7024 78.0687 103.075 71.4413 103.075V74.2749C62.1629 74.2749 54.6413 81.7965 54.6413 91.0749H83.4413ZM54.6413 92.5629C54.6413 101.841 62.1629 109.363 71.4413 109.363V80.5629C78.0687 80.5629 83.4413 85.9355 83.4413 92.5629H54.6413Z'
			fill='white'
			mask='url(#path-29-inside-4)'
		/>
	</svg>
);

export const MessagesBig: React.FC<{}> = () => (
	<svg width='180' height='120' viewBox='0 0 180 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M77 39C77 36.7909 78.7909 35 81 35H121.159C123.368 35 125.159 36.7909 125.159 39V76H81C78.7909 76 77 74.2091 77 72V39Z'
			fill='#50B8DC'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M96 49C96 48.4477 96.4477 48 97 48H113C113.552 48 114 48.4477 114 49C114 49.5523 113.552 50 113 50H97C96.4477 50 96 49.5523 96 49Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M86 57C86 56.4477 86.4477 56 87 56H114C114.552 56 115 56.4477 115 57C115 57.5523 114.552 58 114 58H87C86.4477 58 86 57.5523 86 57Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M86 65C86 64.4477 86.4477 64 87 64H110C110.552 64 111 64.4477 111 65C111 65.5523 110.552 66 110 66H87C86.4477 66 86 65.5523 86 65Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M125 76H112V86.5858C112 87.4767 113.077 87.9229 113.707 87.2929L125 76Z'
			fill='#097590'
		/>

		<rect x='86' y='43' width='7' height='7' rx='1' fill='white' />

		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M51 60C51 57.7909 52.7909 56 55 56H95C97.2091 56 99 57.7909 99 60V93C99 95.2091 97.2091 97 95 97H51V60Z'
			fill='#5FC8ED'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M70 70C70 69.4477 70.4477 69 71 69H87C87.5523 69 88 69.4477 88 70C88 70.5523 87.5523 71 87 71H71C70.4477 71 70 70.5523 70 70Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M60 78C60 77.4477 60.4477 77 61 77H88C88.5523 77 89 77.4477 89 78C89 78.5523 88.5523 79 88 79H61C60.4477 79 60 78.5523 60 78Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M60 86C60 85.4477 60.4477 85 61 85H84C84.5523 85 85 85.4477 85 86C85 86.5523 84.5523 87 84 87H61C60.4477 87 60 86.5523 60 86Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M51 97H64V108C64 108.9 62.9048 109.342 62.2803 108.694L51 97Z'
			fill='#097590'
		/>

		<rect x='60' y='64' width='7' height='7' rx='1' fill='white' />

		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M127 42C133.075 42 138 37.0751 138 31C138 24.9249 133.075 20 127 20C120.925 20 116 24.9249 116 31C116 37.0751 120.925 42 127 42Z'
			fill='#2E3044'
		/>
		<path
			d='M122.664 28.1172V28.1562H124.852V28.1172C124.852 27.0781 125.656 26.3125 126.75 26.3125C127.797 26.3125 128.539 26.9844 128.539 27.8281C128.539 28.6094 128.188 29.1797 127.047 30.3203L122.797 34.4141V36H130.992V34.125H125.906V34.0781L128.656 31.4297C129.898 30.1953 130.836 29.1406 130.836 27.7344C130.836 25.8516 129.203 24.4766 126.828 24.4766C124.367 24.4766 122.664 25.9844 122.664 28.1172Z'
			fill='white'
		/>
		<path opacity='0.2' d='M127 15V2.95841' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M143 31L155.042 31' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M138.261 20.0032L142.191 16.0734' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M111.497 15.7606L115.426 19.6905' stroke='black' strokeWidth='2' strokeLinecap='square' />
		<path opacity='0.2' d='M138.497 42.7606L142.426 46.6905' stroke='black' strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const AvatarAutotype: React.FC<{}> = () => (
	<svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M0 0V40H40V0H0Z' fill='#F8F8F8' />
		<mask id='path-2-inside-1' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.601 27.9838H15.1616V31.1595C15.1616 31.7118 14.7139 32.1595 14.1616 32.1595H12.601C12.0487 32.1595 11.601 31.7118 11.601 31.1595V27.9838Z'
			/>
		</mask>
		<path
			d='M11.601 27.9838V21.9838H5.60101V27.9838H11.601ZM15.1616 27.9838H21.1616V21.9838H15.1616V27.9838ZM11.601 33.9838H15.1616V21.9838H11.601V33.9838ZM9.16158 27.9838V31.1595H21.1616V27.9838H9.16158ZM14.1616 26.1595H12.601V38.1595H14.1616V26.1595ZM17.601 31.1595V27.9838H5.60101V31.1595H17.601ZM12.601 26.1595C15.3624 26.1595 17.601 28.3981 17.601 31.1595H5.60101C5.60101 35.0255 8.73502 38.1595 12.601 38.1595V26.1595ZM9.16158 31.1595C9.16158 28.3981 11.4002 26.1595 14.1616 26.1595V38.1595C18.0276 38.1595 21.1616 35.0255 21.1616 31.1595H9.16158Z'
			fill='#CECFD6'
			mask='url(#path-2-inside-1)'
		/>
		<mask id='path-4-inside-2' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M25.4307 27.9838H28.9913V31.1595C28.9913 31.7118 28.5435 32.1595 27.9913 32.1595H26.4307C25.8784 32.1595 25.4307 31.7118 25.4307 31.1595V27.9838Z'
			/>
		</mask>
		<path
			d='M25.4307 27.9838V21.9838H19.4307V27.9838H25.4307ZM28.9913 27.9838H34.9913V21.9838H28.9913V27.9838ZM25.4307 33.9838H28.9913V21.9838H25.4307V33.9838ZM22.9913 27.9838V31.1595H34.9913V27.9838H22.9913ZM27.9913 26.1595H26.4307V38.1595H27.9913V26.1595ZM31.4307 31.1595V27.9838H19.4307V31.1595H31.4307ZM26.4307 26.1595C29.1921 26.1595 31.4307 28.3981 31.4307 31.1595H19.4307C19.4307 35.0255 22.5647 38.1595 26.4307 38.1595V26.1595ZM22.9913 31.1595C22.9913 28.3981 25.2298 26.1595 27.9913 26.1595V38.1595C31.8573 38.1595 34.9913 35.0255 34.9913 31.1595H22.9913Z'
			fill='#CECFD6'
			mask='url(#path-4-inside-2)'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.5787 9.11334C11.5787 8.56106 12.0265 8.11334 12.5787 8.11334H27.9775C28.5298 8.11334 28.9775 8.56106 28.9775 9.11334V28.6539H11.5787V9.11334Z'
			fill='#CECFD6'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M13.332 16.2251C13.4589 14.6679 14.7597 13.4689 16.3221 13.4689H24.2333C25.7956 13.4689 27.0964 14.6679 27.2233 16.2251L27.8387 23.7728H12.7166L13.332 16.2251Z'
			fill='#F8F8F8'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.5555 25.5177C11.5555 23.8608 12.8986 22.5177 14.5555 22.5177H25.9999C27.6568 22.5177 28.9999 23.8608 28.9999 25.5177V28.6492H11.5555V25.5177Z'
			fill='#CECFD6'
		/>

		<mask id='path-9-inside-3' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.7108 25.1899C27.2631 25.1899 27.7108 25.6377 27.7108 26.1899V26.2778C27.7108 26.8301 27.2631 27.2778 26.7108 27.2778H24.3326V25.1899H26.7108Z'
			/>
		</mask>

		<path
			d='M24.3326 27.2778H18.3326V33.2778H24.3326V27.2778ZM24.3326 25.1899V19.1899H18.3326V25.1899H24.3326ZM21.7108 26.1899V26.2778H33.7108V26.1899H21.7108ZM26.7108 21.2778H24.3326V33.2778H26.7108V21.2778ZM30.3326 27.2778V25.1899H18.3326V27.2778H30.3326ZM24.3326 31.1899H26.7108V19.1899H24.3326V31.1899ZM21.7108 26.2778C21.7108 23.5164 23.9494 21.2778 26.7108 21.2778V33.2778C30.5768 33.2778 33.7108 30.1438 33.7108 26.2778H21.7108ZM33.7108 26.1899C33.7108 22.324 30.5768 19.1899 26.7108 19.1899V31.1899C23.9494 31.1899 21.7108 28.9514 21.7108 26.1899H33.7108Z'
			fill='#F8F8F8'
			mask='url(#path-9-inside-3)'
		/>
		<mask id='path-11-inside-4' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M14.013 27.2978C13.4607 27.2978 13.013 26.8501 13.013 26.2978V26.2099C13.013 25.6577 13.4607 25.2099 14.013 25.2099H16.3912V27.2978H14.013Z'
			/>
		</mask>
		<path
			d='M16.3912 25.2099H22.3912V19.2099H16.3912V25.2099ZM16.3912 27.2978V33.2978H22.3912V27.2978H16.3912ZM19.013 26.2978V26.2099H7.01297V26.2978H19.013ZM14.013 31.2099H16.3912V19.2099H14.013V31.2099ZM10.3912 25.2099V27.2978H22.3912V25.2099H10.3912ZM16.3912 21.2978H14.013V33.2978H16.3912V21.2978ZM19.013 26.2099C19.013 28.9714 16.7744 31.2099 14.013 31.2099V19.2099C10.147 19.2099 7.01297 22.344 7.01297 26.2099H19.013ZM7.01297 26.2978C7.01297 30.1638 10.147 33.2978 14.013 33.2978V21.2978C16.7744 21.2978 19.013 23.5364 19.013 26.2978H7.01297Z'
			fill='#F8F8F8'
			mask='url(#path-11-inside-4)'
		/>
	</svg>
);

export const AvatarDriver: React.FC<{}> = () => (
	<svg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path fillRule='evenodd' clipRule='evenodd' d='M0 0V40H40V0H0Z' fill='#F8F8F8' />
		<g opacity='0.3'>
			<path
				d='M29 31V29C29 26.2386 26.7614 24 24 24H16C13.2386 24 11 26.2386 11 29V31'
				stroke='#6C6F88'
				strokeWidth='2'
				strokeLinecap='square'
			/>

			<circle cx='20' cy='15.1429' r='5.14286' fill='#F8F8F8' stroke='#6C6F88' strokeWidth='2' strokeLinecap='square' />
		</g>
	</svg>
);

export const Edit: React.FC<{ color: string }> = ({ color }) => (
	<svg height='36' viewBox='0 -1 381.53417 381' width='36' xmlns='http://www.w3.org/2000/svg'>
		<path
			fill={color}
			d='m370.589844 230.964844c-5.523438 0-10 4.476562-10 10v88.792968c-.019532 16.558594-13.4375 29.980469-30 30h-280.589844c-16.5625-.019531-29.980469-13.441406-30-30v-260.589843c.019531-16.5625 13.4375-29.980469 30-30h88.789062c5.523438 0 10-4.476563 10-10 0-5.523438-4.476562-10-10-10h-88.789062c-27.601562.03125-49.96875 22.398437-50 50v260.589843c.03125 27.601563 22.398438 49.96875 50 50h280.589844c27.601562-.03125 49.96875-22.398437 50-50v-88.789062c0-5.523438-4.476563-10.003906-10-10.003906zm0 0'
		/>
		<path fill={color} d='m156.367188 178.34375 146.011718-146.015625 47.089844 47.089844-146.011719 146.015625zm0 0' />
		<path fill={color} d='m132.542969 249.257812 52.039062-14.414062-37.625-37.625zm0 0' />
		<path
			fill={color}
			d='m362.488281 7.578125c-9.769531-9.746094-25.585937-9.746094-35.355469 0l-10.605468 10.605469 47.089844 47.089844 10.605468-10.605469c9.75-9.769531 9.75-25.585938 0-35.355469zm0 0'
		/>
	</svg>
);

export const Delete: React.FC<{ color: string }> = ({ color }) => (
	<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 729.837 729.838'>
		<path
			fill={color}
			d='M589.193,222.04c0-6.296,5.106-11.404,11.402-11.404S612,215.767,612,222.04v437.476c0,19.314-7.936,36.896-20.67,49.653 c-12.733,12.734-30.339,20.669-49.653,20.669H188.162c-19.315,0-36.943-7.935-49.654-20.669 c-12.734-12.734-20.669-30.313-20.669-49.653V222.04c0-6.296,5.108-11.404,11.403-11.404c6.296,0,11.404,5.131,11.404,11.404 v437.476c0,13.02,5.37,24.922,13.97,33.521c8.6,8.601,20.503,13.993,33.522,13.993h353.517c13.019,0,24.896-5.394,33.498-13.993 c8.624-8.624,13.992-20.503,13.992-33.498V222.04H589.193z'
		/>
		<path
			fill={color}
			d='M279.866,630.056c0,6.296-5.108,11.403-11.404,11.403s-11.404-5.107-11.404-11.403v-405.07 c0-6.296,5.108-11.404,11.404-11.404s11.404,5.108,11.404,11.404V630.056z'
		/>
		<path
			fill={color}
			d='M376.323,630.056c0,6.296-5.107,11.403-11.403,11.403s-11.404-5.107-11.404-11.403v-405.07 c0-6.296,5.108-11.404,11.404-11.404s11.403,5.108,11.403,11.404V630.056z'
		/>
		<path
			fill={color}
			d='M472.803,630.056c0,6.296-5.106,11.403-11.402,11.403c-6.297,0-11.404-5.107-11.404-11.403v-405.07 c0-6.296,5.107-11.404,11.404-11.404c6.296,0,11.402,5.108,11.402,11.404V630.056L472.803,630.056z'
		/>
		<path
			fill={color}
			d='M273.214,70.323c0,6.296-5.108,11.404-11.404,11.404c-6.295,0-11.403-5.108-11.403-11.404 c0-19.363,7.911-36.943,20.646-49.677C283.787,7.911,301.368,0,320.73,0h88.379c19.339,0,36.92,7.935,49.652,20.669 c12.734,12.734,20.67,30.362,20.67,49.654c0,6.296-5.107,11.404-11.403,11.404s-11.403-5.108-11.403-11.404 c0-13.019-5.369-24.922-13.97-33.522c-8.602-8.601-20.503-13.994-33.522-13.994h-88.378c-13.043,0-24.922,5.369-33.546,13.97 C278.583,45.401,273.214,57.28,273.214,70.323z'
		/>
		<path
			fill={color}
			d='M99.782,103.108h530.273c11.189,0,21.405,4.585,28.818,11.998l0.047,0.048c7.413,7.412,11.998,17.628,11.998,28.818 v29.46c0,6.295-5.108,11.403-11.404,11.403h-0.309H70.323c-6.296,0-11.404-5.108-11.404-11.403v-0.285v-29.175 c0-11.166,4.585-21.406,11.998-28.818l0.048-0.048C78.377,107.694,88.616,103.108,99.782,103.108L99.782,103.108z M630.056,125.916H99.782c-4.965,0-9.503,2.02-12.734,5.274L87,131.238c-3.255,3.23-5.274,7.745-5.274,12.734v18.056h566.361 v-18.056c0-4.965-2.02-9.503-5.273-12.734l-0.049-0.048C639.536,127.936,635.021,125.916,630.056,125.916z'
		/>
	</svg>
);

export const DeleteBig: React.FC<{}> = () => (
	<svg xmlns='http://www.w3.org/2000/svg' width='180' height='120' viewBox='0 0 180 120' fill='none'>
		<path
			d='M67.345 48.1947H111.947V93.3317C111.947 97.9627 108.193 101.717 103.562 101.717H75.7301C71.0991 101.717 67.345 97.9627 67.345 93.3317V48.1947Z'
			fill='#72CDEC'
		/>
		<rect x='83' y='59' width='30' height='3' rx='1.5' transform='rotate(90 83 59)' fill='#50B8DC' />
		<rect x='91' y='59' width='30' height='3' rx='1.5' transform='rotate(90 91 59)' fill='#50B8DC' />
		<rect x='99' y='59' width='30' height='3' rx='1.5' transform='rotate(90 99 59)' fill='#50B8DC' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M115.292 44.8495L63.9999 44.8495L63.9999 40.6123C63.9999 38.6417 65.5974 37.0442 67.568 37.0442L111.724 37.0442C113.694 37.0442 115.292 38.6417 115.292 40.6123L115.292 44.8495Z'
			fill='#2E3044'
		/>
		<path
			d='M84 35.9233C84 33.7565 85.7565 32 87.9233 32H91.2272C93.3939 32 95.1505 33.7565 95.1505 35.9233V37.5752H84V35.9233Z'
			fill='#2E3044'
		/>
		<path
			opacity='0.2'
			d='M74.9497 24.8106L71.0199 20.8807'
			stroke='black'
			strokeWidth='2.23009'
			strokeLinecap='square'
		/>
		<path
			opacity='0.2'
			d='M104.186 24.8105L108.771 20.2257'
			stroke='black'
			strokeWidth='2.23009'
			strokeLinecap='square'
		/>
		<path opacity='0.2' d='M89.7357 19.9583V6.25734' stroke='black' strokeWidth='2.23009' strokeLinecap='square' />
	</svg>
);

export const Notebook: React.FC<{}> = () => (
	<svg width='131' height='82' viewBox='0 0 131 82' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M0.851562 73.8H130.852V78.6C130.852 80.3674 129.419 81.8 127.652 81.8H4.05156C2.28425 81.8 0.851562 80.3674 0.851562 78.6V73.8Z'
			fill='#2E3044'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.3982 3.43303C11.3982 1.66572 12.8309 0.233032 14.5982 0.233032H116.602C118.369 0.233032 119.802 1.66572 119.802 3.43303V71.4891H11.3982V3.43303Z'
			fill='#72CDED'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M56.1797 40.003V38.24H60.7717V24.3C62.3707 24.3 66.1427 24.218 67.7827 24.218C75.7777 24.218 77.9917 27.867 77.9917 32.131C77.9917 36.6 74.9577 40.003 67.7827 40.003H63.0267V45.046H70.4477V46.809H63.0267V53H60.7717V46.809H56.1797V45.046H60.7717V40.003H56.1797ZM67.9877 26.35C66.7167 26.35 64.3797 26.391 63.0267 26.432V38.24H67.7007C72.9077 38.24 75.6137 36.108 75.6137 32.295C75.6137 28.523 73.3997 26.35 67.9877 26.35Z'
			fill='white'
		/>
		<rect opacity='0.4' x='63.2144' y='3.89709' width='3.97161' height='3.7756' rx='1.8878' fill='white' />
	</svg>
);

export const Waiting: React.FC<{}> = () => (
	<svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='32' cy='32' r='23' fill='#64C8EB' />
		<path
			opacity='0.6'
			d='M32 4C27.0935 4 22.2731 5.28931 18.0217 7.73876C13.7703 10.1882 10.2373 13.7118 7.7764 17.9566C5.31553 22.2014 4.01328 27.0183 4.0001 31.9248C3.98692 36.8313 5.26327 41.6551 7.7013 45.9131L32 32V4Z'
			fill='#64C8EB'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M32 34C30.8954 34 30 33.1046 30 32L30 14C30 12.8954 30.8954 12 32 12C33.1046 12 34 12.8954 34 14L34 32C34 33.1046 33.1046 34 32 34Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M19.8271 38.5121C19.2749 37.5555 19.6026 36.3323 20.5592 35.78L30.5705 30C31.5271 29.4477 32.7502 29.7755 33.3025 30.7321C33.8548 31.6887 33.5271 32.9118 32.5705 33.4641L22.5592 39.2441C21.6026 39.7964 20.3794 39.4687 19.8271 38.5121Z'
			fill='white'
		/>
		<circle cx='32' cy='32' r='3' fill='white' stroke='#2E3044' strokeWidth='2.22762' />
		<line x1='32' y1='3' x2='32' y2='5' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='32' y1='59' x2='32' y2='61' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='61' y1='32' x2='59' y2='32' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='5' y1='32' x2='3' y2='32' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='52.5566' y1='11.4438' x2='51.1424' y2='12.858' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='12.8574' y1='51.1422' x2='11.4432' y2='52.5565' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='52.5565' y1='52.5564' x2='51.1423' y2='51.1422' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
		<line x1='12.8582' y1='12.8579' x2='11.444' y2='11.4437' stroke='#64C8EB' strokeWidth='2' strokeLinecap='round' />
	</svg>
);

export const SendTo: React.FC<{}> = () => (
	<svg width='180' height='120' viewBox='0 0 180 120' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M64.1405 69.2123L72.5216 52.6919L148.5 52.5L150.522 55.139L73.817 68.5881L65.2653 70.6372C64.4363 70.8359 63.7548 69.9726 64.1405 69.2123Z'
			fill='#09758F'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M72.0903 15.2874C69.1846 13.8956 65.9098 16.3347 66.4111 19.5173L71.9999 55L131 55L151 55C151.967 55 152.265 53.6898 151.392 53.272L72.0903 15.2874Z'
			fill='#72CDEC'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M72.0903 94.7126C69.1846 96.1044 65.9098 93.6653 66.4111 90.4827L71.9999 55L131 55L150.5 55L150.597 55C151.662 55 151.989 56.4421 151.029 56.9019L72.0903 94.7126Z'
			fill='#50B8DC'
		/>
		<path opacity='0.2' d='M13.9766 54.5H44.4902' stroke='#2E3044' strokeWidth='2' strokeLinecap='round' />
		<path opacity='0.2' d='M38 62H47.6556' stroke='#2E3044' strokeWidth='2' strokeLinecap='round' />
		<path opacity='0.2' d='M24 48L56 48' stroke='#2E3044' strokeWidth='2' strokeLinecap='round' />

		<g opacity='0.2'>
			<path d='M32.6948 84L28.545 88.1498' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
			<path d='M28.46 84L32.6098 88.1498' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
		</g>

		<g opacity='0.2'>
			<path d='M139.695 9L135.545 13.1498' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
			<path d='M135.46 9L139.61 13.1498' stroke='black' strokeWidth='1.344' strokeLinecap='square' />
		</g>

		<circle cx='83.5' cy='46.5' r='2.5' fill='white' />
		<circle cx='92.5' cy='46.5' r='2.5' fill='white' />
		<circle cx='101.5' cy='46.5' r='2.5' fill='white' />
	</svg>
);

export const View: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='32' height='32' viewBox='4 8 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22.8519 15.7739C22.8264 15.7351 22.2164 14.8136 21.0744 13.8879C19.551 12.6528 17.7963 12 16 12C14.2037 12 12.449 12.6528 10.9256 13.8879C9.78363 14.8136 9.1736 15.7351 9.14818 15.7739L9 16L9.14818 16.2261C9.1736 16.2649 9.78363 17.1864 10.9256 18.1121C12.449 19.3472 14.2037 20 16 20C17.7962 20 19.551 19.3472 21.0744 18.1121C22.2164 17.1864 22.8264 16.2649 22.8519 16.2261L23 16L22.8519 15.7739Z'
			stroke={color}
			strokeWidth='2'
		/>
		<path
			d='M16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17Z'
			stroke='#6C6F88'
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);
export const Reply: React.FC<{ color?: string }> = ({ color = '#F4AE46' }) => (
	<svg width='32' height='32' viewBox='4 8 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='7' y='9' width='18' height='14' rx='7' fill={color} />
		<path
			d='M22.5 23C22.5 21.0197 22.3698 18.7045 20.9723 16.9998C19.5202 15.2285 16.9692 14.4006 12.8782 14.6877L13.0182 16.6827C16.8603 16.4131 18.5851 17.2426 19.4257 18.2678C20.3208 19.3597 20.5 20.9802 20.5 23H22.5Z'
			fill='white'
		/>
		<path
			d='M12 15.5168L11.2929 14.8097L10.5858 15.5168L11.2929 16.2239L12 15.5168ZM12.7071 16.2239L16.2239 12.7071L14.8096 11.2929L11.2929 14.8097L12.7071 16.2239ZM16.2239 18.3264L12.7071 14.8097L11.2929 16.2239L14.8096 19.7406L16.2239 18.3264Z'
			fill='white'
		/>
	</svg>
);

export const Approve: React.FC<{ color?: string }> = ({ color = '#79D9A5' }) => (
	<svg width='32' height='32' viewBox='4 8 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='7' y='9' width='18' height='14' rx='7' fill={color} />
		<path
			d='M14.3519 19.1105L13.6374 19.8102L14.3519 20.5398L15.0664 19.8102L14.3519 19.1105ZM10.842 16.9553L13.6374 19.8102L15.0664 18.4109L12.271 15.556L10.842 16.9553ZM15.0664 19.8102L21.1581 13.5892L19.7291 12.1899L13.6374 18.4109L15.0664 19.8102Z'
			fill='white'
		/>
	</svg>
);

export const Filter: React.FC<{}> = () => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path d='M21.0103 9.14283H25.3809' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path d='M6.43891 9.14283H15.9524' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path d='M21.0103 22.8571H25.3809' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path d='M6.43891 16H10.8095' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path d='M6.43891 22.8571H15.9524' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path d='M15.8675 16H25.3809' stroke='white' strokeWidth='2' strokeLinecap='square' />
		<path d='M20.2382 7.83341V10.4637' stroke='white' strokeWidth='3' strokeLinecap='square' />
		<path d='M20.2382 21.541V24.5714' stroke='white' strokeWidth='3' strokeLinecap='square' />
		<path d='M10.8095 14.6838V17.7143' stroke='white' strokeWidth='3' strokeLinecap='square' />
	</svg>
);

export const Contract: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M27 23L27 11C27 9.89543 26.1046 9 25 9L7 9C5.89543 9 5 9.89543 5 11L5 23C5 24.1046 5.89543 25 7 25L25 25C26.1046 25 27 24.1046 27 23Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
		<path d='M9 13L11 13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M9 17L23 17' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M9 21L18.5 21' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M15 13L17 13' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path d='M21 13L23 13' stroke={color} strokeWidth='2' strokeLinecap='square' />
	</svg>
);

export const Error: React.FC<{ size: number }> = ({ size = 64 }) => (
	<svg width={size} height={size} viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='32' cy='32' r='23' fill='white' />
		<circle cx='32' cy='32' r='25.4545' stroke='#BFC5DA' strokeOpacity='0.36' strokeWidth='4.90909' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M32 39C30.8822 39 29.9633 38.1186 29.9167 37.0017L29.1249 17.9974C29.0568 16.3626 30.3637 15 32 15C33.6363 15 34.9432 16.3626 34.8751 17.9974L34.0833 37.0017C34.0367 38.1186 33.1178 39 32 39Z'
			fill='#64C8EB'
		/>
		<circle cx='32' cy='46' r='3' fill='#64C8EB' />
	</svg>
);

export const Calendar: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='32' height='32' viewBox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<rect x='8' y='9' width='16' height='16' rx='2' stroke={color} strokeWidth='2' strokeLinecap='square' />
		<path
			d='M24 12.5H24.5V12V11C24.5 9.61929 23.3807 8.5 22 8.5H10C8.61929 8.5 7.5 9.61929 7.5 11V12V12.5H8H24Z'
			fill={color}
		/>
		<path
			d='M24 12.5H24.5V12V11C24.5 9.61929 23.3807 8.5 22 8.5H10C8.61929 8.5 7.5 9.61929 7.5 11V12V12.5H8H24Z'
			stroke={color}
			strokeLinecap='square'
		/>
		<path
			d='M24 12.5H24.5V12V11C24.5 9.61929 23.3807 8.5 22 8.5H10C8.61929 8.5 7.5 9.61929 7.5 11V12V12.5H8H24Z'
			stroke={color}
			strokeLinecap='square'
		/>
		<rect x='11' y='15' width='5' height='5' fill={color} />
		<rect x='10' y='6' width='2' height='5' fill={color} />
		<rect x='20' y='6' width='2' height='5' fill={color} />
	</svg>
);

export const Document: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='18' height='24' viewBox='0 0 18 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M5.00006 6H7.00006M5.00006 10L13.0001 10M5.00006 14L11.0001 14M15.0001 23L3.00006 23C1.89549 23 1.00006 22.1046 1.00006 21L1.00006 3C1.00006 1.89543 1.89549 1 3.00006 1L15.0001 1C16.1046 1 17.0001 1.89543 17.0001 3L17.0001 21C17.0001 22.1046 16.1046 23 15.0001 23Z'
			stroke={color}
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);
export const DocumentNew: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.4999 24C11.0522 24 11.4999 23.5523 11.4999 23C11.4999 22.4477 11.0522 22 10.4999 22V24ZM3.5 23L3.5 24H3.5V23ZM3.5 1V2V1ZM15.5 1V1.07288e-06V1ZM17.5 3H16.5H17.5ZM17.5 23L16.7929 23.7071C16.9804 23.8946 17.2348 24 17.5 24C17.7652 24 18.0196 23.8946 18.2071 23.7071L17.5 23ZM1.5 21H0.5H1.5ZM1.5 3L0.500002 3L1.5 3ZM5.5 5H4.5V7H5.5V5ZM7.5 7H8.5V5H7.5V7ZM5.5 9H4.5L4.5 11H5.5V9ZM13.5 11H14.5V9H13.5V11ZM5.5 13H4.5L4.5 15H5.5V13ZM11.5 15H12.5V13H11.5V15ZM15.207 19.2929C14.8165 18.9024 14.1833 18.9024 13.7928 19.2929C13.4023 19.6834 13.4023 20.3166 13.7928 20.7071L15.207 19.2929ZM21.2071 20.7071C21.5976 20.3166 21.5976 19.6834 21.207 19.2929C20.8165 18.9024 20.1833 18.9024 19.7928 19.2929L21.2071 20.7071ZM10.4999 22H3.5V24H10.4999V22ZM3.5 2L15.5 2V1.07288e-06L3.5 0V2ZM16.5 3V23H18.5V3H16.5ZM2.5 21L2.5 3L0.500002 3L0.5 21H2.5ZM15.5 2C16.0523 2 16.5 2.44772 16.5 3H18.5C18.5 1.34315 17.1569 1.19209e-06 15.5 1.07288e-06V2ZM3.5 0C1.84315 -1.19209e-07 0.500002 1.34314 0.500002 3L2.5 3C2.5 2.44771 2.94772 2 3.5 2V0ZM3.5 22C2.94771 22 2.5 21.5523 2.5 21H0.5C0.5 22.6569 1.84314 24 3.5 24L3.5 22ZM5.5 7H7.5V5H5.5V7ZM5.5 11L13.5 11V9L5.5 9V11ZM5.5 15L11.5 15V13L5.5 13V15ZM18.2071 22.2929L15.207 19.2929L13.7928 20.7071L16.7929 23.7071L18.2071 22.2929ZM18.2071 23.7071L21.2071 20.7071L19.7928 19.2929L16.7929 22.2929L18.2071 23.7071Z'
			fill={color}
		/>
	</svg>
);

export const CarInfo: React.FC<{}> = () => (
	<svg width='64' height='64' viewBox='0 0 64 64' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='32' cy='32' r='25' fill='#64C8EB' />
		<circle cx='32' cy='32' r='26.5' stroke='#BFC5DA' strokeOpacity='0.36' strokeWidth='3' />
		<mask id='path-3-inside-1' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.4541 44.459H10.0912V49.9562C10.0912 50.5713 9.59252 51.07 8.97738 51.07H5.56791C4.95277 51.07 4.4541 50.5713 4.4541 49.9562V44.459Z'
			/>
		</mask>
		<path
			d='M4.4541 44.459V37.7761H-2.22877V44.459H4.4541ZM10.0912 44.459H16.7741V37.7761H10.0912V44.459ZM4.4541 51.1419H10.0912V37.7761H4.4541V51.1419ZM3.40832 44.459V49.9562H16.7741V44.459H3.40832ZM8.97738 44.3871H5.56791V57.7529H8.97738V44.3871ZM11.137 49.9562V44.459H-2.22877V49.9562H11.137ZM5.56791 44.3871C8.64363 44.3871 11.137 46.8805 11.137 49.9562H-2.22877C-2.22877 54.2622 1.26192 57.7529 5.56791 57.7529V44.3871ZM3.40832 49.9562C3.40832 46.8805 5.90167 44.3871 8.97738 44.3871V57.7529C13.2834 57.7529 16.7741 54.2622 16.7741 49.9562H3.40832Z'
			fill='#2E3044'
			mask='url(#path-3-inside-1)'
		/>
		<mask id='path-5-inside-2' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M26.3491 44.459H31.9862V49.9562C31.9862 50.5713 31.4875 51.07 30.8724 51.07H27.4629C26.8478 51.07 26.3491 50.5713 26.3491 49.9562V44.459Z'
			/>
		</mask>
		<path
			d='M26.3491 44.459V37.7761H19.6663V44.459H26.3491ZM31.9862 44.459H38.6691V37.7761H31.9862V44.459ZM26.3491 51.1419H31.9862V37.7761H26.3491V51.1419ZM25.3033 44.459V49.9562H38.6691V44.459H25.3033ZM30.8724 44.3871H27.4629V57.7529H30.8724V44.3871ZM33.032 49.9562V44.459H19.6663V49.9562H33.032ZM27.4629 44.3871C30.5386 44.3871 33.032 46.8805 33.032 49.9562H19.6663C19.6663 54.2622 23.1569 57.7529 27.4629 57.7529V44.3871ZM25.3033 49.9562C25.3033 46.8805 27.7967 44.3871 30.8724 44.3871V57.7529C35.1784 57.7529 38.6691 54.2622 38.6691 49.9562H25.3033Z'
			fill='#2E3044'
			mask='url(#path-5-inside-2)'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.41797 14.1138C4.41797 13.4987 4.91664 13 5.53178 13H30.8499C31.465 13 31.9637 13.4987 31.9637 14.1138V45.5199H4.41797V14.1138Z'
			fill='#2E3044'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.27571 24.5198C7.4312 22.7979 8.87462 21.4789 10.6036 21.4789H25.7783C27.5072 21.4789 28.9507 22.7979 29.1062 24.5198L30.1616 36.2089H6.22021L7.27571 24.5198Z'
			fill='white'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M4.38184 39.1465C4.38184 37.3011 5.87785 35.8051 7.72327 35.8051H28.6585C30.5039 35.8051 31.9999 37.3011 31.9999 39.1465V45.5124H4.38184V39.1465Z'
			fill='#2E3044'
		/>
		<path d='M28.8454 42.2274L28.8454 43.3413L28.8454 42.2274Z' stroke='white' strokeWidth='6.68287' />
		<path d='M7.80326 41.1812L7.80326 40.0674L7.80326 41.1812Z' stroke='white' strokeWidth='6.68287' />
		<g opacity='0.2'>
			<path d='M55.1377 4.63336L51.4325 8.33858' stroke='#32386C' strokeWidth='1.2' strokeLinecap='square' />
			<path d='M51.3564 4.63336L55.0617 8.33858' stroke='#32386C' strokeWidth='1.2' strokeLinecap='square' />
		</g>
		<g opacity='0.2'>
			<path d='M12.1919 56L8.48668 59.7052' stroke='#32386C' strokeWidth='1.2' strokeLinecap='square' />
			<path d='M8.41064 56L12.1159 59.7052' stroke='#32386C' strokeWidth='1.2' strokeLinecap='square' />
		</g>
		<path
			d='M38 21.1333L39.9091 23L44 19'
			stroke='white'
			strokeWidth='2'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M37 30H51' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M37 36H53' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
		<path d='M37 42H42' stroke='white' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);
