import React, { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import {
	Container,
	Thumbs,
	ThumbsContainer,
	Title,
	DropZoneContainer,
	UploadIconContainer,
} from './UploaderComponents';
import { Upload } from 'app/components/Icons';

export interface FileWithPreview extends File {}

export function Uploader({
	title,
	accept = 'image/*',
	onUpload,
	uploaded = [],
	onDelete,
	className,
	dropZoneClassName,
	thumbsClassName,
	thumbClassName,
	titleClassName,
	allowTitleClick,
}: any) {
	const [files, setFiles] = useState<any[]>([]);
	const [loading] = useState<boolean>(false);

	const handleUpload = async (uploadingFile: any) => {
		if (onUpload) {
			await onUpload(uploadingFile);

			setFiles(files.filter((file: any) => file.preview !== uploadingFile.preview));
		}
	};

	const dropZoneRef = useRef(null);

	const { getRootProps, getInputProps } = useDropzone({
		accept,
		onDrop: (acceptedFiles: File[]) => {
			setFiles([
				...files,
				...acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file),
					}),
				),
			]);

			acceptedFiles.forEach((file) => handleUpload(file));
		},
	});

	useEffect(
		() => () => {
			// Make sure to revoke the data uris to avoid memory leaks
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files],
	);

	const handleTitleClick = () => {
		//@ts-ignore
		const { ref } = getInputProps();
		allowTitleClick && ref.current.click();
	};

	return (
		<Container className={className}>
			{title && (
				<Title className={titleClassName} onClick={handleTitleClick}>
					{title}
				</Title>
			)}

			<DropZoneContainer {...getRootProps()} className={dropZoneClassName}>
				{!uploaded.length && !loading && (
					<UploadIconContainer>
						<Upload key='accept-icon' color='#64C8EB' />
					</UploadIconContainer>
				)}

				<input ref={dropZoneRef} {...getInputProps()} />

				<ThumbsContainer className={thumbsClassName}>
					<Thumbs files={uploaded} onDelete={onDelete} thumbClassName={thumbClassName} />

					<Thumbs loader files={files} />
				</ThumbsContainer>
			</DropZoneContainer>
		</Container>
	);
}

export default Uploader;
