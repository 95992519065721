import React from 'react';
import styled from 'styled-components';

export interface CustomHTMLButtonElement extends HTMLButtonElement {
	variant: string;
}

export type ButtonProps = React.PropsWithChildren<CustomHTMLButtonElement>;

const buttonBase = `
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;    
    transition: all 0.2s ease;
    text-transform: uppercase;
    letter-spacing: 1.1px;
    border-radius: 4px;
    min-width: 150px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
    height: 40px;
    width: 100%;
`;

const variantPrimary = `
    color: rgba(255, 255, 255, 1);
    background-color: #64c8eb;    
    &:hover {
        background-color: rgba(100, 200, 235, 0.8);
    }
`;

const variantSecondary = `
    color: #6C6F88;
    background-color: #F8F8F8;    
    &:hover {
        background-color: rgba(348, 248, 248, 0.8);
    }
`;

const PrimaryButton = styled.button`
	${buttonBase}
	${variantPrimary}
`;

const SecondaryButton = styled.button`
	${buttonBase}
	${variantSecondary}
`;

const buttonsMap: any = {
	primary: PrimaryButton,
	secondary: SecondaryButton,
};

export const Button: React.FC<any | ButtonProps> = (props) => {
	const { children, variant = 'primary', ...rest } = props;
	const Button = buttonsMap[variant];
	return <Button {...rest}>{children}</Button>;
};
