import { combineReducers } from 'redux';
import { handleAction } from 'redux-actions';
import { DriverActions } from 'app/actions';

import carsReducer from './cars';
import driversReducer from './drivers';
import emptyCarReducer from './emptyCar';

// car/driver uuid or loading state string
const initialState = '';

const openedReducer = (actionName: string) =>
	handleAction<string, string>(actionName, (state, { payload }) => payload ?? state, initialState);

export default combineReducers({
	carOpened: openedReducer(DriverActions.Action.SET_OPENED_TRANSPORT),
	driverOpened: openedReducer(DriverActions.Action.SET_OPENED),
	loadingState: openedReducer(DriverActions.Action.SET_LOADING_STATE), //состояние загрузки списка машин
	cars: carsReducer,
	drivers: driversReducer,
	emptyCar: emptyCarReducer,
});
