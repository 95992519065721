import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import * as api from 'app/api';
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
import { GroupFilter } from 'app/sharedComponents/GroupFilter';
import { useFilters } from 'app/hooks/useFilters';
// import Checkbox from 'app/sharedComponents/Checkbox';
import { getDocumentsList, getDocumentsListMore } from 'app/actions';
import { useModalContext } from 'app/containers/Modal';
import { Content, Filters, MapContainer } from '../../Executors/components/StyledCandidatesComponents';
import { DocumentsList } from './DocumentsList';
import { EmptyState } from './EmptyState';
import { DocumentsFilters } from './DocumentsFilters';
import { PdfViewer } from './PdfViewer';
import { DocumentType } from './types';
import { SignALLButtonContainer, DocumentsListContainer } from './styles';
import { DigitalSignModal, NonDigitalSignModal } from './DigitalSignModal';
/**
 *
 * фильтры
 * Заказчик ->
 *
 * для водителя:
 * customer/dispatcher список заказчиков
 *
 * для заказчика:
 *  список диспетчеров
 *
 */

import { DocsZone, LeftPanel, FiltersLeftPart, FiltersRightPart } from './styles';

const groups = {
	INCOMING: 'new',
	SIGNED: 'signed',
	SIGNING: 'signing',
};

const grouping = [
	{ id: groups.INCOMING, title: 'Входящие', icon: 'document-new' },
	{ id: groups.SIGNED, title: 'Подписанные', icon: 'document-new' },
	// { id: groups.SIGNING, title: 'На подписании', icon: 'document-new' },
];

export const parseDocUrl = (url = '') => {
	if (process.env.PROXY) {
		const parsedUrl = url.split('https://dev.vezzer.no');
		return `/pdf${parsedUrl[1]}`;
	}

	return url;
};

function getModalContent(docs: DocumentType[]) {
	return <DigitalSignModal docs={docs} />;
}

function getNonDigitalModalContent(docs: DocumentType[], getDocumentBlob: Function) {
	return <NonDigitalSignModal docs={docs} getDocumentBlob={getDocumentBlob} />;
}

//----------------------------------------------------------
// Documents
//----------------------------------------------------------
export const Documents = () => {
	//helpers
	const dispatch = useDispatch();
	const { handleModal } = useModalContext();

	//states
	const [docBlob, setDocBlob] = useState<Blob | null>(null);
	const [selectedItem, setSelectedItem] = useState<any>(null);
	const [selectedDocuments, setSelectedDocuments] = useState<String[]>([]);
	const [companies, setCompanies] = useState([]);
	const [selectedFile, setSelectedFile] = useState<Uint8Array | null>(null);
	const [docReady, setDocReady] = useState(false);

	//data
	const {
		data: documents,
		cursor: { next },
		loading,
	} = useSelector((state: any) => state.documents);

	const token = useSelector((state: any) => state.user.sessionToken);

	const { filters, handleFilterChange } = useFilters({
		type: '',
		status: 'new',
		company: '',
		ordering: '',
	});

	//callbacks
	const getDocumentBlob = async (url: string) => {
		const resp = await fetch(parseDocUrl(url), {
			method: 'GET',
			headers: {
				'Authorization': `Token ${token}`,
				'Accept': 'application/pdf',
				'Content-Type': 'application/pdf',
			},
		});
		const blob = await resp.blob();
		return blob as Blob;
	};

	const handleDocClick = async (item: any) => {
		setDocReady(false);

		if (selectedItem?.url === item.url) {
			setSelectedItem(null);
			setSelectedFile(null);
			setDocBlob(null);
			return;
		}

		setSelectedItem(item);

		const blob = await getDocumentBlob(item?.url);

		//@ts-ignore
		const arrBuff = await blob.arrayBuffer();

		const int8 = new Uint8Array(arrBuff);

		setDocBlob(blob);
		setSelectedFile(int8);
		setDocReady(true);
	};

	const handleScroll = (event: any) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		const pixelsToBottom = scrollHeight - clientHeight - scrollTop;

		if (pixelsToBottom < 5 && !loading && next) dispatch(getDocumentsListMore());
	};

	const handleDocumentSelect = (item: DocumentType) => {
		const isExists = selectedDocuments.includes(item.uuid);

		if (isExists) {
			setSelectedDocuments(selectedDocuments.filter((el: String) => el !== item.uuid));
			return;
		}

		setSelectedDocuments([...selectedDocuments, item.id]);
	};

	const handleSignDocuments = async () => {
		const uuids = selectedDocuments.length ? [...selectedDocuments] : [];

		signDocuments(uuids);
	};

	const handleTabFilterChange = (val: any) => {
		const filterChangeHandler = handleFilterChange('status');

		filterChangeHandler(val);
		setSelectedDocuments([]);
	};

	const signDocuments = async (uuids: any[]) => {
		const documentsForSign = documents.filter(({ uuid }: DocumentType) => uuids.includes(uuid));
		const noDigital = documentsForSign.some((doc: DocumentType) => doc.signatureType === 'non-digital');

		handleModal({
			content: noDigital
				? getNonDigitalModalContent(documentsForSign, getDocumentBlob)
				: getModalContent(documentsForSign),
			props: {
				title: 'Документы на подпись',

				width: '500',
				height: '400',
			},
		});

		if (!noDigital) {
			const resp = await api.v1.post('/documents/sign', {
				uuids: uuids?.length ? uuids : undefined,
				signAll: uuids?.length ? undefined : true,
			});

			if (resp.status === 200) {
				toast.success('Документы отправлены на подпись', {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			}
		}
	};

	//effects
	useEffect(() => {
		async function fetchLib() {
			const resp = await api.v1.get('documents/company');

			if (resp?.data?.data?.length) {
				setCompanies(resp.data.data.map((el: any) => ({ value: el.id, label: el.name })));
			}
		}
		fetchLib();
		dispatch(getDocumentsList(filters));
	}, []);

	useEffect(() => {
		if (documents.length < 20 && !loading && next) {
			dispatch(getDocumentsListMore(filters));
		}
	}, [documents]);

	useEffect(() => {
		dispatch(getDocumentsList(filters));
	}, [filters]);

	//renders
	return (
		<Content>
			<Filters>
				<FiltersLeftPart>
					<GroupFilter width='500px' active={filters.status} items={grouping} onChange={handleTabFilterChange} />
				</FiltersLeftPart>

				<FiltersRightPart>
					<DocumentsFilters filters={filters} onChange={handleFilterChange} companies={companies} />
				</FiltersRightPart>
			</Filters>

			<DocsZone>
				<div className='documents-screen'>
					<LeftPanel>
						<DocumentsListContainer className='documents-list-container'>
							<DocumentsList
								loading={loading}
								onScroll={handleScroll}
								list={documents}
								selectedDocuments={selectedDocuments}
								selectedItem={selectedItem}
								onSelect={handleDocumentSelect}
								onDocClick={handleDocClick}
							/>
						</DocumentsListContainer>

						<SignALLButtonContainer>
							<Button
								style={{ width: '300px' }}
								disabled={!documents.length || filters.status !== 'new'}
								styleType={ButtonStyleTypes.PRIMARY}
								onClick={handleSignDocuments}
							>
								{selectedDocuments.length ? `Подписать ${selectedDocuments.length} документа` : 'Подписать все'}
							</Button>
						</SignALLButtonContainer>
					</LeftPanel>

					<MapContainer>
						{(!selectedItem || !docReady) && <EmptyState />}

						<PdfViewer
							onSign={signDocuments}
							item={selectedItem}
							isReady={!!selectedItem && !!selectedFile && docReady}
							file={selectedFile}
							blob={docBlob}
						/>
					</MapContainer>
				</div>
			</DocsZone>
		</Content>
	);
};
