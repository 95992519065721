import { handleActions, Action } from 'redux-actions';
import { SET_TRANSPORT_INFO, CLEAR_TRANSPORT, ADD_TRANSPORT_INFO_ARRAY } from 'app/actions';

type TransportState = {
	[uuid: string]: any;
};

const arrToObj = (arr = [], idKey: string) => {
	return arr.reduce((acc, item) => ({ ...acc, [item[idKey]]: item }), {});
};

export const transportReducer = handleActions<TransportState, any>(
	{
		[SET_TRANSPORT_INFO]: (state, { payload }: Action<any>) =>
			payload
				? {
						...state,
						[payload.transportId]: payload,
				  }
				: state,
		[CLEAR_TRANSPORT]: () => ({}),
		[ADD_TRANSPORT_INFO_ARRAY]: (state, { payload }: Action<any>) =>
			payload
				? {
						...state,
						...arrToObj(payload, 'transportId'),
				  }
				: state,
	},
	{},
);
