import React from 'react';

import { StyledContainer } from '../../../sharedComponents/sharedStyles/styledContainer';
import MultiSelectButtonGroup, { MultiButton } from '../../../sharedComponents/MultiSelectButtonGroup';

const TOLOADING_ARROW = require('assets/icons/dottedArrow.svg');
const TOUNLOADING_ARROW = require('assets/icons/toLoadingArrow.svg');
const ONLOAD_ARROW = require('assets/icons/onLoadArrow.svg');
const ONUNLOAD_ARROW = require('assets/icons/onUnloadingArrow.svg');

export type StageFilterProps = {
	filters: any;
	onChange: (key: string, value: boolean) => void;
};

export const StageFilter = ({ filters = {}, onChange }: StageFilterProps) => {
	const handleChange = (filter: any, checked: boolean) => {
		onChange(filter, checked);
	};

	return (
		<StyledContainer>
			<MultiSelectButtonGroup onChange={handleChange} title='стадии рейса' valuesMap={filters}>
				<MultiButton value='to_loading'>
					<img src={TOLOADING_ARROW} />
					<span>в пути</span>
				</MultiButton>
				<MultiButton type='square' value='on_loading'>
					<img src={ONLOAD_ARROW} />
					<span>на погрузке</span>
				</MultiButton>
				<MultiButton value='to_unloading'>
					<img src={TOUNLOADING_ARROW} />
					<span>в пути</span>
				</MultiButton>
				<MultiButton type='square' value='on_unloading'>
					<img src={ONUNLOAD_ARROW} />
					<span>на выгрузке</span>
				</MultiButton>
			</MultiSelectButtonGroup>
		</StyledContainer>
	);
};
