import { v1, prepareParams } from 'app/api';
import { UserContact, Passport, DriverLicence, BankAccount, UserCertificate, UserProfile } from 'app/models';
import { UserApiModel } from './types';
import Masker from 'vanilla-masker';
import _ from 'lodash';
import { globalInitialState } from 'app/utils/initial-state';

export async function userSignIn(data: UserApiModel.SignInRequest): Promise<UserApiModel.SignInResponseData> {
	const { brand } = globalInitialState;
	data = (!!brand && { ...data, brand }) || data;

	const response = await v1.post<UserApiModel.SignInResponse>('/user/signin', prepareParams(data));
	return response.data.data as UserApiModel.SignInResponseData;
}

export async function userVerifyCode(data: UserApiModel.VerifyRequest): Promise<UserApiModel.VerifyResponse> {
	const response = await v1.post<UserApiModel.VerifyResponse>('/user/verify', prepareParams(data));
	return response.data;
}

export async function userSelectRole(data: UserApiModel.SelectRoleRequest): Promise<UserApiModel.UserSessionResponse> {
	const response = await v1.post<UserApiModel.UserSessionResponse>('/user/select_role', prepareParams(data));
	return response.data;
}

export async function userGetSession(): Promise<UserApiModel.UserSessionResponse> {
	const response = await v1.get<UserApiModel.UserSessionResponse>('/user/session');
	return response.data;
}

export async function userUploadAvatar(picture: File): Promise<{ avatar?: string; photo?: string }> {
	const form = new FormData();
	form.append('userpic', picture);
	const response = await v1.post<UserApiModel.UserUpdateAvatarResponse>('/user/profile/upload', form);
	return response.data.data as UserProfile;
}

export async function userUpdateProfile(data: UserApiModel.UserProfile): Promise<UserApiModel.UserProfile> {
	const response = await v1.put<UserApiModel.UserProfileResponse>('/user/profile', prepareParams(data));
	return response.data.data as UserProfile;
}

export async function userPatchProfile(data: UserApiModel.UserProfile): Promise<UserApiModel.UserProfile> {
	const response = await v1.patch<UserApiModel.UserProfileResponse>('/user/profile', prepareParams(data));
	return response.data.data as UserProfile;
}

export async function userGetContacts(): Promise<UserContact[]> {
	const response = await v1.get<UserApiModel.UserContactsResponse>('/user/contact');
	return response.data.data as UserContact[];
}

export async function userGetContact(id: string): Promise<UserContact> {
	const response = await v1.get<UserApiModel.UserGetContactResponse>(`/user/contact/${id}`);
	return response.data.data as UserContact;
}

export async function userCreateContact(data: UserApiModel.UserContactRequest): Promise<UserContact> {
	const response = await v1.post<UserApiModel.UserGetContactResponse>('/user/contact', prepareParams(data));
	return response.data.data as UserContact;
}

export async function userUpdateContact(id: string, data: UserApiModel.UserContactRequest): Promise<UserContact> {
	const response = await v1.put<UserApiModel.UserGetContactResponse>(`/user/contact/${id}`, prepareParams(data));
	return response.data.data as UserContact;
}

export async function userDeleteContact(id: string): Promise<boolean> {
	const response = await v1.delete(`/user/contact/${id}`);
	return response.data.data;
}

export async function userUpdatePassport(data: UserApiModel.UpdatePassportRequest): Promise<Passport> {
	const files = new FormData();
	const params: any = {};
	Object.keys(data).forEach((key) => {
		params[_.snakeCase(key)] = (data as any)[key];
	});
	Object.keys(params).forEach((key) => {
		if (['main_page', 'registration_page', 'snils_page'].includes(key)) {
			const value = params[key];
			files.append(key, value);
			delete params[key];
		} else if (key === 'number') {
			params.number = Masker.toNumber(params.number);
		}
	});
	await v1.post<UserApiModel.UpdatePassportRequest>('/user/profile/passport', files);
	const response = await v1.post<UserApiModel.PassportResponse>('/user/profile/passport', params);
	// delete response.data.data?.profile;
	return response.data.data as Passport;
}

export async function userUpdateDriverLicence(data: UserApiModel.UpdateDriverLicenceRequest): Promise<DriverLicence> {
	const files = new FormData();
	const params: any = {};
	Object.keys(data).forEach((key) => {
		params[_.snakeCase(key)] = (data as any)[key];
	});
	Object.keys(params).forEach((key) => {
		if (['main_page', 'back_page'].includes(key)) {
			const value = params[key];
			files.append(key, value);
			delete params[key];
		} else if (key === 'number') {
			// TODO: vanilla-masker doesn't support russian symbols
			params.number = params.number.replace(/\s/g, '');
		}
	});
	await v1.post<UserApiModel.UpdateDriverLicenceRequest>('/user/profile/driver_licence', files);
	const response = await v1.post<UserApiModel.DriverLicenceResponse>('/user/profile/driver_licence', params);
	// delete response.data.data?.profile;
	return response.data.data as DriverLicence;
}

export async function userGetDriverLicence(): Promise<DriverLicence> {
	const response = await v1.get<UserApiModel.DriverLicenceResponse>('/user/profile/driver_licence');
	// delete response.data.data?.profile; // TODO: why do we delete .profile field?
	return response.data.data as DriverLicence;
}

export async function userGetPassport(): Promise<Passport> {
	const response = await v1.get<UserApiModel.PassportResponse>('/user/profile/passport');
	// delete response.data.data?.profile; // TODO: why do we delete .profile field?
	return response.data.data as Passport;
}

export async function userGetBankAccount(): Promise<BankAccount> {
	const response = await v1.get<UserApiModel.BankAccountResponse>('/user/profile/account');
	return response.data.data as BankAccount;
}

export async function userUpdateBankAccount(data: BankAccount): Promise<BankAccount> {
	const response = await v1.post<UserApiModel.BankAccountResponse>('/user/profile/account', prepareParams(data));
	return response.data.data as BankAccount;
}

export async function userGetCertificates(): Promise<UserCertificate[]> {
	const response = await v1.get<UserApiModel.UserCertificatesResponse>('/user/profile/certificate');
	return response.data.data as UserCertificate[];
}

export async function userCertificate(
	data: UserApiModel.CertificateRequest,
	action: 'post' | 'patch',
	kind?: string,
): Promise<UserCertificate> {
	const files = new FormData();
	const params: any = {};
	Object.keys(data).forEach((key) => {
		params[_.snakeCase(key)] = (data as any)[key];
	});
	Object.keys(params).forEach((key) => {
		if (['document'].includes(key)) {
			const value = params[key];
			files.append(key, value);
			delete params[key];
		}
	});
	const baseResponse = await v1.request<UserApiModel.UserCertificateResponse>({
		url: '/user/profile/certificate',
		method: action.toUpperCase(),
		data: { ...params, kind },
	});
	// @ts-ignore
	if (files.get('document') && baseResponse.status < 300) {
		const baseData = (baseResponse as any).data.data;
		files.append('data', JSON.stringify({ uuid: baseData.uuid, id: baseData.uuid }));
		console.log('POST CERT', params, files);
		try {
			const response = await v1.patch<UserApiModel.UserCertificateResponse>('/user/profile/certificate', files);
			return response.data.data as UserCertificate;
		} catch (error) {
			return baseResponse.data.data as UserCertificate;
		}
	}
	return baseResponse.data.data as UserCertificate;
}

export async function userDeleteCertificate(id: string): Promise<boolean> {
	const data = prepareParams({ uuid: id, id });
	const response = await v1.delete(`/user/profile/certificate`, { data });

	return response.data;
}

export async function userVerifyEmail(): Promise<any> {
	const response = await v1.put('/user/profile/verify_email');
	return response.data;
}

export async function userSendVerifyEmail(): Promise<void> {
	const response = await v1.post('/user/profile/verify_email');
	return response.data;
}
