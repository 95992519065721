import * as React from 'react';

//other deps
import _ from 'lodash';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//schemes
import * as schemes from '../../schemes';

//components
import * as Icons from 'app/components/Icons';

//types
import { RootState } from 'app/reducers';
import { EmptyCarActions } from 'app/actions';
import { SchemeProps } from 'app/containers/Builder/props';
import { EmptyCarBase, UserProfile } from 'app/models';

export interface FileWithPreview extends File {}

//helpers
import Builder from 'app/containers/Builder';

//images
const profileImage = require('src/assets/icons/profile.png');

//----------------------------------------------------------
// BaseCarForm
//----------------------------------------------------------
class BaseCarForm extends React.Component<BaseCarForm.Props, BaseCarForm.State> {
	public builder: Builder | null = null;

	public state: BaseCarForm.State = {
		form: {},
		validationReport: [],
	};

	constructor(props: BaseCarForm.Props) {
		super(props);

		this.applyDefaults = this.applyDefaults.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	public async componentDidMount() {
		const { builder, state } = this;

		if (builder && !state.scheme) {
			const scheme = _.cloneDeep(schemes.base);

			this.setState({ scheme: [...scheme] });

			await Promise.delay(50);
			this.applyDefaults();
		}
	}

	public componentDidUpdate(prevProps: BaseCarForm.Props) {
		const { carBase } = prevProps;

		if (!_.isEqual(carBase, this.props.carBase)) {
			this.applyDefaults();
		}
	}

	private applyDefaults() {
		const { builder } = this;

		if (builder) {
			const { formRefs } = builder;
			const allowed = ['ident', 'trailerIdent'];

			for (const key of allowed) {
				const ref = formRefs?.get(key);
				const value = (this.props?.carBase as any)?.[key] || null;

				if (ref && value) ref.updateValue(value);
			}
		}
	}

	private onUpdate(name: string, value: any) {
		const { form } = this.state;
		const { updateBaseData } = this.props;

		this.setState({
			form: {
				...form,
				[name]: value,
			},
		});

		if (updateBaseData) {
			updateBaseData({
				[name]: value,
			});
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	public render() {
		const { scheme } = this.state;
		const { profile } = this.props;

		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		const avatar = profile?.avatar ?? profileImage;

		return (
			<div className='car base form'>
				<div className='photos'>
					<div className='arrow'>
						<Icons.DottedArrowUp color='#6C6F88' />
					</div>

					<div className='driver'>
						<div className={'avatar ' + (!profile?.avatar ? 'empty' : '')}>
							<img src={avatar} />
						</div>

						<div className='fullname'>
							{profile?.lastName} {profile?.firstName[0]}.{profile?.middleName[0]}.
						</div>
					</div>
				</div>

				<div className='common'>
					<div className='header'>
						<div className='title'>
							<Localized id='transports-base-title' />
						</div>

						<div className='subtitle'>
							<Localized id='transports-base-description' />
						</div>
					</div>

					<div className='form'>
						<Builder {...builderProps} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ common, user }: RootState) => ({
	locales: common.bundlesLocales,
	profile: user.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateBaseData: EmptyCarActions.updateBaseData,
		},
		dispatch,
	);

export default connect<BaseCarForm.StateProps, BaseCarForm.DispatchProps, BaseCarForm.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(BaseCarForm);

namespace BaseCarForm {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
		profile?: UserProfile;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updateBaseData?: (params: Partial<EmptyCarBase>) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		carBase?: EmptyCarBase;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		validationReport: string[];
	}
}
