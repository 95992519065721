import React from 'react';
import styled from 'styled-components';

import { useDispatch } from 'react-redux';
import * as Icons from 'app/components/Icons';
import { Localized } from '@fluent/react';
import { SidebarType } from 'app/models';
import { CommonActions } from 'app/actions';

const Container = styled.div`
	display: flex;
`;

/* eslint-disable react/display-name */
export const ActionsCell = ({ prices, role, from, index }: any) => {
	const buyers = prices ? prices[index] : undefined;

	// const { form } = this.state;
	const dispatch = useDispatch();
	const toggleSidebar = () => {};
	const onContractClick = () => {};

	const onOrderClick = async () => {
		if (buyers && role !== 'driver') {
			const params = {
				rate: buyers.transportTotal,
				product: buyers.productName,
				distance: buyers.distance,
				productId: buyers.cropId,
				to: buyers.basisAddress,
				from,
			};

			dispatch(
				CommonActions.toggleSidebar({
					type: SidebarType.OrderCreate,
					value: true,
					params,
				}),
			);
		}
	};

	return (
		<Container>
			<div data-tip data-for='order' title={!from ? 'Необходимо выбрать адрес для составления заявки' : undefined}>
				<button disabled={!from} key='make-order' className={'table__action make-order'} onClick={onOrderClick}>
					<Icons.ExecutorCar color={from ? '#6C6F88' : '#c7c8d1'} />
					<div className='label'>
						<Localized id='prices-row-order-label' />
					</div>
				</button>
			</div>
			<div data-tip data-for='in-dev' title='Функция в разработке'>
				<button disabled key='show-contract' className={'table__action show-contract'} onClick={onContractClick}>
					<Icons.Contract color='#c7c8d1' />
					<div className='label'>
						<Localized id='prices-row-contract-label' />
					</div>
				</button>
			</div>
		</Container>
	);
};
/* eslint-enable react/display-name */
