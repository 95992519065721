import React from 'react';

export const DocumentNew: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='22' height='24' viewBox='0 0 22 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M10.4999 24C11.0522 24 11.4999 23.5523 11.4999 23C11.4999 22.4477 11.0522 22 10.4999 22V24ZM3.5 23L3.5 24H3.5V23ZM3.5 1V2V1ZM15.5 1V1.07288e-06V1ZM17.5 3H16.5H17.5ZM17.5 23L16.7929 23.7071C16.9804 23.8946 17.2348 24 17.5 24C17.7652 24 18.0196 23.8946 18.2071 23.7071L17.5 23ZM1.5 21H0.5H1.5ZM1.5 3L0.500002 3L1.5 3ZM5.5 5H4.5V7H5.5V5ZM7.5 7H8.5V5H7.5V7ZM5.5 9H4.5L4.5 11H5.5V9ZM13.5 11H14.5V9H13.5V11ZM5.5 13H4.5L4.5 15H5.5V13ZM11.5 15H12.5V13H11.5V15ZM15.207 19.2929C14.8165 18.9024 14.1833 18.9024 13.7928 19.2929C13.4023 19.6834 13.4023 20.3166 13.7928 20.7071L15.207 19.2929ZM21.2071 20.7071C21.5976 20.3166 21.5976 19.6834 21.207 19.2929C20.8165 18.9024 20.1833 18.9024 19.7928 19.2929L21.2071 20.7071ZM10.4999 22H3.5V24H10.4999V22ZM3.5 2L15.5 2V1.07288e-06L3.5 0V2ZM16.5 3V23H18.5V3H16.5ZM2.5 21L2.5 3L0.500002 3L0.5 21H2.5ZM15.5 2C16.0523 2 16.5 2.44772 16.5 3H18.5C18.5 1.34315 17.1569 1.19209e-06 15.5 1.07288e-06V2ZM3.5 0C1.84315 -1.19209e-07 0.500002 1.34314 0.500002 3L2.5 3C2.5 2.44771 2.94772 2 3.5 2V0ZM3.5 22C2.94771 22 2.5 21.5523 2.5 21H0.5C0.5 22.6569 1.84314 24 3.5 24L3.5 22ZM5.5 7H7.5V5H5.5V7ZM5.5 11L13.5 11V9L5.5 9V11ZM5.5 15L11.5 15V13L5.5 13V15ZM18.2071 22.2929L15.207 19.2929L13.7928 20.7071L16.7929 23.7071L18.2071 22.2929ZM18.2071 23.7071L21.2071 20.7071L19.7928 19.2929L16.7929 22.2929L18.2071 23.7071Z'
			fill={color}
		/>
	</svg>
);

export default DocumentNew;
