export const mapMarkers = (markers: any) => {
	const result: any[] = [];

	markers.forEach((point: any) => {
		if (point?.id && point?.location?.lng && point?.location?.lat) {
			result.push({
				type: 'Feature',
				properties: {
					cluster: false,
					id: point.id,
					data: point.data,
					location: point.location,
				},
				geometry: {
					type: 'Point',
					coordinates: [parseFloat(point.location.lng), parseFloat(point.location.lat)],
				},
			});
		}
	});

	return result;
};

export const mapClusterChildrenStatuses = (clusterLeaves: any) =>
	clusterLeaves.map((item: any) => item?.properties?.data.statusInBid);

export const getIdsFromClusters = (clusters: any, supercluster: any) => {
	const items = clusters.reduce((acc: any = [], cluster: any) => {
		const { cluster: isCluster, id } = cluster.properties;

		if (!isCluster) {
			return [...acc, id];
		}

		const leaves = supercluster?.getLeaves(cluster.id, 'Infinity');

		return [...acc, ...leaves.map((leave: any) => leave.properties.id)];
	}, []);

	return items;
};
