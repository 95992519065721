import { Company } from './company';
import { UserContact } from './user';

export const TENDER_PROGRESS = {
	CONFIRMED: 'confirmed',
	DOING: 'doing',
	FINISHED: 'finished',
	DONE: 'done',
	CANCELED: 'canceled',
	NEW: 'new',
} as const;

export const TENDER_CAPABILITIES = {
	DELETE: 'delete',
	FINISH: 'finish',
	EDIT: 'edit',
	SUSPEND: 'suspend',
	UNSUSPEND: 'unsuspend',
	CREATE_OFFER: 'create_offer',
	CHANGE_OFFER: 'change_offer',
	SEND_QUOTE: 'send_quote',
	EDIT_QUOTE: 'edit_quote',
} as const;

export const BID_CAPABILITES = {
	DELETE: 'delete',
	EDIT: 'edit',
	CREATE_OFFER: 'create_offer',
	CHANGE_OFFER: 'change_offer',
	SUSPEND: 'suspend',
	UNSUSPEND: 'unsuspend',
};

export type BidCapabilitiesType = (typeof BID_CAPABILITES)[keyof typeof BID_CAPABILITES];

export type TenderCapabilitiesType = (typeof TENDER_CAPABILITIES)[keyof typeof TENDER_CAPABILITIES];

type TenderProgressType = (typeof TENDER_PROGRESS)[keyof typeof TENDER_PROGRESS];

// BID object
export interface Bid {
	idle: {
		from: number;
		loss: number;
		price: number;
		lossInPercent: boolean;
	};
	limits: any;
	status: Bid.Status;
	tender: Bid.Tender;
	comment: string;
	contact: Bid.Contact;
	payment: Bid.Payment;
	customer: Bid.Customer;
	direction: Bid.Direction;
	dispatcher: Bid.Customer;
	deliveryTime: Bid.DeliveryTime;
	capabilities: TenderCapabilitiesType[];
	progress: string;
	kind: 'tender' | 'bid';
	uuid: 'string';
	id: string;
	createdAt: 'string';
	onlyVerifiedDrivers: true;
	isPlatformBid: boolean;
	weightFree: number;
	weightLocked: number;
	isSuspended: boolean;
	publisher: Bid.Publisher;
	tenderOffers: Bid.TenderOffer[];
	cargo: Bid.Cargo;
}

export interface Cursor {
	previous: string | null;
	next: string | null;
}

export interface BidsCollection {
	cursor: Cursor;
	loading: boolean;
	list: Bid[];
}

export interface BidsFilter {
	minDistance?: number;
	maxDistance?: number;

	minPrice?: number;
	maxPrice?: number;

	minPriceKm?: number;
	maxPriceKm?: number;

	minWeight?: number;
	maxWeight?: number;

	regionUnloading?: string;
	regionLoading?: string;
	cargoType?: string[];
	maxDeliveryFrom?: string;
	minDeliveryFrom?: string;
	min_delivery_from?: string;
	max_delivery_from?: string;
	kind?: 'bid' | 'tender';
}

export namespace Bid {
	// For List of BIDs
	export enum BidType {
		Archive = 'archive',
		All = 'all',
		New = 'new',
		My = 'my',
	}

	export interface Customer {
		name: string;
		uuid: string;
	}
	export interface Contact {
		id: string;
		firstName: string;
		lastName: string;
		middleName: string;
		phone: string;
		uuid: string;
		avatar: string;
		photo: string;
		isAutoCreated: boolean;
	}
	export interface Status {
		newRequests: number;
		confirmed: number;
		requests: number;
		declined: number;
		previews: number;
		views: number;
	}
	export interface Publisher {
		company: {
			uuid: string;
			name: string;
		};
		user: {
			id: string;
			avatar: string;
			photo: string;
			firstName: string;
			lastName: string;
			middleName: string;
			companyName: string;
			legalType: string;
			interestedInDigitalSignature: boolean;
		};
	}
	export interface Tender {
		from: string;
		to: string;
		minWeight: number;
		dailyWeight: number;
		suggestedPrice: number;
		isActive: boolean;
		finalizeType: 'manual_on_expire' | 'auto_on_expire';
		currency: {
			name: string;
			code: string;
			symbol: string;
		};
		status: TenderProgressType;
		capabilities: TenderCapabilitiesType[];
	}

	export interface TenderOffer {
		id: string;
		uuid: string;
		company: Company;
		acceptedPrice: number | null;
		offeredPrice: number;
		acceptedWeight: number | null;
		offeredWeight: number;
		status: string;
		createdAt: string;
		modifiedAt: string;
		capabilities: string[];
		preAcceptedWeight: number;
		preApproved: boolean;
		childBid: any;
	}
	export interface IDLE {
		lossInPercent: boolean;
		price: number;
		loss: number;
		from: number;
	}

	export namespace LossType {
		export const Kg = false;
		export const Pc = true;
	}

	export interface Address {
		isEstablishment: boolean;
		isLocality: boolean;
		address: string;
		region: string;
		name: string;
		id: string;

		location: {
			latitude: number;
			longitude: number;
		};
	}

	export interface Direction {
		autoDistance: number;
		distance: number;
		isChanged: true;

		from: Address;
		to: Address;
	}

	export interface DeliveryTime {
		from: string;
		to: string;
	}

	export interface Cargo {
		numberOfCars: number;
		weight: number;
		volume: number;
		price: number;
		type: {
			specificWeight: number;
			category: string;
			name: string;
			type: string;
			uuid: string;
			addition: {};
		};
	}

	export interface Payment {
		isOverload: boolean;
		isBargain: boolean;
		isPrepaid: boolean;
		isPerKm: boolean;
		isCash: boolean;
		isPricePerKm: boolean;
		hasVat: boolean;

		pricePerKm: number;
		price: number;

		type: {
			category: string;
			type: string;
			uuid: string;
			name: string;
			addition: {};
		};

		currency: {
			name: string;
			code: string;
			symbol: string;
		};
	}
}

export interface BidDetails {
	cargo: Bid.Cargo;
	idle: Bid.IDLE;
	weightFree: number;

	limits: {
		loadWeights: number;
		dimensions: null;

		loadingType: {
			name: string;
			type: string;
			uuid: string;
		};

		trailerType: null | string;
		workingTime: null | string;
		transportType: any[];
	};

	deliveryTime: Bid.DeliveryTime;
	direction: Bid.Direction;
	contact: UserContact;
	payment: Bid.Payment;
	status: Bid.Status;
	comment: string;

	onlyVerifiedDrivers: boolean;
	isPlatformBid: boolean;
	offer: string | null;
	createdAt: string;
	uuid: string;
	id: string;

	publisher?: {
		user: UserContact | any;
	};

	isSuspended: boolean;
	capabilities?: string[];
	tariff: any;
}

export type BidList = {
	cursor: Cursor;
	list: Bid[];
};

export const CAPABILITIES = {
	CONFIRM: 'confirm',
	VERIFY: 'verify',
	DECLINE: 'decline',
} as const;
