import * as React from 'react';

//other deps
import _ from 'lodash';
import Dropzone from 'react-dropzone';
import { Localized } from '@fluent/react';
import Loader from 'react-loader-spinner';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//components
import * as Icons from 'app/components/Icons';
import { UserActions } from 'app/actions';
import { UserCertificate } from 'app/models';
import { userCertificate } from 'app/api';
import Certificate from './certificate';

//types
import { RootState } from 'app/reducers';

interface UFile extends File {
	uniqueId: string;
	preview: string;
}

//----------------------------------------------------------
// UserDocumentsScan
//----------------------------------------------------------
export class UserDocumentsScan extends React.Component<UserDocumentsScan.Props, UserDocumentsScan.State> {
	public state: UserDocumentsScan.State = {
		uploadQueue: {},
	};

	constructor(props: UserDocumentsScan.Props) {
		super(props);

		this.getCertificatesList = this.getCertificatesList.bind(this);
		this.uploadCertificate = this.uploadCertificate.bind(this);
		this.getUploadingList = this.getUploadingList.bind(this);
		this.onDrop = this.onDrop.bind(this);
	}

	private onDrop(accepted: any[]) {
		const files: { [key: string]: UFile } = {};
		const { uploadQueue } = this.state;

		(accepted as UFile[]).forEach((file) => {
			const id = _.uniqueId();
			file.uniqueId = id;
			files[id] = file;

			this.uploadCertificate(file);
		});

		this.setState({
			uploadQueue: {
				...uploadQueue,
				...files,
			},
		});
	}

	private async uploadCertificate(document: UFile) {
		const { addUserCertificate, sessionToken } = this.props;

		try {
			if (addUserCertificate && sessionToken) {
				const certificate = await userCertificate({ document }, 'post');
				addUserCertificate(certificate);
			}
		} catch (error) {
			console.error(error);
		}

		const { uploadQueue } = this.state;
		delete uploadQueue[document.uniqueId];

		this.setState({
			uploadQueue: {
				...uploadQueue,
			},
		});
	}

	private getCertificatesList() {
		const certificates = this.props.certificates ? this.props.certificates : {};
		const { deleteUserCertificate, sessionToken, editable } = this.props;

		return Object.keys(certificates).map((uuid) => {
			const certificate = certificates[uuid];

			const _delete = () => {
				if (deleteUserCertificate && sessionToken) {
					deleteUserCertificate(uuid, sessionToken);
				}
			};
			return (
				<Certificate
					editable={editable}
					key={`certificate-${uuid}`}
					document={certificate.document}
					onDelete={_delete}
					uuid={uuid}
				/>
			);
		});
	}

	private getUploadingList() {
		const { uploadQueue } = this.state;

		if (_.isEmpty(uploadQueue)) {
			return (
				<div key='action-drop' className='action'>
					<Localized id='builder-picker-action-upload' />
				</div>
			);
		} else {
			return Object.keys(uploadQueue).map((id) => {
				const file = uploadQueue[id];

				return (
					<div key={`uploading-${file.uniqueId}`} className='preview uploading'>
						<div className='overlay'>
							<Loader color='#2196F3' height={34} width={34} type='Oval' />
						</div>
						<img src={file.preview} alt='' />
					</div>
				);
			});
		}
	}

	public render() {
		const { uploadQueue } = this.state;
		const { editable } = this.props;
		const { onDrop } = this;

		const props = {
			accept: ['image/png', 'image/jpeg'] as any,
			className: 'drop-container',
			activeClassName: 'active',
			onDrop,
		};

		const content = [
			<div key='scans-title' className={editable ? 'header' : 'label-text'}>
				<Localized id='profile-certificates-header' />
			</div>,

			<div key='scans-certificates' className='certificates'>
				{this.getCertificatesList()}
			</div>,

			!editable ? null : (
				<div key='scans-picker' className='picker'>
					<Dropzone {...props}>
						{({ isDragActive, isDragAccept }) => {
							const extendedClass = !isDragActive ? '' : isDragAccept ? 'accepted' : 'rejected';
							const content = isDragActive
								? isDragAccept
									? [
											<Icons.Upload key='accept-icon' color='#64C8EB' />,
											<div key='accept-title' className='title'>
												<Localized id='profile-certificates-add' />
											</div>,
									  ]
									: [
											<Icons.Upload key='reject-icon' color='#ebd407' />,
											<div key='reject-title' className='title'>
												<Localized id='builder-picker-error-invalid-type' />
											</div>,
									  ]
								: [
										<div key='label-drop' className='label'>
											<Localized id='profile-certificates-add' />
											{!_.isEmpty(uploadQueue) ? null : <Icons.Upload key='accept-icon' color='#64C8EB' />}
										</div>,

										<div key='uploadings-list-items' className='items'>
											{this.getUploadingList()}
										</div>,
								  ];

							return <div className={'content ' + extendedClass}>{content}</div>;
						}}
					</Dropzone>
				</div>
			),
		];

		return editable ? <div className='scans'>{content}</div> : <>{content}</>;
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	certificates: user && user.profile && user.profile.certificates,
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			deleteUserCertificate: UserActions.deleteUserCertificate,
			addUserCertificate: UserActions.addUserCertificate,
		},
		dispatch,
	);

export default connect<UserDocumentsScan.StateProps, UserDocumentsScan.DispatchProps, UserDocumentsScan.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(UserDocumentsScan);

export namespace UserDocumentsScan {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;

		certificates?: {
			[uuid: string]: UserCertificate;
		};
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		deleteUserCertificate?: (id: string, token: string) => void;
		addUserCertificate?: (certificate: UserCertificate) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		editable?: boolean;
	}

	// Main component state
	export interface State {
		uploadQueue: {
			[id: string]: UFile;
		};
	}
}
