import { Action, handleActions } from 'redux-actions';
import { SET_CUSTOMER_OFFER_DETAILS_LOADING, SET_CUSTOMER_OFFER_DETAILS, TransportDetailsPayload } from 'app/actions';

type State = {
	data: TransportDetailsPayload | null;
	loading: boolean;
};

export const detailsReducer = handleActions<State, any>(
	{
		[SET_CUSTOMER_OFFER_DETAILS]: (state: State, { payload }: Action<TransportDetailsPayload>) =>
			payload
				? {
						data: payload,
						loading: false,
				  }
				: state,
		[SET_CUSTOMER_OFFER_DETAILS_LOADING]: (state: State, { payload }: Action<boolean>) =>
			payload
				? {
						...state,
						loading: payload,
				  }
				: state,
	},
	{
		data: null,
		loading: false,
	},
);
