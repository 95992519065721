import React, { useCallback, useEffect, useState, useRef } from 'react';
import Posed from 'react-pose';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import FormBuilder from 'app/containers/Builder';
import { RootState } from 'app/reducers';
import { BidActions } from 'app/actions';
import { SchemeProps, BasicTypes } from 'app/containers/Builder/props';
import { BidsFilter, Region } from 'app/models';
import SCHEME from './scheme';

const clearIcon = require('../../../assets/icons/clear_all.png');

const Container = Posed.div({
	opened: {
		applyAtEnd: { overflow: 'visible' },
		overflow: 'visible',
		height: 'auto',
	},
	closed: {
		applyAtStart: { overflow: 'hidden' },
		applyAtEnd: { overflow: 'hidden' },
		height: 0,
	},
});

function prepareSchema(schema: SchemeProps.Form, regions: Region[] = [], cargos: any[] = []) {
	return schema.map((entry) => {
		if (entry.type === BasicTypes.MultiSelect && entry.name === 'cargoType') {
			const choices = cargos.map(({ name, id }) => ({ label: name, value: id }));
			return {
				...entry,
				choices,
			};
		}
		if (entry.type === BasicTypes.Select && ['regionLoading', 'regionUnloading'].includes(entry.name)) {
			const choices = regions.map(({ name }) => ({ label: name, value: name }));

			return {
				...entry,
				choices,
			};
		}
		return entry;
	});
}

export const OrdersFilter = () => {
	const dispatch = useDispatch();
	const applyFilters = useCallback((v: BidsFilter) => dispatch(BidActions.applyFilters(v)), [dispatch]);
	// const filtersOpened = useSelector((state: RootState) => state.bid.filtersOpened);
	const filtersOpened = true;
	const filters = useSelector((state: RootState) => state.bid.filters);
	const regions = useSelector((state: RootState) => state.common.regions);
	const cargos = useSelector((state: RootState) => state.common.cargos);
	const builder = useRef<FormBuilder>(null);
	const [schema, setSchema] = useState<SchemeProps.Form>(prepareSchema(SCHEME, regions, cargos));

	useEffect(() => {
		setSchema(prepareSchema(SCHEME, regions, cargos));
	}, [regions, cargos]);

	const reset = useCallback(() => {
		if (builder.current && applyFilters) {
			applyFilters({});
			builder.current.reset();
		}
	}, [builder, applyFilters]);

	const onUpdate = useCallback(
		(name: string, value: any) => {
			const keys = schema.map((entry) => (entry as any).name);
			if (keys.includes(name) && applyFilters) {
				const distance = {
					[`min${_.capitalize(name)}`]: value?.min,
					[`max${_.capitalize(name)}`]: value?.max,
				};
				const values = name === 'distance' ? distance : { [name]: value };
				applyFilters(_.pickBy({ ...filters, ...values }, _.identity));
			}
		},
		[filters, applyFilters],
	);

	return (
		<Container pose={filtersOpened ? 'opened' : 'closed'} initialPose='closed' className='filters' withParent={false}>
			<div className='container'>
				<FormBuilder ref={builder} scheme={schema} onUpdate={onUpdate} />
				<button className='clear icon' onClick={reset}>
					<img src={clearIcon} />
					<div className='text'>Сбросить</div>
				</button>
			</div>
		</Container>
	);
};
