import React from 'react';
import { Typography } from 'app/sharedComponents/Typography';
import { CellColContainer } from './styles';

export const CargoCell = ({ value, row }: any) => {
	const { productProtein } = row;
	return (
		<CellColContainer>
			<Typography variant='body3'>{value}</Typography>
			{productProtein && (
				<Typography variant='body4' color='gray'>
					{`${productProtein}%`}
				</Typography>
			)}
		</CellColContainer>
	);
};
