import React, { useState, useEffect, useCallback } from 'react';

//other deps
import { isEqual } from 'lodash';
import { Localized } from '@fluent/react';
import Scrollbars from 'react-custom-scrollbars';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//screen parts
import Empty from './components/Empty';
import OpenedCar from './components/OpenedCar';
import NoSelectedCar from './components/NoSelectedCar';

//components
import CarsList from './components/CarsList';
import Modal from 'app/sharedComponents/Modal';
import LoadingIndicator from 'app/sharedComponents/LoadingIndicator';
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
import { TabLayout, TabLayoutSidebar, TabLayoutContent } from 'app/commonPatterns/TabLayout';
import { MessagesBig as IconMessagesBig, DeleteBig as IconDeleteBig } from 'app/components/Icons';

//types
import { RootState } from 'app/reducers';
import { DriverActions, EmptyCarActions } from 'app/actions';
import { BidApiModel } from 'app/api/types';
import { DriverCar, UserProfile, UserContact } from 'app/models';

//helpers
import { usePrevious } from 'app/hooks';
import { getUrlParam } from 'app/utils';
import { getBasicContact } from './cars.helpers';
import CarItem from './components/CarItem';

//----------------------------------------------------------
// Cars
//----------------------------------------------------------
const Cars: React.FC<Cars.Props> = (props: Cars.Props) => {
	//data
	const {
		cars = {},
		emptyCar,
		loadingState,
		openedCarId,
		profile,
		sessionToken,
		transportTypes,
		addEmptyCar,
		openEmptyCar,
		closeEmptyCar,
		deleteDriverTransport,
		getCarInfoCertificates,
		getCarInfoInsurances,
		getDriverTransportsList,
		setOpened,
	} = props;

	const modalCarSendedState = useState(false);
	const modalCarDeleteConfirmState = useState<any>(false);
	const isHaveCars: boolean = cars && Object.keys(cars).length > 0;
	const isCarDataOpened = !!openedCarId;

	//helpers
	const prevCars = usePrevious(cars);

	//callbacks
	const handleCarClick = (carId: string) => {
		closeEmptyCar && closeEmptyCar();

		modalCarSendedState[1](false);
		setOpened && setOpened(carId);

		const car = cars[carId];

		if (carId !== DriverActions.NEW_TRANSPORT_ID) {
			if (car && !Object.keys(car.certificates).length) {
				sessionToken && getCarInfoCertificates && getCarInfoCertificates(carId);
			}

			if (car && !Object.keys(car.insurances).length) {
				sessionToken && getCarInfoInsurances && getCarInfoInsurances(carId);
			}
		}
	};

	const handleCarDelete = () => {
		const [carId, setModalState] = modalCarDeleteConfirmState;

		if (openedCarId === carId) {
			setOpened && setOpened('');
		}

		deleteDriverTransport && sessionToken && deleteDriverTransport(carId);
		setModalState(false);
	};

	const handleCreateTransport = () => {
		setOpened && setOpened('');
		transportTypes && sessionToken && addEmptyCar && addEmptyCar();
	};

	const handleSendCarModalClose = () => {
		props.openedCarId && handleCarClick(props.openedCarId);
		modalCarSendedState[1](false);
	};

	//effects
	useEffect(() => {
		const fetchTransports = () => {
			if (!sessionToken || !getDriverTransportsList || !profile) return;

			const basicContact = getBasicContact(profile);
			getDriverTransportsList(basicContact);
		};

		fetchTransports();

		return () => setOpened && setOpened('');
	}, []);

	useEffect(() => {
		if (cars && Object.keys(cars).length && !isEqual(prevCars, cars)) {
			const urlTransportId = getUrlParam('transportId');

			if (urlTransportId && openedCarId !== urlTransportId) {
				handleCarClick(urlTransportId);
				getCarInfoCertificates && getCarInfoCertificates(urlTransportId);
				getCarInfoInsurances && getCarInfoInsurances(urlTransportId);
			}
		}
	}, [cars]);

	//renders
	const _renderTransportInfoPanel = useCallback(() => {
		if (!isHaveCars && !emptyCar?.isOpen) return <Empty />;
		else if (emptyCar?.isOpen || (isCarDataOpened && cars?.[openedCarId as string]))
			return (
				<OpenedCar
					emptyCar={emptyCar}
					car={cars[openedCarId as string]}
					handleModal={() => modalCarSendedState[1](true)}
				/>
			);

		return <NoSelectedCar />;
	}, [isHaveCars, isCarDataOpened, cars, modalCarSendedState, openedCarId, emptyCar?.isOpen]);

	return (
		<TabLayout>
			<TabLayoutSidebar className='nopadding sidebar-items-list'>
				<Scrollbars>
					{loadingState ? (
						<LoadingIndicator />
					) : (
						<>
							<CarsList
								opened={openedCarId}
								profile={profile}
								setOpened={handleCarClick}
								cars={cars}
								addNewTransport={handleCreateTransport}
								deleteDriverTransport={modalCarDeleteConfirmState[1]}
								sessionToken={sessionToken}
								onClick={handleCarClick}
							/>

							{emptyCar?.data && (
								<CarItem
									car={{
										uuid: DriverActions.NEW_TRANSPORT_ID,
										//@ts-ignore
										transport: {},
									}}
									isOpened={emptyCar?.isOpen}
									onClick={() => {
										setOpened && setOpened('');
										openEmptyCar && openEmptyCar();
									}}
								/>
							)}
						</>
					)}
				</Scrollbars>
			</TabLayoutSidebar>

			<TabLayoutContent className='page-content'>
				<Modal
					value={modalCarSendedState}
					width={500}
					height={300}
					actions={[
						<Button key='button-car-sended-okay' onClick={handleSendCarModalClose} styleType={ButtonStyleTypes.PRIMARY}>
							<Localized id='profile-action-show' />
						</Button>,
					]}
				>
					<div>
						<IconMessagesBig />
					</div>
					<Localized id='profile-docs-sended-modal' />
				</Modal>

				<Modal
					value={modalCarDeleteConfirmState}
					width={500}
					height={300}
					actions={[
						<Button onClick={handleCarDelete} key='button-car-delete-confirm-yes' styleType={ButtonStyleTypes.DEFAULT}>
							<Localized id='yes' />
						</Button>,
						<Button
							onClick={() => modalCarDeleteConfirmState[1](false)}
							key='button-car-delete-confirm-no'
							styleType={ButtonStyleTypes.PRIMARY}
						>
							<Localized id='not' />
						</Button>,
					]}
				>
					<div>
						<IconDeleteBig />
					</div>
					<div>Вы уверены, что хотите удалить этот транспорт?</div>
				</Modal>

				{_renderTransportInfoPanel()}
			</TabLayoutContent>
		</TabLayout>
	);
};

const mapStateToProps = ({ user, driver, common }: RootState) => ({
	cars: driver.cars,
	emptyCar: driver.emptyCar,
	loadingState: driver.loadingState,
	openedCarId: driver.carOpened,
	profile: user.profile,
	sessionToken: user.sessionToken,
	transportTypes: common.transports,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			addNewTransport: DriverActions.addNewTransport,
			deleteDriverTransport: DriverActions.deleteDriverTransport,
			getCarInfoCertificates: DriverActions.getCarInfoCertificates,
			getCarInfoInsurances: DriverActions.getCarInfoInsurances,
			getDriverTransportsList: DriverActions.getDriverTransportsList,
			setOpened: DriverActions.setOpenedTransport,
			addEmptyCar: EmptyCarActions.addEmptyCar,
			closeEmptyCar: EmptyCarActions.closeEmptyCar,
			openEmptyCar: EmptyCarActions.openEmptyCar,
		},
		dispatch,
	);

export default connect<Cars.StateProps, Cars.DispatchProps>(mapStateToProps, mapDispatchToProps)(Cars);

export namespace Cars {
	export type Props = StateProps & DispatchProps & ExternalProps;

	export interface StateProps {
		cars?: { [uuid: string]: DriverCar };
		emptyCar?: RootState['driver']['emptyCar'];
		loadingState?: boolean;
		openedCarId?: string;
		profile?: UserProfile;
		sessionToken?: string;
		transportTypes?: { [name: string]: BidApiModel.CommonMeta };
	}

	export interface DispatchProps {
		addNewTransport?: (contact: UserContact) => void;
		deleteDriverTransport?: (uuid: string) => void;
		getCarInfoCertificates?: (uuid: string) => void;
		getCarInfoInsurances?: (uuid: string) => void;
		getDriverTransportsList?: (contact: UserContact) => void;
		setOpened?: (carId?: string) => void;
		addEmptyCar?: () => void;
		closeEmptyCar?: () => void;
		openEmptyCar?: () => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}
}
