import { executorGetList, vacantExecutorGetList, getDirection } from 'app/api';
import { Executor, Location as RLocation, VacantExecutor } from 'app/models';
import { decode as decodePolyline } from '@mapbox/polyline';
// import transportService from 'app/services/transport';
import { ExecutorApiModel } from 'app/api/types';
import { createAction } from 'redux-actions';
import { Dispatch } from 'redux';

export namespace ExecutorActions {
	export enum Action {
		RESET_ALL_VACANT_HOVERED = 'EXECUTOR_RESET_ALL_VACANT_HOVERED',
		TOGGLE_SHOW_FILTER = 'EXECUTOR_TOGGLE_SHOW_FILTER',
		RESET_ALL_HOVERED = 'EXECUTOR_RESET_ALL_HOVERED',
		SET_VISIBLE_ZONE = 'EXECUTOR_SET_VISIBLE_ZONE',
		UPSERT_DIRECTION = 'EXECUTOR_UPSERT_DIRECTION',
		EXECUTOR_SET_LOADING = 'EXECUTOR_SET_LOADING',
		TOGGLE_SELECTED = 'EXECUTOR_TOGGLE_SELECTED',
		UPSERT_LOCATION = 'EXECUTOR_UPSERT_LOCATION',
		TOGGLE_HOVERED = 'EXECUTOR_TOGGLE_HOVERED',
		TOGGLE_OPENED = 'EXECUTOR_TOGGLE_OPENED',
		TOGGLE_TRACKS = 'EXECUTOR_TOGGLE_TRACKS',
		TOGGLE_ROADS = 'EXECUTOR_TOGGLE_ROADS',
		ADD_EXECUTOR = 'ADD_EXECUTOR',
		CLEAR = 'EXECUTOR_LIST_CLEAR',

		// Vacant
		SET_VACANT_VISIBLE_ZONE = 'EXECUTOR_SET_VACANT_VISIBLE_ZONE',
		UPSERT_VACANT_LOCATION = 'EXECUTOR_UPSERT_VACANT_LOCATION',
		TOGGLE_VACANT_HOVERED = 'EXECUTOR_TOGGLE_VACANT_HOVERED',
		TOGGLE_VACANT_OPENED = 'EXECUTOR_TOGGLE_VACANT_OPENED',
		ADD_VACANT = 'EXECUTOR_ADD_VACANT',
	}

	export namespace Payload {
		export enum LineType {
			Tracks = 'Tracks',
			Roads = 'Roads',
		}

		export interface Toggle {
			value: boolean;
			id: string;
		}

		export interface ToggleShowLine {
			value?: boolean;
			type: LineType;
		}

		export interface Location {
			location: RLocation;
			id: string;
		}

		export interface Direction {
			direction: Array<[number, number]>;
			id: string;
		}
	}

	export const toggleSelected = createAction<Payload.Toggle | Payload.Toggle[]>(Action.TOGGLE_SELECTED);
	export const toggleTracks = createAction<Payload.Toggle | Payload.Toggle[]>(Action.TOGGLE_TRACKS);
	export const toggleRoads = createAction<Payload.Toggle | Payload.Toggle[]>(Action.TOGGLE_ROADS);
	export const toggleHovered = createAction<Payload.Toggle | Payload.Toggle[]>(Action.TOGGLE_HOVERED);
	export const toggleShowFilter = createAction<Payload.ToggleShowLine>(Action.TOGGLE_SHOW_FILTER);
	export const upsertDirection = createAction<Payload.Direction>(Action.UPSERT_DIRECTION);
	export const upsertLocation = createAction<Payload.Location>(Action.UPSERT_LOCATION);
	export const addExecutor = createAction<Executor | Executor[]>(Action.ADD_EXECUTOR);
	export const setVisibleZone = createAction<number[][]>(Action.SET_VISIBLE_ZONE);
	export const toggleOpened = createAction<Payload.Toggle>(Action.TOGGLE_OPENED);
	export const setLoading = createAction<boolean>(Action.EXECUTOR_SET_LOADING);
	export const resetAllVacantHovered = createAction(Action.RESET_ALL_VACANT_HOVERED);
	export const resetAllHovered = createAction(Action.RESET_ALL_HOVERED);
	export const clear = createAction(Action.CLEAR);

	// Vacant
	export const toggleVacantHovered = createAction<Payload.Toggle | Payload.Toggle[]>(Action.TOGGLE_VACANT_HOVERED);
	export const addVacantTransport = createAction<VacantExecutor | VacantExecutor[]>(Action.ADD_VACANT);
	export const upsertVacantLocation = createAction<Payload.Location>(Action.UPSERT_VACANT_LOCATION);
	export const setVacantVisibleZone = createAction<number[][]>(Action.SET_VACANT_VISIBLE_ZONE);
	export const toggleVacantOpened = createAction<Payload.Toggle>(Action.TOGGLE_VACANT_OPENED);

	export const getDirectionRoute =
		(
			id: string,

			from: RLocation,
			to: RLocation,
		) =>
		async (dispatch: Dispatch) => {
			try {
				const {
					routes: [route],
				} = await getDirection(from, to);
				const direction = decodePolyline(route.geometry, 5).map((c) => {
					return c.reverse() as [number, number];
				});

				dispatch(upsertDirection({ id, direction }));
			} catch (error) {
				// @todo: handle if need
			}
		};

	export const getExecutors = (params: ExecutorApiModel.ListRequest) => async (dispatch: Dispatch) => {
		// return Promise.resolve();
		try {
			// await dispatch(setLoading(true))
			const list = await executorGetList(params);

			dispatch(addExecutor(list));

			// realtime subscribe to transport positions
			// list.forEach(({ transport }) => transportService.subscribe('transport', transport.id));

			// await dispatch(setLoading(false))

			// default order directions
			const orders: { [id: string]: { from: RLocation; to: RLocation } } = {};
			list.forEach(
				({
					bid: {
						id,
						direction: { from, to },
					},
				}) =>
					(orders[id] = {
						from: from.location,
						to: to.location,
					}),
			);

			// Load directions polyline
			await Promise.all(
				Object.keys(orders).map((id) => {
					const { from, to } = orders[id];
					return dispatch(getDirectionRoute(id, from, to) as any);
				}),
			);
		} catch (error) {
			// @TODO: handle it future
		}
	};

	export const getVacantExecutors = (params: ExecutorApiModel.VacantListRequest) => async (dispatch: Dispatch) => {
		return Promise.resolve();
		try {
			const list = await vacantExecutorGetList(params);
			dispatch(addVacantTransport(list));

			// realtime subscribe to transport positions
			// list.forEach(({ transport }) => transportService.subscribe('transport', transport.id));
		} catch (error) {
			// @TODO: handle it future
		}
	};
}
