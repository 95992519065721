/** file was generated by icon-map-generator.js */
import Approve from './Approve';
import Check from './Check';
import Delete from './Delete';
import Document from './Document';
import DocumentNew from './DocumentNew';
import Download from './Download';
import Edit from './Edit';
import FullScreen from './FullScreen';
import Info from './Info';
import Mail from './Mail';
import Pencil from './Pencil';
import Reply from './Reply';
import Views from './Views';

export const IconMappings = {
	'approve': Approve,
	'check': Check,
	'delete': Delete,
	'document': Document,
	'document-new': DocumentNew,
	'download': Download,
	'edit': Edit,
	'full-screen': FullScreen,
	'info': Info,
	'mail': Mail,
	'pencil': Pencil,
	'reply': Reply,
	'views': Views,
} as const;
