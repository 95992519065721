import Posed from 'react-pose';

export const FormItem = Posed.div({
	closed: {
		x: '120%',

		applyAtEnd: {
			position: 'absolute',
			display: 'none',
		},

		transition: {
			ease: 'easeOut',
			duration: 200,
		},
	},

	opened: {
		x: '0%',

		applyAtStart: {
			position: 'relative',
			display: 'inline-block',
		},

		transition: {
			ease: 'easeOut',
			duration: 200,
		},
	},
});
