import React from 'react';
import { Localized } from '@fluent/react';
import { Bid, BidDetails } from 'app/models';
import styled from 'styled-components';

import { Typography } from 'app/sharedComponents/Typography';

const MarginWrapper = styled.div<{ margin: string }>`
	margin: ${(props) => props.margin + ''}px;
`;

const Container = styled.div`
	border-bottom: 1px solid rgba(0, 0, 0, 0.15);
	padding: 12px 16px;
`;

export const Distance = ({ bid }: { bid: Bid | undefined | BidDetails }) => {
	if (!bid) return null;
	return (
		<Container>
			<Typography bold variant='body5' color='#64c8eb;' textTransform='uppercase'>
				<Localized id='details-label-distance' />
			</Typography>
			<MarginWrapper margin='5'>
				<Typography variant='body2'>
					{bid?.direction?.distance} <Localized id='details-label-km' />
				</Typography>
			</MarginWrapper>
			<MarginWrapper margin='3'>
				<Typography variant='body4' color='rgba(0,0,0,0.6);'>
					<Localized id='details-status-changes' vars={{ changed: bid?.direction?.isChanged ? 'yes' : 'not' }} />
				</Typography>
			</MarginWrapper>
		</Container>
	);
};
