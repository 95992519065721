export default () => {
	const query: { [key: string]: any } = {};
	const queries = (window.location.search && window.location.search.split('?')[1].split('&')) || [];

	queries.forEach((param) => {
		const key = param.split('=')[0];
		const value = param.split('=')[1];

		query[key] = value;
	});

	return query;
};
