import styled from 'styled-components';

export const TabLayout = styled.div`
	display: flex;
	flex-direction: row;
	flex: auto;
`;

export const TabLayoutSidebar = styled.div`
    background-color: #f1f2f5;
    box-sizing: border-box;

    padding: 20px;
    min-width: 330px;
    width: 330px;
    z-index: 1;

    &.nopadding {
        padding: 0;
    }
    box-shadow: 2px 0px 7px rgba(0, 0, 0, 0.15);
}
`;

export const TabLayoutContent = styled.div`
	flex: auto;
	position: relative;

	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
	}
`;

export const TabLayoutCenterContent = styled.div`
	transform: translate3d(-50%, -50%, 0);
	position: absolute;
	text-align: center;
	top: 50%;
	left: 50%;
	width: 438px;

	img,
	svg {
		& + .title {
			margin-top: 20px;
		}
	}

	.title {
		font-size: 22px;
	}
`;

export const TabContainer = styled.div`
	flex-direction: row;
	display: flex;

	flex-grow: 1;
`;

export const TabContainerMainColumn = styled.div`
	max-width: 452px;
	flex-grow: 1;

	.form-item {
		margin-bottom: 20px;
	}

	&:first-child {
		margin-left: 248px;
	}
`;

export const TabContainerForm = styled.form`
	flex-direction: row;
	display: flex;

	flex-grow: 1;
`;

export const TabContainerSideColumn = styled.div`
	width: 248px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	.align-right {
		align-self: flex-end;
	}

	.align-left {
		align-self: flex-start;
	}

	.align-center {
		align-self: center;
	}
`;
