import { SET_PRICES, SET_PRICES_LOADING } from 'app/actions/prices';
import { handleActions, Action } from 'redux-actions';
import { PriceItem } from 'app/models';

export type PricesState = {
	loading: boolean;
	data: PriceItem[];
};

const defaultState: PricesState = {
	loading: false,
	data: [],
};

export const pricesReducer = handleActions<any, any>(
	{
		[SET_PRICES]: (state, { payload }: Action<any>) => (payload ? { loading: false, data: payload } : state),
		[SET_PRICES_LOADING]: (state, { payload }: Action<any>) => (payload ? { ...state, loading: payload } : state),
	},
	defaultState,
);
