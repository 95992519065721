import React from 'react';
import clsx from 'clsx';

export type CheckboxProps = {
	className?: string;
	name?: string;
	checked: boolean;
	onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
	children?: React.ReactNode | React.ReactNodeArray;
};

export const Checkbox = ({ className, name, checked, onChange, children }: CheckboxProps) => {
	const containerClassList = clsx({
		[className || '']: true,
		checkbox: true,
	});
	return (
		<label className={containerClassList}>
			<input name={name} onChange={onChange} checked={checked} type='checkbox' />
			<span>{children}</span>
		</label>
	);
};

export default Checkbox;
