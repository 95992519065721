import React from 'react';
import { Input } from 'app/sharedComponents/Input';
import { Typography } from 'app/sharedComponents/Typography';
import { Container, PaddingContainer, OffersListContainer } from './styles';
import { getTenderStatus, TENDER_STATUS } from '../helper';
import { OffersTitle } from './OffersTitle';
import { OfferRow } from './OfferRow';
import { TenderInfo } from './TenderInfo';

export const TenderOfferView = ({
	bid,
	offer,
	onChange,
	tenderOwner,
	onDeleteOffer,
}: // userOffer,
{
	bid: any;
	offer: any;
	onChange: any;
	tenderOwner: boolean;
	onDeleteOffer: any;
}) => {
	const { payment, tender, tenderOffers } = bid;
	const { status } = getTenderStatus(tender);
	const showOffers = Boolean(tenderOffers.length);

	const handleOfferDelete = (id: string) => onDeleteOffer(id);
	// const minOffer = tenderOffers.sort((a: any, b: any) => a.offeredPrice - b.offeredPrice);
	// console.log('---', minOffer);
	return (
		<Container>
			<PaddingContainer>
				<TenderInfo bid={bid} />
			</PaddingContainer>
			<PaddingContainer>
				{status === TENDER_STATUS.ACTIVE && !tenderOwner && (
					<>
						<Input
							type='number'
							value={offer?.rate}
							label='Ваша ставка'
							placeholder={`Базовая ставка ${payment.price} ${payment.type.name}`}
							onChange={onChange}
							name='rate'
						/>
						<Input
							type='number'
							value={offer?.lot}
							label='Ваш заявленный вес'
							placeholder={`Минимальный лот ${tender.minWeight}`}
							onChange={onChange}
							name='lot'
						/>
					</>
				)}
				<Typography variant='body5' bold textTransform='uppercase'>
					Список текущих заявок
				</Typography>
				{!showOffers && (
					<div>
						<Typography variant='body5' color='#6C6F88'>
							Заявок нет, добавьте и будете первым!
						</Typography>
					</div>
				)}
				{showOffers && (
					<OffersListContainer>
						<OffersTitle />
						{tenderOffers.map((offer: any, index: number) => (
							<OfferRow
								onDelete={tenderOwner && handleOfferDelete}
								key={offer?.id}
								offer={offer}
								index={index + 1}
								priceType={payment?.type?.name}
							/>
						))}
					</OffersListContainer>
				)}
			</PaddingContainer>
		</Container>
	);
};
