import React from 'react';

export const Reply: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='32' height='32' viewBox='4 8 32 32' fill='none'>
		<rect x='7' y='9' width='18' height='14' rx='7' fill={color} />
		<path
			d='M22.5 23C22.5 21.0197 22.3698 18.7045 20.9723 16.9998C19.5202 15.2285 16.9692 14.4006 12.8782 14.6877L13.0182 16.6827C16.8603 16.4131 18.5851 17.2426 19.4257 18.2678C20.3208 19.3597 20.5 20.9802 20.5 23H22.5Z'
			fill='white'
		/>
		<path
			d='M12 15.5168L11.2929 14.8097L10.5858 15.5168L11.2929 16.2239L12 15.5168ZM12.7071 16.2239L16.2239 12.7071L14.8096 11.2929L11.2929 14.8097L12.7071 16.2239ZM16.2239 18.3264L12.7071 14.8097L11.2929 16.2239L14.8096 19.7406L16.2239 18.3264Z'
			fill='white'
		/>
	</svg>
);

export default Reply;
