import { Location } from 'app/models';
import * as Mapbox from 'mapbox-gl';
import * as turf from '@turf/turf';

export const getVisibleZone = (map: Mapbox.Map): number[][] => {
	const bounds = map.getBounds();

	return [
		[bounds.getNorthWest().lng, bounds.getNorthWest().lat],
		[bounds.getNorthEast().lng, bounds.getNorthEast().lat],
		[bounds.getSouthEast().lng, bounds.getSouthEast().lat],
		[bounds.getSouthWest().lng, bounds.getSouthWest().lat],
		[bounds.getNorthWest().lng, bounds.getNorthWest().lat],
	];
};

export const pointExistsInPolygon = (visibleZone: number[][], location: Location): boolean => {
	return turf.booleanPointInPolygon(turf.point([location.longitude, location.latitude]), turf.polygon([visibleZone]));
};

export const makePlace = (place: any) => `${place?.name} ${place?.address}`;
export const driverName = (contact: any) => `${contact?.lastName} ${contact?.firstName} ${contact?.middleName}`;
export const driverInfo = (offer: any) =>
	`${offer?.transports?.[0]?.contact?.phone} • ${
		offer?.user?.companyName || offer?.transports?.[0]?.contact?.companyName
	} `;

export const getTransportsByBidId = (transport: any, bidId: string) => {
	return transport.filter((item: any) => item?.data?.bid?.bidId === bidId).map((i: any) => i.id);
};

export const getTransportStatus = ({ transportId, bidId, candidates = [] }: any) => {
	const bid = candidates.find((candidate: any) => candidate?.bid?.id === bidId);
	if (!bid) return 'free';
	const offer = bid?.offers?.find((offer: any) => offer.transports?.[0]?.transport?.uuid === transportId);
	return offer?.status || 'free';
};

export const findBidByTransportId = (transportId: string, candidates = []) => {
	return candidates.find(
		(candidate: any) =>
			candidate?.offers?.some((offer: any) => offer?.transports?.[0]?.transport?.uuid === transportId),
	);
};

export const parseLocation = (location: any) => ({ lat: location.latitude, lng: location.longitude });

export const getTransportRoute = ({ transportId, candidates }: any) => {
	const offer: any = findBidByTransportId(transportId, candidates);

	const route = offer?.bid?.direction
		? {
				from: parseLocation(offer?.bid?.direction?.from?.location),
				to: parseLocation(offer?.bid?.direction?.to?.location),
		  }
		: {};
	return route;
};

export const parseRouteFromBid = (bid: any) => {
	return {
		origin: parseLocation(bid?.direction?.from?.location),
		destination: parseLocation(bid?.direction?.to?.location),
	};
};

export enum directionMapper {
	to_loading = 'origin',
	unloaded = 'origin',
	on_loading = 'destination',
	on_unloading = 'destination',
	to_unloading = 'destination',
}
