import React, { useState, useEffect } from 'react';

//other deps
import Spinner from 'react-loader-spinner';

//redux
import { connect } from 'react-redux';

//pages
import Form from './form';
import Preview from './preview';

//types
import { RootState } from 'app/reducers';
import { UserVerifyStatus, UserProfile, LegalTypeEnum } from 'app/models';

interface ProfileBaseProps {
	verifyStatus?: UserVerifyStatus;
	sessionToken?: string;
	profile?: UserProfile;
}

//helpers
function isBaseValid(profile: UserProfile) {
	return (
		profile.firstName &&
		profile.firstName.length > 0 &&
		profile.lastName &&
		profile.lastName.length > 0 &&
		// (profile.middleName && profile.middleName.length > 0) && // Uncomment when middle name is required field
		profile.phone &&
		profile.phone.length > 0 &&
		profile.email &&
		profile.email.length > 0 &&
		profile.legalType &&
		profile.legalType.length > 0 &&
		((profile.inn && profile.inn.length > 0) || (!profile.inn && profile.legalType === LegalTypeEnum.Np))
	);
}

//----------------------------------------------------------
// ProfileBase
//----------------------------------------------------------
const ProfileBase = (props: ProfileBaseProps) => {
	//data
	const [editable, setEditable] = useState(true);
	const [loading, setLoading] = useState(true);
	const { profile, sessionToken, verifyStatus } = props;

	//callbacks
	const toggleEditable = (editable: boolean) => {
		setEditable(editable);
	};

	//effects
	useEffect(() => {
		if (profile) {
			setEditable(!isBaseValid(profile));
			setLoading(false);
		}
	}, [profile]);

	//renders
	if (!profile || loading) {
		return (
			<div className='loading-spinner'>
				<Spinner type='RevolvingDot' color='#1FB0E2' height={50} width={50} />
			</div>
		);
	} else if (editable) {
		return <Form toggleEditable={toggleEditable} />;
	}

	return (
		<Preview
			toggleEditable={toggleEditable}
			profile={profile}
			sessionToken={sessionToken}
			verifyStatus={verifyStatus}
		/>
	);
};

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
	verifyStatus: user.verifyStatus,
	profile: user.profile,
});

export default connect<ProfileBaseProps>(mapStateToProps)(ProfileBase);
