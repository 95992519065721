import { SchemeProps, BasicTypes, Validate } from 'app/containers/Builder/props';
import { Rate as RateApi } from 'app/api';

const scheme: SchemeProps.Form = [
	//@ts-ignore
	{
		label: 'create-order-label-shipment-end',
		localized: true,
		type: BasicTypes.Date,
		rounded: true,
		name: 'shipmentEnd',
		defaultValue: new Date(),
	},

	/*  {
        validation: Validate.Number,
        label: 'builder-map-rate',
        type: BasicTypes.Input,
        localized: true,
        rounded: true,
        required: true,
        name: 'price',
        step: '1',
    },*/
	{
		type: BasicTypes.Rate,
		label: 'create-order-label-rate',
		localized: true,
		name: 'rate',

		defaultValues: {
			isOverload: false,
			isPrepaid: false,
			isBargain: false,
			hasVat: false,
			isCash: false,
			isPricePerKm: false,
			type: RateApi.ByType.WT,
			// Lazy set in create order
			currencySymbol: '',
			hideSetupBlock: true,
		},
	},
	{
		validation: Validate.Number,
		type: BasicTypes.Input,
		required: true,
		name: 'weight',
		label: 'create-order-label-weight',
		localized: true,
		rounded: true,
		step: '1',
	},

	{
		validation: Validate.Number,
		type: BasicTypes.Input,
		required: true,
		name: 'cargoPrice',
		label: 'create-order-label-cargo-price',
		localized: true,
		rounded: true,
		step: '1',
	},

	{
		label: 'create-order-header-contacts-header',
		type: BasicTypes.Contacts,
		localized: true,
		name: 'contact',
	},

	{
		type: BasicTypes.Input,
		label: 'create-order-label-comment',
		localized: true,
		defaultValue: '',
		multiline: true,
		name: 'comment',
		rounded: true,
	},
];

export default scheme;
