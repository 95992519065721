import React, { useEffect } from 'react';
import { Localized } from '@fluent/react';
import clsx from 'clsx';
import { Bid, Cursor } from 'app/models';
import { Table } from './Table';
import { headers } from './constants';
import { Orders as OrdersNamespace } from './types';
import { Sort as SortIcon } from 'app/components/Icons';

const OrdersList = (props: OrdersListProps) => {
	const {
		list = [],
		tab = OrdersNamespace.Tab.My,
		onRowClick,
		onLoadMore,
		cursor,
		compact,
		setCompact,
		onSort,
		currentSort,
		loader,
	} = props;

	useEffect(() => {
		cursor.next && list.length < 20 && onLoadMore();
	}, [cursor]);

	const tableProps = {
		onLoadMore,
		rowCount: cursor.next ? list.length + 1 : list.length,
		rowHeight: 50,
		compact,
		setCompact,
		list,
		onRowClick,
		tab,
	};

	const handleSort = (sort: string) => () => onSort(sort);
	return (
		<div className='all'>
			<div className='container'>
				<div className='table-header'>
					{headers[tab].map((o: any) => {
						const isSortActive = o.id === currentSort.replace('-', '');
						const isCeilSorting = currentSort[0] !== '-';
						const classList = clsx('sort-icon', {
							'sort-icon--active': isSortActive,
							'sort-icon--up': isCeilSorting && isSortActive,
						});
						return (
							<div key={o.localized} className={o.className}>
								<div
									className={clsx('table-header-item', { sortable: o.sort })}
									onClick={o.sort ? handleSort(o.id) : () => {}}
								>
									<Localized id={o.localized} />
									{o.sort && <SortIcon className={classList} />}
								</div>
							</div>
						);
					})}
				</div>

				<div className='content'>
					<Table {...tableProps} loading={loader} />
				</div>
			</div>
		</div>
	);
};

interface OrdersListProps {
	list: Bid[];
	tab: OrdersNamespace.Tab;
	onRowClick: Function;
	onLoadMore(): Promise<void>;
	cursor: Cursor;
	compact: boolean;
	setCompact: (compact: boolean) => void;
	onSort: Function;
	currentSort: string;
	loader: boolean;
}

export default OrdersList;
