/* eslint-disable */

import { RouteComponentProps, withRouter, Route, Redirect, Switch } from 'react-router';
import { UserProfile, SidebarType, BidDetails, RoleEnum } from 'app/models';
import { Dispatch, bindActionCreators } from 'redux';
import { CommonActions, getCustomerCompanies } from 'app/actions';
import { RootState } from 'app/reducers';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { delay } from 'bluebird';
import * as api from 'app/api';
import * as React from 'react';

import { SidebarMenu, NavbarMenu } from 'app/containers/Menu';
import ActionSidebar from 'app/containers/ActionSidebar';
import { Sidebar } from '../Sidebar';
import InfoCompanyDocsFaq from 'app/containers/InfoFaq';
import Executors from 'app/containers/Executors';
import NotFound from 'app/containers/NotFound';
import Profile from 'app/containers/Profile';
import Prices from 'app/containers/Prices';
import Orders from 'app/containers/Orders';
import Admin from 'app/containers/Admin';

import { NewPrices } from 'app/containers/NewPrices';
import NewOrders from 'app/containers/NewOrders';

import { MainLayout, LayoutContent, LayoutRow } from './Components';

const mapStateToProps = ({ user, common }: RootState) => ({
	sessionToken: user.sessionToken,
	detailsBid: common.detailsBid,
	profile: user.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			setDetailsBid: CommonActions.setDetailsBid,
			getCustomerCompanies,
		},
		dispatch,
	);

class ContentLayout extends React.Component<ContentLayout.Props, ContentLayout.State> {
	public state: ContentLayout.State = {};

	constructor(props: ContentLayout.Props) {
		super(props);
	}

	public async componentDidMount() {
		await delay(250);
		const {
			location: { search },
			toggleSidebar,
			setDetailsBid,
			sessionToken,
			profile,
			getCustomerCompanies,
		} = this.props;

		if (profile?.role !== 'driver' && getCustomerCompanies) {
			getCustomerCompanies();
		}

		try {
			const parsed = queryString.parse(search);

			if (setDetailsBid && toggleSidebar && sessionToken && profile && parsed && parsed.bid) {
				const bid = await api.bidGetDetails(parsed.bid as string).catch(() => 'NOT_FOUND');

				setDetailsBid(bid as any);

				toggleSidebar({
					type: SidebarType.OrderDetails,
					value: true,
				});
			}
		} catch (error) {
			console.error(error);
		}
	}

	public render() {
		const { match, location, profile, sessionToken } = this.props;

		if (match && location && profile && sessionToken) {
			const transportComponent = profile.role === RoleEnum.Customer ? Executors : NotFound;

			return (
				<MainLayout>
					<NavbarMenu location={location} />

					<LayoutRow>
						<SidebarMenu location={location} />
						<LayoutContent>
							<div>
								<Switch location={location}>
									{/* Production switch by role */}
									<Route path='/panel/transport' component={transportComponent} />
									<Route exact path='/panel/profile' component={Profile} />
									<Route path='/panel/orders' component={NewOrders} />
									<Route exact path='/panel/oldorders' component={Orders} />
									<Route exact path='/panel/prices' component={NewPrices} />
									<Route exact path='/panel/oldprices' component={Prices} />
									<Route exact path='/panel/faq/verify' component={InfoCompanyDocsFaq} />
									<Route exact path='/panel/admin' component={Admin} />
									<Route component={NotFound} />
								</Switch>
							</div>
						</LayoutContent>
						<ActionSidebar />
						<Sidebar />
					</LayoutRow>
				</MainLayout>
			);
		} else {
			return (
				<Redirect
					to={{
						state: { from: location },
						pathname: '/',
					}}
				/>
			);
		}
	}
}

export default withRouter(
	connect<ContentLayout.StateProps, ContentLayout.DispatchProps, ContentLayout.ExternalProps>(
		mapStateToProps,
		mapDispatchToProps,
	)(ContentLayout),
);

namespace ContentLayout {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		detailsBid?: BidDetails | string;
		sessionToken?: string;
		profile?: UserProfile;
		dispatch?: Dispatch;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		setDetailsBid?: (bid?: BidDetails | string) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		getCustomerCompanies?: () => void;
	}

	// Main component state
	export interface State {}
}
