import Autocomplete, { Props as AutocompleteProps } from 'react-autocomplete';
import { SchemeProps, Field } from '../props';
import { suggestsCompany } from 'app/api';
import { Localized } from '@fluent/react';
import * as Model from 'app/models';
import * as React from 'react';
import _ from 'lodash';

const initialState = {
	menuIsOpen: false,
	company: null,
	suggests: [],
	value: '',
};

export class Company extends React.Component<Company.Props, Company.State> implements Field.Hooks<Model.Company> {
	public state: Company.State = _.clone(initialState);

	constructor(props: Company.Props) {
		super(props);

		this.getItemValue = this.getItemValue.bind(this);
		this.onMenuChange = this.onMenuChange.bind(this);
		this.onSelect = this.onSelect.bind(this);
		this.onChange = this.onChange.bind(this);
		this.getInput = this.getInput.bind(this);
		this.getItem = this.getItem.bind(this);
		this.getMenu = this.getMenu.bind(this);
		this.onBlur = this.onBlur.bind(this);

		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);

		const { onUpdate, field } = props;

		if (field.defaultValue) {
			onUpdate(field.name, (field as any).defaultValue);
			this.state.value = (field as any).defaultValue; // as string
		}
	}

	public async componentDidMount() {
		if (this.state.value.length > 0) {
			try {
				const suggests = await suggestsCompany(this.state.value);
				this.setState({ suggests });
			} catch (error) {
				// @ignore
			}
		}
	}

	public async updateValue() {
		// @unimplemented
	}

	public reset() {
		this.setState(_.clone(initialState));
	}

	private getItemValue(item: Model.Company): string {
		return item.inn;
	}

	private getItem(item: Model.Company, isHighlighted: boolean) {
		const activeClass = isHighlighted ? 'selected' : '';
		// const typeLabel = item.type === "Индивидуальный предприниматель"
		//     ? "builder-self-employer-type"
		//     : "builder-entity-type"

		return (
			<div key={`item-${item.ogrn}`} className={`item ${activeClass}`}>
				<div className='name'>{item.name}</div>
				<div className='type'>
					{/* <Localized id={typeLabel} /> */}
					{item.type}
				</div>
				<div className='reg-codes'>
					<div className='ogrn'>
						<Localized id='builder-psrn' />: {item.ogrn}
					</div>

					<div className='inn'>
						<Localized id='builder-itn' />: {item.inn}
					</div>
				</div>
			</div>
		);
	}

	private getMenu(items: JSX.Element[], value: string): JSX.Element | null {
		if (value === '') {
			return <div style={{ display: 'none' }} />;
			//   return (
			//     <div className="menu">
			//       <div className="empty">
			//         Начните название или рег. номера
			//       </div>
			//     </div>
			//   )
		} else {
			return <div className='menu'>{items}</div>;
		}
	}

	private getInput(props: any) {
		const { name, label, localized } = this.props.field;
		const { menuIsOpen } = this.state;

		return (
			<div className={'input rounded ' + (menuIsOpen ? 'opened' : '')}>
				<input name={name} type='text' required {...props} />
				<label>{localized ? <Localized id={label} /> : label}</label>
			</div>
		);
	}

	private async onSelect(value: string, item: Model.Company) {
		const {
			onUpdate,
			field: { name },
		} = this.props;

		this.setState({ company: item, value });
		onUpdate(name, value);
	}

	private async onChange(e: React.ChangeEvent<HTMLInputElement>, value: string) {
		const {
			onUpdate,
			field: { name },
		} = this.props;
		onUpdate(name, undefined as any);
		this.setState({ value });

		try {
			const suggests = await suggestsCompany(value);
			this.setState({ suggests });
		} catch (error) {
			// @ignore
			console.error(error);
		}
	}

	private async onBlur() {
		const {
			field: { inputProps },
		} = this.props;

		if (inputProps && inputProps.onBlur) {
			inputProps.onBlur();
		}
	}

	private onMenuChange(isOpen: boolean) {
		this.setState({
			menuIsOpen: isOpen,
		});
	}

	public render() {
		const { value, suggests } = this.state;
		const { hidden, disabled } = this.props.field;
		const { onBlur } = this;

		const styles: React.CSSProperties = {
			position: 'relative',
			width: '100%',
		};

		const props: AutocompleteProps = {
			onMenuVisibilityChange: this.onMenuChange,
			getItemValue: this.getItemValue,
			renderMenu: this.getMenu as any, // @todo: potential problem with types
			renderInput: this.getInput,
			renderItem: this.getItem,
			onSelect: this.onSelect,
			onChange: this.onChange,
			inputProps: { onBlur, disabled },
			wrapperStyle: styles,
			items: suggests,
			// open: true,
			value,
		};

		return hidden ? null : (
			<div className='form-item input company'>
				<Autocomplete {...props} />
			</div>
		);
	}
}

export namespace Company {
	export interface State {
		company: Model.Company | null;
		suggests: Model.Company[];
		menuIsOpen: boolean;
		value: string;
	}

	export interface Props extends Field.ComponentProps<string, SchemeProps.Company> {
		// @default from base
	}
}
