import { Bid, Cursor, BidsFilter } from 'app/models';
import {
	bidGetList,
	bidGetDetails,
	suspendOrder as suspendOrderCall,
	unsuspendOrder as unsuspendOrderCall,
} from 'app/api';
import { createAction } from 'redux-actions';
import { BidApiModel } from 'app/api/types';
import { CommonActions } from './common';
import { Dispatch } from 'redux';

export namespace BidActions {
	export enum Action {
		BID_TOGGLE_TOOLBAR = 'BID_TOGGLE_TOOLBAR',
		SET_BIDS_LOADING = 'SET_BIDS_LOADING',
		SET_BID_FILTERS = 'SET_BID_FILTERS',
		SET_ARCHIVE_BID = 'SET_ARCHIVE_BID',
		BID_LIST_CLEAN = 'BID_LIST_CLEAN',
		SET_ALL_BID = 'SET_ALL_BID',
		SET_MY_BID = 'SET_MY_BID',
		DELETE_BID = 'DELETE_BID',
		GET_BIDS = 'GET_BIDS',
		GET_BIDS_NEW = 'GET_BIDS_NEW',
	}

	export namespace Payload {
		export interface Bids {
			cursor: Cursor;
			list: Bid[];
			loadMore?: boolean;
		}

		export interface Loading {
			type: Bid.BidType;
			value: boolean;
		}
	}

	export const setLoading = createAction<Payload.Loading>(Action.SET_BIDS_LOADING);
	export const toggleFiltersToolbar = createAction(Action.BID_TOGGLE_TOOLBAR);
	export const setFilters = createAction<BidsFilter>(Action.SET_BID_FILTERS);
	export const cleanList = createAction<Bid.BidType>(Action.BID_LIST_CLEAN);
	export const setAllBids = createAction<Payload.Bids>(Action.SET_ALL_BID);
	export const setArchiveBids = createAction<Payload.Bids>(Action.SET_ARCHIVE_BID);
	export const setMyBids = createAction<Payload.Bids>(Action.SET_MY_BID);
	export const deleteBid = createAction<string>(Action.DELETE_BID);

	export const getBidsNew = createAction<any>(Action.GET_BIDS_NEW);

	export const getBids =
		(params: BidApiModel.BidsRequest, cursor?: string, loadMore?: boolean) => async (dispatch: Dispatch) => {
			try {
				dispatch(setLoading({ type: params.type, value: true }));
				const bids = await bidGetList(params, cursor);
				switch (params.type) {
					case Bid.BidType.Archive:
						dispatch(setArchiveBids({ ...bids, loadMore }));
						break;
					case Bid.BidType.All:
						dispatch(setAllBids({ ...bids, loadMore }));
						break;
					case Bid.BidType.My:
						dispatch(setMyBids({ ...bids, loadMore }));
						break;
					default:
						console.log('Unsupported bid type: ' + params.type);
						break;
				}
				dispatch(setLoading({ type: params.type, value: false }));
			} catch (error) {
				// @TODO: handle it future
			}
		};

	export const getDetailsBid = (id: string) => async (dispatch: Dispatch) => {
		try {
			await dispatchBidDetails(id, dispatch);
		} catch (error) {
			dispatch(CommonActions.setDetailsBid('NOT_FOUND'));
			// @TODO: handle it future
		}
	};

	export const suspendOrder = (id: string) => async (dispatch: Dispatch) => {
		try {
			const result = await suspendOrderCall(id);
			if (!result) {
				console.error('suspendOrder failure');
				return;
			}
			await dispatchBidDetails(id, dispatch);
		} catch (error) {
			console.error('failed to suspend order', error);
		}
	};

	export const unsuspendOrder = (id: string) => async (dispatch: Dispatch) => {
		try {
			const result = await unsuspendOrderCall(id);
			if (!result) {
				console.error('unsuspendOrder failure');
				return;
			}
			await dispatchBidDetails(id, dispatch);
		} catch (error) {
			console.error('failed to unsuspend order', error);
		}
	};

	export const applyFilters = (filters: BidsFilter) => async (dispatch: Dispatch) => {
		dispatch(setFilters(filters));
		dispatch(cleanList(Bid.BidType.Archive));
		dispatch(cleanList(Bid.BidType.All));
		dispatch(cleanList(Bid.BidType.My));
		// dispatch(getBids({ type: Bid.BidType.Archive, ...filters }) as any);
		// dispatch(getBids({ type: Bid.BidType.All, ...filters }) as any);
		// dispatch(getBids({ type: Bid.BidType.My, ...filters }) as any);
	};
}

async function dispatchBidDetails(id: string, dispatch: Dispatch) {
	const bid = await bidGetDetails(id);
	dispatch(CommonActions.setDetailsBid(bid));
}
