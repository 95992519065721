import React, { useState, useEffect, useRef } from 'react';
import { Localized } from '@fluent/react';
import { useSelector } from 'react-redux';

import _ from 'lodash';

import Builder from 'app/containers/Builder';

import { TabLayoutSidebar } from 'app/commonPatterns/TabLayout';
import { basePriceNew as basePrice } from './schemes';
import { ProteinSlider } from './ProteinSlider';

export const SideBarFilter = ({ loading, onSearch, onFromChange }: any) => {
	const [schemeBase, setSchemeBase] = useState([]);
	const [proteinsRange, setProteinsRange] = useState([]);
	const [protein, setProtein] = useState('');
	//@ts-ignore
	const products = useSelector((state) => state?.common?.products);
	const [selectedProduct, setSelectedProduct] = useState('');
	const form = useRef();

	const onUpdate = (name: string, value: any) => {
		if (name === 'from') {
			onFromChange(value);
			if (value) {
				//@ts-ignore
				const { cargo } = form?.current?.state?.form || {}; //TODO: check eslint/no-unsafe-optional-chaining;
				const product = products[cargo];
				const choices = product?.classes
					? Object.keys(product?.classes).map((classType) => ({
							value: classType,
							label: classType,
					  }))
					: [];

				const updatedScheme = schemeBase.map((item: any) => {
					return item?.name === 'classType' ? { ...item, choices, hide: !choices.length } : item;
				});

				setProteinsRange([]);
				setProtein('');
				//@ts-ignore
				setSchemeBase(updatedScheme);
			} else {
				const updatedScheme = schemeBase.map((item: any) =>
					item?.name === 'classType' ? { ...item, hide: true } : item,
				);
				setProteinsRange([]);
				//@ts-ignore
				setSchemeBase(updatedScheme);
			}
		}

		//@ts-ignore
		const { from } = form?.current?.state?.form || {}; //TODO: check eslint/no-unsafe-optional-chaining;

		if (name === 'cargo') {
			const product = products[value];
			setSelectedProduct(product?.id || '');

			const choices = product?.classes
				? Object.keys(product?.classes).map((classType) => ({
						value: classType,
						label: classType,
				  }))
				: [];

			const updatedScheme = schemeBase.map((item: any) => {
				return item?.name === 'classType' ? { ...item, choices, hide: !choices.length || !from } : item;
			});
			setProteinsRange([]);
			setProtein('');
			//@ts-ignore
			setSchemeBase(updatedScheme);
		}
		if (name === 'classType' && form.current) {
			//@ts-ignore
			const { cargo } = form?.current?.state?.form || {}; //TODO: check eslint/no-unsafe-optional-chaining;
			const classType = products?.[cargo]?.classes?.[value];
			setSelectedProduct(classType?.id || '');

			const newProteinsRange = cargo
				? Object.keys(products[cargo]?.classes?.[value] || {})
						.map(parseFloat)
						.filter((n) => !isNaN(n))
						.sort()
				: [];
			//@ts-ignore
			setProteinsRange(newProteinsRange);
			setProtein('');
		}
	};

	const formValid = true;

	const handleAction = () => {
		//@ts-ignore
		onSearch({
			//@ts-ignore
			...form?.current?.state?.form,
			selectedProduct,
			protein,
		});
	};

	const handleProteinChange = (protein: any) => {
		setProtein(protein);
		//@ts-ignore
		const { cargo, classType } = form?.current?.state?.form || {}; //TODO: check eslint/no-unsafe-optional-chaining;
		const id = products?.[cargo]?.classes?.[classType]?.[protein]?.id;
		setSelectedProduct(id);
	};

	useEffect(() => {
		if (products) {
			const schemeBaseClone: any = _.cloneDeep(basePrice);

			schemeBaseClone[1].choices = Object.keys(products).map((name) => ({
				label: name,
				value: name,
			}));
			setSchemeBase(schemeBaseClone);
		}
	}, [products]);

	const builderProps = {
		ref: (node: Builder | null) => {
			//@ts-ignore
			form.current = node;
		},
		scheme: schemeBase,
		onUpdate: onUpdate,
	};

	const actionDisabled = !!schemeBase.find(
		//@ts-ignore
		(item: any) => item?.name === 'classType' && !item?.hide && !form?.current?.state?.form?.classType,
	);

	const actionProps = {
		className: 'action ' + (!formValid || loading || actionDisabled ? 'disabled' : ''),
		onClick: handleAction,
	};

	return (
		<TabLayoutSidebar className='options'>
			<div className='header'>
				<Localized id='prices-sidebar-header' />
			</div>

			<Builder {...builderProps} />
			{proteinsRange && proteinsRange.length > 0 && (
				<ProteinSlider proteinsRange={proteinsRange} protein={protein} onChange={handleProteinChange} />
			)}
			<div {...actionProps}>
				{loading ? <Localized id='prices-loading-sidebar-action' /> : <Localized id='prices-sidebar-action' />}
			</div>
		</TabLayoutSidebar>
	);
};
