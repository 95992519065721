import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ToastContainer } from 'react-toastify';
import { Toaster } from 'app/components/Toaster';
import { Route, Switch, RouteComponentProps, withRouter, Redirect } from 'react-router';
import { getCookie, deleteCookie } from 'app/utils/cookies';
import { UserProfile } from 'app/models';
import { RootState } from 'app/reducers';
import { ModalProvider } from '../Modal';
import { SidebarContextProvider } from '../Sidebar';
// Screens
import EmailVerification from 'app/containers/EmailVerification';
import ContentLayout from 'app/containers/ContentLayout';
import PrivacyPolicy from 'app/containers/Privacy';
import NotFound from 'app/containers/NotFound';
import Login from 'app/containers/Login';
import { ProdWarning } from './ProdWarning';
import { isDevConnectedToProd } from 'app/config';
import { globalInitialState } from 'app/utils/initial-state';

const ICON_LOGO = require('../../../assets/icons/logo.svg');

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
	profile: user.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

class Layout extends React.Component<Readonly<Layout.Props>, Layout.State> {
	public state: Layout.State = {};
	public render() {
		const { profile, location } = this.props;
		let { sessionToken } = this.props;
		const root = (): any => {
			const cookieSessionToken = getCookie('sessionToken');
			if (cookieSessionToken && window.location.pathname === '/') {
				localStorage.setItem('sessionToken', cookieSessionToken);
				deleteCookie('sessionToken');
				sessionToken = cookieSessionToken;
				window.location.pathname = '/';
				return;
			}
			if (sessionToken && profile) {
				return (
					<Redirect
						to={{
							state: { from: location },
							pathname: '/panel/orders',
						}}
					/>
				);
			} else if (sessionToken && !profile) {
				return (
					<div className='preloader page'>
						<img src={globalInitialState.logoUrl || ICON_LOGO} alt='' />
						<h4>Загрузка...</h4>
					</div>
				);
			} else {
				return (
					<Redirect
						to={{
							state: { from: location },
							pathname: '/login',
						}}
					/>
				);
			}
		};

		return (
			<SidebarContextProvider>
				<ModalProvider>
					<Switch location={location}>
						<Route exact path='/' render={root} />
						<Route path='/panel' component={ContentLayout} />
						<Route path='/login' component={Login} />
						<Route path='/email_verification' component={EmailVerification} />
						<Route path='/privacy-policy' component={PrivacyPolicy} />
						<Route component={NotFound} />
					</Switch>
					{isDevConnectedToProd && <ProdWarning />}
					<ToastContainer autoClose={1100} />
					<Toaster />
				</ModalProvider>
			</SidebarContextProvider>
		);
	}
}

export default withRouter(
	connect<Layout.StateProps, Layout.DispatchProps, Layout.ExternalProps>(mapStateToProps, mapDispatchToProps)(Layout),
);

namespace Layout {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
		profile?: UserProfile;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
