import * as React from 'react';

//other deps
import _ from 'lodash';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { EmptyCarActions } from 'app/actions';

//schemes
import * as schemes from '../../schemes';

//types
import { EmptyCarDimensions } from 'app/models';
import { RootState } from 'app/reducers';
import { SchemeProps } from 'app/containers/Builder/props';

//helpers
import Builder from 'app/containers/Builder';
import diffArrays from 'app/utils/array-diff';
import validateObject from 'app/utils/form-validator';

//----------------------------------------------------------
// DimensionsCar
//----------------------------------------------------------
class DimensionsCar extends React.Component<DimensionsCar.Props, DimensionsCar.State> {
	public builder: Builder | null = null;

	public state: DimensionsCar.State = {
		form: {},
		validationReport: [],
	};

	constructor(props: DimensionsCar.Props) {
		super(props);

		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	//effects
	public async componentDidMount() {
		const { builder, state } = this;

		if (builder && !state.scheme) {
			const scheme = _.cloneDeep(schemes.dimensions);

			this.setState({ scheme });

			await Promise.delay(50);
			this.applyDefaults();
		}

		this.validateForm(this.state.form);
	}

	public componentDidUpdate(prevProps: DimensionsCar.Props) {
		const { dimensions = {} } = prevProps;

		if (!_.isEqual(dimensions, this.props.dimensions)) {
			this.applyDefaults();
		}
	}

	//callbacks
	private applyDefaults() {
		const { dimensions = {} } = this.props;
		const { builder } = this;

		if (builder) {
			const { formRefs } = builder;

			const allowed = ['height', 'length', 'gross'];

			for (const key of allowed) {
				const value = (dimensions as any)?.[key];

				if (value) {
					const ref = formRefs.get(key);

					if (ref) {
						ref.updateValue(value);
					}
				}
			}
		}
	}

	private validateForm(form: any) {
		const { getLocalizedMessage } = this;
		const validationReport: string[] = validateObject(form, {
			gross(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-gross-empty');
				v = parseInt(v);
				if (v < 0) return getLocalizedMessage('transports-validation-gross-less-than');
				if (v > 150) return getLocalizedMessage('transports-validation-gross-more-than');
				return true;
			},
			height(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-height-empty');
				v = parseInt(v);
				if (v < 0) return getLocalizedMessage('transports-validation-height-less-than');
				if (v > 5) return getLocalizedMessage('transports-validation-height-more-than');

				return true;
			},
			length(v: any) {
				if (v === '') return getLocalizedMessage('transports-validation-length-empty');
				v = parseInt(v);
				if (v < 0) return getLocalizedMessage('transports-validation-length-less-than');
				return true;
			},
		});

		if (diffArrays(validationReport, this.state.validationReport)) {
			this.setState({ validationReport });
		}

		return validationReport.length === 0;
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private onUpdate(name: string, value: any) {
		const { form } = this.state;
		const { updateDimensionsData } = this.props;

		this.setState({
			form: {
				...form,
				[name]: value,
			},
		});

		if (updateDimensionsData) {
			updateDimensionsData({
				[name]: value,
			} as EmptyCarDimensions);
		}
	}

	//renders
	public render() {
		const { scheme } = this.state;

		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		return (
			<div className='car dimensions form'>
				<div className='photos'></div>

				<div className='common'>
					<div className='header'>
						<div className='title'>
							<Localized id='transports-dimensions-title' />
						</div>
					</div>

					<div className='form'>
						<Builder {...builderProps} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ common }: RootState) => ({
	locales: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updateDimensionsData: EmptyCarActions.updateDimensionsData,
		},
		dispatch,
	);

export default connect<DimensionsCar.StateProps, DimensionsCar.DispatchProps, DimensionsCar.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(DimensionsCar);

namespace DimensionsCar {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updateDimensionsData?: (dimensions: EmptyCarDimensions) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		dimensions?: EmptyCarDimensions | null;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		validationReport: string[];
	}
}
