import React from 'react';
import styled from 'styled-components';
import { Localized } from '@fluent/react';
import { Typography } from 'app/sharedComponents/Typography';
import { Icon } from 'app/components/Icons';
import { TenderStatusType, valueMapper } from './constants';

export const BasicCell = ({ value }: any) => {
	return <span>{value}</span>;
};

export const CheckWrapper = styled.div<{ showbackground: boolean }>`
	width: 14px;
	height: 14px;

	background-color: ${(props) => (props?.showbackground ? '#64c8eb' : '')};
	border-radius: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 10px;
`;

export const HeaderWrapper = (props: any) => {
	const {
		column: { localizedId },
	} = props;
	return (
		<Typography variant='body4' color='#737373'>
			<Localized id={localizedId} />
		</Typography>
	);
};

export const AddresCell = ({ value, row, id }: any) => {
	return (
		<div style={{ display: 'flex' }}>
			{id === 'from' && (
				<CheckWrapper showbackground={row.verified}>
					{row.verified && <Icon variant='check' color='white' size={16} />}
				</CheckWrapper>
			)}
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<Typography variant='body3' bold>
					{value?.name}
				</Typography>
				<Typography variant='body3'>{value.address?.replace(value?.name + ',', '')}</Typography>
			</div>
		</div>
	);
};

export const PriceCell = ({ value, row, isPerKm }: any) => {
	return (
		<Typography variant='body3'>
			{value}
			<Localized id={isPerKm ? 'bid-price-per-km-unit' : 'bid-price-unit'} vars={{ currency: row?.currency }} />
		</Typography>
	);
};

export const OrderMyInfo = ({
	value,
	icon,
	color,
}: {
	value: any;
	icon: 'views' | 'approve' | 'reply';
	color: string;
}) => {
	return (
		<div style={{ display: 'flex' }}>
			<Icon variant={icon} color={color} />
			<Typography variant='body3'>{value}</Typography>
		</div>
	);
};

export const Cellwrapper =
	(Component = BasicCell, cellProps = {}) =>
	(props: any) => {
		const {
			column,
			cell: {
				value,
				row: { original: row },
			},
		} = props;

		return <Component value={value} row={row} id={column.id} column={column} {...cellProps} />;
	};

const TenderStatusContainer = styled.div<{ color: string }>`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 0px 8px;
	background: ${(p) => p.color};
	border-radius: 25px;
	font-size: 12px;
	color: #111111;
`;

export const TenderStatus = ({ value }: { value: TenderStatusType }) => {
	const { color, label } = valueMapper[value];
	return <TenderStatusContainer color={color}>{label}</TenderStatusContainer>;
};
