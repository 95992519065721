import React from 'react';

import { Avatar } from 'app/components/Avatar';
import { Localized } from '@fluent/react';
import clsx from 'clsx';

import { DriverCar } from 'app/models';
import { DriverActions } from 'app/actions';

export interface Props {
	profileAvatar?: string;
	isOpened: boolean;
	car: DriverCar;
	onClick: () => void;
	deleteDriverTransport: (carId: string) => void;
}

const CarItem = (props: Props) => {
	const { car, isOpened, profileAvatar, onClick, deleteDriverTransport } = props;
	const {
		transport: { ident, transportType, status },
	} = car;

	const handleDelete = () => {
		deleteDriverTransport(car.uuid);
	};

	return (
		<div className={clsx('car item', { opened: isOpened })}>
			<div className='info' onClick={onClick}>
				<Avatar
					driver={(car.contact && car.contact.avatar) || profileAvatar}
					transport={car.transport.avatar}
					type='transport'
					verified={car.transport.isVerified}
					stacked
				/>

				<div className='title'>
					<div className='top'>{ident || <Localized id='transports-ident-unknown' />}</div>

					<div className='bottom'>{transportType?.name || <Localized id='transports-type-unknown' />}</div>
				</div>
			</div>

			<div className='actions'>
				<button
					disabled={status === 'busy' || car.uuid === DriverActions.NEW_TRANSPORT_ID}
					className={clsx('action action--small action--icon', 'action--danger')}
					onClick={handleDelete}
				>
					✖
				</button>
			</div>
		</div>
	);
};

export default CarItem;
