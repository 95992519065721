import React from 'react';
import { OfferGridRow } from './styles';
import { Typography } from 'app/sharedComponents/Typography';

export const OffersTitle = () => {
	const titles = ['#', 'Заявл. вес', 'Выдел. вес', 'Цена'];
	return (
		<div style={{ marginBottom: '20px', backgroundColor: '#FAFAFA' }}>
			<OfferGridRow>
				{titles.map((title: string) => (
					<Typography key={title} variant='body5'>
						{title}
					</Typography>
				))}
			</OfferGridRow>
		</div>
	);
};
