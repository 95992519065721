import React, { useRef } from 'react';
import styled from 'styled-components';
//@ts-ignore
import useDoubleClick from 'use-double-click';

const ICON_CHECKED = require('assets/icons/i-checked.png');
const MapMarkElement = styled.button`
	position: relative;
	width: 27px;
	height: 27px;
	vertical-align: middle;
	cursor: pointer;

	&:after,
	&:before,
	& > .mark-body,
	& > span {
		content: '';
		position: absolute;

		width: 100%;
		height: 100%;
		border-radius: 50%;
		top: 0px;
		left: 0px;
		pointer-events: none;
	}

	&:after {
		content: attr(data-cluster);

		justify-content: center;
		flex-direction: column;
		white-space: nowrap;
		text-align: center;
		display: flex;

		font-family: UniNeue;
		font-style: normal;
		font-weight: bold;
		font-size: 26px;

		z-index: 3;
		padding-top: 3px;

		color: #ffffff;
	}

	&:before,
	& > span {
		background-color: #64c8eb;
		opacity: 0;
		transition:
			0.5s transform,
			0.25s opacity;
	}

	& > .mark-body {
		border: 4px solid #fff;
		box-shadow: 0px 2px 4px rgba(77, 87, 112, 0.5);
		box-sizing: border-box;
		background-color: #000;
		z-index: 2;
		transition: 0.25s;
		overflow: hidden;
		font-size: 0;
		text-align: right;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		& > .mark-section {
			display: inline-block;
			width: 50%;
			height: 50%;
			&:nth-child(1) {
				order: 0;
			}
			&:nth-child(2) {
				order: 0;
			}
			&:nth-child(3) {
				order: 3;
			}
			&:nth-child(3) {
				order: 2;
			}
		}
	}

	&:hover,
    /* &[data-hovered], */
    &[data-selected] {
		&:before,
		& > span {
			transform: scale(2.206896551724138);
			opacity: 0.2;
		}

		& > span {
			transition-delay: 0.25s;
		}
	}

	&:active {
		& > .mark-body {
			transform: scale(0.9);
			transition: 0s;
		}
	}

	&[data-cluster] {
		width: 53px;
		height: 53px;
	}
	&[data-sections='2'] {
		.mark-section {
			height: 100%;
		}
	}
	&[data-sections='3'] {
		.mark-section:last-child {
			width: 100%;
		}
	}

	.mark-select-status {
		position: absolute;
		top: 3px;
		left: 3px;
		right: 3px;
		bottom: 3px;
		border-radius: 100%;
		background-image: url(${ICON_CHECKED});
		background-color: rgba(100, 200, 235, 0.7);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100%;

		transform: scale(0.8);
		opacity: 0;
		transition: 0.3s ease-out;
		z-index: 10;
	}

	&[data-selected] .mark-select-status {
		transform: scale(1);
		transition: 0.25s cubic-bezier(0.13, 1.17, 0, 1.41);
		opacity: 1;
	}
`;

interface MapMarkProps {
	color?: string | string[];
	cluster?: number; // Если метка является чанком машин, то нужно указать число машин в чанке
	sections?: number; // Количество секций (максимум 4)
	selected?: boolean;
	hovered?: boolean;
	onClick?: () => void;
	children?: any;
	onMouseEnter?: any;
	onMouseLeave?: any;
	onDoubleClick?: any;
}

const MapMark = ({
	sections,
	color = '#000',
	cluster,
	onMouseEnter,
	onMouseLeave,
	onClick = () => {},
	onDoubleClick = () => {},
	selected,
	hovered,
	children,
}: MapMarkProps) => {
	const mainColor = Array.isArray(color) ? color[0] : color;
	const markerRef = useRef();

	useDoubleClick({
		onSingleClick: onClick,
		onDoubleClick: onDoubleClick,
		ref: markerRef,
		latency: 250,
	});

	return (
		<MapMarkElement
			//@ts-ignore
			ref={markerRef}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			//onClick={onClick}
			//onDoubleClick={onDoubleClick}
			data-cluster={cluster}
			data-sections={sections}
			data-selected={selected}
			data-hovered={hovered}
		>
			<div style={{ backgroundColor: hovered ? '#79CFEA' : mainColor }} className='mark-body'>
				{Array.isArray(color) &&
					cluster &&
					color.map((color: string, i: number) => (
						<div
							key={`cluster-section-${i}`}
							style={{ backgroundColor: hovered ? '#79CFEA' : color }}
							className='mark-section'
						/>
					))}
			</div>
			<div className='mark-select-status' />
			<span />
			{children}
		</MapMarkElement>
	);
};

export default MapMark;
