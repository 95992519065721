import styled from 'styled-components';
const Default = styled.button`
	background: #eee;
	border-radius: 4px;
	padding: 10px 20px;

	height: 40px;

	letter-spacing: 1px;
	text-transform: uppercase;

	font-family: UniNeue, Helvetica, sans-serif;
	color: #333;
	font-size: 14px;
	font-weight: bold;
	line-height: 14px;

	&.fill {
		width: 100%;
	}
`;
export default Default;
