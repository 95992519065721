import React, { useState, useEffect } from 'react';

//other deps
import { FluentBundle } from '@fluent/bundle';
import { Localized } from '@fluent/react';

//api
import { userSendVerifyEmail } from 'app/api';

//redux
import { connect } from 'react-redux';

//components
import InfoLabel from 'app/components/InfoLabel';
import HoverPopup from 'app/components/HoverPopup';
import { CompanyInfo } from './company';

//types
import { RootState } from 'app/reducers';
import { UserProfile, UserVerifyStatus, LegalTypeEnum } from 'app/models';

interface PreviewProfileProps {
	sessionToken?: string;
	profile: UserProfile;
	verifyStatus?: UserVerifyStatus;
	locales?: FluentBundle[];
	toggleEditable: Function;
}

//helpers
import { maskPhoneValue } from 'app/utils';
import { getLocalizedMessage } from 'app/locales';

function msToMinutesAndSeconds(ms: number) {
	const minutes = Math.floor(ms / 60000);
	const seconds = ((ms % 60000) / 1000).toFixed(0);

	return `${minutes} : ${Number(seconds) < 10 ? '0' : ''}${seconds}`;
}

//constants
const TIMER_INTERVAL = 1000;
const VERIFICATION_INTERVAL = 300000;

//images
const profileImage = require('assets/icons/profile.png');
const verifiedIcon = require('assets/icons/verified.png');

//----------------------------------------------------------
// PreviewProfile
//----------------------------------------------------------
const PreviewProfile = (props: PreviewProfileProps) => {
	//data
	const { sessionToken, profile, verifyStatus, toggleEditable } = props;
	const profileLegalType = 'legal-type-' + profile.legalType;

	const [verificationSend, setVerificationSend] = useState(false);
	const [timeLeft, setTimeLeft] = useState(0);

	//callbacks
	const handleVerificationSend = () => {
		setVerificationSend(true);
		setTimeLeft(VERIFICATION_INTERVAL);
	};

	const requestVerifyEmail = async () => {
		try {
			if (!sessionToken) {
				return;
			}
			handleVerificationSend();
			await userSendVerifyEmail();
		} catch (error) {
			console.log(error);
		}
	};

	//effects
	useEffect(() => {
		if (timeLeft > 0) {
			setTimeout(() => {
				setTimeLeft(timeLeft - TIMER_INTERVAL);
			}, TIMER_INTERVAL);
		} else {
			setVerificationSend(false);
		}
	}, [timeLeft]);

	//renders
	const isEmailVerified = !profile.needActions.includes('email');
	const isProfileVerified = profile.verification?.status === 'verified';
	const photo = profile.avatar ? profile.avatar : profileImage;
	const isIndividualUser = profile.legalType === LegalTypeEnum.Np;

	const _renderEmailVerificationPopup = () => {
		return (
			<HoverPopup
				text={getLocalizedMessage('profile-hint-email-not-verified')}
				icon=' '
				position='top'
				key='no-verified-email-field'
			>
				<div className='verification-actions'>
					<button disabled={verificationSend} onClick={requestVerifyEmail} className='action action--small ml-1'>
						<Localized id={verificationSend ? 'profile-action-verify-email-send' : 'profile-action-verify'} />
					</button>

					{verificationSend && <div className='verification-timer'>{msToMinutesAndSeconds(timeLeft)}</div>}
				</div>
			</HoverPopup>
		);
	};

	if (!profile || !verifyStatus) {
		return null;
	}

	return (
		<div className='profile base preview'>
			<div className='columns'>
				<div className='profile__photo-container'>
					<img className='profile__photo' src={photo} alt='' />

					{isProfileVerified && <img className='profile__verified-icon' src={verifiedIcon} alt='' />}
				</div>

				<div className='content'>
					<div className='header-2 profile__title'>
						{profile.lastName} {profile.firstName} {profile.middleName}
					</div>

					<div className='row profile__info'>
						<div className='column'>
							<InfoLabel title={<Localized id='profile-label-phone' />} text={maskPhoneValue(profile.phone)} />
							<InfoLabel
								title={<Localized id='profile-label-email' />}
								text={[profile.email, !isEmailVerified && _renderEmailVerificationPopup()]}
							/>

							<InfoLabel
								title={<Localized id='profile-header-organization' />}
								text={<Localized id={profileLegalType} />}
							/>

							<div className='action profile__edit-button' onClick={() => toggleEditable(true)}>
								<Localized id='profile-action-edit' />
							</div>
						</div>

						<div className='column'>
							<div className='items'>
								<InfoLabel
									title={<Localized id='profile-label-itn' />}
									text={profile.inn || <Localized id='profile-field-itn-unspecifed' />}
								/>

								{!isIndividualUser && (
									<div className='org-info'>
										<CompanyInfo hideFullName={true} inn={profile.inn} />
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = ({ common }: RootState) => {
	return {
		locales: common.bundlesLocales,
	};
};

export default connect(mapStateToProps)(PreviewProfile);
