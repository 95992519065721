import React, { useEffect } from 'react';

import styled from 'styled-components';
import { Localized } from '@fluent/react';

import { useSelector, useDispatch } from 'react-redux';
import { setTransportOnMapFilter, setTransportOnMapBounds, setTransportOnMap } from 'app/actions/transportOnMap';

import Checkbox from 'app/sharedComponents/Checkbox';

const Container = styled.div`
	padding: 10px 20px;
	padding-bottom: 7px;
	border: 1px solid #70ccec;
	position: absolute;
	z-index: 99999;
	right: 150px;
	top: 30px;
	background-color: white;
	border-radius: 5px;
	display: flex;
`;

export const SyncMap = ({ bounds, idsOnMap }: any) => {
	const filter = useSelector((state: any) => state.transportOnMap.filter);
	const dispatch = useDispatch();

	const handleChange = (e: any) => {
		dispatch(setTransportOnMapFilter(e.target.checked));

		localStorage.setItem('gmap-move-sync', JSON.stringify(e.target.checked));
	};

	useEffect(() => {
		const bbox = Array.isArray(bounds) ? [bounds[0], bounds[3], bounds[2], bounds[1]].join() : '';

		dispatch(setTransportOnMapBounds(bbox));
	}, [bounds]);

	useEffect(() => {
		dispatch(setTransportOnMap(idsOnMap));
	}, [idsOnMap]);

	return (
		<Container>
			<Checkbox onChange={handleChange} checked={filter} />
			<Localized id='gmap-sync-map-move' />
		</Container>
	);
};

export default SyncMap;
