import { CommonActions, UserActions } from 'app/actions';
import { Dispatch, bindActionCreators } from 'redux';
import Scrollbars from 'react-custom-scrollbars';
import { RootState } from 'app/reducers';
import update from 'immutability-helper';
import { SidebarType } from 'app/models';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';
import { connect } from 'react-redux';
import Masker from 'vanilla-masker';
import * as React from 'react';
import _ from 'lodash';

import { SchemeProps } from 'app/containers/Builder/props';
import Builder from 'app/containers/Builder';
import SCHEME_CREATE from './scheme';

const closeIcon = require('../../../../assets/icons/close.svg');
const clearIcon = require('../../../../assets/icons/clear_all.png');

const mapStateToProps = ({ common, user }: RootState) => ({
	openedSidebar: common.openedSidebar,
	sessionToken: user.sessionToken,
	locales: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			createContact: UserActions.createContact,
		},
		dispatch,
	);

class CreateContact extends React.Component<CreateContact.Props, CreateContact.State> {
	// Instance for direct manipulation local state & props
	public scrollbar: Scrollbars | null = null;
	public builder: Builder | null = null;

	public state: CreateContact.State = {
		scheme: _.cloneDeep(SCHEME_CREATE),
		lockSubmit: false,
		valid: false,
	};

	constructor(props: CreateContact.Props) {
		super(props);

		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.getSidebarHeader = this.getSidebarHeader.bind(this);
		this.formIsValid = this.formIsValid.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.reset = this.reset.bind(this);
	}

	private getSidebarHeader() {
		const { toggleSidebar } = this.props;

		const toggle = () => {
			if (toggleSidebar) {
				toggleSidebar({ type: SidebarType.ContactCreate, value: false });
			}
		};

		const reset = () => {
			this.reset();
		};

		return (
			<div key='create-contact-header' className='header'>
				<div className='title'>
					<Localized id='create-contact-header' />
				</div>

				<div className='clear icon' onClick={reset}>
					<img src={clearIcon} alt='' />
				</div>
				<div className='icon' onClick={toggle}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
		);
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private async onUpdate() {
		this.setState({
			valid: this.formIsValid(),
		});
	}

	private async reset() {
		const { state, scrollbar, builder } = this;

		if (builder) {
			await builder.reset();

			await this.setState(
				update(state, {
					lockSubmit: { $set: false },
					valid: { $set: false },
				}),
			);
		}

		if (scrollbar) {
			scrollbar.scrollTop(0);
		}
	}

	private formIsValid(): boolean {
		const { builder } = this;

		if (builder) {
			const { form } = builder.state;
			const phoneLength = form.phone ? form.phone.length : 0;
			const phoneValid = form.phone && (phoneLength === 18 || phoneLength === 19);

			const valid = phoneValid && !!form.middleName && !!form.firstName && !!form.lastName;

			return valid;
		} else {
			return false;
		}
	}

	private async onSubmit() {
		const { state, reset, builder } = this;
		const { sessionToken, createContact } = this.props;
		const { lockSubmit } = state;

		if (!state.valid || !sessionToken || !builder || !createContact || lockSubmit) {
			return;
		} else {
			const { form } = builder.state;
			const params = _.clone(form) as any;

			// Splice phone format
			params.phone = '+' + Masker.toNumber(params.phone);
			// console.log(params)

			await createContact(params, {
				success: this.getLocalizedMessage('form-contact-create-success'),
				failed: this.getLocalizedMessage('form-contact-create-failed'),
			});

			await this.setState({ lockSubmit: true });

			try {
				await reset();
			} catch (error) {
				this.setState({ lockSubmit: false });
				console.error(error);
			}
		}
	}

	public render() {
		const { lockSubmit, scheme } = this.state;

		const extendedButtonClass = !this.formIsValid() || lockSubmit ? 'disabled' : '';

		const scrollbarProps: any = {
			ref: (node: Scrollbars | null) => (this.scrollbar = node),
			renderTrackHorizontal: () => <div />,
			key: 'create-contact-scrollbar',
			className: 'form',
		};

		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			onUpdate: this.onUpdate,
			scheme,
		};

		return [
			this.getSidebarHeader(),

			<Scrollbars {...scrollbarProps} key='create-contact=scrollbars'>
				<Builder {...builderProps} />
			</Scrollbars>,

			<div key='create-contact-action' onClick={this.onSubmit} className={'submit ' + extendedButtonClass}>
				<Localized id={lockSubmit ? 'form-contact-process' : 'form-contact-create'} />
			</div>,
		];
	}
}

export default connect<CreateContact.StateProps, CreateContact.DispatchProps, CreateContact.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(CreateContact);

namespace CreateContact {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		openedSidebar?: [SidebarType, boolean, any];
		locales?: FluentBundle[];
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		createContact?: (
			params: {
				middleName: string;
				firstName: string;
				lastName: string;
				phone: string;
			},
			messages: {
				success: string;
				failed: string;
			},
		) => void;

		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		scheme: SchemeProps.Union[];
		lockSubmit: boolean;
		valid: boolean;
	}
}
