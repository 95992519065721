import React from 'react';

//other deps
import { saveAs } from 'file-saver';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { fullScreenPlugin, Zoom } from '@react-pdf-viewer/full-screen';

//components
import { DocumentInfo } from './DocumentInfo';

//styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/full-screen/lib/styles/index.css';

import { PdfContainer } from './styles';

//types
import { DocumentType } from './types';

type PdfViewerProps = {
	item: DocumentType;
	isReady: boolean;
	file: any;
	blob: any;
	onSign: Function;
};

//constants
const WORKER_URL = 'https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js';

//----------------------------------------------------------
// PdfViewer
//----------------------------------------------------------
export const PdfViewer = ({ item, isReady, file, blob, onSign }: PdfViewerProps) => {
	//callbacks
	const handleSignDocument = async (item: DocumentType) => {
		onSign([item.uuid]);
	};

	const handleDownload = (item: any) => {
		if (blob) {
			saveAs(blob, `${item.number}.pdf`);
		}
	};

	//renders
	const fullScreenPluginInstance = fullScreenPlugin({
		onEnterFullScreen: (zoom: Zoom) => zoom(2),
		onExitFullScreen: (zoom: Zoom) => zoom(1.5),
	});

	const { EnterFullScreenButton } = fullScreenPluginInstance;

	return (
		<Worker workerUrl={WORKER_URL}>
			{isReady && (
				<PdfContainer>
					<DocumentInfo
						item={item}
						onSign={handleSignDocument}
						onDownload={handleDownload}
						FullScreenButtonSlot={EnterFullScreenButton}
					/>

					<Viewer fileUrl={file} plugins={[fullScreenPluginInstance]} defaultScale={1.5} />
				</PdfContainer>
			)}
		</Worker>
	);
};
