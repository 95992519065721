import React from 'react';
import styled from 'styled-components';

const Dot = styled.div`
	width: 4px;
	height: 4px;
	border-radius: 50px;
	margin: 5px;
	border-color: #6c6f88;
	background-color: #6c6f88;
`;
const Container = styled.div`
	display: flex;
`;

export const DotsCell = () => {
	const keysArr = ['1', '2', '3'];
	return (
		<Container>
			{keysArr.map((key: string) => (
				<Dot key={key} />
			))}
		</Container>
	);
};
