import React, { useCallback } from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/reducers';
import { BidActions } from 'app/actions';
import { BidsFilter } from 'app/models';
import { Select } from 'app/sharedComponents/Select';
import { DatePicker } from 'app/sharedComponents/DatePicker';

const FilterContainer = styled.div`
	width: 200px;
	padding-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
`;

const bidKindOptions = [
	{ label: 'Обычная', value: 'bid' },
	{ label: 'Тендер', value: 'tender' },
];

export const Filters = () => {
	const dispatch = useDispatch();
	const applyFilters = useCallback((v: BidsFilter) => dispatch(BidActions.applyFilters(v)), [dispatch]);
	const userSettings = useSelector((state: any) => state.user.settings) || {};
	const { tendersAllowed } = userSettings;

	const filters = useSelector((state: RootState) => state.bid.filters);
	const regions = [
		...useSelector((state: RootState) => state.common.regions).map(({ name }: any) => ({
			label: name,
			value: name,
		})),
	];

	const cargos = useSelector((state: RootState) => state.common.cargos).map(({ name, id }: any) => ({
		label: name,
		value: id,
	}));

	const onUpdate = (name: string) => (val: any) => {
		if (!val) {
			applyFilters({ ...filters, [name]: '' });
			return;
		}
		const values = Array.isArray(val) ? { [name]: val.map(({ value }: any) => value) } : { [name]: val.value };
		const updatedFilters = _.pickBy({ ...filters, ...values }, _.identity);

		applyFilters(updatedFilters);
	};

	const handleTenderChange = ({ value }: any) => {
		onUpdate('kind')({ value });
	};

	return (
		<>
			{tendersAllowed && (
				<FilterContainer>
					<Select
						defaultValue={bidKindOptions[0]}
						options={bidKindOptions}
						label='Вид заявки'
						// placeholder="Вид заявки"
						onChange={handleTenderChange}
					/>
				</FilterContainer>
			)}
			<FilterContainer>
				<Select isClearable multi options={cargos} label='Груз' placeholder='Любой' onChange={onUpdate('cargoType')} />
			</FilterContainer>
			<FilterContainer>
				<Select options={regions} label='Регион' placeholder='Откуда' onChange={onUpdate('regionLoading')} />
			</FilterContainer>
			<FilterContainer>
				<Select options={regions} label='' placeholder='Куда' onChange={onUpdate('regionUnloading')} />
			</FilterContainer>
			<FilterContainer>
				<DatePicker
					isClearable
					value={filters['minDeliveryFrom']}
					label='Дата от'
					placeholder='Выбрать дату'
					onChange={(value: string) => onUpdate('minDeliveryFrom')({ value })}
				/>
			</FilterContainer>
			<FilterContainer>
				<DatePicker
					isClearable
					value={filters['maxDeliveryFrom']}
					label='Дата до'
					placeholder='Выбрать дату'
					onChange={(value: string) => onUpdate('maxDeliveryFrom')({ value })}
				/>
			</FilterContainer>
		</>
	);
};
