import { put, takeEvery, select } from 'redux-saga/effects';
import { Action } from 'redux-actions';
import qs from 'qs';

import * as api from '../api';
// import { ExecutorApiModel } from '../api/types';

import { GET_BID_POINTS, setBidPointsLoading, setBidPoints } from 'app/actions/bidPoints';

const hasValue = (el: any) => {
	if (Array.isArray(el)) {
		return !!el.length;
	}
	return !!el;
};

const normalizeParams = (obj: any) => {
	return Object.keys(obj).reduce((acc: any, key: string) => {
		return hasValue(obj[key]) ? { ...acc, [key]: obj[key] } : { ...acc };
	}, {});
};

export interface ResponseGenerator {
	config?: any;
	data?: any;
	headers?: any;
	request?: any;
	status?: number;
	statusText?: string;
}

export function* getBidPoints(action: Action<string>): Generator<any, void, string> {
	const filters: any = yield select((state) => state.bid.filters);
	const preparedParams = normalizeParams(api.prepareParams(filters));

	yield put(setBidPointsLoading(true));
	try {
		const typeParam = action.payload === 'all' ? '' : `&type=${action.payload}`;
		const response: any = yield api.v1.get(`bid?only_coordinate=1${typeParam}`, {
			params: preparedParams,
			paramsSerializer: function (params) {
				return qs.stringify(params, { arrayFormat: 'comma' });
			},
		});

		//@ts-ignore
		yield put(setBidPoints({ type: action.payload, data: response?.data?.data }));
	} catch (error) {
		// yield call(api.apiSentryException, error);
	}
}

export function* watchBidPoints() {
	yield takeEvery(GET_BID_POINTS, getBidPoints);
}
