import { RequestError, prepareResponse } from 'app/api';
import { Location } from 'app/models';
import axios from 'axios';

export namespace MapsConfig {
	export const MAPBOX_TOKEN =
		'pk.eyJ1IjoidGFwb2stc2F0YW4iLCJhIjoiY2pvcm91cHo4MGk2cjNwa3djeXRlOThncCJ9.h9DFxGeBccN72sE6saIJVw';
	export const GOOGLE_API_KEY = 'AIzaSyCQMuTsgHgGsV4gkIjoacoJZm4v4adGdVM';
}

export namespace MapboxDirection {
	export interface Route {
		weightName: string;
		distance: number;
		geometry: string;
		duration: number;
		weight: number;

		legs: Array<{
			duration: number;
			distance: number;
			summary: string;
			weight: number;
			steps: any[];
		}>;
	}

	export interface Waypoint {
		location: number[];
		distance: number;
		name: string;
	}

	export interface Response {
		waypoints: Waypoint[];
		routes: Route[];
		code: string;
		uuid: string;
	}
}

export async function getDirection(from: Location, to: Location): Promise<MapboxDirection.Response> {
	const _from = `${from.longitude},${from.latitude}`;
	const _to = `${to.longitude},${to.latitude}`;

	const response = await axios.request<MapboxDirection.Response>({
		url: `https://api.mapbox.com/directions/v5/mapbox/driving/${_from};${_to}.json?access_token=${MapsConfig.MAPBOX_TOKEN}&geometries=polyline&overview=full`,
		method: 'GET',
	});

	if (response.status === 200 && response.data.code === 'Ok') {
		const result: MapboxDirection.Response = {
			...response.data,

			waypoints: response.data.waypoints.map((w) => prepareResponse<MapboxDirection.Waypoint>(w)),
			routes: response.data.routes.map((r) => prepareResponse<MapboxDirection.Route>(r)),
		};

		return result;
	}

	throw new RequestError('mapboxDirections', response.data);
}
