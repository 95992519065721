import { isFiltersActive } from './filters';

export const candidates = (state: any) => state?.candidates?.data || [];

const applyFiltersToOffers = (filters: any = {}, offers = [], state: any) => {
	return isFiltersActive(state) ? offers.filter(({ state }: any) => filters[state]) : offers;
};

export const bidDetails = (state: any) => {
	const { transportOnMap, candidates = {} } = state;
	const { detail = {} } = candidates;
	const res = {
		...detail,
		offers: transportOnMap.filter
			? applyFiltersToOffers(state.filters, detail?.offers, state).filter((offer: any) => {
					const res = transportOnMap.data.includes(offer?.transports?.[0].transport?.id);
					return res;
			  })
			: applyFiltersToOffers(state.filters, detail?.offers, state),
	};
	return detail && res ? res : null;
};
