import React, { useState, createContext, useContext } from 'react';
// import Modal from 'app/sharedComponents/Modal';
import ModalView from './ModalView';

export const ModalContext = createContext<any>({});
export const useModalContext = () => useContext(ModalContext);

export const useModal = () => {
	const [showModal, setShow] = useState(false);
	const [modalContent, setModalContent] = useState();
	const [actions, setActions] = useState([]);
	const [props, setProps] = useState({});

	const handleModal = (handleProps: any = {}) => {
		const { content, actions, props } = handleProps;
		content && setModalContent(content);
		actions && setActions(actions);
		props && setProps(props);
		setShow(!showModal);
	};

	return { showModal, handleModal, modalContent, actions, props };
};

export const ModalProvider = ({ children }: any) => {
	const { showModal, handleModal, modalContent, actions, props } = useModal();

	return (
		<ModalContext.Provider value={{ modalContent, actions, props, handleModal, showModal }}>
			<ModalView />
			{children}
		</ModalContext.Provider>
	);
};
