import React, { useState, forwardRef, useEffect } from 'react';
import ReactDatePicker from 'react-datepicker';
import russianLocale from 'date-fns/locale/ru';
import styled from 'styled-components';
import { Calendar } from 'app/components/Icons';

import {
	InputContainer,
	DatePickerContainer,
	Container,
	Label,
	Placeholder,
	Value,
} from './DatePickerStyledComponents';

import 'react-datepicker/dist/react-datepicker.css';

export type DatePickerProps = {
	value?: string;
	label?: string;
	showTime?: boolean;
	onChange: (value: string) => void;
	placeholder?: string;
	isClearable?: boolean;
	noBorder?: boolean;
	showIcon?: boolean;
};

export type CustomInputProps = {
	value?: string;
	label?: string;
	showIcon?: boolean;
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

type Ref = HTMLInputElement;

const TimeInput = styled.input`
	font-size: 16px;
	border: 1px solid #d3d3d3;
	border-radius: 4px;
	&:focus {
		border: 1px solid #64c8eb;
		outline-color: #64c8eb;
	}
	&:focus-visible {
		border: 2px solid #64c8eb;
		outline-color: #64c8eb;
	}
`;

/* eslint-disable  react/display-name */
const CustomInput = forwardRef<Ref, CustomInputProps>(({ value, onClick, label, showIcon }, ref) => {
	return (
		<InputContainer onClick={onClick} ref={ref}>
			{value && <Value>{value}</Value>}
			{!value && <Placeholder>{label}</Placeholder>}
			{(!value || showIcon) && <Calendar color='#64C8EB' />}
		</InputContainer>
	);
});

export const CustomTimeInput = ({ value, onChange }: any) => {
	const handleChange = (e: any) => {
		onChange(e.target.value || '00:00');
	};
	return <TimeInput value={value} type='time' onChange={handleChange} />;
};

/* eslint-enable react/display-name */

export const DatePicker = ({
	value,
	label,
	placeholder,
	showTime,
	isClearable,
	noBorder,
	onChange,
	showIcon,
}: DatePickerProps) => {
	const [date, setDate] = useState<Date | null>(new Date());

	const handleOnChange = (date: Date) => {
		setDate(date);
		const dateArr = date.toISOString().split('T');
		onChange(showTime ? date.toISOString() : dateArr[0]);
	};

	useEffect(() => {
		try {
			setDate(value ? new Date(value) : null);
		} catch (e) {
			console.warn('____date-picker___ wrong date value');
		}
	}, [value]);

	return (
		<Container>
			{label && <Label>{label}</Label>}
			<DatePickerContainer noBorder={noBorder}>
				<ReactDatePicker
					isClearable={isClearable}
					timeFormat='HH:mm'
					timeInputLabel='Время'
					selected={date}
					locale={russianLocale}
					showTimeInput={showTime}
					dateFormat={showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy'}
					customInput={<CustomInput label={placeholder} showIcon={showIcon} />}
					onChange={handleOnChange}
					customTimeInput={<CustomTimeInput />}
				/>
			</DatePickerContainer>
		</Container>
	);
};
