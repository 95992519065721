import { Company } from './company';
import { suggestsCompanyByPattern } from 'app/api';
import * as Model from 'app/models';
import React, { useState, useEffect } from 'react';

import { Select } from './select';
import { Field } from '../props';

interface CompanyState {
	suggested: Model.Company[];
	choices: Field.Choice<any>[];
}

const initialSuggestsState = {
	suggested: [],
	choices: [],
};

const _c = ({ field: { hidden, name, label, localized }, onUpdate }: Company.Props, ref: any) => {
	const [suggested, _setSugggested] = useState<CompanyState>(initialSuggestsState);
	useEffect(() => {
		getDefaultOptions();
	}, []);

	const setSugggested = (suggested: Model.Company[]) => {
		const choices = mapSuggested(suggested);
		_setSugggested({ choices, suggested });
	};

	const mapSuggested = (suggested: Model.Company[]) => {
		const choices = suggested.map((c) => {
			return { label: c.name, value: c.uuid };
		});
		choices.unshift({ label: '      ', value: 'cleanId' });

		return choices;
	};

	const getDefaultOptions = async () => {
		const suggests = await getSuggestedCompanies();
		setSugggested(suggests || []);
	};

	const getSuggestedCompanies = async (value?: string) => {
		let suggests = null;
		try {
			suggests = await suggestsCompanyByPattern(value);
		} catch (error) {
			// @ignore
			console.error(error);
		}

		return suggests;
	};

	const handleUpdate = (name: string, value: any) => {
		onUpdate(name, value || (undefined as any));
	};

	const loadingOptions = async (input: any, callBack: any) => {
		//console.log('loadingOptions', inputValue);
		if (!input || (input && input.length > 2)) {
			const suggests = await getSuggestedCompanies(input);
			const options = mapSuggested(suggests || []);
			callBack(options);
		} else {
			callBack(choices);
		}
	};

	const { choices } = suggested;

	const selectProps = {
		onUpdate: handleUpdate,
		ref,
		field: {
			name,
			label,
			localized,
			choices,
			hidden,
			required: true,
			disabled: false,
			type: 'Select' as any,
			disableLocalizeForOption: true,
			rounded: true,
			async: true,
			inputProps: {
				loadingOptions,
			},
		},
		form: {},
	};

	return <Select {...selectProps} />;
};

export const CompanyPreview = React.forwardRef(_c);
