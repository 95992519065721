import * as React from 'react';
import _ from 'lodash';

import * as Mapbox from 'mapbox-gl';
import * as turf from '@turf/turf';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import { decode as decodePolyline } from '@mapbox/polyline';
import MapboxLanguage from '@mapbox/mapbox-gl-language';

import { MapsConfig, getDirection } from 'app/api';
import { Place } from 'app/models';

const fromIcon = require('../../../assets/icons/from.png');
const toIcon = require('../../../assets/icons/to.png');

export const mapStyleURL = 'mapbox://styles/mapbox/light-v9';
const Map = ReactMapboxGl({ accessToken: MapsConfig.MAPBOX_TOKEN });

const metersToPixelsAtMaxZoom = (meters: number, latitude: number) =>
	meters / 0.075 / Math.cos((latitude * Math.PI) / 180);

export const MIN_DISTANCE = 1000;

export default class StaticMap extends React.Component<StaticMap.Props, StaticMap.State> {
	private map: Mapbox.Map | null = null;
	public state: StaticMap.State = {};

	constructor(props: StaticMap.Props) {
		super(props);

		this.refreshDirectionsPolyline = this.refreshDirectionsPolyline.bind(this);
		this.fitCircleRadiusBounds = this.fitCircleRadiusBounds.bind(this);
		this.fitDirectionBounds = this.fitDirectionBounds.bind(this);

		this.onMapLoaded = this.onMapLoaded.bind(this);
	}

	public async componentDidUpdate(prevProps: StaticMap.Props) {
		const { from, to, onUpdate } = this.props;

		const fromEqual = _.isEqual(prevProps.from, from);
		const toEqual = _.isEqual(prevProps.to, to);
		const bothOfPointsEqual = _.isEqual(from, to);

		if (!from || !to) {
			await this.setState({ directions: undefined });
		}

		if ((!fromEqual || !toEqual) && !bothOfPointsEqual) {
			if (from && to) {
				if (_.isEqual(from.location, to.location)) {
					// Clear directions line
					const radius = metersToPixelsAtMaxZoom(MIN_DISTANCE, from.location.latitude);
					const distance = MIN_DISTANCE; //  default base dstance

					this.setState({
						directions: undefined,
						distance,
						radius,
					});

					if (onUpdate) {
						onUpdate({ distance });
					}
				} else {
					this.refreshDirectionsPolyline(from, to, 50);
				}
			}
		}
	}

	public async componentDidMount() {
		const { from, to, onUpdate } = this.props;

		if (from && to) {
			if (_.isEqual(from.location, to.location)) {
				const radius = metersToPixelsAtMaxZoom(MIN_DISTANCE, from.location.latitude);
				const distance = MIN_DISTANCE; // default base dstance

				this.setState({
					directions: undefined,
					distance,
					radius,
				});

				if (onUpdate) {
					onUpdate({ distance });
				}
			} else {
				this.refreshDirectionsPolyline(from, to, 350);
			}
		}
	}

	private async fitDirectionBounds() {
		const { directions } = this.state;
		const { map } = this;

		if (map && directions) {
			map.resize();

			const bounds = directions.reduce(
				(_bounds: any, coord: any) => _bounds.extend(coord),
				new Mapbox.LngLatBounds(directions[0], directions[0]),
			);

			await Promise.delay(50);
			map.fitBounds(bounds, {
				animate: false,
				padding: 20,
			});
		}
	}

	private async fitCircleRadiusBounds() {
		const { from } = this.props;
		const { map } = this;

		if (map && from) {
			const location = turf.point([from.location.longitude, from.location.latitude]);
			const buffered = turf.buffer(location, 8, { units: 'kilometers' });
			const box = turf.bbox(buffered);
			// const polygon = turf.bboxPolygon(turf.bbox(buffered))

			map.resize();
			await Promise.delay(50);

			map.fitBounds(box as any, {
				animate: false,
				padding: 20,
			});
		}
	}

	private async refreshDirectionsPolyline(from: Place, to: Place, delayBeforeFit: number) {
		const { onUpdate } = this.props;

		try {
			const direction = await getDirection(from.location, to.location);
			const { distance, geometry } = direction.routes[0];

			const coordinates = decodePolyline(geometry, 5).map((c) => {
				return c.reverse() as [number, number];
			});

			await this.setState({
				directions: [...coordinates],
				distance,
			});

			if (onUpdate) {
				onUpdate({ distance });
			}

			await Promise.delay(delayBeforeFit);
			this.fitDirectionBounds();
		} catch (error) {
			// console.error(error)
		}
	}

	private async onMapLoaded(map: Mapbox.Map) {
		const { directions, radius } = this.state;

		this.map = map;

		const lang = new MapboxLanguage({ defaultLanguage: 'ru' }) as any;
		map.addControl(lang);

		map.loadImage(fromIcon, (error: Error, image: any) => {
			if (error) return;
			(map as any).style && map.addImage('from', image);
		});

		map.loadImage(toIcon, (error: Error, image: any) => {
			if (error) return;
			(map as any).style && map.addImage('to', image);
		});

		if (directions) {
			this.fitDirectionBounds();
		} else if (radius) {
			this.fitCircleRadiusBounds();
		}
	}

	public render() {
		const { directions, radius } = this.state;
		const { width, from } = this.props;

		const mapWidth = width ? width : '100%';

		const mapProps: any = {
			containerStyle: { height: '190px', width: mapWidth },
			onStyleLoad: this.onMapLoaded,
			style: mapStyleURL,
		};

		const className = `static-map ${directions || radius ? 'opened' : 'closed'}`;

		return (
			<div tabIndex={-1} className={className}>
				<Map {...mapProps}>
					{!directions
						? null
						: [
								<Layer
									paint={{ 'line-color': '#64C8EB', 'line-width': 4 }}
									layout={{ 'line-cap': 'round', 'line-join': 'round' }}
									key='layer-directions'
									type='line'
								>
									<Feature coordinates={directions} />
								</Layer>,

								<Layer layout={{ 'icon-image': 'from' }} key='layer-from-icon' id='marker-from' type='symbol'>
									<Feature coordinates={directions[0]} />
								</Layer>,

								<Layer layout={{ 'icon-image': 'to' }} key='layer-to-icon' id='marker-to' type='symbol'>
									<Feature coordinates={directions[directions.length - 1]} />
								</Layer>,
						  ]}

					{!directions && radius && from
						? [
								<Layer
									key='layer-radius-circle'
									type='circle'
									paint={{
										'circle-color': '#64C8EB',
										'circle-opacity': 0.3,
										'circle-radius': {
											stops: [
												[0, 0],
												[20, radius],
											],
											base: 2,
										},
									}}
								>
									<Feature coordinates={[from.location.longitude, from.location.latitude]} />
								</Layer>,

								<Layer layout={{ 'icon-image': 'from' }} key='layer-from-icon' id='marker-from' type='symbol'>
									<Feature coordinates={[from.location.longitude, from.location.latitude]} />
								</Layer>,
						  ]
						: null}
				</Map>
			</div>
		);
	}
}

export namespace StaticMap {
	export interface State {
		directions?: Array<[number, number]>;
		radius?: number;

		distance?: number;
	}

	export interface Props {
		onUpdate?: (value: State) => void;
		width?: number | string;
		from?: Place;
		to?: Place;
	}
}
