import styled from 'styled-components';
import PrimaryButton from './Primary';

const Secondary = styled(PrimaryButton)`
	background: none;
	border: 1px solid #64c8eb;
	color: #64c8eb;
`;

export default Secondary;
