import {
	SET_VACANT_EXECUTORS_LOADING,
	// SET_VACANT_EXECUTORS_UPDATING,
	ADD_VACANT_EXECUTORS_TO_LIST,
	SET_VACANT_EXECUTOR_DETAIL,
	SET_VACANT_EXECUTORS_LIST,
} from 'app/actions/vacantExecutors';
import { Action, handleActions } from 'redux-actions';

import { Executor } from 'app/models';

type State = {
	detail: Executor | null;
	data: Executor[];
	next: string | null;
	prev: string | null;
	loading: boolean;
	updating: boolean;
};

export const vacantExecutorsReducer = handleActions<State, any>(
	{
		[SET_VACANT_EXECUTOR_DETAIL]: (state, action: Action<Executor>) => ({
			...state,
			detail: action.payload !== undefined ? action.payload : state.detail,
		}),
		[SET_VACANT_EXECUTORS_LOADING]: (state, action: Action<boolean>) => ({
			...state,
			loading: action.payload ?? state.loading,
		}),

		[SET_VACANT_EXECUTORS_LIST]: (state, action: Action<any>) => ({
			...state,
			data: action.payload.data,
			next: action.payload.next,
			prev: action.payload.prev,
		}),
		[ADD_VACANT_EXECUTORS_TO_LIST]: (state, action: Action<any>) => ({
			...state,
			data: [...state.data, ...action.payload.data],
			next: action.payload.next,
			prev: action.payload.prev,
		}),
	},
	{
		detail: null,
		data: [],
		prev: null,
		next: null,
		loading: false,
		updating: false,
	},
);
