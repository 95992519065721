import React from 'react';

export const Views: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='32' height='32' viewBox='4 8 32 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M22.8519 15.7739C22.8264 15.7351 22.2164 14.8136 21.0744 13.8879C19.551 12.6528 17.7963 12 16 12C14.2037 12 12.449 12.6528 10.9256 13.8879C9.78363 14.8136 9.1736 15.7351 9.14818 15.7739L9 16L9.14818 16.2261C9.1736 16.2649 9.78363 17.1864 10.9256 18.1121C12.449 19.3472 14.2037 20 16 20C17.7962 20 19.551 19.3472 21.0744 18.1121C22.2164 17.1864 22.8264 16.2649 22.8519 16.2261L23 16L22.8519 15.7739Z'
			stroke={color}
			strokeWidth='2'
		/>
		<path
			d='M16 17C15.4477 17 15 16.5523 15 16C15 15.4477 15.4477 15 16 15C16.5523 15 17 15.4477 17 16C17 16.5523 16.5523 17 16 17Z'
			stroke='#6C6F88'
			strokeWidth='2'
			strokeLinecap='square'
		/>
	</svg>
);

export default Views;
