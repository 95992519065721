import { handleActions, Action } from 'redux-actions';

import { filterMap } from 'app/utils';
import { DriverActions } from 'app/actions';
import { Certificate, Insurance, DriverCar, Transport } from 'app/models';

interface CarsState {
	[uuid: string]: DriverCar;
}

const carsReducer = handleActions<CarsState, any>(
	{
		[DriverActions.Action.ADD_TRANSPORT]: (state, { payload }: Action<DriverCar>) => ({
			...state,
			...(payload ? { [payload.uuid]: payload } : {}),
		}),
		[DriverActions.Action.DELETE_TRANSPORT]: (state, { payload }: Action<string>) =>
			payload ? filterMap(state, (value: any) => value.uuid !== payload) : state,
		[DriverActions.Action.UPDATE_TRANSPORT_PHOTOS]: (state, { payload }: Action<any>) =>
			payload
				? {
						...state,
						[payload.uuid]: {
							...state[payload.uuid],
							transport: {
								...state[payload.uuid].transport,
								avatar: payload.avatar,
								photo: payload.photo,
							},
						},
				  }
				: state,
		[DriverActions.Action.DELETE_DOCUMENT]: (state, { payload }: Action<DriverActions.Payload.DeleteDoc>) =>
			payload
				? {
						...state,
						[payload.transportId]: {
							...state[payload.transportId],

							[payload.type]: filterMap<Insurance | Certificate>(
								state[payload.transportId][payload.type],
								(value) => value.uuid !== payload.id,
							),
						},
				  }
				: state,
		[DriverActions.Action.UPDATE_CERTIFICATE]: (
			state,
			{ payload }: Action<DriverActions.Payload.Docs<Certificate>>,
		) => {
			const transportId = payload?.transportId;

			if (!transportId) return state;

			return {
				...state,
				[transportId]: {
					...state[transportId],
					certificates: {
						...state[transportId].certificates,
						[payload.data.uuid]: {
							...state[transportId].certificates[payload.data.uuid],
							...payload.data,
						},
					},
				},
			};
		},
		[DriverActions.Action.UPDATE_INSURANCE]: (state, { payload }: Action<DriverActions.Payload.Docs<Insurance>>) => {
			const transportId = payload?.transportId;

			if (!transportId) return state;

			return {
				...state,
				[payload.transportId]: {
					...state[payload.transportId],
					insurances: {
						...state[payload.transportId].insurances,
						[payload.data.uuid]: {
							...state[payload.transportId].insurances[payload.data.uuid],
							...payload.data,
						},
					},
				},
			};
		},
		[DriverActions.Action.ADD_INSURANCE]: (state, { payload }: Action<DriverActions.Payload.Docs<Insurance>>) =>
			payload
				? {
						...state,
						[payload.transportId]: {
							...state[payload.transportId],
							insurances: {
								...state[payload.transportId].insurances,
								[payload.data.uuid]: payload.data,
							},
						},
				  }
				: state,
		[DriverActions.Action.SET_CERTIFICATES]: (state, { payload }: Action<DriverActions.Payload.Docs<Certificate[]>>) =>
			payload
				? {
						...state,
						[payload.transportId]: {
							...state[payload.transportId],

							certificates: payload.data.reduce((obj: any, item: any) => {
								obj[item.uuid] = item;
								return obj;
							}, {}),
						},
				  }
				: state,
		[DriverActions.Action.SET_CAR_CERTIFICATES]: (state, { payload }: Action<DriverActions.Payload.Certificates>) => {
			if (!payload) {
				return state;
			}
			const { uuid, data } = payload;

			return { ...state, [uuid]: { ...state[uuid], certificates: { ...data } } };
		},

		[DriverActions.Action.SET_CAR_INSURANCES]: (state, { payload }: Action<DriverActions.Payload.Insurances>) => {
			if (!payload) {
				return state;
			}
			const { uuid, data } = payload;
			return { ...state, [uuid]: { ...state[uuid], insurances: { ...data } } };
		},
		[DriverActions.Action.UPDATE_TRANSPORT]: (state, { payload }: Action<Transport>) => {
			return payload
				? {
						...state,
						[payload.uuid]: {
							...state[payload.uuid],
							transport: {
								...state[payload.uuid].transport,
								...payload,
							},
						},
				  }
				: state;
		},
		[DriverActions.Action.SET_CERTIFICATES_LOADING]: (state, { payload: uuid }: any) => {
			return uuid
				? {
						...state,
						[uuid]: {
							...state[uuid],
							certificates: { loading: true },
						},
				  }
				: state;
		},
		[DriverActions.Action.SET_INSURANCES_LOADING]: (state, { payload: uuid }: any) => {
			return uuid
				? {
						...state,
						[uuid]: {
							...state[uuid],
							insurances: { loading: true },
						},
				  }
				: state;
		},
	},
	{},
);

export default carsReducer;
