import { createAction } from 'redux-actions';

export const SET_BID_POINTS = 'SET_BID_POINTS';
export const GET_BID_POINTS = 'GET_BID_POINTS';
export const SET_BID_POINTS_LOADING = 'SET_BID_POINTS_LOADING';

export enum BidsType {
	ARCHIVE = 'archive',
	ALL = 'all',
	MY = 'my',
}

export type SetBidPointsPayload = {
	type: string;
	data: any[];
};

export const getBidPoints = createAction<BidsType>(GET_BID_POINTS);
export const setBidPointsLoading = createAction<boolean>(SET_BID_POINTS_LOADING);
export const setBidPoints = createAction<SetBidPointsPayload>(SET_BID_POINTS);
