import { createAction } from 'redux-actions';
import { Company, LegalTypeEnum, Transport } from 'app/models';

type CompanySmall = {
	name: string;
	uuid: string;
};

export type User = {
	id: string;
	avatar: string;
	photo: string;
	firstName: string;
	lastName: string;
	middleName: string;
	companyName: string;
	legalType: LegalTypeEnum;
	interestedInDigitalSignature: boolean;
	phone: string;
	isVerified: boolean;
	verificationStatus: string;
	extendedSignature: boolean;
};

type Offer = {
	id: string;
	uuid: string;
	user: User;
	startAt: string;
	price: string;
	status: string;
	state: string;
	createdAt: Date;
};

export type TransportOffer = {
	company: CompanySmall;
	offersCount: number;
	transport: Transport;
	driver?: User | {};
};

export type TransportOfferPayload = {
	data: TransportOffer[];
	next: string | null;
	previous: string | null;
};

export type TransportDetailsPayload = {
	transport: Transport;
	company: CompanySmall;
	offers: Offer[];
	driver?: User;
};

type GetCustomerOffersPayload = {
	cursor: string;
};

export type СonfirmDeclineCustomerOfferPayload = {
	id: string;
	offer: string;
};

export type GetCustomerOfferDetailsPayload = {
	id: string;
	updateOffer?: boolean;
};

export const GET_CUSTOMER_COMPANIES = 'GET_CUSTOMER_COMPANIES';
export const SET_CUSTOMER_COMPANIES = 'SET_CUSTOMER_COMPANIES';
export const GET_CUSTOMER_OFFERS = 'GET_CUSTOMER_OFFERS';
export const SET_CUSTOMER_OFFERS = 'SET_CUSTOMER_OFFERS';
export const ADD_CUSTOMER_OFFERS = 'ADD_CUSTOMER_OFFERS';
export const SET_CUSTOMER_OFFERS_LOADING = 'SET_CUSTOMER_OFFERS_LOADING';
export const GET_CUSTOMER_OFFER_DETAILS = 'GET_CUSTOMER_OFFER_DETAILS';
export const SET_CUSTOMER_OFFER_DETAILS = 'SET_CUSTOMER_OFFER_DETAILS';
export const SET_CUSTOMER_OFFER_DETAILS_LOADING = 'SET_CUSTOMER_OFFER_DETAILS_LOADING';
export const CONFIRM_CUSTOMER_OFFER = 'CONFIRM_CUSTOMER_OFFER';
export const DECLINE_CUSTOMER_OFFER = 'DECLINE_CUSTOMER_OFFER';
export const UPDATE_CUSTOMER_OFFER = 'UPDATE_CUSTOMER_OFFER';
export const REMOVE_CUSTOMER_OFFER = 'REMOVE_CUSTOMER_OFFER';

export const getCustomerCompanies = createAction<void>(GET_CUSTOMER_COMPANIES);
export const setCustomerCompanies = createAction<Company[]>(SET_CUSTOMER_COMPANIES);
export const getCustomerOffers = createAction<GetCustomerOffersPayload | void>(GET_CUSTOMER_OFFERS);
export const setCustomerOffers = createAction<TransportOfferPayload>(SET_CUSTOMER_OFFERS);
export const addCustomerOffers = createAction<TransportOfferPayload>(ADD_CUSTOMER_OFFERS);
export const setCustomerOffersLoading = createAction<any>(SET_CUSTOMER_OFFERS_LOADING);

export const getCustomerOffersDetails = createAction<GetCustomerOfferDetailsPayload>(GET_CUSTOMER_OFFER_DETAILS);
export const setCustomerOffersDetails = createAction<TransportDetailsPayload>(SET_CUSTOMER_OFFER_DETAILS);
export const setCustomerOffersDetailsLoading = createAction<boolean>(SET_CUSTOMER_OFFER_DETAILS_LOADING);
export const confirmCustomerOffer = createAction<СonfirmDeclineCustomerOfferPayload>(CONFIRM_CUSTOMER_OFFER);
export const declineCustomerOffer = createAction<СonfirmDeclineCustomerOfferPayload>(DECLINE_CUSTOMER_OFFER);
export const updateCustomerOffer = createAction<any>(UPDATE_CUSTOMER_OFFER);
export const removeCustomerOffer = createAction<string>(REMOVE_CUSTOMER_OFFER);
