export enum CandidateStatus {
	OPEN = 'open',
	CONFIRMED = 'confirmed',
}

export interface User {
	id: string;
	avatar?: any;
	photo?: any;
	firstName: string;
	lastName: string;
	middleName: string;
	companyName: string;
	legalType: string;
	interestedInDigitalSignature: boolean;
	isVerified: boolean;
	extendedSignature: boolean;
}

export interface Contact {
	firstName: string;
	lastName: string;
	middleName: string;
	phone: string;
	avatar?: any;
	photo?: any;
	isVerified: boolean;
}

export interface Addition {}

export interface TransportType {
	id: number;
	name: string;
	type: string;
	addition: Addition;
	uuid: string;
	category: string;
}

export interface TransportData {
	uuid: string;
	id: string;
	transportType: TransportType;
	status: string;
	isVerified: boolean;
	ident: string;
	trailerIdent: string;
	gross: number;
	height: number;
	length: number;
	avatar?: any;
	photo?: any;
}

export interface Transport {
	contact: Contact;
	transport: TransportData;
}

export interface CandidateData {
	id: string;
	uuid: string;
	user: User;
	transports: Transport[];
	price: number;
	status: CandidateStatus;
	createdAt: Date;
	capabilities?: string[];
}
