import React, { MouseEvent } from 'react';
import Modal from 'react-modal';
import { Close as CloseIcon } from 'app/components/Icons';

interface ConfirmModalProps {
	onConfirm: Function;
	onCancel: Function;
	children?: React.ReactNode;
	isOpen: boolean;
	text: String;
	confirmBtnText?: String;
}

const ConfirmModal = ({ onConfirm, onCancel, isOpen, text, confirmBtnText, children }: ConfirmModalProps) => {
	const handleConfirm = (e: MouseEvent) => onConfirm(e);
	const handleCancel = (e: MouseEvent) => onCancel(e);
	return (
		<Modal key='confirm-modal' className='confirm-modal' isOpen={isOpen}>
			<div>
				<div className='close-btn'>
					<CloseIcon onClick={handleCancel} color='gray' />
				</div>
				<div className='content'>{text || children}</div>
				<div className='action-buttons'>
					<div className='action' onClick={handleConfirm}>
						{confirmBtnText}
					</div>
				</div>
			</div>
		</Modal>
	);
};

ConfirmModal.defaultProps = {
	confirmBtnText: 'Сохранить',
};

export default ConfirmModal;
