import React, { useState, useEffect, createContext, useContext, useMemo } from 'react';

//other deps
import { useHistory } from 'react-router-dom';

//redux
import { useDispatch, useSelector } from 'react-redux';

import {
	BidActions,
	CommonActions,
	addTransportInfoArray,
	getCandidateList,
	getCandidateListMore,
	setFilter,
} from 'app/actions';

import { getCustomerOffersDetails } from 'app/actions/customer';
import { confirmCandidateOffers, declineCandidateOffers } from 'app/actions/candidates';

import {
	bidDetails as bidDetailsSelector,
	candidates as candidatesSelector,
	filters as filtersSelector,
	transport as transportSelector,
} from 'app/selectors';

//components
import { GMap } from 'app/components/GMap';

import {
	BidInfo,
	BidList,
	BidMapBubble,
	Content,
	Filters,
	FiltersLeftPart,
	FiltersRightPart,
	GroupFilter,
	LeftPanel,
	MapContainer,
	MapZone,
	StageFilter,
	StatusFilter,
	TransportsWebSocket,
} from './components';

import { OffersByTransport } from './offersByTransport/OffersByTransport';
import { Details as SelectedTransportDetails } from './offersByTransport/Details';

//types
import { SidebarType } from 'app/models';
import { CandidateStatus } from './components/types/CandidateData';

//constants
const statusMapper = {
	open: 'free',
	confirmed: 'busy',
};

//helpers
import { getUrlParam } from 'app/utils';

import {
	parseRouteFromBid,
	parseLocation,
	directionMapper,
	findBidByTransportId,
	getTransportsByBidId,
} from 'app/utils/transport';

//context
export const MyTransportContext = createContext({});
export const useMyTransportContext = () => useContext(MyTransportContext);

//----------------------------------------------------------
// Candidates
//----------------------------------------------------------
export const Candidates: React.FC = () => {
	//helpers
	const history = useHistory();
	const dispatch = useDispatch();

	//state
	const [status, setStatus] = useState<CandidateStatus | undefined>();
	const [byTransport, setByTransport] = useState(false);
	const [selectedTransport, setSelectedTransport] = useState<any>({});
	const [routeToShow, setRouteToShow] = useState<any>(null);
	const [selectedBid, setSelectedBid] = useState<string | null>(null);
	const [selectedBids, setSelectedBids] = useState<string[]>([]);
	const [showSelectedTransport, setShowSelectedTransport] = useState<boolean>(false);
	const [chosenTransport, setChosenTransport] = useState({});
	const [selectedOffers, setSelectedOffers] = useState([]);
	const [zoomToPoint, setZoomToPoint] = useState(undefined);

	//selectors
	const { filter: filterOnMap, bounds } = useSelector((state: any) => state?.transportOnMap);
	const filters = useSelector(filtersSelector);
	const candidates = useSelector(candidatesSelector);
	const loading = useSelector((state: any) => state.candidates.loading);
	const selectedBidDetails = useSelector(bidDetailsSelector);

	const transportInBid = useMemo(
		() => selectedBid && selectedBidDetails?.offers?.map((offer: any) => offer?.transports?.[0]?.transport?.uuid),
		[selectedBidDetails, selectedBid],
	);

	const transport = useSelector(transportSelector('my', transportInBid))
		.filter((item: any) => (status ? item.status === statusMapper[status] : true))
		.map((item: any) => {
			return {
				id: item.transportId,
				location: { lat: item?.position?.latitude, lng: item?.position?.longitude },
				data: {
					...item,
					statusInBid:
						selectedBid && selectedBid === item?.bid?.bidId ? (item.status === 'busy' ? 'confirmed' : 'open') : 'free',

					route: (item.bid && parseRouteFromBid(item.bid)) || null,
					//@ts-ignore
					direction: item.offerStatus ? directionMapper[item.offerStatus as directionMapper] : null,
				},
			};
		});

	const selectedTransportByOffers = transport.reduce((acc: any, item: any) => {
		//@ts-ignore
		if (selectedOffers.includes(item?.data?.offerId)) {
			acc[item.id] = true;
		}
		return acc;
	}, {});

	const checkRouteForBidId = () => {
		const urlBidId = getUrlParam('bidId');
		if (urlBidId && selectedBid !== urlBidId) {
			handleBidSelect(urlBidId);
		}
	};
	const checkRouteForTransportId = () => {
		const urlTransportId = getUrlParam('transportId');
		if (urlTransportId) {
			setByTransport(true);
		}
	};

	const handleShowBidDetails = (bidId: string) => {
		dispatch(BidActions.getDetailsBid(bidId));
		dispatch(CommonActions.toggleSidebar({ type: SidebarType.OrderDetails, value: true }));
	};

	const handleLoadMore = () => {
		if (!loading) dispatch(getCandidateListMore());
	};
	const handleConfirm = (bidId: string, offerIds: string | string[]) => {
		dispatch(confirmCandidateOffers({ id: bidId, offers: Array.isArray(offerIds) ? offerIds : [offerIds] }));
	};
	const handleDecline = (bidId: string, offerIds: string | string[]) => {
		dispatch(declineCandidateOffers({ id: bidId, offers: Array.isArray(offerIds) ? offerIds : [offerIds] }));
	};
	const loadTransport = (id: any) => {
		dispatch(getCustomerOffersDetails({ id }));
		setShowSelectedTransport(true);
	};

	const handleMarkerClick = (id: string, data: any) => {
		//@ts-ignore
		setChosenTransport({ ...chosenTransport, [data?.transportId]: !chosenTransport[data?.transportId] });

		const bidInList = candidates.find((i: any) => i?.bid?.id === data?.bid?.id);

		if (!bidInList) {
			loadTransport(data.transportId);
			return;
		}
		if (selectedBid !== data?.bid?.bidId && !byTransport) handleBidSelect(data?.bid?.bidId);
	};

	const handleShowRoute = ({ from, to, id }: any) => {
		setRouteToShow(
			routeToShow?.id === id
				? undefined
				: {
						origin: parseLocation(from.location),
						destination: parseLocation(to.location),
						id,
				  },
		);
	};

	const handleBidSelect = (bidId: string) => {
		setSelectedBid(selectedBid === bidId ? null : bidId);
		const bidInList = candidates.find((i: any) => i?.bid?.id === bidId);

		if (bidInList?.bid) {
			const { from, to } = bidInList.bid.direction;
			handleShowRoute({
				from,
				to,
				id: bidId,
			});
		}
		setSelectedOffers([]);
		const urlQuery = bidId && selectedBid !== bidId ? `?bidId=${bidId}` : '';
		history.push(`/panel/transport/my/${urlQuery}`);
	};

	const handleClusterSelect = (items: any[]) => {
		setSelectedBids(items.map((item: any) => item?.properties?.data?.bid?.bidId));
		const newSelectedTransport = items.reduce((acc: any, item: any) => {
			acc[item?.properties?.data?.transportId] = true;
			return acc;
		}, {});
		setSelectedTransport({ ...newSelectedTransport });
	};

	const handleMarkerSelect = (id: string, data: any) => {
		setSelectedTransport({ [id]: !selectedTransport[id] });

		if (data) {
			setSelectedBids([data?.bid?.bidId]);
		} else {
			setSelectedBids([]);
		}
	};

	const handleOfferCardSelect = (selectedTransport: { [key: string]: boolean }) => {
		setSelectedTransport(selectedTransport);
	};

	const handleOfferCardExpand = (transportId: any, expanded: boolean) => {
		if (!transportId) return;

		const selectedWSTransport: any = transport.find((item: any) => item.id === transportId);

		if (selectedWSTransport && expanded) {
			setZoomToPoint(selectedWSTransport.location);
			const candidate: any = findBidByTransportId(transportId, candidates);

			if (candidate && candidate.bid && candidate.bid.direction) {
				setRouteToShow({
					origin: parseLocation(candidate.bid.direction.from.location),
					destination: parseLocation(candidate.bid.direction.to.location),
					id: candidate.bid.id,
				});
			}
		}
	};

	const handleSetSelectedOffers = (offerId: string) => {
		if (offerId === 'clear') {
			setSelectedOffers([]);
			return;
		}
		//@ts-ignore;
		if (selectedOffers.includes(offerId)) setSelectedOffers(selectedOffers.filter((id: any) => id !== offerId));
		//@ts-ignore
		else setSelectedOffers(selectedOffers.concat(offerId));
	};

	const handleFilters = (key: string, value: boolean) => {
		dispatch(setFilter({ [key]: value }));
	};
	const handleMarkerDblClick = (offerId: string) => {
		handleSetSelectedOffers(offerId);
	};

	const handleClusterDblClick = (offerIds: string[] = []) => {
		const updatedSelectedOffers = [...selectedOffers];

		offerIds.forEach((offerId: string) => {
			//@ts-ignore
			const index = updatedSelectedOffers.indexOf(offerId);

			if (index > -1) {
				updatedSelectedOffers.splice(index, 1);
			} else {
				if (selectedBidDetails?.offers?.some((item: any) => item.uuid === offerId)) {
					//@ts-ignore
					updatedSelectedOffers.push(offerId);
				}
			}
		});
		setSelectedOffers(updatedSelectedOffers);
	};

	const handleBidHover = (bidId: string, value: boolean) => {
		const transportInBid = getTransportsByBidId(transport, bidId);

		if (!transportInBid) return;

		const newSelectedTransport = transportInBid.reduce((acc: any, item: any) => ({ ...acc, [item]: value }), {});
		setSelectedTransport(newSelectedTransport);
	};

	const handleBoundsSet = () => {};

	const handleTransportSelect = (id: any) => {
		//@ts-ignore
		const updatedTransport = { ...chosenTransport, [id]: !chosenTransport[id] };
		setChosenTransport(updatedTransport);
	};

	const handleSetByTransport = (value: boolean) => {
		history.push(`/panel/transport/my/`);
		setByTransport(value);
		value && handleBidSelect('');
	};

	const _onGetMessages = (messages: any[]) => {
		dispatch(addTransportInfoArray(messages));
	};

	//effects
	useEffect(() => {
		checkRouteForBidId();
		checkRouteForTransportId();
	}, []);

	useEffect(() => {
		dispatch(getCandidateList({}));
	}, [bounds, filterOnMap]);

	//renders
	return (
		<MyTransportContext.Provider
			value={{
				choosenTransport: chosenTransport,
				selectedBid,
				selectedTransport,
				showBidDetails: handleShowBidDetails,
				onShowBid: handleBidSelect,
				onShowRoute: handleShowRoute,
				onTransportHover: handleOfferCardSelect,
				onTransportSelect: handleTransportSelect,
			}}
		>
			<TransportsWebSocket topic='my' onGetMessages={_onGetMessages} />

			<Content>
				<Filters>
					<FiltersLeftPart>
						<GroupFilter value={byTransport} onChange={handleSetByTransport} />
					</FiltersLeftPart>

					<FiltersRightPart>
						<StatusFilter value={status} onChange={setStatus} />
						<StageFilter filters={filters} onChange={handleFilters} />
					</FiltersRightPart>
				</Filters>

				<MapZone>
					<div>
						<LeftPanel>
							{!byTransport && (
								<BidList
									bidoffers={candidates}
									loading={loading}
									selectedBids={selectedBids}
									selectedBid={selectedBid}
									onSelectBid={handleBidSelect}
									onLoadMore={handleLoadMore}
									handleBidHover={handleBidHover}
								/>
							)}

							{byTransport && showSelectedTransport && (
								<SelectedTransportDetails
									onShowBid={handleBidSelect}
									onShowRoute={handleShowRoute}
									onClose={() => setShowSelectedTransport(false)}
								/>
							)}

							{!showSelectedTransport && byTransport && (
								<OffersByTransport
									onShowBid={handleBidSelect}
									onShowRoute={handleShowRoute}
									onTransportHover={handleOfferCardSelect}
									onTransportSelect={handleTransportSelect}
									selectedTransport={selectedTransport}
									choosenTransport={chosenTransport}
								/>
							)}
						</LeftPanel>

						<MapContainer>
							{selectedBid && (
								<BidInfo
									bidId={selectedBid}
									status={status}
									//@ts-ignore
									onOfferExpand={handleOfferCardExpand}
									choosenTransport={chosenTransport}
									selectedTransport={selectedTransport}
									onShowRoute={handleShowRoute}
									onConfirm={handleConfirm}
									onDecline={handleDecline}
									selectedOffers={selectedOffers}
									onOfferHover={handleMarkerSelect}
									onClose={handleBidSelect}
									//@ts-ignore
									setSelectedOffers={handleSetSelectedOffers}
								/>
							)}

							<GMap
								showMapMoveSync
								showZoomButtons
								routeToShow={routeToShow}
								zoomToPoint={zoomToPoint}
								markers={transport}
								// handleMarkersRender={handleMarkersRender}
								onMarkerClick={handleMarkerClick}
								onMarkerDblClick={handleMarkerDblClick}
								hintComponent={BidMapBubble}
								onClusterSelect={handleClusterSelect}
								onClusterDblClick={handleClusterDblClick}
								onMarkerSelect={handleMarkerSelect}
								highlightedMarkers={selectedTransport}
								selectedMarkers={selectedTransportByOffers}
								onBoundsSet={handleBoundsSet}
							/>
						</MapContainer>
					</div>
				</MapZone>
			</Content>
		</MyTransportContext.Provider>
	);
};
