import styled from 'styled-components';

export const MainLayout = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;

	display: flex;
	flex-direction: column;
`;

export const LayoutContent = styled.div`
	flex-grow: 1;
	position: relative;
	& > div {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.page {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;

			display: flex;
			flex-direction: column;
		}
	}
`;

export const LayoutRow = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	flex-grow: 1;
`;
