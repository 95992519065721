import styled from 'styled-components';
import DefaultButtonStyle from './Default';

export const Primary = styled(DefaultButtonStyle)`
	background: #64c8eb;
	color: #fff;

	&:disabled {
		background-color: #f8f8f8;
		color: #d0d0d4;
	}
`;

export default Primary;
