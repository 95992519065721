import React from 'react';
interface HoverPopupProps {
	icon?: string;
	text: any;
	position?: 'bottom' | 'top' | 'left' | 'right';
	children?: React.ReactNode;
}

const HoverPopup = (props: HoverPopupProps) => {
	const { icon, text, position = 'top', children } = props;
	const classList = ['hover-popup'];
	if (position) {
		classList.push('hover-popup--' + position);
	}
	return (
		<div className={classList.join(' ')} data-text={text} data-icon={icon || 'ℹ️'}>
			{children}
		</div>
	);
};

export default HoverPopup;
