import React from 'react';
import styled from 'styled-components';

const TransitionDuration = 0.15;
const SwitchContainer = styled.label`
	display: inline-block;
`;

const HiddenInput = styled.input.attrs({ type: 'checkbox' })`
	display: none;
`;

const StyledInput = styled.div`
	background: rgba(191, 191, 191, 0.5);
	transition: ${TransitionDuration}s;
	border-radius: 7px;
	position: relative;
	cursor: pointer;
	height: 14px;
	width: 34px;

	&:before {
		transition: ${TransitionDuration}s;
		background: rgb(193, 193, 193);
		border-radius: 10px;
		position: absolute;
		margin-top: -10px;
		display: block;
		margin-left: 0;
		height: 20px;
		content: '';
		width: 20px;
		top: 50%;
		left: 0;

		box-shadow:
			0px 2px 2px rgba(0, 0, 0, 0.24),
			0px 0px 2px rgba(0, 0, 0, 0.12);
	}

	${HiddenInput}:checked + & {
		background: rgba(100, 200, 235, 0.5);
		&:before {
			left: 100%;
			margin-left: -20px;
			background: #64c8eb;
		}
	}
`;

interface SwitchProps {
	value?: Boolean;
	onChange?: (event: any) => void;
}

const Switch: React.FC<any> = (props) => {
	const InputProps = {
		checked: props.value,
		onChange: (event: any) => props.onChange?.(event.target.checked),
	};
	return (
		<SwitchContainer>
			<HiddenInput {...InputProps} />
			<StyledInput />
		</SwitchContainer>
	);
};

export { SwitchProps };
export default Switch;
