import React from 'react';

//other deps
import clsx from 'clsx';
import Loader, { LoaderProps } from 'react-loader-spinner';

//types
interface LoadingIndicatorProps {
	align?: string;
	color?: string;
}

//constants
const DEFAULT_OPTIONS = {
	color: '#2196f3',
	width: 34,
	height: 34,
	type: 'Oval',
	align: 'center',
};

//----------------------------------------------------------
// LoadingIndicator
//----------------------------------------------------------
export default function LoadingIndicator(props: LoadingIndicatorProps) {
	//data
	const LoaderProps = {
		...DEFAULT_OPTIONS,
		...props,
		align: undefined,
	};

	const classList = clsx({
		['loading-indicator--' + props.align]: true,
	});

	//render
	return (
		<div className={classList}>
			<Loader {...(LoaderProps as LoaderProps)} />
		</div>
	);
}
