import { BidApiModel } from 'app/api/types';
import { Field } from 'app/containers/Builder/props';

type TransportType = {
	[name: string]: BidApiModel.CommonMeta;
};

//helpers
export const getTransportTypes = (transportTypes?: TransportType): Array<Field.Choice<string>> => {
	const types = transportTypes ? transportTypes : {};

	return Object.keys(types).map(
		(transportTypeKey): Field.Choice<string> => ({
			label: types[transportTypeKey].name,
			value: types[transportTypeKey].id,
		}),
	);
};

export const getTrailerTypes = (trailerTypes?: TransportType): Array<Field.Choice<string>> => {
	const types = trailerTypes ? trailerTypes : {};

	return Object.keys(types).map(
		(trailerTypeKey): Field.Choice<string> => ({
			label: types[trailerTypeKey].name,
			value: types[trailerTypeKey].id,
		}),
	);
};

export const getTransportBrands = (transportBrands?: BidApiModel.CommonMeta[]): Array<Field.Choice<string>> => {
	const types = transportBrands ? transportBrands : [];

	return types.map(
		(brand): Field.Choice<string> => ({
			label: brand.name,
			value: brand.id,
		}),
	);
};

export const findTransportTypeByName = (
	transportTypes: TransportType, // need with trailers
	name: string,
): BidApiModel.CommonMeta | undefined => {
	let transportType: BidApiModel.CommonMeta | undefined;
	const keys = Object.keys(transportTypes);

	for (const key of keys) {
		const value = transportTypes[key];

		if (value.name === name) {
			transportType = value;
			break;
		}
	}

	return transportType;
};
