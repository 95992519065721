import * as React from 'react';
import { Dispatch, bindActionCreators } from 'redux';
import Scrollbars from 'react-custom-scrollbars';
import update from 'immutability-helper';
import { Localized } from '@fluent/react';
import { toast } from 'react-toastify';
import { FluentBundle } from '@fluent/bundle';
import { connect } from 'react-redux';
import _ from 'lodash';

import ConfirmModal from 'app/components/ConfirmModal';
import { SidebarType, BidDetails, Bid } from 'app/models';
import { CommonActions, BidActions } from 'app/actions';
import * as Icons from 'app/components/Icons';
import { BidApiModel } from 'app/api/types';
import { RootState } from 'app/reducers';
import { bidUpdate } from 'app/api';

import { SchemeProps } from 'app/containers/Builder/props';
import { Input } from '../../Builder/components';
import Builder from 'app/containers/Builder';
import SCHEME_CREATE from './scheme';
import validateByScheme from 'app/utils/validate-by-scheme';
import clsx from 'clsx';
import { Rate } from '../../Builder/components';

const closeIcon = require('../../../../assets/icons/close.svg');

const mapStateToProps = ({ common, user }: RootState) => ({
	sessionToken: user.sessionToken,
	locales: common.bundlesLocales,
	bid: common.detailsBid,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			setDetailsBid: CommonActions.setDetailsBid,
			cleanList: BidActions.cleanList,
			getBids: BidActions.getBids,
			deleteBid: BidActions.deleteBid,
		},
		dispatch,
	);

const typeMapper = {
	'₽/т/км': 'т/км',
	'₽/т': 'т',
};
class UpdateOrder extends React.Component<UpdateOrder.Props, UpdateOrder.State> {
	// Instance for direct manipulation local state & props
	public scrollbar: Scrollbars | null = null;
	public builder: Builder | null = null;

	public state: UpdateOrder.State = {
		scheme: _.cloneDeep(SCHEME_CREATE),
		isFormValid: false,
		isFormSubmitting: false,
		showConfirm: false,
	};

	constructor(props: UpdateOrder.Props) {
		super(props);

		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.getSidebarHeader = this.getSidebarHeader.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.reset = this.reset.bind(this);

		this.transformScheme = this.transformScheme.bind(this);

		this.transformScheme();
	}

	private transformScheme() {
		const { bid } = this.props;
		const scheme = _.cloneDeep(SCHEME_CREATE);

		const rateField = scheme.find((e: any) => e.name === 'rate') || {};
		//@ts-ignore
		rateField.defaultValues.currencySymbol = bid.payment.currency.symbol;
		//@ts-ignore
		const { isOverload, isPrepaid, isBargain, hasVat, isCash, isPricePerKm } = bid.payment;
		//@ts-ignore
		rateField.defaultValues = {
			//@ts-ignore
			...rateField.defaultValues,
			isOverload,
			isPrepaid,
			isBargain,
			hasVat,
			isCash,
			isPricePerKm,
		};

		// eslint-disable-next-line
		this.state = {
			...this.state,
			scheme,
		};
	}

	public componentDidMount() {
		const { builder } = this;

		const bid = this.props.bid as BidDetails;
		const { isPricePerKm } = bid.payment;
		const priceProp = isPricePerKm ? 'pricePerKm' : 'price';

		const data = {
			shipmentEnd: bid.deliveryTime.to,
			price: bid.payment[priceProp],
			weight: bid.cargo.weight,
			cargoPrice: bid.cargo.price,
			comment: bid.comment,
			contact: bid.contact,
			rate: bid.payment[priceProp],
		};

		if (builder) {
			const { formRefs } = builder;
			Object.keys(data).forEach((key) => {
				const ref = formRefs.get(key) as Input;
				if (key !== 'price') {
					ref.updateValue((data as any)[key]);
				}
			});
		}
		this.updateRateProps({
			weight: data.weight,
			distance: bid.direction.distance,
			price: data.price,
			type: bid.payment.type.name,
		});
	}

	private async updateRateProps({ weight, distance, price, type }: any) {
		const { builder } = this;

		if (builder) {
			const refs = builder.formRefs as any;
			const rate = refs.get('rate') as Rate;
			//@ts-ignore
			await rate.onSetRate(price, typeMapper[type]);
			await rate.updateWeight(weight);
			await rate.updateDistance(distance);
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private getSidebarHeader() {
		const { toggleSidebar } = this.props;

		const toggle = () => {
			if (toggleSidebar) {
				toggleSidebar({ type: SidebarType.OrderEdit, value: false });
			}
		};

		const backDetails = () => {
			if (toggleSidebar) {
				toggleSidebar({ type: SidebarType.OrderDetails, value: true });
			}
		};

		return (
			<div key='update-order-header' className='header'>
				<div className='title'>
					<Localized id='update-order-title' />
				</div>

				<div className='edit' onClick={backDetails}>
					<Icons.DottedArrowLeft color='rgb(0,0,0)' />
				</div>

				<div className='icon' onClick={toggle}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
		);
	}

	private async reset() {
		const { state, scrollbar, builder } = this;

		if (builder) {
			await builder.reset();

			await this.setState(
				update(state, {
					isFormSubmitting: { $set: false },
					isFormValid: { $set: false },
				}),
			);
		}

		if (scrollbar) {
			scrollbar.scrollTop(0);
		}
	}

	private async onSubmit() {
		const { sessionToken, bid, cleanList, getBids, setDetailsBid } = this.props;
		const { builder } = this;

		if (typeof bid === 'string' || !sessionToken || !builder || !bid || !setDetailsBid || !cleanList || !getBids) {
			return;
		} else {
			this.setState({ isFormSubmitting: true });
			const { form } = builder.state;

			const params = {
				payment: { price: form.rate },
				comment: form.comment,
				cargo: {
					weight: form.weight,
					price: form.cargoPrice,
				},
				deliveryTime: {
					from: bid.deliveryTime.from,
					to: form.shipmentEnd,
				},
				contact: {
					uuid: form.contact,
				},
			};

			try {
				const updatedBid = await bidUpdate(bid.uuid, params);
				setDetailsBid(updatedBid);
				await Promise.all([cleanList(Bid.BidType.All), cleanList(Bid.BidType.My)]);
				await Promise.all([getBids({ type: Bid.BidType.All }), getBids({ type: Bid.BidType.My })]);
				toast.success(this.getLocalizedMessage('update-order-alert-success'), {
					position: toast.POSITION.BOTTOM_CENTER,
				});
			} catch (error) {
				console.error(error);
			}

			this.setState({ isFormSubmitting: false });
		}
	}
	onDelete = () => {
		const { bid, deleteBid } = this.props;
		if (bid && bid.id && deleteBid) {
			deleteBid(bid.id);
		}
		this.setState({ showConfirm: false });
	};
	public render() {
		const { scheme, showConfirm, isFormSubmitting, isFormValid } = this.state;
		const { bid } = this.props;

		const scrollbarProps: any = {
			ref: (node: Scrollbars | null) => (this.scrollbar = node),
			renderTrackHorizontal: () => <div />,
			key: 'update-order-scrollbar',
			className: 'form',
		};

		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			onUpdate: () => {
				this.setState({
					isFormValid: validateByScheme(this.builder?.state.form || {}, this.state.scheme),
				});
			},
			scheme,
		};
		//@ts-ignore
		const showDeleteButton = bid?.capabilities?.includes('delete');
		return [
			this.getSidebarHeader(),

			<Scrollbars {...scrollbarProps} key='update-order-scrollbars'>
				<div className='crunch' style={{ marginBottom: 15 }} />
				<Builder {...builderProps} />
			</Scrollbars>,
			<>
				<button
					key='update-order-action'
					onClick={this.onSubmit}
					disabled={!isFormValid}
					className={clsx({ submit: true, disabled: !isFormValid || isFormSubmitting })}
				>
					<Localized id={isFormSubmitting ? 'update-order-process-update' : 'update-order-update'} />
				</button>
				{showDeleteButton && (
					<button
						className='submit delete'
						key='delete-order-action'
						onClick={() => this.setState({ showConfirm: true })}
					>
						<Localized id='update-order-delete' />
					</button>
				)}
				<ConfirmModal
					key='confirm-modal'
					text='Уверены что хотите закрыть заявку?'
					confirmBtnText='Закрыть'
					isOpen={showConfirm}
					onCancel={() => this.setState({ showConfirm: false })}
					onConfirm={() => this.onDelete()}
				/>
			</>,
		];
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrder);

namespace UpdateOrder {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		bid?: BidDetails;
		locales?: FluentBundle[];
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		getBids?: (params: BidApiModel.BidsRequest, cursor?: string) => void;
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		setDetailsBid?: (bid?: BidDetails) => void;
		cleanList?: (type: Bid.BidType) => void;
		deleteBid?: (id: string) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		scheme: SchemeProps.Union[];
		isFormSubmitting: boolean;
		isFormValid: boolean;
		showConfirm: boolean;
	}
}
