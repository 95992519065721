import React, { useState, useEffect } from 'react';

//other deps
import { Localized } from '@fluent/react';

//api
import { suggestsCompany } from 'app/api';

//types
import { Company } from 'app/models';

export interface CompanyInfoProps {
	hideFullName?: boolean;
	hidden?: boolean;
	inn?: string;
}

//----------------------------------------------------------
// CompanyInfo
//----------------------------------------------------------
export const CompanyInfo = (props: CompanyInfoProps) => {
	//data
	const { hidden, hideFullName, inn } = props;
	const [company, setCompany] = useState<Company | null>(null);

	//effects
	useEffect(() => {
		async function loadCompany(inn: string) {
			const suggests = await suggestsCompany(inn);
			const company = suggests.find((company) => company.inn === inn);

			if (company) {
				setCompany(company);
			}
		}

		inn && loadCompany(inn);
	}, [inn]);

	//renders
	if (!company || hidden) return null;

	return (
		<div className='company-info'>
			{!hideFullName && (
				<div className='title'>
					<div className='text'>{company.name}</div>
				</div>
			)}

			<div className='address'>
				<div className='text'>{company.address}</div>
			</div>

			<div className='type'>{company.type}</div>

			<div className='reg-codes'>
				<div className='ogrn'>
					<Localized id='builder-psrn' /> : {company.ogrn}
				</div>

				{Boolean(company?.kpp) && (
					<div className='kpp'>
						<Localized id='builder-rsc' />: {company.kpp}
					</div>
				)}
			</div>
		</div>
	);
};
