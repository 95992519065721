import React, { useMemo } from 'react';

import { RTable } from 'app/sharedComponents/Table';
import { TabLayoutContent } from 'app/commonPatterns/TabLayout';
import { Bid } from 'app/models';
import { BidsType } from 'app/actions';
import { columns, columnsMy, tenders } from './columns';
import { TENDER_STATUS } from './constants';

const listMapper = (list: Bid[]) => {
	return list.map((bid: Bid) => ({
		bidId: bid.id,
		dateFrom: bid.deliveryTime.from,
		from: bid?.direction?.from,
		to: bid?.direction?.to,
		distance: bid?.direction.distance,
		cargo: bid?.cargo?.type?.name,
		weight: bid?.cargo?.weight,
		price: bid?.payment?.price,
		pricePerKm: bid?.payment?.pricePerKm,
		currency: bid?.payment?.currency?.symbol,
		verified: bid?.isPlatformBid,
		previews: `${bid?.status?.previews}/${bid?.status?.views}`,
		requests: bid?.status?.requests,
		confirmed: bid?.status?.confirmed,
		tenderStatus: bid?.tender?.isActive
			? TENDER_STATUS.ACTIVE
			: bid?.tender?.status === 'new'
			? TENDER_STATUS.NOT_STARTED
			: TENDER_STATUS.FINISHED,
	}));
};

export const OrdersListNew = (props: any) => {
	const { list = [], tab, onRowClick, onLoadMore, cursor, loader, isTender } = props;

	const handleRowClick = (row: any) => {
		onRowClick(row.bidId);
	};
	const mappedData = useMemo(() => listMapper(list), [list]);
	const spreadedColums = tab === BidsType.MY ? [...columns, ...columnsMy] : [...columns];
	const columnsFinal = isTender ? [...spreadedColums, ...tenders] : spreadedColums;

	return (
		<div>
			<TabLayoutContent>
				<RTable
					sticky
					disableSort
					hasMore={!!cursor.next}
					onLoadMore={onLoadMore}
					loading={loader}
					data={mappedData}
					onRowClick={handleRowClick}
					columns={columnsFinal}
				/>
			</TabLayoutContent>
		</div>
	);
};
