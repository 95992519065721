// Auto import locales
const files = require.context('./', true, /\.ftl$/);
const locales: any = [];

files.keys().forEach((file: string) => {
	const module = files(file).default;
	locales.push(module);
});

export default locales;
