import { Select } from 'app/containers/Builder/components/select';
import { Buyer, PriceProduct, SidebarType, RoleEnum } from 'app/models';
import { SchemeProps } from 'app/containers/Builder/props';
import { Dispatch, bindActionCreators } from 'redux';
import * as Icons from 'app/components/Icons';
import Builder from 'app/containers/Builder';
import { CommonActions } from 'app/actions';
import { pricesGetPrices } from 'app/api';
import { Localized } from '@fluent/react';
import { RootState } from 'app/reducers';
import { connect } from 'react-redux';
import { basePrice } from './schemes';
import { FluentBundle } from '@fluent/bundle';
import Slider from 'rc-slider';
import * as React from 'react';
import _ from 'lodash';

import { TabLayout, TabLayoutSidebar, TabLayoutContent, TabLayoutCenterContent } from 'app/commonPatterns/TabLayout';
import TableCustom from 'app/components/TableCustom';
import { getLocalizedMessage } from 'app/locales';

const ICON_AVATAR_DRIVER = require('../../../assets/icons/avatar_driver.jpg');

const mapStateToProps = ({ user, common }: RootState) => ({
	role: user.profile && user.profile.role,
	sessionToken: user.sessionToken,
	products: common.products,
	locales: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
		},
		dispatch,
	);

class AllPrices extends React.Component<AllPrices.Props, AllPrices.State> {
	public selectClassType: Select | null = null;
	public form: Builder | null = null;

	public state: AllPrices.State = {
		loading: false,
		modalContractState: false,
		form: {},
	};

	constructor(props: AllPrices.Props) {
		super(props);

		this.getSelectClassType = this.getSelectClassType.bind(this);
		this.getProteinSlider = this.getProteinSlider.bind(this);
		this.renderTable = this.renderTable.bind(this);
		this.initializeForm = this.initializeForm.bind(this);
		this.getSelectedProduct = this.getSelectedProduct.bind(this);
		this.getPrices = this.getPrices.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	public componentDidMount() {
		setTimeout(() => {
			const { products } = this.props;

			if (products && !_.isEmpty(products)) {
				this.initializeForm();
			}
		}, 300);
	}

	public componentDidUpdate(prevProps: AllPrices.Props) {
		if (
			(!prevProps.products || _.isEmpty(prevProps.products)) &&
			this.props.products &&
			!_.isEmpty(this.props.products)
		) {
			this.initializeForm();
		}
	}

	private initializeForm() {
		const { products } = this.props;

		if (products && !_.isEmpty(products)) {
			const schemeBase: any = _.cloneDeep(basePrice);

			schemeBase[1].choices = Object.keys(products).map((name) => ({
				label: name,
				value: name,
			}));

			this.setState({
				schemeBase,
			});
		}
	}

	private onUpdate(name: string, value: any) {
		const { products } = this.props;
		const { form } = this.state;

		const updated = {
			form: {
				...form,
				[name]: value,
			},
		};

		if (products) {
			if (name === 'cargo' && products[value].id) {
				delete updated.form.classType;
				delete updated.form.protein;
			}

			if (
				name === 'classType' &&
				value !== 'фуражная' &&
				products[updated.form.cargo] &&
				!products[updated.form.cargo].id
			) {
				const proteinsRange = _.keys(products[updated.form.cargo].classes[value])
					.map(parseFloat)
					.filter((n) => !isNaN(n))
					.sort();

				updated.form.protein = proteinsRange[0];
			}
		}

		this.setState(updated);
	}

	private async getPrices() {
		const product = this.getSelectedProduct();
		const { form, loading } = this.state;
		try {
			if (form.from && product && !loading) {
				this.setState({ loading: true });
				const prices = await pricesGetPrices({ address: form.from.address, product });
				prices.sort((a, b) => b.cptPrice - a.cptPrice);
				this.setState({
					prices,
				});
			}
		} catch (error) {
			console.error(error);
		}

		this.setState({ loading: false });
	}

	private renderTable() {
		const { prices = [] } = this.state;
		const { role } = this.props;

		const tableProps = {
			data: prices,
			className: 'table-overlay--auto',
			head: [
				{
					title: getLocalizedMessage('prices-row-header-logo'),
					width: 80,
					key: 'buyerLogo',
					render(value: any) {
						return <img src={value || ICON_AVATAR_DRIVER} width='50' height='50' />;
					},
				},
				{
					title: getLocalizedMessage('prices-row-header-buyer'),
					width: 200,
					key: 'buyerName',
				},

				{
					title: getLocalizedMessage('prices-row-header-basis'),
					width: 200,
					key: 'basisName',
				},

				{
					title: getLocalizedMessage('prices-row-header-protein'),
					width: 80,
					key: 'productProtein',
					sortable: true,
					render(value: any) {
						return value || '-';
					},
				},

				{
					title: getLocalizedMessage('prices-row-header-cpt'),
					width: 80,
					key: 'buyerPrice',
					sortable: true,
					render(value: any) {
						return value || '-';
					},
				},

				{
					title: getLocalizedMessage('prices-row-header-km'),
					key: 'distance',
				},
				{
					title: getLocalizedMessage('prices-row-header-rate'),
					width: 80,
					key: 'transportTotal',
					sortable: true,
					render(value: any) {
						return value || '-';
					},
				},

				{
					title: getLocalizedMessage('prices-row-header-sell-price'),
					key: 'cptPrice',
					width: 100,
					sortable: true,
					render(value: any) {
						return value ? value + '₽/т' : '-';
					},
				},
				{
					key: '',
					condition: role !== 'driver',
					render: (value: any, props: any, index: number) => {
						const buyers = prices ? prices[index] : undefined;
						const { toggleSidebar, role } = this.props;
						const { form } = this.state;
						const onOrderClick = async () => {
							if (buyers && toggleSidebar && role != 'driver') {
								const params = {
									rate: buyers.transportTotal,
									product: buyers.productName,
									distance: buyers.distance,
									productId: buyers.cropId,
									to: buyers.basisAddress,
									from: form.from,
								};

								toggleSidebar({
									type: SidebarType.OrderCreate,
									value: true,
									params,
								});
							}
						};

						return [
							<button key='make-order' className={'table__action make-order'} onClick={onOrderClick}>
								<Icons.ExecutorCar color='#6C6F88' />
								<div className='label'>
									<Localized id='prices-row-order-label' />
								</div>
							</button>,
							<button
								key='show-contract'
								className={'table__action show-contract'}
								onClick={() => this.props.onContractClick?.()}
							>
								<Icons.Contract />
								<div className='label'>
									<Localized id='prices-row-contract-label' />
								</div>
							</button>,
						];
					},
				},
			],
		};
		return (
			<TabLayoutContent>
				<div className='table-container'>
					<div className='header'>
						<div className='title'>
							<Localized id='prices-content-header' />
						</div>
						<div className='subtitle'>
							<Localized id='prices-content-subheader' />
						</div>
					</div>
					<TabLayoutContent>
						<TableCustom {...tableProps} />
					</TabLayoutContent>
				</div>
			</TabLayoutContent>
		);
	}

	private getSelectedProduct(): string | undefined {
		const {
			form: { cargo, classType, protein },
		} = this.state;
		const { products } = this.props as any;

		if (!!products && !!products[cargo] && products[cargo].id) {
			return products[cargo].id;
		}
		if (
			!!classType &&
			products &&
			products[cargo] &&
			products[cargo].classes &&
			products[cargo].classes[classType] &&
			products[cargo].classes[classType].id
		) {
			return products[cargo].classes[classType].id;
		}

		return protein ? products[cargo].classes[classType][protein].id : false;
	}

	private getProteinSlider() {
		const {
			form: { cargo, classType, protein },
		} = this.state;
		const { products } = this.props;

		if (
			!products ||
			!cargo ||
			!products[cargo] ||
			!classType ||
			_.isEmpty(products[cargo].classes[classType]) ||
			products[cargo].classes[classType].id
		) {
			return null;
		}

		const marks: any = {};
		const proteinsRange = _.keys(products[cargo].classes[classType])
			.map(parseFloat)
			.filter((n) => !isNaN(n))
			.sort();

		proteinsRange.forEach((protein) => {
			marks[protein] = protein;
		});

		const onChange = (value: any) => {
			this.setState({
				form: {
					...this.state.form,
					protein: value,
				},
			});
		};

		return (
			<div className='protein-slider'>
				<div className='label'>
					<Localized id='prices-row-header-protein' />
				</div>
				<div className='value'>{protein ? protein : proteinsRange[0]}</div>
				<div className='entry'>
					<Slider
						max={proteinsRange[proteinsRange.length - 1]}
						defaultValue={proteinsRange[0]}
						min={proteinsRange[0]}
						onChange={onChange}
						marks={marks}
						step={null}
					/>
				</div>
			</div>
		);
	}

	private getSelectClassType() {
		const {
			form: { cargo },
		} = this.state;
		const { products } = this.props;

		if (!products || !cargo || !products[cargo] || products[cargo].id) {
			return null;
		}

		const { classes } = products[cargo];

		const selectClassTypeProps: any = {
			ref: (node: any) => (this.selectClassType = node),
			onUpdate: this.onUpdate,

			field: {
				label: 'prices-label-class-type',
				disableLocalizeForOption: true,
				name: 'classType',
				localized: true,
				rounded: true,

				choices: _.keys(classes).map((classType) => ({
					value: classType,
					label: classType,
				})),
			},
		};

		return <Select {...selectClassTypeProps} />;
	}

	public render() {
		const { schemeBase, prices, form, loading } = this.state;
		const formValid = this.getSelectedProduct() && form.from;

		const builderProps = {
			ref: (node: Builder | null) => {
				this.form = node;
			},
			scheme: schemeBase ? schemeBase : [],
			onUpdate: this.onUpdate,
		};

		const actionProps = {
			className: 'action ' + (!formValid || loading ? 'disabled' : ''),
			onClick: this.getPrices,
		};

		// const emptyClass = !prices || _.isEmpty(prices) ? 'empty' : '';

		return (
			<TabLayout>
				<TabLayoutSidebar className='options'>
					<div className='header'>
						<Localized id='prices-sidebar-header' />
					</div>

					<Builder {...builderProps} />
					{this.getSelectClassType()}
					{this.getProteinSlider()}

					<div {...actionProps}>
						{loading ? <Localized id='prices-loading-sidebar-action' /> : <Localized id='prices-sidebar-action' />}
					</div>
				</TabLayoutSidebar>
				<TabLayoutContent>
					<div>
						{prices ? (
							!_.isEmpty(prices) ? (
								this.renderTable()
							) : (
								<TabLayoutCenterContent>
									<Icons.MessagesBig />
									<div className='title'>
										<Localized id='prices-content-empty-list' />
									</div>
								</TabLayoutCenterContent>
							)
						) : (
							<TabLayoutCenterContent>
								<Icons.Notebook />
								<div className='title'>
									<Localized id='prices-content-empty-header' />
								</div>
							</TabLayoutCenterContent>
						)}
					</div>
				</TabLayoutContent>
			</TabLayout>
		);
	}
}

export default connect<AllPrices.StateProps, AllPrices.DispatchProps, AllPrices.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(AllPrices);

namespace AllPrices {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		products?: { [id: string]: PriceProduct };
		sessionToken?: string;
		role?: RoleEnum;
		locales?: FluentBundle[];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		onContractClick: (contractData?: any) => void;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		schemeBase?: SchemeProps.Union[];
		prices?: Buyer[];
		loading: boolean;
		modalContractState: boolean;
	}
}
