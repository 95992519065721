const rootDomain = process.env.NODE_ENV === 'production' ? '.vezzer.no' : '.dev.vezzer.no';

export const getCookieAsObject = () => {
	const cookieArray: string[] = document.cookie.split('; ');
	const result: { [key: string]: any } = {};

	cookieArray.forEach((param) => {
		const key = param.split('=')[0];
		const value = param.split('=')[1];

		result[key] = value;
	});

	return result;
};

export const getCookie = (name: any) => {
	const cookies = getCookieAsObject();
	return cookies[name] || null;
};

export const setCookie = (name: string, value: string, options: { [key: string]: any }) => {
	let optionsString = '';
	if (options) {
		for (const key in options) {
			optionsString += `${key}=${options[key]};`;
		}
	}
	document.cookie = `${name}=${encodeURIComponent(value)}; ${optionsString}`;
};

export const deleteCookie = (name: string) => {
	// let rootDomain = location.hostname.replace(/^\w+/, '')
	const options: object = {
		'max-age': -1,
		'expires': 'Thu, 01 Jan 1970 00:00:01 GMT',
		'domain': rootDomain,
	};

	setCookie(name, '', options);
};
