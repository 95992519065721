import { SchemeProps, Field, Validate } from '../props';
import { Localized } from '@fluent/react';
import * as React from 'react';
import { makeMasker, maskGetValue } from 'app/utils';
import { getLocalizedMessage } from 'app/locales';

export interface InputState {
	value: string;
	validationMessage: '';
}

export class Input extends React.Component<Input.Props, Input.State> implements Field.Hooks<string> {
	private inputRef: any = null;

	public state: InputState = {
		value: '',
		validationMessage: '',
	};

	constructor(props: Input.Props) {
		super(props);

		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);

		const { field, onUpdate } = props;

		if (typeof field.defaultValue === 'string') {
			const masker = !field.mask ? undefined : makeMasker(field.mask as any, field.defaultValue);
			const nextValue = maskGetValue(field.defaultValue, field.validation, masker);
			onUpdate(field.name, nextValue);

			this.state.value = nextValue.toString();
		}
	}

	public async reset() {
		const { defaultValue } = this.props.field;
		await this.setState({ value: '' });

		if (typeof defaultValue === 'string') {
			this.updateValue(defaultValue);
		}
	}

	public async updateValue(value: string) {
		const {
			onUpdate,
			field: { name, validation, mask, preventSpace },
		} = this.props;

		const masker = !mask ? undefined : makeMasker(mask as any, value);
		let nextValue = maskGetValue(value, validation, masker);

		if (preventSpace) {
			nextValue = nextValue.replace(/^\s+/, '').replace(/\s+$/, '');
		}

		this.setState({ value: nextValue.toString() }, () => {
			onUpdate(name, nextValue);
		});
	}

	public focus() {
		if (this.inputRef) {
			this.inputRef.focus();
		}
	}

	private validate(value: string) {
		const { rules, required } = this.props.field;
		const { form } = this.props;

		let validationMessage = '';

		if (this.state.validationMessage !== '') {
			this.setState({ validationMessage: '' });
		}

		if (rules) {
			rules.forEach((check: Function) => {
				const report = check(value, form);

				if (report !== true) {
					validationMessage = getLocalizedMessage(report) || 'Invalid report';
				}
			});
		}

		if (required && !value) {
			validationMessage = getLocalizedMessage('builder-field-required') || 'Invalid report';
		}

		if (!required && !value) {
			validationMessage = '';
		}

		if (validationMessage) {
			this.setState({ validationMessage });
			return validationMessage;
		}

		return true;
	}

	public render() {
		const {
			name,
			label,
			validation,
			rounded,
			multiline,
			hidden,
			step,
			min,
			max,
			disabled,
			localized,
			mask,
			uppercase,
		} = this.props.field;

		const { value } = this.state;

		const onChangeValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
			if (validation === Validate.Number) {
				if (min) {
					e.target.value = Math.max(parseFloat(min), parseFloat(e.target.value)).toString();
				}

				if (max) {
					e.target.value = Math.min(parseFloat(max), parseFloat(e.target.value)).toString();
				}
			}

			if (uppercase) {
				e.target.value = e.target.value.toUpperCase();
			}

			this.updateValue(e.target.value);
			const target = e.target;

			setTimeout(() => {
				this.validate(target.value);
			}, 10);
		};

		const inputProps: any = {
			ref: (node: any) => (this.inputRef = node),
			...(multiline ? {} : { type: 'text' }),
			onChange: onChangeValue,
			disabled: !!disabled,
			required: true,
			value,
			name,
			placeholder: ' ',
			...(validation === Validate.Number
				? {
						step: step ? step : '0.01',
						type: 'number',

						// Setting up min/max
						...(max ? { max } : {}),
						min: min ? min : 0,
				  }
				: {}),
		};

		let extendedClass = rounded ? 'rounded' : 'material';

		const maskValue =
			mask && typeof mask === 'string' ? mask.replace(/s/g, 'A').replace(/[wl]/g, 'X').replace(/`/g, '') : false;

		if (multiline) {
			extendedClass += ' multiline';
		}

		const classValidationModification = this.state.validationMessage ? 'input--invalid' : '';

		return hidden ? null : (
			<div
				style={{ opacity: disabled ? '.6' : '1' }}
				className={`form-item input ${extendedClass} ${classValidationModification}`}
			>
				{multiline ? <textarea {...inputProps} /> : <input {...inputProps} />}

				{maskValue ? (
					<span className='input__mask'>
						<span className='input__mask-hidden'>{this.state.value}</span>
						<div className='input__mask-show'>{maskValue.substr(this.state.value.length, maskValue.length)}</div>
					</span>
				) : (
					false
				)}

				<div className='input__message'>{this.state.validationMessage}</div>

				{rounded ? null : [<span key='hig' className='highlight' />, <span key='bar' className='bar' />]}

				<label>{localized ? <Localized id={label} /> : label}</label>
			</div>
		);
	}
}

export namespace Input {
	export interface State {
		value: string;
		validationMessage: string;
	}

	export interface Props extends Field.ComponentProps<any, SchemeProps.Input> {
		// @default from base
	}
}
