import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Select } from 'app/sharedComponents/Select';
import * as api from 'app/api';
const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding-right: 19px;
	align-items: center;
	margin-top: -20px;
`;
const LeftSide = styled.div`
	display: flex;
	justify-content: flex-start;
`;

const FilterContainer = styled.div`
	width: 200px;
	padding-top: 5px;
	margin-left: 5px;
	margin-right: 5px;
`;

export const ordering = {
	created_at: 'Дата создания',
	title: 'Заголовок',
	company: 'Заказчик',
};

const currencyOptions = [
	{ value: 'rub', label: 'RUB' },
	{ value: 'usd', label: 'USD' },
];

export const TopFilter = ({ onChange = () => {} }: any) => {
	const [bases, setBases] = useState([]);
	const [buyers, setBuyers] = useState([]);
	const handleBuyerChange = onChange('buyer');
	const handleBasisChange = onChange('basis');
	const handleCurrencyChange = onChange('currency');

	useEffect(() => {
		async function fetchBases() {
			const res = await api.v2.get(`/prices/delivery_bases`);

			if (res?.data?.data?.length) {
				setBases(res?.data?.data?.map((el: any) => ({ label: el.name, value: el.uuid })));
			}
			const res1 = await api.v2.get(`/prices/grainbuyers`);

			if (res1?.data?.data?.length) {
				setBuyers(res1?.data?.data?.map((el: any) => ({ label: el.name, value: el.uuid })));
			}
		}

		fetchBases();
	}, []);
	return (
		<Container>
			<LeftSide>
				<FilterContainer>
					<Select
						isClearable
						// multi
						options={buyers}
						label='Покупатель'
						placeholder='Любой'
						onChange={(option: any) => handleBuyerChange(option?.value || '')}
					/>
				</FilterContainer>
				<FilterContainer>
					<Select
						isClearable
						// multi
						options={bases}
						label='Склад/элеватор'
						placeholder='Любой'
						onChange={(option: any) => handleBasisChange(option?.value || '')}
					/>
				</FilterContainer>
				<FilterContainer>
					<Select
						isClearable
						disabled
						options={[]}
						label='Регион'
						placeholder='Любой'
						onChange={(option: any) => handleBuyerChange(option?.value || '')}
					/>
				</FilterContainer>
				<FilterContainer>
					<Select
						isClearable
						options={currencyOptions}
						label='Валюта'
						placeholder='Любой'
						onChange={(option: any) => handleCurrencyChange(option?.value || '')}
					/>
				</FilterContainer>
			</LeftSide>
		</Container>
	);
};
