import * as React from 'react';

//other deps
import { Localized } from '@fluent/react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//screen parts
import Certificates from './features/certificates';
import Insurances from './features/insurances';

//components
import * as Icons from 'app/components/Icons';

//types
import { RootState } from 'app/reducers';
import { DriverCar, VerifyStatus } from 'app/models';

//constants
export const VERIFIED = 'verified';

//----------------------------------------------------------
// DocsCar
//----------------------------------------------------------
class DocsCar extends React.Component<DocsCar.Props, DocsCar.State> {
	public state: DocsCar.State = {};

	constructor(props: DocsCar.Props) {
		super(props);
	}

	public render() {
		const { car } = this.props;
		const { certificates } = car;

		const verifyStatus = Object.keys(certificates).map((uuid) => certificates[uuid]?.verification?.status);

		const allVerified =
			!verifyStatus.includes(VerifyStatus.DetailsNeeded) && !verifyStatus.includes(VerifyStatus.OnVerification);

		return (
			<div className='car docs form'>
				<div className='photos'>{!allVerified ? <Icons.VerifyUser /> : null}</div>

				<div className='common'>
					<div className='header'>
						{allVerified ? (
							<div className='alert panel verified'>
								<div className='icon'>
									<Icons.VerifyUser />
								</div>
								<div className='title'>
									<Localized id='profile-docs-preview-verify-success-header' />
								</div>
							</div>
						) : (
							<>
								<div className='title'>
									<Localized id='transports-docs-title' />
								</div>
								<div className='subtitle'>
									<Localized id='transports-docs-description' />
								</div>
							</>
						)}

						<Certificates allVerified={allVerified} car={car} />
						<Insurances car={car} />
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<DocsCar.StateProps, DocsCar.DispatchProps, DocsCar.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(DocsCar);

namespace DocsCar {
	export type Props = StateProps & DispatchProps & ExternalProps;
	// Props from redux mapState
	export interface StateProps {}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		openSendedModal: () => void;
		car: DriverCar;
	}

	// Main component state
	export interface State {}
}
