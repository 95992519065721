import React from 'react';
import toast, { Toaster as RCToaster } from 'react-hot-toast';

export { toast };

export const Toaster = () => {
	const toastOptions = {
		style: {
			background: 'rgba(0, 0, 0, 0.64)',
			color: '#fff',
		},
	};
	return <RCToaster position='bottom-right' toastOptions={toastOptions} />;
};
