import { FilterActions } from 'app/actions';
import { handleActions, Action } from 'redux-actions';

export type FilterState = {
	to_loading: boolean;
	on_loading: boolean;
	to_unloading: boolean;
	on_unloading: boolean;
};

const defaultState: FilterState = {
	to_loading: false,
	on_loading: false,
	to_unloading: false,
	on_unloading: false,
};

export const filterReducer = handleActions<FilterState, any>(
	{
		[FilterActions.Action.SET_FILTER]: (state, { payload }: Action<FilterActions.Payload.Filter>) =>
			payload ? { ...state, ...payload } : state,
	},
	defaultState,
);
