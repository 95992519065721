import { SchemeProps, BasicTypes, Validate } from 'app/containers/Builder/props';
import { Rate as RateApi } from 'app/api';

const scheme: SchemeProps.Form = [
	{
		type: BasicTypes.Address,
		label: 'create-order-label-from',
		localized: true,
		required: true,
		name: 'from',
	},

	{
		type: BasicTypes.Address,
		label: 'create-order-label-to',
		localized: true,
		required: true,
		name: 'to',
	},

	{
		type: BasicTypes.Map,
		label: 'create-order-label-distance',
		localized: true,
		placesBy: ['from', 'to'],
		name: 'distance',
	},

	{
		// choices: [ /* lazy */ ],
		type: BasicTypes.Select,
		label: 'create-order-label-cargo',
		disableLocalizeForOption: true,
		localized: true,
		required: true,
		name: 'cargo',
		rounded: true,
	},

	{
		validation: Validate.Number,
		type: BasicTypes.Input,
		required: true,
		name: 'weight',
		label: 'create-order-label-weight',
		localized: true,
		rounded: true,
		step: '1',
		min: '1',
	},

	{
		validation: Validate.Number,
		type: BasicTypes.Input,
		required: true,
		name: 'cargoPrice',
		label: 'create-order-label-cargo-price',
		localized: true,
		rounded: true,
		step: '1',
	},

	{
		type: BasicTypes.Rate,
		label: 'create-order-label-rate',
		localized: true,
		name: 'rate',

		defaultValues: {
			isOverload: false,
			isPrepaid: false,
			isBargain: false,
			hasVat: false,
			isCash: false,
			isPricePerKm: false,
			type: RateApi.ByType.WT,
			// Lazy set in create order
			currencySymbol: '',
		},
	},

	//@ts-ignore
	{
		type: BasicTypes.Group,
		className: 'shipments',
		direction: 'row',
		contains: [
			{
				type: BasicTypes.DateTime,
				label: 'create-order-label-shipment-start',
				localized: true,
				showIcon: true,
				noBorder: true,
				name: 'shipmentStart',
				defaultValue: new Date(),
			},
			{
				label: 'create-order-label-shipment-end',
				localized: true,
				noBorder: true,
				showIcon: true,
				type: BasicTypes.DateTime,
				name: 'shipmentEnd',
				defaultValue: new Date(),
			},
		],
	},
	{
		type: BasicTypes.CompanyPreview,
		label: 'create-order-label-company',
		localized: true,
		required: true,
		name: 'company',
	},
	{
		// choices: [ /* lazy */ ],
		type: BasicTypes.Select,
		label: 'create-order-label-tariff',
		disableLocalizeForOption: true,
		localized: true,
		required: false,
		name: 'tariff',
		rounded: true,
	},
	{
		type: BasicTypes.Group,
		className: 'optionals',
		direction: 'column',
		prepareProps: (form: any) => {
			let hidden = false;

			if (!form.rate || !form.cargo || !form.weight || !form.from || !form.to) {
				hidden = true;
			}

			return { hidden };
		},

		contains: [
			{
				label: 'create-order-header-delivery-terms',
				type: BasicTypes.Header,
				name: 'deliveryTerms',
				localized: true,
			},

			{
				validation: Validate.Number,
				label: 'create-order-label-trips-count',
				localized: true,
				type: BasicTypes.Input,
				name: 'tripsCount',
				required: true,
				step: '1',
			},

			{
				validation: Validate.Number,
				label: 'create-order-label-keep-rate',
				localized: true,
				type: BasicTypes.Input,
				name: 'keepRate',
				required: false,
				step: '1',
			},

			{
				validation: Validate.Number,
				label: 'create-order-label-keep-start',
				localized: true,
				type: BasicTypes.Input,
				name: 'keepStart',
				required: false,
				step: '1',
				max: '10',

				prepareProps: (form: any) => {
					// console.log("prepare", form, props)
					let hidden = false;

					if (!form.keepRate) {
						hidden = true;
					}

					return { hidden };
				},
			},

			// Row UI group
			{
				type: BasicTypes.Group,
				className: 'loss',
				direction: 'row',
				contains: [
					{
						label: 'create-order-label-allowed-loss',
						disableLocalizeForOption: true,
						type: BasicTypes.Select,
						name: 'allowedLoss',
						localized: true,
						required: false,

						// Lazy-loading
						// choices: [],

						prepareProps: (form: any) => {
							let choices: any = [];

							if (form.allowedLossUnit === 'kg') {
								choices = [
									{ label: '0', value: 0 },
									{ label: '50', value: 50 },
									{ label: '100', value: 100 },
									{ label: '150', value: 150 },
									{ label: '200', value: 200 },
									{ label: '250', value: 250 },
									{ label: '300', value: 300 },
									{ label: '350', value: 350 },
									{ label: '400', value: 400 },
									{ label: '450', value: 450 },
									{ label: '500', value: 500 },
								];
							} else if (form.allowedLossUnit === 'pc') {
								choices = [
									{ label: '0.0', value: 0.0 },
									{ label: '0.02', value: 0.02 },
									{ label: '0.05', value: 0.05 },
									{ label: '0.07', value: 0.07 },
									{ label: '0.08', value: 0.08 },
									{ label: '0.1', value: 0.1 },
									{ label: '0.16', value: 0.16 },
									{ label: '0.17', value: 0.17 },
									{ label: '0.2', value: 0.2 },
									{ label: '0.3', value: 0.3 },
									{ label: '0.32', value: 0.32 },
									{ label: '0.4', value: 0.4 },
									{ label: '0.5', value: 0.5 },
								];
							}

							// console.log(choices)
							return { choices };
						},
					},

					{
						label: 'create-order-label-allowed-loss-unit',
						disableLocalizeForOption: true,
						type: BasicTypes.Select,
						name: 'allowedLossUnit',
						required: false,
						localized: true,

						choices: [
							{ label: 'Кг', value: 'kg' },
							{ label: '%', value: 'pc' },
						],
					},
				],
			},
		],
	},

	{
		type: BasicTypes.Select,
		label: 'тип заявки',
		// localized: true,
		name: 'tenderOrderType',
		choices: [
			{ label: 'Простая заявка', value: 'simple' },
			{ label: 'Тендер', value: 'tender' },
		],
		defaultValue: { label: 'Простая заявка', value: 'simple' },
	},
	//@ts-ignore
	{
		type: BasicTypes.Group,
		className: 'tenders',
		direction: 'column',
		prepareProps: (form: any) => {
			return {
				hidden: form.tenderOrderType !== 'tender',
			};
		},
		contains: [
			{
				label: 'create-order-tender-header',
				type: BasicTypes.Header,
				name: 'contactsHeader',
				localized: true,
			},
			{
				type: BasicTypes.DateTime,
				label: 'create-order-tender-start',
				showTime: true,
				showIcon: true,
				localized: true,
				name: 'tenderFrom',
				defaultValue: new Date(),
			},
			{
				type: BasicTypes.DateTime,
				label: 'create-order-tender-end',
				showTime: true,
				showIcon: true,
				localized: true,
				name: 'tenderTo',
				defaultValue: new Date(),
			},
			{
				type: BasicTypes.Select,
				label: 'Тип финализации',
				// localized: true,
				name: 'finalizeType',
				choices: [
					{ label: 'Ручная', value: 'manual_on_expire' },
					{ label: 'Автоматическая', value: 'auto_on_expire' },
				],
				defaultValue: { label: 'Ручная', value: 'manual_on_expire' },
			},
			{
				type: BasicTypes.Input,
				label: 'create-order-tender-min-lot',
				localized: true,
				defaultValue: '',
				name: 'minWeight',
				rounded: true,
			},
			{
				type: BasicTypes.Input,
				label: 'create-order-tender-day-value',
				localized: true,
				defaultValue: '',
				name: 'dailyWeight',
				rounded: true,
			},
		],
	},
	{
		label: 'create-order-header-contacts-header',
		type: BasicTypes.Header,
		name: 'contactsHeader',
		localized: true,
	},

	{
		label: 'create-order-header-contacts-header',
		type: BasicTypes.Contacts,
		localized: true,
		name: 'contacts',
	},

	{
		type: BasicTypes.Input,
		label: 'create-order-label-comment',
		localized: true,
		defaultValue: '',
		multiline: true,
		name: 'comment',
		rounded: true,
	},
];

export default scheme;
