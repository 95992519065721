import * as React from 'react';

//other deps
import styled from 'styled-components';
import { Localized } from '@fluent/react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//components
import * as Icons from 'app/components/Icons';
import ImageModal from 'app/components/ImageModal';

const CarInfo = styled.div``;

//types
import { DriverCar } from 'app/models';

//----------------------------------------------------------
// PreviewCar
//----------------------------------------------------------
class PreviewCar extends React.Component<PreviewCar.Props, PreviewCar.State> {
	public state: PreviewCar.State = {};

	constructor(props: PreviewCar.Props) {
		super(props);
	}

	public render() {
		const { car, toggleEditable } = this.props;

		const {
			transport: { isVerified },
		} = car;

		return (
			<div className='car base preview'>
				<div className='content'>
					<div className='title'>
						<div className='text'>
							<Localized id='transports-base-title' />
						</div>

						{!isVerified && (
							<div className='edit' onClick={() => toggleEditable(true)}>
								<Icons.Edit color='rgba(0,0,0, 0.75)' />

								<div className='text'>
									<Localized id='transports-action-edit' />
								</div>
							</div>
						)}
					</div>

					<div className='row'>
						<div className='column photos'>
							<div className='transport-card-info'>
								<div className='driver photo'>
									{car.transport.avatar ? <ImageModal src={car.transport.avatar} /> : <Icons.AvatarAutotype />}
								</div>
							</div>

							<div className='arrow'>
								<Icons.DottedArrowLeft color='#6C6F88' />
							</div>

							<div className='transport-card-info'>
								<div className='driver photo'>
									{car.contact.avatar ? <img src={car.contact.avatar} /> : <Icons.AvatarDriver />}

									<div className='checked'>
										<Icons.Check color='#FFF' size={18} />
									</div>
								</div>

								<div className='title'>
									{car.contact.lastName} {car.contact.firstName[0]}.{car.contact.middleName[0]}.
								</div>

								<div className='subtitle'>
									<Localized id='role-driver' />
								</div>
							</div>
						</div>
					</div>

					<CarInfo className='mt-4'>
						<div className='default-text color-tx-gray-4'>Номер тягача</div>
						<div className='input-text'>{car.transport.ident}</div>
						<div className='default-text color-tx-gray-4 mt-2'>Номер прицепа</div>
						<div className='input-text'>{car.transport.trailerIdent}</div>
					</CarInfo>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<PreviewCar.StateProps, PreviewCar.DispatchProps, PreviewCar.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(PreviewCar);

namespace PreviewCar {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		toggleEditable: (editable: boolean) => void;
		car: DriverCar;
	}

	// Main component state
	export interface State {}
}
