import React from 'react';
import { Bid, BidDetails } from 'app/models';

import { Stats, StatsValue } from 'app/commonPatterns/Stats';

const ICON_REPLIES = require('assets/icons/i-reply.png');
const ICON_APPROVES = require('assets/icons/i-approve.png');
const ICON_VIEWS = require('assets/icons/i-view.png');

export const BidStatus = ({ bid }: { bid: Bid | BidDetails | undefined }) => {
	return bid?.status ? (
		<Stats>
			<StatsValue title='Просмотры' icon={ICON_VIEWS}>
				{bid.status.views} / {bid.status.previews}
			</StatsValue>
			<StatsValue title='Ответы' icon={ICON_REPLIES}>
				{bid.status.requests}
			</StatsValue>
			<StatsValue title='В работе' icon={ICON_APPROVES}>
				{bid.status.confirmed}
			</StatsValue>
		</Stats>
	) : null;
};
