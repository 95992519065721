import React from 'react';
import styled from 'styled-components';
import { Localized } from '@fluent/react';
import * as Icons from 'app/components/Icons';

const NotFoundContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const NotFound = () => {
	return (
		<NotFoundContainer className='info'>
			<Icons.CarInfo />

			<div className='title'>
				<Localized id='details-order-not-found' />
			</div>
		</NotFoundContainer>
	);
};
