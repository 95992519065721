import React from 'react';

import { CarCreation } from '../features/car-creation';
import { CarDetailed } from '../features/car-detailed';

//types
import type { DriverCar } from 'app/models';
import type { RootState } from 'app/reducers';

interface OpenedCarProps {
	car: DriverCar;
	emptyCar?: RootState['driver']['emptyCar'];
	handleModal: () => void;
}

//----------------------------------------------------------
// OpenedCar
//----------------------------------------------------------
const OpenedCar = ({ car = {} as DriverCar, emptyCar, handleModal }: OpenedCarProps) => {
	if (emptyCar?.isOpen) {
		return <CarCreation car={emptyCar} handleModal={handleModal} />;
	} else {
		return <CarDetailed car={car} handleModal={handleModal} />;
	}
};

export default OpenedCar;
