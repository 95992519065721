import React from 'react';
import styled from 'styled-components';

import { CardHeader, CardPhoto, CardPhotoVerified, CardStatus } from '../components/Common';

import { getDriverName } from './utils';
import Actions from '../components/Actions';
import { OfferCard } from './OfferCard';

const ICON_REPLIES = require('assets/icons/i-reply.png');
const ICON_APPROVES = require('assets/icons/i-approve.png');
const ICON_VERIFIED = require('assets/icons/svg/ic_verification_small.svg');
const ICON_NO_AVATAR = require('src/assets/icons/avatar_driver.jpg');
const ICON_NO_DRIVER_PHOTO = require('src/assets/icons/avatar_autotype.png');

const STATUS_ICONS: { [key: string]: string } = {
	free: ICON_REPLIES,
	busy: ICON_APPROVES,
};
const InfoCard = styled.div`
	background-color: white;
	border-radius: 5px;
	box-shadow: rgba(103, 116, 150, 0.247) 0px 0px 4px 0px;
`;

const OfferCardContainer = styled.div`
	/* padding-top: 12px; */
	display: flex;
	flex-direction: column;
	/* background-color: white; */
	border-top-left-radius: 4px;
	border-top-right-radius: 4px;
	/* border-radius: 4px; */
	/* box-shadow: rgba(103, 116, 150, 0.247) 0px 0px 4px 0px; */
`;

const Separator = styled.div<any>`
	width: 100%;
	/* border: 1px solid lightgray; */
	/* height: 5px; */
	margin-top: 5px;
	/* margin-bottom: 5px; */
	/* ${({ fat }) =>
		fat &&
		`
		// border: 2px solid lightgray;
  `} */
`;

export const DetailsCard = ({ data, onClose, onShowRoute, onShowBid }: any) => {
	const { transport = {}, driver = {}, company, offers = [] } = data;
	const isVerified = transport?.isVerified;
	const onActionRouteClick = () => {
		if (offers && offers[0]) {
			const { bid } = offers[0];
			onShowRoute({ from: bid?.direction?.from, to: bid?.direction?.to, id: bid?.id });
		}
	};
	const isShowRouteAvailable = transport?.status === 'busy' && offers.length === 1;
	const onActionMessageClick = () => {
		window.open(`https://wa.me/${driver.phone}`);
	};

	// useEffect(() => {
	//     if (!offers.length) onClose();
	// }, [offers]);

	return (
		<OfferCardContainer>
			<InfoCard>
				<CardHeader>
					<div className='photos'>
						<CardPhoto src={driver?.photo || ICON_NO_AVATAR} />
						{isVerified ? <CardPhotoVerified src={ICON_VERIFIED} /> : null}
					</div>
					<div className='info'>
						<div className='info__name'>
							<div>{getDriverName(driver)}</div>
						</div>

						<div className='info__data'>
							<div>{driver?.phone || '-----'}</div>
						</div>
						<div className='info__data'>
							<div>{company?.name}</div>
						</div>
					</div>
					<CardStatus value={STATUS_ICONS[transport?.status]} />
				</CardHeader>
				<CardHeader>
					<div className='photos'>
						<CardPhoto src={transport?.photo || ICON_NO_DRIVER_PHOTO} />
					</div>
					<div className='info'>
						<div className='info__name'>
							<div>{transport?.ident}</div>
						</div>

						<div className='info__data'>
							<div>{transport?.transportType?.name}</div>
						</div>
					</div>
				</CardHeader>

				<Separator />
				<Actions
					actions={[
						{
							icon: require('assets/icons/i-message.png'),
							title: 'Написать',
							handler: onActionMessageClick,
							disabled: !driver?.phone,
						},
						{
							icon: require('assets/icons/i-route.png'),
							title: 'Показать маршрут',
							disabled: !isShowRouteAvailable,
							handler: onActionRouteClick,
						},
					]}
				/>
			</InfoCard>
			{offers.map((offer: any) => (
				<OfferCard key={offer?.id} onShowBid={onShowBid} offer={offer} onClose={onClose} />
			))}
		</OfferCardContainer>
	);
};
