import { useState, useEffect, useRef } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'app/components/Toaster';
import { isEqual } from 'lodash';
import { Bid, Company, UserProfile, SidebarType } from 'app/models';
import * as api from 'app/api';
import { getTenderStatus, TenderStatus } from './helper';
import { BidActions, getBidPoints, BidsType, CommonActions } from 'app/actions';

const checkTenderOwner = (bid: Bid, profile: UserProfile) => {
	return bid?.publisher?.company.name === profile?.companyName;
};

export type CurrentOffer = {
	rate: string;
	lot: string;
};

export interface UseTendersType extends UseTendersState {
	profile: UserProfile;
	updateCurrentOffer: (updatedfOffer: any) => void;
	addUpdateOffer: (isUpdate?: boolean) => void;
	approveTender: (finalize: boolean) => void;
	denyTender: () => void;
	deleteOffer: (id: string) => void;
}

export type UseTendersState = {
	userCompany: Company | null;
	isTenderOwner: boolean;
	currentOffer: CurrentOffer | null;
	userOffer: Bid.TenderOffer | null;
	status: TenderStatus | null;
};

export const useTenders = (selectedTender: Bid | null, dispatch: any, profile: any): UseTendersType => {
	const [state, setState] = useState<any>({});
	const tenderRef = useRef<Bid | undefined>();
	const updateCurrentOffer = (newOfferValue: any) => {
		setState({
			...state,
			currentOffer: {
				...state.currentOffer,
				[Object.keys(newOfferValue)[0]]: Object.values(newOfferValue)[0],
			},
		});
	};

	const addUpdateOffer = async (isUpdate: boolean) => {
		if (!state.userCompany || !state.currentOffer || !selectedTender) return;

		if (isUpdate && !state.userOffer) return;

		const actionType = isUpdate && state.userOffer?.uuid ? 'put' : 'post';
		const offerId = isUpdate && state.userOffer?.uuid ? `/${state.userOffer?.uuid}` : '';

		const res = await api.v1[actionType](`/bid/${selectedTender.id}/tenderoffers${offerId}`, {
			offered_price: state.currentOffer.rate,
			offered_weight: state.currentOffer.lot,
			company: profile?.defaultCompany,
		});
		console.log('0-00000', res);
		if (res.status === 200 || res.status === 201) {
			toast('Оффер успешно отправлен!');
			dispatch(BidActions.getDetailsBid(selectedTender.id));
		}
	};

	const approveTender = async (finalize: boolean) => {
		if (!selectedTender) return;

		const res = await api.v1.put(`/bid/${selectedTender.id}/tender`, { finalize });
		if (res.status === 200 || res.status === 201) {
			toast(`Тендер успешно завершен!`);
			dispatch(BidActions.getDetailsBid(selectedTender.id));
		}
	};

	const deleteOffer = async (offerId: string) => {
		if (!selectedTender) return;
		const res = await api.v1.delete(`/bid/${selectedTender.id}/tenderoffers/${offerId}`);
		if (res.status === 200 || res.status === 201) {
			toast(`Предложение успешно отмененено!`);
			dispatch(BidActions.getDetailsBid(selectedTender.id));
		}
	};

	const denyTender = async () => {
		if (!selectedTender) return;
		const res = await api.v1.delete(`/bid/${selectedTender.id}`);
		if (res.status === 200 || res.status === 204) {
			toast(`Тендер успешно отменен!`);
			dispatch(getBidPoints(BidsType.ALL));
			dispatch(
				CommonActions.toggleSidebar({
					type: SidebarType.TenderView,
					value: false,
				}),
			);
		}
	};

	useEffect(() => {
		function fetchData() {
			if (!selectedTender) {
				return;
			}

			const userOffer = selectedTender?.tenderOffers?.find(
				(offer) => offer?.company?.uuid === profile?.defaultCompany?.uuid,
			) || {
				offeredPrice: '',
				offeredWeight: '',
			};

			const stateToUpdate = {
				isTenderOwner: checkTenderOwner(selectedTender, profile),
				userOffer,
				userCompany: profile?.defaultCompany,
				status: selectedTender?.tender ? getTenderStatus(selectedTender.tender) : {},
				currentOffer: {
					rate: userOffer?.offeredPrice?.toString(),
					lot: userOffer?.offeredWeight?.toString(),
				},
			};

			setState(stateToUpdate);
		}

		if (selectedTender && !isEqual(selectedTender, tenderRef.current)) {
			//@ts-ignore
			tenderRef.current = selectedTender;
			fetchData();
		}
	}, [selectedTender]);

	return {
		...state,
		profile,
		updateCurrentOffer,
		approveTender,
		denyTender,
		deleteOffer,
		addUpdateOffer,
	};
};
