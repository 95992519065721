import * as React from 'react';

//other deps
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';
import AsyncSelect from 'react-select/async';
import SelectComponent, { components } from 'react-select';

//redux
import { store } from 'src/main';

//types
import { SchemeProps, Field } from '../props';

//helpers
import { getLocalizedMessage } from 'app/locales';

//components
const LocalizedOption = (localized: boolean, disableLocalizeForOption: boolean, props: any) => {
	const children = localized && !disableLocalizeForOption ? <Localized id={props.children} /> : props.children;

	const localizedProps = {
		...props,
		children,
	};

	return <components.Option {...localizedProps} />;
};

const SingleValue = (
	inputLabel: string,
	localized: boolean,
	disableLocalizeForOption: boolean,
	{ children, ...props }: any,
) => {
	return (
		<components.SingleValue {...props}>
			<div className='label'>{localized ? <Localized id={inputLabel} /> : inputLabel}</div>
			<div className='value'>{localized && !disableLocalizeForOption ? <Localized id={children} /> : children}</div>
		</components.SingleValue>
	);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Input = (inputLabel: string, localized: boolean, { children, ...props }: any) => {
	return [
		<div key={'labels-custom'} className='label'>
			{localized ? <Localized id={inputLabel} /> : inputLabel}
		</div>,
		<components.Input key={'inputs-custom'} {...props} />,
	];
};

//----------------------------------------------------------
// Select
//----------------------------------------------------------
export class Select<T = any> extends React.Component<Select.Props<T>, Select.State<T>> implements Field.Hooks<T> {
	private selectRef: null | SelectComponent<any> = null;
	private bundlesLocales: FluentBundle[] | undefined;

	public state: Select.State<T> = {
		value: null,
	};

	constructor(props: Select.Props<T>) {
		super(props);

		this.updateValue = this.updateValue.bind(this);
		this.focus = this.focus.bind(this);
		this.reset = this.reset.bind(this);

		const { field, onUpdate } = props;

		if (field.defaultValue) {
			onUpdate(field.name, field.defaultValue.value);
			this.state.value = field.defaultValue;
		}
		this.bundlesLocales = store.getState().common.bundlesLocales;
	}

	public async updateValue(value: any) {
		const {
			onUpdate,
			field: { name },
		} = this.props;

		this.setState({ value });
		onUpdate(name, value.value);
	}

	public focus() {
		this?.selectRef?.focus();
	}

	public reset() {
		this.setState({
			value: null,
		});
	}

	public validate() {
		const { required } = this.props.field;
		const { value } = this.state;

		let validationMessage = '';

		if (this.state.validationMessage !== '') {
			this.setState({ validationMessage: '' });
		}

		if (required && !value) {
			validationMessage = getLocalizedMessage('builder-field-required') || 'Invalid report';
		}

		if (validationMessage) {
			this.setState({ validationMessage });
			return validationMessage;
		}

		return true;
	}

	public render() {
		const {
			name,
			label,
			choices,
			rounded,
			disabled,
			localized,
			disableLocalizeForOption,
			async = false,
		} = this.props.field;

		const selectPrefix = rounded ? 'rounded-select' : 'select';

		const {
			onUpdate,
			field: { inputProps },
		} = this.props;

		const { value, validationMessage } = this.state;

		const onChangeValue = (selected: any) => {
			let newValue = selected;
			let toUpdate = (selected as any).value;

			if (toUpdate === 'cleanId') {
				newValue = null;
				toUpdate = null;
			}

			onUpdate(name, toUpdate);
			this.setState({ value: newValue as any });

			if (inputProps && inputProps.onSelect) {
				inputProps.onSelect();
			}

			setTimeout(() => {
				this.validate();
			}, 10);
		};

		const handleInputChange = (value: any /*, actionMeta: any*/) => {
			return value;
		};

		const selectProps: any = {
			ref: (node: SelectComponent<any> | null) => (this.selectRef = node),
			classNamePrefix: selectPrefix,
			className: selectPrefix,
			onChange: onChangeValue,
			onInputChange: handleInputChange,
			blurInputOnSelect: true,
			isDisabled: disabled,
			options: choices,
			value,
			name,

			components: {
				SingleValue: SingleValue.bind(this, label, !!localized, !!disableLocalizeForOption),
				Option: LocalizedOption.bind(this, !!localized, !!disableLocalizeForOption),
				Input: Input.bind(this, label, !!localized),
			},
		};

		const asyncProps: any = {
			ref: (node: SelectComponent<any> | null) => (this.selectRef = node),
			classNamePrefix: selectPrefix,
			className: selectPrefix,
			onChange: onChangeValue,
			onInputChange: handleInputChange,
			defaultOptions: choices,
			isDisabled: disabled,
			value,
			name,
			loadOptions: inputProps?.loadingOptions,
			components: {
				SingleValue: SingleValue.bind(this, label, !!localized, !!disableLocalizeForOption),
				Option: LocalizedOption.bind(this, !!localized, !!disableLocalizeForOption),
				Input: Input.bind(this, label, !!localized),
			},
		};

		const classList = ['form-item', 'select', name];

		if (validationMessage) {
			classList.push('select--invalid');
		}

		// @TODO: Fix styles next using https://react-select.com/styles
		return (
			<div className={classList.join(' ')}>
				<div className='input__message'>{validationMessage}</div>
				{async ? <AsyncSelect {...asyncProps} /> : <SelectComponent {...selectProps} />}
			</div>
		);
	}
}

export namespace Select {
	export interface Props<T> extends Field.ComponentProps<any, SchemeProps.Select<T>> {
		// @default from base
	}

	export interface State<T> {
		value: Field.Choice<T> | null;
		validationMessage?: string;
	}
}
