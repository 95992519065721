import { CommonActions, UserActions } from 'app/actions';
import { SidebarType, UserContact } from 'app/models';
import { Dispatch, bindActionCreators } from 'redux';
import Scrollbars from 'react-custom-scrollbars';
import { RootState } from 'app/reducers';
import update from 'immutability-helper';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';
import { connect } from 'react-redux';
import Masker from 'vanilla-masker';
import * as React from 'react';
import _ from 'lodash';

import { SchemeProps } from 'app/containers/Builder/props';
import { Input } from '../../Builder/components';
import Builder from 'app/containers/Builder';
import SCHEME_CREATE from './scheme';

const closeIcon = require('../../../../assets/icons/close.svg');

const mapStateToProps = ({ common, user }: RootState) => ({
	sessionToken: user.sessionToken,
	locales: common.bundlesLocales,
	tmpContact: user.tmpContact,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			updateContact: UserActions.updateContact,
		},
		dispatch,
	);

class UpdateContact extends React.Component<UpdateContact.Props, UpdateContact.State> {
	// Instance for direct manipulation local state & props
	public scrollbar: Scrollbars | null = null;
	public builder: Builder | null = null;

	public state: UpdateContact.State = {
		scheme: _.cloneDeep(SCHEME_CREATE),
		lockSubmit: false,
		valid: false,
	};

	constructor(props: UpdateContact.Props) {
		super(props);

		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.getSidebarHeader = this.getSidebarHeader.bind(this);
		this.formIsValid = this.formIsValid.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.reset = this.reset.bind(this);
	}

	public componentDidUpdate(prevProps: UpdateContact.Props) {
		const { builder } = this;

		if (!this.props.tmpContact && !!prevProps.tmpContact) {
			// console.log("tmp was cleaned, reset form")
			this.reset();
			return;
		} else {
			const tmpContactUuid = (this.props.tmpContact ? this.props.tmpContact : ({} as any)).uuid;
			const prevUuid = (prevProps.tmpContact ? prevProps.tmpContact : ({} as any)).uuid;

			if (tmpContactUuid !== prevUuid) {
				const { tmpContact } = this.props;
				// console.log("update values")

				if (builder) {
					const { formRefs } = builder;

					formRefs.keySeq().forEach((name) => {
						const ref = formRefs.get(name) as Input;
						ref.updateValue((tmpContact as any)[name]);
					});
				}
			}
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private getSidebarHeader() {
		const { toggleSidebar } = this.props;

		const toggle = () => {
			if (toggleSidebar) {
				toggleSidebar({ type: SidebarType.ContactCreate, value: false });
			}
		};

		return (
			<div key='update-contact-header' className='header'>
				<div className='title'>
					<Localized id='update-contact-header' />
				</div>

				<div className='icon' onClick={toggle}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
		);
	}

	private async onUpdate() {
		this.setState({
			valid: this.formIsValid(),
		});
	}

	private async reset() {
		const { state, scrollbar, builder } = this;

		if (builder) {
			await builder.reset();

			await this.setState(
				update(state, {
					lockSubmit: { $set: false },
					valid: { $set: false },
				}),
			);
		}

		if (scrollbar) {
			scrollbar.scrollTop(0);
		}
	}

	private formIsValid(): boolean {
		const { builder } = this;

		if (builder) {
			const { form } = builder.state;
			const phoneLength = form.phone ? form.phone.length : 0;
			const phoneValid = form.phone && (phoneLength === 18 || phoneLength === 19);

			const valid = phoneValid && !!form.middleName && !!form.firstName && !!form.lastName;

			return valid;
		} else {
			return false;
		}
	}

	private async onSubmit() {
		const { state, builder } = this;
		const { sessionToken, updateContact, tmpContact } = this.props;
		const { lockSubmit } = state;

		if (!state.valid || !sessionToken || !builder || !tmpContact || !updateContact || lockSubmit) {
			return;
		} else {
			const { form } = builder.state;
			const params = _.clone(form) as any;

			// Splice phone format
			params.phone = '+' + Masker.toNumber(params.phone);

			await this.setState({ lockSubmit: true });
			await updateContact(tmpContact.uuid, params, {
				success: this.getLocalizedMessage('form-contact-update-success'),
				failed: this.getLocalizedMessage('form-contact-update-failed'),
			});

			await this.setState({ lockSubmit: false });
		}
	}

	public render() {
		const { lockSubmit, scheme } = this.state;

		const extendedButtonClass = !this.formIsValid() || lockSubmit ? 'disabled' : '';

		const scrollbarProps: any = {
			ref: (node: Scrollbars | null) => (this.scrollbar = node),
			renderTrackHorizontal: () => <div />,
			key: 'update-contact-scrollbar',
			className: 'form',
		};

		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			onUpdate: this.onUpdate,
			scheme,
		};

		return [
			this.getSidebarHeader(),

			<Scrollbars {...scrollbarProps} key='update-contact-scrollbars'>
				<Builder {...builderProps} />
			</Scrollbars>,

			<div key='update-contact-action' onClick={this.onSubmit} className={'submit ' + extendedButtonClass}>
				<Localized id={lockSubmit ? 'form-contact-process-update' : 'form-contact-update'} />
			</div>,
		];
	}
}

export default connect<UpdateContact.StateProps, UpdateContact.DispatchProps, UpdateContact.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(UpdateContact);

namespace UpdateContact {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		tmpContact?: UserContact;
		locales?: FluentBundle[];
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updateContact?: (
			id: string,
			params: {
				middleName: string;
				firstName: string;
				lastName: string;
				phone: string;
			},
			messages: {
				success: string;
				failed: string;
			},
		) => void;

		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		scheme: SchemeProps.Union[];
		lockSubmit: boolean;
		valid: boolean;
	}
}
