import React, { useMemo, useEffect } from 'react';
import Slider from 'rc-slider';
import { Localized } from '@fluent/react';

export const ProteinSlider = ({ proteinsRange = [], protein = '', onChange }: any) => {
	const marks = useMemo(
		() => proteinsRange.reduce((acc: any, val: any) => ({ ...acc, [val]: val }), {}),
		[proteinsRange],
	);
	useEffect(() => {
		onChange(proteinsRange[0]);
	}, [proteinsRange]);
	return (
		<div className='protein-slider'>
			<div className='label'>
				<Localized id='prices-row-header-protein' />
			</div>
			<div className='value'>{protein ? protein : proteinsRange[0]}</div>
			<div className='entry'>
				<Slider
					max={proteinsRange[proteinsRange.length - 1]}
					defaultValue={protein || proteinsRange[0]}
					min={proteinsRange[0]}
					onChange={onChange}
					marks={marks}
					step={null}
				/>
			</div>
		</div>
	);
};
