export * from './objectSwitch';
export * from './metrics';
// export * from './sentry';
export * from './maps';
export * from './mask';
export * from './filter-map';
export * from './array-to-map';
export * from './getUrlParam';
export * from './removeKeyFromObj';

export const windowSize = () => window.innerWidth;

export function isMobile() {
	if (
		navigator.userAgent.match(/Android/i) ||
		navigator.userAgent.match(/iPhone/i) ||
		navigator.userAgent.match(/BlackBerry/i) ||
		navigator.userAgent.match(/Windows Phone/i)
	) {
		return true;
	} else {
		return false;
	}
}

let jivoUser: any | undefined;
export function jivoSetUser(phone: string, email?: string, firstName?: string, lastName?: string) {
	jivoUser = {
		name: `${firstName || '-'} ${lastName || '-'}`,
		email,
		phone,
	};
}

export async function jivoStyleFixesInit() {
	const addClasses = async () => {
		const inputs = document.querySelectorAll('jdiv input, jdiv textarea, .label_39._left_2d, .headerBox_3s');

		if (inputs.length > 0) {
			inputs.forEach((element) => {
				if (!element.classList.contains('custom')) {
					element.classList.add('custom');
				}
			});
		}
	};

	(window as any).jivo_onLoadCallback = () => {
		const jivo_api = (window as any).jivo_api;
		addClasses();

		// Preset onload session
		if (jivo_api && jivoUser) {
			// console.log(jivoUser)
			jivo_api.setContactInfo(jivoUser);
		}
	};

	(window as any).jivo_onChangeState = async () => {
		await Promise.delay(20);
		addClasses();
	};
}
