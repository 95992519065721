import styled from 'styled-components';

export const RadioButtonContainerStyle = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	padding-top: 18px;

	position: relative;

	&:before {
		position: absolute;
		top: 0;
		left: 0;
		content: attr(data-title);
		display: block;
		font-size: 10px;
		line-height: 14px;

		letter-spacing: 0.5px;
		text-transform: uppercase;

		color: #6c6f88;
		opacity: 0.7;
	}
`;
