import { UserProfile } from 'app/models';

export const getBasicContact = (profile: UserProfile) => ({
	middleName: profile.middleName,
	firstName: profile.firstName,
	lastName: profile.lastName,
	avatar: profile.avatar,
	phone: profile.phone,
	photo: profile.photo,
	uuid: profile.uuid,
	isAutoCreated: true,
});

export const user = {
	getSessionToken: (state: any) => state?.user?.sessionToken,
	getProfile: (state: any) => state?.user?.profile,
	getBasicProfile: (state: any) => state?.user?.profile && getBasicContact(state?.user?.profile),
};
