import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { getUrlParam } from 'app/utils';
import Switch from 'app/sharedComponents/Switch';
import { getCustomerOffers, getCustomerOffersDetails, User } from 'app/actions/customer';
import { customerTransportOffers, transportOnMapBounds } from 'app/selectors';
import { TransportOffer } from 'app/actions/customer';

import { PaddingContainer } from '../components/StyledCandidatesComponents';
import { TransportCard } from './TransportCard';
import { Details } from './Details';
import { filters as filtersSelector } from 'app/selectors';
import Loader from 'react-loader-spinner';
import { getDriverName, getDriverInfo } from './utils';

const SwitchContainer = styled.div`
	display: flex;
	justify-content: space-between;
`;

const ICON_NO_AVATAR = require('src/assets/icons/avatar_driver.jpg');
const ICON_NO_DRIVER_PHOTO = require('src/assets/icons/avatar_autotype.png');

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

// const getDriverAvatar = (driver: User | any) => driver?.avatar || ICON_NO_AVATAR;
const getDriverPhoto = (driver: User | any) => driver?.photo || ICON_NO_AVATAR;
const getTransportPhoto = (transport: any) => transport.avatar || ICON_NO_DRIVER_PHOTO;

export const OffersByTransport = ({
	selectedTransport,
	choosenTransport,
	onTransportHover,
	onTransportSelect,
	onShowRoute,
	onShowBid,
}: any) => {
	const dispatch = useDispatch();
	const [showDetails, setShowDetails] = useState(false);
	const [showDriverFirst, setShowDriverFirst] = useState(false);
	const { data: offers, next, loading } = useSelector(customerTransportOffers);
	const filters = useSelector(filtersSelector);
	const { bounds, filter } = useSelector(transportOnMapBounds);
	const history = useHistory();

	useEffect(() => {
		if (offers.length < 20 && next) {
			dispatch(getCustomerOffers(next));
		}
	}, [offers, next]);

	useEffect(() => {
		dispatch(getCustomerOffers());
	}, [bounds, filter, filters]);
	useEffect(() => {
		const urlTransportId = getUrlParam('transportId');

		if (urlTransportId) {
			handleClick(urlTransportId);
		}
	}, []);
	const handleClick = (id: string) => {
		dispatch(getCustomerOffersDetails({ id }));
		setShowDetails(true);
		history.push(`/panel/transport/my/?transportId=${id}`);
	};

	const handleScroll: any = (event: any) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		const pixelsToBottom = scrollHeight - clientHeight - scrollTop;
		if (pixelsToBottom < 50 && next) dispatch(getCustomerOffers(next));
	};

	const handleOnClose = () => {
		history.push('/panel/transport/my/');
		setShowDetails(false);
	};

	return (
		<>
			{showDetails && <Details onShowBid={onShowBid} onShowRoute={onShowRoute} onClose={handleOnClose} />}
			<Scrollbars onScroll={handleScroll}>
				<PaddingContainer>
					<SwitchContainer>
						<p>Показывать водителей</p>
						<Switch checked={showDriverFirst} onChange={setShowDriverFirst} />
					</SwitchContainer>
					{offers.map(({ transport, driver = {} }: TransportOffer) => (
						<TransportCard
							onSelect={() => onTransportSelect(transport.uuid)}
							onMouseEnter={() => onTransportHover({ [transport.uuid]: true })}
							onMouseLeave={() => onTransportHover({ [transport.uuid]: false })}
							onClick={() => handleClick(transport.uuid)}
							key={transport.uuid}
							selected={selectedTransport[transport.id]}
							checked={choosenTransport[transport.id]}
							title={showDriverFirst ? getDriverName(driver) : transport.ident}
							text={showDriverFirst ? getDriverInfo(driver) : transport?.transportType?.name}
							isVerified={transport.isVerified}
							primaryPhoto={showDriverFirst ? getDriverPhoto(driver) : getTransportPhoto(transport)}
							secondaryPhoto={showDriverFirst ? getTransportPhoto(transport) : getDriverPhoto(driver)}
							status={transport.status}
						/>
					))}
					{loading && (
						<LoaderContainer>
							<Loader color='#2196F3' height={34} width={34} type='Oval' />
						</LoaderContainer>
					)}
				</PaddingContainer>
			</Scrollbars>
		</>
	);
};
