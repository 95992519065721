import React, { useState } from 'react';

import MapMark from 'app/sharedComponents/MapMark/MapMark';
import { colorSelectorByStatus } from './Marker';
import { BallonContainer } from './Marker';

export type ClusterProps = {
	data?: any;
	count?: number;
	onClick?: () => void;
	onDoubleClick?: Function;
	onMouseEnter: Function;
	onMouseLeave: Function;
	statuses?: string[];
	hovered?: boolean;
	hintComponent?: any;
	lat?: any;
	lng?: any;
};

export const Cluster = ({
	data,
	count,
	onClick,
	onDoubleClick,
	onMouseEnter,
	onMouseLeave,
	statuses = [],
	hovered,
	hintComponent: Ballon,
}: ClusterProps) => {
	const [show, setShow] = useState(false);

	const handleMouseLeave = () => {
		setShow(false);
		onMouseLeave();
	};

	const handleMouseEnter = () => {
		setShow(true);
		onMouseEnter();
	};

	const colors = statuses.reduce((acc: any, item: any) => {
		const color = colorSelectorByStatus(item);
		return acc.includes(color) ? [...acc] : [...acc, color];
	}, []);

	return (
		<MapMark
			cluster={count}
			sections={colors.length}
			color={colors}
			hovered={hovered}
			onClick={onClick}
			onDoubleClick={onDoubleClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			{show && Ballon && (
				<BallonContainer onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)}>
					<Ballon data={data} />
				</BallonContainer>
			)}
		</MapMark>
	);
};
