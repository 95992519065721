import { UserContact, DriverDocs, Verification } from './user';
import { UserApiModel } from 'app/api/types';
import { BidDetails } from './bid';

export enum TransportStage {
	ReturnToLoading = 'return_to_loading',
	ToUnloading = 'to_unloading',
	OnUnloading = 'on_unloading',
	ToLoading = 'to_loading',
	OnLoading = 'on_loading',
	Unloaded = 'unloaded',
}

export interface TransportType {
	addition: { [index: string]: any };
	category: string;
	name: string;
	type: string;
	uuid: string;
}

export interface Transport {
	transportType?: TransportType;
	status: 'free' | 'busy';
	avatar?: string;
	photo?: string;
	uuid: string;
	id: string;
	isVerified?: boolean;

	trailerIdent?: string;
	onBid: boolean;
	height?: number;
	length?: number;
	gross?: number;
	ident?: string;
	brand?: string;
}

export interface LoadingType {
	addition: { [index: string]: any };
	category: string;
	name: string;
	type: string;
	uuid: string;
}

export interface TransportType {
	addition: { [index: string]: any };
	category: string;
	name: string;
	type: string;
	uuid: string;
}

export interface TrailerType {
	addition: { [index: string]: any };
	category: string;
	name: string;
	type: string;
	uuid: string;
}

export namespace Transport {
	export interface Dimensions {
		height: number;
		length: number;
		gross: number;
	}
}

export interface Executor {
	transport: Transport;
	contact: UserContact;
	bid: BidDetails;
}

export interface VacantExecutor {
	transport: Transport;
	contact: UserContact;
}

export interface Insurance {
	expiredAt?: string;
	issuedAt?: string;
	mainPage?: string;
	number?: string;
	id?: string;
	uuid: string;
	verification?: Verification;
}

export interface Certificate {
	id: string;
	uuid: string;

	verification: Verification;

	kind: string; // transport type of cert car
	ident: string;

	brand?: string | null;
	model?: string | null;
	color?: string | null;
	manufactureYear?: number | null;
	vin?: string | null;
	number?: string | null;
	issuedAt?: string; // Date

	ownerPage?: string;
	mainPage?: string;
}

export interface DriverProfile extends UserApiModel.UserProfile {
	driverDocs?: DriverDocs;
	phone: string;
	uuid: string;
}

export interface DriverCar {
	// Transport id
	uuid: string;

	certificates: { [uuid: string]: Certificate };
	insurances: { [uuid: string]: Insurance };

	contact: UserContact;
	transport: Transport;
}

// empty - new car
type EmptyCarBaseConfig = {
	kind: string | null;
	brand?: string | null;
	number?: string | null;
	model?: string | null;
	color?: string | null;
	manufactureYear?: number | null;
	vin?: string | null;
	issuedAt?: string | null;
	ownershipType?: string | null;
	ownershipDocument?: string | null;
	ownershipDocumentDate?: string | null;
	ownerPage?: File;
	mainPage?: File;
};

export interface EmptyCarBase {
	ident: string;
	trailerIdent?: string | null;
}

export interface EmptyCarDocuments {
	transport?: null | EmptyCarBaseConfig;
	trailer?: null | EmptyCarBaseConfig;
	policy?: null | {
		number: string | null;
		issuedAt: string | null;
		expiredAt: string | null;
		mainPage?: File;
	};
}

export interface EmptyCarDimensions {
	gross?: number | null;
	height?: number | null;
	length?: number | null;
}

export interface EmptyCar {
	base?: EmptyCarBase;
	documents?: EmptyCarDocuments;
	dimensions?: EmptyCarDimensions;
}
