import React from 'react';
import { AvatarDriver, AvatarAutotype, Check } from 'app/components/Icons';

export type AvatarProps = React.HTMLAttributes<HTMLDivElement> & {
	driver?: string;
	transport?: string;
	type?: 'driver' | 'transport';
	verified?: boolean;
	selected?: boolean;
	stacked?: boolean;
};

export const Avatar = (props: AvatarProps) => {
	const { driver, transport, type, verified, selected, stacked, ...htmlProps } = props;
	const driverElement = typeof driver === 'string' ? <img src={driver} /> : <AvatarDriver />;
	const transportElement = typeof transport === 'string' ? <img src={transport} /> : <AvatarAutotype />;
	const foreground = type !== 'transport' ? driverElement : transportElement;
	const background = type !== 'driver' ? driverElement : transportElement;
	return (
		<div className='avatar' {...htmlProps}>
			<div className='front'>
				{foreground}
				{selected && (
					<div className='checked'>
						<Check color='#FFF' />
					</div>
				)}
			</div>
			{stacked && <div className='back'>{background}</div>}
			{verified && (
				<div className='check'>
					<Check color='#FFF' size={18} />
				</div>
			)}
		</div>
	);
};
