import * as React from 'react';
import { SchemeProps, Field } from '../props';

import { Localized } from '@fluent/react';
import moment from 'moment';
import { getLocalizedMessage } from 'app/locales';
import { FluentBundle } from '@fluent/bundle';
import { store } from 'src/main';
import { DatePicker } from 'app/sharedComponents/DatePicker';

const initialState = () => ({
	date: new Date(),
	validationMessage: '',
});

type Hooks = Field.Hooks<Date>;
const TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
const DATE_FORMAT = 'YYYY-MM-DD';
export class SelectDateTime extends React.Component<SelectDateTime.Props, SelectDateTime.State> implements Hooks {
	public state: SelectDateTime.State = initialState();

	private bundlesLocales: FluentBundle[] | undefined;

	constructor(props: SelectDateTime.Props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);

		const {
			onUpdate,
			field: { name, defaultValue, showTime },
		} = props;
		if (defaultValue) {
			this.state.date = defaultValue;
			onUpdate(name, moment(defaultValue).format(showTime ? TIME_FORMAT : DATE_FORMAT));
		}
		this.bundlesLocales = store.getState().common.bundlesLocales;
	}

	private validate(value: Date) {
		const { rules } = this.props.field;
		const { form } = this.props;

		let validationMessage = '';

		if (rules) {
			this.setState({ validationMessage: '' });
			rules.forEach((check: Function) => {
				const report = check(value, form);

				if (report !== true) {
					validationMessage = getLocalizedMessage(report) || 'Invalid report';
				}
			});

			this.setState({ validationMessage });
		}

		if (validationMessage) {
			this.setState({ validationMessage });

			return validationMessage;
		}

		return true;
	}

	public async updateValue(value: Date | string) {
		const {
			onUpdate,
			field: { name, showTime },
		} = this.props;

		const newDate = typeof value === 'string' ? moment(value).toDate() : value;
		await this.setState({ date: newDate });
		onUpdate(name, moment(newDate).format(showTime ? TIME_FORMAT : DATE_FORMAT));
	}

	public reset() {
		const { defaultValue } = this.props.field;
		if (defaultValue) {
			this.updateValue(defaultValue);
		}
	}

	private onChange(date: Date) {
		const { name } = this.props.field;
		const { onUpdate } = this.props;

		this.setState({
			date,
		});

		this.validate(date);

		// onUpdate(name, moment(date).format(TIME_FORMAT));
		//ts-ignore
		onUpdate(name, date.toString());
	}

	public render() {
		const { name, label, rounded, localized, disabled, showTime, noBorder, showIcon } = this.props.field;
		const { date, validationMessage } = this.state;

		const modificators = [];

		if (validationMessage) {
			modificators.push('date--invalid');
		}

		if (rounded) {
			modificators.push('rounded');
		}
		return (
			<div className={'form-item date ' + modificators.join(' ')}>
				<div className='label'>{localized ? <Localized id={label} /> : label}</div>

				<DatePicker
					required
					format='dd.MM.yyyy'
					noBorder={noBorder}
					showIcon={showIcon}
					//@ts-ignore
					onChange={this.onChange}
					disabled={disabled}
					showTime={showTime}
					locale='ru-RU' // @todo: adding dynamic from store
					//@ts-ignore
					value={date}
					name={name}
				/>

				{<div className='input__message'>{this.state.validationMessage}</div>}
			</div>
		);
	}
}

export namespace SelectDateTime {
	export interface Props extends Field.ComponentProps<string, SchemeProps.SelectDateTime> {
		// @default from base
		rules: Function[];
	}

	export interface State {
		date?: Date;
		validationMessage: string;
	}
}
