import React from 'react';
import styled from 'styled-components';

export const ActionButton = styled.button<any>`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;

	font-family: inherit;

	border-radius: 3px;
	width: 60px;
	padding: 4px;

	& > img {
		width: 32px;
		height: 32px;
		object-fit: contain;
	}

	& > div {
		font-family: inherit;
		font-size: 10px;
		line-height: 14px;

		text-align: center;
		letter-spacing: 0.5px;
		text-transform: uppercase;

		color: #6c6f88;
		opacity: 0.7;
	}

	&:disabled {
		opacity: 0.5;
		pointer-events: none;
	}

	&:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}
`;

export interface ActionProps {
	title: string;
	handler?: (event: MouseEvent) => any;
	icon: string;
	disabled?: boolean;
}

export const ActionsContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	width: 100%;
`;

interface ActionsContainerProps {
	actions: ActionProps[];
}

const Actions: React.FC<any> = (props: ActionsContainerProps) => {
	const Buttons = props.actions.map((actionProps: ActionProps, i: Number) => (
		<ActionButton disabled={actionProps.disabled} key={'button-index-' + i} onClick={actionProps.handler}>
			<img src={actionProps.icon} />
			<div>{actionProps.title}</div>
		</ActionButton>
	));

	return <ActionsContainer>{Buttons}</ActionsContainer>;
};

export default Actions;
