import { BasicTypes } from 'app/containers/Builder/props';

export const basePrice = [
	{
		label: 'prices-label-from',
		type: BasicTypes.Address,
		localized: true,
		required: true,
		name: 'from',
	},

	{
		// choices: [ /* lazy */ ],
		disableLocalizeForOption: true,
		label: 'prices-label-cargo',
		type: BasicTypes.Select,
		localized: true,
		required: true,
		name: 'cargo',
		rounded: true,
	},
];
export const basePriceNew = [
	{
		label: 'prices-label-from',
		type: BasicTypes.Address,
		localized: true,
		required: true,
		name: 'from',
	},

	{
		// choices: [ /* lazy */ ],
		disableLocalizeForOption: true,
		label: 'prices-label-cargo',
		type: BasicTypes.Select,
		localized: true,
		required: true,
		name: 'cargo',
		rounded: true,
	},
	{
		type: BasicTypes.Select,
		label: 'prices-label-class-type',
		disableLocalizeForOption: true,
		name: 'classType',
		hide: true,
		localized: true,
		rounded: true,
	},
];

export const summaryPrice = [
	{
		// choices: [ /* lazy */ ],
		disableLocalizeForOption: true,
		label: 'prices-label-cargo',
		type: BasicTypes.Select,
		localized: true,
		required: true,
		name: 'cargo',
		rounded: true,
	},
];

export const extendedPrice = [
	{
		// choices: [ /* lazy */ ],
		label: 'prices-label-class-type',
		disableLocalizeForOption: true,
		type: BasicTypes.Select,
		name: 'classType',
		localized: true,
		required: true,
		rounded: true,
	},

	{
		label: 'prices-label-protein',
		disableLocalizeForOption: true,
		type: BasicTypes.Slider,
		name: 'protein',
		localized: true,
		required: true,
		max: 10,
		min: 0,
	},
];
