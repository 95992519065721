import { Action, handleActions } from 'redux-actions';
import { SET_BID_POINTS, SET_BID_POINTS_LOADING } from 'app/actions/bidPoints';

type State = {
	archive: any[];
	all: any[];
	my: any[];
	loading: boolean;
};

export const initialState: State = {
	archive: [],
	all: [],
	my: [],
	loading: false,
};

export const bidPointsReducer = handleActions<State, any>(
	{
		//@ts-ignore
		[SET_BID_POINTS_LOADING]: (state, { payload }: Action<boolean>) => {
			return {
				...state,
				loading: payload,
			};
		},
		[SET_BID_POINTS]: (state, payload: Action<{ type: string; data: any }>) => {
			return {
				...state,
				loading: false,
				//@ts-ignore
				[payload.payload.type]: payload.payload.data,
			};
		},
	},
	initialState,
);
