import { Bid, BidList, BidDetails, Cursor } from 'app/models';
import { BidApiModel, CommonApiModel } from './types';
import { v1, prepareParams } from '.';
import qs from 'qs';

export async function getRegionsCommon(): Promise<CommonApiModel.RegionsCommon> {
	const response = await v1.get<CommonApiModel.GetFiltersResponse>('/config/bid_filters');
	return response.data.data;
}

export async function bidGetCommonMeta(): Promise<BidApiModel.CommonMeta[]> {
	const response = await v1.get<BidApiModel.CommonMetaResponse>('/config/dictionary');
	return response.data.data ?? [];
}

const removeEmptyParams = (params: any = {}) =>
	Object.keys(params).reduce((acc, key) => {
		return params[key] && ((Array.isArray(params[key]) && params[key].length) || !Array.isArray(params[key]))
			? {
					...acc,
					[key]: params[key],
			  }
			: acc;
	}, {});

export async function bidGetList(params: BidApiModel.BidsRequest, cursor?: string): Promise<BidList> {
	const preparedParams = prepareParams(removeEmptyParams(params));

	if (cursor) preparedParams.cursor = cursor;
	if (preparedParams.type === Bid.BidType.All) delete preparedParams.type;
	if (preparedParams.ordering) preparedParams.ordering = preparedParams.ordering.join(',');

	const response = await v1.get<BidApiModel.BidsResponse>('/bid', {
		params: preparedParams,
		paramsSerializer: function (params) {
			return qs.stringify(params, { arrayFormat: 'comma' });
		},
	});
	return {
		cursor: response.data.meta?.links as Cursor,
		list: response.data.data,
	};
}

export async function suspendOrder(itemId: string): Promise<Boolean> {
	const response = await v1.post<BidApiModel.CommonMetaResponse>(`/bid/${itemId}/suspend`);
	return response.status === 204 ? true : false;
}

export async function unsuspendOrder(itemId: string): Promise<Boolean> {
	const response = await v1.delete(`/bid/${itemId}/suspend`);
	return response.status === 204 ? true : false;
}

export async function bidGetDetails(id: string): Promise<BidDetails> {
	const response = await v1.get<BidApiModel.BidDetailsResponse>(`/bid/${id}`);
	return response.data.data as BidDetails;
}

export async function bidCreate(data: BidApiModel.BidCreateRequest): Promise<BidDetails> {
	const response = await v1.post<{ data: BidDetails; meta?: any }>('/bid', prepareParams(data));
	return response.data.data;
}

export async function bidUpdate(id: string, data: BidApiModel.BidUpdateRequest): Promise<BidDetails> {
	const response = await v1.patch<{ data: BidDetails; meta?: any }>(`/bid/${id}`, prepareParams(data));
	return response.data.data;
}

export async function bidDelete(id: string) {
	const response = await v1.delete(`/bid/${id}`);
	return response;
}
