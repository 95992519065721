import React from 'react';

export const Download: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none'>
		<path
			d='M8.75 0.5V2H7.25V0.5H8.75ZM8.75 3.5V9.93945L11.2197 7.46973L12.2803 8.53027L8 12.8105L3.71973 8.53027L4.78027 7.46973L7.25 9.93945V3.5H8.75ZM15.5 11.75V14C15.5 14.8198 14.8198 15.5 14 15.5H2C1.1802 15.5 0.5 14.8198 0.5 14V11.75H2V14H14V11.75H15.5Z'
			fill={color}
		/>
	</svg>
);

export default Download;
