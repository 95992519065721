export const docTypes = {
	tr_report: 'Акт',
	tr_contract: 'Рамочный договор между диспетчером и перевозчиком',
	tr_contract_add: 'Дополнительное соглашение к рамочному договору между диспетчером и перевозчиком',
	tr_customer_contract: 'Рамочный договор между диспетчером и заказчиком',
	tr_customer_contract_add: 'Дополнительное соглашение к рамочному договору между диспетчером и заказчиком',
};

export const ordering = {
	created_at: 'Дата создания',
	title: 'Заголовок',
	company: 'Заказчик',
};

//documents/company

//sort

//зарегистрировать свой номер в деве сделать компанию

export const getOptions = (optionsObject: any) => {
	return Object.keys(optionsObject).reduce((acc: any, el: any) => {
		//@ts-ignore
		return [...acc, { value: el, label: optionsObject[el] }];
	}, []);
};

export const docTypesOptions = getOptions(docTypes);
export const orderingOptions = getOptions(ordering);
