import { createAction } from 'redux-actions';

export const SET_DOCUMENTS_LIST = 'SET_DOCUMENTS_LIST';
export const SET_DOCUMENTS_LOADING = 'SET_DOCUMENTS_LOADING';
export const SET_DOCUMENTS_LIST_MORE = 'SET_DOCUMENTS_LIST_MORE';
export const GET_DOCUMENTS_LIST = 'GET_DOCUMENTS_LIST';
export const GET_DOCUMENTS_LIST_MORE = 'GET_DOCUMENTS_LIST_MORE';

export const getDocumentsList = createAction<any>(GET_DOCUMENTS_LIST);
export const getDocumentsListMore = createAction<void>(GET_DOCUMENTS_LIST_MORE);
export const setDocumentsLoading = createAction<boolean>(SET_DOCUMENTS_LOADING);
export const setDocumentsList = createAction<any>(SET_DOCUMENTS_LIST);
export const setDocumentsListMore = createAction<any>(SET_DOCUMENTS_LIST_MORE);
