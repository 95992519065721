import { DriverActions } from 'app/actions';

import { handleActions, Action } from 'redux-actions';
import { DriverProfile } from 'app/models';

interface DriversState {
	[uuid: string]: DriverProfile;
}

const driversReducer = handleActions<DriversState, any>(
	{
		[DriverActions.Action.ADD]: (state, { payload }: Action<DriverProfile>) =>
			payload
				? {
						...state,
						[payload.uuid]: payload,
				  }
				: state,
		[DriverActions.Action.UPDATE]: (state, { payload }: Action<DriverProfile>) => {
			return payload && payload.uuid
				? {
						...state,
						[payload.uuid]: payload,
				  }
				: state;
		},
	},
	{},
);

export default driversReducer;
