import * as React from 'react';

//other deps
import _ from 'lodash';
import { Map } from 'immutable';

//types
import { Field, SchemeProps, BasicTypes } from './props';
import { Verification, VerifyStatus } from 'app/models';

//----------------------------------------------------------
// FormBuilder
//----------------------------------------------------------
export default class FormBuilder extends React.Component<FormBuilder.Props, FormBuilder.State> {
	public formRefs: Map<string, Field.Component> = Map();

	public state: FormBuilder.State = {
		form: {},
		verification: null,
	};

	constructor(props: FormBuilder.Props) {
		super(props);

		this.getFields = this.getFields.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.getRef = this.getRef.bind(this);
		this.reset = this.reset.bind(this);
	}

	public componentDidMount() {
		const { getRef } = this.props;

		if (getRef) {
			getRef(this);
		}
	}

	public UNSAFE_componentWillMount() {
		const { getRef } = this.props;

		if (getRef) {
			getRef(null);
		}
	}

	private async onUpdate(name: string, value: any) {
		const { onUpdate } = this.props;

		this.state.form[name] = value; // eslint-disable-line react/no-direct-mutation-state

		if (onUpdate) {
			onUpdate(name, value);
		}
	}

	private getFields(presetScheme?: SchemeProps.Form): JSX.Element[] | JSX.Element {
		const scheme = presetScheme ? presetScheme : this.props.scheme;
		const { form } = this.state;
		const { onUpdate } = this;
		const filterEntries = Object.entries(form);
		const selected = filterEntries.filter((e) => !Array.isArray(e[1])).map((e) => e[0]);

		return scheme.map((field: SchemeProps.Union, index: number) => {
			if ('choices' in field && selected.indexOf(field.name) > -1) {
				const { choices = [] } = field;

				if (!choices.find((e) => e.value === 'cleanId')) {
					choices.unshift({ label: '  ', value: 'cleanId' });
				}
			}

			//@ts-ignore
			if (field.hide) return null;

			if (field.type === BasicTypes.Group) {
				const groupField = field as SchemeProps.Group;

				const { contains, direction, className, prepareProps } = groupField;
				const Group = BasicTypes.COMPONENTS[field.type];
				const children = this.getFields(contains);

				let extendedProps = {};
				if (prepareProps) {
					extendedProps = prepareProps(form, groupField);
				}

				const groupProps = {
					direction,
					className,
					...extendedProps,
				};

				return (
					<Group key={`group-${index}`} {...groupProps}>
						{children}
					</Group>
				);
			} else {
				const Container = BasicTypes.COMPONENTS[field.type] as any;

				// Prepare props before apply for field from scheme handler
				const prepareProps = (field as any).prepareProps;
				let extendedProps = { ...field, form };

				if (prepareProps) {
					extendedProps = {
						...extendedProps,
						...prepareProps(form, field),
					};
				}

				const { extendComponentProps, extendComponent: component } = extendedProps as Field.ExtendComponent;
				const ExtendComponent: React.FC | null = component ? component : null;

				let extendedComponent: JSX.Element | null = null;

				if (ExtendComponent && extendComponentProps) {
					extendedComponent = <ExtendComponent key={`extended-${field.name}`} {...extendComponentProps} />;
				}
				const ref = (node: Field.Component<any> | null) => {
					if (!node) {
						this.formRefs = this.formRefs.delete(field.name);
					} else {
						this.formRefs = this.formRefs.set(field.name, node);
					}
				};

				const props: any = {
					key: extendedProps.name + index,
					form: _.cloneDeep(form),
					field: extendedProps,
					onUpdate,
					ref,
				};

				if (extendedComponent) {
					return [<Container key={`builder-container-${index}`} {...props} />, extendedComponent] as any;
				} else {
					return <Container key={`builder-container-${index}`} {...props} />;
				}
			}
		});
	}

	public getRef(name: string) {
		return this.formRefs.get(name);
	}

	public async reset() {
		const { formRefs } = this;

		// Clear final values
		await this.setState({ form: {} });

		// Reset inputs & handle presets value for form
		formRefs.keySeq().forEach((name) => {
			const ref = formRefs.get(name);
			if (name !== 'rate' && ref && ref.reset) {
				ref.reset();
			}
		});

		// Reset for rate
		const rate = formRefs.get('rate');
		if (rate) {
			await rate.reset();
		}
	}

	public outputOperatorCommentary(verification: Verification) {
		if (verification.status === VerifyStatus.DetailsNeeded) {
			this.setState({ verification });
		}
	}

	public isValid() {
		const { form } = this.state;
		const keysWithRules = this.props.scheme.filter((field: any) => !!field.rules || field.required);
		const fields: JSX.Element | JSX.Element[] = this.getFields();
		const reportBucket = [];

		if (Array.isArray(fields)) {
			fields.forEach((node: JSX.Element) => {
				if (node.props.onUpdate) {
					node.props.onUpdate();
				}
			});
		}

		for (let i = 0; i < keysWithRules.length; i++) {
			const field: any = keysWithRules[i];
			const value = form[field.name] || '';
			const ref = this.getRef(field.name) as any;

			// Show validation message
			if (ref && ref.validate) {
				const validationState = ref.validate(value);

				if (validationState !== true) {
					reportBucket.push({
						message: validationState,
						fieldName: field.label,
					});
				}
			}
		}

		return reportBucket.length === 0 ? true : reportBucket;
	}

	public renderOperatorCommentary() {
		const { verification } = this.state;
		if (verification && verification.comment) {
			return (
				<div className='builder__comment'>
					<h4>Сообщение от оператора:</h4>
					<p>{verification.comment}</p>
				</div>
			);
		}

		return null;
	}

	public render() {
		return (
			<div className='builder fields'>
				{this.renderOperatorCommentary()}
				{this.getFields()}
			</div>
		);
	}
}

namespace FormBuilder {
	// Props from parent element e.g <Cmp custom={true} />
	export interface Props {
		onUpdate?: (name: string, value: any) => void;
		scheme: SchemeProps.Form;
		getRef?: (builder: FormBuilder | null) => any;
	}

	// Main component state
	export interface State {
		form: {
			[name: string]: any;
		};
		verification: Verification | null;
	}
}
