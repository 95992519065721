import React from 'react';
import styled from 'styled-components';

import { ActionProps } from './Actions';

const ICON_CLOSE = require('assets/icons/i-close.png');

interface MultiselectOperationsProps {
	actions: ActionProps[];
	selectedCount: number;
	onCloseClick?: (event: MouseEvent) => void;
}

const Container = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background: #fff;
	box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.25);
	padding: 14px 12px;
	color: #6c6f88;
	font-size: 16px;
	line-height: 22px;
	box-sizing: border-box;

	.left-side,
	.right-side {
		display: flex;
		flex-direction: row;
		align-items: center;
	}

	.left-side {
		justify-content: flex-start;
	}
	.right-side {
		justify-content: flex-end;

		button:not(:first-child) {
			margin-left: 12px;
		}
	}
`;

const CloseButton = styled.button<any | HTMLButtonElement>`
	font-size: 0;
	padding: 0;
	margin: 0;
	margin-right: 16px;
`;

const ActionButton = styled.button<any | HTMLButtonElement>`
	width: 32px;
	height: 32px;
	padding: 0;
	font-size: 0;
	& > img {
		width: 32px;
		height: 32px;
		object-fit: contain;
	}
`;

const MultiselectOperations: React.FC<MultiselectOperationsProps> = (props) => {
	return (
		<Container>
			<div className='left-side'>
				<CloseButton onClick={props.onCloseClick}>
					<img width='24' height='24' src={ICON_CLOSE} />
				</CloseButton>

				<span>Выбрано {props.selectedCount}</span>
			</div>

			<div className='right-side'>
				{props.actions.map((actionProps: ActionProps, i: Number) => (
					<ActionButton key={'button-index-' + i} onClick={actionProps.handler} title={actionProps.title}>
						<img width='32' src={actionProps.icon} />
					</ActionButton>
				))}
			</div>
		</Container>
	);
};

export default MultiselectOperations;
