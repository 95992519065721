import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { BidCard } from 'app/containers/Executors/components/BidCard';
import { bidGetDetails } from 'app/api';
import Loader from 'react-loader-spinner';

export const BidMapBubbleContainer = styled.div`
	position: relative;
	width: 298px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 0px 32px rgba(103, 116, 150, 0.246971);
	padding: 6px 12px;
	z-index: 9999999;
	text-align: left;
	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 30px;
		height: 30px;
		border-radius: 4px;
		margin-left: -15px;
		margin-top: -16px;
		background: #fff;
		transform: rotate(45deg);
	}

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 32px;
	}

	hr {
		margin-left: -12px;
		margin-right: -12px;
		border: none;
		height: 1px;
		background-color: #d6d8e5;
	}
`;

export const ScrollContainer = styled.div`
	display: flex;
	flex-direction: column;
	max-height: 250px;
	overflow-y: auto;
`;

const LoaderContainer = styled.div`
	height: 95px;
	display: flex;
	justify-content: center;
	align-items: center;
`;
interface BidMapBubbleProps {
	data?: any;
	onActionFlightsClick?: () => void;
	onActionMessageClick?: () => void;
	onActionRouteClick?: () => void;
	onActionTrackClick?: () => void;
}

export const MapBubbleWithoutContainer: React.FC<BidMapBubbleProps> = ({ data = {} }) => {
	const [bid, setBid] = useState<any>(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		async function fetchBid() {
			const res = await bidGetDetails(data.bidId);
			setBid(res);
			setLoading(false);
		}
		if (data.bid) {
			setBid(data.bid);
		} else {
			setLoading(true);
			fetchBid();
		}
	}, [data]);

	return (
		<>
			{bid && !loading && (
				<>
					<BidCard noBorder bid={bid} onClick={() => {}} onMouseEnter={() => {}} onMouseLeave={() => {}} />
				</>
			)}
			{loading && (
				<LoaderContainer>
					<Loader color='#2196F3' height={34} width={34} type='Oval' />
				</LoaderContainer>
			)}
		</>
	);
};

const MapBubble = ({ data }: any) => (
	<BidMapBubbleContainer>
		<MapBubbleWithoutContainer data={data} />
	</BidMapBubbleContainer>
);

export default MapBubble;
