import React from 'react';

export const Pencil: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M0.903682 14.8172L1.34165 11.3135C1.34855 11.2583 1.37363 11.207 1.41294 11.1677L9.26387 3.31679L10.6361 1.94454L10.1187 1.42715L10.6361 1.94454C11.1243 1.45639 11.9157 1.45639 12.4039 1.94454L12.9342 1.41421L12.4039 1.94454L14.0555 3.59612C14.5436 4.08427 14.5436 4.87573 14.0555 5.36388L12.7497 6.66967L10.0297 9.38967L4.83228 14.5871C4.79297 14.6264 4.74168 14.6515 4.68651 14.6583L1.18276 15.0963C1.02099 15.1165 0.88346 14.979 0.903682 14.8172Z'
			stroke={color}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Pencil;
