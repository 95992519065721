import { Cellwrapper, HeaderWrapper, AddresCell, PriceCell, OrderMyInfo, TenderStatus } from './Cells';
export const columns = [
	{
		localizedId: 'orders-row-start',
		accessor: 'dateFrom',
		Cell: Cellwrapper(),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-from',
		accessor: 'from',
		Cell: Cellwrapper(AddresCell),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-to',
		accessor: 'to',
		Cell: Cellwrapper(AddresCell),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-distance',
		accessor: 'distance',
		Cell: Cellwrapper(),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-cargo',
		accessor: 'cargo',
		Cell: Cellwrapper(),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-weight',
		accessor: 'weight',
		Cell: Cellwrapper(),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-rate',
		accessor: 'price',
		Cell: Cellwrapper(PriceCell),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-rate-per-km',
		accessor: 'pricePerKm',
		Cell: Cellwrapper(PriceCell, { isPerKm: true }),
		Header: HeaderWrapper,
	},
];

export const columnsMy = [
	{
		localizedId: 'orders-row-views',
		accessor: 'previews',
		Cell: Cellwrapper(OrderMyInfo, { icon: 'views' }),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-answers',
		accessor: 'requests',
		Cell: Cellwrapper(OrderMyInfo, { icon: 'reply', color: '#F4AE46' }),
		Header: HeaderWrapper,
	},
	{
		localizedId: 'orders-row-inwork',
		accessor: 'confirmed',
		Cell: Cellwrapper(OrderMyInfo, { icon: 'approve', color: '#79D9A5' }),
		Header: HeaderWrapper,
	},
];

export const tenders = [
	{
		localizedId: 'orders-row-status',
		accessor: 'tenderStatus',
		Cell: Cellwrapper(TenderStatus),
		Header: HeaderWrapper,
	},
];
