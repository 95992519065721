// import * as Sentry from '@sentry/browser';
import camelCaseKeys from 'camelcase-keys';
import snakeCaseKeys from 'snakecase-keys';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { store } from '../../main';
import { getSessionToken, getEnvUrl, IS_DEVELOPMENT_ENV } from 'app/config';
import { parseErrorMessages } from './interceptors/errors';

export * from './executor';
export * from './suggests';
export * from './prices';
export * from './driver';
export * from './emptyCar';
export * from './maps';
export * from './user';
export * from './rate';
export * from './bid';

//helpers
const getOsName = () => {
	const userAgent = window.navigator.userAgent;
	const platform = window.navigator.platform || '';

	let osName = 'Unknown OS';

	if (/Win/i.test(platform)) {
		osName = 'Windows';
	} else if (/Mac/i.test(platform)) {
		osName = 'macOS';
	} else if (/Linux/i.test(platform)) {
		osName = 'Linux';
	} else if (/Android/i.test(userAgent)) {
		osName = 'Android';
	} else if (/iPhone|iPad|iPod/i.test(userAgent)) {
		osName = 'iOS';
	}

	return osName;
};

export const apiSentryException = (error: RequestError): string | void => {
	if (process.env.NODE_ENV === 'development') console.log('[apiSentryException]: ', { error });

	if (process.env.NODE_ENV !== 'development') {
		// const {
		//     user: { profile },
		// } = store.getState();

		// const userId = profile ? profile.uuid : 'anonymous';

		// const extras = {
		//     statusCode: error.statusCode,
		//     request: error.request,
		//     data: error.data,
		//     name: error.name,
		// };

		// Sentry.configureScope(scope =>
		//     scope
		//         .setLevel(Sentry.Severity.Error)
		//         .setTag('api', error.name)
		//         .setUser({ id: userId })
		//         .setExtras(extras),
		// );

		// const eventId = Sentry.captureException(error);
		// error.sentryId = eventId;

		return;
	}
};

export class RequestError extends Error {
	public statusCode: number;
	public sentryId?: string;
	public name: string;
	public request: any;
	public data: any;

	constructor(name: string, request?: any, data?: any, statusCode: number = 500) {
		super(`Rejected Response "${name}" with ${statusCode}`);

		this.statusCode = statusCode;
		this.request = request;
		this.data = data;
		this.name = name;
	}
}

export interface RequestParams {
	[index: string]: any;
}

export interface ResponseParams {
	[index: string]: any;
}

export function prepareParams(source: RequestParams): RequestParams {
	return snakeCaseKeys<RequestParams>(source);
}

const hasValue = (el: any) => {
	if (Array.isArray(el)) {
		return !!el.length;
	}
	return !!el;
};

export const normalizeParams = (obj: any) => {
	return Object.keys(obj).reduce((acc: any, key: string) => {
		return hasValue(obj[key]) ? { ...acc, [key]: obj[key] } : { ...acc };
	}, {});
};

export function prepareResponse<T extends { [key: string]: any }>(source: ResponseParams): T {
	return camelCaseKeys<T>(source as T, { deep: true });
}

const API_V1_HOST = IS_DEVELOPMENT_ENV
	? `https://${getEnvUrl()}vezzer.no/api/v1`
	: window.location.origin === 'https://app.dev.vezzer.no'
	? 'https://dev.vezzer.no/api/v1'
	: 'https://vezzer.no/api/v1';

const API_V2_HOST = IS_DEVELOPMENT_ENV
	? `https://${getEnvUrl()}vezzer.no/api/v2`
	: window.location.origin === 'https://app.dev.vezzer.no'
	? 'https://dev.vezzer.no/api/v2'
	: 'https://vezzer.no/api/v2';

export const v1 = axios.create({ baseURL: API_V1_HOST });
v1.interceptors.request.use(preprocessRequest);
v1.interceptors.response.use(preprocessSuccessResponse, preprocessErrorResponse);

export const v2 = axios.create({ baseURL: API_V2_HOST });
v2.interceptors.request.use(preprocessRequest);
v2.interceptors.response.use(preprocessSuccessResponse, preprocessErrorResponse);

function preprocessRequest(config: AxiosRequestConfig) {
	const token = getSessionToken(store.getState().user.sessionToken);
	if (token) config.headers['Authorization'] = `Token ${token}`;

	config.headers['Accept-Language'] = 'ru';
	if (!config.headers['Accept']) config.headers['Accept'] = 'application/vnd.api+json';
	if (!config.headers['Content-Type']) config.headers['Content-Type'] = 'application/json';

	let withCustomHeader = true;

	// don`t use custom header for external `suggestions` service
	if (config.url?.includes('/suggestions/')) withCustomHeader = false;

	if (withCustomHeader) {
		const os = getOsName();
		config.headers['Warning'] = `299 - "version for ${os} 2.61"`;
	}

	return config;
}

function preprocessSuccessResponse(response: AxiosResponse) {
	return {
		...response,
		data: camelCaseKeys(response.data, { deep: true }),
	};
}

function preprocessErrorResponse(error: AxiosError) {
	const name = error?.config?.url ?? '';
	const status = error?.response?.status;
	const requestError = new RequestError(name, error.request, error.response, status);
	apiSentryException(requestError);

	parseErrorMessages(error);
	return Promise.reject(error);
}
