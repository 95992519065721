import PageTitle from 'app/components/PageTitle';
import React, { Component } from 'react';
import queryParser from 'app/utils/query-parser';
import { userVerifyEmail } from 'app/api';

class EmailVerification extends Component {
	public state = {
		token: '',
		pending: true,
		message: '',
	};

	public constructor(props: any) {
		super(props);

		this.getToken = this.getToken.bind(this);
	}

	public componentDidMount() {
		this.getToken();
	}

	private getToken() {
		const { token } = queryParser();
		if (token) {
			this.setState({ token }, () => {
				this.activateEmail();
			});
		} else {
			this.setState(() => ({
				pending: false,
				message: 'Ошибка! Ссылка не валидна или устарела',
			}));
		}
	}

	private async activateEmail() {
		try {
			const {
				data: { email },
			} = await userVerifyEmail();

			this.setState({ message: 'Спасибо, @ успешно подтвержден!'.replace('@', email) });
		} catch (error) {
			const message = 'Ошибка! Cсылка с подтверждением не валидна или устарела';
			this.setState({ message });
		}

		this.setState({ pending: false });
	}

	public getContent() {
		const { message, pending } = this.state;
		return pending ? 'Проверка токена. Пожалуйста подождите...' : message;
	}

	public render() {
		const { pending } = this.state;
		return (
			<div className='login page' style={{ flexDirection: 'column' }}>
				<PageTitle titleKey='title-login-page' />
				{this.getContent()}
				<br />
				<div>{!pending ? <a href='/panel/profile'>Вернуться на сайт</a> : null}</div>
			</div>
		);
	}
}

export default EmailVerification;
