import * as React from 'react';

//other deps
import moment from 'moment';
import { Localized } from '@fluent/react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//components
import UserDocumentsScan from './scans';
import * as Icons from 'app/components/Icons';
import InfoLabel from 'app/components/InfoLabel';
import ImageModal from 'app/components/ImageModal';

//types
import { RootState } from 'app/reducers';
import { UserProfile, DriverDocs, RoleEnum, DriverCar } from 'app/models';

//----------------------------------------------------------
// PreviewProfile
//----------------------------------------------------------
class PreviewProfile extends React.Component<PreviewProfile.Props, PreviewProfile.State> {
	public state: PreviewProfile.State = {};

	constructor(props: PreviewProfile.Props) {
		super(props);
	}

	public render() {
		const { docs, profile, cars } = this.props;

		if (!docs || !profile || !cars) {
			return null;
		}

		const verified =
			docs.passport.verification.status === 'verified' &&
			(docs.driverLicence ? docs.driverLicence.verification.status === 'verified' : true);

		const haveVerifiedTransport = profile ? !profile.needActions.includes('verify_transport') : false;

		const birthdayFieldLabel = !docs.passport.birthday ? null : moment(docs.passport.birthday).format('DD MMMM YYYY');

		const _renderVerifyHeader = () => {
			if (!verified) {
				return (
					<div className='alert panel processing'>
						<div className='icon'>
							<Icons.Check color='#AEB4C8' />
						</div>

						<div className='title'>
							<Localized id='profile-docs-preview-verify-processing-header' />
						</div>
					</div>
				);
			}
			if (haveVerifiedTransport) {
				return (
					<div className='alert panel verified'>
						<div className='icon'>
							<Icons.VerifyUser />
						</div>
						<div className='title'>
							<Localized id='profile-docs-preview-verify-success-header' />
						</div>
					</div>
				);
			} else {
				return (
					<div className='alert panel verified fill'>
						<div className='icon'>
							<Icons.CarInfo />
						</div>
						<div className='title'>
							<Localized id='profile-docs-preview-verify-success-transport-header' />
						</div>
					</div>
				);
			}
		};

		return (
			<div className='profile docs preview'>
				<div></div>
				<div className='content'>
					<div className='header-2 profile__title'>
						<Localized id='profile-docs-header' />
					</div>

					{_renderVerifyHeader()}

					<div className='row'>
						<div className='column'>
							<div className='label-text profile__subtitle'>
								<Localized id='profile-header-passport' />
							</div>
							<div className='items'>
								<InfoLabel
									title={<Localized id='profile-label-passport-series' />}
									text={docs.passport.number.slice(0, 4)}
								/>
								<InfoLabel
									title={<Localized id='profile-label-passport-number' />}
									text={docs.passport.number.slice(4)}
								/>
								<InfoLabel title={<Localized id='profile-label-passport-issuer' />} text={docs.passport.issuer} />
								<InfoLabel
									title={<Localized id='profile-label-passport-issued-at' />}
									text={moment(docs.passport.issuedAt).format('DD MMMM YYYY')}
								/>
								<InfoLabel
									title={<Localized id='profile-label-passport-issuer-code' />}
									text={docs.passport.issuerCode}
								/>
								<InfoLabel title={<Localized id='profile-label-passport-birthday' />} text={birthdayFieldLabel} />
								<InfoLabel
									title={<Localized id='profile-label-passport-birthplace' />}
									text={docs.passport.birthplace}
								/>
								<InfoLabel
									title={<Localized id='profile-label-passport-registration-address' />}
									text={docs.passport.registrationAddress}
								/>
								<InfoLabel title={<Localized id='profile-label-passport-snils' />} text={docs.passport.snils} />
							</div>
						</div>

						{profile.role !== RoleEnum.Driver || !docs.driverLicence ? null : (
							<div className='column'>
								<div className='label-text profile__subtitle'>
									<Localized id='profile-header-driver-licence' />
								</div>
								<div className='items'>
									<InfoLabel
										title={<Localized id='profile-label-licence-number' />}
										text={docs.driverLicence.number.toUpperCase()}
									/>
									<InfoLabel
										title={<Localized id='profile-label-licence-issued-at' />}
										text={moment(docs.driverLicence.issuedAt).format('DD MMMM YYYY')}
									/>
									<InfoLabel
										title={<Localized id='profile-label-licence-expired-at' />}
										text={moment(docs.driverLicence.expiredAt).format('DD MMMM YYYY')}
									/>
								</div>
							</div>
						)}
					</div>

					{/* passport scans */}
					<div className='row'>
						<div className='column'>
							<div className='row'>
								<div className='column'>
									<InfoLabel
										title={<Localized id='profile-label-passport-main-page' />}
										text={<ImageModal src={docs.passport.mainPage} />}
									/>
								</div>

								<div className='column'>
									<InfoLabel
										title={<Localized id='profile-label-passport-registration-page' />}
										text={<ImageModal src={docs.passport.registrationPage} />}
									/>
								</div>

								{Boolean(docs.passport.snilsPage) && (
									<div className='column'>
										<InfoLabel
											title={<Localized id='profile-label-passport-snils-page' />}
											text={<ImageModal src={docs.passport.snilsPage} />}
										/>
									</div>
								)}
							</div>
						</div>

						{/* license scans */}
						<div className='column'>
							<div className='row'>
								<div className='column'>
									<InfoLabel
										title={<Localized id='profile-label-licence-front-page' />}
										text={<ImageModal src={docs.driverLicence.mainPage} />}
									/>
								</div>

								<div className='column'>
									<InfoLabel
										title={<Localized id='profile-label-licence-back-page' />}
										text={<ImageModal src={docs.driverLicence.backPage} />}
									/>
								</div>
							</div>
						</div>
					</div>

					{!profile || !profile.driverDocs || !profile.driverDocs.bankAccount ? null : (
						<div className='row profile__org-docs'>
							<div className='column scans'>
								<UserDocumentsScan />
							</div>

							<div className='column'>
								<div className='label-text profile__subtitle'>
									<Localized id='profile-org-header' />
								</div>

								<div className='items'>
									<InfoLabel title={<Localized id='profile-account' />} text={profile.driverDocs.bankAccount.account} />
									<InfoLabel title={<Localized id='profile-bic' />} text={profile.driverDocs.bankAccount.bic} />
								</div>
							</div>
						</div>
					)}

					{profile && profile.interestedInDigitalSignature ? (
						<div className='alert panel digital-signature'>
							<div className='icon'>
								<Icons.Check color='#AEB4C8' />
							</div>
							<div className='title'>
								<Localized id='profile-digital-signature-requested' />
							</div>
						</div>
					) : null}

					<div className='action' onClick={() => this.props.toggleEditable(true)}>
						<Localized id='profile-action-edit' />
					</div>

					<div className='divider' />
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ user, driver }: RootState) => ({
	cars: driver.cars,
	docs: user.profile ? user.profile.driverDocs : undefined,
	profile: user.profile,
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<PreviewProfile.StateProps, PreviewProfile.DispatchProps, PreviewProfile.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(PreviewProfile);

namespace PreviewProfile {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		cars?: { [uuid: string]: DriverCar };
		docs?: DriverDocs;
		profile?: UserProfile;
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		toggleEditable: (editable: boolean) => void;
	}

	// Main component state
	export interface State {}
}
