import React from 'react';
import styled from 'styled-components';
import { DocTitle } from './DocTitle';
import { Icon } from 'app/components/Icons';
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';

const Container = styled.div`
	display: flex;
	justify-content: space-between;
	height: 48px;
	padding: 24px 22px;
	align-items: center;
	border-bottom: 1px solid #eff0f3;
`;

const IconContainer = styled.div`
	cursor: pointer;
`;

const ActionsContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 253px;
`;

const buttonTextMap = {
	signed: 'Подписан',
	new: 'Подписать',
	signing: 'Ожидает подписания',
};

export const DocumentInfo = ({ item, onSign, onDownload, FullScreenButtonSlot }: any) => {
	//@ts-ignore
	const buttonText = buttonTextMap[item.status];
	const isButtonDisabled = item.status !== 'new';

	return (
		<Container>
			<DocTitle item={item} />

			<ActionsContainer>
				<IconContainer onClick={() => onDownload(item)}>
					<Icon variant='download' />
				</IconContainer>

				{Boolean(FullScreenButtonSlot) && <FullScreenButtonSlot />}

				<Button styleType={ButtonStyleTypes.PRIMARY} disabled={isButtonDisabled} onClick={() => onSign(item)}>
					{buttonText}
				</Button>
			</ActionsContainer>
		</Container>
	);
};
