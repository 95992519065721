export const DEFAULT_CENTER = { lat: 47.2357, lng: 39.7015 };
export const DEFAULT_ZOOM = 5;

export const GOOGLE_API_KEY = 'AIzaSyC2M3d_GETeolzwj3aZVlOj0RB5G_x2Ti8';

export const MAP_OPTIONS = {
	// controlSize: 100,
	// mapTypeControl: true,
	// streetViewControl: true,
	// scaleControl: true,
	// fullscreenControl: true,
	// styles: [
	//     {
	//         featureType: 'poi.business',
	//         elementType: 'labels',
	//         stylers: [
	//             {
	//                 visibility: 'off',
	//             },
	//         ],
	//     },
	// ],
	// gestureHandling: 'greedy',
	// disableDoubleClickZoom: true,
	// minZoom: 11,
	// maxZoom: 18,
	// mapTypeId: 'roadmap',
	disableDoubleClickZoom: true,
	mapTypeControlOptions: {
		style: 2,
		position: 3,
		mapTypeIds: ['roadmap', 'satellite', 'terrain', 'hybrid'],
	},
	zoom: 16,
	mapTypeControl: false,
	streetViewControl: false,
	zoomControl: true,
	overviewMapControl: true,
	overviewMapControlOptions: { opened: true },
};
