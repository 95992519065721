import styled from 'styled-components';
const ICON_CHECKED = require('assets/icons/i-checked.png');
export const InfoColumns = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: stretch;
	margin-bottom: 10px;

	& > div {
		flex: auto;
		font-size: 16px;
		line-height: 22px;
		text-align: center;
		font-weight: bold;
		color: #000000;
		display: flex;
		flex-direction: column;

		&:not(:first-child) {
			border-left: 1px dashed #bfbfbf;
		}
	}
`;

export interface MarkerProps {
	icon?: string;
}
export const IconMarker = styled.div<any>`
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-size: 12px;
	line-height: 17px;
	color: ${(props) => props.color || '#6c6f88'};
	opacity: 0.7;

	& > img {
		display: inline-block;
		margin-right: 5px;
		vertical-align: middle;
	}
`;

export const CardPhoto = styled.img`
	--border-size: 2px;
	--photo-size: 40px;

	border: var(--border-size) solid #fff;
	height: var(--photo-size);
	width: var(--photo-size);
	position: relative;
	object-fit: cover;
	border-radius: var(--photo-size);
	box-sizing: border-box;
`;

export const CardPhotoVerified = styled.img`
	width: 16px;
	height: 16px;
	margin-left: -16px;
	position: relative;
	z-index: 2;
`;

export const CardPhotoSmall = styled(CardPhoto)`
	--photo-size: 30px;
`;

export const CardRow = styled.div`
	--vertical-padding: 5px;
	padding: var(--vertical-padding) 10px;

	&:first-child {
		padding-top: 0;
	}

	&:last-child {
		padding-bottom: 0;
	}

	&.borders {
		border-top: 1px solid #d6d8e5;

		&:not(:last-child) {
			border-bottom: 1px solid #d6d8e5;
		}
	}

	&.flex {
		display: flex;

		&.flex--column {
			flex-direction: column;
		}
	}
`;

export const CardHeader = styled(CardRow)`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	min-height: 37px;

	& > button {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		height: 100%;
		flex: auto;
		text-align: left;
		padding: 0;
	}

	.photos {
		user-select: none;
		font-size: 0;
		& > ${CardPhoto} {
			z-index: 2;
		}
		& > ${CardPhotoSmall} {
			z-index: 1;
			margin-left: -15px;

			transition: 0.25s;
		}

		margin-right: 8px;
	}

	.info {
		display: flex;
		flex-direction: column;
		flex: auto;
		transition: 0.5s;

		&__name {
			font-size: 14px;
			line-height: 19px;
		}

		&__data {
			font-size: 12px;
			line-height: 17px;
		}

		& > div {
			position: relative;
			flex: auto;

			&:before {
				content: 'w';
				opacity: 0;
			}
			& > div {
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;

				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
	}
`;

export const ExpansionCard = styled.div`
	--expanded-height: 1000px;
	--expansion-transition-time: 0.5s;
	--base-height: 64px;

	max-height: var(--base-height);
	padding: 12px 0;
	justify-content: flex-start;
	background-color: #fff;
	flex-direction: column;
	border-radius: 4px;
	position: relative;
	display: flex;
	margin: 10px 0;

	transition: var(--expansion-transition-time) cubic-bezier(0.1, 0.98, 0.33, 1.02);
	box-shadow: 0px 0px 4px rgba(103, 116, 150, 0.246971);

	overflow: hidden;
	box-sizing: border-box;

	& > ${CardRow}:not(:first-child) {
		opacity: 0;
		transition: 1s cubic-bezier(0.1, 0.98, 0.33, 1.02);
		transform: translate3d(0, 20px, 0);
	}

	&:before {
		content: '';
		position: absolute;
		pointer-events: none;
		height: var(--base-height);
		border-radius: 4px;
		top: 0;
		left: 0;
		right: 0;

		transition: 0.25s;
	}

	&:not([data-expanded]):hover:before {
		background-color: rgba(0, 190, 255, 0.14);
	}

	.dropdown-indicator {
		transition: var(--expansion-transition-time);
	}

	&[data-expanded] {
		max-height: var(--expanded-height);

		${CardHeader}:first-child {
			.photos > ${CardPhotoSmall} {
				transform: translate3d(-20px, 0, 0);
			}

			.info {
				transform: translate3d(-17px, 0, 0);
			}
		}

		.dropdown-indicator {
			transform: rotate(180deg);
		}

		& > ${CardRow} {
			opacity: 1;
			transform: none;
			${new Array(20)
				.fill(1)
				.map((e, i) => {
					return `&:nth-child(${i + 1}) {
                        transition-delay: ${0.07 * i}s;
                    }`;
				})
				.join('\n')}
		}
	}
`;

export const PhotoCheckBox = styled.button`
	--photo-size: 40px;

	margin-right: calc(-1 * var(--photo-size));
	margin-bottom: calc(-1 * var(--photo-size));
	padding: 0;
	width: var(--photo-size);
	height: var(--photo-size);

	z-index: 3;
	position: relative;
	transition: 0.25s;
	vertical-align: top;
	user-select: none;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 100%;
		background-image: url(${ICON_CHECKED});
		background-color: rgba(0 0 0 / 0.7);
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 32px 32px;

		transform: scale(0.8);
		opacity: 0;
		transition: 0.3s ease-out;
	}

	&:hover:before {
		transform: scale(1);
		transition: 0.25s cubic-bezier(0.13, 1.17, 0, 1.41);
		opacity: 1;
	}

	&:active {
		transform: scale(0.8);
		transition: 0s;
	}

	&[data-checked]:before {
		transform: scale(1);
		background-color: rgba(100, 200, 235, 0.7);
		opacity: 1;
		transition: 0.25s cubic-bezier(0.13, 1.17, 0, 1.41);
	}
`;

export const CardStatus = styled.div<any>`
	position: absolute;
	width: 18px;
	height: 14px;
	top: 0;
	right: 0;
	overflow: hidden;
	border-bottom-left-radius: 10px;
	&:after,
	&:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: url(${(props) => props.value});
		background-size: 32px;
		background-position: center center;
	}
	&:before {
		background-size: 1000px;
		background-position: 60% 60%;
	}
`;

export const Options = styled.table.attrs({
	width: '100%',
	cellspacing: '0',
	cellpadding: '0',
	border: '0',
})`
	font-family: inherit;
	font-size: 16px;
	line-height: 22px;
	color: #6c6f88;
	margin-bottom: 20px;

	td {
		vertical-align: top;
	}
	td:nth-child(2) {
		width: 40px;
		text-align: right;
	}
`;

export const Conditions = styled.div`
	margin: 0 -12px;
	padding: 0 12px;
	padding-top: 12px;
	h1 {
		font-size: 14px;
		line-height: 19px;
		display: flex;
		align-items: center;
		color: #000000;
		margin-bottom: 8px;
		letter-spacing: 0;
	}
	${Options} {
		font-size: 12px;
		line-height: 17px;
		color: #6c6f88;
		opacity: 0.7;
		margin-bottom: 0;
		td {
			width: 50%;
			padding: 0;
		}
		button {
			margin-top: 19px;
			width: 99%;
		}
	}
`;
