import React from 'react';
import styled from 'styled-components';
import { Icon } from 'app/components/Icons';
import { Typography } from 'app/sharedComponents/Typography';

const Container = styled.div`
	display: flex;
	overflow-wrap: break-word;
	white-space: break-spaces;
	align-items: center;
`;

const TypographyContainer = styled.div`
	overflow-wrap: break-word;
	white-space: break-spaces !important;
	/* width: 400px !important; */
`;

const IconContainer = styled.div`
	margin-right: 5px;
`;

export const NoAddressHeader = () => {
	return (
		<Container>
			<IconContainer>
				<Icon variant='info' color='#64C8EB' />
			</IconContainer>
			<TypographyContainer>
				<Typography variant='body5' color='#64C8EB'>
					Укажите ваш адрес погрузки чтобы рассчитать цену FCA
				</Typography>
			</TypographyContainer>
		</Container>
	);
};
