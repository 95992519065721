import React from 'react';
import styled from 'styled-components';
import { DocumentType } from './types';
import { Icon } from 'app/components/Icons';
import { Typography } from 'app/sharedComponents/Typography';
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
import { saveAs } from 'file-saver';
// import * as api from 'app/api';

const Balloon = styled.div`
	background-color: rgba(95, 200, 237, 0.1);
	display: flex;
	padding: 18px;
	border-radius: 18px;
	font-size: 14px;
	font-family: UniNeue;
	flex-direction: column;
	align-items: flex-start;
`;

const IconContainer = styled.div`
	/* position: absolute; */
	width: 40px;
	height: 40px;
	border-radius: 40px;
	display: flex;
	justify-content: center;
	align-items: flex-end;
	background: linear-gradient(0deg, #f8f8f8, #f8f8f8);
	border: 2px solid #ffffff;
`;

const NonDigitalIconContainer = styled.div`
	width: 32px;
	height: 32px;
	display: flex;
	justify-content: center;
	margin-right: 8px;
	margin-bottom: 12px;

	background-color: #eff9fd;

	border-radius: 4px;
`;

const ItemRowLeft = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
`;

const ItemRow = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const ItemText = styled.div`
	font-size: 16px;
	font-family: UniNeue;
	color: #d3d4dc;
`;

const ButtonContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 30px;
`;

export const DigitalSignModal = ({ docs = [] }: { docs: DocumentType[] }) => {
	// useEffect(() => {
	//     const interval = setInterval(async () => {
	//         const resp = await api.v1.get('/documents', {
	//             params: {
	//                 uuid: docs?.[0].uuid,
	//             },
	//         });
	//         console.log('------', resp);
	//     }, 1000);
	//     return () => {
	//         clearInterval(interval);
	//     };
	// }, []);
	const handleChatOpen = () => {
		//@ts-ignore
		window.jivo_api.open();
	};
	return (
		<div>
			<Balloon>
				<p>Возникли проблемы с подписанием документов?</p>
				<Typography variant='link1' onClick={handleChatOpen}>
					Обратиться в поддержку
				</Typography>
			</Balloon>
			<div>
				{docs.map((doc) => (
					<ItemRow key={doc?.uuid}>
						<ItemRowLeft>
							<IconContainer>
								<Icon variant='document' color='#D3D4DC' />
							</IconContainer>
							<ItemText>{doc?.number}</ItemText>
						</ItemRowLeft>
					</ItemRow>
				))}
				{!docs.length && (
					<ItemRow>
						<Typography variant='body3' color='#d3d4dc'>
							Все документы отправленны на подпись
						</Typography>
					</ItemRow>
				)}
			</div>
		</div>
	);
};

export const NonDigitalSignModal = ({
	docs = [],
	getDocumentBlob,
}: {
	docs: DocumentType[];
	getDocumentBlob: Function;
}) => {
	const getDocs = () => {
		docs.forEach(async (item: DocumentType) => {
			const blob = await getDocumentBlob(item.url);
			if (blob) {
				saveAs(blob, `${item.number}.pdf`);
			}
		});
	};

	const handleChatOpen = () => {
		//@ts-ignore
		window.jivo_api.open();
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignContent: 'flex-start',
				alignItems: 'baseline',
				textAlign: 'left',
			}}
		>
			<div style={{ width: '328px', textAlign: 'left' }}>
				<Typography variant='body3' bold>
					Чтобы подписывать документы онлайн в один клик, сделайте ЭЦП.
				</Typography>
				<Typography variant='link1' onClick={handleChatOpen}>
					&nbsp;Подробнее
				</Typography>
			</div>

			<Typography variant='body3' color='#6C6F88'>
				А пока порядок действий такой:
			</Typography>
			<ItemRow>
				<ItemRowLeft>
					<NonDigitalIconContainer>
						<Icon variant='download' color='#5FC8ED' />
					</NonDigitalIconContainer>
					<Typography variant='body3'>Скачайте и распечатайте документ</Typography>
				</ItemRowLeft>
			</ItemRow>
			<ItemRow>
				<ItemRowLeft>
					<NonDigitalIconContainer>
						<Icon variant='pencil' color='#5FC8ED' />
					</NonDigitalIconContainer>
					<Typography variant='body3'>Подпишите два оригинала</Typography>
				</ItemRowLeft>
			</ItemRow>
			<ItemRow>
				<ItemRowLeft>
					<NonDigitalIconContainer>
						<Icon variant='mail' color='#5FC8ED' />
					</NonDigitalIconContainer>

					<Typography variant='body3'>Отправьте два оригинала нам по почте.</Typography>
					<Typography variant='link1' onClick={handleChatOpen}>
						&nbsp;Подробнее
					</Typography>
				</ItemRowLeft>
			</ItemRow>
			<ButtonContainer>
				<Button styleType={ButtonStyleTypes.PRIMARY} onClick={getDocs}>
					Скачать
				</Button>
			</ButtonContainer>
		</div>
	);
};
