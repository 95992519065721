import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { Localized } from '@fluent/react';
import PageTitle from 'app/components/PageTitle';

const Container = styled.div`
	height: 100%;
	width: 100%;
	top: -55px;
	position: relative;
	z-index: 0;
`;

const Admin = () => {
	const token = useSelector((state: any) => state?.user?.sessionToken);
	const url = `/admin/iframe/?token=${token}`;
	return (
		<div className='admin page'>
			<PageTitle titleKey='title-admin' />
			<div className='header widget' style={{ zIndex: 1 }}>
				<div className='container'>
					<div className='title'>
						<Localized id='admin-page-header' />
					</div>
				</div>
			</div>
			<Container>
				<iframe width='100%' height='100%' src={url} />
			</Container>
		</div>
	);
};

export default Admin;
