import React, { useState } from 'react';

//components
import * as Icons from 'app/components/Icons';
import ConfirmModal from 'app/components/ConfirmModal';
import ImageModal from 'app/components/ImageModal';

//types
export interface CertificateProps {
	editable: Boolean | undefined;
	document: any;
	onDelete: Function;
	uuid: string;
}

//----------------------------------------------------------
// Certificate
//----------------------------------------------------------
const Certificate = ({ editable, document, onDelete, uuid }: CertificateProps) => {
	//state
	const [requestedDelete, setRequestedDelete] = useState(false);

	//callbacks
	const handleDelete = () => {
		if (requestedDelete) {
			onDelete(uuid);
			setRequestedDelete(false);
		} else {
			setRequestedDelete(true);
		}
	};

	//renders
	return (
		<div key={`certificate-${uuid}`} className='item'>
			{editable && (
				<div className='action remove' onClick={handleDelete}>
					<Icons.Delete color='#f44336' />
				</div>
			)}

			<ConfirmModal
				text='Уверены что хотите удалить документ?'
				confirmBtnText='Удалить'
				isOpen={requestedDelete}
				onCancel={() => setRequestedDelete(false)}
				onConfirm={() => onDelete(uuid)}
			/>

			<ImageModal src={document} />
		</div>
	);
};

export default Certificate;
