import * as React from 'react';
import styled from 'styled-components';
import _ from 'lodash';
import { Field, SchemeProps } from '../props';
import { Localized } from '@fluent/react';
import update from 'immutability-helper';
import { RateApi, Rate as RateNameSpace } from 'app/api';
import Slider from 'rc-slider';
import Posed from 'react-pose';
import Checkbox from 'app/sharedComponents/Checkbox';
import Switch from 'app/sharedComponents/Switch';
import { Typography } from 'app/sharedComponents/Typography';

const Container = Posed.div({
	closed: {
		height: 48,

		transition: {
			ease: 'easeOut',
			duration: 200,
		},
	},

	opened: {
		height: '100%',

		transition: {
			ease: 'easeIn',
			duration: 200,
		},
	},
});

const SwitchContainer = styled.div`
	display: flex;
	flex-direction: column;
	height: 40px;
	justify-content: space-between;
`;

type RatePriceWithParams = {
	recomended: {
		farePerKm: number;
		fare: number;
	};
	range: {
		farePerKm: {
			min: number;
			max: number;
		};
		fare: {
			min: number;
			max: number;
		};
	};
};

type PricePerKmSwitchProps = {
	titleId: string;
	currencySymbol: string | undefined;
	isPricePerKm: boolean;
	onChange: Function;
};

const PricePerKmSwitch = ({ titleId, currencySymbol, isPricePerKm, onChange }: PricePerKmSwitchProps) => {
	return (
		<SwitchContainer>
			<Typography variant='body3'>
				<Localized id='builder-map-rate' />
				{` `} {currencySymbol}/<Localized id={titleId} />
			</Typography>

			<Switch value={isPricePerKm} onChange={onChange} />
		</SwitchContainer>
	);
};

const initialState = (defaultValues: SchemeProps.RateDefaultValues, baseState?: Rate.State) => {
	const state = baseState ? baseState : (_.clone(RateNameSpace.DEFAULT_STATE) as Rate.State);
	state.values = { ...state.values, ...defaultValues };

	state.values.rate = RateApi.getDefaultRate(state.distance, state.values.isPricePerKm);
	state.params = { ...state.params, ...RateApi.getRateRange(state.values.rate, state.values.isPricePerKm) };

	// Pre-calculate total price
	if (state.weight) {
		state.values.totalPrice = RateApi.getTotalPrice(
			state.values.rate,
			state.values.isPricePerKm,
			state.distance,
			state.weight,
		);
	} else {
		state.values.totalPrice = 0;
	}

	state.sliderRate = state.values.rate;
	return state;
};

export class Rate extends React.Component<Rate.Props, Rate.State> {
	public state: Rate.State;

	constructor(props: Rate.Props) {
		super(props);

		// Methods bind
		this.onAfterChangeSliderRate = this.onAfterChangeSliderRate.bind(this);
		this.onChangeSliderRate = this.onChangeSliderRate.bind(this);
		this.onChangeOrderType = this.onChangeOrderType.bind(this);
		this.onChangeInputRate = this.onChangeInputRate.bind(this);
		this.onChangeOption = this.onChangeOption.bind(this);
		this.getOptions = this.getOptions.bind(this);

		// Calculator bindings
		this.updateTotalPrice = this.updateTotalPrice.bind(this);
		this.getRateTypes = this.getRateTypes.bind(this);
		this.updateRate = this.updateRate.bind(this);

		// Public methods for update local state
		this.updateRatePrice = this.updateRatePrice.bind(this);
		this.updateDistance = this.updateDistance.bind(this);
		this.updateWeight = this.updateWeight.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);
		this.handlePricePerKm = this.handlePricePerKm.bind(this);

		// Pre-calculate
		this.state = initialState(props.field.defaultValues);

		// Update default form
		Object.keys(props.field.defaultValues).forEach((key) => {
			const value = (props.field.defaultValues as any)[key];

			if (key === 'type') {
				props.onUpdate('orderType', value);
			} else {
				props.onUpdate(key, value);
			}
		});
	}

	public updateValue() {
		// @unimplemented
	}

	public async reset() {
		const {
			props: { field, onUpdate },
		} = this;

		const state = initialState(this.props.field.defaultValues);

		await this.setState(state);

		Object.keys(field.defaultValues).forEach((key) => {
			const value = (this.state.values as any)[key];

			if (key === 'type') {
				onUpdate('orderType', value);
			} else {
				onUpdate(key, value);
			}
		});

		await this.forceUpdate();
	}

	public async updateRatePrice(price: number) {
		const {
			props: { onUpdate },
		} = this;

		const { state } = this;
		const { values } = state;

		const range = RateApi.getRateRange(price, values.isPricePerKm);

		await this.setState(
			update(state, {
				sliderRate: {
					$set: price,
				},

				values: {
					rate: { $set: price },
					isRecommendedPrice: { $set: false },
				},

				params: {
					min: { $set: range.min },
					max: { $set: range.max },
				},
			}),
		);

		await this.updateTotalPrice();

		onUpdate('rate', price);
	}

	public async updateRatePriceWithParams(params: RatePriceWithParams) {
		const {
			state,
			props: { onUpdate },
		} = this;

		const { values } = state;

		const isPricePerKm = values.isPricePerKm;
		const fareKey = isPricePerKm ? 'farePerKm' : 'fare';

		const recommendedPrice = params.recomended[fareKey];
		const min = params.range[fareKey].min;
		const max = params.range[fareKey].max;

		const type: RateNameSpace.ByType = isPricePerKm ? RateNameSpace.ByType.WT_KM : RateNameSpace.ByType.WT;

		await this.setState(
			update(state, {
				sliderRate: { $set: recommendedPrice },
				values: {
					rate: { $set: recommendedPrice },
					type: { $set: type },
					isRecommendedPrice: { $set: true },
				},
				params: {
					min: { $set: min },
					max: { $set: max },
				},
			}),
		);

		await this.updateTotalPrice();

		onUpdate('rate', recommendedPrice);
	}

	public async updateWeight(weight: number) {
		await this.setState({ weight });

		this.updateRate();
		this.updateTotalPrice();
	}

	public async updateDistance(distance: number) {
		await this.setState({ distance });

		this.updateRate();
		this.updateTotalPrice();
	}

	private async updateTotalPrice() {
		const { state } = this;
		const { values } = state;

		if (state.weight && state.distance) {
			const totalPrice = RateApi.getTotalPrice(
				values.rate,
				values.isPricePerKm,

				state.distance,
				state.weight,
			);

			this.setState(
				update(state, {
					values: {
						totalPrice: { $set: totalPrice },
					},
				}),
			);
		} else {
			this.setState(
				update(state, {
					values: {
						totalPrice: { $set: 0 },
					},
				}),
			);
		}
	}

	private async updateRate() {
		const {
			state,
			props: { onUpdate },
		} = this;
		const { values } = state;

		if (state.distance) {
			const rate = values.rate ? values.rate : RateApi.getDefaultRate(state.distance, values.isPricePerKm);
			const range = RateApi.getRateRange(rate, values.isPricePerKm);

			await this.setState(
				update(state, {
					sliderRate: { $set: rate },
					values: {
						rate: { $set: rate },
					},
					params: {
						min: { $set: range.min },
						max: { $set: range.max },
					},
				}),
			);

			onUpdate('rate', rate);
		}
	}

	public async onChangeSliderRate(rate: number) {
		const { state } = this;

		await this.setState(
			update(state, {
				values: { isRecommendedPrice: { $set: false } },
				sliderRate: { $set: rate },
			}),
		);
	}

	public async onSetRate(rate: number, type: any) {
		const { state } = this;
		const range = RateApi.getRateRange(rate, type);

		await this.setState(
			update(state, {
				sliderRate: { $set: rate },
				values: {
					rate: { $set: rate },
					type: { $set: type as RateNameSpace.ByType },
					isRecommendedPrice: { $set: false },
				},
				params: {
					min: { $set: range.min },
					max: { $set: range.max },
				},
			}),
		);
	}

	private async onAfterChangeSliderRate(rate: number) {
		const {
			props: { onUpdate },
		} = this;
		const { state } = this;

		await this.setState(
			update(state, {
				sliderRate: { $set: rate },
				values: {
					rate: { $set: rate },
					isRecommendedPrice: { $set: false },
				},
			}),
		);

		onUpdate('rate', rate);
		await this.updateTotalPrice();
	}

	private async onChangeInputRate(e: React.ChangeEvent<HTMLInputElement>) {
		const {
			props: { onUpdate },
		} = this;

		const floatValue = parseFloat(e.target.value);
		const nextValue = floatValue ? floatValue : 0;
		e.target.value = nextValue.toString();

		const { state } = this;
		const { values } = state;

		const range = RateApi.getRateRange(nextValue, values.isPricePerKm);

		await this.setState(
			update(state, {
				sliderRate: { $set: nextValue },
				values: {
					rate: { $set: nextValue },
					isRecommendedPrice: { $set: false },
				},
				params: {
					min: { $set: range.min },
					max: { $set: range.max },
				},
			}),
		);

		await this.updateTotalPrice();
		onUpdate('rate', nextValue?.toFixed(2)?.replace('.00', ''));
	}

	private async onChangeOrderType(e: React.ChangeEvent<HTMLInputElement>) {
		const { onUpdate } = this.props;
		const { value } = e.target;
		const { state } = this;

		if (value) {
			await this.setState(
				update(state, {
					values: {
						type: { $set: value as RateNameSpace.ByType },
						isRecommendedPrice: { $set: false },
					},
				}),
			);

			onUpdate('orderType', value);
			await this.updateRate();
			await this.updateTotalPrice();
		}
	}

	private onChangeOption(e: any) {
		const { checked, name } = e.target;
		const { onUpdate } = this.props;
		const { state } = this;

		this.setState(
			update(state, {
				values: {
					[name]: { $set: checked },
				},
			}),
		);

		onUpdate(name, checked);
	}

	private getOptions() {
		const { onChangeOption } = this;
		const { values } = this.state;
		const checkboxList = [
			{ text: 'builder-map-is-prepaid', name: 'isPrepaid' },
			{ text: 'builder-map-is-overload', name: 'isOverload' },
			{ text: 'builder-map-is-cash', name: 'isCash' },
			{ text: 'builder-map-is-bargain', name: 'isBargain' },
			{ text: 'builder-map-has-vat', name: 'hasVat' },
		];

		return checkboxList.map(({ text, name }, index) => {
			const checked = (values as any)[name] as boolean;
			const key = `${name}-${index}`;
			return (
				<Checkbox key={key} name={name} checked={checked} onChange={onChangeOption}>
					<Localized id={text} />
				</Checkbox>
			);
		});
	}

	private getRateTypes(currentType: RateNameSpace.ByType) {
		const { currencySymbol } = this.state.values;
		const { onChangeOrderType } = this;

		return [RateNameSpace.ByType.WT, RateNameSpace.ByType.WT_KM].map((type, index) => {
			return (
				<div key={`${type}-${index}`} className='radio-button'>
					<input
						checked={type === currentType}
						onChange={onChangeOrderType}
						value={type}
						type='radio'
						name='type'
						id={type}
					/>
					<label htmlFor={type}>
						{currencySymbol}/{type}
					</label>
				</div>
			);
		});
	}

	private async handlePricePerKm(value: any) {
		const { values } = this.state;

		await this.setState({
			values: {
				...values,
				isPricePerKm: value,
			},
		});

		// await this.updateRate();
		await this.updateTotalPrice();

		this.props.onUpdate('isPricePerKm', value);

		// if (state.distance) {
		// 	const isPricePerKm = value;

		// 	const newRate = isPricePerKm
		// 		? Number(Number(values.rate / state.distance).toFixed(2))
		// 		: Number(Number(values.rate * state.distance).toFixed());

		// 	this.onSetRate(newRate, isPricePerKm);

		// 	onUpdate('rate', newRate);
		// }
	}

	public render() {
		const { onAfterChangeSliderRate, onChangeSliderRate, onChangeInputRate } = this;
		const {
			//@ts-ignore
			values: { currencySymbol, totalPrice, hasVat, type, rate, hideSetupBlock, isPricePerKm, isRecommendedPrice },
			params: { min, max },
			sliderRate,
			distance,
			weight,
			opened,
		} = this.state;

		const rateInputStep = this.state.values.type === RateNameSpace.ByType.WT ? '10.0' : '0.1';
		const rateStep = this.state.values.type === RateNameSpace.ByType.WT ? 10 : 0.1;
		const middleValue = (max + min) / 2;
		const fixedRate = rate?.toFixed(2)?.replace('.00', '');

		const sliderProps = {
			onAfterChange: onAfterChangeSliderRate,
			onChange: onChangeSliderRate,
			value: sliderRate,
			step: rateStep,
			tabIndex: -1,
			min,
			max,
			marks: {
				[`${middleValue}`]: `${Math.round(middleValue * 10) / 10} ₽`,
				[`${min}`]: `${Math.round(min * 10) / 10} ₽`,
				[`${max}`]: `${Math.round(max * 10) / 10} ₽`,
			},
		};

		const rateActive = distance && weight;

		const currentPose = opened && rateActive ? 'opened' : 'closed';
		const activeExtendClass = !rateActive ? 'disabled' : '';

		const onClickContainer = () => {
			if (rateActive) {
				this.setState({ opened: !opened });
			}
		};

		const onRateFocused = () => {
			this.setState({ opened: true });
		};

		return (
			<div className='form-item order-rate'>
				<Container withParent={false} pose={currentPose}>
					<div className={'current ' + activeExtendClass} onClick={onClickContainer}>
						<div className='label'>
							{isPricePerKm ? <Localized id='builder-map-rate-per-km' /> : <Localized id='builder-map-rate' />}
						</div>

						<span className='value'>
							{fixedRate} {currencySymbol}/{type}
						</span>

						{hasVat ? null : (
							<span className='no-vat'>
								<Localized id='builder-map-no-vat' />
							</span>
						)}

						{isRecommendedPrice && (
							<span className='is-recommended'>
								<Localized id='builder-map-is-recommended' />
							</span>
						)}
					</div>

					{!distance || !weight ? null : (
						<div className='inner'>
							<div className='slider'>
								<Slider {...sliderProps} />
							</div>

							<div className='input rounded valid'>
								<input
									onChange={onChangeInputRate}
									onFocus={onRateFocused}
									step={rateInputStep}
									type='number'
									value={fixedRate}
									required
									min='0'
								/>

								<label>
									{isPricePerKm ? <Localized id='builder-map-rate-per-km' /> : <Localized id='builder-map-rate' />}
								</label>
							</div>

							<hr />

							{/* {!hideSetupBlock && <div className="type">{this.getRateTypes(type)}</div>} */}
							{!hideSetupBlock && (
								<PricePerKmSwitch
									titleId={isPricePerKm ? 'builder-map-rate-per-km-switch' : 'builder-map-rate-per-weight-switch'}
									currencySymbol={currencySymbol}
									isPricePerKm={isPricePerKm}
									onChange={this.handlePricePerKm}
								/>
							)}
							<hr />

							{!hideSetupBlock && <div className='options'>{this.getOptions()}</div>}

							<div className='total'>
								<div className='label'>
									<Localized id='builder-map-total-price' />
								</div>
								<div className='price'>
									{(parseInt as any)(totalPrice?.toFixed(0)).toLocaleString('ru')} {currencySymbol}
								</div>
							</div>
						</div>
					)}
				</Container>
			</div>
		);
	}
}

export namespace Rate {
	export interface Props extends Field.ComponentProps<any, SchemeProps.Rate> {
		// @default
	}

	export interface State {
		values: RateNameSpace.Values;
		params: RateNameSpace.Params;

		sliderRate: number;
		distance: number;
		weight: number;

		opened: boolean;
	}
}
