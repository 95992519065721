import { BasicTypes, Validate } from 'app/containers/Builder/props';
import { regex, lessThan, moreThan, isDateLaterThanToday, isDateEarlierThanToday } from 'app/utils/form-rules';

export const TRANSPORT_IDENT_REGEX = /^[авекмнорстухabekmhopctyx]\d\d\d[авекмнорстухabekmhopctyx]{2}(RUS)?[\d]{2,3}$/i;
export const TRAILER_IDENT_REGEX = /^[авекмнорстухabekmhopctyx]{2}\d\d\d\d(RUS)?[\d]{2,3}$/i;
const VIN_CODE_REGEX = /[0-9ABCDEFGHJKLMNPRSTUVWXYZ]{13}[0-9]{4}/i;
const STS_REGEX = /^[\d]{2}[\wa-я\d]{2}[\d]{6}$/i;
const INSURANCE_REGEX = /^[\w]{3}№?[\d]{10}$/i;
const CURRENT_YEAR = new Date().getFullYear();
const RANGE_YEARS = CURRENT_YEAR - 1970;

export const base = [
	{
		label: 'transports-car-ident',
		type: BasicTypes.Input,
		localized: true,
		mask: 's000ss`000',
		required: true,
		rounded: true,
		uppercase: true,
		name: 'ident',
		rules: [regex(TRANSPORT_IDENT_REGEX, 'transports-validation-ident-invalid')],
	},
	{
		label: 'transports-car-trailer-ident',
		type: BasicTypes.Input,
		localized: true,
		name: 'trailerIdent',
		mask: 'ss0000000',
		required: false,
		rounded: true,
		uppercase: true,
		rules: [regex(TRAILER_IDENT_REGEX, 'transports-validation-trailer-ident-invalid')],
	},
];

export const certificate = [
	{
		// choices: [ /* lazy */ ],
		label: 'transports-certificate-kind',
		disableLocalizeForOption: true,
		type: BasicTypes.Select,
		localized: true,
		required: true,
		rounded: true,
		name: 'kind',
	},
	{
		// choices: [ /* lazy */ ],
		label: 'transports-certificate-brand',
		disableLocalizeForOption: true,
		type: BasicTypes.Select,
		localized: true,
		required: false,
		rounded: true,
		name: 'brand',
	},
	{
		label: 'transports-certificate-model',
		type: BasicTypes.Input,
		localized: true,
		required: false,
		rounded: true,
		name: 'model',
	},
	{
		label: 'transports-certificate-color',
		type: BasicTypes.Input,
		localized: true,
		required: false,
		rounded: true,
		name: 'color',
	},
	{
		choices: [...Array(RANGE_YEARS + 1).keys()]
			.map((i) => CURRENT_YEAR - RANGE_YEARS + i)
			.map((i) => ({ label: i, value: i })) as any,
		label: 'transports-certificate-manufacture-year',
		disableLocalizeForOption: true,
		type: BasicTypes.Select,
		name: 'manufactureYear',
		localized: true,
		required: false,
		rounded: true,
	},
	{
		mask: 'vvvvvvvvvvvvv0000',
		type: BasicTypes.Input,
		required: false,
		rounded: true,
		uppercase: true,
		label: 'VIN',
		name: 'vin',
		rules: [regex(VIN_CODE_REGEX, 'certificate-validation-vin-invalid')],
	},
	{
		type: BasicTypes.Input,
		mask: '00ll000000',
		label: 'transports-certificate-number',
		name: 'number',
		localized: true,
		required: false,
		rounded: true,
		uppercase: true,
		rules: [regex(STS_REGEX, 'certificate-validation-sts-invalid')],
	},
	{
		label: 'transports-certificate-issued-at',
		defaultValue: new Date(),
		localized: true,
		type: BasicTypes.Date,
		rounded: true,
		name: 'issuedAt',
		rules: [isDateEarlierThanToday('certificate-validation-issued-invalid')],
	},
	{
		label: 'transports-certificate-owner-page',
		type: BasicTypes.ImagePicker,
		localized: true,
		name: 'ownerPage',
		required: false,
	},
	{
		label: 'transports-certificate-main-page',
		type: BasicTypes.ImagePicker,
		localized: true,
		name: 'mainPage',
		required: false,
	},
];

export const insurance = [
	{
		label: 'transports-insurance-number',
		type: BasicTypes.Input,
		mask: 'www№0000000000',
		localized: true,
		name: 'number',
		required: false,
		rounded: true,
		uppercase: true,
		rules: [regex(INSURANCE_REGEX, 'insurance-validation-number-invalid')],
	},
	{
		type: BasicTypes.Date,
		label: 'transports-insurance-issued-at',
		localized: true,
		name: 'issuedAt',
		rounded: true,
		rules: [isDateEarlierThanToday('insurance-validation-issued-invalid')],
	},
	{
		label: 'transports-insurance-expired-at',
		type: BasicTypes.Date,
		localized: true,
		name: 'expiredAt',
		rounded: true,
		rules: [isDateLaterThanToday('insurance-validation-expired-invalid')],
	},
	{
		label: 'transports-insurance-main-page',
		type: BasicTypes.ImagePicker,
		name: 'mainPage',
		localized: true,
		required: true,
	},
];

export const dimensions = [
	{
		validation: Validate.Number,
		label: 'transports-car-height',
		type: BasicTypes.Input,
		localized: true,
		rounded: true,
		name: 'height',
		step: '0.1',
		max: 5,
		rules: [
			moreThan(0, 'transports-validation-height-less-than'),
			lessThan(5, 'transports-validation-height-more-than'),
		],
	},
	{
		label: 'transports-car-length',
		validation: Validate.Number,
		type: BasicTypes.Input,
		localized: true,
		rounded: true,
		name: 'length',
		step: '1',
		max: 100,
		rules: [moreThan(0, 'transports-validation-length-less-than')],
	},
	{
		label: 'transports-car-gross',
		validation: Validate.Number,
		type: BasicTypes.Input,
		localized: true,
		rounded: true,
		name: 'gross',
		step: '1',
		max: 100,
		rules: [
			moreThan(0, 'transports-validation-gross-less-than'),
			lessThan(100, 'transports-validation-gross-more-than'),
		],
	},
];
