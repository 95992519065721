import React from 'react';
import styled from 'styled-components';
import { BidCard } from './BidCard';
import Scrollbars from 'react-custom-scrollbars';
import { PaddingContainer } from './StyledCandidatesComponents';
import LoadingIndicator from 'app/sharedComponents/LoadingIndicator';
import { Typography } from 'app/sharedComponents/Typography';

export type BidListProps = {
	bidoffers: any[];
	loading?: boolean;
	onLoadMore?: () => void;
	onSelectBid: (bidId: string) => void;
	selectedBid?: string | null;
	selectedBids?: string[];
	handleBidHover?: (bidId: string, value: boolean) => void;
};

const checkIfSelected = (bidId: string, selectedBids: string[]) => {
	return selectedBids.some((id: any) => id === bidId);
};

const LoaderWrapper = styled.div`
	width: 100;
	padding: 50px;
	display: flex;
	justify-content: center;
`;

const NoDataCard = styled.div`
	border-radius: 4px;
	box-shadow: 0px 0px 4px rgba(103, 116, 150, 0.246971);
	background: #fff;
	display: flex;
	align-items: center;
	text-align: center;
	padding: 10px 13px;
	margin-bottom: 10px;
`;

export const BidList: React.FC<BidListProps> = ({
	bidoffers,
	onLoadMore,
	onSelectBid = () => {},
	selectedBid,
	selectedBids = [],
	handleBidHover,
	loading,
}: BidListProps) => {
	const handleScroll: any = (event: any) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		const pixelsToBottom = scrollHeight - clientHeight - scrollTop;
		if (pixelsToBottom < 50) onLoadMore?.();
	};
	const handleBidSelect = (bidId: string) => () => onSelectBid(bidId);
	const handleMouseEnter = (bidId: string) => () => {
		handleBidHover && handleBidHover(bidId, true);
	};

	const handleMouseLeave = (bidId: string) => () => {
		handleBidHover && handleBidHover(bidId, false);
	};

	return (
		<Scrollbars onScroll={handleScroll}>
			<PaddingContainer>
				{bidoffers.map(({ bid }) => (
					<BidCard
						key={bid.id}
						bid={bid}
						selected={bid.id === selectedBid || checkIfSelected(bid.id, selectedBids)}
						onClick={handleBidSelect(bid.id)}
						onMouseEnter={handleMouseEnter(bid.id)}
						onMouseLeave={handleMouseLeave(bid.id)}
					/>
				))}
				{loading && (
					<LoaderWrapper>
						<LoadingIndicator />
					</LoaderWrapper>
				)}
				{!bidoffers.length && !loading && (
					<NoDataCard>
						<Typography variant='body3'>Заявки не найдены</Typography>
					</NoDataCard>
				)}
			</PaddingContainer>
		</Scrollbars>
	);
};
