import React from 'react';
import styled from 'styled-components';
import { Typography } from 'app/sharedComponents/Typography';

const Container = styled.div`
	display: flex;
	align-items: center;
`;

const ICON_AVATAR_DRIVER = require('../../../../assets/icons/avatar_driver.jpg');

export const BuyerCell = ({ buyerName, logo }: any) => {
	return (
		<Container>
			<img src={logo || ICON_AVATAR_DRIVER} width='40' height='40' style={{ marginRight: '9px' }} />
			<Typography variant='body3'>{buyerName}</Typography>
		</Container>
	);
};
