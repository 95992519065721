import React from 'react';
import styled from 'styled-components';
import {
	ExpansionCard,
	// CardRow,
	CardHeader,
	CardPhoto,
	CardPhotoSmall,
	CardPhotoVerified,
	CardStatus,
	// Conditions,
	PhotoCheckBox,
	// Options,
} from '../components/Common';

const ICON_DROPDOWN = require('src/assets/icons/i-arrow-drop.png');
const ICON_REPLIES = require('assets/icons/i-reply.png');
const ICON_APPROVES = require('assets/icons/i-approve.png');
const ICON_VERIFIED = require('assets/icons/svg/ic_verification_small.svg');

const STATUS_ICONS: { [key: string]: string } = {
	free: ICON_REPLIES,
	busy: ICON_APPROVES,
};

const TransportCardContainer = styled(ExpansionCard)<any>`
	--expanded-height: 400px;
	background-color: ${(props) => (props.selected ? '#D6F3FD' : 'none')};
`;

export const TransportCard = ({
	checked,
	selected,
	title,
	text,
	primaryPhoto,
	secondaryPhoto,
	isVerified,
	status,
	onClick,
	onMouseEnter,
	onMouseLeave,
	onSelect,
}: any) => {
	const handleSelect = (e: any) => {
		e.stopPropagation();
		onSelect();
	};

	return (
		<TransportCardContainer selected={selected} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
			<CardHeader>
				<div className='photos'>
					<PhotoCheckBox onClick={handleSelect} data-checked={checked || undefined} />
					<CardPhoto src={primaryPhoto} />
					{isVerified ? <CardPhotoVerified src={ICON_VERIFIED} /> : null}
					<CardPhotoSmall src={secondaryPhoto} />
				</div>

				<button onClick={onClick} className='actions__dropdown-button'>
					<div className='info'>
						<div className='info__name'>
							<div>{title}</div>
						</div>

						<div className='info__data'>
							<div>{text}</div>
						</div>
					</div>

					<div className='actions'>
						<img className='dropdown-indicator' src={ICON_DROPDOWN} width='24' />
					</div>
				</button>
			</CardHeader>
			<CardStatus value={STATUS_ICONS[status]} />
		</TransportCardContainer>
	);
};
