import * as React from 'react';

//other deps
import Modal from 'react-modal';
import { Localized } from '@fluent/react';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//parts
import Form from './form';
import Preview from './preview';

//components
import * as Icons from 'app/components/Icons';

//types
import { RootState } from 'app/reducers';
import { UserProfile, RoleEnum } from 'app/models';

//----------------------------------------------------------
// ProfileDocs
//----------------------------------------------------------
class ProfileDocs extends React.Component<ProfileDocs.Props, ProfileDocs.State> {
	public state: ProfileDocs.State = {
		modalOpened: false,
		editable: true,
		loading: true,
	};

	constructor(props: ProfileDocs.Props) {
		super(props);

		this.openSendedModal = this.openSendedModal.bind(this);
		this.toggleEditable = this.toggleEditable.bind(this);
		this.modal = this.modal.bind(this);
	}

	public async componentDidMount() {
		await Promise.delay(200);

		const { profile } = this.props;

		if (profile && profile.driverDocs && profile.role === RoleEnum.Driver) {
			const { passport, driverLicence, bankAccount } = profile.driverDocs;

			const baseValid =
				passport.issuedAt &&
				passport.issuedAt.length > 0 &&
				passport.number &&
				passport.number.length > 0 &&
				passport.issuer &&
				passport.issuer.length > 0 &&
				!!passport.registrationPage &&
				!!passport.mainPage &&
				driverLicence.expiredAt &&
				driverLicence.expiredAt.length > 0 &&
				driverLicence.issuedAt &&
				driverLicence.issuedAt.length > 0 &&
				driverLicence.number &&
				driverLicence.number.length > 0 &&
				!!driverLicence.mainPage &&
				!!driverLicence.backPage &&
				bankAccount &&
				bankAccount.bic &&
				bankAccount.account &&
				bankAccount.account.length === 20 &&
				bankAccount.bic.length === 9 &&
				bankAccount.bic.substr(0, 2) === '04';

			this.setState({
				editable: !baseValid,
				loading: false,
			});
		}
	}

	public toggleEditable(editable: boolean) {
		this.setState({
			editable,
		});
	}

	public openSendedModal() {
		this.setState({
			modalOpened: true,
		});
	}

	public modal() {
		const { modalOpened } = this.state;

		const close = () => this.setState({ modalOpened: false });

		const modalProps = {
			className: 'modal profile-sended',
			onRequestClose: close,
			isOpen: modalOpened,
		};

		return (
			<Modal {...modalProps}>
				<div className='modal-overlay' onClick={close} />

				<div className='content'>
					<Icons.MessagesBig />

					<div className='message'>
						<Localized id='profile-docs-sended-modal' />
					</div>

					<div className='action' onClick={close}>
						<Localized id='profile-action-show' />
					</div>
				</div>
			</Modal>
		);
	}

	public render() {
		const { toggleEditable, openSendedModal } = this;
		const { loading, editable } = this.state;
		const { profile } = this.props;

		if (!profile || loading) {
			return null;
		} else {
			const props = {
				key: `docs-profile-${editable.toString()}`,
				openSendedModal,
				toggleEditable,
			};

			return (
				<>
					{this.modal()}

					{editable ? <Form {...props} /> : <Preview {...props} />}
				</>
			);
		}
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	profile: user.profile,
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

export default connect<ProfileDocs.StateProps, ProfileDocs.DispatchProps, ProfileDocs.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(ProfileDocs);

namespace ProfileDocs {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		profile?: UserProfile;
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		modalOpened: boolean;
		editable: boolean;
		loading: boolean;
	}
}
