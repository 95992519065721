import React, { useRef, useState, useEffect, useMemo } from 'react';

//other deps
import useSupercluster from 'use-supercluster';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';

//components
import MapType from './MapType';
import SyncMap from './SyncMap';
import { Marker } from './Marker';
import { Cluster } from './Cluster';
import { ZoomButtons } from './ZoomButtons';

//types
import { BBox } from '@turf/turf';
import { GMapProps, CurrentRoute, Route, Coordinate } from './types';

//images
const RouteFromIcon = require('assets/icons/start.png');
const RouteToIcon = require('assets/icons/finish.png');

//constants
import { DEFAULT_CENTER, DEFAULT_ZOOM, GOOGLE_API_KEY, MAP_OPTIONS } from './GMap.constants';

//helpers
import { getIdsFromClusters, mapClusterChildrenStatuses, mapMarkers } from './GMap.helpers';

//----------------------------------------------------------
// GMap
//----------------------------------------------------------
export const GMap = ({
	defaultCenter = DEFAULT_CENTER,
	defaultZoom = DEFAULT_ZOOM,
	markers = [],
	onMarkerClick = () => {},
	onMarkerDblClick = () => {},
	hintComponent,
	clusterHintComponent,
	highlightedMarkers = {},
	selectedMarkers = {},
	routeToShow,
	onClusterSelect = () => {},
	onClusterDblClick = () => {},
	onMarkerSelect = () => {},
	// handleMarkersRender,
	zoomToPoint,
	hideMapType,
	showMapMoveSync,
	showZoomButtons,
	// onBoundsSet,
	// prevBounds,
	onMapReady,
}: GMapProps) => {
	const mapRef = useRef(null);
	const mapsRef = useRef(null);
	const directionsRenderer = useRef(null);
	const directionsService = useRef(null);
	const distanceMatrixService = useRef(null);
	const currentRouteRef = useRef<CurrentRoute>(null);
	const [zoom, setZoom] = useState(defaultZoom);
	const [bounds, setBounds] = useState<BBox | undefined>(undefined);
	const [mapReady, setMapReady] = useState(false);
	const [idsOnMap, setIdsOnMap] = useState([]);
	const setCurrentRoute = (route: CurrentRoute) => (currentRouteRef.current = route);
	const getCurrentRoute = () => currentRouteRef.current;

	const points = useMemo(() => mapMarkers(markers), [markers]);

	const { clusters, supercluster } = useSupercluster({
		points,
		bounds,
		zoom,
		options: { radius: 150, maxZoom: 20 },
	});

	const handleApiLoad = (props: any) => {
		const { map, maps } = props;

		mapRef.current = map;
		mapsRef.current = maps;

		directionsRenderer.current = new maps.DirectionsRenderer();
		//@ts-ignore
		directionsRenderer.current.setMap(map);
		directionsService.current = new maps.DirectionsService();
		distanceMatrixService.current = new maps.DistanceMatrixService();

		setMapReady(true);
		onMapReady && onMapReady();
	};

	const zoomToObject = (obj: any) => {
		//@ts-ignore
		const bounds = new mapsRef.current.LatLngBounds();

		obj.getPath().forEach(function (latLng: any) {
			bounds.extend(latLng);
		});

		//@ts-ignore
		mapRef?.current?.fitBounds(bounds);
	};

	const showRoute = (route: any, matrix: any, id: string) => {
		const currentRoute = getCurrentRoute();

		if (currentRoute && currentRoute?.start && currentRoute?.end) {
			currentRoute.start.setMap();
			currentRoute.end.setMap();
			currentRoute.route.setMap();
		}

		//@ts-ignore
		const routePolyline = new mapsRef.current.Polyline({
			//@ts-ignore
			path: route.routes[0].overview_path,
			strokeColor: '#64C8EB',
			strokeOpacity: 1.0,
			strokeWeight: 5,
		});

		routePolyline.setMap(mapRef.current);
		zoomToObject(routePolyline);

		const myRoute = route.routes[0].legs[0];
		//@ts-ignore
		const startMarker = new mapsRef.current.Marker({
			position: myRoute.steps[0].start_point,
			map: mapRef.current,
			icon: RouteFromIcon,
		});
		startMarker.setMap(mapRef.current);

		//@ts-ignore
		const endMarker = new mapsRef.current.Marker({
			position: myRoute.steps[myRoute.steps.length - 1].end_point,
			map: mapRef.current,
			icon: RouteToIcon,
		});
		endMarker.setMap(mapRef.current);

		setCurrentRoute({ start: startMarker, end: endMarker, route: routePolyline, id });

		if (matrix && matrix.rows && matrix.rows[0]) {
			const { distance, duration } = matrix?.rows?.[0]?.elements?.[0] || {}; //TODO: check eslint/no-unsafe-optional-chaining

			//@ts-ignore
			const infoWIndow = new mapsRef.current.InfoWindow({
				content: `<div><p>расстояние: ${distance?.text}</p><p>продолжительность: ${duration?.text}</p></div>`,
			});

			endMarker.addListener('click', () => {
				infoWIndow.open(mapsRef.current, endMarker);
			});

			//@ts-ignore
			mapsRef.current.event.addListener(routePolyline, 'click', (event: any) => {
				infoWIndow.setPosition(event.latLng);
				infoWIndow.open(mapsRef.current, routePolyline);
			});
		}
	};

	const hideRoute = (route: CurrentRoute) => {
		if (route && route?.start && route?.end) {
			route.start.setMap();
			route.end.setMap();
			route.route.setMap();
		}
		setCurrentRoute(null);
	};

	const handleShowRoute = (route: Route | undefined) => () => {
		const currentRoute = getCurrentRoute();

		if (!route) {
			currentRoute && hideRoute(currentRoute);
			return;
		}

		//@ts-ignore
		const { origin, destination = DEFAULT_CENTER, id } = route;
		if (currentRoute?.id === id) {
			hideRoute(currentRoute);
		}

		if (directionsRenderer.current && directionsService.current && distanceMatrixService.current) {
			//@ts-ignore
			directionsService.current.route(
				{
					origin,
					destination,
					travelMode: 'DRIVING',
				},
				(route: any, status: any) => {
					if (status === 'OK') {
						//@ts-ignore
						distanceMatrixService.current.getDistanceMatrix(
							{
								origins: [origin],
								destinations: [destination],
								travelMode: 'DRIVING',
							},
							(distance: any) => {
								showRoute(route, distance, id);
							},
						);
					} else {
						console.log(`Directions request failed due to ${status}`);
					}
				},
			);
		}
	};

	const handleMarkerClick = (id: any, data: any) => () => {
		onMarkerClick(id, data);
	};

	const handleMarkerDoubleClick = (id: any, data: any) => (e: any) => {
		e.stopPropagation();
		e.preventDefault();

		onMarkerDblClick(data?.offerId);
	};

	const handleClusterClick =
		(id: any, { lat, lng }: any) =>
		() => {
			//@ts-ignore
			const expansionZoom = Math.min(supercluster.getClusterExpansionZoom(id), 20);
			//@ts-ignore
			mapRef.current.setZoom(expansionZoom);
			//@ts-ignore
			mapRef.current.panTo({ lat, lng });
		};

	const handleClusterDblClick = (id: any) => () => {
		//@ts-ignore
		const leaves = supercluster?.getLeaves(id, 'Infinity');

		onClusterDblClick(leaves?.map((item: any) => item?.properties?.data?.offerId));
	};

	const handleMapType = (type: string) => {
		//@ts-ignore
		mapRef.current && mapRef.current.setMapTypeId(type);
	};
	const handleClusterMouseOver = (cluster: any) => () => {
		//@ts-ignore
		onClusterSelect(cluster?.id ? supercluster?.getLeaves(cluster.id, 'Infinity') : []);
	};

	const handleMarkerMouseOver = (id: any, data: any) => () => {
		onMarkerSelect(id, data);
	};

	const handleZoomIn = () => {
		if (mapRef && mapRef.current) {
			//@ts-ignore
			mapRef.current.setZoom(mapRef.current.getZoom() + 1);
		}
	};

	const handleZoomOut = () => {
		if (mapRef && mapRef.current) {
			//@ts-ignore
			mapRef.current.setZoom(mapRef.current.getZoom() - 1);
		}
	};

	const handleZoomToPoint = (zoomToPoint: Coordinate) => {
		if (zoomToPoint?.lat && zoomToPoint?.lng) {
			//@ts-ignore
			mapRef?.current?.panTo(zoomToPoint);
			//@ts-ignore
			mapRef?.current?.setZoom(15);
		}
	};

	useEffect(() => {
		if (routeToShow) {
			handleShowRoute(routeToShow)();
		}
	}, [routeToShow]);

	useEffect(() => {
		if (zoomToPoint) {
			handleZoomToPoint(zoomToPoint);
		}
	}, [zoomToPoint]);

	useEffect(() => {
		// if (handleMarkersRender) {
		//     handleMarkersRender(getIdsFromClusters(clusters, supercluster));
		// }
		setIdsOnMap(getIdsFromClusters(clusters, supercluster));
	}, [clusters]);

	const handleOnChange = ({ zoom, bounds }: ChangeEventValue) => {
		setZoom(zoom);

		const { nw, se } = bounds;

		setBounds([nw.lng, se.lat, se.lng, nw.lat]);
	};

	return (
		<div style={{ height: '100%', width: '100%' }}>
			{!hideMapType && <MapType onClick={handleMapType} />}
			{showMapMoveSync && <SyncMap bounds={bounds} idsOnMap={idsOnMap} />}
			{showZoomButtons && <ZoomButtons onZoomIn={handleZoomIn} onZoomOut={handleZoomOut} />}

			<GoogleMapReact
				bootstrapURLKeys={{ key: GOOGLE_API_KEY, language: 'ru', region: 'ru' }}
				defaultCenter={defaultCenter}
				defaultZoom={defaultZoom}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={handleApiLoad}
				options={MAP_OPTIONS}
				onChange={handleOnChange}
			>
				{mapReady &&
					mapRef.current &&
					clusters.map((cluster: any) => {
						const [longitude, latitude] = cluster.geometry.coordinates;
						const { cluster: isCluster, point_count: pointCount, data = {}, id, location } = cluster.properties;

						const { route, direction } = data;
						//@ts-ignore
						const leaves = cluster?.id ? supercluster?.getLeaves(cluster.id, 'Infinity') : [];
						const leavesToShow = leaves?.filter((l: any) => l?.properties?.data?.showOnMap);

						//@ts-ignore
						if (isCluster && leavesToShow?.length > 1) {
							// const leaves = supercluster?.getLeaves(cluster.id, 'Infinity');
							const statusesInCluster = mapClusterChildrenStatuses(leaves);
							const isHovered = leaves?.some((item: any) => highlightedMarkers[item?.properties?.id]);

							return (
								<Cluster
									data={leavesToShow}
									hintComponent={clusterHintComponent}
									onMouseEnter={handleClusterMouseOver(cluster)}
									onMouseLeave={handleClusterMouseOver(null)}
									onDoubleClick={handleClusterDblClick(cluster.id)}
									onClick={handleClusterClick(cluster.id, { lat: latitude, lng: longitude })}
									count={leavesToShow?.length || pointCount}
									lat={latitude}
									lng={longitude}
									hovered={isHovered}
									key={`cluster-${cluster.id}`}
									statuses={statusesInCluster}
								/>
							);
						}

						if (isCluster && leavesToShow?.length === 1) {
							const curLeave = leavesToShow[0].properties;

							const onShowRoute = route
								? handleShowRoute({
										origin: curLeave?.data?.route?.origin,
										destination: curLeave?.data?.route?.destination,
										location: curLeave?.data?.location,
										direction: curLeave?.data?.direction,
										id: curLeave?.data?.id,
								  })
								: null;

							return (
								<Marker
									status={data?.statusInBid}
									onClick={handleMarkerClick(curLeave?.id, curLeave?.data)}
									onDoubleClick={handleMarkerDoubleClick(curLeave.id, curLeave.data)}
									onMouseEnter={handleMarkerMouseOver(curLeave.id, curLeave.data)}
									onMouseLeave={handleMarkerMouseOver(curLeave.id, undefined)}
									onShowRoute={onShowRoute}
									data={curLeave?.data}
									key={curLeave?.id}
									hovered={highlightedMarkers[curLeave?.id]}
									selected={selectedMarkers[curLeave?.id]}
									lat={curLeave?.location?.lat}
									lng={curLeave?.location?.lng}
									hintComponent={hintComponent}
								/>
							);
						}

						if (!isCluster) {
							const onShowRoute = route
								? handleShowRoute({
										origin: route.origin,
										destination: route.destination,
										location,
										direction,
										id,
								  })
								: null;

							return (
								<Marker
									status={data?.statusInBid}
									onClick={handleMarkerClick(id, data)}
									onDoubleClick={handleMarkerDoubleClick(id, data)}
									onMouseEnter={handleMarkerMouseOver(id, data)}
									onMouseLeave={handleMarkerMouseOver(id, undefined)}
									onShowRoute={onShowRoute}
									data={data}
									key={id}
									hovered={highlightedMarkers[id]}
									selected={selectedMarkers[id]}
									lat={location.lat}
									lng={location.lng}
									hintComponent={hintComponent}
								/>
							);
						} else return null;
					})}
			</GoogleMapReact>
		</div>
	);
};
