export const IS_DEVELOPMENT_ENV = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'dev-light';

export const getEnvUrl = () => {
	return IS_DEVELOPMENT_ENV ? (process.env.DEV_CONNECT_TO === 'prod' ? '' : 'dev.') : '';
};

export const debugSessionToken = 'fc543faa019ff834d0521f19ac9801a8e92b7994';

export const getSessionToken = (sessionToken: any) => {
	return IS_DEVELOPMENT_ENV ? (process.env.DEV_CONNECT_TO === 'prod' ? debugSessionToken : sessionToken) : sessionToken;
};

export const isDevConnectedToProd = process.env.NODE_ENV === 'development' && process.env.DEV_CONNECT_TO === 'prod';
