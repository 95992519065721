//@ts-nocheck
export function initMetrics(...args) {
	if (process.env.NODE_ENV === 'development') return;
	// GA
	(window as any).dataLayer = (window as any).dataLayer || [];
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	function gtag(key: any, value: any) {
		//
		(window as any).dataLayer.push(args);
	}

	gtag('js', new Date());
	gtag('config', 'UA-117324864-1');

	// Yandex.Metrica
	(function (m: any, e: any, t: any, r: any, i: any, k?: any, a?: any) {
		m[i] =
			m[i] ||
			function () {
				(m[i].a = m[i].a || []).push(args);
			};
		m[i].l = new Date().getTime();

		(k = e.createElement(t)),
			(a = e.getElementsByTagName(t)[0]),
			(k.async = 1),
			(k.src = r),
			a.parentNode.insertBefore(k, a);
	})(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

	// Check Auth User
	const metricaParams: any = {
		accurateTrackBounce: true,
		trackLinks: true,
		clickmap: true,
		webvisor: true,
		userParams: {},
	};

	if (localStorage.profile) {
		try {
			const { uuid, role } = JSON.parse(localStorage.profile);
			if (uuid) {
				metricaParams.userParams = {
					UserID: uuid,
					Role: role,
				};
			}
		} catch (error) {
			// @TODO: handle if need
		}
	}

	// console.log("ya.metrica params", metricaParams);
	(window as any).ym(48431690, 'init', metricaParams);
}
