import { CommonActions, UserActions } from 'app/actions';
import ProfileWidget from 'app/components/ProfileWidget';
import { RoleEnum, SidebarType, UserProfile } from 'app/models';
import { RootState } from 'app/reducers';
import { globalInitialState } from 'app/utils/initial-state';
import { Localized } from '@fluent/react';
import { Location } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { bindActionCreators, Dispatch } from 'redux';

const ICON_LOGO = require('../../../assets/icons/logo.svg');
const ICON_LOGOUT = require('../../../assets/icons/logout.svg');

const mapStateToProps = ({ common, user }: RootState) => ({
	openedSidebar: common.openedSidebar,
	profile: user.profile,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			logout: UserActions.logout,
		},
		dispatch,
	);

class SidebarMenu extends React.Component<SidebarMenu.Props, SidebarMenu.State> {
	public state: SidebarMenu.State = {};

	constructor(props: SidebarMenu.Props) {
		super(props);

		this.getList = this.getList.bind(this);
		this.getHeader = this.getHeader.bind(this);
	}

	private getHeader() {
		const { profile } = this.props;

		if (profile) {
			return <ProfileWidget profile={profile} />;
		} else {
			return null;
		}
	}

	private getList() {
		const { location, profile, toggleSidebar, openedSidebar } = this.props;

		const closeSidebar = () => {
			if (toggleSidebar && openedSidebar) {
				toggleSidebar({ type: openedSidebar[0], value: false });
			}
		};

		const list = [
			{ text: 'menu-link-orders', page: '/panel/orders' },
			{ text: 'menu-link-profile', page: '/panel/profile' },
		];

		if (profile?.isDispatcher) {
			list.push({
				text: 'menu-link-admin',
				page: '/panel/admin',
			});
		}

		if (profile?.role === RoleEnum.Customer) {
			list.push({
				text: 'menu-link-transport',
				page: '/panel/transport/my',
			});
		}

		return list.map(({ text, page }, index) => {
			if (page.indexOf('http') === 0) {
				return (
					<a key={page + index} className='item' href={page}>
						<Localized id={text} />
					</a>
				);
			}
			return (
				<NavLink
					activeClassName='active'
					onClick={closeSidebar}
					location={location}
					key={page + index}
					className='item'
					to={page}
				>
					<Localized id={text} />
				</NavLink>
			);
		});
	}

	public render() {
		const { logout } = this.props;

		return (
			<div className='sidebar menu'>
				{this.getHeader()}

				<div className='logo'>
					<img src={globalInitialState.logoUrl || ICON_LOGO} alt='' />
				</div>

				<div className='list'>{this.getList()}</div>

				<div className='fill' />

				<div className='logout' onClick={logout}>
					<img src={ICON_LOGOUT} alt='' />
					<Localized id='menu-action-logout' />
				</div>
			</div>
		);
	}
}

export default connect<SidebarMenu.StateProps, SidebarMenu.DispatchProps, SidebarMenu.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(SidebarMenu);

namespace SidebarMenu {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		openedSidebar?: [SidebarType, boolean, any];
		profile?: UserProfile;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		logout?: () => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		location: Location;
	}

	// Main component state
	export interface State {}
}
