import React, { forwardRef } from 'react';
import clsx from 'clsx';

export type GroupProps = {
	direction: string;
	className: string;
	hidden?: boolean;
	children?: React.ReactNode;
};

export const Group = forwardRef((props: GroupProps, ref?: React.Ref<HTMLDivElement>) => {
	const { direction, className, hidden, children } = props;

	return (
		<div ref={ref} className={clsx('group', direction, className, { hidden })}>
			{children}
		</div>
	);
});

Group.displayName = 'Group';
