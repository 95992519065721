import React from 'react';

//other deps
import { isEmpty } from 'lodash';
import Scrollbars from 'react-custom-scrollbars';

//parts
import { BaseCarPage } from './parts/base';
import { DocsCarPage } from './parts/docs';

//types
import { DriverCar } from 'app/models';

type CarDetailedProps = {
	car: DriverCar;
	handleModal: () => void;
};

//helpers
import { isValidBase } from '../../cars.helpers';

//----------------------------------------------------------
// CarDetailed
//----------------------------------------------------------
export const CarDetailed = (props: CarDetailedProps) => {
	const { car = {} as DriverCar, handleModal } = props;

	const { certificates, transport } = car;
	const showDocs = !isEmpty(certificates) && isValidBase(transport);

	return (
		<>
			<Scrollbars className='opened-car' key={car.uuid}>
				<BaseCarPage openSendedModal={handleModal} car={car} />

				{showDocs && <DocsCarPage openSendedModal={handleModal} car={car} />}
			</Scrollbars>
		</>
	);
};
