//@ts-nocheck
import React from 'react';
import SelectComponent from 'react-select';
import styled from 'styled-components';
import { Check } from 'app/components/Icons';

const CustomOptionDiv = styled.div<{ selected?: boolean }>`
	display: flex;
	align-items: center;
	padding: 10px 12px;
	background-color: ${(props) => (props.selected ? '#e7f9ff' : 'none')};
	&:hover {
		background-color: #e7f9ff;
	}

	cursor: pointer;
	z-index: 1199999;
`;

const CheckboxDiv = styled.div<{ selected: boolean }>`
	background-color: ${(props) => (props.selected ? '#04b9e6' : 'none')};
	display: flex;
	justify-content: center;
	margin-right: 8px;
	padding: 0px;
	width: 12px;
	height: 12px;
`;

export const SelectLabel = styled.p`
	font-size: 10px;
	color: #6c6f88;
	margin-bottom: 6px;
	min-height: 11px;
`;

const Label = styled.span<{ selected: boolean }>`
	font-size: 16px;
	color: ${(props) => (props.selected ? '#04b9e6' : '#000000')};
`;

export const CustomOption = ({ innerRef, innerProps, ...rest }) => {
	const { label, isSelected, isMulti } = rest;
	return (
		<CustomOptionDiv selected={isSelected && !isMulti} ref={innerRef} {...innerProps}>
			{isMulti && <CheckboxDiv selected={isSelected}>{isSelected && <Check size='12' color='white' />}</CheckboxDiv>}
			<Label selected={isSelected}>{label}</Label>
		</CustomOptionDiv>
	);
};

type Option = {
	label: string;
	value: string | number;
};

export type SelectProps = {
	onChange?: (option: Option) => void;
	label?: string;
	disabled?: boolean;
	isClearable?: boolean;
	options: Option[];
	multi?: boolean;
	name?: string;
	placeholder?: string;
	defaultValue?: any;
};

export const createOptionsFromObject = (optionsObject: any) => {
	return Object.keys(optionsObject).reduce((acc: any, el: any) => {
		//@ts-ignore
		return [...acc, { value: el, label: optionsObject[el] }];
	}, []);
};

export const Select = ({
	onChange,
	label,
	disabled,
	options,
	multi,
	name,
	placeholder,
	isClearable,
	defaultValue,
}: SelectProps) => {
	const customStyles = {
		dropdownIndicator: () => ({
			color: '#5FC8ED',
		}),
		indicatorSeparator: () => ({
			color: 'none',
		}),
		control: (provided, state) => {
			return {
				...provided,
				borderColor: state.menuIsOpen ? '#5FC8ED' : 'lightgray',
				height: '40px',
				overflow: 'hidden',
				boxShadow: 'none',
				['&:hover']: {
					borderColor: '#5FC8ED',
				},
				['&:active']: {
					borderColor: '#5FC8ED',
				},
			};
		},
		menu: (provided) => ({
			...provided,
			zIndex: '99999999',
		}),
		valueContainer: (provided) => ({
			...provided,
			height: '30px',
			overflow: 'hidden',
		}),
	};

	return (
		<div>
			<SelectLabel>{label}</SelectLabel>
			<SelectComponent
				defaultValue={defaultValue}
				isClearable={isClearable}
				classNamePrefix='select'
				styles={customStyles}
				components={{
					Option: CustomOption,
				}}
				placeholder={placeholder}
				hideSelectedOptions={false}
				isDisabled={disabled}
				// value={value}
				isMulti={multi}
				onChange={onChange}
				name={name}
				options={options}
			/>
		</div>
	);
};
