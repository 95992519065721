import { store } from 'src/main';
import { ShippingFare } from 'app/models';

export namespace Rate {
	export enum ByType {
		WT_KM = 'т/км',
		WT = 'т',
	}

	export interface Range {
		min: number;
		max: number;
	}

	export interface Values {
		currencySymbol?: string;
		isOverload: boolean;
		isPrepaid: boolean;
		isBargain: boolean;
		hasVat: boolean;
		isCash: boolean;
		isPricePerKm: boolean;
		isRecommendedPrice: boolean;
		type: ByType;

		totalPrice: number;
		rate: number;
	}

	export interface Params {
		step: number;
		min: number;
		max: number;
	}

	export interface State {
		opened: boolean;

		distance: number;
		weight: number;

		params: Params;
		values: Values;
	}

	export const DEFAULT_PARAMS: Params = {
		max: 1000,
		min: 100,
		step: 10,
	};

	export const DEFAULT_VALUES: Values = {
		isOverload: false,
		isPrepaid: false,
		isBargain: false,
		hasVat: false,
		isCash: false,
		isPricePerKm: false,
		isRecommendedPrice: false,

		type: ByType.WT,
		totalPrice: 0,
		rate: 550,
	};

	export const DEFAULT_STATE: State = {
		opened: false,
		distance: 0,
		weight: 0,

		params: DEFAULT_PARAMS,
		values: DEFAULT_VALUES,
	};
}

export const RateApi = {
	getTotalPrice(rate: number, isPricePerKm: boolean, distance: number, weight: number): number {
		if (isPricePerKm) {
			return distance * rate * (weight || 1);
		} else {
			return Math.round(rate * (weight || 1));
		}
	},

	getRateRange(rate: number, isPricePerKm: boolean): Rate.Range {
		const rateStep = isPricePerKm ? 0.01 : 10;

		const min = Math.round((rate - rate * 0.4) / rateStep) * rateStep;
		const max = Math.round((rate + rate * 0.4) / rateStep) * rateStep;

		return {
			min,
			max,
		};
	},

	getDefaultRate(distance: number, isPricePerKm: boolean): number {
		const { shippingFare = [] } = store.getState().common;
		const { fare = 12 } =
			shippingFare.find((s: ShippingFare) => distance > s.low && distance <= s.high) || ({} as ShippingFare);

		return isPricePerKm ? fare : fare * distance;
	},
};
