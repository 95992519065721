import React from 'react';
import styled from 'styled-components';
import { Typography } from 'app/sharedComponents/Typography';
import { LineChart, Line } from 'recharts';

const CellContainer = styled.div`
	display: flex;
	flex-direction: row;
`;
const PriceContainer = styled.div`
	display: flex;
	flex-direction: column;
	margin-right: 15px;
`;

const TriangleUp = styled.div`
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-bottom: 10px solid #72dab3;
	margin-right: 5px;
`;

const TriangleDown = styled.div`
	width: 0;
	height: 0;
	border-left: 5px solid transparent;
	border-right: 5px solid transparent;
	border-top: 10px solid #ff265a;
	margin-right: 5px;
`;

const DirCell = styled.div`
	display: flex;
	justify-content: flex-end;
	align-items: center;
`;

const DIRECTIONS = {
	UP: 'up',
	DOWN: 'down',
	STABLE: 'stable',
};

const colors = {
	[DIRECTIONS.UP]: '#72DAB3',
	[DIRECTIONS.DOWN]: '#FF265A',
	[DIRECTIONS.STABLE]: '#8D8D8D',
};

const getDirection = (diff: number) => {
	switch (true) {
		case diff > 0:
			return DIRECTIONS.UP;

		case diff < 0:
			return DIRECTIONS.DOWN;

		case diff === 0:
		default:
			return DIRECTIONS.STABLE;
	}
};

export const PriceCell = ({ price, row }: any) => {
	// const { charts } = row;
	// const diff = charts?.[charts?.length - 1]?.value - charts?.[0]?.value;

	const newCharts = row?.preview.length
		? row?.preview.map((value: any) => ({
				value,
				timepoint: '2021-07-04T00:00:00+00:00',
				count: 0,
		  }))
		: [];
	const diff = newCharts?.[newCharts?.length - 1]?.value - newCharts?.[0]?.value;
	const direction = getDirection(diff);
	return (
		<CellContainer>
			<PriceContainer>
				<Typography variant='body3' bold>
					{price}
				</Typography>
				<DirCell>
					{direction === DIRECTIONS.DOWN && <TriangleDown />}
					{direction === DIRECTIONS.UP && <TriangleUp />}
					{direction !== DIRECTIONS.STABLE && (
						<Typography color={colors[direction]} variant='body3'>
							{Math.abs(diff).toFixed(2)}
						</Typography>
					)}
				</DirCell>
			</PriceContainer>
			<LineChart width={45} height={35} data={newCharts}>
				<Line type='monotone' dataKey='value' stroke={colors[direction]} strokeWidth={1} dot={false} />
			</LineChart>
		</CellContainer>
	);
};
