import { Orders as OrdersNamespace } from './types';

const tableHeadersAll = [
	{ className: 'start', localized: 'orders-row-start', id: 'start' },
	{ className: 'from', localized: 'orders-row-from', sort: true, id: 'delivery_from' },
	{ className: 'to', localized: 'orders-row-to', sort: true, id: 'direction_to__name' },
	{ className: 'distance', localized: 'orders-row-distance', id: 'distance' },
	{
		className: 'cargo',
		localized: 'orders-row-cargo',
		sort: true,
		id: 'cargo_type__name',
	},
	{
		className: 'weight',
		localized: 'orders-row-weight',
		sort: true,
		id: 'weight',
	},
	{ className: 'rate', localized: 'orders-row-rate', sort: true, id: 'price' },
	{ className: 'rate', localized: 'orders-row-rate-per-km', sort: true, id: 'price_per_km' },
];

const tableHeadersMy = [
	{ className: 'views', localized: 'orders-row-views' },
	{ className: 'views', localized: 'orders-row-answers' },
	{ className: 'views', localized: 'orders-row-inwork' },
];

export const headers = <any>{
	[OrdersNamespace.Tab.All]: [...tableHeadersAll],
	[OrdersNamespace.Tab.My]: [...tableHeadersAll, ...tableHeadersMy],
	[OrdersNamespace.Tab.Archive]: [...tableHeadersAll],
};
