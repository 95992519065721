import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import Scrollbars from 'react-custom-scrollbars';
import * as Icons from 'app/components/Icons';
import { Localized } from '@fluent/react';
import { RootState } from 'app/reducers';
import { connect } from 'react-redux';
import * as React from 'react';
import { withLocalization } from '@fluent/react';

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

class InfoCompanyDocsFaq extends React.Component<InfoCompanyDocsFaq.Props, InfoCompanyDocsFaq.State> {
	public state: InfoCompanyDocsFaq.State = {};

	constructor(props: InfoCompanyDocsFaq.Props) {
		super(props);
	}

	public render() {
		const { getString } = this.props;

		//TODO: Сделать перевод на английский язык в src/app/locales/en/faq.ftl
		const localizedFaqMainParagraph = getString('faq-main-paragraph').split('$link$');
		const items = [
			{
				title: 'faq-verify-header-security',
				content: 'faq-verify-content-security',
			},
			{
				title: 'faq-verify-header-key',
				content: 'faq-verify-content-key',

				subcontent: {
					title: 'faq-verify-header-key-receive',
					content: 'faq-verify-content-key-receive',
				},
			},
		].map(({ title, content, subcontent }) => (
			<div className='item' key={title}>
				<div className='icon'>
					<Icons.Check color='#72CDED' />
				</div>
				<div className='description'>
					<div className='title'>
						<Localized id={title} />
					</div>
					<div className='info'>
						<Localized id={content} />
					</div>
					{!subcontent ? null : (
						<div className='sub-info'>
							<div className='title'>
								<Localized id={subcontent.title} />
							</div>
							<div className='info'>
								<Localized id={subcontent.content} />
							</div>
						</div>
					)}
				</div>
			</div>
		));

		return (
			<div className='faq page'>
				<Scrollbars>
					<div className='faq__container'>
						<div className='header'>
							<Localized id='faq-verify-header' />
						</div>
						<div>
							{localizedFaqMainParagraph[0]}
							<a href='https://хартия-атс.рф/o-hartii' target='_blank' rel='noreferrer'>
								https://хартия-атс.рф/o-hartii
							</a>
							{localizedFaqMainParagraph[1]}
						</div>
						<div className='items'>{items}</div>
					</div>
				</Scrollbars>
			</div>
		);
	}
}

export default withRouter(
	connect<InfoCompanyDocsFaq.StateProps, InfoCompanyDocsFaq.DispatchProps, InfoCompanyDocsFaq.ExternalProps>(
		mapStateToProps,
		mapDispatchToProps,
	)(withLocalization(InfoCompanyDocsFaq)),
);

namespace InfoCompanyDocsFaq {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		getString: (id: string) => string;
	}

	// Main component state
	export interface State {}
}
