import { SchemeProps } from 'app/containers/Builder/props';

import { LegalTypeEnum } from 'app/models';
export const processErrors = (errors: any) => {
	if (errors?.fields) {
		const { fields } = errors;
		const errorsMap = Object.keys(fields).map((field: string) => {
			return fields[field] && Array.isArray(fields[field]) ? `${fields[field].join()}` : '';
		});
		return errorsMap;
	}
	return [];
};

export const schemeMapper = (scheme: SchemeProps.Form, legalType: LegalTypeEnum | undefined, verified?: boolean) => {
	const wrongType = legalType === LegalTypeEnum.Np ? 'Company' : 'Input';

	return scheme.map((item: any) =>
		item.name === 'inn'
			? item.type === wrongType
				? { ...item, hidden: true, disabled: verified }
				: { ...item, hidden: false, disabled: verified }
			: { ...item, disabled: item.name !== 'email' && verified },
	);
};
