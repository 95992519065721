import * as React from 'react';

//other deps
import _ from 'lodash';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';

//redux
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';

//schemes
import * as schemes from '../../../schemes';

//components
import Builder from 'app/containers/Builder';
import ValidationReporter from 'app/components/ValidationReporter';

ValidationReporter;

//types
import { EmptyCarDocuments } from 'app/models';
import { RootState } from 'app/reducers';
import { EmptyCarActions } from 'app/actions';
import { SchemeProps } from 'app/containers/Builder/props';

//----------------------------------------------------------
// InsuranceItem
//----------------------------------------------------------
class InsuranceItem extends React.Component<InsuranceItem.Props, InsuranceItem.State> {
	private builder: Builder | null = null;

	public state: InsuranceItem.State = {
		form: {},
		validationReport: [],
	};

	constructor(props: InsuranceItem.Props) {
		super(props);

		this.initializeBuilderWithProps = this.initializeBuilderWithProps.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.applyDefaults = this.applyDefaults.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	public async componentDidMount() {
		this.initializeBuilderWithProps();
	}

	private async initializeBuilderWithProps() {
		if (!this.state.scheme) {
			const scheme = _.cloneDeep(schemes.insurance);
			this.setState({ scheme });

			await Promise.delay(50);
		}

		this.applyDefaults();
	}

	private applyDefaults() {
		const { builder } = this;

		if (builder) {
			const { formRefs } = builder;
			const allowed = ['number'];

			for (const key of allowed) {
				const ref = formRefs?.get(key);
				const value = (this.props?.insurance as any)?.[key] ?? null;

				if (ref && value) ref.updateValue(value);
			}
		}
	}

	private async onUpdate(name: string, value: any) {
		const { form } = this.state;
		const { updatePolicyData } = this.props;

		this.setState({
			form: {
				...form,
				[name]: value,
			},
		});

		if (updatePolicyData) {
			updatePolicyData({
				[name]: value,
			});
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	public render() {
		const { scheme } = this.state;

		const builderProps = {
			getRef: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		return (
			<div className='insurance item opened'>
				<div className='titlebar'>
					<div className='titles'>
						<div className='header'>
							<Localized id='transport-insurances-title' />
						</div>
					</div>
				</div>

				<div className='form'>
					<Builder {...builderProps} />
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ common }: RootState) => ({
	locales: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			updatePolicyData: EmptyCarActions.updatePolicyData,
		},
		dispatch,
	);

export default connect<InsuranceItem.StateProps, InsuranceItem.DispatchProps, InsuranceItem.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(InsuranceItem);

export namespace InsuranceItem {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		locales?: FluentBundle[];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		updatePolicyData?: (insurance: Partial<EmptyCarDocuments['policy']>) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		insurance: EmptyCarDocuments['policy'] | null;
	}

	// Main component state
	export interface State {
		form: { [name: string]: any };
		scheme?: SchemeProps.Union[];
		validationReport: string[];
	}
}
