import { bindActionCreators, Dispatch } from 'redux';
import { SchemeProps, Field } from '../props';
import { UserContact } from 'app/models';
import { RootState } from 'app/reducers';
import { Localized } from '@fluent/react';
import { connect } from 'react-redux';
import Masker from 'vanilla-masker';
import * as React from 'react';
import _ from 'lodash';
import Checkbox from 'app/sharedComponents/Checkbox';

const mapStateToProps = ({ user }: RootState) => ({
	contacts: user.contacts,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

const initialState = {
	onlyChat: false,
	selectedId: '',
};

class ContactsField extends React.Component<ContactsField.Props, ContactsField.State> {
	public state: ContactsField.State = _.clone(initialState);

	constructor(props: ContactsField.Props) {
		super(props);

		this.onChangeContact = this.onChangeContact.bind(this);
		this.onChangeOption = this.onChangeOption.bind(this);
		this.getList = this.getList.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);
	}

	public updateValue(contactData: UserContact) {
		if (!contactData) {
			this.setState({
				onlyChat: true,
			});
			return;
		}
		// @unimplemented
		this.setState({
			selectedId: contactData.uuid,
		});
	}

	public reset() {
		this.setState(_.clone(initialState));
	}

	public componentDidMount() {
		const {
			contacts,
			field: { onUpdate },
		} = this.props;
		const { selectedId } = this.state;

		if (onUpdate && contacts && contacts.length > 0 && !selectedId) {
			const contactId = contacts[0].uuid;

			this.setState({ selectedId: contactId });
			onUpdate('contact', contactId);
		}
	}

	private onChangeOption(e: React.ChangeEvent<HTMLInputElement>) {
		const {
			props: { onUpdate },
		} = this;
		const { checked } = e.target;

		// this.setState(update(state, {
		//     onlyChat: {
		//         $set: checked,
		//     },
		// }))
		this.setState(() => ({
			onlyChat: checked,
		}));

		if (checked) {
			onUpdate('contact', '');
		}
	}

	private onChangeContact(index: number, event: React.ChangeEvent<HTMLInputElement>) {
		const { checked, value } = event.target;
		const { onUpdate } = this.props;

		if (checked) {
			this.setState({ selectedId: value });
			onUpdate('contact', value);
		}
	}

	private getList() {
		const { selectedId } = this.state;
		const { onChangeContact } = this;

		let { contacts } = this.props;
		contacts = contacts ? contacts : [];

		if (contacts.length < 1 && !selectedId) {
			return null;
		}

		return contacts.map((contact, index) => {
			const phone = Masker.toPattern(contact.phone, '+9 (999) 999-99-99');
			const key = 'create_contact' + index + Masker.toNumber(phone);
			const checked = selectedId === contact.uuid;

			const onChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
				onChangeContact(index, event);
			};

			const inputProps = {
				onChange: onChangeOption,
				value: contact.uuid,
				name: 'contact',
				type: 'radio',
				id: key,
				checked,
			};

			return (
				<div key={key} className='radio-button'>
					<input {...inputProps} />
					<label htmlFor={key}>
						<div className='name'>
							{contact.firstName} {contact.middleName}
						</div>
						<div className='phone'>{phone}</div>
					</label>
				</div>
			);
		});
	}

	public render() {
		const { onChangeOption } = this;
		const { onlyChat } = this.state;
		const contacts = this.getList();

		return (
			<div className='form-item contacts'>
				<Checkbox name='onlyChat' checked={onlyChat} onChange={onChangeOption}>
					<Localized id='builder-contacts-item-chat' />
				</Checkbox>
				{onlyChat ? null : <div className='my-contacts'>{contacts}</div>}
			</div>
		);
	}
}

namespace ContactsField {
	export type Props = ExternalProps & StateProps & DispatchProps;

	export interface State {
		selectedId: string;
		onlyChat: boolean;
	}

	export interface StateProps {
		contacts?: UserContact[];
	}

	export interface DispatchProps {}

	export interface ExternalProps extends Field.ComponentProps<string, SchemeProps.Contacts> {
		// @default from base
	}
}

export const Contacts = connect<ContactsField.StateProps, ContactsField.DispatchProps, ContactsField.ExternalProps>(
	mapStateToProps as any,
	mapDispatchToProps,
	null,
	{ forwardRef: true },
)(ContactsField);
