import * as React from 'react';
import _ from 'lodash';
import { Dispatch, bindActionCreators } from 'redux';
import update from 'immutability-helper';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { Localized } from '@fluent/react';
import { FluentBundle } from '@fluent/bundle';

import { CommonActions, BidActions } from 'app/actions';
import Scrollbars from 'react-custom-scrollbars';
import * as Icons from 'app/components/Icons';
import { RootState } from 'app/reducers';
import { bidCreate, getLocationFares } from 'app/api';

import { Bid, SidebarType, UserProfile, Company } from 'app/models';
import { BidApiModel } from 'app/api/types';

import { Field, SchemeProps } from 'app/containers/Builder/props';
import { normalizeParams, LIMITS_DEFAULT } from './common';
import { Rate } from '../../Builder/components';
import Builder from 'app/containers/Builder';

import SCHEME_CREATE from './scheme';
import validateByScheme from 'app/utils/validate-by-scheme';

const closeIcon = require('../../../../assets/icons/close.svg');
const clearIcon = require('../../../../assets/icons/clear_all.png');

const getCargos = (cargos?: BidApiModel.CommonMeta[]): Array<Field.Choice<string>> => {
	return (cargos ? cargos : []).map(
		(cargo): Field.Choice<string> => ({
			category: cargo.category,
			label: cargo.name,
			value: cargo.id,
		}),
	);
};

const toFixedNumber = (value: number, fractionDigits?: number | undefined): number => {
	return Number(value.toFixed(fractionDigits));
};

class CreateOrder extends React.Component<CreateOrder.Props, CreateOrder.State> {
	// Instance for direct manipulation local state & props
	public scrollbar: Scrollbars | null = null;
	public builder: Builder | null = null;

	public state: CreateOrder.State = {
		lockSubmit: false,
		valid: false,
	};

	constructor(props: CreateOrder.Props) {
		super(props);

		this.getSidebarHeader = this.getSidebarHeader.bind(this);
		this.formIsValid = this.formIsValid.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
		this.onSubmit = this.onSubmit.bind(this);
		this.reset = this.reset.bind(this);

		this.presetFormFromParams = this.presetFormFromParams.bind(this);
		this.getLocalizedMessage = this.getLocalizedMessage.bind(this);
		this.tripsCountUpdate = this.tripsCountUpdate.bind(this);
		this.updateRateProps = this.updateRateProps.bind(this);
		this.initializeForm = this.initializeForm.bind(this);
	}

	private getSidebarHeader() {
		const { toggleSidebar, profile } = this.props;

		const baseProfileValid =
			profile && !profile.needActions.includes('fill_profile') && !profile.needActions.includes('company');

		const toggle = () => {
			if (toggleSidebar) {
				toggleSidebar({ type: SidebarType.OrderCreate, value: false });
			}
		};

		const reset = () => {
			this.reset();
		};

		return (
			<div key='create-order-header' className='header'>
				<div className='title'>
					<Localized id='create-order-title' />
				</div>

				{!baseProfileValid ? null : (
					<div className='clear icon' onClick={reset}>
						<img src={clearIcon} alt='' />
					</div>
				)}

				<div className='icon' onClick={toggle}>
					<img src={closeIcon} alt='' />
				</div>
			</div>
		);
	}

	updateTariff = (tariff: any) => {
		const { scheme } = this.state;
		const tariffOptions = tariff?.map(({ name, id }: any) => ({ label: name, value: id })) ?? [];
		//@ts-ignore
		const updatedScheme = scheme?.map((el: any) => (el.name === 'tariff' ? { ...el, choices: tariffOptions } : el));
		this.setState({
			scheme: updatedScheme,
		});
	};

	public componentDidMount() {
		this.initializeForm();
		this.presetFormFromParams();
	}

	public componentDidUpdate(prevProps: CreateOrder.Props) {
		const { openedSidebar: prevOpenedSidebar } = prevProps as any;
		const { openedSidebar, tariff } = this.props;

		this.initializeForm();

		if (openedSidebar && !_.isEqual(openedSidebar[2], prevOpenedSidebar[2])) {
			this.presetFormFromParams();
		}
		if (!_.isEqual(tariff, prevProps.tariff)) {
			this.updateTariff(tariff);
		}
	}

	private async presetFormFromParams() {
		/*eslint-disable */
		const {
			//@ts-ignore
			openedSidebar: [sidebarType, sidebarOpened, params],
			companies = [],
		} = this.props;
		/*eslint-enable */
		const { builder } = this;

		if (params && builder) {
			const fromRef: any = builder.formRefs.get('from');
			fromRef.updateValue(params.from);

			const toRef: any = builder.formRefs.get('to');
			toRef.updateValue(params.to);

			const cargoRef: any = builder.formRefs.get('cargo');
			cargoRef.updateValue({
				value: params.productId,
				label: params.product,
			});

			await Promise.delay(1200);
			const distanceRef: any = builder.formRefs.get('distance');
			distanceRef.updateDistance(params.distance);

			await Promise.delay(50);
			const rateRef: any = builder.formRefs.get('rate');
			rateRef.updateRatePrice(params.rate);
			await Promise.delay(50);
		}
		if (builder && companies.length === 1) {
			await Promise.delay(50);
			const company: Company = companies[0];
			const refCompany = builder.formRefs.get('company') as any;
			refCompany.updateValue({ label: company.name || `ИНН: ${company.inn}`, value: company.id });
		}
	}

	private initializeForm() {
		const { cargos, profile, companies = [] } = this.props;
		const { state, builder } = this;

		if (companies.length > 0 && cargos && cargos.length > 0 && builder && profile && !state.scheme) {
			const scheme = _.cloneDeep(SCHEME_CREATE).map((e: any) =>
				e.name === 'tariff' ? { ...e, hide: profile?.isDispatcher ? false : true } : e,
			);

			const selectInput = scheme[3] as SchemeProps.Select<any>;
			const addressInput = scheme[1] as SchemeProps.Address;
			const rateInput = scheme[6] as SchemeProps.Rate;
			const companySelect = scheme[8] as SchemeProps.Select<any>;
			const map = scheme[2] as SchemeProps.Map;

			//@ts-ignore
			companySelect.choices = companies.map(({ name, inn, id }: Company) => ({
				label: name || `ИНН: ${inn}`,
				value: id,
			}));

			map.onFocus = () => {
				const ref = builder.formRefs.get('cargo') as any;
				if (ref) {
					ref.focus();
				}
			};

			// Lazy set choices
			selectInput.choices = getCargos(cargos);

			// Auto-tab on select
			selectInput.inputProps = {
				onSelect: () => {
					const ref = builder.formRefs.get('weight') as any;
					if (ref) {
						ref.focus();
					}
				},
			};

			companySelect.inputProps = {
				onSelect: () => {
					const ref = builder.formRefs.get('company') as any;
					if (ref) {
						ref.focus();
					}
				},
			};

			// Hotfix focus after map
			addressInput.inputProps = {
				onBlur: () => {
					const ref = builder.formRefs.get('cargo') as any;

					setTimeout(() => {
						if (ref) {
							ref.focus();
						}
					}, 200);
				},
			};

			// Preset currency for rate
			rateInput.defaultValues.currencySymbol = profile.currency.symbol;

			// Set final in scheme
			scheme[3] = selectInput;
			scheme[1] = addressInput;
			scheme[6] = rateInput;
			scheme[8] = companySelect;

			this.setState(
				update(state, {
					scheme: {
						$set: scheme,
					},
				}),
			);
		}
	}

	private tripsCountUpdate(name: string, value: any) {
		const { builder } = this;

		if (builder) {
			const { form } = builder.state;

			const { isOverload } = form;
			const { formRefs } = builder;

			// Auto-calc trips count by params
			if (name === 'weight' || name === 'isOverload') {
				const weight = name !== 'weight' ? (form.weight ? form.weight : 0) : value;

				let count = 0;

				if (isOverload) {
					count = Math.round(weight / 35);
				} else {
					count = Math.round(weight / 25);
				}

				if (!count) {
					count = 1;
				}

				const tripsCount = formRefs.get('tripsCount') as any;
				if (tripsCount) {
					tripsCount.updateValue(count.toString());
				}
			}
		}
	}

	private async updateRateProps(name: string, value: any) {
		const { builder } = this;

		if (builder) {
			const refs = builder.formRefs as any;
			const rate = refs.get('rate') as Rate;

			if (name === 'weight') {
				await rate.updateWeight(value);
			}

			if (name === 'distance') {
				await rate.updateDistance(value);
			}
		}
	}
	checkDistance = () => {
		const { builder } = this;

		if (builder) {
			const { form } = builder.state;
			const { from, to } = form;

			if (from && from.id && to && to.id) {
				if (from.id === to.id) {
					this.updateRateProps('distance', 1);
				}
			}
		}
	};

	checkFare = async () => {
		const { builder } = this;

		if (builder) {
			const { form } = builder.state;
			const { from, to, distance, cargo } = form;

			const refs = builder.formRefs as any;
			const rate = refs.get('rate') as Rate;

			if (from && from.id && to && to.id && distance) {
				try {
					const data = await getLocationFares({
						distance,
						fromLatitude: from.location.latitude,
						fromLongitude: from.location.longitude,
						toLatitude: to.location.latitude,
						toLongitude: to.location.longitude,
						cargo: cargo ?? null,
					});

					console.log(JSON.stringify(data, null, 4));

					if (data && data.recomended && data.range) {
						await rate.updateRatePriceWithParams({
							recomended: {
								farePerKm: toFixedNumber(data.recomended.farePerKm, 1),
								fare: toFixedNumber(data.recomended.fare),
							},
							range: {
								farePerKm: {
									min: toFixedNumber(data.range.farePerKm.min, 1),
									max: toFixedNumber(data.range.farePerKm.max, 1),
								},
								fare: {
									min: toFixedNumber(data.range.fare.min),
									max: toFixedNumber(data.range.fare.max),
								},
							},
						});
					}
				} catch (error) {
					console.log({ error });
				}
			}
		}
	};

	private async onUpdate(name: string, value: any) {
		if (name === 'cargo') {
			const { state = {} as any, formRefs = {} as Map<string, Field.Component<{}>> } = this.builder || {};
			const { form = {} } = state;
			const { cargo } = form;

			if (cargo) {
				const cargoData = this.props.cargos?.find((e: BidApiModel.CommonMeta) => e.id === cargo);

				if (cargoData) {
					formRefs.get('cargoPrice')?.updateValue((cargoData.addition.defaultPrice || 0).toString());
				}
			}
		}

		if (name === 'weight' || name === 'distance' || name === 'isOverload') {
			this.tripsCountUpdate(name, value);
			await this.updateRateProps(name, value);
		}

		if (name === 'from' || name === 'to') {
			this.checkDistance();
		}

		if (name === 'distance' || name === 'cargo' || name === 'isPricePerKm') {
			this.checkFare();
		}

		await this.setState({
			valid: this.formIsValid(),
		});
	}

	private async reset() {
		const { state, scrollbar, builder } = this;

		if (builder) {
			await builder.reset();

			await this.setState(
				update(state, {
					lockSubmit: { $set: false },
					valid: { $set: false },
				}),
			);
		}

		if (scrollbar) {
			scrollbar.scrollTop(0);
		}
	}

	private formIsValid(): boolean {
		const { builder } = this;

		if (builder) {
			const { form } = builder.state;
			const valid = validateByScheme(form, SCHEME_CREATE);
			return valid;
		} else {
			return false;
		}
	}

	private getLocalizedMessage(id: string): string {
		const { locales } = this.props;

		if (locales) {
			const [locale] = locales;
			return locale.getMessage(id)?.value?.toString() || '';
		} else {
			return '';
		}
	}

	private async onSubmit() {
		const { prices, sessionToken, getBids, cleanList } = this.props;
		const { state, reset, builder } = this;
		const { lockSubmit } = state;

		if (!state.valid || !prices || !sessionToken || !getBids || !cleanList || lockSubmit || !builder) {
			return;
		} else {
			console.log('in else');
			const { form } = builder.state;
			const params = normalizeParams({ ...form, limits: LIMITS_DEFAULT }, prices);
			console.log('normalized', params);

			await this.setState({ lockSubmit: true });

			try {
				const created = await bidCreate(params);
				console.log('BID Created: ', created);

				toast.success(this.getLocalizedMessage('create-order-alert-success'), {
					position: toast.POSITION.BOTTOM_CENTER,
				});

				await Promise.all([cleanList(Bid.BidType.All), cleanList(Bid.BidType.My)]);
				await Promise.all([getBids({ type: Bid.BidType.All }), getBids({ type: Bid.BidType.My })]);
				await reset();
			} catch (error) {
				this.setState({ lockSubmit: false });
				console.error(error);
			}
		}
	}

	public render() {
		const { lockSubmit, scheme, valid } = this.state;
		const { profile } = this.props;

		const extendedButtonClass = !valid || lockSubmit ? 'disabled' : '';

		const scrollbarProps: any = {
			ref: (node: Scrollbars | null) => (this.scrollbar = node),
			renderTrackHorizontal: () => <div />,
			key: 'create-order-scrollbar',
			className: 'form',
		};

		const builderProps = {
			ref: (node: Builder | null) => {
				this.builder = node;
			},
			scheme: scheme ? scheme : [],
			onUpdate: this.onUpdate,
		};

		const baseProfileValid =
			profile && !profile.needActions.includes('fill_profile') && !profile.needActions.includes('company');

		return baseProfileValid ? (
			<>
				{this.getSidebarHeader()}

				<Scrollbars {...scrollbarProps}>
					<Builder {...builderProps} />
				</Scrollbars>

				<button key='create-order-action' onClick={this.onSubmit} className={'submit ' + extendedButtonClass}>
					<Localized id={lockSubmit ? 'create-order-action-process' : 'create-order-action-create'} />
				</button>
			</>
		) : (
			<>
				{this.getSidebarHeader()}

				<div key='fill-profile' className='fill-profile'>
					<Icons.VerifyUser />

					<div className='title'>
						<Localized id='create-order-fill-profile' />
					</div>

					<a href='/panel/profile' target='_blank' className='action'>
						<Localized id='profile-action-transport-fill' />
					</a>
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ common, user, customer }: RootState) => ({
	openedSidebar: common.openedSidebar,
	sessionToken: user.sessionToken,
	locales: common.bundlesLocales,
	profile: user.profile,
	cargos: common.cargos,
	prices: common.prices,
	tariff: common.tariff,
	companies: customer.companies,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
			cleanList: BidActions.cleanList,
			getBids: BidActions.getBids,
		},
		dispatch,
	);

export default connect<CreateOrder.StateProps, CreateOrder.DispatchProps, CreateOrder.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(CreateOrder);

namespace CreateOrder {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		openedSidebar?: [SidebarType, boolean, any];
		cargos?: BidApiModel.CommonMeta[];
		prices?: BidApiModel.CommonMeta[];
		locales?: FluentBundle[];
		sessionToken?: string;
		profile?: UserProfile;
		companies?: Company[];
		tariff?: any[];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		getBids?: (params: BidApiModel.BidsRequest, cursor?: string) => void;
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		cleanList?: (type: Bid.BidType) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		scheme?: SchemeProps.Union[];
		lockSubmit: boolean;
		valid: boolean;
	}
}
