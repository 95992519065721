import { Dispatch, bindActionCreators } from 'redux';
import { LocalizationProvider, ReactLocalization } from '@fluent/react';
import { CommonActions } from 'app/actions';
import { RootState } from 'app/reducers';
import { FluentBundle } from '@fluent/bundle';
import { connect } from 'react-redux';
import * as React from 'react';

const mapStateToProps = ({ common }: RootState) => ({
	bundles: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			changeLocales: CommonActions.changeLocales,
		},
		dispatch,
	);

class AppLocalization extends React.Component<AppLocalization.Props, AppLocalization.State> {
	UNSAFE_componentWillMount() {
		const { changeLocales } = this.props;
		if (changeLocales) {
			let locales: string[] = [];
			locales = ['ru', 'en-US', 'en'];
			localStorage.i18n = JSON.stringify(locales);

			// if (!localStorage.getItem("i18n")) {
			//     localStorage.setItem("i18n", JSON.stringify(navigator.languages))
			//     locales = navigator.languages as string[]
			// } else {
			//     locales = JSON.parse(localStorage.getItem("i18n") as string)
			// }

			changeLocales(locales);
			// @TODO: for force en - ["en-US", "ru-RU", "en", "ru"]
		}
	}

	render() {
		const { bundles, children } = this.props;

		if (!bundles) {
			// Show a loader
			return <div>…</div>;
		}

		const l10n = new ReactLocalization(bundles);

		return <LocalizationProvider l10n={l10n}>{children}</LocalizationProvider>;
	}
}

export default connect<AppLocalization.StateProps, AppLocalization.DispatchProps, AppLocalization.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(AppLocalization);

namespace AppLocalization {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		bundles?: FluentBundle[];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		changeLocales?: (userLocales: string[]) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {}
}
