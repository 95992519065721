import styled from 'styled-components';
import React from 'react';
import { useSelector } from 'react-redux';
import { transportTypeByKind } from 'app/selectors/transport';
import { CardPhoto as Photo } from './Common';

import Actions from 'app/sharedComponents/Actions';
import { driverName } from 'app/utils/transport';

const ICON_AVATAR = require('assets/icons/avatar_driver.jpg');
const ICON_NO_DRIVER_PHOTO = require('src/assets/icons/avatar_autotype.png');

const BidMapBubbleContainer = styled.div`
	position: relative;
	width: 298px;
	border-radius: 4px;
	background: #fff;
	box-shadow: 0px 0px 32px rgba(103, 116, 150, 0.246971);
	padding: 6px 12px;
	z-index: 9999999;
	text-align: left;

	&:before {
		content: '';
		position: absolute;
		top: 100%;
		left: 50%;
		width: 30px;
		height: 30px;
		border-radius: 4px;
		margin-left: -15px;
		margin-top: -16px;
		background: #fff;
		transform: rotate(45deg);
	}

	&:after {
		content: '';
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 32px;
	}

	${Photo} {
		margin-right: 8px;
	}

	hr {
		margin-left: -12px;
		margin-right: -12px;
		border: none;
		height: 1px;
		background-color: #d6d8e5;
	}
`;

const Line = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 6px 0;
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
`;

const Title = styled.div`
	font-size: 14px;
	line-height: 19px;
`;

const SubTitle = styled.div`
	font-size: 12px;
	line-height: 17px;
	color: #6c6f88;
	opacity: 0.7;
`;

const IndicatorContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
	margin-right: 10px;
	width: 40px;
	height: 40px;
`;

const levelMapper = ['5px', '8px', '12px', '16px'];

const levelText = [
	'Координаты обновлены очень давно',
	'Координаты обновлены давно',
	'Координаты обновлены недавно',
	'Координаты актуальны',
];

const getLevel = (minutes: number) => {
	const levels = [720, 180, 30];

	for (const [index, level] of levels.entries()) {
		if (minutes >= level) {
			return index;
		}
	}

	return 3;
};

const Indicator = styled.div<{ level: string; selected: boolean }>`
	height: ${(props) => props.level};
	border: 1px solid ${(props) => (props?.selected ? '#6c6f88' : 'lightgray')};
	margin: 1px;
`;

const LastSeenContainer = styled.div`
	display: flex;
	justify-content: flex-start;
	padding: 6px 0;
	align-items: baseline;
`;

const LastSeen = ({ lastSeenLevel }: { lastSeenLevel: number }) => {
	return (
		<LastSeenContainer>
			<IndicatorContainer>
				{levelMapper.map((level, index) => (
					<Indicator key={`last-seen-indicator-${level}`} level={level} selected={index <= lastSeenLevel} />
				))}
			</IndicatorContainer>
			<SubTitle>{levelText[lastSeenLevel]}</SubTitle>
		</LastSeenContainer>
	);
};

interface BidMapBubbleProps {
	data?: any;
	onActionFlightsClick?: () => void;
	onActionMessageClick?: () => void;
	onActionRouteClick?: () => void;
	onActionTrackClick?: () => void;
}

const getLastSeen = (timestamp: any) => {
	const today = new Date();
	const lastSeen = new Date(timestamp);
	//@ts-ignore
	return today - lastSeen;
};

const renderDriverInfo = (data: any) => {
	const { kind = '', userProperties = {}, driver, photo, transport } = data;

	const { timestamp = 0 } = userProperties;
	const diffMs = getLastSeen(timestamp * 1000) / 60000;
	const transportType = useSelector(transportTypeByKind(kind));
	const driverPhoto = driver?.photo || ICON_AVATAR;
	const transportPhoto = photo || ICON_NO_DRIVER_PHOTO;
	const isHaveData = !!driver || !!transport?.ident || !!diffMs;

	if (!isHaveData) return null;

	const content: React.ReactNode[] = [];

	if (driver) {
		content.push(
			<Line key='driver-name'>
				<Photo src={driverPhoto} />
				<Info>
					<Title>{driverName(driver)}</Title>
					<SubTitle>{driver?.phone}</SubTitle>
				</Info>
			</Line>,
		);
	}

	if (transport?.ident) {
		content.push(
			<Line key='driver-transport'>
				<Photo src={transportPhoto} />
				<Info>
					<Title>{transport.ident}</Title>
					<SubTitle>{transportType}</SubTitle>
				</Info>
			</Line>,
		);
	}

	if (diffMs) {
		const lastSeenLevel = getLevel(Math.floor(diffMs));

		content.push(<LastSeen key='driver-last-seen' lastSeenLevel={lastSeenLevel} />);
	}

	if (content.length) content.push(<hr key='driver-divider' />);

	return content;
};

const BidMapBubble: React.FC<BidMapBubbleProps> = (props) => {
	const { data = {} } = props;

	const handleRouteClick = () => {
		props?.onActionRouteClick?.();
	};

	const handleMessageClick = () => {
		window.open(`https://wa.me/${data?.driver?.phone}`);
	};

	return (
		<BidMapBubbleContainer>
			{renderDriverInfo(data)}

			<Actions
				actions={[
					{
						icon: require('assets/icons/i-car.png'),
						title: 'Рейсы',
						disabled: true,
						handler: props.onActionFlightsClick,
					},
					{
						icon: require('assets/icons/i-message.png'),
						title: 'Написать',
						handler: handleMessageClick,
						disabled: !data?.driver?.phone,
					},

					{
						icon: require('assets/icons/i-route.png'),
						title: 'Маршрут',
						handler: handleRouteClick,
						disabled: !props.onActionRouteClick,
					},

					{
						icon: require('assets/icons/i-tracking.png'),
						title: 'Трек',
						disabled: true,
						handler: props.onActionTrackClick,
					},
				]}
			/>
		</BidMapBubbleContainer>
	);
};

export default BidMapBubble;
