import React from 'react';

export default class ValidationReporter extends React.Component<ValidationReporter.Props> {
	render() {
		return (
			<div className='validation-reporter'>
				{(this.props.reportList || []).map((message: string, i: number) => (
					<div key={'message-id-' + i} className='validation-reporter__message'>
						{' '}
						{message}{' '}
					</div>
				))}
			</div>
		);
	}
}

namespace ValidationReporter {
	export interface Props {
		reportList: string[];
	}
}
