// import { Dispatch } from 'redux';
// import { Executor, Location as RLocation, VacantExecutor } from 'app/models';
// import { ExecutorApiModel } from 'app/api/types';
// import { executorGetList, vacantExecutorGetList, getDirection } from 'app/api';
// import { createAction } from 'redux-actions';
import { createAction } from 'redux-actions';

export const SET_VACANT_EXECUTORS_LOADING = 'SET_VACANT_EXECUTORS_LOADING';
export const SET_VACANT_EXECUTORS_UPDATING = 'SET_VACANT_EXECUTORS_UPDATING';
export const SET_VACANT_EXECUTORS_LIST = 'SET_VACANT_EXECUTORS_LIST';
export const SET_VACANT_EXECUTOR_DETAIL = 'SET_VACANT_EXECUTOR_DETAIL';
export const ADD_VACANT_EXECUTORS_TO_LIST = 'ADD_VACANT_EXECUTORS_LIST_TO';

export const GET_VACANT_EXECUTORS_LIST = 'GET_VACANT_EXECUTORS_LIST';
export const GET_VACANT_EXECUTORS_LIST_MORE = 'GET_VACANT_EXECUTORS_LIST_MORE';
export const GET_VACANT_EXECUTORS_LIST_NEXT = 'GET_VACANT_EXECUTORS_LIST_NEXT';
export const GET_VACANT_EXECUTORS_LIST_PREV = 'GET_VACANT_EXECUTORS_LIST_PREV';
export const UPDATE_BOUNDS_AND_EXECUTORS = 'UPDATE_BOUNDS_AND_EXECUTORS';

export const setVacantExecutorsLoading = createAction<boolean>(SET_VACANT_EXECUTORS_LOADING);
export const getVacantExecutorsList = createAction<void>(GET_VACANT_EXECUTORS_LIST);
export const getVacantExecutorsListMore = createAction<any>(GET_VACANT_EXECUTORS_LIST_MORE);
export const setVacantExecutorsList = createAction<any>(SET_VACANT_EXECUTORS_LIST);
export const getVacantExecutorsListNext = createAction<void>(GET_VACANT_EXECUTORS_LIST_NEXT);
export const getVacantExecutorsListPrev = createAction<void>(GET_VACANT_EXECUTORS_LIST_PREV);
export const addVacantExecutosToList = createAction<any>(ADD_VACANT_EXECUTORS_TO_LIST);
export const updateBoundsAndExecutors = createAction<any>(UPDATE_BOUNDS_AND_EXECUTORS);
