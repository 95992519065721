import React from 'react';
import styled from 'styled-components';
import { Localized } from '@fluent/react';
import { Icon } from 'app/components/Icons/Icon';

import { Typography } from 'app/sharedComponents/Typography';
const closeIcon = require('../../../../assets/icons/close.svg');

const Title = styled.div`
	padding-left: 20px;
	padding-top: 20px;
	width: 271px;
	height: 51px;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const TenderViewTitle = ({
	onEdit,
	onClose,
	response,
	onBack,
}: {
	onEdit?: () => void;
	onClose: () => void;
	response?: boolean;
	onBack?: () => void;
}) => {
	return (
		<Title>
			{response && <button onClick={onBack}>{'<'}</button>}
			<Typography bold variant='body1'>
				<Localized id={response ? 'tender-header-title-response' : 'tender-header-title'} />
			</Typography>

			{onEdit && !response && (
				<button className='edit' onClick={onEdit}>
					<Icon variant='edit' size={24} />
				</button>
			)}
			<div style={{ cursor: 'pointer' }} onClick={onClose}>
				<img src={closeIcon} alt='' />
			</div>
		</Title>
	);
};
