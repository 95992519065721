import { Transport, UserProfile } from 'app/models';

export const isValidBase = (transport: Transport) =>
	// (transport.trailerIdent && transport.trailerIdent.length > 0) &&
	transport?.ident && transport?.ident?.length > 0;

export const getBasicContact = (profile: UserProfile) => ({
	middleName: profile.middleName,
	firstName: profile.firstName,
	lastName: profile.lastName,
	avatar: profile.avatar,
	phone: profile.phone,
	photo: profile.photo,
	uuid: profile.uuid,
	isAutoCreated: true,
});

export const isProfileFilled = (profile: UserProfile): boolean =>
	!profile?.needActions?.includes('fill_profile') && !profile?.needActions?.includes('company');
