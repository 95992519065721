import styled from 'styled-components';

interface StatsValueProps {
	icon: string;
	title: string;
}

export const Stats = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;

	margin: 23px 0;
`;

export const StatsValue = styled.div`
	margin: 0 16px;
	text-align: center;
	font-size: 14px;
	color: #6c6f88;

	&:before {
		content: '';
		display: inline-block;
		width: 18px;
		height: 14px;
		margin-right: 7px;

		background: url(${(props: StatsValueProps) => props.icon});
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 32px 32px;

		vertical-align: middle;
	}

	&:after {
		font-size: 10px;
		line-height: 14px;
		text-transform: uppercase;
		text-align: center;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: #6c6f88;
		opacity: 0.7;
		margin-top: 12px;
		display: block;
		content: '${(props: StatsValueProps) => props.title}';
	}
`;
