import React from 'react';
import styled from 'styled-components';

const ZoomButton = styled.div`
	width: 40px;
	height: 40px;
	background-color: white;
	border-radius: 50px;
	box-shadow: -2px 3px 5px 0px rgba(151, 149, 149, 0.75);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 29px;
	color: #989aa9;
	margin: 5px;
	cursor: pointer;
	&:hover {
		color: black;
	}

	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
`;

export const ButtonsContainer = styled.div`
	position: absolute;
	top: 40%;
	right: 10px;
	display: flex;
	flex-direction: column;
	z-index: 99999999999;
`;

export const ZoomButtons = ({ onZoomIn, onZoomOut }: any) => {
	return (
		<ButtonsContainer>
			<ZoomButton onClick={onZoomIn}>&#43;</ZoomButton>

			<ZoomButton onClick={onZoomOut}>&#8211;</ZoomButton>
		</ButtonsContainer>
	);
};
