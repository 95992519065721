import React from 'react';

const InfoLabel: React.FC<InfoLabelProps> = function InfoLabel(props) {
	if (!props?.text) return null;

	return (
		<div className='info-label'>
			<div className='title'>{props.title}</div>
			<div className='text'>{props.text}</div>
		</div>
	);
};

interface InfoLabelProps {
	title: string | React.ReactNode;
	text: string | React.ReactNode;
}

export default InfoLabel;
