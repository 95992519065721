import { Executor, Location, VacantExecutor } from 'app/models';
import { handleActions, Action } from 'redux-actions';
import { ExecutorActions } from 'app/actions';
import { RootState } from './state';
import _ from 'lodash';

const initialState: RootState.Executors = {
	showTracksByDefault: false,
	showRoadsByDefault: false,
	loading: false,
	directions: {},
	locations: {},
	executors: [],
	selected: {},
	hovered: {},
	opened: {},
	tracks: {},
	roads: {},

	// Vacant
	vacantExecutors: [],
	vacantLocations: {},
	vacantHovered: {},
	vacantOpened: {},
};

export const executorReducer = handleActions<RootState.Executors, any>(
	{
		[ExecutorActions.Action.EXECUTOR_SET_LOADING]: (state, { payload }: Action<boolean>) => ({
			...state,
			loading: payload ? payload : !state.loading,
		}),

		[ExecutorActions.Action.CLEAR]: (state) => ({
			...state,
			...initialState,
		}),

		[ExecutorActions.Action.UPSERT_LOCATION]: (state, action: Action<ExecutorActions.Payload.Location>) => ({
			...state,
			locations: {
				...state.locations,
				...(!action.payload
					? {}
					: {
							[action.payload.id]: {
								...action.payload.location,
							},
					  }),
			},
		}),

		[ExecutorActions.Action.RESET_ALL_HOVERED]: (state) => ({
			...state,
			hovered: {},
		}),

		[ExecutorActions.Action.RESET_ALL_VACANT_HOVERED]: (state) => ({
			...state,
			vacantHovered: {},
		}),

		[ExecutorActions.Action.UPSERT_VACANT_LOCATION]: (state, action: Action<ExecutorActions.Payload.Location>) => ({
			...state,
			vacantLocations: {
				...state.vacantLocations,
				...(!action.payload
					? {}
					: {
							[action.payload.id]: {
								...action.payload.location,
							},
					  }),
			},
		}),

		[ExecutorActions.Action.UPSERT_DIRECTION]: (state, action: Action<ExecutorActions.Payload.Direction>) => ({
			...state,
			directions: {
				...state.directions,
				...(action.payload
					? {
							[action.payload.id]: action.payload.direction,
					  }
					: {}),
			},
		}),

		[ExecutorActions.Action.SET_VISIBLE_ZONE]: (state, action: Action<number[][]>) => ({
			...state,

			...(!action.payload
				? {}
				: {
						visibleZone: state.visibleZone ? [...action.payload] : action.payload,
				  }),
		}),

		[ExecutorActions.Action.SET_VACANT_VISIBLE_ZONE]: (state, action: Action<number[][]>) => ({
			...state,

			...(!action.payload
				? {}
				: {
						vacantVisibleZone: state.vacantVisibleZone ? [...action.payload] : action.payload,
				  }),
		}),

		[ExecutorActions.Action.ADD_EXECUTOR]: (state, action: Action<Executor | Executor[]>) => {
			const { payload } = action;

			if (payload) {
				const data = payload as any;

				// Format default locations by started point of order
				let locations = _.isArray(data)
					? _.fromPairs(
							data.map(({ bid: { id: bidId, direction }, transport: { id: transportId } }) => [
								`${bidId}:${transportId}`,
								direction.from.location,
							]),
					  )
					: {
							[`${data.bid.id}:${data.transport.id}`]: data.bid.direction.from.location,
					  };

				// Hardcode fix offsets for transport locations
				// For exclude duplicate marker per location
				{
					const offsets: any = {};

					locations = _.mapValues(locations, (value: Location) => {
						if (offsets[`${value.latitude},${value.longitude}`]) {
							const movedLocation = {
								longitude: value.longitude + 0.003,
								latitude: value.latitude + 0.003,
							};

							offsets[`${value.latitude},${value.longitude}`] = true;
							return movedLocation;
						} else {
							offsets[`${value.latitude},${value.longitude}`] = true;
							return value;
						}
					});
				}

				return {
					...state,

					locations: {
						...state.locations,
						...locations,
					},

					executors: [
						...state.executors,
						...(!action.payload ? [] : _.isArray(action.payload) ? action.payload : [action.payload]),
					],
				};
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.ADD_VACANT]: (state, action: Action<VacantExecutor | VacantExecutor[]>) => ({
			...state,

			vacantExecutors: [
				...state.vacantExecutors,
				...(!action.payload ? [] : _.isArray(action.payload) ? action.payload : [action.payload]),
			],
		}),

		[ExecutorActions.Action.TOGGLE_SHOW_FILTER]: (state, action: Action<ExecutorActions.Payload.ToggleShowLine>) => {
			const { payload } = action;

			if (payload) {
				const key = `show${payload.type}ByDefault`;
				const value = payload.value ? payload.value : (state as any)[key];

				return {
					...state,
					[key]: value,
				};
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_SELECTED]: (
			state,
			action: Action<ExecutorActions.Payload.Toggle | ExecutorActions.Payload.Toggle[]>,
		) => {
			const { payload } = action;
			const { selected } = state;

			if (payload) {
				if (_.isArray(payload)) {
					payload.forEach((item) => {
						const { id, value } = item;

						if (!value) {
							delete selected[id];
						} else {
							selected[id] = true;
						}
					});

					return {
						...state,
						selected: {
							...selected,
						},
					};
				} else {
					const { id, value } = payload;

					if (!value) {
						delete selected[id];
					} else {
						selected[id] = true;
					}

					return {
						...state,
						selected: {
							...selected,
						},
					};
				}
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_TRACKS]: (
			state,
			action: Action<ExecutorActions.Payload.Toggle | ExecutorActions.Payload.Toggle[]>,
		) => {
			const { payload } = action;
			const { tracks } = state;

			if (payload) {
				if (_.isArray(payload)) {
					payload.forEach((item) => {
						const { id, value } = item;

						if (!value) {
							delete tracks[id];
						} else {
							tracks[id] = true;
						}
					});

					return {
						...state,
						tracks: {
							...tracks,
						},
					};
				} else {
					const { id, value } = payload;

					if (!value) {
						delete tracks[id];
					} else {
						tracks[id] = true;
					}

					return {
						...state,
						tracks: {
							...tracks,
						},
					};
				}
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_ROADS]: (
			state,
			action: Action<ExecutorActions.Payload.Toggle | ExecutorActions.Payload.Toggle[]>,
		) => {
			const { payload } = action;
			const { roads } = state;

			if (payload) {
				if (_.isArray(payload)) {
					payload.forEach((item) => {
						const { id, value } = item;

						if (!value) {
							delete roads[id];
						} else {
							roads[id] = true;
						}
					});

					return {
						...state,
						roads: {
							...roads,
						},
					};
				} else {
					const { id, value } = payload;

					if (!value) {
						delete roads[id];
					} else {
						roads[id] = true;
					}

					return {
						...state,
						roads: {
							...roads,
						},
					};
				}
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_HOVERED]: (
			state,
			action: Action<ExecutorActions.Payload.Toggle | ExecutorActions.Payload.Toggle[]>,
		) => {
			const { payload } = action;
			const { hovered } = state;

			if (payload) {
				if (_.isArray(payload)) {
					payload.forEach((item) => {
						const { id, value } = item;

						if (!value) {
							delete hovered[id];
						} else {
							hovered[id] = true;
						}
					});

					return {
						...state,
						hovered: {
							...hovered,
						},
					};
				} else {
					const { id, value } = payload;

					if (!value) {
						delete hovered[id];
					} else {
						hovered[id] = true;
					}

					return {
						...state,
						hovered: {
							...hovered,
						},
					};
				}
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_VACANT_HOVERED]: (
			state,
			action: Action<ExecutorActions.Payload.Toggle | ExecutorActions.Payload.Toggle[]>,
		) => {
			const { payload } = action;
			const { vacantHovered } = state;

			if (payload) {
				if (_.isArray(payload)) {
					payload.forEach((item) => {
						const { id, value } = item;

						if (!value) {
							delete vacantHovered[id];
						} else {
							vacantHovered[id] = true;
						}
					});

					return {
						...state,
						vacantHovered: {
							...vacantHovered,
						},
					};
				} else {
					const { id, value } = payload;

					if (!value) {
						delete vacantHovered[id];
					} else {
						vacantHovered[id] = true;
					}

					return {
						...state,
						vacantHovered: {
							...vacantHovered,
						},
					};
				}
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_OPENED]: (state, action: Action<ExecutorActions.Payload.Toggle>) => {
			const { payload } = action;
			const { opened } = state;

			if (payload) {
				const { id, value } = payload;

				if (!value) {
					delete opened[id];
				} else {
					opened[id] = true;
				}

				return {
					...state,
					opened: {
						...opened,
					},
				};
			} else {
				return state;
			}
		},

		[ExecutorActions.Action.TOGGLE_VACANT_OPENED]: (state, action: Action<ExecutorActions.Payload.Toggle>) => {
			const { payload } = action;
			const { vacantOpened } = state;

			if (payload) {
				const { id, value } = payload;

				if (!value) {
					delete vacantOpened[id];
				} else {
					vacantOpened[id] = true;
				}

				return {
					...state,
					vacantOpened: {
						...vacantOpened,
					},
				};
			} else {
				return state;
			}
		},
	},
	initialState,
);
