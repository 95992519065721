import { SchemeProps, BasicTypes } from 'app/containers/Builder/props';

const scheme: SchemeProps.Form = [
	{
		//@ts-ignore
		choices: [
			/* lazy */
		],
		type: BasicTypes.Date,
		label: 'Дата от',
		name: 'min_delivery_from',
		required: false,
		rounded: true,
	},

	{
		//@ts-ignore
		choices: [
			/* lazy */
		],
		type: BasicTypes.Date,
		label: 'Дата до',
		name: 'max_delivery_from',
		required: false,
		rounded: true,
	},

	{
		choices: [
			/* lazy */
		],
		type: BasicTypes.Select,
		label: 'Регион Загрузки',
		name: 'regionLoading',
		required: true,
		rounded: true,
	},

	{
		choices: [
			/* lazy */
		],
		type: BasicTypes.Select,
		label: 'Регион Разгрузки',
		name: 'regionUnloading',
		required: true,
		rounded: true,
	},
	{
		choices: [
			/* lazy */
		],
		type: BasicTypes.MultiSelect,
		label: 'Груз',
		required: true,
		name: 'cargoType',
		rounded: true,
	},
	/*
    {
        type: BasicTypes.Group,
        className: "distances",
        direction: "row",

        contains: [
            {
                type: BasicTypes.Header,
                name: "distances-header",
                label: "Расстояние",
            },

            {
                type: BasicTypes.Range,
                label: "Расстояние",
                name: "distance",
                min: 0, max: 100,
                step: 1,
            }
        ],
    },

    {
        type: BasicTypes.Group,
        className: "prices",
        direction: "row",

        contains: [
            {
                type: BasicTypes.Header,
                name: "distances-price",
                label: "Цена",
            },

            {
                type: BasicTypes.Range,
                label: "Цена",
                name: "price",
                min: 0, max: 100,
                step: 1,
            }
        ],
    },

    {
        type: BasicTypes.Group,
        className: "prices-km",
        direction: "row",

        contains: [
            {
                type: BasicTypes.Header,
                name: "price-km-header",
                label: "Цена/Км.",
            },

            {
                type: BasicTypes.Range,
                label: "Цена км/т",
                name: "priceKm",
                min: 0, max: 100,
                step: 0.1,
            }
        ],
    },

    {
        type: BasicTypes.Group,
        className: "weights",
        direction: "row",

        contains: [
            {
                type: BasicTypes.Header,
                name: "weight-header",
                label: "Вес Тонн",
            },

            {
                type: BasicTypes.Range,
                label: "Вес т.",
                name: "weight",
                min: 0, max: 100,
                step: 1,
            }
        ],
    },
    */
];

export default scheme;
