import { put, takeEvery, select } from 'redux-saga/effects';
import {
	GET_DOCUMENTS_LIST,
	GET_DOCUMENTS_LIST_MORE,
	setDocumentsLoading,
	setDocumentsList,
	setDocumentsListMore,
} from 'app/actions';

import * as api from 'app/api';

function* getDocumentsListNext({ payload }: any): Generator<any, void, any> {
	yield put(setDocumentsLoading(true));
	const next = yield select((state) => state.documents.cursor.next);

	try {
		const response = yield api.v1.get(`/documents`, {
			params: { cursor: next, ...payload },
		});
		const {
			data,
			meta: { links },
		} = response?.data || {}; //TODO: check eslint/no-unsafe-optional-chaining;
		yield put(setDocumentsListMore({ data, cursor: { next: links.next, previous: links.previos } }));
	} catch (error) {
		// yield call(api.apiSentryException, error);
	}
}

export function* getDocumentsList({ payload }: any): Generator<any, void, any> {
	yield put(setDocumentsLoading(true));
	// seems scrollbars has issue - need to clean store to refresh
	yield put(setDocumentsList({ data: [], cursor: { next: null, previous: null } }));
	try {
		const response = yield api.v1.get(`/documents`, {
			params: { ...payload },
		});
		const {
			data,
			meta: { links },
		} = response?.data || {}; //TODO: check eslint/no-unsafe-optional-chaining;
		yield put(setDocumentsList({ data, cursor: { next: links.next, previous: links.previos } }));
	} catch (error) {
		// yield call(api.apiSentryException, error);
	}
}

export function* watchDocuments() {
	yield takeEvery(GET_DOCUMENTS_LIST, getDocumentsList);
	yield takeEvery(GET_DOCUMENTS_LIST_MORE, getDocumentsListNext);
}
