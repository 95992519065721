import * as React from 'react';

import moment from 'moment';
import { FluentBundle } from '@fluent/bundle';
import { Localized } from '@fluent/react';
import DatePicker from 'react-date-picker';

import { store } from 'src/main';
import { getLocalizedMessage } from 'app/locales';
import { SchemeProps, Field } from '../props';

const initialState = () => ({
	date: new Date(),
	validationMessage: '',
});

type Hooks = Field.Hooks<Date>;

export class SelectDate extends React.Component<SelectDate.Props, SelectDate.State> implements Hooks {
	public state: SelectDate.State = initialState();

	private bundlesLocales: FluentBundle[] | undefined;

	constructor(props: SelectDate.Props) {
		super(props);

		this.onChange = this.onChange.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.reset = this.reset.bind(this);

		const {
			onUpdate,
			field: { name, defaultValue },
		} = props;

		if (defaultValue) {
			this.state.date = defaultValue;
			onUpdate(name, moment(defaultValue).format('YYYY-MM-DD'));
		}
		this.bundlesLocales = store.getState().common.bundlesLocales;
	}

	private validate(value: Date) {
		const { rules } = this.props.field;
		const { form } = this.props;

		let validationMessage = '';

		if (rules) {
			this.setState({ validationMessage: '' });

			rules.forEach((check: Function) => {
				const report = check(value, form);

				if (report !== true) {
					validationMessage = getLocalizedMessage(report) || 'Invalid report';
				}
			});

			this.setState({ validationMessage });
		}

		if (validationMessage) {
			this.setState({ validationMessage });

			return validationMessage;
		}

		return true;
	}

	public async updateValue(value: Date | string) {
		const {
			onUpdate,
			field: { name },
		} = this.props;

		const newDate = typeof value === 'string' ? moment(value).toDate() : value;

		await this.setState({ date: newDate });
		onUpdate(name, moment(newDate).format('YYYY-MM-DD'));
	}

	public reset() {
		const { defaultValue } = this.props.field;

		if (defaultValue) {
			this.updateValue(defaultValue);
		}
	}

	private onChange(date: Date) {
		//@ts-ignore
		if (date?._reactName) return;

		const { name } = this.props.field;
		const { onUpdate } = this.props;

		this.setState({
			date,
		});

		this.validate(date);

		onUpdate(name, moment(date).format('YYYY-MM-DD'));
	}

	public render() {
		const { name, label, rounded, localized, disabled } = this.props.field;
		const { date, validationMessage } = this.state;

		const modificators = [];

		if (validationMessage) {
			modificators.push('date--invalid');
		}

		if (rounded) {
			modificators.push('rounded');
		}

		return (
			<div className={'form-item date ' + modificators.join(' ')}>
				<div className='label'>{localized ? <Localized id={label} /> : label}</div>

				<DatePicker
					//@ts-ignore
					onChange={this.onChange}
					disabled={disabled}
					clearIcon={null}
					locale='ru-RU' // @todo: adding dynamic from store
					value={date}
					name={name}
					required
					showLeadingZeros
					// format='dd.MM.yyyy'
				/>

				{<div className='input__message'>{this.state.validationMessage}</div>}
			</div>
		);
	}
}

export namespace SelectDate {
	export interface Props extends Field.ComponentProps<string, SchemeProps.SelectDate> {
		// @default from base
		rules: Function[];
	}

	export interface State {
		date?: Date;
		validationMessage: string;
	}
}
