import * as React from 'react';

//other deps
import { Localized } from '@fluent/react';

//components
import * as Shapes from 'app/components/Shapes';
import FileUpload, { FileWithPreview } from 'app/sharedComponents/FileUpload/Uploader';

//types
import { SchemeProps, Field } from '../props';

//helpers
import { getLocalizedMessage } from 'app/locales';

//----------------------------------------------------------
// ImagePicker
//----------------------------------------------------------
export class ImagePicker extends React.Component<ImagePicker.Props, ImagePicker.State> implements Field.Hooks<any> {
	public state: ImagePicker.State = {};

	constructor(props: ImagePicker.Props) {
		super(props);

		this.updateValue = this.updateValue.bind(this);
		this.onDrop = this.onDrop.bind(this);
		this.reset = this.reset.bind(this);
	}

	public reset() {
		const {
			onUpdate,
			field: { name },
		} = this.props;

		this.setState({ value: undefined });

		if (onUpdate) {
			onUpdate(name, undefined);
		}
	}

	public async updateValue(value: FileWithPreview | string): Promise<void> {
		const newValue = typeof value === 'string' ? { preview: value } : value;

		this.setState({
			value: newValue as any,
		});
	}

	public validate() {
		const { required } = this.props.field;
		const { value } = this.state;
		let validationMessage = '';

		if (this.state.validationMessage !== '') this.setState({ validationMessage: '' });

		if (required && !value) {
			validationMessage = getLocalizedMessage('builder-field-required') || 'Invalid report';
		}

		if (validationMessage) {
			this.setState({ validationMessage });
			return validationMessage;
		}

		return true;
	}

	private onDrop(accepted: FileWithPreview[]) {
		const {
			onUpdate,
			field: { name },
		} = this.props;

		const [value] = accepted;

		this.setState({ value }, () => {
			this.validate();
		});

		if (onUpdate) {
			onUpdate(name, value);
		}
	}
	handleUpload = (value: any) => {
		const {
			onUpdate,
			field: { name },
		} = this.props;

		onUpdate(name, value);

		this.setState({ value }, () => {
			this.validate();
		});

		return Promise.resolve();
	};
	public render() {
		const {
			field: { label },
		} = this.props;

		const { containerSize, validationMessage, value } = this.state;

		// const { onDrop } = this;

		// const props = {
		//     accept: ['image/png', 'image/jpeg'] as any,
		//     className: 'drop-container',
		//     activeClassName: 'active',
		//     onDrop,
		//     name,
		// };

		const onMeasured = (element: any) => {
			if (!containerSize && element) {
				const { height, width } = element.getBoundingClientRect();

				this.setState({
					containerSize: { height, width },
				});
			}
		};

		const classList = ['form-item', 'file-picker'];

		if (validationMessage) {
			classList.push('file-picker--invalid');
		}

		const uploaded = value ? [value] : [];

		return (
			<>
				<FileUpload title={<Localized id={label} />} uploaded={uploaded} onUpload={this.handleUpload} />

				<div ref={onMeasured} style={{ display: 'none' }} className={classList.join(' ')}>
					{containerSize && (
						<Shapes.DashedDropzone height={containerSize.height} width={containerSize.width} color='#D6D8E5' />
					)}

					<div className='input__message'>{this.state.validationMessage}</div>
				</div>
			</>
		);
	}
}

export namespace ImagePicker {
	export interface State {
		value?: FileWithPreview;
		containerSize?: {
			height: number;
			width: number;
		};
		validationMessage?: string;
	}

	export interface Props extends Field.ComponentProps<any, SchemeProps.ImagePicker> {
		// @default from base
	}
}
