import { RouteComponentProps, withRouter } from 'react-router';
import { Dispatch, bindActionCreators } from 'redux';
import { Localized } from '@fluent/react';
import { RootState } from 'app/reducers';
import { connect } from 'react-redux';
import * as React from 'react';
import * as Icons from 'app/components/Icons';

import PageTitle from 'app/components/PageTitle';
import SummaryPrices from './summary';
import AllPrices from './prices';
import Modal from 'app/sharedComponents/Modal';

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({}, dispatch);

class Profile extends React.Component<Profile.Props, Profile.State> {
	public state: Profile.State = {
		tab: Profile.Tab.Prices,
		modalContractState: false,
	};

	constructor(props: Profile.Props) {
		super(props);

		this.getListHeaderList = this.getListHeaderList.bind(this);
		this.getTab = this.getTab.bind(this);
	}

	private getListHeaderList() {
		const currentTab = this.state.tab;

		const items = [
			{ text: 'prices-tab-prices', tab: Profile.Tab.Prices },
			{ text: 'prices-tab-summary', tab: Profile.Tab.Summary },
		];

		return items.map(({ text, tab }, index) => {
			const className = `item ${currentTab === tab ? 'active' : ''}`;
			const onClick = () => this.setState({ tab });
			const props = { className, onClick, key: 'tab-' + (text + index) };

			return (
				<div {...props} key={`prices-header-tab-${index}`}>
					<Localized id={text} />
				</div>
			);
		});
	}

	private getTab() {
		const { tab } = this.state;

		switch (+tab) {
			case Profile.Tab.Prices:
				return <AllPrices onContractClick={this.onContractClick.bind(this)} />;
			case Profile.Tab.Summary:
				return <SummaryPrices onContractClick={this.onContractClick.bind(this)} />;
			default:
				return <div />;
		}
	}

	onContractClick() {
		this.setState({
			modalContractState: true,
		});
	}

	public render() {
		const contractModalProps = {
			width: 600,
			value: [this.state.modalContractState, (modalContractState: boolean) => this.setState({ modalContractState })],
		};

		return (
			<div className='prices page'>
				<PageTitle titleKey='title-prices' />

				<div className='header widget'>
					<div className='container'>
						<div className='title'>
							<Localized id='prices-page-header' />
						</div>
						<div className='actions'>
							<div className='menu'>{this.getListHeaderList()}</div>
						</div>
					</div>
				</div>
				<Modal {...contractModalProps}>
					<Icons.Error size={128} />
					<div className='header-1'>Раздел находится в разработке</div>
				</Modal>

				{this.getTab()}
			</div>
		);
	}
}

export default withRouter(
	connect<Profile.StateProps, Profile.DispatchProps, Profile.ExternalProps>(
		mapStateToProps,
		mapDispatchToProps,
	)(Profile),
);

namespace Profile {
	export type Props = StateProps & DispatchProps & ExternalProps & RouteComponentProps<void>;

	export enum Tab {
		Summary,
		Prices,
	}

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		tab: Tab;
		modalContractState: boolean;
	}
}
