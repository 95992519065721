import { createSliderWithTooltip } from 'rc-slider';
import { SchemeProps, Field } from '../props';
import RCSlider from 'rc-slider';
import * as React from 'react';

const RangeSliderTip = createSliderWithTooltip(RCSlider);

export class Slider extends React.Component<Slider.Props, Slider.State> implements Field.Hooks<number> {
	private sliderRef: any = null;

	public state: Slider.State = {
		value: 0,
	};

	constructor(props: Slider.Props) {
		super(props);

		this.onAfterChange = this.onAfterChange.bind(this);
		this.updateValue = this.updateValue.bind(this);
		this.onChange = this.onChange.bind(this);
		this.reset = this.reset.bind(this);

		const { field, onUpdate } = props;

		// or preset default values
		if (typeof field.defaultValue === 'number') {
			this.state.value = field.defaultValue;
			onUpdate(field.name, field.defaultValue);
		} else {
			this.state.value = field.min;
		}
	}

	public async reset() {
		const { defaultValue, min } = this.props.field;
		const value = defaultValue ? defaultValue : min;

		this.updateValue(value);
	}

	public async updateValue(value: number) {
		await this.setState({ value });
	}

	private async onAfterChange(value: number) {
		const {
			onUpdate,
			field: { name },
		} = this.props;
		onUpdate(name, { value });
		this.setState({ value });
	}

	private async onChange(value: number) {
		await this.setState({ value });
	}

	public render() {
		const { hidden, step, min, max } = this.props.field;

		return hidden ? null : (
			<div className={`form-item slider`}>
				<RangeSliderTip
					onAfterChange={this.onAfterChange}
					defaultValue={this.state.value}
					onChange={this.onChange}
					value={this.state.value}
					step={step}
					max={max}
					min={min}
				/>
				<div className='values'>
					<div className='min'>{min}</div>
					<div className='max'>{max}</div>
				</div>
			</div>
		);
	}
}

export namespace Slider {
	export interface State {
		value: number;
	}

	export interface Props extends Field.ComponentProps<any, SchemeProps.Slider> {
		// @default from base
	}
}
