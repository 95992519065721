import React, { useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';

import { CommonActions } from 'app/actions';
import { SidebarType, Bid } from 'app/models';
import { BidStatus, BidMap, FromTo, Distance } from 'app/components/BidPreview';
import { Button } from 'app/components/Button';
import { getDetailedBid } from 'app/selectors/detailedBid';

import { TenderOfferView } from './components/TenderOfferView';
import { TenderViewTitle } from './components/TenderViewTitle';
import { useTenders } from './useTenders';
import { getCapabilites, TENDER_VIEW_ACTIONS } from './helper';
import { NotFound } from './components/NotFound';

export const Container = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

export const PaddingContainer = styled.div`
	padding-left: ${(props) => props.theme.padding.regular};
	padding-right: ${(props) => props.theme.padding.regular};
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
`;

const ButtonConteiner = styled.div`
	position: absolute;
	bottom: 0px;
	width: 300px;
`;

export const TenderView = () => {
	const dispatch = useDispatch();
	const [offerView, setOfferView] = useState(false);
	const selectedTender: Bid | null = useSelector(getDetailedBid);

	const profile = useSelector((state: any) => state.user.profile);
	const tenderInfo = useTenders(selectedTender, dispatch, profile);

	const {
		currentOffer,
		isTenderOwner,
		// status,
		updateCurrentOffer,
		// userOffer,
		addUpdateOffer,
		approveTender,
		denyTender,
		deleteOffer,
	} = tenderInfo;

	const handleOfferChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		updateCurrentOffer({ [e.target.name]: e.target.value });
	};

	const handleClose = () => dispatch(CommonActions.toggleSidebar({ type: SidebarType.TenderView, value: false }));
	const handleEdit = () => dispatch(CommonActions.toggleSidebar({ type: SidebarType.OrderEdit, value: true }));

	const actions = selectedTender ? getCapabilites(selectedTender, offerView) : [];
	// .map(el =>
	//     el.action === TENDER_VIEW_ACTIONS.UPDATE_OFFER && currentOffer?.rate
	//         ? el
	//         : { action: TENDER_VIEW_ACTIONS.ADD_OFFER, text: 'Создать оферту', variant: 'primary' },
	// )

	const actionsMapper = {
		[TENDER_VIEW_ACTIONS.DENY_TENDER]: () => denyTender(),
		[TENDER_VIEW_ACTIONS.APPROVE_TENDER]: () => approveTender(true),
		[TENDER_VIEW_ACTIONS.UPDATE_OFFER]: () => addUpdateOffer(true),
		[TENDER_VIEW_ACTIONS.ADD_OFFER]: () => addUpdateOffer(),
		[TENDER_VIEW_ACTIONS.SHOW_OFFER]: () => setOfferView(true),
	};
	const isEditAllowed = isTenderOwner && selectedTender?.capabilities.includes('edit');

	if (!selectedTender) {
		return (
			<Container>
				<Content>
					<TenderViewTitle onClose={handleClose} />
					<NotFound />
				</Content>
			</Container>
		);
	}
	return (
		<Container>
			<Content>
				<TenderViewTitle
					onEdit={isEditAllowed ? handleEdit : undefined}
					onClose={handleClose}
					response={offerView}
					onBack={() => setOfferView(false)}
				/>
				{offerView && (
					<TenderOfferView
						// userOffer={userOffer}
						tenderOwner={isTenderOwner}
						bid={selectedTender}
						onDeleteOffer={deleteOffer}
						onChange={handleOfferChange}
						offer={currentOffer}
					/>
				)}
				{!offerView && (
					<>
						<BidMap bid={selectedTender} />
						<BidStatus bid={selectedTender} />
						<PaddingContainer>
							<FromTo bid={selectedTender} />
						</PaddingContainer>
						<Distance bid={selectedTender} />
					</>
				)}
			</Content>

			<ButtonConteiner>
				<div style={{ display: 'flex' }}>
					{actions.map(({ variant, text, action }: any) => (
						//@ts-ignore
						<Button
							key={text}
							variant={actions.length > 1 ? variant : 'primary'}
							//@ts-ignore
							onClick={actionsMapper[action]}
						>
							{text}
						</Button>
					))}
				</div>
			</ButtonConteiner>
		</Container>
	);
};
