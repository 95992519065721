export const TENDER_STATUS = {
	ACTIVE: 'active',
	NOT_STARTED: 'not_started',
	FINISHED: 'finished',
};

export type TenderStatusType = (typeof TENDER_STATUS)[keyof typeof TENDER_STATUS];

export const valueMapper = {
	[TENDER_STATUS.ACTIVE]: { label: 'Активный', color: '#acf1d4' },
	[TENDER_STATUS.FINISHED]: { label: 'Завершен', color: '#F3F3F6' },
	[TENDER_STATUS.NOT_STARTED]: { label: 'Ожидание', color: '#efe887' },
};
