import { AxiosError } from 'axios';
import { toast } from 'react-toastify';

export const parseErrorMessages = (error: AxiosError) => {
	const errorMessages: RestAPIErrorMessages = error?.response?.data.errors;

	if (!errorMessages) {
		return;
	}

	const showError = (message: string) => {
		toast.error(message, {
			autoClose: 3000,
			position: toast.POSITION.BOTTOM_CENTER,
		});
	};
	const { fields = {}, non_fields = [], summary } = errorMessages;

	if (Object.keys(fields).length > 0) {
		window.dispatchEvent(new CustomEvent('vz:apierror', { detail: fields }));
	}

	const parseNonFieldsErrors = () => {
		non_fields.forEach(showError);
	};

	const parseSummaryMessages = () => {
		summary.split('\n').forEach(showError);
	};

	parseNonFieldsErrors();
	parseSummaryMessages();
};
interface FieldMessages {
	[fieldName: string]: string[];
}
export interface RestAPIErrorMessages {
	non_fields: string[];

	fields: {
		[fieldName: string]: string[] | FieldMessages;
	};

	summary: string;
}
