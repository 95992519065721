/**
 * Глобальное инициализированное состояние приложения
 * @description
 * Подробнее в задаче: https://track.vz.wuza.ru/issue/FRONT-428
 * @type { GlobalInitialState }
 */
const globalInitialState = (<any>window)['GLOBAL_INITIAL_STATE'] as GlobalInitialState;

interface GlobalInitialState {
	/**
	 * Название сайта
	 */
	sitename: string;
	/**
	 * Ссылка на логотип
	 */
	logoUrl: string;

	/**
	 * Доменное имя сайта
	 */
	hostname: string;

	brand: string;
}

export { globalInitialState };
