import React from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { Document as DocumentIcon, Check as CheckIcon } from 'app/components/Icons';
import Spinner from 'react-loader-spinner';

import { DocumentType } from './types';
import { DocTitle } from './DocTitle';

const DocContainer = styled.div<{ selected?: boolean }>`
	display: flex;
	justify-content: flex-start;
	padding: 0px 26px;
	height: 94px;
	min-width: 320px;

	align-items: center;
	margin-bottom: 8px;
	border-radius: 8px;
	cursor: pointer;
	color: ${(props) => (props?.selected ? 'white' : 'black')};
	background-color: ${(props) => (props?.selected ? '#5FC8ED' : 'white')};
`;

const IconContainer = styled.div<{ selected?: boolean }>`
	background-color: ${(props) => (props?.selected ? '#8CD2F1;' : '#f2f7f9')};
	width: 40px;
	height: 40px;
	align-items: center;
	display: flex;
	justify-content: center;
	border-radius: 50px;
	margin-right: 12px;
`;

export const PaddingContainer = styled.div`
	/* padding: 20px; */
	box-sizing: border-box;
`;

const CheckIconContainer = styled.div`
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #8cd2f1;
	opacity: 0.8;
	width: 40px;
	height: 40px;
	border-radius: 50px;
`;

const DocumentItem = ({ item = {}, onClick, selected, checked, onCheck }: any) => {
	const handleClick = () => onClick(item);
	const handleOnCheck = () => onCheck(item);
	return (
		<DocContainer selected={selected} className='docs-container'>
			<IconContainer selected={selected} onClick={handleOnCheck}>
				<DocumentIcon />

				{checked && (
					<CheckIconContainer>
						<div className='check-iocn'>
							<CheckIcon color='white' />
						</div>
					</CheckIconContainer>
				)}
			</IconContainer>

			<DocTitle item={item} onClick={handleClick} />
		</DocContainer>
	);
};

export const DocumentsList = ({
	list = [],
	onDocClick,
	selectedItem,
	onScroll,
	loading,
	selectedDocuments,
	onSelect,
}: any) => {
	return (
		<Scrollbars onScroll={onScroll}>
			<PaddingContainer>
				{list.map((item: DocumentType) => (
					<DocumentItem
						checked={selectedDocuments.includes(item.uuid)}
						selected={selectedItem?.uuid === item?.uuid}
						onCheck={onSelect}
						onClick={onDocClick}
						key={item.uuid}
						item={item}
					/>
				))}
				{loading && (
					<DocContainer>
						<Spinner type='RevolvingDot' color='#1FB0E2' height={50} width={50} />
					</DocContainer>
				)}
			</PaddingContainer>
		</Scrollbars>
	);
};
