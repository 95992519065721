import React from 'react';
import { Localized } from '@fluent/react';
import { Bid, BidDetails } from 'app/models';
import styled from 'styled-components';
import { Typography } from 'app/sharedComponents/Typography';
// const fromIcon = require('../../../../assets/icons/from.png');
const fromIcon = require('../../../assets/icons/from.png');
const toIcon = require('../../../assets/icons/to.png');

const Container = styled.div`
	flex-direction: column;
	padding: 10 px 16 px;
	display: flex;
`;

const Title = styled.div`
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	display: flex;
	text-transform: uppercase;
	letter-spacing: 0;
	font-weight: 800;
	font-size: 10px;
	color: ${(props) => props.theme.colors.fontBlue};
	align-items: center;
	margin-top: 6px;
`;

const TypographyWrapper = styled.div`
	margin-top: 5px;
`;

const Img = styled.img`
	margin-right: 8px;
	height: 10px;
	width: auto;
`;

const FromToContainer = styled.div`
	margin-top: 6px;
`;

const DirectionItem = ({ direction, title, img }: { direction: any; title: string; img: any }) => (
	<Container className='from'>
		<Title>
			<Img src={img} alt='' />
			<div className='text'>
				<Localized id={title} />
			</div>
		</Title>
		<TypographyWrapper>
			<Typography variant='body2' bold>
				{direction.name}
			</Typography>
		</TypographyWrapper>
		<TypographyWrapper>
			<Typography variant='body3'>{direction.address.replace(direction.name + ',', '')}</Typography>
		</TypographyWrapper>
	</Container>
);

export const FromTo = ({ bid }: { bid: Bid | undefined | BidDetails }) => {
	if (!bid?.direction) return null;
	return (
		<FromToContainer>
			<DirectionItem direction={bid.direction.from} title={'details-label-from'} img={fromIcon} />
			<DirectionItem direction={bid.direction.to} title={'details-label-to'} img={toIcon} />
		</FromToContainer>
	);
};
