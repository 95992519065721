import React from 'react';

export const Mail: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='18' height='14' viewBox='0 0 18 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M1 1V12.5C1 12.7761 1.22386 13 1.5 13H16.5C16.7761 13 17 12.7761 17 12.5V1M1 1H17M1 1L7.8 6.1C8.51111 6.63333 9.48889 6.63333 10.2 6.1L17 1'
			stroke={color}
			strokeWidth='1.5'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
	</svg>
);

export default Mail;
