import React from 'react';

export const Approve: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='32' height='32' viewBox='4 8 32 32' fill='none'>
		<rect x='7' y='9' width='18' height='14' rx='7' fill={color} />
		<path
			d='M14.3519 19.1105L13.6374 19.8102L14.3519 20.5398L15.0664 19.8102L14.3519 19.1105ZM10.842 16.9553L13.6374 19.8102L15.0664 18.4109L12.271 15.556L10.842 16.9553ZM15.0664 19.8102L21.1581 13.5892L19.7291 12.1899L13.6374 18.4109L15.0664 19.8102Z'
			fill='white'
		/>
	</svg>
);

export default Approve;
