import { BidDetails, SidebarType } from 'app/models';
import { Action, handleActions } from 'redux-actions';
import { RootState } from 'app/reducers/state';
import { CommonActions } from 'app/actions';
import { store } from '../../main';
import { delay } from 'bluebird';

// Auto get cargo types if user is logged in
async function defaultPrepareCommon() {
	await delay(150);

	try {
		const { user } = store.getState();

		if (user.sessionToken && user.profile) {
			await store.dispatch(CommonActions.getCommonMeta() as any);
			await store.dispatch(CommonActions.getRegions() as any);
		}
	} catch (error) {
		// @PASS
	}
}

defaultPrepareCommon();

export const initialState: RootState.Common = {
	transports: {},
	trailers: {},
	products: {},
	loadings: [],
	regions: [],
	cargos: [],
	prices: [],
	brands: [],

	bundlesLocales: undefined,
	currentLocales: ['en'],
	userLocales: ['en'],

	title: 'Home',

	openedSidebar: [SidebarType.OrderCreate, false, undefined],
	shippingFare: [],
	tariff: [],
};

type ActionToggleSidebar = Action<CommonActions.Payload.ToggleSidebar>;
type ActionExtend = Action<CommonActions.Payload.Extend>;

export const commonReducer = handleActions<RootState.Common, any>(
	{
		[CommonActions.Action.CHANGE_LOCALES]: (state, { payload }: Action<CommonActions.Payload.Locales>) => ({
			...state,
			...(!payload ? {} : payload),
		}),

		[CommonActions.Action.SET_COMMON_SIDEBAR_OPENED]: (state, { payload }: ActionToggleSidebar) => {
			if (payload) {
				const type = payload.type === SidebarType.Current ? state.openedSidebar[0] : payload.type;
				const value = typeof payload.value === 'boolean' ? payload.value : !state.openedSidebar[1];

				return {
					...state,
					openedSidebar: [type, value, payload.params],
				};
			} else {
				return {
					...state,
				};
			}
		},

		[CommonActions.Action.SET_COMMON_DETAILS_ITEM]: (state, { payload }: Action<BidDetails | 'NOT_FOUND'>) => ({
			...state,

			...(payload
				? {
						detailsBid: payload,
				  }
				: {
						detailsBid: undefined,
				  }),
		}),

		[CommonActions.Action.EXTEND_COMMON]: (state, { payload }: ActionExtend) => ({
			...state,
			...(payload ? payload : {}),
		}),
	},
	initialState,
);
