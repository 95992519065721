import React, { useState, useEffect, useCallback, memo } from 'react';

//other deps
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import Scrollbars from 'react-custom-scrollbars';
import { useInfiniteScroll } from 'react-infinite-scroll-hook';

//redux
import { useSelector, useDispatch } from 'react-redux';
import { getVacantExecutorsListNext, updateBoundsAndExecutors, addTransportInfoArray } from 'app/actions';
import { transport as transportSelector, vacantTransport as vacantTransportSelector } from 'app/selectors';

//components
import { GMap } from 'app/components/GMap';

import {
	BidMapBubble,
	CandidateData,
	Content,
	LeftPanel,
	MapContainer,
	MapZone,
	OfferCard,
	PaddingContainer,
	TransportsWebSocket,
} from './components';

const ListItem = styled.li`
	display: flex;
	justify-content: center;
`;

const PContainer = PaddingContainer as any;

//----------------------------------------------------------
// FreeTransport
//----------------------------------------------------------
export const FreeTransport: React.FC = () => {
	//helpers
	const dispatch = useDispatch();

	//state
	const [zoomToPoint, setZoomToPoint] = useState(undefined);
	const [chosenTransport, setChosenTransport] = useState({});
	const [selectedTransport, setSelectedTransport] = useState<any>({});

	//selectors
	const bounds = useSelector((state: any) => state?.transportOnMap.bounds);
	const filter = useSelector((state: any) => state?.transportOnMap.filter);

	const transports = useSelector(transportSelector('free')).map((item: any) => ({
		id: item.transportId,
		location: { lat: item?.position?.latitude, lng: item?.position?.longitude },
		data: { ...item },
	}));

	const { data: vacantTransport, loading, next: hasNextPage } = useSelector(vacantTransportSelector);

	//callbacks
	const handleMarkerSelect = (id: string) => {
		setSelectedTransport({ [id]: !selectedTransport[id] });
	};

	const handleMarkerClick = (id: string, data: any) => {
		//@ts-ignore
		setChosenTransport({ ...chosenTransport, [data?.transportId]: !chosenTransport[data?.transportId] });
	};

	const handleLoadMore = () => {
		dispatch(getVacantExecutorsListNext());
	};

	const handleOfferCardExpand = (transportId: any, expanded: boolean) => {
		if (!transportId) return;

		setSelectedTransport({ ...selectedTransport, [transportId]: !selectedTransport[transportId] });
		const selectedWSTransport = transports.find((item: any) => item.id === transportId);

		if (selectedWSTransport && expanded) {
			setZoomToPoint(selectedWSTransport.location);
		}
	};

	const _onGetMessages = useCallback((messages: any[]) => {
		dispatch(addTransportInfoArray(messages));
	}, []);

	//effects
	useEffect(() => {
		dispatch(updateBoundsAndExecutors(bounds));
	}, [filter, bounds]);

	//renders
	const infiniteRef = useInfiniteScroll<HTMLUListElement>({
		loading,
		hasNextPage,
		onLoadMore: handleLoadMore,
		scrollContainer: 'parent',
	});

	return (
		<>
			<TransportsWebSocket topic='free' onGetMessages={_onGetMessages} />

			<Content>
				<MapZone>
					<div>
						<LeftPanel className='nopadding' style={{ height: '100%', overflow: 'auto' }}>
							<Scrollbars>
								<PContainer ref={infiniteRef}>
									{vacantTransport.map((item: any) => {
										const offerData = {
											transports: [{ contact: item.contact, transport: item.transport }],
										} as CandidateData;

										return (
											<OfferCard
												showDriverFirst
												noSelect
												//@ts-ignore
												choosen={chosenTransport[item?.transport?.id]}
												selected={selectedTransport[item.transport.id]}
												onExpand={handleOfferCardExpand}
												data={offerData}
												key={item.id}
											/>
										);
									})}

									{!vacantTransport.length && <ListItem>Нет данных</ListItem>}

									{loading && (
										<ListItem>
											<Loader color='#2196F3' height={34} width={34} type='Oval' />
										</ListItem>
									)}
								</PContainer>
							</Scrollbars>
						</LeftPanel>

						<MapContainer>
							<GMap
								showMapMoveSync
								showZoomButtons
								markers={transports}
								onMarkerSelect={handleMarkerSelect}
								onMarkerClick={handleMarkerClick}
								zoomToPoint={zoomToPoint}
								hintComponent={BidMapBubble}
								highlightedMarkers={selectedTransport}
								prevBounds={bounds}
							/>
						</MapContainer>
					</div>
				</MapZone>
			</Content>
		</>
	);
};

export default memo(FreeTransport);
