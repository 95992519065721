/**
 * Метод для валидации полей объекта по схеме.
 * Метод проверяет поля, которые имеют свойство rules
 *
 * @param object - проверяемый объект
 * @param scheme - схема
 */

const validateByScheme = (object: any, scheme: any[]) =>
	!scheme.find(({ name, rules, required }) => {
		if (!name) {
			return false;
		}

		if (required) {
			return !object[name];
		}

		if (rules) {
			return rules.find((validate: (v: any) => any) => {
				return validate(object[name]) !== true;
			});
		}

		return false;
	});

export default validateByScheme;
