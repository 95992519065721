import React from 'react';
import { Localized } from '@fluent/react';
import Checkbox from 'app/sharedComponents/Checkbox';
import styled from 'styled-components';

const LocalizedOptionContainer = styled.div`
	display: flex;
	padding: 5px 10px;
	cursor: pointer;
`;

const StyledLocalizedOption = styled.div<{ selected: boolean }>`
	margin-left: 5px;
	color: ${(props) => (props.selected ? '#64C8EB' : '#000000')};
`;

export const LocalizedOption = ({ localized, disableLocalizeForOption, selected, ...props }: any) => {
	const children = localized && !disableLocalizeForOption ? <Localized id={props.children} /> : props.children;

	const isSelected = selected.some((item: any) => item.value === props.value);

	const handleClick = () => props.selectOption({ value: props.value, label: props.label });

	return (
		<LocalizedOptionContainer onClick={handleClick}>
			<Checkbox checked={isSelected} />
			<StyledLocalizedOption selected={isSelected}> {children}</StyledLocalizedOption>
		</LocalizedOptionContainer>
	);
};

const StyledValueContainer = styled.div`
	display: flex;
	padding: 5px;
	align-items: center;
`;

export const ValueContainer = ({ children, ...props }: any) => {
	const { selectedItem } = props;

	const cutSelected = selectedItem.length > 2;
	const childrenArr = React.Children.toArray(children);
	const updatedChildren = cutSelected
		? [...childrenArr.slice(0, 2), childrenArr[childrenArr.length - 1]]
		: React.Children.toArray(children);
	return (
		<StyledValueContainer>
			{updatedChildren}
			{selectedItem.length > 2 && <span> + {selectedItem.length - 2} выбрано</span>}
		</StyledValueContainer>
	);
};
