import React from 'react';

export const FullScreen: React.FC<{ color?: string }> = ({ color = '#6C6F88' }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none'>
		<path d='M4 9V6C4 4.89543 4.89543 4 6 4H9' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
		<path d='M4 15V18C4 19.1046 4.89543 20 6 20H9' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
		<path d='M20 9V6C20 4.89543 19.1046 4 18 4H15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
		<path d='M20 15V18C20 19.1046 19.1046 20 18 20H15' stroke={color} strokeWidth='1.5' strokeLinecap='round' />
	</svg>
);

export default FullScreen;
