import { Buyer, PriceProduct, SidebarType, RoleEnum, GrainCropData } from 'app/models';
import { pricesGetGrainCrops, pricesGetByCropId, prepareResponse } from 'app/api';
import { SchemeProps } from 'app/containers/Builder/props';
import { Dispatch, bindActionCreators } from 'redux';
import { CommonActions } from 'app/actions';
import { Localized } from '@fluent/react';
import { RootState } from 'app/reducers';
import { summaryPrice } from './schemes';
import { connect } from 'react-redux';
import * as React from 'react';
import _ from 'lodash';

// Components
import Spinner from 'react-loader-spinner';
import TableCustom from 'app/components/TableCustom';
import Builder from 'app/containers/Builder';

import * as Icons from 'app/components/Icons';
import { FluentBundle } from '@fluent/bundle';
import { getLocalizedMessage } from 'app/locales';
import { TabLayout, TabLayoutContent, TabLayoutSidebar, TabLayoutCenterContent } from 'app/commonPatterns/TabLayout';

const iconAvatarDriver = require('../../../assets/icons/avatar_driver.jpg');

const mapStateToProps = ({ user, common }: RootState) => ({
	sessionToken: user.sessionToken,
	products: common.products,
	role: user.profile && user.profile.role,
	locales: common.bundlesLocales,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			toggleSidebar: CommonActions.toggleSidebar,
		},
		dispatch,
	);

class SummaryPrices extends React.Component<SummaryPrices.Props, SummaryPrices.State> {
	public form: Builder | null = null;
	public state: SummaryPrices.State = {
		schemeBase: summaryPrice,
		grainCrops: [],
		loading: true,
		priceList: [],
		form: {},
		modalContractState: false,
	};

	constructor(props: SummaryPrices.Props) {
		super(props);
		this.fetchGrainCrops = this.fetchGrainCrops.bind(this);
		this.initializeForm = this.initializeForm.bind(this);
		this.getPriceList = this.getPriceList.bind(this);
		this.isFormValid = this.isFormValid.bind(this);
		this.renderTable = this.renderTable.bind(this);
		this.onUpdate = this.onUpdate.bind(this);
	}

	private async fetchGrainCrops(): Promise<void> {
		const setGrainCropsList = (grainCrops: GrainCropData[]) => {
			this.setState(
				{
					grainCrops,
				},
				() => {
					this.initializeForm();
				},
			);
		};

		const saveGrainCropsToStorage = (grainCrops: GrainCropData[]) => {
			localStorage.setItem('v-grain-crops', JSON.stringify(grainCrops));
			localStorage.setItem('v-grain-crops-expires', Date.now() + (8.64e7).toString());
		};

		const removeGrainCropsFromStorage = () => {
			localStorage.removeItem('v-grain-crops');
			localStorage.removeItem('v-grain-crops-expires');
		};

		const grainCrops: string | null = localStorage.getItem('v-grain-crops');
		const grainCropsExpires: number | null = parseInt(localStorage.getItem('v-grain-crops-expires') || '');
		const isGrainCropsListExpired = grainCropsExpires < Date.now();

		if (!grainCrops) {
			const grainCrops = await pricesGetGrainCrops();
			setGrainCropsList(grainCrops);
			saveGrainCropsToStorage(grainCrops);
		} else {
			if (isGrainCropsListExpired) {
				removeGrainCropsFromStorage();
				return await this.fetchGrainCrops();
			}
			setGrainCropsList(JSON.parse(grainCrops) as GrainCropData[]);
		}
	}

	private initializeForm() {
		const { grainCrops } = this.state;

		if (grainCrops && !_.isEmpty(grainCrops)) {
			const schemeBase: any = _.cloneDeep(summaryPrice);

			schemeBase[0].choices = grainCrops.map((grainCrop: GrainCropData) => ({
				label: grainCrop.name,
				value: grainCrop.uuid,
			}));

			this.setState({
				schemeBase,
			});
		}
	}

	public componentDidMount() {
		this.fetchGrainCrops().then(() => {
			this.setState({
				loading: false,
			});
		});

		// First request of price list
		this.getPriceList();
	}

	private onUpdate(name: string, value: any) {
		const { form } = this.state;

		const updated = {
			form: {
				...form,
				[name]: value,
			},
		};

		this.setState(updated);
	}

	private isFormValid() {
		const { from, cargo } = this.state.form;

		return !!from && !!cargo;
	}

	private async getPriceList() {
		const { from, cargo: cropId = null } = this.state.form;
		let address = null;

		if (from) {
			address = from.address;
		}
		this.setState({ loading: true });

		let priceList = await pricesGetByCropId({ crop: cropId, address });
		priceList = priceList.map((obj: any) => prepareResponse(obj));

		this.setState(() => ({ priceList, loading: false }));
	}

	private renderTable() {
		const { priceList } = this.state;
		const fetched = (priceList[0] as any).fetched * 1000;
		const { role } = this.props;

		const tableProps = {
			data: priceList,
			className: 'table-overlay--auto',
			head: [
				{
					title: getLocalizedMessage('prices-row-header-logo'),
					width: 100,
					key: 'buyerLogo',
					render(value: any) {
						return <img src={value || iconAvatarDriver} width='50' height='50' />;
					},
				},
				{
					title: getLocalizedMessage('prices-row-header-buyer'),
					width: 200,
					key: 'buyerName',
				},
				{
					title: getLocalizedMessage('prices-row-header-product-name'),
					key: 'productName',
				},
				{
					title: getLocalizedMessage('prices-row-header-product-class'),
					width: 80,
					key: 'productClass',
					sortable: true,
					render(value: any) {
						return value || '-';
					},
				},
				{
					title: getLocalizedMessage('prices-row-header-protein'),
					width: 80,
					key: 'productProtein',
					sortable: true,
					render(value: any) {
						return value || '-';
					},
				},
				{
					title: getLocalizedMessage('prices-row-header-price'),
					width: 100,
					key: 'buyerPrice',
					sortable: true,
					render(value: any) {
						return value || '-';
					},
				},
				{
					title: getLocalizedMessage('prices-row-header-basis'),
					width: 200,
					key: 'basisName',
				},
				{
					key: '',
					render: (value: any, props: any) => {
						const buyers = priceList ? priceList[props.index] : undefined;
						const { toggleSidebar, role } = this.props;
						const { form } = this.state;
						const onOrderClick = async () => {
							if (buyers && toggleSidebar && role != 'driver') {
								const params = {
									rate: buyers.transportTotal,
									product: buyers.productName,
									distance: buyers.distance,
									productId: buyers.cropId,
									to: buyers.basisAddress,
									from: form.from,
								};

								toggleSidebar({
									type: SidebarType.OrderCreate,
									value: true,
									params,
								});
							}
						};

						return [
							<button key='make-order' className={'table__action make-order'} onClick={onOrderClick}>
								<Icons.ExecutorCar color='#6C6F88' />
								<div className='label'>
									<Localized id='prices-row-order-label' />
								</div>
							</button>,
							<button
								key='show-contract'
								className={'table__action show-contract'}
								onClick={() => this.props.onContractClick?.()}
							>
								<Icons.Contract />
								<div className='label'>
									<Localized id='prices-row-contract-label' />
								</div>
							</button>,
						];
					},
					condition: role !== 'driver',
				},
			],
		};
		return (
			<TabLayoutContent>
				<div className='table-container'>
					<div className='header'>
						<div className='title'>
							<Localized id='prices-summary-header' /> {new Date(fetched).toLocaleDateString()}
						</div>
						<div className='subtitle'>
							<Localized id='prices-content-subheader' />
						</div>
					</div>
					<TabLayoutContent>
						<TableCustom {...tableProps} />
					</TabLayoutContent>
				</div>
			</TabLayoutContent>
		);
	}

	public render() {
		const { schemeBase, loading, priceList } = this.state;
		const builderProps = {
			ref: (node: Builder | null) => {
				this.form = node;
			},
			scheme: schemeBase || [],
			onUpdate: this.onUpdate,
		};
		// const isEmpty = priceList.length < 1 ? 'empty' : '';
		// const isSubmitDisabled = !this.isFormValid() ? 'disabled' : '';
		return (
			<TabLayout>
				<TabLayoutSidebar className='options'>
					<div className='header'>
						<Localized id='prices-summary-sidebar-header' />
					</div>

					<Builder {...builderProps} />

					<button disabled={loading} className={'action'} onClick={this.getPriceList}>
						{!loading ? 'Поиск' : <Spinner type='RevolvingDot' color='#000000' height={22} width={22} />}
					</button>
				</TabLayoutSidebar>
				<TabLayoutContent>
					<div>
						{priceList.length > 0 ? (
							this.renderTable()
						) : (
							<TabLayoutCenterContent>
								<Icons.Notebook />
								<div className='title'>
									<Localized id='prices-summary-empty' />
								</div>
							</TabLayoutCenterContent>
						)}
					</div>
				</TabLayoutContent>
			</TabLayout>
		);
	}
}

export default connect<SummaryPrices.StateProps, SummaryPrices.DispatchProps, SummaryPrices.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(SummaryPrices);
namespace SummaryPrices {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		sessionToken?: string;
		products?: { [id: string]: PriceProduct };
		role?: RoleEnum;
		locales?: FluentBundle[];
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {
		onContractClick: (contractData?: any) => void;
	}

	// Main component state
	export interface State {
		schemeBase?: SchemeProps.Union[];
		grainCrops: GrainCropData[];
		loading: boolean;
		priceList: Buyer[];
		form: any;
		modalContractState: boolean;
	}
}
