import React from 'react';
import { getLocalizedMessage } from 'app/locales';
// const ICON_AVATAR_DRIVER = require('../../../assets/icons/avatar_driver.jpg');
export const types = { ROUTE: 'route', PRICES: 'prices' };
/* eslint-disable react/display-name */
import {
	PriceCell,
	BuyerCell,
	RegionCell,
	CargoCell,
	PriceWithDistance,
	OrderCell,
	NoAddressHeader,
	DotsCell,
} from './tableCells';

const PriceCellWrapper = (props: any) => {
	const {
		cell: {
			value,
			row: { original: row },
		},
	} = props;
	// return <h1>custom cell</h1>;
	return <PriceCell price={value} row={row} />;
};

const BuyerCellWrapper = (props: any) => {
	const {
		cell: {
			value,
			row: { original: row },
		},
	} = props;
	return <BuyerCell buyerName={value} logo={row?.buyerLogo} />;
};

const PricesWithDistanceWrapper = (props: any) => {
	const {
		cell: {
			value,
			row: { original: row },
		},
	} = props;
	return <PriceWithDistance value={value} row={row} />;
};

export const getTableProps = ({ prices, typeToShow, from, role }: any) => ({
	data: prices,
	className: 'table-overlay--auto',
	head: [
		{
			title: getLocalizedMessage('prices-row-header-buyer'),
			width: 240,
			key: 'buyerName', //all
			Cell: BuyerCellWrapper,
			render: (value: any, row: any) => <BuyerCell buyerName={value} logo={row?.buyerLogo} />,
		},
		{
			title: getLocalizedMessage('prices-row-header-location'),
			width: 240,
			sortable: true,
			key: 'basisName', //all
			render: (value: any, row: any) => <RegionCell value={value} row={row} />,
		},

		{
			title: getLocalizedMessage('prices-row-header-cargo'),
			width: 100,
			key: 'productName', //all
			render: (value: any, row: any) => <CargoCell value={value} row={row} />,
		},

		{
			title: getLocalizedMessage('prices-row-header-crt'),
			width: 75,
			key: 'buyerPrice',
			// disableSortBy: true,
			sortable: true,
			show: types.PRICES,
			breakWords: true,
			Cell: PriceCellWrapper,
			render: (value: any, row: any) => <PriceCell price={value} row={row} />,
		},
		{
			title: getLocalizedMessage('prices-row-header-crt'),
			width: 200,
			breakWords: true,
			key: 'buyerPrice',
			// disableSortBy: true,
			// sortable: true,
			show: types.ROUTE,
		},
		{
			title: getLocalizedMessage('prices-row-header-price-and-distance'),
			width: 200,
			key: 'transportTotal',
			breakWords: true,
			// sortable: true,
			show: types.ROUTE,
			Cell: PricesWithDistanceWrapper,
			render: (value: any, row: any) => <PriceWithDistance value={value} row={row} />,
		},
		{
			title: getLocalizedMessage('prices-row-header-fca'),
			width: 100,
			breakWords: true,
			key: 'fcaPrice', //all
			show: types.ROUTE,
			// sortable: true,
			Cell: PriceCellWrapper,
			render: (value: any, row: any) => <PriceCell price={value} row={row} />,
			// render: (value: any) => value || '-',
		},
		{
			key: 'fcaPrice',
			width: 300,
			breakWords: true,
			// sortable: true,
			show: types.PRICES,
			headerRender: () => <NoAddressHeader />,
			Header: NoAddressHeader,
			Cell: () => null,
			// render: () => <div style={{ width: '400px', border: '1px solid red' }} />,
		},
		{
			key: 'order',
			title: '',
			show: types.PRICES,
			// condition: role !== 'driver',
			// Cell: (props: any) => (
			//     <OrderCell index={1} role={role} prices={prices} from={from} row={props.cell.row.original} />
			// ),
			Cell: DotsCell,
			render: (value: any, row: any, index: any) => (
				<OrderCell index={index} role={role} prices={prices} from={from} row={row} />
			),
		},
		{
			key: 'order',
			title: '',
			show: types.ROUTE,
			// condition: role !== 'driver',
			Cell: (props: any) => (
				<OrderCell index={1} role={role} prices={prices} from={from} row={props.cell.row.original} />
			),
			// render: (value: any, props: any, index: any) => (
			//     <OrderCell index={index} role={role} prices={prices} from={from} row={row} />
			// ),
		},
	].filter((e: any) => !e.show || e.show === typeToShow),
});

export const convertToReactTable = ({ data, head }: any) => {
	return {
		data,
		columns: head
			.map(({ title, key, ...other }: any) => ({ Header: title, accessor: key, ...other }))
			.filter(({ Header, accessor }: any) => Header && accessor),
	};
};
/* eslint-enable react/display-name */

export const sortFieldMap = {
	[types.PRICES]: 'buyerPrice',
	[types.ROUTE]: 'cptPrice',
};
