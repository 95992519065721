import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RadioButtonContainerStyle } from '../sharedStyles/RadioButtonContainerStyle';

const ButtonStyle: string = `
    border-radius: 4px;
    border: 1px solid #d6d8e5;
    padding: 0 10px;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    font-family: 'UniNeue';
    font-size: 14px;
    font-weight: bold;
    line-height: 19px;
    height: 40px;
    box-sizing: border-box;
    user-select: none;

    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: 0.25s;

    color: #6c6f88;

    img {
        object-fit: cover;
        margin-right: 10px;
        width: 32px;
        height: 32px;
    }
`;

export const DivButton = styled.div`
	${ButtonStyle}
`;

export const Button = styled.button`
	${ButtonStyle}
`;

export const RadioButtonStyle = styled.label<any>`
	input {
		display: none;
	}

	input:checked + ${DivButton} {
		background: #2e3043;
		border-color: #2e3043;
		color: #ffffff;
	}

	&:not(:last-child) {
		div {
			border-right: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}

		&:not(:first-child) {
			div {
				border-top-left-radius: 0;
				border-bottom-left-radius: 0;
			}
		}
	}
	&:last-child {
		div {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
`;

export const RadioButton: React.FC<any | HTMLInputElement> = (props) => {
	const inputProps = {
		...props,
		children: undefined,
		value: undefined,
	};

	return (
		<RadioButtonStyle>
			<input type='radio' {...inputProps} />
			<DivButton>{props.children}</DivButton>
		</RadioButtonStyle>
	);
};

let ids = 0;
const generateId = (prefix: string) => {
	ids++;
	return prefix + ids;
};

export interface RadioButtonGroupProps {
	onChange?: (index: number, event: Event) => any;
	checkedIndex?: number;
	children: any[];
	canBeUnchecked?: Boolean; // Может кнопка отжиматься (в этом случае значение -1)
	title?: string;
}

const RadioButtonGroup: React.FC<RadioButtonGroupProps> = (props) => {
	const { children, checkedIndex = 0, canBeUnchecked = false } = props;
	const validChildren = children.filter((child: any) => {
		return child.type === RadioButton || (child.type.displayName || child.type.name) === 'RadioButton';
	});
	const [name] = useState(generateId('radio-button-group-'));
	const [checked, setCheckIndex] = useState(checkedIndex);

	useEffect(() => {
		setCheckIndex(checkedIndex);
	}, [checkedIndex]);

	const newChildrenList = React.Children.map(validChildren, (child: any, index: number) => {
		const checkingValue = child.props.value || index;
		return React.cloneElement(child, {
			onClick(event: MouseEvent) {
				if (canBeUnchecked) {
					const newValue = checkingValue === checked ? -1 : checkingValue;
					setCheckIndex(newValue);
					props.onChange?.(newValue, event);
					return;
				}
				setCheckIndex(checkingValue);
				props.onChange?.(checkingValue, event);
			},
			name,
			checked: checkingValue === checked,
		});
	});

	return <RadioButtonContainerStyle data-title={props.title ?? ''}>{newChildrenList}</RadioButtonContainerStyle>;
};

export default RadioButtonGroup;
