import React from 'react';
import styled from 'styled-components';
import { makePlace } from 'app/utils/transport';
import { InfoColumns, IconMarker } from './Common';

const ICON_LOADING = require('assets/icons/svg/ic_shipment_small.svg');
const ICON_TO = require('assets/icons/svg/ic_landing_small.svg');
const ICON_REPLIES = require('assets/icons/svg/ic_reply_micro.svg');
const ICON_APPROVES = require('assets/icons/svg/ic_approve_micro.svg');
const ICON_VIEWS = require('assets/icons/svg/ic_view_micro.svg');

const BidCardContainer = styled.button<any | { selected?: boolean; noBorder?: boolean }>`
	min-height: 112px;
	border-radius: 4px;
	box-shadow: ${(props) => (props.noBorder ? 'none' : '0px 0px 4px rgba(103, 116, 150, 0.246971)')};
	background: ${(props) => (props.selected ? '#d6f3fd' : '#fff')};
	display: flex;
	flex-direction: column;
	align-items: stretch;
	text-align: left;
	width: 100%;
	padding: 10px 13px;
	margin-bottom: 10px;

	&:hover,
	&[data-active] {
		background: #d6f3fd;
	}
`;

const Stats = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	margin-top: 10px;
`;
export interface StatsValueProps {
	icon: string;
	title: string;
}
const StatsValue = styled.div`
	margin: 0 16px;
	text-align: center;
	font-size: 14px;
	color: #6c6f88;
	display: inline-flex;
	flex-direction: row;
	align-items: center;

	& > img {
		width: 19px;
		height: 19px;
		margin-right: 7px;
	}

	& > span,
	& > img {
		display: inline-block;
		vertical-align: top;
	}
`;

export type BidCardProps = {
	onClick?: (event: MouseEvent) => void;
	selected?: boolean;
	bid: any;
	noBorder?: boolean;
	onMouseEnter?: (event: MouseEvent) => void;
	onMouseLeave?: (event: MouseEvent) => void;
};

export const BidCard: React.FC<BidCardProps> = ({
	onClick,
	selected,
	bid,
	onMouseEnter,
	onMouseLeave,
	noBorder,
}: BidCardProps) => {
	const {
		cargo: {
			type: { name: cargoName },
		},
		direction: { distance, from, to },
		payment: {
			pricePerKm,
			price,
			type: { name: priceType },
			isPricePerKm,
		},
		status,
	} = bid;

	const handleCargoName = (cargoName: string): string => {
		if (cargoName.length > 10) {
			return cargoName.slice(0, 9) + '...';
		}
		return cargoName;
	};

	const outputPrice = isPricePerKm ? pricePerKm : price;
	const showStatus = status?.views || status?.requests || status?.confirmed;
	return (
		<BidCardContainer
			noBorder={noBorder}
			onClick={onClick}
			selected={selected}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<InfoColumns>
				<div>{handleCargoName(cargoName) || 'NONE'}</div>
				<div>{distance || 'NONE'} км</div>
				<div>
					{outputPrice || 'NONE'}
					{priceType && priceType.toLowerCase()}
				</div>
			</InfoColumns>
			<div>
				<IconMarker>
					<img src={ICON_LOADING} alt='Погрузка' />
					{makePlace(from) || ''}
				</IconMarker>
				<IconMarker>
					<img src={ICON_TO} alt='Место назначения' />
					{makePlace(to) || ''}
				</IconMarker>
			</div>
			{showStatus ? (
				<Stats>
					<StatsValue title='Просмотры'>
						<img src={ICON_VIEWS} alt='Просмотры' />
						{status?.views}/{status?.previews}
					</StatsValue>
					<StatsValue title='Ответы'>
						<img src={ICON_REPLIES} alt='Ответы' />
						<span>{status?.requests}</span>
					</StatsValue>
					<StatsValue title='В работе'>
						<img src={ICON_APPROVES} alt='В работе' />
						<span>{status?.confirmed}</span>
					</StatsValue>
				</Stats>
			) : (
				<div />
			)}
		</BidCardContainer>
	);
};
