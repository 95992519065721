import React from 'react';
import { connect } from 'react-redux';
import { Localized } from '@fluent/react';
import { Dispatch, bindActionCreators } from 'redux';

import { UserActions, CommonActions } from 'app/actions';
import { UserContact, SidebarType } from 'app/models';
import { maskPhoneValue } from 'app/utils';
import { RootState } from 'app/reducers';

import PageTitle from 'app/components/PageTitle';
import Modal from 'app/sharedComponents/Modal';
import Button, { ButtonStyleTypes } from 'app/sharedComponents/Button';
import { DeleteBig as IconDelete } from 'app/components/Icons';

const profileImage = require('assets/icons/profile.png');

class Contacts extends React.Component<Contacts.Props, Contacts.State> {
	public state: Contacts.State = {
		modalConfirmState: false,
	};

	constructor(props: Contacts.Props) {
		super(props);

		this.getContacts = this.getContacts.bind(this);
		this.modalConfirmSetState = this.modalConfirmSetState.bind(this);
		this.deleteSelectedContact = this.deleteSelectedContact.bind(this);
	}

	private modalConfirmSetState(modalConfirmState: any) {
		this.setState({ modalConfirmState });
	}

	private async deleteSelectedContact() {
		const contact = this.state.modalConfirmState;
		if (!contact) return;
		const { deleteContact, setTmpContact, toggleSidebar, sessionToken, tmpContact } = this.props;
		if (tmpContact && tmpContact.uuid === contact.uuid && setTmpContact && toggleSidebar) {
			await setTmpContact(undefined);
			await toggleSidebar({ type: SidebarType.ContactCreate, value: false });
		}

		if (deleteContact && sessionToken) {
			await deleteContact(contact.uuid, sessionToken);
		}

		this.modalConfirmSetState(false);
	}

	private getContacts() {
		const { openContactEditor } = this.props;

		let { contacts } = this.props;

		contacts = contacts ? contacts : [];

		return contacts.map((contact) => {
			const phone = maskPhoneValue(contact.phone);
			const avatar = contact.avatar ? contact.avatar : profileImage;

			const onEdit = () => {
				if (openContactEditor) {
					openContactEditor(contact);
				}
			};

			return (
				<div key={contact.phone} className='contact'>
					<div className={`photo ${!contact.avatar ? 'default' : ''}`}>
						<img src={avatar} />
					</div>
					<div className='data'>
						<div className='name'>
							{contact.firstName} {contact.lastName} {contact.middleName}
						</div>
						<div className='phone'>{phone}</div>

						{contact.isAutoCreated ? null : (
							<div className='actions'>
								<button onClick={onEdit}>
									<Localized id='contacts-action-edit' />
								</button>
								<button onClick={() => this.modalConfirmSetState(contact)} className='delete'>
									<Localized id='contacts-action-remove' />
								</button>
							</div>
						)}
					</div>
				</div>
			);
		});
	}

	public render() {
		const { contacts } = this.props;

		const content: JSX.Element | JSX.Element[] =
			contacts && contacts.length > 0 ? (
				<div className='row'>
					<div className='list'>{this.getContacts()}</div>
					<div className='info'>
						<div className='text' style={{ maxWidth: 350 }}>
							<Localized id='contacts-description' />
						</div>
					</div>
				</div>
			) : (
				<div className='placeholder'>
					<img width='132' src={profileImage} alt='' />
					<div className='note text align-center'>
						<Localized id='contacts-please-add' />
						<div className='actions'>
							<div className='action'>
								<Localized id='contacts-action-add' />
							</div>
						</div>
					</div>
				</div>
			);

		return (
			<div className='contacts'>
				<PageTitle key='title-comp' titleKey='title-contacts' />,{content}
				<Modal
					actions={[
						<Button
							onClick={() => this.deleteSelectedContact()}
							key='button-remove-confirm-yes'
							styleType={ButtonStyleTypes.DEFAULT}
						>
							<Localized id='yes' />
						</Button>,
						<Button
							onClick={() => this.modalConfirmSetState(false)}
							key='button-remove-confirm-no'
							styleType={ButtonStyleTypes.PRIMARY}
						>
							<Localized id='not' />
						</Button>,
					]}
					value={[this.state.modalConfirmState, this.modalConfirmSetState]}
				>
					<div>
						<IconDelete />
					</div>
					<div>Вы действительно хотите удалить этот контакт?</div>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = ({ user }: RootState) => ({
	sessionToken: user.sessionToken,
	tmpContact: user.tmpContact,
	contacts: user.contacts,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
	bindActionCreators(
		{
			openContactEditor: UserActions.openContactEditor,
			toggleSidebar: CommonActions.toggleSidebar,
			setTmpContact: UserActions._setTmpContact,
			deleteContact: UserActions.deleteContact,
		},
		dispatch,
	);

export default connect<Contacts.StateProps, Contacts.DispatchProps, Contacts.ExternalProps>(
	mapStateToProps,
	mapDispatchToProps,
)(Contacts);

namespace Contacts {
	export type Props = StateProps & DispatchProps & ExternalProps;

	// Props from redux mapState
	export interface StateProps {
		contacts?: UserContact[];
		tmpContact?: UserContact;
		sessionToken?: string;
	}

	// Dispatch properties function from redux
	export interface DispatchProps {
		toggleSidebar?: (opt: CommonActions.Payload.ToggleSidebar) => void;
		deleteContact?: (id: string, token: string) => void;
		openContactEditor?: (contact: UserContact) => void;
		setTmpContact?: (contact?: UserContact) => void;
	}

	// Props from parent element e.g <Cmp custom={true} />
	export interface ExternalProps {}

	// Main component state
	export interface State {
		[x: string]: any;
		modalConfirmState?: any;
	}
}
