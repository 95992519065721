import React, { useState } from 'react';
import { GMap } from '../GMap';
import { Bid, BidDetails } from 'app/models';
import { parseLocation } from 'app/utils/transport';

export const BidMap = ({ bid }: { bid: Bid | BidDetails | undefined }) => {
	const [mapReady, setMapReady] = useState(false);

	if (!bid?.direction) return <div>...loading</div>;

	const { from, to } = bid.direction;
	return (
		<div style={{ width: '100%', height: '200px' }}>
			<GMap
				hideMapType
				singleRoute
				routeToShow={
					mapReady
						? {
								origin: parseLocation(from.location),
								destination: parseLocation(to.location),
								id: bid?.id,
						  }
						: undefined
				}
				onMapReady={() => setMapReady(true)}
			/>
		</div>
	);
};
