import React, { useRef } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import styled from 'styled-components';
import { AutoSizer } from 'react-virtualized';
import LoadingIndicator from '../../sharedComponents/LoadingIndicator';

export type TableProps = {
	compact: boolean;
	setCompact?: (compact: boolean) => void;
	onLoadMore?: () => Promise<void>;
	loading?: boolean;
	tab?: any;
	onRowClick?: any;
	list: any[];
};
import { TableRow } from './TableRow';

const LoaderWrapper = styled.div`
	width: 100;
	padding: 50px;
	display: flex;
	justify-content: center;
`;

export const Table = (props: TableProps) => {
	const scrollbarsRef = useRef<Scrollbars>(null);

	const handleScroll = async (event: any) => {
		const { scrollTop, clientHeight, scrollHeight } = event.target;
		const pixelsToBottom = scrollHeight - clientHeight - scrollTop;
		if (pixelsToBottom < 10 && !props.loading) {
			props.onLoadMore?.();
		}
		if (scrollbarsRef.current) {
			const scrolledTop = scrollbarsRef.current.getScrollTop();
			// Handle compact view of table
			if (scrolledTop > 100) {
				if (!props.compact) props.setCompact?.(true);
			} else {
				props.setCompact?.(false);
			}
		}
	};

	const { list } = props;
	return (
		<AutoSizer>
			{({ height, width }) => {
				return (
					<Scrollbars ref={scrollbarsRef} onScroll={handleScroll} style={{ height, width }}>
						{list.map((item: any) => (
							<TableRow
								//@ts-ignore
								onClick={() => props.onRowClick(item.uuid)}
								tab={props.tab}
								style={{ overflowX: 'visible', overflowY: 'visible' }}
								key={item.uuid}
								bid={item}
							/>
						))}
						{props.loading && (
							<LoaderWrapper>
								<LoadingIndicator />
							</LoaderWrapper>
						)}
					</Scrollbars>
				);
			}}
		</AutoSizer>
	);
};
