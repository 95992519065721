import React from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import Scrollbars from 'react-custom-scrollbars';
import { customerTransportDetails } from 'app/selectors';

import { PaddingContainer } from '../components/StyledCandidatesComponents';

import { DetailsCard } from './DetailsCard';
import { ArrowDown } from '../../../components/Icons';
// const ICON_DROPDOWN = require('src/assets/icons/i-arrow-drop.png');

const LoaderContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const DetailsContainer = styled.div`
	position: absolute;
	z-index: 99999999999;
	width: 350px;
	height: 100%;
	background-color: #f1f2f5;
	padding: 0px !important;
`;

const BackButton = styled.div`
	width: 36px;
	height: 36px;
	border-radius: 30px;
	background-color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-right: 12px;
	svg {
		transform: rotate(90deg);
		&:hover {
			color: black;
		}
	}
`;

const BackButtonContainer = styled.div`
	padding: 5px;
	cursor: pointer;
	display: flex;
	align-items: center;
	/* font-family: Uni Neue;
    font-style: normal;
    font-weight: 500;
    font-size: 16px; */
`;

export const Details = ({ onClose, onShowRoute, onShowBid }: any) => {
	const { data, loading } = useSelector(customerTransportDetails);

	return (
		<DetailsContainer>
			<Scrollbars>
				<PaddingContainer>
					<BackButtonContainer onClick={onClose}>
						<BackButton>
							<ArrowDown color='gray' />
						</BackButton>
						Назад
					</BackButtonContainer>
					{loading && (
						<LoaderContainer>
							<Loader color='#2196F3' height={34} width={34} type='Oval' />
						</LoaderContainer>
					)}
					{!loading && data && (
						<>
							<DetailsCard onShowBid={onShowBid} onShowRoute={onShowRoute} onClose={onClose} data={data} />
						</>
					)}
				</PaddingContainer>
			</Scrollbars>
		</DetailsContainer>
	);
};
