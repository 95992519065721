export interface Company {
	address: string;
	name: string;
	type: string;
	inn: string;
	ogrn: string;
	kpp?: string;
	id?: string;
	uuid?: string;
}

export interface Bank {
	address: string;
	coracc: string;
	name: string;
	bic: string;
}

export namespace Company {
	export enum DocumentType {
		P51003 = 'Р51003',
		P61003 = 'Р61003',
		P61004 = 'Р61004',
		P50007 = 'Р50007',
		P60009 = 'Р60009',

		Other = 'OTHER',
		Bank = 'BANK',
	}

	export const DocumentTypeLabel = {
		[DocumentType.P51003]: 'Форма P51003 - ООО (устаревший)',
		[DocumentType.P61003]: 'Форма Р61003 - ИП (устаревший)',
		[DocumentType.P61004]: 'Форма Р61004 - Фермер (устаревший)',
		[DocumentType.P50007]: 'Форма Р50007 - вписка из ЕГРЮЛ',
		[DocumentType.P60009]: 'Форма Р60009 - вписка из ЕГРЮЛ ИП',
		[DocumentType.Bank]: 'Справка из банка',
		[DocumentType.Other]: 'Прочее',
	};
}
