//types
import { LegalTypeEnum } from 'app/models';
import { BasicTypes } from 'app/containers/Builder/props';

export const legalTypes = [
	{ value: LegalTypeEnum.Np, label: 'legal-type-np' },
	{ value: LegalTypeEnum.Company, label: 'legal-type-company' },
];

//validators
import { minLength, regex, isDateEarlierThanToday, isDateLaterThanToday } from 'app/utils/form-rules';

//constants
const CYRILLIC = /^[a-zа-я\-ё]+$/i;
const EMAIL =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

export const base = [
	{
		label: 'profile-label-last-name',
		type: BasicTypes.Input,
		name: 'lastName',
		localized: true,
		//required: true,
		rounded: true,
		preventSpace: true,
		rules: [minLength(2, 'profile-field-less-than'), regex(CYRILLIC, 'profile-field-last-name-invalid')],
	},

	{
		label: 'profile-label-first-name',
		type: BasicTypes.Input,
		name: 'firstName',
		localized: true,
		//required: true,
		rounded: true,
		preventSpace: true,
		rules: [minLength(2, 'profile-field-less-than'), regex(CYRILLIC, 'profile-field-first-name-invalid')],
	},

	{
		label: 'profile-label-middle-name',
		type: BasicTypes.Input,
		name: 'middleName',
		localized: true,
		required: false,
		rounded: true,
		preventSpace: true,
	},

	{
		label: 'profile-header-contacts',
		type: BasicTypes.Header,
		name: 'contactsHeader',
		localized: true,
	},

	{
		label: 'profile-label-phone',
		type: BasicTypes.Input,
		localized: true,
		disabled: true,
		//required: true,
		rounded: true,
		name: 'phone',
		rules: [regex(/^\+[0-9\-\s]{10,}$/i, 'profile-field-phone-invalid')],
	},

	{
		label: 'profile-label-email',
		type: BasicTypes.Input,
		localized: true,
		//required: true,
		rounded: true,
		name: 'email',
		rules: [regex(EMAIL, 'profile-field-email-invalid')],

		// @TODO Enable that after create email verify on backend

		// Props setted dynamic by bindings in Personal index
		// extendComponentProps: { verified: false },

		// extendComponent: (props: any) => (
		//     <div className="email-verified">
		//         <Localized id={!props.verified
		//             ? "profile-email-status-not-verified"
		//             : "profile-email-status-verified"
		//         } />
		//     </div>
		// ),
	},

	{
		label: 'profile-header-organization',
		type: BasicTypes.Header,
		name: 'orgHeader',
		localized: true,
	},

	{
		label: 'profile-label-itn',
		type: BasicTypes.Company,
		localized: true,
		rounded: true,
		name: 'inn',
	},
];

export const passport = [
	{
		label: 'profile-header-passport',
		type: BasicTypes.Header,
		name: 'passportHeader',
		localized: true,
	},

	{
		label: 'profile-label-passport-number',
		type: BasicTypes.Input,
		mask: '00 00 000000',
		localized: true,
		name: 'number',
		required: true,
		rounded: true,
		rules: [regex(/^[0-9]{2}\s[0-9]{2}\s[0-9]{6}$/i, 'profile-field-passport-invalid')],
	},

	{
		label: 'profile-label-passport-issuer',
		type: BasicTypes.Input,
		localized: true,
		name: 'issuer',
		required: true,
		rounded: true,
	},

	{
		label: 'profile-label-passport-issued-at',
		defaultValue: new Date(),
		type: BasicTypes.Date,
		localized: true,
		name: 'issuedAt',
		required: true,
		rounded: true,
		rules: [isDateEarlierThanToday('profile-field-date-invalid')],
	},

	{
		label: 'profile-label-passport-issuer-code',
		type: BasicTypes.Input,
		mask: '000 000',
		localized: true,
		name: 'issuerCode',
		required: false,
		rounded: true,
	},

	{
		label: 'profile-label-passport-birthday',
		defaultValue: new Date(),
		type: BasicTypes.Date,
		localized: true,
		name: 'birthday',
		required: false,
		rounded: true,
		rules: [isDateEarlierThanToday('profile-field-date-invalid')],
	},

	{
		label: 'profile-label-passport-birthplace',
		type: BasicTypes.Input,
		localized: true,
		name: 'birthplace',
		required: false,
		rounded: true,
	},

	{
		label: 'profile-label-passport-registration-address',
		type: BasicTypes.Input,
		localized: true,
		name: 'registrationAddress',
		required: false,
		rounded: true,
	},

	{
		label: 'profile-label-passport-main-page',
		type: BasicTypes.ImagePicker,
		name: 'mainPage',
		localized: true,
		required: true,
	},

	{
		label: 'profile-label-passport-registration-page',
		type: BasicTypes.ImagePicker,
		name: 'registrationPage',
		localized: true,
		required: true,
	},

	{
		label: 'profile-label-passport-snils',
		type: BasicTypes.Input,
		mask: '00000000000',
		localized: true,
		name: 'snils',
		required: false,
		rounded: true,
	},

	{
		label: 'profile-label-passport-snils-page',
		type: BasicTypes.ImagePicker,
		name: 'snilsPage',
		localized: true,
		required: false,
	},
];

export const driverLicence = [
	{
		label: 'profile-header-driver-licence',
		type: BasicTypes.Header,
		name: 'licenceHeader',
		localized: true,
	},

	{
		label: 'profile-label-licence-number',
		type: BasicTypes.Input,
		mask: '00 ll 000000',
		localized: true,
		required: true,
		rounded: true,
		name: 'number',
		rules: [regex(/^[\d]{2}[\s]{0,1}[а-яА-Я\w\d]{2}[\s]{0,1}[\d]{6}$/i, 'profile-field-license-invalid')],
	},

	{
		label: 'profile-label-licence-issued-at',
		defaultValue: new Date(),
		type: BasicTypes.Date,
		localized: true,
		name: 'issuedAt',
		required: true,
		rounded: true,
		rules: [isDateEarlierThanToday('profile-field-date-invalid')],
	},

	{
		label: 'profile-label-licence-expired-at',
		defaultValue: new Date(),
		type: BasicTypes.Date,
		name: 'expiredAt',
		localized: true,
		required: true,
		rounded: true,
		rules: [isDateLaterThanToday('profile-field-date-invalid')],
	},

	{
		label: 'profile-label-licence-front-page',
		type: BasicTypes.ImagePicker,
		localized: true,
		name: 'mainPage',
		required: true,
	},

	{
		label: 'profile-label-licence-back-page',
		type: BasicTypes.ImagePicker,
		localized: true,
		name: 'backPage',
		required: true,
	},
];

export const company = [
	{
		label: 'profile-org-header',
		type: BasicTypes.Header,
		name: 'orgHeader',
		localized: true,
	},

	{
		mask: '00000000000000000000',
		label: 'profile-account',
		type: BasicTypes.Input,
		name: 'account',
		localized: true,
		required: true,
		rounded: true,
		rules: [regex(/^\d{20}$/, 'profile-field-account-number-invalid')],
	},

	{
		mask: '040000000',
		type: BasicTypes.Bank,
		label: 'profile-bic',
		localized: true,
		required: true,
		rounded: true,
		name: 'bic',
		rules: [regex(/^04\d{7}$/, 'profile-field-bic-invalid')],
	},
];
