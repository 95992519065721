import styled from 'styled-components';
import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';

const ICON_CLOSE = require('assets/icons/svg/ic_close.svg');
const ModalWindow = styled.section`
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(103, 116, 150, 0.246971);
	padding: 32px;

	transform: scale(0.9);
	transition: 0.25s ease-out;

	display: flex;
	flex-direction: column;
	box-sizing: border-box;

	.close-button {
		position: absolute;
		top: 32px;
		right: 32px;
		padding: 0;
	}
`;

const ModalTitle = styled.h1`
	font-size: 20px;
	margin: 0;
	margin-bottom: 20px;
	text-align: center;
`;

const ModalContent = styled.div`
	flex-grow: 1;
	text-align: center;
	font-size: 22px;
	line-height: 30px;
	color: #1b1b1b;
`;
const ModalActions = styled.div`
	display: flex;
	justify-content: space-around;
	margin-top: 16px;
	padding: 8px;
	border-radius: 4px;

	& > button {
		margin-left: 5px;
		min-width: 150px;
	}
`;

const ModalOverlay = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgb(0 0 0 / 0.4);

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	opacity: 0;
	pointer-events: none;
	transition: 0.25s;

	&[data-opened] {
		opacity: 1;
		pointer-events: auto;

		${ModalWindow} {
			transform: scale(1);
		}
	}

	z-index: 99999;
`;

interface ModalProps {
	width?: number;
	height?: number;
	title?: string;
	persistent?: boolean;
	actions?: ReactElement[];
	value: any[]; // value of "useState" with boolean type
	position?: any;
}

const Modal: React.FC<ModalProps> = (props) => {
	const { persistent, width, height, title, value, children, actions, position } = props;

	const [stateValue, setValue] = value;
	let overlayNode: any;

	const overlayProps = {
		'onClick': !persistent
			? (e: any) => {
					if (e.target === overlayNode) {
						setValue(false);
					}
			  }
			: undefined,
		'data-opened': stateValue || undefined,
		'style': {
			position,
		},
	};

	const windowProps = {
		style: {
			width: width + 'px',
			minHeight: height + 'px',
		},
	};
	return ReactDOM.createPortal(
		<ModalOverlay ref={(node) => (overlayNode = node)} {...overlayProps}>
			<ModalWindow {...windowProps}>
				<button className='close-button' onClick={() => setValue(false)}>
					<img src={ICON_CLOSE} />
				</button>

				<ModalTitle>{title}</ModalTitle>

				<ModalContent>{children}</ModalContent>

				{actions ? <ModalActions>{actions}</ModalActions> : null}
			</ModalWindow>
		</ModalOverlay>,
		document.body,
	);
};

export default Modal;
