import React, { useState, useRef, MouseEvent } from 'react';
import styled from 'styled-components';
import Loader from 'react-loader-spinner';
import { Delete } from 'app/components/Icons';
import ConfirmModal from 'app/components/ConfirmModal';

export const Container = styled.section`
	width: 380px;
	background-color: white;
	border-radius: 4px;
	padding: 10px;
	min-height: 130px;
	margin-top: 10px;
`;

export const ThumbsContainer = styled.aside`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 16px;
`;

const ThumbContainer = styled.div`
	display: inline-flex;
	border-radius: 2px;
	border: 1px solid #eaeaea;
	margin-bottom: 8px;
	margin-right: 8px;
	width: fit-content;
	width: 100px;
	height: 80px;
	padding: 4px;
	box-sizing: border-box;
	position: relative;
`;

const Inner = styled.div`
	display: flex;
	min-width: 0;
	overflow: hidden;
`;

const Img = styled.img`
	display: block;
	width: auto;
	height: 100%;
`;

export const Title = styled.p`
	font-family: UniNeue, sans-serif;
	font-size: 11px;
	font-weight: 500;
`;

const LoaderContainer = styled.div`
	position: absolute;
	left: 30px;
	top: 20px;
`;

export const DropZoneContainer = styled.div`
	position: relative;
	min-height: 100px;
	border: 1px dashed lightgray;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	cursor: pointer;
	&:hover {
		border-color: #64c8eb;
	}
`;

export const IconContainer = styled.div`
	position: absolute;
	left: 50px;
	top: 50px;
`;

export const UploadIconContainer = styled.div`
	position: absolute;
	left: 174px;
	top: 40px;
`;

const DeleteContainer = styled.div`
	position: absolute;
	right: 5px;
	top: 5px;
	width: 20px;
	height: 20px;
	background-color: white;
	border-radius: 20px;
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: center;
	svg {
		height: 14px;
		width: auto;
	}
	&:hover {
		cursor: pointer;
		border: 1px solid red;
	}
`;

export const Thumbs = ({ files = [], loader, onDelete, thumbClassName }: any) => {
	const [showModal, setShowModal] = useState(false);

	const fileToDelete = useRef(null);

	const handleDelete = (file: any) => (e: MouseEvent) => {
		e.stopPropagation();
		fileToDelete.current = file;
		setShowModal(true);
	};
	const handleDeleteConfirm = (e: MouseEvent) => {
		e.stopPropagation();
		onDelete(fileToDelete.current);
		fileToDelete.current = null;
		setShowModal(false);
	};

	return (
		<>
			{files.map((file: any) => {
				if (file)
					return (
						<ThumbContainer className={thumbClassName} key={file.name || file.preview}>
							<Inner>
								<Img src={file.preview} />
								{loader && (
									<LoaderContainer>
										<Loader color='#2196F3' height={34} width={34} type='Oval' />
									</LoaderContainer>
								)}
								{onDelete && (
									<DeleteContainer onClick={handleDelete(file)}>
										<Delete color='#f44336' />
									</DeleteContainer>
								)}
							</Inner>
						</ThumbContainer>
					);
				else return null;
			})}

			<ConfirmModal
				text='Уверены что хотите удалить документ?'
				confirmBtnText='Удалить'
				isOpen={showModal}
				onCancel={() => setShowModal(false)}
				onConfirm={handleDeleteConfirm}
			/>
		</>
	);
};
