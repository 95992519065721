import styled from 'styled-components';

export const Tr = styled.tr<{ rowClickable?: boolean }>`
	&:hover {
		background-color: ${(props) => (props.rowClickable ? '#d1eff9' : 'none')};
	}
	:last-child {
		td {
			border-bottom: 0;
		}
	}
`;

export const Styles = styled.div<{ sticky: boolean; rowClickable: boolean }>`
	position: absolute;
	overflow: scroll;
	width: 99%;
	height: 95%;
	margin: 1rem;
	font-family: 'UniNeue', Helvetica, sans-serif;
	table {
		width: 100%;
		border-spacing: 0;

		thead {
			position: ${(props) => (props.sticky ? 'sticky !important;' : 'inherit')};
			/* z-index: 9999; */
			left: 0px;
			top: 0px;
			background-color: white;
			color: rgba(0, 0, 0, 0.55);
			text-transform: uppercase;
			font-size: 11px;
		}
		th,
		td {
			margin: 0;
			padding: 0.5rem;
			font-size: 14px;
			font-weight: 500;
			/* border-bottom: 1px solid black; */
			/* border-right: 1px solid black; */
			:last-child {
				border-right: 0;
			}
		}

		th {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			text-align: left;
		}
	}
`;

export const ThContent = styled.div`
	display: flex;
	align-items: center;
`;

export const SpinnerContainer = styled.div`
	display: flex;
	justify-content: center;
`;
