import React from 'react';

import { Localized } from '@fluent/react';
import { Switch, Route, NavLink, useRouteMatch } from 'react-router-dom';

import PageTitle from 'app/components/PageTitle';
import { Candidates } from './Candidates';
import { FreeTransport } from './FreeTransport';

const Executors = () => {
	const match = useRouteMatch();

	return (
		<div className='transports page'>
			<PageTitle titleKey='title-customer-transport' />

			<div className='header widget'>
				<div className='container'>
					<div className='title'>
						<Localized id='executors-page-header' />
					</div>

					<div className='actions'>
						<div className='menu'>
							<NavLink to={`${match.url}/all`} className='item' activeClassName='active'>
								<Localized id='executors-tab-all' />
							</NavLink>

							<NavLink to={`${match.url}/my`} className='item' activeClassName='active'>
								<Localized id='executors-tab-my' />
							</NavLink>
						</div>
					</div>
				</div>
			</div>

			<Switch>
				<Route path={`${match.url}/my`} component={Candidates} />
				<Route path={`${match.url}/all`} component={FreeTransport} />
			</Switch>
		</div>
	);
};

export default Executors;
