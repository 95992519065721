import React from 'react';
import styled from 'styled-components';
import RadioButtonGroup, { RadioButton } from 'app/sharedComponents/RadioButtonGroup';
import { CandidateStatus } from './types/CandidateData';
import { StyledContainer } from '../../../sharedComponents/sharedStyles/styledContainer';

const StyledContainerLeft = styled(StyledContainer)`
	width: 330px;
`;

const ICON_REPLY = require('assets/icons/i-reply.png');
const ICON_APPROVE = require('assets/icons/i-approve.png');

export type StatusFilterProps = {
	value: CandidateStatus | undefined;
	onChange: (value: CandidateStatus | undefined) => void;
};

export const StatusFilter = (props: StatusFilterProps) => {
	const index = props.value === CandidateStatus.CONFIRMED ? 1 : props.value === CandidateStatus.OPEN ? 0 : -1;
	const onChange = (value: number) => {
		props.onChange(value === 1 ? CandidateStatus.CONFIRMED : value === 0 ? CandidateStatus.OPEN : undefined);
	};
	return (
		<StyledContainer>
			<RadioButtonGroup canBeUnchecked checkedIndex={index} onChange={onChange} title='статусы исполнителей'>
				<RadioButton>
					<img src={ICON_REPLY} />
					<span>Ответы</span>
				</RadioButton>
				<RadioButton>
					<img src={ICON_APPROVE} />
					<span>В работе</span>
				</RadioButton>
			</RadioButtonGroup>
		</StyledContainer>
	);
};

export type GroupFilterProps = {
	value: boolean;
	onChange: (value: boolean) => void;
};

export const GroupFilter = ({ value, onChange }: GroupFilterProps) => {
	const index = value ? 0 : 1;
	const handleOnChange = (value: number) => {
		onChange(value === 0 ? true : false);
	};
	return (
		<StyledContainerLeft>
			<RadioButtonGroup checkedIndex={index} onChange={handleOnChange} title='группировка'>
				<RadioButton>
					<span>Транспорт</span>
				</RadioButton>
				<RadioButton>
					<span>По заявкам</span>
				</RadioButton>
			</RadioButtonGroup>
		</StyledContainerLeft>
	);
};
