import { SET_DOCUMENTS_LIST, SET_DOCUMENTS_LIST_MORE, SET_DOCUMENTS_LOADING } from 'app/actions';
import { Action, handleActions } from 'redux-actions';
import { DocumentType } from 'app/models';

type State = {
	data: DocumentType[];
	cursor: {
		next: string | null;
		previous: string | null;
	};
	loading: boolean;
	updating: boolean;
};

export const documentsReducer = handleActions<State, any>(
	{
		// [SET_CANDIDATE_DETAIL]: (state, action: Action<Candidate>) => ({
		//     ...state,
		//     detail: action.payload !== undefined ? action.payload : state.detail,
		// }),
		[SET_DOCUMENTS_LOADING]: (state, action: Action<boolean>) => ({
			...state,
			loading: action.payload ?? state.loading,
		}),
		[SET_DOCUMENTS_LIST]: (state, action: Action<any>) => ({
			...state,
			loading: false,
			cursor: {
				next: action.payload?.cursor?.next !== undefined ? action.payload.cursor?.next : state.cursor.next,
				previous:
					action.payload?.cursor?.previous !== undefined ? action.payload.cursor?.previous : state.cursor.previous,
			},
			data: action.payload?.data !== undefined ? action.payload.data : state.data,
		}),
		[SET_DOCUMENTS_LIST_MORE]: (state, action: Action<any>) => ({
			...state,
			loading: false,
			cursor: {
				next: action.payload?.cursor?.next !== undefined ? action.payload.cursor?.next : state.cursor.next,
				previous:
					action.payload?.cursor?.previous !== undefined ? action.payload.cursor?.previous : state.cursor.previous,
			},
			data: action.payload?.data !== undefined ? [...state.data, ...action.payload.data] : state.data,
		}),
	},
	{
		data: [],
		cursor: { next: null, previous: null },
		loading: false,
		updating: false,
	},
);
