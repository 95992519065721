//redux
import { Dispatch } from 'redux';
import { createAction } from 'redux-actions';

//api
import { driverDeleteTransport } from 'app/api';

//types
import { LegalTypeEnum, DriverProfile, UserContact, Certificate, DriverCar, Transport, Insurance } from 'app/models';

//actions
export namespace DriverActions {
	export enum Action {
		// Cars
		ADD_INSURANCE = 'DRIVER_TRANSPORT_ADD_INSURANCE',
		ADD_TRANSPORT = 'DRIVER_ADD_TRANSPORT',
		CREATE_TRANSPORT = 'DRIVER_CREATE_TRANSPORT',
		DELETE_DOCUMENT = 'DRIVER_TRANSPORT_DELETE_DOCUMENT',
		DELETE_TRANSPORT = 'DRIVER_TRANSPORT_DELETE',
		SET_CERTIFICATES = 'DRIVER_TRANSPORT_SET_CERTIFICATES',
		SET_OPENED_TRANSPORT = 'DRIVER_SET_OPENED_TRANSPORT',
		TRANSPORT_UPDATE = 'DRIVER_TRANSPORT_UPDATE',
		UPDATE_CERTIFICATE = 'DRIVER_UPDATE_TRANSPORT_CERTIFICATE',
		UPDATE_INSURANCE = 'DRIVER_UPDATE_TRANSPORT_INSURANCE',
		UPDATE_TRANSPORT = 'DRIVER_UPDATE_TRANSPORT',
		UPDATE_TRANSPORT_PHOTOS = 'DRIVER_UPDATE_TRANSPORT_PHOTOS',

		GET_CAR_CERTIFICATES = 'DRIVER_GET_CAR_CERTIFICATES',
		SET_CAR_CERTIFICATES = 'DRIVER_SET_CAR_CERTIFICATES',
		GET_CAR_INSURANCES = 'DRIVER_GET_CAR_INSURANCES',
		SET_CAR_INSURANCES = 'DRIVER_SET_CAR_INSURANCES',
		GET_DRIVER_TRANSPORT = 'DRIVER_GET_DRIVER_TRANSPORT',

		SET_CERTIFICATES_LOADING = 'DRIVER_TRANSPORT_SET_CERTIFICATES_LOADING',
		SET_INSURANCES_LOADING = 'DRIVER_TRANSPORT_SET_INSURANCES_LOADING',

		// Drivers
		ADD = 'DRIVER_ADD',
		SET_LOADING_STATE = 'SET_DRIVER_LOADING_STATE',
		SET_OPENED = 'DRIVER_SET_OPENED',
		UPDATE = 'DRIVER_UPDATE',
	}

	export namespace Payload {
		export interface UpdatePhoto {
			uuid: string;
			photo?: string;
			avatar?: string;
		}

		export interface Docs<T> {
			transportId: string;
			data: T;
		}

		export interface DeleteDoc {
			id: string;
			type: 'insurances' | 'certificates';
			transportId: string;
		}

		export interface Certificates {
			uuid: string;
			data: { [uuid: string]: Certificate };
		}

		export interface Insurances {
			uuid: string;
			data: { [uuid: string]: Insurance };
		}

		export interface SagaAction {
			action: string;
			payload: {
				uuid: string;
			};
		}
	}

	export const NEW_TRANSPORT_ID = 'NEW_TRANSPORT_ID';

	// @CARS
	export const addDriverTransport = createAction<DriverCar>(Action.ADD_TRANSPORT);
	export const addInsurance = createAction<Payload.Docs<Insurance>>(Action.ADD_INSURANCE);
	export const deleteTransport = createAction<string>(Action.DELETE_TRANSPORT);
	export const deleteTransportDocument = createAction<Payload.DeleteDoc>(Action.DELETE_DOCUMENT);
	export const setCertificates = createAction<Payload.Docs<Certificate[]>>(Action.SET_CERTIFICATES);
	export const setCertificatesByCar = createAction<Payload.Certificates>(Action.SET_CAR_CERTIFICATES);
	export const setCertificatesLoading = createAction<string>(Action.SET_CERTIFICATES_LOADING);
	export const setInsurancesByCar = createAction<Payload.Insurances>(Action.SET_CAR_INSURANCES);
	export const setInsurancesLoading = createAction<string>(Action.SET_INSURANCES_LOADING);
	export const setOpenedTransport = createAction<string | undefined>(Action.SET_OPENED_TRANSPORT);
	export const setUpdatedDriverTransport = createAction<Payload.Docs<Transport>>(Action.UPDATE_TRANSPORT);
	export const updateDriverTransport = createAction<Payload.Docs<Transport>>(Action.TRANSPORT_UPDATE);
	export const updateTransportCertificate = createAction<Payload.Docs<Certificate>>(Action.UPDATE_CERTIFICATE);
	export const updateTransportInsurance = createAction<Payload.Docs<Insurance>>(Action.UPDATE_INSURANCE);
	export const updateTransportPhoto = createAction<Payload.UpdatePhoto>(Action.UPDATE_TRANSPORT_PHOTOS);

	export const cancelAddNewTransport = () => (dispatch: Dispatch) => {
		dispatch(setOpenedTransport(''));
		dispatch(deleteTransport(NEW_TRANSPORT_ID));
	};

	export const addNewTransport = (contact: UserContact) => async (dispatch: Dispatch) => {
		const newTransportData: DriverCar = {
			uuid: NEW_TRANSPORT_ID,
			certificates: {},
			insurances: {},
			transport: {
				onBid: true,
				uuid: NEW_TRANSPORT_ID,
				id: NEW_TRANSPORT_ID,
				status: 'free',
				isVerified: false,
				ident: '',
				trailerIdent: '',
			},
			contact,
		};

		dispatch(addDriverTransport(newTransportData));
		dispatch(setOpenedTransport(NEW_TRANSPORT_ID));
	};

	export const deleteDriverTransport = (uuid: string) => async (dispatch: Dispatch) => {
		await driverDeleteTransport(uuid);
		dispatch(deleteTransport(uuid));
	};

	export const setDriverLoadingState = createAction(
		DriverActions.Action.SET_LOADING_STATE,
		(loadingState: Boolean) => loadingState,
	);

	export const getDriverTransportsList = createAction(
		DriverActions.Action.GET_DRIVER_TRANSPORT,
		(contact: UserContact) => ({ contact }),
	);
	export const getCarInfoCertificates = createAction(DriverActions.Action.GET_CAR_CERTIFICATES, (uuid: string) => ({
		uuid,
	}));
	export const getCarInfoInsurances = createAction(DriverActions.Action.GET_CAR_INSURANCES, (uuid: string) => ({
		uuid,
	}));

	// @DRIVERS
	export const setOpened = createAction<string | undefined>(Action.SET_OPENED);
	export const updateDriver = createAction<DriverProfile>(Action.UPDATE);
	export const addDriver = createAction<DriverProfile>(Action.ADD);

	export const addNew = () =>
		addDriver({
			uuid: Math.random().toString(36).substring(7),
			interestedInDigitalSignature: false,
			legalType: LegalTypeEnum.Np,
			companyName: '',
			middleName: '',
			firstName: '',
			lastName: '',
			email: '',
			inn: '',
			phone: '',
			// docs?: UserDocs,
		});
}
