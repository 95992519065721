import React from 'react';
import { Typography } from 'app/sharedComponents/Typography';
import { CellColContainer } from './styles';

export const PriceWithDistance = ({ value, row }: any) => {
	const { distance } = row;
	return (
		<CellColContainer>
			<Typography variant='body3'>{value}</Typography>

			<Typography variant='body4' color='gray'>
				{`${distance} км`}
			</Typography>
		</CellColContainer>
	);
};
