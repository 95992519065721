import { handleActions, Action } from 'redux-actions';

import { EmptyCarActions } from 'app/actions';
import { EmptyCar, EmptyCarBase, EmptyCarDimensions, EmptyCarDocuments } from 'app/models';

//types
interface EmptyCarState {
	isLoading: boolean;
	isOpen: boolean;
	data: Partial<EmptyCar> | null;
	error?: string;
}

//constants
const initialState = {
	isLoading: false,
	isOpen: false,
	data: null,
};

//----------------------------------------------------------
// emptyCarReducer
//----------------------------------------------------------
const emptyCarReducer = handleActions<EmptyCarState, any>(
	{
		[EmptyCarActions.Action.UPDATE_BASE_DATA]: (state, { payload }: Action<EmptyCarBase>) => {
			if (payload) {
				return {
					...state,
					data: {
						...state.data,
						base: {
							...state.data?.base,
							...payload,
						},
					},
				};
			} else return state;
		},
		[EmptyCarActions.Action.UPDATE_TRANSPORT_DATA]: (state, { payload }: Action<EmptyCarDocuments['transport']>) => {
			if (payload) {
				return {
					...state,
					data: {
						...state.data,
						documents: {
							...state.data?.documents,
							transport: {
								...state.data?.documents?.transport,
								...payload,
							},
						},
					},
				};
			} else return state;
		},
		[EmptyCarActions.Action.UPDATE_TRAILER_DATA]: (state, { payload }: Action<EmptyCarDocuments['trailer']>) => {
			if (payload) {
				return {
					...state,
					data: {
						...state.data,
						documents: {
							...state.data?.documents,
							trailer: {
								...state.data?.documents?.trailer,
								...payload,
							},
						},
					},
				};
			} else return state;
		},
		[EmptyCarActions.Action.UPDATE_POLICY_DATA]: (state, { payload }: Action<EmptyCarDocuments['policy']>) => {
			if (payload) {
				return {
					...state,
					data: {
						...state.data,
						documents: {
							...state.data?.documents,
							policy: {
								...state.data?.documents?.policy,
								...payload,
							},
						},
					},
				};
			} else return state;
		},
		[EmptyCarActions.Action.UPDATE_DIMENSIONS_DATA]: (state, { payload }: Action<EmptyCarDimensions>) => {
			if (payload) {
				return {
					...state,
					data: {
						...state.data,
						dimensions: {
							...state.data?.dimensions,
							...payload,
						},
					},
				};
			} else return state;
		},
		[EmptyCarActions.Action.ADD_CAR_DATA]: (state, { payload = null }: Action<EmptyCar | null>) => ({
			...state,
			data: payload ?? null,
		}),
		[EmptyCarActions.Action.DELETE_CAR_DATA]: (state) => ({
			...state,
			data: null,
			isOpen: false,
		}),
		[EmptyCarActions.Action.SET_OPENED_STATE]: (state, { payload = false }: Action<boolean>) => ({
			...state,
			isOpen: payload,
		}),
		[EmptyCarActions.Action.SET_LOADING_STATE]: (state, { payload = false }: Action<boolean>) => ({
			...state,
			isLoading: payload,
		}),
		[EmptyCarActions.Action.CREATE_CAR]: (state) => ({
			...state,
		}),
	},
	initialState,
);

export default emptyCarReducer;
