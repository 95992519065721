import React from 'react';
import styled from 'styled-components';

const OrderingLine = styled.div<{ width: string; isSorted: boolean }>`
	width: ${(props) => props.width};
	border: 1px solid #64c8eb;
	opacity: ${(props) => (props.isSorted ? '1' : '0.5')};
	margin-bottom: 2px;
`;

const orArr = {
	desc: ['6px', '4px', '2px'],
	asc: ['2px', '4px', '6px'],
};

export const OrderingIcon = ({ ordering = 'desc', isSorted }: { ordering: 'desc' | 'asc'; isSorted: boolean }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', marginLeft: '5px' }}>
			{orArr[ordering].map((el: string) => (
				<OrderingLine isSorted={isSorted} key={el} width={el} />
			))}
		</div>
	);
};
