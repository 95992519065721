import React from 'react';
import { Localized } from '@fluent/react';
import { BigCar } from 'app/components/Icons';

const Empty = () => (
	<div className='info-message'>
		<div className='content'>
			<div className='icon'>
				<BigCar />
			</div>

			<div className='title' style={{ maxWidth: 350 }}>
				<Localized id='transports-page-please-create' />
			</div>
		</div>
	</div>
);

export default Empty;
