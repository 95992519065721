export enum SidebarType {
	ContactCreate = 'contactCreate',
	OrderDetails = 'orderDetails',
	OrderCreate = 'orderCreate',
	ContactEdit = 'contactEdit',
	OrderEdit = 'orderEdit',
	Current = 'current',
	TenderView = 'renderView',
}

export interface Region {
	count: number;
	name: string;
}

export interface FilterRange {
	min: number;
	max: number;
}

export interface FilterOpts {
	pricePerKm: FilterRange;
	distance: FilterRange;
	weight: FilterRange;
	price: FilterRange;
}

export interface GrainCropData {
	id: string;
	name: string;
	uuid: string;
}

export interface ShippingFare {
	low: number;
	high: number;
	fare: number;
}
